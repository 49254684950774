import { Socket } from "./phoenix";

const socketUtils = {
  getUrl: function (env) {
    switch (env) {
      case "production":
        return "wss://location.service.paack.co/socket";
      case "staging":
        return "wss://location.service.staging.paack.co/socket";
      case "sandbox":
        return "wss://location.service.sandbox.paack.co/socket";
      case "development":
        return "ws://localhost:4000/socket";
    }
  },
  getChannelNameFromUser: function (user) {
    switch (user.role) {
      case "admin":
        return "user:" + user.id;
      case "fleet_manager":
        return "fleet_manager:" + user.company_id;
    }
  },
  webSocketMsgs: {
    ROUTE_UPDATED: "RouteUpdated",
    ROUTE_IN_PROGRESS: "RouteInProgress",
    ROUTE_COMPLETED: "RouteCompleted",
    ROUTE_ERRORED: "RouteErrored",
    REPORT_COMPLETED: "ReportCompleted",
  },
};

function PaackSocket(env, elm) {
  this.socket = null;
  this.channel = null;
  this.env = env;
  this.elm = elm;
}

PaackSocket.prototype.connect = function (user) {
  this.socket = new Socket(socketUtils.getUrl(this.env), {});

  this.socket.connect();
};

PaackSocket.prototype.connectToChannel = function (user) {
  var channelName = socketUtils.getChannelNameFromUser(user);
  this.channel = this.socket.channel(channelName, { token: user.token });
  this.channel.join();
  this.registerEvents(user.role);
};

PaackSocket.prototype.registerEvents = function (role) {
  var elm = this.elm;

  var sendFn = function (tagName) {
    return function (msg) {
      elm.ports.fromUpsideDown.send({ tag: tagName, data: msg });
    };
  };
  switch (role) {
    case "admin":
      this.channel.on(
        "report_completed",
        sendFn(socketUtils.webSocketMsgs.REPORT_COMPLETED)
      );
      break;
    case "fleet_manager":
      this.channel.on(
        "route_updated",
        sendFn(socketUtils.webSocketMsgs.ROUTE_UPDATED)
      );
      this.channel.on(
        "route_in_progress",
        sendFn(socketUtils.webSocketMsgs.ROUTE_IN_PROGRESS)
      );
      this.channel.on(
        "route_errored",
        sendFn(socketUtils.webSocketMsgs.ROUTE_ERRORED)
      );
      this.channel.on(
        "route_completed",
        sendFn(socketUtils.webSocketMsgs.ROUTE_COMPLETED)
      );
      break;
  }
};

PaackSocket.prototype.disconnectChannel = function () {
  if (this.channel !== null) {
    this.channel.leave();
    this.channel = null;
  }
};

PaackSocket.prototype.resetChannelConnection = function (user) {
  this.disconnectChannel();
  this.connectToChannel(user);
};

export { PaackSocket };
