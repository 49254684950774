(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.hq.ad === region.fp.ad)
	{
		return 'on line ' + region.hq.ad;
	}
	return 'on lines ' + region.hq.ad + ' through ' + region.fp.ad;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.fW,
		impl.hT,
		impl.hA,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	var initPair = init(result.a);
	var model = initPair.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		var pair = A2(update, msg, model);
		stepper(model = pair.a, viewMetadata);
		_Platform_enqueueEffects(managers, pair.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, initPair.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS
//
// For some reason, tabs can appear in href protocols and it still works.
// So '\tjava\tSCRIPT:alert("!!!")' and 'javascript:alert("!!!")' are the same
// in practice. That is why _VirtualDom_RE_js and _VirtualDom_RE_js_html look
// so freaky.
//
// Pulling the regular expressions out to the top level gives a slight speed
// boost in small benchmarks (4-10%) but hoisting values to reduce allocation
// can be unpredictable in large programs where JIT may have a harder time with
// functions are not fully self-contained. The benefit is more that the js and
// js_html ones are so weird that I prefer to see them near each other.


var _VirtualDom_RE_script = /^script$/i;
var _VirtualDom_RE_on_formAction = /^(on|formAction$)/i;
var _VirtualDom_RE_js = /^\s*j\s*a\s*v\s*a\s*s\s*c\s*r\s*i\s*p\s*t\s*:/i;
var _VirtualDom_RE_js_html = /^\s*(j\s*a\s*v\s*a\s*s\s*c\s*r\s*i\s*p\s*t\s*:|d\s*a\s*t\s*a\s*:\s*t\s*e\s*x\s*t\s*\/\s*h\s*t\s*m\s*l\s*(,|;))/i;


function _VirtualDom_noScript(tag)
{
	return _VirtualDom_RE_script.test(tag) ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return _VirtualDom_RE_on_formAction.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return _VirtualDom_RE_js.test(value)
		? /**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return _VirtualDom_RE_js_html.test(value)
		? /**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlJson(value)
{
	return (typeof _Json_unwrap(value) === 'string' && _VirtualDom_RE_js_html.test(_Json_unwrap(value)))
		? _Json_wrap(
			/**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		) : value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		gm: func(record.gm),
		hv: record.hv,
		g$: record.g$
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.gm;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.hv;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.g$) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.fW,
		impl.hT,
		impl.hA,
		function(sendToApp, initialModel) {
			var view = impl.h_;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.fW,
		impl.hT,
		impl.hA,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.be && impl.be(sendToApp)
			var view = impl.h_;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.eC);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.hH) && (_VirtualDom_doc.title = title = doc.hH);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.gF;
	var onUrlRequest = impl.gG;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		be: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.de === next.de
							&& curr.cm === next.cm
							&& curr.c9.a === next.c9.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		fW: function(flags)
		{
			return A3(impl.fW, flags, _Browser_getUrl(), key);
		},
		h_: impl.h_,
		hT: impl.hT,
		hA: impl.hA
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { fP: 'hidden', eJ: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { fP: 'mozHidden', eJ: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { fP: 'msHidden', eJ: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { fP: 'webkitHidden', eJ: 'webkitvisibilitychange' }
		: { fP: 'hidden', eJ: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		dC: _Browser_getScene(),
		ea: {
			ei: _Browser_window.pageXOffset,
			h3: _Browser_window.pageYOffset,
			ef: _Browser_doc.documentElement.clientWidth,
			ci: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		ef: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		ci: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			dC: {
				ef: node.scrollWidth,
				ci: node.scrollHeight
			},
			ea: {
				ei: node.scrollLeft,
				h3: node.scrollTop,
				ef: node.clientWidth,
				ci: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			dC: _Browser_getScene(),
			ea: {
				ei: x,
				h3: y,
				ef: _Browser_doc.documentElement.clientWidth,
				ci: _Browser_doc.documentElement.clientHeight
			},
			fm: {
				ei: x + rect.left,
				h3: y + rect.top,
				ef: rect.width,
				ci: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}




// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.b6.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.b6.b, xhr)); });
		$elm$core$Maybe$isJust(request.d2) && _Http_track(router, xhr, request.d2.a);

		try {
			xhr.open(request.cM, request.hV, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.hV));
		}

		_Http_configureRequest(xhr, request);

		request.eC.a && xhr.setRequestHeader('Content-Type', request.eC.a);
		xhr.send(request.eC.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.ch; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.d$.a || 0;
	xhr.responseType = request.b6.d;
	xhr.withCredentials = request.es;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		hV: xhr.responseURL,
		dQ: xhr.status,
		hu: xhr.statusText,
		ch: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			hi: event.loaded,
			hn: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			g1: event.loaded,
			hn: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}

function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}


// DECODER

var _File_decoder = _Json_decodePrim(function(value) {
	// NOTE: checks if `File` exists in case this is run on node
	return (typeof File !== 'undefined' && value instanceof File)
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FILE', value);
});


// METADATA

function _File_name(file) { return file.name; }
function _File_mime(file) { return file.type; }
function _File_size(file) { return file.size; }

function _File_lastModified(file)
{
	return $elm$time$Time$millisToPosix(file.lastModified);
}


// DOWNLOAD

var _File_downloadNode;

function _File_getDownloadNode()
{
	return _File_downloadNode || (_File_downloadNode = document.createElement('a'));
}

var _File_download = F3(function(name, mime, content)
{
	return _Scheduler_binding(function(callback)
	{
		var blob = new Blob([content], {type: mime});

		// for IE10+
		if (navigator.msSaveOrOpenBlob)
		{
			navigator.msSaveOrOpenBlob(blob, name);
			return;
		}

		// for HTML5
		var node = _File_getDownloadNode();
		var objectUrl = URL.createObjectURL(blob);
		node.href = objectUrl;
		node.download = name;
		_File_click(node);
		URL.revokeObjectURL(objectUrl);
	});
});

function _File_downloadUrl(href)
{
	return _Scheduler_binding(function(callback)
	{
		var node = _File_getDownloadNode();
		node.href = href;
		node.download = '';
		node.origin === location.origin || (node.target = '_blank');
		_File_click(node);
	});
}


// IE COMPATIBILITY

function _File_makeBytesSafeForInternetExplorer(bytes)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/10
	// all other browsers can just run `new Blob([bytes])` directly with no problem
	//
	return new Uint8Array(bytes.buffer, bytes.byteOffset, bytes.byteLength);
}

function _File_click(node)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/11
	// all other browsers have MouseEvent and do not need this conditional stuff
	//
	if (typeof MouseEvent === 'function')
	{
		node.dispatchEvent(new MouseEvent('click'));
	}
	else
	{
		var event = document.createEvent('MouseEvents');
		event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
		document.body.appendChild(node);
		node.dispatchEvent(event);
		document.body.removeChild(node);
	}
}


// UPLOAD

var _File_node;

function _File_uploadOne(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			callback(_Scheduler_succeed(event.target.files[0]));
		});
		_File_click(_File_node);
	});
}

function _File_uploadOneOrMore(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.multiple = true;
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			var elmFiles = _List_fromArray(event.target.files);
			callback(_Scheduler_succeed(_Utils_Tuple2(elmFiles.a, elmFiles.b)));
		});
		_File_click(_File_node);
	});
}


// CONTENT

function _File_toString(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsText(blob);
		return function() { reader.abort(); };
	});
}

function _File_toBytes(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(new DataView(reader.result)));
		});
		reader.readAsArrayBuffer(blob);
		return function() { reader.abort(); };
	});
}

function _File_toUrl(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsDataURL(blob);
		return function() { reader.abort(); };
	});
}




var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});


// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.gr) { flags += 'm'; }
	if (options.eG) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;
var $author$project$Msg$UrlChanged = function (a) {
	return {$: 1, a: a};
};
var $author$project$Msg$UrlRequested = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$List$cons = _List_cons;
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.f) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.h),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.h);
		} else {
			var treeLen = builder.f * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.i) : builder.i;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.f);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.h) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.h);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{i: nodeList, f: (len / $elm$core$Array$branchFactor) | 0, h: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {cd: fragment, cm: host, gR: path, c9: port_, de: protocol, g0: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $author$project$Ports$ConnectToWebSocket = function (a) {
	return {$: 2, a: a};
};
var $author$project$Data$Context$Context = F7(
	function (user, zone, time, env, savedSettings, mapsApiKey, jobIdsInProgress) {
		return {fr: env, f4: jobIdsInProgress, gi: mapsApiKey, dB: savedSettings, d_: time, d7: user, em: zone};
	});
var $author$project$Msg$ContextMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Model$Model = F3(
	function (context, key, page) {
		return {eW: context, f5: key, gP: page};
	});
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $Fresheyeball$elm_return$Return$command = F2(
	function (cmd, _v0) {
		var model = _v0.a;
		var cmd_ = _v0.b;
		return _Utils_Tuple2(
			model,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[cmd, cmd_])));
	});
var $author$project$Data$Context$Tick = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $author$project$Data$Context$currentTime = A2($elm$core$Task$perform, $author$project$Data$Context$Tick, $elm$time$Time$now);
var $author$project$Data$Context$AdjustTimeZone = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$here = _Time_here(0);
var $author$project$Data$Context$currentTimeZone = A2($elm$core$Task$perform, $author$project$Data$Context$AdjustTimeZone, $elm$time$Time$here);
var $author$project$Data$Environment$Production = 3;
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $author$project$Data$Environment$Development = 0;
var $author$project$Data$Environment$Sandbox = 2;
var $author$project$Data$Environment$Staging = 1;
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Data$Environment$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (env) {
		switch (env) {
			case 'production':
				return $elm$json$Json$Decode$succeed(3);
			case 'development':
				return $elm$json$Json$Decode$succeed(0);
			case 'staging':
				return $elm$json$Json$Decode$succeed(1);
			case 'sandbox':
				return $elm$json$Json$Decode$succeed(2);
			default:
				return $elm$json$Json$Decode$succeed(3);
		}
	},
	$elm$json$Json$Decode$string);
var $elm$json$Json$Decode$field = _Json_decodeField;
var $elm$core$Result$toMaybe = function (result) {
	if (!result.$) {
		var v = result.a;
		return $elm$core$Maybe$Just(v);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$Data$Environment$decodeFromJsonField = function (json) {
	return A2(
		$elm$core$Maybe$withDefault,
		3,
		$elm$core$Result$toMaybe(
			A2(
				$elm$json$Json$Decode$decodeValue,
				A2($elm$json$Json$Decode$field, 'environment', $author$project$Data$Environment$decoder),
				json)));
};
var $author$project$Data$User$User = F6(
	function (id, fullName, companyId, accessToken, tokenCreatedAt, role) {
		return {bs: accessToken, bL: companyId, fJ: fullName, fS: id, aL: role, d1: tokenCreatedAt};
	});
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $author$project$Data$User$Admin = 0;
var $author$project$Data$User$Driver = 2;
var $author$project$Data$User$FleetManager = 1;
var $author$project$Data$User$Partnership = 3;
var $elm$json$Json$Decode$fail = _Json_fail;
var $author$project$Data$User$roleDecoder = function (role_str) {
	switch (role_str) {
		case 'admin':
			return $elm$json$Json$Decode$succeed(0);
		case 'fleet_manager':
			return $elm$json$Json$Decode$succeed(1);
		case 'driver':
			return $elm$json$Json$Decode$succeed(2);
		case 'partner_ship':
			return $elm$json$Json$Decode$succeed(3);
		default:
			return $elm$json$Json$Decode$fail('Invalid role');
	}
};
var $author$project$Data$User$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'role',
	A2($elm$json$Json$Decode$andThen, $author$project$Data$User$roleDecoder, $elm$json$Json$Decode$string),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'tokenCreatedAt',
		A2($elm$json$Json$Decode$map, $elm$time$Time$millisToPosix, $elm$json$Json$Decode$int),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'token',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'company_id',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'full_name',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'id',
						$elm$json$Json$Decode$int,
						$elm$json$Json$Decode$succeed($author$project$Data$User$User)))))));
var $author$project$Data$User$decodeFromJsonField = function (json) {
	return $elm$core$Result$toMaybe(
		A2(
			$elm$json$Json$Decode$decodeValue,
			A2($elm$json$Json$Decode$field, 'user', $author$project$Data$User$decoder),
			json));
};
var $author$project$Views$MapAssignment$Types$JobIdInProgress = F2(
	function (routeId, jobId) {
		return {f3: jobId, du: routeId};
	});
var $author$project$Views$MapAssignment$Types$jobIdsInProgressDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Views$MapAssignment$Types$JobIdInProgress,
	A2($elm$json$Json$Decode$field, 'routeId', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'jobId', $elm$json$Json$Decode$string));
var $elm$json$Json$Decode$list = _Json_decodeList;
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (!result.$) {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $author$project$Views$MapAssignment$Types$decodeJobIdsInProgressFromJson = function (json) {
	return A2(
		$elm$core$Result$withDefault,
		_List_Nil,
		A2(
			$elm$json$Json$Decode$decodeValue,
			A2(
				$elm$json$Json$Decode$field,
				'routesJobIds',
				$elm$json$Json$Decode$list($author$project$Views$MapAssignment$Types$jobIdsInProgressDecoder)),
			json));
};
var $author$project$Data$Context$decodeMapsApiKey = function (json) {
	return A2(
		$elm$core$Maybe$withDefault,
		'',
		$elm$core$Result$toMaybe(
			A2(
				$elm$json$Json$Decode$decodeValue,
				A2($elm$json$Json$Decode$field, 'mapsApiKey', $elm$json$Json$Decode$string),
				json)));
};
var $author$project$Views$MapAssignment$Types$Settings = F7(
	function (clusterSettings, routingSettings, autoRoutingSettings, enableNoAddressOrdersTab, isOpen, isSaving, errors) {
		return {aR: autoRoutingSettings, eQ: clusterSettings, fo: enableNoAddressOrdersTab, ft: errors, T: isOpen, f1: isSaving, he: routingSettings};
	});
var $elm_community$json_extra$Json$Decode$Extra$andMap = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $author$project$Views$MapAssignment$Types$AutoRoutingSettings = F3(
	function (vehicle, workingHours, startWorkingTime) {
		return {hs: startWorkingTime, hX: vehicle, eg: workingHours};
	});
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $author$project$Data$Driver$Bicycle = 2;
var $author$project$Data$Driver$Motorbike = 0;
var $author$project$Data$Driver$Truck = 3;
var $author$project$Data$Driver$Van = 1;
var $author$project$Common$Vehicle$vehicleDecoder = function (vehicle) {
	return $elm$json$Json$Decode$succeed(
		function () {
			switch (vehicle) {
				case 'Motorbike':
					return 0;
				case 'Van':
					return 1;
				case 'Bicycle':
					return 2;
				case 'Truck':
					return 3;
				default:
					return 3;
			}
		}());
};
var $author$project$Views$MapAssignment$Types$decodeAutoRoutingSettings = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'startWorkingTime', $elm$json$Json$Decode$string)),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'workingHours', $elm$json$Json$Decode$int),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2(
				$elm$json$Json$Decode$field,
				'vehicle',
				A2($elm$json$Json$Decode$andThen, $author$project$Common$Vehicle$vehicleDecoder, $elm$json$Json$Decode$string)),
			$elm$json$Json$Decode$succeed($author$project$Views$MapAssignment$Types$AutoRoutingSettings))));
var $author$project$Views$MapAssignment$Types$Clustered = function (a) {
	return {$: 1, a: a};
};
var $author$project$Views$MapAssignment$Types$clusteredDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Views$MapAssignment$Types$Clustered,
	A3(
		$elm$json$Json$Decode$map2,
		F2(
			function (gridSize, minPinsToCluster) {
				return {fN: gridSize, go: minPinsToCluster};
			}),
		A2($elm$json$Json$Decode$field, 'gridSize', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'minPinsToCluster', $elm$json$Json$Decode$int)));
var $author$project$Views$MapAssignment$Types$NotClustered = {$: 0};
var $author$project$Views$MapAssignment$Types$notClusteredDecoder = $elm$json$Json$Decode$succeed($author$project$Views$MapAssignment$Types$NotClustered);
var $author$project$Views$MapAssignment$Types$decodeClusterSettings = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[$author$project$Views$MapAssignment$Types$clusteredDecoder, $author$project$Views$MapAssignment$Types$notClusteredDecoder]));
var $author$project$Views$MapAssignment$Types$RoutingSettings = F6(
	function (deliveryServiceTime, packageVolume, originWarehouseId, startTime, startTimeSetAt, warehouseSelectedAt) {
		return {bW: deliveryServiceTime, gN: originWarehouseId, c5: packageVolume, ak: startTime, hr: startTimeSetAt, h$: warehouseSelectedAt};
	});
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $author$project$Views$MapAssignment$Types$decodeEpochTime = function (epochTimeDecoder) {
	return A2(
		$elm$json$Json$Decode$andThen,
		A2($elm$core$Basics$composeR, $elm$time$Time$millisToPosix, $elm$json$Json$Decode$succeed),
		epochTimeDecoder);
};
var $author$project$Views$MapAssignment$Types$decodeRoutingSettings = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	$elm$json$Json$Decode$maybe(
		$author$project$Views$MapAssignment$Types$decodeEpochTime(
			A2($elm$json$Json$Decode$field, 'warehouseSelectedAt', $elm$json$Json$Decode$int))),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		$elm$json$Json$Decode$maybe(
			$author$project$Views$MapAssignment$Types$decodeEpochTime(
				A2($elm$json$Json$Decode$field, 'startTimeSetAt', $elm$json$Json$Decode$int))),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			$elm$json$Json$Decode$maybe(
				A2($elm$json$Json$Decode$field, 'startTime', $elm$json$Json$Decode$string)),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				$elm$json$Json$Decode$maybe(
					A2($elm$json$Json$Decode$field, 'originWarehouseId', $elm$json$Json$Decode$int)),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'packageVolume', $elm$json$Json$Decode$string),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'serviceTime', $elm$json$Json$Decode$string),
						$elm$json$Json$Decode$succeed($author$project$Views$MapAssignment$Types$RoutingSettings)))))));
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $author$project$Views$MapAssignment$Types$decoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	$elm$json$Json$Decode$succeed($elm$core$Dict$empty),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		$elm$json$Json$Decode$succeed(false),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			$elm$json$Json$Decode$succeed(false),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'enableNoAddressOrdersTab', $elm$json$Json$Decode$bool),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'autoRoutingSettings', $author$project$Views$MapAssignment$Types$decodeAutoRoutingSettings),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'routingSettings', $author$project$Views$MapAssignment$Types$decodeRoutingSettings),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'clusterSettings', $author$project$Views$MapAssignment$Types$decodeClusterSettings),
							$elm$json$Json$Decode$succeed($author$project$Views$MapAssignment$Types$Settings))))))));
var $author$project$Views$MapAssignment$Types$decodeSettingsFromJson = function (json) {
	return $elm$core$Result$toMaybe(
		A2(
			$elm$json$Json$Decode$decodeValue,
			A2($elm$json$Json$Decode$field, 'settings', $author$project$Views$MapAssignment$Types$decoder),
			json));
};
var $author$project$Page$Login = function (a) {
	return {$: 0, a: a};
};
var $author$project$Views$Login$Login$FetchUserByToken = function (a) {
	return {$: 4, a: a};
};
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $rtfeldman$elm_iso8601_date_strings$DeadEnds$problemToString = function (p) {
	switch (p.$) {
		case 0:
			var s = p.a;
			return 'expecting \'' + (s + '\'');
		case 1:
			return 'expecting int';
		case 2:
			return 'expecting hex';
		case 3:
			return 'expecting octal';
		case 4:
			return 'expecting binary';
		case 5:
			return 'expecting float';
		case 6:
			return 'expecting number';
		case 7:
			return 'expecting variable';
		case 8:
			var s = p.a;
			return 'expecting symbol \'' + (s + '\'');
		case 9:
			var s = p.a;
			return 'expecting keyword \'' + (s + '\'');
		case 10:
			return 'expecting end';
		case 11:
			return 'unexpected char';
		case 12:
			var s = p.a;
			return 'problem ' + s;
		default:
			return 'bad repeat';
	}
};
var $rtfeldman$elm_iso8601_date_strings$DeadEnds$deadEndToString = function (deadend) {
	return $rtfeldman$elm_iso8601_date_strings$DeadEnds$problemToString(deadend.da) + (' at row ' + ($elm$core$String$fromInt(deadend.dx) + (', col ' + $elm$core$String$fromInt(deadend.bK))));
};
var $elm$core$List$intersperse = F2(
	function (sep, xs) {
		if (!xs.b) {
			return _List_Nil;
		} else {
			var hd = xs.a;
			var tl = xs.b;
			var step = F2(
				function (x, rest) {
					return A2(
						$elm$core$List$cons,
						sep,
						A2($elm$core$List$cons, x, rest));
				});
			var spersed = A3($elm$core$List$foldr, step, _List_Nil, tl);
			return A2($elm$core$List$cons, hd, spersed);
		}
	});
var $rtfeldman$elm_iso8601_date_strings$DeadEnds$deadEndsToString = function (deadEnds) {
	return $elm$core$String$concat(
		A2(
			$elm$core$List$intersperse,
			'; ',
			A2($elm$core$List$map, $rtfeldman$elm_iso8601_date_strings$DeadEnds$deadEndToString, deadEnds)));
};
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0;
		return function (s0) {
			var _v1 = parseA(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				var _v2 = callback(a);
				var parseB = _v2;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$parser$Parser$ExpectingEnd = {$: 10};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {bK: col, eX: contextStack, da: problem, dx: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.dx, s.bK, x, s.eW));
	});
var $elm$parser$Parser$Advanced$end = function (x) {
	return function (s) {
		return _Utils_eq(
			$elm$core$String$length(s.a),
			s.cV) ? A3($elm$parser$Parser$Advanced$Good, false, 0, s) : A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$end = $elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd);
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.a);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.cV, offset) < 0,
					0,
					{bK: col, eW: s0.eW, d: s0.d, cV: offset, dx: row, a: s0.a});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.cV, s.dx, s.bK, s);
	};
};
var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3($elm$core$String$slice, s0.cV, s1.cV, s0.a),
						a),
					s1);
			}
		};
	});
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
var $elm$parser$Parser$Problem = function (a) {
	return {$: 12, a: a};
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return function (s) {
		return A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $elm$core$Basics$round = _Basics_round;
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $elm$core$String$toFloat = _String_toFloat;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs = A2(
	$elm$parser$Parser$andThen,
	function (str) {
		if ($elm$core$String$length(str) <= 9) {
			var _v0 = $elm$core$String$toFloat('0.' + str);
			if (!_v0.$) {
				var floatVal = _v0.a;
				return $elm$parser$Parser$succeed(
					$elm$core$Basics$round(floatVal * 1000));
			} else {
				return $elm$parser$Parser$problem('Invalid float: \"' + (str + '\"'));
			}
		} else {
			return $elm$parser$Parser$problem(
				'Expected at most 9 digits, but got ' + $elm$core$String$fromInt(
					$elm$core$String$length(str)));
		}
	},
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompWhile($elm$core$Char$isDigit)));
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts = F6(
	function (monthYearDayMs, hour, minute, second, ms, utcOffsetMinutes) {
		return $elm$time$Time$millisToPosix((((monthYearDayMs + (((hour * 60) * 60) * 1000)) + (((minute - utcOffsetMinutes) * 60) * 1000)) + (second * 1000)) + ms);
	});
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $elm$parser$Parser$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$core$String$append = _String_append;
var $elm$parser$Parser$UnexpectedChar = {$: 11};
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return function (s) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.cV, s.a);
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{bK: 1, eW: s.eW, d: s.d, cV: s.cV + 1, dx: s.dx + 1, a: s.a}) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{bK: s.bK + 1, eW: s.eW, d: s.d, cV: newOffset, dx: s.dx, a: s.a}));
		};
	});
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $elm$parser$Parser$Advanced$loopHelp = F4(
	function (p, state, callback, s0) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var parse = _v0;
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p1 = _v1.a;
				var step = _v1.b;
				var s1 = _v1.c;
				if (!step.$) {
					var newState = step.a;
					var $temp$p = p || p1,
						$temp$state = newState,
						$temp$callback = callback,
						$temp$s0 = s1;
					p = $temp$p;
					state = $temp$state;
					callback = $temp$callback;
					s0 = $temp$s0;
					continue loopHelp;
				} else {
					var result = step.a;
					return A3($elm$parser$Parser$Advanced$Good, p || p1, result, s1);
				}
			} else {
				var p1 = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p || p1, x);
			}
		}
	});
var $elm$parser$Parser$Advanced$loop = F2(
	function (state, callback) {
		return function (s) {
			return A4($elm$parser$Parser$Advanced$loopHelp, false, state, callback, s);
		};
	});
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $elm$parser$Parser$Advanced$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Advanced$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$toAdvancedStep = function (step) {
	if (!step.$) {
		var s = step.a;
		return $elm$parser$Parser$Advanced$Loop(s);
	} else {
		var a = step.a;
		return $elm$parser$Parser$Advanced$Done(a);
	}
};
var $elm$parser$Parser$loop = F2(
	function (state, callback) {
		return A2(
			$elm$parser$Parser$Advanced$loop,
			state,
			function (s) {
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$toAdvancedStep,
					callback(s));
			});
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt = function (quantity) {
	var helper = function (str) {
		if (_Utils_eq(
			$elm$core$String$length(str),
			quantity)) {
			var _v0 = $elm$core$String$toInt(str);
			if (!_v0.$) {
				var intVal = _v0.a;
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$Done,
					$elm$parser$Parser$succeed(intVal));
			} else {
				return $elm$parser$Parser$problem('Invalid integer: \"' + (str + '\"'));
			}
		} else {
			return A2(
				$elm$parser$Parser$map,
				function (nextChar) {
					return $elm$parser$Parser$Loop(
						A2($elm$core$String$append, str, nextChar));
				},
				$elm$parser$Parser$getChompedString(
					$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
		}
	};
	return A2($elm$parser$Parser$loop, '', helper);
};
var $elm$parser$Parser$ExpectingSymbol = function (a) {
	return {$: 8, a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$core$Basics$not = _Basics_not;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.cV, s.dx, s.bK, s.a);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{bK: newCol, eW: s.eW, d: s.d, cV: newOffset, dx: newRow, a: s.a});
	};
};
var $elm$parser$Parser$Advanced$symbol = $elm$parser$Parser$Advanced$token;
var $elm$parser$Parser$symbol = function (str) {
	return $elm$parser$Parser$Advanced$symbol(
		A2(
			$elm$parser$Parser$Advanced$Token,
			str,
			$elm$parser$Parser$ExpectingSymbol(str)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear = 1970;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay = function (day) {
	return $elm$parser$Parser$problem(
		'Invalid day: ' + $elm$core$String$fromInt(day));
};
var $elm$core$Basics$modBy = _Basics_modBy;
var $elm$core$Basics$neq = _Utils_notEqual;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear = function (year) {
	return (!A2($elm$core$Basics$modBy, 4, year)) && ((!(!A2($elm$core$Basics$modBy, 100, year))) || (!A2($elm$core$Basics$modBy, 400, year)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore = function (y1) {
	var y = y1 - 1;
	return (((y / 4) | 0) - ((y / 100) | 0)) + ((y / 400) | 0);
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay = 86400000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear = 31536000000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay = function (_v0) {
	var year = _v0.a;
	var month = _v0.b;
	var dayInMonth = _v0.c;
	if (dayInMonth < 0) {
		return $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth);
	} else {
		var succeedWith = function (extraMs) {
			var yearMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear * (year - $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear);
			var days = ((month < 3) || (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year))) ? (dayInMonth - 1) : dayInMonth;
			var dayMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay * (days + ($rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore(year) - $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore($rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear)));
			return $elm$parser$Parser$succeed((extraMs + yearMs) + dayMs);
		};
		switch (month) {
			case 1:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(0);
			case 2:
				return ((dayInMonth > 29) || ((dayInMonth === 29) && (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year)))) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(2678400000);
			case 3:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(5097600000);
			case 4:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(7776000000);
			case 5:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(10368000000);
			case 6:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(13046400000);
			case 7:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(15638400000);
			case 8:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(18316800000);
			case 9:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(20995200000);
			case 10:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(23587200000);
			case 11:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(26265600000);
			case 12:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(28857600000);
			default:
				return $elm$parser$Parser$problem(
					'Invalid month: \"' + ($elm$core$String$fromInt(month) + '\"'));
		}
	}
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs = A2(
	$elm$parser$Parser$andThen,
	$rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay,
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F3(
						function (year, month, day) {
							return _Utils_Tuple3(year, month, day);
						})),
				$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(4)),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed($elm$core$Basics$identity),
							$elm$parser$Parser$symbol('-')),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
					]))),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$ignorer,
						$elm$parser$Parser$succeed($elm$core$Basics$identity),
						$elm$parser$Parser$symbol('-')),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
				]))));
var $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes = function () {
	var utcOffsetMinutesFromParts = F3(
		function (multiplier, hours, minutes) {
			return (multiplier * (hours * 60)) + minutes;
		});
	return A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$map,
					function (_v0) {
						return 0;
					},
					$elm$parser$Parser$symbol('Z')),
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							$elm$parser$Parser$succeed(utcOffsetMinutesFromParts),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$map,
										function (_v1) {
											return 1;
										},
										$elm$parser$Parser$symbol('+')),
										A2(
										$elm$parser$Parser$map,
										function (_v2) {
											return -1;
										},
										$elm$parser$Parser$symbol('-'))
									]))),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$ignorer,
									$elm$parser$Parser$succeed($elm$core$Basics$identity),
									$elm$parser$Parser$symbol(':')),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2),
								$elm$parser$Parser$succeed(0)
							]))),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(0),
					$elm$parser$Parser$end)
				])));
}();
var $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601 = A2(
	$elm$parser$Parser$andThen,
	function (datePart) {
		return $elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed(
											$rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts(datePart)),
										$elm$parser$Parser$symbol('T')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$elm$parser$Parser$oneOf(
									_List_fromArray(
										[
											A2(
											$elm$parser$Parser$keeper,
											A2(
												$elm$parser$Parser$ignorer,
												$elm$parser$Parser$succeed($elm$core$Basics$identity),
												$elm$parser$Parser$symbol(':')),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
										]))),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$keeper,
										A2(
											$elm$parser$Parser$ignorer,
											$elm$parser$Parser$succeed($elm$core$Basics$identity),
											$elm$parser$Parser$symbol(':')),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2),
										$elm$parser$Parser$succeed(0)
									]))),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$symbol('.')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs),
									$elm$parser$Parser$succeed(0)
								]))),
					A2($elm$parser$Parser$ignorer, $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes, $elm$parser$Parser$end)),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A6($rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts, datePart, 0, 0, 0, 0, 0)),
					$elm$parser$Parser$end)
				]));
	},
	$rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs);
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {bK: col, da: problem, dx: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.dx, p.bK, p.da);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{bK: 1, eW: _List_Nil, d: 1, cV: 0, dx: 1, a: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime = function (str) {
	return A2($elm$parser$Parser$run, $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601, str);
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		var _v0 = $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(str);
		if (_v0.$ === 1) {
			var deadEnds = _v0.a;
			return $elm$json$Json$Decode$fail(
				$rtfeldman$elm_iso8601_date_strings$DeadEnds$deadEndsToString(deadEnds));
		} else {
			var time = _v0.a;
			return $elm$json$Json$Decode$succeed(time);
		}
	},
	$elm$json$Json$Decode$string);
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt = F3(
	function (path, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$at, path, valDecoder),
			decoder);
	});
var $author$project$Data$User$decoderFromLoginResponse = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'role',
	A2($elm$json$Json$Decode$andThen, $author$project$Data$User$roleDecoder, $elm$json$Json$Decode$string),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
		_List_fromArray(
			['token', 'created_at']),
		$rtfeldman$elm_iso8601_date_strings$Iso8601$decoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
			_List_fromArray(
				['token', 'token']),
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'company_id',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'full_name',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'id',
						$elm$json$Json$Decode$int,
						$elm$json$Json$Decode$succeed($author$project$Data$User$User)))))));
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Request$User$fetchUserDataByTokenEncoder = function (token) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'token',
				$elm$json$Json$Encode$string(token))
			]));
};
var $author$project$Request$Url$apiRailsUrl = function (env) {
	switch (env) {
		case 3:
			return '//dashboard.paack.co/';
		case 1:
			return '//dashboard.staging.paack.co/';
		case 2:
			return 'https://dashboard.sandbox.paack.co/';
		default:
			return '//localhost:3000/';
	}
};
var $author$project$Request$User$fetchUserDataByTokenUrl = function (env) {
	return $author$project$Request$Url$apiRailsUrl(env) + 'api/v1/users/login';
};
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.dQ));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			$elm$http$Http$resolve(
				function (string) {
					return A2(
						$elm$core$Result$mapError,
						$elm$json$Json$Decode$errorToString,
						A2($elm$json$Json$Decode$decodeString, decoder, string));
				}));
	});
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {dm: reqs, dY: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.d2;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.dm));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.dY)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					es: r.es,
					eC: r.eC,
					b6: A2(_Http_mapExpect, func, r.b6),
					ch: r.ch,
					cM: r.cM,
					d$: r.d$,
					d2: r.d2,
					hV: r.hV
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{es: false, eC: r.eC, b6: r.b6, ch: r.ch, cM: r.cM, d$: r.d$, d2: r.d2, hV: r.hV}));
};
var $author$project$Request$HttpClient$requestParams = F5(
	function (method, headers, url, body, expect) {
		return {eC: body, b6: expect, ch: headers, cM: method, d$: $elm$core$Maybe$Nothing, d2: $elm$core$Maybe$Nothing, hV: url};
	});
var $author$project$Request$HttpClient$unauthenticatedPost = F4(
	function (url, value, decoder, msg) {
		return $elm$http$Http$request(
			A5(
				$author$project$Request$HttpClient$requestParams,
				'POST',
				_List_Nil,
				url,
				$elm$http$Http$jsonBody(value),
				A2($elm$http$Http$expectJson, msg, decoder)));
	});
var $author$project$Request$User$fetchUserDataByToken = F3(
	function (env, token, msg) {
		return A4(
			$author$project$Request$HttpClient$unauthenticatedPost,
			$author$project$Request$User$fetchUserDataByTokenUrl(env),
			$author$project$Request$User$fetchUserDataByTokenEncoder(token),
			$author$project$Data$User$decoderFromLoginResponse,
			msg);
	});
var $author$project$Views$Login$Login$initialModel = F3(
	function (_v0, redirectPath, token) {
		var env = _v0.fr;
		var shouldFetchingUserDataByToken = (!_Utils_eq(redirectPath, $elm$core$Maybe$Nothing)) && (!_Utils_eq(token, $elm$core$Maybe$Nothing));
		var cmd = shouldFetchingUserDataByToken ? _List_fromArray(
			[
				A3(
				$author$project$Request$User$fetchUserDataByToken,
				env,
				A2($elm$core$Maybe$withDefault, '', token),
				$author$project$Views$Login$Login$FetchUserByToken)
			]) : _List_Nil;
		return _Utils_Tuple2(
			{fn: '', ft: _List_Nil, as: shouldFetchingUserDataByToken, gQ: '', aK: redirectPath},
			$elm$core$Platform$Cmd$batch(cmd));
	});
var $author$project$Page$initialPage = function (context) {
	return $author$project$Page$Login(
		A3($author$project$Views$Login$Login$initialModel, context, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing).a);
};
var $elm$core$Platform$Cmd$map = _Platform_map;
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $elm$json$Json$Encode$int = _Json_wrap;
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $author$project$Data$User$roleEncode = function (role) {
	var str_role = function () {
		switch (role) {
			case 0:
				return 'admin';
			case 1:
				return 'fleet_manager';
			case 2:
				return 'driver';
			default:
				return 'partner_ship';
		}
	}();
	return $elm$json$Json$Encode$string(str_role);
};
var $author$project$Data$User$encode = function (user) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(user.fS)),
				_Utils_Tuple2(
				'full_name',
				$elm$json$Json$Encode$string(user.fJ)),
				_Utils_Tuple2(
				'company_id',
				$elm$json$Json$Encode$int(user.bL)),
				_Utils_Tuple2(
				'token',
				$elm$json$Json$Encode$string(user.bs)),
				_Utils_Tuple2(
				'tokenCreatedAt',
				$elm$json$Json$Encode$int(
					$elm$time$Time$posixToMillis(user.d1))),
				_Utils_Tuple2(
				'role',
				$author$project$Data$User$roleEncode(user.aL))
			]));
};
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$Ports$encodeExpirableSetting = function (setting) {
	switch (setting) {
		case 0:
			return $elm$json$Json$Encode$string('StartTime');
		case 1:
			return $elm$json$Json$Encode$string('SelectedWarehouse');
		case 2:
			return $elm$json$Json$Encode$string('Both');
		default:
			return $elm$json$Json$Encode$null;
	}
};
var $elm$json$Json$Encode$bool = _Json_wrap;
var $author$project$Common$Vehicle$toString = function (vehicle) {
	switch (vehicle) {
		case 0:
			return 'Motorcycle';
		case 1:
			return 'Van';
		case 2:
			return 'Bicycle';
		default:
			return 'Truck';
	}
};
var $author$project$Views$MapAssignment$Types$encodeAutoRoutingSettings = function (autoRoutingSettings) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'vehicle',
				$elm$json$Json$Encode$string(
					$author$project$Common$Vehicle$toString(autoRoutingSettings.hX))),
				_Utils_Tuple2(
				'workingHours',
				$elm$json$Json$Encode$int(autoRoutingSettings.eg)),
				_Utils_Tuple2(
				'startWorkingTime',
				$elm$json$Json$Encode$string(
					A2($elm$core$Maybe$withDefault, '', autoRoutingSettings.hs)))
			]));
};
var $author$project$Views$MapAssignment$Types$encodeClusterSettings = function (clusterSettings) {
	if (!clusterSettings.$) {
		return $elm$json$Json$Encode$string('not_clustered');
	} else {
		var gridSize = clusterSettings.a.fN;
		var minPinsToCluster = clusterSettings.a.go;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'gridSize',
					$elm$json$Json$Encode$int(gridSize)),
					_Utils_Tuple2(
					'minPinsToCluster',
					$elm$json$Json$Encode$int(minPinsToCluster))
				]));
	}
};
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm_community$json_extra$Json$Encode$Extra$maybe = function (encoder) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$Maybe$map(encoder),
		$elm$core$Maybe$withDefault($elm$json$Json$Encode$null));
};
var $author$project$Views$MapAssignment$Types$encodeRoutingSettings = function (routingSettings) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'serviceTime',
				$elm$json$Json$Encode$string(routingSettings.bW)),
				_Utils_Tuple2(
				'packageVolume',
				$elm$json$Json$Encode$string(routingSettings.c5)),
				_Utils_Tuple2(
				'originWarehouseId',
				A2($elm_community$json_extra$Json$Encode$Extra$maybe, $elm$json$Json$Encode$int, routingSettings.gN)),
				_Utils_Tuple2(
				'startTime',
				A2($elm_community$json_extra$Json$Encode$Extra$maybe, $elm$json$Json$Encode$string, routingSettings.ak)),
				_Utils_Tuple2(
				'startTimeSetAt',
				A2(
					$elm_community$json_extra$Json$Encode$Extra$maybe,
					A2($elm$core$Basics$composeR, $elm$time$Time$posixToMillis, $elm$json$Json$Encode$int),
					routingSettings.hr)),
				_Utils_Tuple2(
				'warehouseSelectedAt',
				A2(
					$elm_community$json_extra$Json$Encode$Extra$maybe,
					A2($elm$core$Basics$composeR, $elm$time$Time$posixToMillis, $elm$json$Json$Encode$int),
					routingSettings.h$))
			]));
};
var $author$project$Views$MapAssignment$Types$encoder = function (settings) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'clusterSettings',
				$author$project$Views$MapAssignment$Types$encodeClusterSettings(settings.eQ)),
				_Utils_Tuple2(
				'routingSettings',
				$author$project$Views$MapAssignment$Types$encodeRoutingSettings(settings.he)),
				_Utils_Tuple2(
				'autoRoutingSettings',
				$author$project$Views$MapAssignment$Types$encodeAutoRoutingSettings(settings.aR)),
				_Utils_Tuple2(
				'enableNoAddressOrdersTab',
				$elm$json$Json$Encode$bool(settings.fo))
			]));
};
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $author$project$Ports$toUpsideDown = _Platform_outgoingPort(
	'toUpsideDown',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'data',
					$elm$core$Basics$identity($.e2)),
					_Utils_Tuple2(
					'tag',
					$elm$json$Json$Encode$string($.j))
				]));
	});
var $author$project$Ports$sendMsgToUpsideDown = function (msg) {
	switch (msg.$) {
		case 0:
			var user = msg.a;
			return $author$project$Ports$toUpsideDown(
				{
					e2: A2($elm_community$json_extra$Json$Encode$Extra$maybe, $author$project$Data$User$encode, user),
					j: 'StoreSession'
				});
		case 1:
			var settings = msg.a;
			return $author$project$Ports$toUpsideDown(
				{
					e2: A2($elm_community$json_extra$Json$Encode$Extra$maybe, $author$project$Views$MapAssignment$Types$encoder, settings),
					j: 'SaveSettings'
				});
		case 2:
			var user = msg.a;
			return $author$project$Ports$toUpsideDown(
				{
					e2: $author$project$Data$User$encode(user),
					j: 'ConnectToWebSocket'
				});
		case 3:
			return $author$project$Ports$toUpsideDown(
				{e2: $elm$json$Json$Encode$null, j: 'DiscardRouteJobInfo'});
		case 4:
			return $author$project$Ports$toUpsideDown(
				{e2: $elm$json$Json$Encode$null, j: 'CheckStoredRouteJobInfo'});
		case 5:
			var jobId = msg.a;
			return $author$project$Ports$toUpsideDown(
				{
					e2: $elm$json$Json$Encode$string(jobId),
					j: 'StartJobTimer'
				});
		case 6:
			var jobId = msg.a;
			return $author$project$Ports$toUpsideDown(
				{
					e2: $elm$json$Json$Encode$string(jobId),
					j: 'StopJobTimer'
				});
		case 7:
			return $author$project$Ports$toUpsideDown(
				{e2: $elm$json$Json$Encode$null, j: 'PlayAlertSound'});
		case 8:
			var title = msg.a;
			return $author$project$Ports$toUpsideDown(
				{
					e2: $elm$json$Json$Encode$string(title),
					j: 'UpdatePageTitle'
				});
		case 9:
			var setting = msg.a;
			var keysToReset = msg.b;
			return $author$project$Ports$toUpsideDown(
				{
					e2: $elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'setting',
								$author$project$Ports$encodeExpirableSetting(setting)),
								_Utils_Tuple2(
								'keysToReset',
								A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, keysToReset))
							])),
					j: 'SetManualRouteSettingsExpired'
				});
		case 10:
			return $author$project$Ports$toUpsideDown(
				{e2: $elm$json$Json$Encode$null, j: 'SetTimerToKeepOptimizeButtonsDisabled'});
		case 11:
			var routeId = msg.a.du;
			var jobId = msg.a.f3;
			return $author$project$Ports$toUpsideDown(
				{
					e2: $elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'routeId',
								$elm$json$Json$Encode$int(routeId)),
								_Utils_Tuple2(
								'jobId',
								$elm$json$Json$Encode$string(jobId))
							])),
					j: 'StoreRouteJobId'
				});
		default:
			var routeId = msg.a;
			return $author$project$Ports$toUpsideDown(
				{
					e2: $elm$json$Json$Encode$int(routeId),
					j: 'DiscardRouteJobId'
				});
	}
};
var $Fresheyeball$elm_return$Return$singleton = function (a) {
	return _Utils_Tuple2(a, $elm$core$Platform$Cmd$none);
};
var $elm$time$Time$utc = A2($elm$time$Time$Zone, 0, _List_Nil);
var $author$project$Model$init = F3(
	function (val, url, key) {
		var maybeUser = $author$project$Data$User$decodeFromJsonField(val);
		var maybeSettings = $author$project$Views$MapAssignment$Types$decodeSettingsFromJson(val);
		var mapsApiKey = $author$project$Data$Context$decodeMapsApiKey(val);
		var jobIdsInProgress = $author$project$Views$MapAssignment$Types$decodeJobIdsInProgressFromJson(val);
		var env = $author$project$Data$Environment$decodeFromJsonField(val);
		var context = A7(
			$author$project$Data$Context$Context,
			maybeUser,
			$elm$time$Time$utc,
			$elm$time$Time$millisToPosix(0),
			env,
			maybeSettings,
			mapsApiKey,
			jobIdsInProgress);
		var page = $author$project$Page$initialPage(context);
		var cmd = function () {
			if (!maybeUser.$) {
				var u = maybeUser.a;
				return $author$project$Ports$sendMsgToUpsideDown(
					$author$project$Ports$ConnectToWebSocket(u));
			} else {
				return $elm$core$Platform$Cmd$none;
			}
		}();
		return A2(
			$Fresheyeball$elm_return$Return$command,
			cmd,
			A2(
				$Fresheyeball$elm_return$Return$command,
				A2(
					$elm$core$Task$perform,
					$author$project$Msg$UrlChanged,
					$elm$core$Task$succeed(url)),
				A2(
					$Fresheyeball$elm_return$Return$command,
					A2($elm$core$Platform$Cmd$map, $author$project$Msg$ContextMsg, $author$project$Data$Context$currentTime),
					A2(
						$Fresheyeball$elm_return$Return$command,
						A2($elm$core$Platform$Cmd$map, $author$project$Msg$ContextMsg, $author$project$Data$Context$currentTimeZone),
						$Fresheyeball$elm_return$Return$singleton(
							A3($author$project$Model$Model, context, key, page))))));
	});
var $author$project$Msg$ReportsMsg = function (a) {
	return {$: 10, a: a};
};
var $author$project$Views$Reports$Msg$TickMsg = function (a) {
	return {$: 17, a: a};
};
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$time$Time$Every = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$State = F2(
	function (taggers, processes) {
		return {dc: processes, dZ: taggers};
	});
var $elm$time$Time$init = $elm$core$Task$succeed(
	A2($elm$time$Time$State, $elm$core$Dict$empty, $elm$core$Dict$empty));
var $elm$time$Time$addMySub = F2(
	function (_v0, state) {
		var interval = _v0.a;
		var tagger = _v0.b;
		var _v1 = A2($elm$core$Dict$get, interval, state);
		if (_v1.$ === 1) {
			return A3(
				$elm$core$Dict$insert,
				interval,
				_List_fromArray(
					[tagger]),
				state);
		} else {
			var taggers = _v1.a;
			return A3(
				$elm$core$Dict$insert,
				interval,
				A2($elm$core$List$cons, tagger, taggers),
				state);
		}
	});
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$time$Time$setInterval = _Time_setInterval;
var $elm$time$Time$spawnHelp = F3(
	function (router, intervals, processes) {
		if (!intervals.b) {
			return $elm$core$Task$succeed(processes);
		} else {
			var interval = intervals.a;
			var rest = intervals.b;
			var spawnTimer = $elm$core$Process$spawn(
				A2(
					$elm$time$Time$setInterval,
					interval,
					A2($elm$core$Platform$sendToSelf, router, interval)));
			var spawnRest = function (id) {
				return A3(
					$elm$time$Time$spawnHelp,
					router,
					rest,
					A3($elm$core$Dict$insert, interval, id, processes));
			};
			return A2($elm$core$Task$andThen, spawnRest, spawnTimer);
		}
	});
var $elm$time$Time$onEffects = F3(
	function (router, subs, _v0) {
		var processes = _v0.dc;
		var rightStep = F3(
			function (_v6, id, _v7) {
				var spawns = _v7.a;
				var existing = _v7.b;
				var kills = _v7.c;
				return _Utils_Tuple3(
					spawns,
					existing,
					A2(
						$elm$core$Task$andThen,
						function (_v5) {
							return kills;
						},
						$elm$core$Process$kill(id)));
			});
		var newTaggers = A3($elm$core$List$foldl, $elm$time$Time$addMySub, $elm$core$Dict$empty, subs);
		var leftStep = F3(
			function (interval, taggers, _v4) {
				var spawns = _v4.a;
				var existing = _v4.b;
				var kills = _v4.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, interval, spawns),
					existing,
					kills);
			});
		var bothStep = F4(
			function (interval, taggers, id, _v3) {
				var spawns = _v3.a;
				var existing = _v3.b;
				var kills = _v3.c;
				return _Utils_Tuple3(
					spawns,
					A3($elm$core$Dict$insert, interval, id, existing),
					kills);
			});
		var _v1 = A6(
			$elm$core$Dict$merge,
			leftStep,
			bothStep,
			rightStep,
			newTaggers,
			processes,
			_Utils_Tuple3(
				_List_Nil,
				$elm$core$Dict$empty,
				$elm$core$Task$succeed(0)));
		var spawnList = _v1.a;
		var existingDict = _v1.b;
		var killTask = _v1.c;
		return A2(
			$elm$core$Task$andThen,
			function (newProcesses) {
				return $elm$core$Task$succeed(
					A2($elm$time$Time$State, newTaggers, newProcesses));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$time$Time$spawnHelp, router, spawnList, existingDict);
				},
				killTask));
	});
var $elm$time$Time$onSelfMsg = F3(
	function (router, interval, state) {
		var _v0 = A2($elm$core$Dict$get, interval, state.dZ);
		if (_v0.$ === 1) {
			return $elm$core$Task$succeed(state);
		} else {
			var taggers = _v0.a;
			var tellTaggers = function (time) {
				return $elm$core$Task$sequence(
					A2(
						$elm$core$List$map,
						function (tagger) {
							return A2(
								$elm$core$Platform$sendToApp,
								router,
								tagger(time));
						},
						taggers));
			};
			return A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$succeed(state);
				},
				A2($elm$core$Task$andThen, tellTaggers, $elm$time$Time$now));
		}
	});
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$time$Time$subMap = F2(
	function (f, _v0) {
		var interval = _v0.a;
		var tagger = _v0.b;
		return A2(
			$elm$time$Time$Every,
			interval,
			A2($elm$core$Basics$composeL, f, tagger));
	});
_Platform_effectManagers['Time'] = _Platform_createManager($elm$time$Time$init, $elm$time$Time$onEffects, $elm$time$Time$onSelfMsg, 0, $elm$time$Time$subMap);
var $elm$time$Time$subscription = _Platform_leaf('Time');
var $elm$time$Time$every = F2(
	function (interval, tagger) {
		return $elm$time$Time$subscription(
			A2($elm$time$Time$Every, interval, tagger));
	});
var $author$project$Views$MapAssignment$Msg$CheckRouteRequest = function (a) {
	return {$: 93, a: a};
};
var $author$project$Views$MapAssignment$Msg$EnableOptimizeButtons = {$: 115};
var $author$project$Msg$LogOutUser = {$: 4};
var $author$project$Msg$MapAssignmentMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$Views$Reports$Msg$ReportCompleted = function (a) {
	return {$: 14, a: a};
};
var $author$project$Views$MapAssignment$Msg$RouteCompletedWSMessage = function (a) {
	return {$: 59, a: a};
};
var $author$project$Views$MapAssignment$Msg$RouteErroredWSMessage = function (a) {
	return {$: 61, a: a};
};
var $author$project$Views$MapAssignment$Msg$RouteInProgressWSMessage = function (a) {
	return {$: 60, a: a};
};
var $author$project$Views$MapAssignment$Msg$RouteJobInfoStored = function (a) {
	return {$: 95, a: a};
};
var $author$project$Views$MapAssignment$Msg$RouteUpdatedWSMessage = function (a) {
	return {$: 58, a: a};
};
var $author$project$Msg$SetUser = function (a) {
	return {$: 3, a: a};
};
var $author$project$Views$MapAssignment$Msg$SettingsExpired = function (a) {
	return {$: 114, a: a};
};
var $author$project$Views$MapAssignment$Msg$SettingsSaved = {$: 110};
var $author$project$Msg$UnknownUpsideDownMsg = {$: 11};
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $author$project$Ports$fromUpsideDown = _Platform_incomingPort(
	'fromUpsideDown',
	A2(
		$elm$json$Json$Decode$andThen,
		function (tag) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (data) {
					return $elm$json$Json$Decode$succeed(
						{e2: data, j: tag});
				},
				A2($elm$json$Json$Decode$field, 'data', $elm$json$Json$Decode$value));
		},
		A2($elm$json$Json$Decode$field, 'tag', $elm$json$Json$Decode$string)));
var $author$project$Ports$getInfoFromUpsideDown = $author$project$Ports$fromUpsideDown(
	function (_v0) {
		var tag = _v0.j;
		var data = _v0.e2;
		switch (tag) {
			case 'OnSessionChange':
				return $author$project$Msg$SetUser(
					$elm$core$Result$toMaybe(
						A2($elm$json$Json$Decode$decodeValue, $author$project$Data$User$decoder, data)));
			case 'OnSettingsSaved':
				return $author$project$Msg$MapAssignmentMsg($author$project$Views$MapAssignment$Msg$SettingsSaved);
			case 'ReportCompleted':
				return $author$project$Msg$ReportsMsg(
					$author$project$Views$Reports$Msg$ReportCompleted(data));
			case 'RouteUpdated':
				return $author$project$Msg$MapAssignmentMsg(
					$author$project$Views$MapAssignment$Msg$RouteUpdatedWSMessage(data));
			case 'RouteInProgress':
				return $author$project$Msg$MapAssignmentMsg(
					$author$project$Views$MapAssignment$Msg$RouteInProgressWSMessage(data));
			case 'RouteErrored':
				return $author$project$Msg$MapAssignmentMsg(
					$author$project$Views$MapAssignment$Msg$RouteErroredWSMessage(data));
			case 'RouteCompleted':
				return $author$project$Msg$MapAssignmentMsg(
					$author$project$Views$MapAssignment$Msg$RouteCompletedWSMessage(data));
			case 'OnSendJobRequest':
				return $author$project$Msg$MapAssignmentMsg(
					$author$project$Views$MapAssignment$Msg$CheckRouteRequest(data));
			case 'OnRouteJobInfoStored':
				return $author$project$Msg$MapAssignmentMsg(
					$author$project$Views$MapAssignment$Msg$RouteJobInfoStored(data));
			case 'OnSettingsExpired':
				return $author$project$Msg$MapAssignmentMsg(
					$author$project$Views$MapAssignment$Msg$SettingsExpired(data));
			case 'EnableOptimizeButtons':
				return $author$project$Msg$MapAssignmentMsg($author$project$Views$MapAssignment$Msg$EnableOptimizeButtons);
			case 'LogOutUser':
				return $author$project$Msg$LogOutUser;
			default:
				return $author$project$Msg$UnknownUpsideDownMsg;
		}
	});
var $elm$core$Platform$Sub$map = _Platform_map;
var $author$project$Subscription$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Msg$ContextMsg,
				A2($elm$time$Time$every, 10000, $author$project$Data$Context$Tick)),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Msg$ReportsMsg,
				A2($elm$time$Time$every, 10000, $author$project$Views$Reports$Msg$TickMsg)),
				$author$project$Ports$getInfoFromUpsideDown
			]));
};
var $author$project$Views$MapAssignment$Msg$FetchUpcomingOrders = function (a) {
	return {$: 0, a: a};
};
var $author$project$Route$Home = {$: 0};
var $author$project$Views$MapAssignment$Model$LoadingStarted = {$: 1};
var $author$project$Msg$LoginMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$MapArea = function (a) {
	return {$: 2, a: a};
};
var $author$project$Msg$MapAreaMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$MapAssignment = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Reports = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$RetailerRoundIntegration = function (a) {
	return {$: 5, a: a};
};
var $author$project$Msg$RetailerRoundIntegrationMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$Update$authTokenAboutToExpire = function (_v0) {
	var user = _v0.d7;
	var time = _v0.d_;
	if (user.$ === 1) {
		return false;
	} else {
		var tokenCreatedAt = user.a.d1;
		return (($elm$time$Time$posixToMillis(time) - $elm$time$Time$posixToMillis(tokenCreatedAt)) / ((1000 * 60) * 60)) > 46;
	}
};
var $author$project$Route$isAdminRoute = function (route) {
	switch (route.$) {
		case 0:
			return true;
		case 1:
			return true;
		case 2:
			return true;
		case 4:
			return false;
		case 3:
			return false;
		case 6:
			return false;
		case 7:
			return true;
		default:
			return false;
	}
};
var $author$project$Route$isDriverRoute = function (route) {
	switch (route.$) {
		case 0:
			return true;
		case 1:
			return true;
		case 2:
			return false;
		case 4:
			return false;
		case 3:
			return false;
		case 6:
			return true;
		case 7:
			return false;
		default:
			return false;
	}
};
var $author$project$Route$isFleetManagerRoute = function (route) {
	switch (route.$) {
		case 0:
			return true;
		case 1:
			return true;
		case 2:
			return true;
		case 4:
			return true;
		case 3:
			return true;
		case 6:
			return false;
		case 7:
			return false;
		default:
			return true;
	}
};
var $author$project$Route$isPublicRoute = function (route) {
	switch (route.$) {
		case 0:
			return false;
		case 1:
			return true;
		case 2:
			return false;
		case 4:
			return false;
		case 3:
			return false;
		case 6:
			return true;
		case 7:
			return false;
		default:
			return false;
	}
};
var $author$project$Route$canUserAccess = F2(
	function (maybeRoute, maybeUser) {
		var _v0 = _Utils_Tuple2(maybeRoute, maybeUser);
		if (!_v0.a.$) {
			if (!_v0.b.$) {
				var route = _v0.a.a;
				var user = _v0.b.a;
				var _v1 = user.aL;
				switch (_v1) {
					case 0:
						return $author$project$Route$isAdminRoute(route);
					case 1:
						return $author$project$Route$isFleetManagerRoute(route);
					case 3:
						return $author$project$Route$isFleetManagerRoute(route);
					default:
						return $author$project$Route$isDriverRoute(route);
				}
			} else {
				var route = _v0.a.a;
				var _v2 = _v0.b;
				return $author$project$Route$isPublicRoute(route);
			}
		} else {
			var _v3 = _v0.a;
			return false;
		}
	});
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {M: frag, N: params, L: unvisited, y: value, P: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.L;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.y);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.y);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.gR),
					$elm$url$Url$Parser$prepareQuery(url.g0),
					url.cd,
					$elm$core$Basics$identity)));
	});
var $author$project$Route$ForgotPassword = {$: 6};
var $author$project$Route$Login = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Route$Logout = {$: 2};
var $author$project$Route$MapArea = {$: 4};
var $author$project$Route$MapAssignment = {$: 3};
var $author$project$Route$Reports = {$: 7};
var $author$project$Route$RetailerRoundIntegration = function (a) {
	return {$: 5, a: a};
};
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.P;
		var unvisited = _v0.L;
		var params = _v0.N;
		var frag = _v0.M;
		var value = _v0.y;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0;
		return function (_v1) {
			var visited = _v1.P;
			var unvisited = _v1.L;
			var params = _v1.N;
			var frag = _v1.M;
			var value = _v1.y;
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$mapState(value),
				parseArg(
					A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			$elm$core$List$concatMap,
			function (_v0) {
				var parser = _v0;
				return parser(state);
			},
			parsers);
	};
};
var $elm$url$Url$Parser$query = function (_v0) {
	var queryParser = _v0;
	return function (_v1) {
		var visited = _v1.P;
		var unvisited = _v1.L;
		var params = _v1.N;
		var frag = _v1.M;
		var value = _v1.y;
		return _List_fromArray(
			[
				A5(
				$elm$url$Url$Parser$State,
				visited,
				unvisited,
				params,
				frag,
				value(
					queryParser(params)))
			]);
	};
};
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $elm$url$Url$Parser$questionMark = F2(
	function (parser, queryParser) {
		return A2(
			$elm$url$Url$Parser$slash,
			parser,
			$elm$url$Url$Parser$query(queryParser));
	});
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.P;
		var unvisited = _v0.L;
		var params = _v0.N;
		var frag = _v0.M;
		var value = _v0.y;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $elm$url$Url$Parser$custom = F2(
	function (tipe, stringToSomething) {
		return function (_v0) {
			var visited = _v0.P;
			var unvisited = _v0.L;
			var params = _v0.N;
			var frag = _v0.M;
			var value = _v0.y;
			if (!unvisited.b) {
				return _List_Nil;
			} else {
				var next = unvisited.a;
				var rest = unvisited.b;
				var _v2 = stringToSomething(next);
				if (!_v2.$) {
					var nextValue = _v2.a;
					return _List_fromArray(
						[
							A5(
							$elm$url$Url$Parser$State,
							A2($elm$core$List$cons, next, visited),
							rest,
							params,
							frag,
							value(nextValue))
						]);
				} else {
					return _List_Nil;
				}
			}
		};
	});
var $elm$url$Url$Parser$string = A2($elm$url$Url$Parser$custom, 'STRING', $elm$core$Maybe$Just);
var $elm$url$Url$Parser$Internal$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$Query$custom = F2(
	function (key, func) {
		return function (dict) {
			return func(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2($elm$core$Dict$get, key, dict)));
		};
	});
var $elm$url$Url$Parser$Query$string = function (key) {
	return A2(
		$elm$url$Url$Parser$Query$custom,
		key,
		function (stringList) {
			if (stringList.b && (!stringList.b.b)) {
				var str = stringList.a;
				return $elm$core$Maybe$Just(str);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		});
};
var $elm$url$Url$Parser$top = function (state) {
	return _List_fromArray(
		[state]);
};
var $author$project$Route$routeParser = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2($elm$url$Url$Parser$map, $author$project$Route$Home, $elm$url$Url$Parser$top),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Login,
			A2(
				$elm$url$Url$Parser$questionMark,
				A2(
					$elm$url$Url$Parser$questionMark,
					$elm$url$Url$Parser$s('login'),
					$elm$url$Url$Parser$Query$string('redirectPath')),
				$elm$url$Url$Parser$Query$string('token'))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Logout,
			$elm$url$Url$Parser$s('logout')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$MapAssignment,
			$elm$url$Url$Parser$s('map_assignment')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$MapArea,
			$elm$url$Url$Parser$s('map_area')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$RetailerRoundIntegration,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('routes'),
				$elm$url$Url$Parser$string)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$ForgotPassword,
			$elm$url$Url$Parser$s('forgot_password')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Reports,
			$elm$url$Url$Parser$s('reports'))
		]));
var $author$project$Route$fromUrl = function (url) {
	var _v0 = function (l) {
		return _Utils_Tuple2(
			A2(
				$elm$core$Maybe$withDefault,
				'',
				$elm$core$List$head(l)),
			$elm$core$List$head(
				A2($elm$core$List$drop, 1, l)));
	}(
		A2(
			$elm$core$String$split,
			'?',
			A2($elm$core$Maybe$withDefault, '', url.cd)));
	var path = _v0.a;
	var query = _v0.b;
	return A2(
		$elm$url$Url$Parser$parse,
		$author$project$Route$routeParser,
		_Utils_update(
			url,
			{cd: $elm$core$Maybe$Nothing, gR: path, g0: query}));
};
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $author$project$Request$HttpClient$authenticatedGet = F4(
	function (token, url, decoder, msg) {
		return $elm$http$Http$request(
			A5(
				$author$project$Request$HttpClient$requestParams,
				'GET',
				_List_fromArray(
					[
						A2($elm$http$Http$header, 'X-Authentication', token)
					]),
				url,
				$elm$http$Http$emptyBody,
				A2($elm$http$Http$expectJson, msg, decoder)));
	});
var $author$project$Data$Order$Order = function (id) {
	return function (paackOrderNumber) {
		return function (retailerOrderNumber) {
			return function (saleNumber) {
				return function (store) {
					return function (company) {
						return function (status) {
							return function (vehicle) {
								return function (deliverySlot) {
									return function (numOfPackages) {
										return function (orderType) {
											return function (pickupAddress) {
												return function (deliveryAddress) {
													return function (incidentCreatedAt) {
														return function (updatedAt) {
															return function (preDriver) {
																return function (driver) {
																	return function (position) {
																		return function (serviceType) {
																			return {eT: company, e6: deliveryAddress, e7: deliverySlot, fh: driver, fS: id, fU: incidentCreatedAt, gD: numOfPackages, gK: orderType, gO: paackOrderNumber, gT: pickupAddress, gW: position, g_: preDriver, g5: retailerOrderNumber, hf: saleNumber, hj: serviceType, ht: status, hw: store, hU: updatedAt, hX: vehicle};
																		};
																	};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Data$Address$Address = function (id) {
	return function (name) {
		return function (phone) {
			return function (email) {
				return function (title) {
					return function (postalCode) {
						return function (country) {
							return function (city) {
								return function (latlon) {
									return function (geoLocationAccuracy) {
										return {eL: city, e_: country, fn: email, fL: geoLocationAccuracy, fS: id, ga: latlon, gs: name, gS: phone, gX: postalCode, hH: title};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Data$Address$Unknown = 3;
var $author$project$Data$Address$Good = 0;
var $author$project$Data$Address$Low = 2;
var $author$project$Data$Address$Medium = 1;
var $author$project$Data$Address$accurayQualityDecoder = function (accuracyQualityStringDecoder) {
	return A2(
		$elm$json$Json$Decode$map,
		function (quality) {
			switch (quality) {
				case 'good':
					return 0;
				case 'medium':
					return 1;
				case 'low':
					return 2;
				default:
					return 3;
			}
		},
		accuracyQualityStringDecoder);
};
var $author$project$Data$Map$Coordinates = F2(
	function (lat, lng) {
		return {f8: lat, gb: lng};
	});
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $author$project$Data$Map$coordinatesDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2($elm$json$Json$Decode$field, 'longitude', $elm$json$Json$Decode$float),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'latitude', $elm$json$Json$Decode$float),
		$elm$json$Json$Decode$succeed($author$project$Data$Map$Coordinates)));
var $author$project$Data$Address$decoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2(
		$elm$json$Json$Decode$map,
		function (maybeQuality) {
			if (!maybeQuality.$) {
				var quality = maybeQuality.a;
				return quality;
			} else {
				return 3;
			}
		},
		$elm$json$Json$Decode$maybe(
			$author$project$Data$Address$accurayQualityDecoder(
				A2($elm$json$Json$Decode$field, 'accuracy_quality', $elm$json$Json$Decode$string)))),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2(
			$elm$json$Json$Decode$field,
			'latlon',
			$elm$json$Json$Decode$maybe($author$project$Data$Map$coordinatesDecoder)),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2(
				$elm$json$Json$Decode$field,
				'city',
				$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2(
					$elm$json$Json$Decode$field,
					'country',
					$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2(
						$elm$json$Json$Decode$field,
						'postal_code',
						$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2(
							$elm$json$Json$Decode$field,
							'title',
							$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2(
								$elm$json$Json$Decode$field,
								'email',
								$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2(
									$elm$json$Json$Decode$field,
									'phone',
									$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2(
										$elm$json$Json$Decode$field,
										'name',
										$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$int),
										$elm$json$Json$Decode$succeed($author$project$Data$Address$Address)))))))))));
var $author$project$Data$Company$Company = F2(
	function (id, name) {
		return {fS: id, gs: name};
	});
var $author$project$Data$Company$decoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$int),
		$elm$json$Json$Decode$succeed($author$project$Data$Company$Company)));
var $author$project$Data$Driver$Driver = F3(
	function (id, fullName, vehicle) {
		return {fJ: fullName, fS: id, hX: vehicle};
	});
var $author$project$Data$Driver$vehicleDecoder = function (vehicle) {
	return $elm$json$Json$Decode$succeed(
		function () {
			if (!vehicle.$) {
				var v = vehicle.a;
				switch (v) {
					case 0:
						return 0;
					case 1:
						return 1;
					case 2:
						return 2;
					case 3:
						return 3;
					default:
						return 3;
				}
			} else {
				return 3;
			}
		}());
};
var $author$project$Data$Driver$decoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2(
		$elm$json$Json$Decode$field,
		'vehicle',
		A2(
			$elm$json$Json$Decode$andThen,
			$author$project$Data$Driver$vehicleDecoder,
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int))),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'full_name', $elm$json$Json$Decode$string),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$int),
			$elm$json$Json$Decode$succeed($author$project$Data$Driver$Driver))));
var $author$project$Data$Store$Store = F4(
	function (id, name, company_id, address) {
		return {er: address, eU: company_id, fS: id, gs: name};
	});
var $author$project$Data$Store$decoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'address', $author$project$Data$Address$decoder)),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'company_id', $elm$json$Json$Decode$int),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$int),
				$elm$json$Json$Decode$succeed($author$project$Data$Store$Store)))));
var $author$project$Data$Order$DeliverySlot = F2(
	function (start, end) {
		return {fp: end, hq: start};
	});
var $author$project$Data$Order$deliverySlotDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2($elm$json$Json$Decode$field, 'end', $rtfeldman$elm_iso8601_date_strings$Iso8601$decoder),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'start', $rtfeldman$elm_iso8601_date_strings$Iso8601$decoder),
		$elm$json$Json$Decode$succeed($author$project$Data$Order$DeliverySlot)));
var $author$project$Data$Order$Collaborative = 2;
var $author$project$Data$Order$Direct = 0;
var $author$project$Data$Order$Reverse = 1;
var $author$project$Data$Order$orderTypeDecoder = function (orderType) {
	switch (orderType) {
		case 'collaborative':
			return $elm$json$Json$Decode$succeed(2);
		case 'reverse':
			return $elm$json$Json$Decode$succeed(1);
		default:
			return $elm$json$Json$Decode$succeed(0);
	}
};
var $author$project$Data$Order$Cancelled = 7;
var $author$project$Data$Order$Delivered = 4;
var $author$project$Data$Order$DeliveryAttempt = 9;
var $author$project$Data$Order$Draft = 0;
var $author$project$Data$Order$Oncourse = 2;
var $author$project$Data$Order$Pending = 1;
var $author$project$Data$Order$Pickedup = 3;
var $author$project$Data$Order$ReachedDelivered = 6;
var $author$project$Data$Order$ReachedPickedup = 5;
var $author$project$Data$Order$ReturnInProgress = 10;
var $author$project$Data$Order$UnDelivered = 8;
var $author$project$Data$Order$statusDecoder = function (state) {
	switch (state) {
		case 'draft':
			return $elm$json$Json$Decode$succeed(0);
		case 'pending':
			return $elm$json$Json$Decode$succeed(1);
		case 'on_course':
			return $elm$json$Json$Decode$succeed(2);
		case 'picked_up':
			return $elm$json$Json$Decode$succeed(3);
		case 'reached_picked_up':
			return $elm$json$Json$Decode$succeed(5);
		case 'reached_delivered':
			return $elm$json$Json$Decode$succeed(6);
		case 'delivered':
			return $elm$json$Json$Decode$succeed(4);
		case 'undelivered':
			return $elm$json$Json$Decode$succeed(8);
		case 'cancelled':
			return $elm$json$Json$Decode$succeed(7);
		case 'delivery_attempt':
			return $elm$json$Json$Decode$succeed(9);
		case 'return_in_progress':
			return $elm$json$Json$Decode$succeed(10);
		default:
			return $elm$json$Json$Decode$succeed(0);
	}
};
var $author$project$Data$Order$vehicleDecoder = function (vehicle) {
	return $elm$json$Json$Decode$succeed(
		function () {
			switch (vehicle) {
				case 'motorbike':
					return 0;
				case 'van':
					return 1;
				case 'bicycle':
					return 2;
				case 'truck':
					return 3;
				default:
					return 3;
			}
		}());
};
var $author$project$Data$Order$decoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2(
		$elm$json$Json$Decode$field,
		'service_type',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2(
			$elm$json$Json$Decode$field,
			'position',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2(
				$elm$json$Json$Decode$field,
				'driver',
				$elm$json$Json$Decode$maybe($author$project$Data$Driver$decoder)),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2(
					$elm$json$Json$Decode$field,
					'pre_driver',
					$elm$json$Json$Decode$maybe($author$project$Data$Driver$decoder)),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'updated_at', $rtfeldman$elm_iso8601_date_strings$Iso8601$decoder),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2(
							$elm$json$Json$Decode$field,
							'incident_created_at',
							$elm$json$Json$Decode$maybe($rtfeldman$elm_iso8601_date_strings$Iso8601$decoder)),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'delivery_address', $author$project$Data$Address$decoder),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'pickup_address', $author$project$Data$Address$decoder),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2(
										$elm$json$Json$Decode$field,
										'order_type',
										A2($elm$json$Json$Decode$andThen, $author$project$Data$Order$orderTypeDecoder, $elm$json$Json$Decode$string)),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'num_of_packages', $elm$json$Json$Decode$int),
										A2(
											$elm_community$json_extra$Json$Decode$Extra$andMap,
											A2($elm$json$Json$Decode$field, 'delivery_slot', $author$project$Data$Order$deliverySlotDecoder),
											A2(
												$elm_community$json_extra$Json$Decode$Extra$andMap,
												A2(
													$elm$json$Json$Decode$field,
													'vehicle',
													A2($elm$json$Json$Decode$andThen, $author$project$Data$Order$vehicleDecoder, $elm$json$Json$Decode$string)),
												A2(
													$elm_community$json_extra$Json$Decode$Extra$andMap,
													A2(
														$elm$json$Json$Decode$field,
														'status',
														A2($elm$json$Json$Decode$andThen, $author$project$Data$Order$statusDecoder, $elm$json$Json$Decode$string)),
													A2(
														$elm_community$json_extra$Json$Decode$Extra$andMap,
														A2(
															$elm$json$Json$Decode$field,
															'company',
															$elm$json$Json$Decode$maybe($author$project$Data$Company$decoder)),
														A2(
															$elm_community$json_extra$Json$Decode$Extra$andMap,
															A2(
																$elm$json$Json$Decode$field,
																'store',
																$elm$json$Json$Decode$maybe($author$project$Data$Store$decoder)),
															A2(
																$elm_community$json_extra$Json$Decode$Extra$andMap,
																A2(
																	$elm$json$Json$Decode$field,
																	'sale_number',
																	$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
																A2(
																	$elm_community$json_extra$Json$Decode$Extra$andMap,
																	A2(
																		$elm$json$Json$Decode$field,
																		'retailer_order_number',
																		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
																	A2(
																		$elm_community$json_extra$Json$Decode$Extra$andMap,
																		A2($elm$json$Json$Decode$field, 'paack_order_number', $elm$json$Json$Decode$string),
																		A2(
																			$elm_community$json_extra$Json$Decode$Extra$andMap,
																			A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$int),
																			$elm$json$Json$Decode$succeed($author$project$Data$Order$Order))))))))))))))))))));
var $author$project$Views$Common$Pagination$PaginationData = F3(
	function (totalPages, currentPage, count) {
		return {eZ: count, e1: currentPage, hN: totalPages};
	});
var $author$project$Views$Common$Pagination$decoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2($elm$json$Json$Decode$field, 'count', $elm$json$Json$Decode$int),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'current_page', $elm$json$Json$Decode$int),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'total_pages', $elm$json$Json$Decode$int),
			$elm$json$Json$Decode$succeed($author$project$Views$Common$Pagination$PaginationData))));
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $author$project$Request$Url$apiServiceUrl = function (env) {
	switch (env) {
		case 3:
			return '//api.service.paack.co/';
		case 1:
			return '//api.service.staging.paack.co/';
		case 2:
			return 'https://api.service.sandbox.paack.co/';
		default:
			return '//localhost:4001/';
	}
};
var $elm$core$String$fromFloat = _String_fromNumber;
var $elm_community$list_extra$List$Extra$zip = $elm$core$List$map2($elm$core$Tuple$pair);
var $author$project$Request$Order$boundParameters = function (_v0) {
	var lon1 = _v0.a;
	var lat1 = _v0.b;
	var lon2 = _v0.c;
	var lat2 = _v0.d;
	return A2(
		$elm$core$List$map,
		function (_v1) {
			var k = _v1.a;
			var v = _v1.b;
			return k + ('=' + v);
		},
		A2(
			$elm_community$list_extra$List$Extra$zip,
			_List_fromArray(
				['lat1', 'lon1', 'lat2', 'lon2']),
			A2(
				$elm$core$List$map,
				$elm$core$String$fromFloat,
				_List_fromArray(
					[lat1, lon1, lat2, lon2]))));
};
var $justinmimbs$time_extra$Time$Extra$Day = 11;
var $justinmimbs$date$Date$Days = 3;
var $justinmimbs$time_extra$Time$Extra$Millisecond = 15;
var $justinmimbs$time_extra$Time$Extra$Month = 2;
var $justinmimbs$date$Date$Months = 1;
var $justinmimbs$date$Date$RD = $elm$core$Basics$identity;
var $justinmimbs$date$Date$isLeapYear = function (y) {
	return ((!A2($elm$core$Basics$modBy, 4, y)) && (!(!A2($elm$core$Basics$modBy, 100, y)))) || (!A2($elm$core$Basics$modBy, 400, y));
};
var $justinmimbs$date$Date$daysBeforeMonth = F2(
	function (y, m) {
		var leapDays = $justinmimbs$date$Date$isLeapYear(y) ? 1 : 0;
		switch (m) {
			case 0:
				return 0;
			case 1:
				return 31;
			case 2:
				return 59 + leapDays;
			case 3:
				return 90 + leapDays;
			case 4:
				return 120 + leapDays;
			case 5:
				return 151 + leapDays;
			case 6:
				return 181 + leapDays;
			case 7:
				return 212 + leapDays;
			case 8:
				return 243 + leapDays;
			case 9:
				return 273 + leapDays;
			case 10:
				return 304 + leapDays;
			default:
				return 334 + leapDays;
		}
	});
var $justinmimbs$date$Date$floorDiv = F2(
	function (a, b) {
		return $elm$core$Basics$floor(a / b);
	});
var $justinmimbs$date$Date$daysBeforeYear = function (y1) {
	var y = y1 - 1;
	var leapYears = (A2($justinmimbs$date$Date$floorDiv, y, 4) - A2($justinmimbs$date$Date$floorDiv, y, 100)) + A2($justinmimbs$date$Date$floorDiv, y, 400);
	return (365 * y) + leapYears;
};
var $justinmimbs$date$Date$daysInMonth = F2(
	function (y, m) {
		switch (m) {
			case 0:
				return 31;
			case 1:
				return $justinmimbs$date$Date$isLeapYear(y) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $justinmimbs$date$Date$monthToNumber = function (m) {
	switch (m) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $justinmimbs$date$Date$numberToMonth = function (mn) {
	var _v0 = A2($elm$core$Basics$max, 1, mn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		case 7:
			return 6;
		case 8:
			return 7;
		case 9:
			return 8;
		case 10:
			return 9;
		case 11:
			return 10;
		default:
			return 11;
	}
};
var $justinmimbs$date$Date$toCalendarDateHelp = F3(
	function (y, m, d) {
		toCalendarDateHelp:
		while (true) {
			var monthDays = A2($justinmimbs$date$Date$daysInMonth, y, m);
			var mn = $justinmimbs$date$Date$monthToNumber(m);
			if ((mn < 12) && (_Utils_cmp(d, monthDays) > 0)) {
				var $temp$y = y,
					$temp$m = $justinmimbs$date$Date$numberToMonth(mn + 1),
					$temp$d = d - monthDays;
				y = $temp$y;
				m = $temp$m;
				d = $temp$d;
				continue toCalendarDateHelp;
			} else {
				return {bT: d, cQ: m, ej: y};
			}
		}
	});
var $justinmimbs$date$Date$divWithRemainder = F2(
	function (a, b) {
		return _Utils_Tuple2(
			A2($justinmimbs$date$Date$floorDiv, a, b),
			A2($elm$core$Basics$modBy, b, a));
	});
var $justinmimbs$date$Date$year = function (_v0) {
	var rd = _v0;
	var _v1 = A2($justinmimbs$date$Date$divWithRemainder, rd, 146097);
	var n400 = _v1.a;
	var r400 = _v1.b;
	var _v2 = A2($justinmimbs$date$Date$divWithRemainder, r400, 36524);
	var n100 = _v2.a;
	var r100 = _v2.b;
	var _v3 = A2($justinmimbs$date$Date$divWithRemainder, r100, 1461);
	var n4 = _v3.a;
	var r4 = _v3.b;
	var _v4 = A2($justinmimbs$date$Date$divWithRemainder, r4, 365);
	var n1 = _v4.a;
	var r1 = _v4.b;
	var n = (!r1) ? 0 : 1;
	return ((((n400 * 400) + (n100 * 100)) + (n4 * 4)) + n1) + n;
};
var $justinmimbs$date$Date$toOrdinalDate = function (_v0) {
	var rd = _v0;
	var y = $justinmimbs$date$Date$year(rd);
	return {
		a8: rd - $justinmimbs$date$Date$daysBeforeYear(y),
		ej: y
	};
};
var $justinmimbs$date$Date$toCalendarDate = function (_v0) {
	var rd = _v0;
	var date = $justinmimbs$date$Date$toOrdinalDate(rd);
	return A3($justinmimbs$date$Date$toCalendarDateHelp, date.ej, 0, date.a8);
};
var $justinmimbs$date$Date$add = F3(
	function (unit, n, _v0) {
		var rd = _v0;
		switch (unit) {
			case 0:
				return A3($justinmimbs$date$Date$add, 1, 12 * n, rd);
			case 1:
				var date = $justinmimbs$date$Date$toCalendarDate(rd);
				var wholeMonths = ((12 * (date.ej - 1)) + ($justinmimbs$date$Date$monthToNumber(date.cQ) - 1)) + n;
				var m = $justinmimbs$date$Date$numberToMonth(
					A2($elm$core$Basics$modBy, 12, wholeMonths) + 1);
				var y = A2($justinmimbs$date$Date$floorDiv, wholeMonths, 12) + 1;
				return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A2(
					$elm$core$Basics$min,
					date.bT,
					A2($justinmimbs$date$Date$daysInMonth, y, m));
			case 2:
				return rd + (7 * n);
			default:
				return rd + n;
		}
	});
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $justinmimbs$date$Date$fromCalendarDate = F3(
	function (y, m, d) {
		return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A3(
			$elm$core$Basics$clamp,
			1,
			A2($justinmimbs$date$Date$daysInMonth, y, m),
			d);
	});
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.hq, posixMinutes) < 0) {
					return posixMinutes + era.cV;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$core$Basics$ge = _Utils_ge;
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		bT: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		cQ: month,
		ej: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).bT;
	});
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).cQ;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).ej;
	});
var $justinmimbs$date$Date$fromPosix = F2(
	function (zone, posix) {
		return A3(
			$justinmimbs$date$Date$fromCalendarDate,
			A2($elm$time$Time$toYear, zone, posix),
			A2($elm$time$Time$toMonth, zone, posix),
			A2($elm$time$Time$toDay, zone, posix));
	});
var $justinmimbs$date$Date$toRataDie = function (_v0) {
	var rd = _v0;
	return rd;
};
var $justinmimbs$time_extra$Time$Extra$dateToMillis = function (date) {
	var daysSinceEpoch = $justinmimbs$date$Date$toRataDie(date) - 719163;
	return daysSinceEpoch * 86400000;
};
var $justinmimbs$time_extra$Time$Extra$timeFromClock = F4(
	function (hour, minute, second, millisecond) {
		return (((hour * 3600000) + (minute * 60000)) + (second * 1000)) + millisecond;
	});
var $elm$time$Time$toHour = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			24,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60));
	});
var $elm$time$Time$toMillis = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			1000,
			$elm$time$Time$posixToMillis(time));
	});
var $elm$time$Time$toMinute = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2($elm$time$Time$toAdjustedMinutes, zone, time));
	});
var $elm$time$Time$toSecond = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				1000));
	});
var $justinmimbs$time_extra$Time$Extra$timeFromPosix = F2(
	function (zone, posix) {
		return A4(
			$justinmimbs$time_extra$Time$Extra$timeFromClock,
			A2($elm$time$Time$toHour, zone, posix),
			A2($elm$time$Time$toMinute, zone, posix),
			A2($elm$time$Time$toSecond, zone, posix),
			A2($elm$time$Time$toMillis, zone, posix));
	});
var $justinmimbs$time_extra$Time$Extra$toOffset = F2(
	function (zone, posix) {
		var millis = $elm$time$Time$posixToMillis(posix);
		var localMillis = $justinmimbs$time_extra$Time$Extra$dateToMillis(
			A2($justinmimbs$date$Date$fromPosix, zone, posix)) + A2($justinmimbs$time_extra$Time$Extra$timeFromPosix, zone, posix);
		return ((localMillis - millis) / 60000) | 0;
	});
var $justinmimbs$time_extra$Time$Extra$posixFromDateTime = F3(
	function (zone, date, time) {
		var millis = $justinmimbs$time_extra$Time$Extra$dateToMillis(date) + time;
		var offset0 = A2(
			$justinmimbs$time_extra$Time$Extra$toOffset,
			zone,
			$elm$time$Time$millisToPosix(millis));
		var posix1 = $elm$time$Time$millisToPosix(millis - (offset0 * 60000));
		var offset1 = A2($justinmimbs$time_extra$Time$Extra$toOffset, zone, posix1);
		if (_Utils_eq(offset0, offset1)) {
			return posix1;
		} else {
			var posix2 = $elm$time$Time$millisToPosix(millis - (offset1 * 60000));
			var offset2 = A2($justinmimbs$time_extra$Time$Extra$toOffset, zone, posix2);
			return _Utils_eq(offset1, offset2) ? posix2 : posix1;
		}
	});
var $justinmimbs$time_extra$Time$Extra$add = F4(
	function (interval, n, zone, posix) {
		add:
		while (true) {
			switch (interval) {
				case 15:
					return $elm$time$Time$millisToPosix(
						$elm$time$Time$posixToMillis(posix) + n);
				case 14:
					var $temp$interval = 15,
						$temp$n = n * 1000,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
				case 13:
					var $temp$interval = 15,
						$temp$n = n * 60000,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
				case 12:
					var $temp$interval = 15,
						$temp$n = n * 3600000,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
				case 11:
					return A3(
						$justinmimbs$time_extra$Time$Extra$posixFromDateTime,
						zone,
						A3(
							$justinmimbs$date$Date$add,
							3,
							n,
							A2($justinmimbs$date$Date$fromPosix, zone, posix)),
						A2($justinmimbs$time_extra$Time$Extra$timeFromPosix, zone, posix));
				case 2:
					return A3(
						$justinmimbs$time_extra$Time$Extra$posixFromDateTime,
						zone,
						A3(
							$justinmimbs$date$Date$add,
							1,
							n,
							A2($justinmimbs$date$Date$fromPosix, zone, posix)),
						A2($justinmimbs$time_extra$Time$Extra$timeFromPosix, zone, posix));
				case 0:
					var $temp$interval = 2,
						$temp$n = n * 12,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
				case 1:
					var $temp$interval = 2,
						$temp$n = n * 3,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
				case 3:
					var $temp$interval = 11,
						$temp$n = n * 7,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
				default:
					var weekday = interval;
					var $temp$interval = 11,
						$temp$n = n * 7,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
			}
		}
	});
var $justinmimbs$date$Date$Day = 11;
var $justinmimbs$date$Date$Friday = 8;
var $justinmimbs$date$Date$Monday = 4;
var $justinmimbs$date$Date$Month = 2;
var $justinmimbs$date$Date$Quarter = 1;
var $justinmimbs$date$Date$Saturday = 9;
var $justinmimbs$date$Date$Sunday = 10;
var $justinmimbs$date$Date$Thursday = 7;
var $justinmimbs$date$Date$Tuesday = 5;
var $justinmimbs$date$Date$Wednesday = 6;
var $justinmimbs$date$Date$Week = 3;
var $justinmimbs$date$Date$Year = 0;
var $elm$time$Time$Fri = 4;
var $elm$time$Time$Mon = 0;
var $elm$time$Time$Sat = 5;
var $elm$time$Time$Sun = 6;
var $elm$time$Time$Thu = 3;
var $elm$time$Time$Tue = 1;
var $elm$time$Time$Wed = 2;
var $justinmimbs$date$Date$weekdayNumber = function (_v0) {
	var rd = _v0;
	var _v1 = A2($elm$core$Basics$modBy, 7, rd);
	if (!_v1) {
		return 7;
	} else {
		var n = _v1;
		return n;
	}
};
var $justinmimbs$date$Date$weekdayToNumber = function (wd) {
	switch (wd) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		default:
			return 7;
	}
};
var $justinmimbs$date$Date$daysSincePreviousWeekday = F2(
	function (wd, date) {
		return A2(
			$elm$core$Basics$modBy,
			7,
			($justinmimbs$date$Date$weekdayNumber(date) + 7) - $justinmimbs$date$Date$weekdayToNumber(wd));
	});
var $justinmimbs$date$Date$firstOfMonth = F2(
	function (y, m) {
		return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + 1;
	});
var $justinmimbs$date$Date$firstOfYear = function (y) {
	return $justinmimbs$date$Date$daysBeforeYear(y) + 1;
};
var $justinmimbs$date$Date$month = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.cQ;
	});
var $justinmimbs$date$Date$monthToQuarter = function (m) {
	return (($justinmimbs$date$Date$monthToNumber(m) + 2) / 3) | 0;
};
var $justinmimbs$date$Date$quarter = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToQuarter);
var $justinmimbs$date$Date$quarterToMonth = function (q) {
	return $justinmimbs$date$Date$numberToMonth((q * 3) - 2);
};
var $justinmimbs$date$Date$floor = F2(
	function (interval, date) {
		var rd = date;
		switch (interval) {
			case 0:
				return $justinmimbs$date$Date$firstOfYear(
					$justinmimbs$date$Date$year(date));
			case 1:
				return A2(
					$justinmimbs$date$Date$firstOfMonth,
					$justinmimbs$date$Date$year(date),
					$justinmimbs$date$Date$quarterToMonth(
						$justinmimbs$date$Date$quarter(date)));
			case 2:
				return A2(
					$justinmimbs$date$Date$firstOfMonth,
					$justinmimbs$date$Date$year(date),
					$justinmimbs$date$Date$month(date));
			case 3:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 0, date);
			case 4:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 0, date);
			case 5:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 1, date);
			case 6:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 2, date);
			case 7:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 3, date);
			case 8:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 4, date);
			case 9:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 5, date);
			case 10:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 6, date);
			default:
				return date;
		}
	});
var $justinmimbs$time_extra$Time$Extra$floorDate = F3(
	function (dateInterval, zone, posix) {
		return A3(
			$justinmimbs$time_extra$Time$Extra$posixFromDateTime,
			zone,
			A2(
				$justinmimbs$date$Date$floor,
				dateInterval,
				A2($justinmimbs$date$Date$fromPosix, zone, posix)),
			0);
	});
var $justinmimbs$time_extra$Time$Extra$floor = F3(
	function (interval, zone, posix) {
		switch (interval) {
			case 15:
				return posix;
			case 14:
				return A3(
					$justinmimbs$time_extra$Time$Extra$posixFromDateTime,
					zone,
					A2($justinmimbs$date$Date$fromPosix, zone, posix),
					A4(
						$justinmimbs$time_extra$Time$Extra$timeFromClock,
						A2($elm$time$Time$toHour, zone, posix),
						A2($elm$time$Time$toMinute, zone, posix),
						A2($elm$time$Time$toSecond, zone, posix),
						0));
			case 13:
				return A3(
					$justinmimbs$time_extra$Time$Extra$posixFromDateTime,
					zone,
					A2($justinmimbs$date$Date$fromPosix, zone, posix),
					A4(
						$justinmimbs$time_extra$Time$Extra$timeFromClock,
						A2($elm$time$Time$toHour, zone, posix),
						A2($elm$time$Time$toMinute, zone, posix),
						0,
						0));
			case 12:
				return A3(
					$justinmimbs$time_extra$Time$Extra$posixFromDateTime,
					zone,
					A2($justinmimbs$date$Date$fromPosix, zone, posix),
					A4(
						$justinmimbs$time_extra$Time$Extra$timeFromClock,
						A2($elm$time$Time$toHour, zone, posix),
						0,
						0,
						0));
			case 11:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 11, zone, posix);
			case 2:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 2, zone, posix);
			case 0:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 0, zone, posix);
			case 1:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 1, zone, posix);
			case 3:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 3, zone, posix);
			case 4:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 4, zone, posix);
			case 5:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 5, zone, posix);
			case 6:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 6, zone, posix);
			case 7:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 7, zone, posix);
			case 8:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 8, zone, posix);
			case 9:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 9, zone, posix);
			default:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 10, zone, posix);
		}
	});
var $justinmimbs$time_extra$Time$Extra$ceiling = F3(
	function (interval, zone, posix) {
		var floored = A3($justinmimbs$time_extra$Time$Extra$floor, interval, zone, posix);
		return _Utils_eq(floored, posix) ? posix : A4($justinmimbs$time_extra$Time$Extra$add, interval, 1, zone, floored);
	});
var $author$project$Request$Order$getTodaysTimeBounds = function (_v0) {
	var time = _v0.d_;
	var zone = _v0.em;
	var millisToSeconds = function (date) {
		return function (d) {
			return (d / 1000) | 0;
		}(
			$elm$time$Time$posixToMillis(date));
	};
	var start = millisToSeconds(
		A3($justinmimbs$time_extra$Time$Extra$floor, 11, zone, time));
	var end = millisToSeconds(
		A3($justinmimbs$time_extra$Time$Extra$ceiling, 11, zone, time));
	return _Utils_Tuple2(start, end);
};
var $author$project$Request$Order$upcomingOrdersUrl = F3(
	function (context, bounds, page) {
		var _v0 = $author$project$Request$Order$getTodaysTimeBounds(context);
		var dayStart = _v0.a;
		var dayEnd = _v0.b;
		var pageParams = A2(
			$elm$core$List$map,
			$elm$core$String$join('='),
			_List_fromArray(
				[
					_List_fromArray(
					[
						'page',
						$elm$core$String$fromInt(page)
					]),
					_List_fromArray(
					['page_size', '400']),
					_List_fromArray(
					['states', 'draft,pending']),
					_List_fromArray(
					[
						'start_date',
						$elm$core$String$fromInt(dayStart)
					]),
					_List_fromArray(
					[
						'end_date',
						$elm$core$String$fromInt(dayEnd)
					])
				]));
		var query = A2(
			$elm$core$String$append,
			'?',
			A2(
				$elm$core$String$join,
				'&',
				A2(
					$elm$core$List$append,
					pageParams,
					$author$project$Request$Order$boundParameters(bounds))));
		return $author$project$Request$Url$apiServiceUrl(context.fr) + ('api/v2/orders/fleet_upcoming_orders' + query);
	});
var $author$project$Request$Order$loadUpcomingOrders = F4(
	function (context, bounds, page, msg) {
		return A4(
			$author$project$Request$HttpClient$authenticatedGet,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.bs;
					},
					context.d7)),
			A3($author$project$Request$Order$upcomingOrdersUrl, context, bounds, page),
			A3(
				$elm$json$Json$Decode$map2,
				$elm$core$Tuple$pair,
				A2(
					$elm$json$Json$Decode$field,
					'data',
					$elm$json$Json$Decode$list($author$project$Data$Order$decoder)),
				A2($elm$json$Json$Decode$field, 'meta', $author$project$Views$Common$Pagination$decoder)),
			msg);
	});
var $author$project$Ports$SaveSettings = function (a) {
	return {$: 1, a: a};
};
var $author$project$Ports$StoreSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Route$loginRouteWithoutParams = A2($author$project$Route$Login, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
var $elm$browser$Browser$Navigation$replaceUrl = _Browser_replaceUrl;
var $author$project$Route$routeToString = function (route) {
	var pieces = function () {
		switch (route.$) {
			case 0:
				return _List_fromArray(
					['']);
			case 1:
				return _List_fromArray(
					['login']);
			case 6:
				return _List_fromArray(
					['forgot_password']);
			case 2:
				return _List_fromArray(
					['logout']);
			case 3:
				return _List_fromArray(
					['map_assignment']);
			case 4:
				return _List_fromArray(
					['map_area']);
			case 7:
				return _List_fromArray(
					['reports']);
			default:
				var retailer = route.a;
				return _List_fromArray(
					['routes', retailer]);
		}
	}();
	return '#/' + A2($elm$core$String$join, '/', pieces);
};
var $author$project$Route$modifyUrl = F2(
	function (key, route) {
		return A2(
			$elm$browser$Browser$Navigation$replaceUrl,
			key,
			$author$project$Route$routeToString(route));
	});
var $author$project$Data$Context$updateSettings = F2(
	function (mSettings, ctx) {
		return _Utils_update(
			ctx,
			{dB: mSettings});
	});
var $author$project$Data$Context$updateUser = F2(
	function (mUser, ctx) {
		return _Utils_update(
			ctx,
			{d7: mUser});
	});
var $author$project$SessionUtils$logoutAndRedirectsToLogin = function (model) {
	return A2(
		$Fresheyeball$elm_return$Return$command,
		$author$project$Ports$sendMsgToUpsideDown(
			$author$project$Ports$SaveSettings($elm$core$Maybe$Nothing)),
		A2(
			$Fresheyeball$elm_return$Return$command,
			$author$project$Ports$sendMsgToUpsideDown(
				$author$project$Ports$StoreSession($elm$core$Maybe$Nothing)),
			A2(
				$Fresheyeball$elm_return$Return$command,
				A2($author$project$Route$modifyUrl, model.f5, $author$project$Route$loginRouteWithoutParams),
				$Fresheyeball$elm_return$Return$singleton(
					_Utils_update(
						model,
						{
							eW: A2(
								$author$project$Data$Context$updateSettings,
								$elm$core$Maybe$Nothing,
								A2($author$project$Data$Context$updateUser, $elm$core$Maybe$Nothing, model.eW))
						})))));
};
var $Fresheyeball$elm_return$Return$map = F2(
	function (f, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		return _Utils_Tuple2(
			f(model),
			cmd);
	});
var $Fresheyeball$elm_return$Return$mapCmd = F2(
	function (f, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		return _Utils_Tuple2(
			model,
			A2($elm$core$Platform$Cmd$map, f, cmd));
	});
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $author$project$Route$redirectForbiddenAccess = F2(
	function (key, maybeUser) {
		if (!maybeUser.$) {
			return A2($author$project$Route$modifyUrl, key, $author$project$Route$Home);
		} else {
			return A2(
				$author$project$Route$modifyUrl,
				key,
				A2($author$project$Route$Login, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing));
		}
	});
var $author$project$Page$ForgotPassword = function (a) {
	return {$: 1, a: a};
};
var $author$project$Data$Country$ES = 2;
var $author$project$Data$Country$FR = 4;
var $author$project$Data$Country$PT = 1;
var $author$project$Data$Country$UK = 3;
var $author$project$Data$Country$US = 0;
var $author$project$Data$Country$all = _List_fromArray(
	[0, 1, 2, 3, 4]);
var $author$project$Views$Reports$AdvancedFilters$AdvancedFilters = $elm$core$Basics$identity;
var $author$project$Views$Reports$AdvancedFilters$Closed = {$: 0};
var $author$project$Views$Reports$AdvancedFilters$closed = $author$project$Views$Reports$AdvancedFilters$Closed;
var $author$project$Views$Reports$Model$initReportForm = {aQ: $author$project$Views$Reports$AdvancedFilters$closed, ar: $elm$core$Maybe$Nothing, ah: 'range', aO: $elm$core$Maybe$Nothing};
var $author$project$Data$Report$reportTypes = _List_fromArray(
	['range', 'day', 'location']);
var $author$project$Views$Reports$Model$initialModel = function (_v0) {
	var time = _v0.d_;
	return {ao: $author$project$Data$Report$reportTypes, eS: _List_Nil, bS: $author$project$Data$Country$all, cc: 2, cs: false, cv: true, cy: true, cD: time, cS: _List_Nil, ba: $author$project$Views$Reports$Model$initReportForm, dh: 0, di: $elm$core$Maybe$Nothing, dj: $elm$core$Maybe$Nothing, dk: _List_Nil, dl: $elm$core$Maybe$Nothing, dV: _List_Nil};
};
var $author$project$Views$Reports$Msg$FetchCompanies = function (a) {
	return {$: 1, a: a};
};
var $author$project$Views$Reports$Msg$FetchStores = function (a) {
	return {$: 2, a: a};
};
var $author$project$Request$Company$fetchCompaniesUrl = function (env) {
	return $author$project$Request$Url$apiServiceUrl(env) + 'api/v2/companies';
};
var $author$project$Request$Company$fetchCompanies = F2(
	function (_v0, msg) {
		var user = _v0.d7;
		var env = _v0.fr;
		return A4(
			$author$project$Request$HttpClient$authenticatedGet,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.bs;
					},
					user)),
			$author$project$Request$Company$fetchCompaniesUrl(env),
			$elm$json$Json$Decode$list($author$project$Data$Company$decoder),
			msg);
	});
var $author$project$Request$Store$fetchStoresUrl = function (env) {
	return $author$project$Request$Url$apiServiceUrl(env) + 'api/v2/stores/list';
};
var $author$project$Request$Store$fetchStores = F2(
	function (_v0, msg) {
		var user = _v0.d7;
		var env = _v0.fr;
		return A4(
			$author$project$Request$HttpClient$authenticatedGet,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.bs;
					},
					user)),
			$author$project$Request$Store$fetchStoresUrl(env),
			$elm$json$Json$Decode$list($author$project$Data$Store$decoder),
			msg);
	});
var $author$project$Views$Reports$Model$reportFormRequests = function (context) {
	return _List_fromArray(
		[
			A2($author$project$Request$Company$fetchCompanies, context, $author$project$Views$Reports$Msg$FetchCompanies),
			A2($author$project$Request$Store$fetchStores, context, $author$project$Views$Reports$Msg$FetchStores)
		]);
};
var $author$project$Views$Reports$Msg$FetchCurrentJobs = function (a) {
	return {$: 4, a: a};
};
var $author$project$Views$Reports$Msg$FetchReports = function (a) {
	return {$: 3, a: a};
};
var $author$project$Data$Report$ReportJob = F3(
	function (id, type_, status) {
		return {fS: id, ht: status, d5: type_};
	});
var $elm$json$Json$Decode$map3 = _Json_map3;
var $author$project$Request$Report$reportJobDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Data$Report$ReportJob,
	A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'status', $elm$json$Json$Decode$string));
var $author$project$Request$Report$reportJobsUrl = function (env) {
	return $author$project$Request$Url$apiRailsUrl(env) + 'api/v2/reports/operations';
};
var $author$project$Request$Report$fetchCurrentJobs = F2(
	function (_v0, msg) {
		var user = _v0.d7;
		var env = _v0.fr;
		return A4(
			$author$project$Request$HttpClient$authenticatedGet,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.bs;
					},
					user)),
			$author$project$Request$Report$reportJobsUrl(env),
			A2(
				$elm$json$Json$Decode$at,
				_List_fromArray(
					['data']),
				$elm$json$Json$Decode$list($author$project$Request$Report$reportJobDecoder)),
			msg);
	});
var $author$project$Data$Report$Report = F5(
	function (id, fileName, type_, createdAt, url) {
		return {e$: createdAt, fC: fileName, fS: id, d5: type_, hV: url};
	});
var $elm$json$Json$Decode$map5 = _Json_map5;
var $author$project$Request$Report$reportDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Data$Report$Report,
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['id']),
		$elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['file_name']),
		$elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['metadata']),
		$elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$andThen,
		function (i) {
			return $elm$json$Json$Decode$succeed(
				$elm$time$Time$millisToPosix(i * 1000));
		},
		A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['created_at']),
			$elm$json$Json$Decode$int)),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['url']),
		$elm$json$Json$Decode$string));
var $author$project$Request$Report$reportsUrl = function (env) {
	return $author$project$Request$Url$apiRailsUrl(env) + 'api/v2/reports';
};
var $author$project$Request$Report$fetchReports = F2(
	function (_v0, msg) {
		var user = _v0.d7;
		var env = _v0.fr;
		return A4(
			$author$project$Request$HttpClient$authenticatedGet,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.bs;
					},
					user)),
			$author$project$Request$Report$reportsUrl(env),
			A2(
				$elm$json$Json$Decode$at,
				_List_fromArray(
					['data']),
				$elm$json$Json$Decode$list($author$project$Request$Report$reportDecoder)),
			msg);
	});
var $author$project$Views$Reports$Model$reportListRequests = function (context) {
	return _List_fromArray(
		[
			A2($author$project$Request$Report$fetchCurrentJobs, context, $author$project$Views$Reports$Msg$FetchCurrentJobs),
			A2($author$project$Request$Report$fetchReports, context, $author$project$Views$Reports$Msg$FetchReports)
		]);
};
var $author$project$Views$Reports$Model$init = function (context) {
	return _Utils_Tuple2(
		$author$project$Views$Reports$Model$initialModel(context),
		$elm$core$Platform$Cmd$batch(
			A2(
				$elm$core$List$append,
				$author$project$Views$Reports$Model$reportListRequests(context),
				$author$project$Views$Reports$Model$reportFormRequests(context))));
};
var $author$project$Views$RetailerRoundIntegration$Model$LoadingRoutes = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$Views$RetailerRoundIntegration$Model$RetailerNotSupported = {$: 2};
var $author$project$Views$RetailerRoundIntegration$Msg$FetchRoutes = function (a) {
	return {$: 0, a: a};
};
var $krisajenkins$remotedata$RemoteData$Failure = function (a) {
	return {$: 2, a: a};
};
var $krisajenkins$remotedata$RemoteData$Success = function (a) {
	return {$: 3, a: a};
};
var $krisajenkins$remotedata$RemoteData$fromResult = function (result) {
	if (result.$ === 1) {
		var e = result.a;
		return $krisajenkins$remotedata$RemoteData$Failure(e);
	} else {
		var x = result.a;
		return $krisajenkins$remotedata$RemoteData$Success(x);
	}
};
var $author$project$Data$Route$Route = F9(
	function (id, name, orders, driver, createdAt, data, status, tags, center) {
		return {eH: center, e$: createdAt, e2: data, fh: driver, fS: id, gs: name, gL: orders, ht: status, hE: tags};
	});
var $author$project$Data$Map$coordinatesCenterOf = function (list) {
	var coordinatesSum = F2(
		function (_v2, _v3) {
			var lat = _v2.f8;
			var lng = _v2.gb;
			var accu = _v3.a;
			var count = _v3.b;
			return _Utils_Tuple2(
				{f8: lat + accu.f8, gb: lng + accu.gb},
				count + 1);
		});
	var coordinatesProduct = function (_v1) {
		var lat = _v1.a.f8;
		var lng = _v1.a.gb;
		var count = _v1.b;
		return {f8: lat / count, gb: lng / count};
	};
	if (list.b) {
		var head = list.a;
		var tail = list.b;
		return $elm$core$Maybe$Just(
			coordinatesProduct(
				A3(
					$elm$core$List$foldl,
					coordinatesSum,
					_Utils_Tuple2(head, 1),
					tail)));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Data$Route$centerDecoder = function (unprocessedRoute) {
	return unprocessedRoute(
		$author$project$Data$Map$coordinatesCenterOf(
			A2(
				$elm$core$List$filterMap,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.e6;
					},
					function ($) {
						return $.ga;
					}),
				unprocessedRoute($elm$core$Maybe$Nothing).gL)));
};
var $elm_community$json_extra$Json$Decode$Extra$optionalField = F2(
	function (fieldName, decoder) {
		var finishDecoding = function (json) {
			var _v0 = A2(
				$elm$json$Json$Decode$decodeValue,
				A2($elm$json$Json$Decode$field, fieldName, $elm$json$Json$Decode$value),
				json);
			if (!_v0.$) {
				var val = _v0.a;
				return A2(
					$elm$json$Json$Decode$map,
					$elm$core$Maybe$Just,
					A2($elm$json$Json$Decode$field, fieldName, decoder));
			} else {
				return $elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing);
			}
		};
		return A2($elm$json$Json$Decode$andThen, finishDecoding, $elm$json$Json$Decode$value);
	});
var $author$project$Data$Route$Complete = 1;
var $author$project$Data$Route$Deleted = 5;
var $author$project$Data$Route$Draft = 0;
var $author$project$Data$Route$Error = 3;
var $author$project$Data$Route$Hidden = 4;
var $author$project$Data$Route$InProgress = 2;
var $author$project$Data$Route$routeStatusDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		switch (str) {
			case 'draft':
				return $elm$json$Json$Decode$succeed(0);
			case 'complete':
				return $elm$json$Json$Decode$succeed(1);
			case 'in_progress':
				return $elm$json$Json$Decode$succeed(2);
			case 'error':
				return $elm$json$Json$Decode$succeed(3);
			case 'deleted':
				return $elm$json$Json$Decode$succeed(5);
			case 'hidden':
				return $elm$json$Json$Decode$succeed(4);
			default:
				return $elm$json$Json$Decode$fail('Couldn\'t decode routeStatus type');
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Data$RouteResponse$Solution = function (costs) {
	return function (distance) {
		return function (time) {
			return function (transportTime) {
				return function (completionTime) {
					return function (maxOperationTime) {
						return function (waitingTime) {
							return function (serviceDuration) {
								return function (preparationTime) {
									return function (noVehicles) {
										return function (noUnassigned) {
											return function (routes) {
												return function (id) {
													return {bN: completionTime, eY: costs, ab: distance, fS: id, gk: maxOperationTime, gu: noUnassigned, gv: noVehicles, a9: preparationTime, hc: routes, dJ: serviceDuration, d_: time, d4: transportTime, bp: waitingTime};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder = F3(
	function (path, valDecoder, fallback) {
		var nullOr = function (decoder) {
			return $elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						decoder,
						$elm$json$Json$Decode$null(fallback)
					]));
		};
		var handleResult = function (input) {
			var _v0 = A2(
				$elm$json$Json$Decode$decodeValue,
				A2($elm$json$Json$Decode$at, path, $elm$json$Json$Decode$value),
				input);
			if (!_v0.$) {
				var rawValue = _v0.a;
				var _v1 = A2(
					$elm$json$Json$Decode$decodeValue,
					nullOr(valDecoder),
					rawValue);
				if (!_v1.$) {
					var finalResult = _v1.a;
					return $elm$json$Json$Decode$succeed(finalResult);
				} else {
					return A2(
						$elm$json$Json$Decode$at,
						path,
						nullOr(valDecoder));
				}
			} else {
				return $elm$json$Json$Decode$succeed(fallback);
			}
		};
		return A2($elm$json$Json$Decode$andThen, handleResult, $elm$json$Json$Decode$value);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional = F4(
	function (key, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				_List_fromArray(
					[key]),
				valDecoder,
				fallback),
			decoder);
	});
var $author$project$Data$RouteResponse$RouteData = function (uuid) {
	return function (vehicleId) {
		return function (distance) {
			return function (transportTime) {
				return function (completionTime) {
					return function (waitingTime) {
						return function (serviceDuration) {
							return function (preparationTime) {
								return function (activities) {
									return function (points) {
										return function (driver) {
											return {eo: activities, bN: completionTime, ab: distance, fh: driver, gU: points, a9: preparationTime, dJ: serviceDuration, d4: transportTime, hW: uuid, hY: vehicleId, bp: waitingTime};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Data$RouteResponse$Activity = function (id) {
	return function (activityType) {
		return function (locationId) {
			return function (address) {
				return function (distance) {
					return function (drivingTime) {
						return function (preparationTime) {
							return function (waitingTime) {
								return function (operationTime) {
									return function (endTime) {
										return {ep: activityType, er: address, ab: distance, fj: drivingTime, fq: endTime, fS: id, gc: locationId, gH: operationTime, a9: preparationTime, bp: waitingTime};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Data$RouteResponse$Deliver = 3;
var $author$project$Data$RouteResponse$End = 1;
var $author$project$Data$RouteResponse$Other = 5;
var $author$project$Data$RouteResponse$Pickup = 2;
var $author$project$Data$RouteResponse$Service = 4;
var $author$project$Data$RouteResponse$Start = 0;
var $author$project$Data$RouteResponse$activityTypeDecoder = function (st) {
	switch (st) {
		case 'start':
			return $elm$json$Json$Decode$succeed(0);
		case 'pickupShipment':
			return $elm$json$Json$Decode$succeed(2);
		case 'pickup':
			return $elm$json$Json$Decode$succeed(2);
		case 'delivery':
			return $elm$json$Json$Decode$succeed(3);
		case 'deliverShipment':
			return $elm$json$Json$Decode$succeed(3);
		case 'end':
			return $elm$json$Json$Decode$succeed(1);
		case 'service':
			return $elm$json$Json$Decode$succeed(4);
		default:
			return $elm$json$Json$Decode$succeed(5);
	}
};
var $author$project$Data$RouteResponse$LocationAddress = F4(
	function (location_id, name, lat, lon) {
		return {f8: lat, gd: location_id, ge: lon, gs: name};
	});
var $elm_community$json_extra$Json$Decode$Extra$withDefault = F2(
	function (fallback, decoder) {
		return A2(
			$elm$json$Json$Decode$map,
			$elm$core$Maybe$withDefault(fallback),
			$elm$json$Json$Decode$maybe(decoder));
	});
var $author$project$Data$RouteResponse$locationAddressDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2($elm$json$Json$Decode$field, 'lon', $elm$json$Json$Decode$float),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'lat', $elm$json$Json$Decode$float),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2(
				$elm_community$json_extra$Json$Decode$Extra$withDefault,
				$elm$core$Maybe$Nothing,
				A2(
					$elm$json$Json$Decode$field,
					'name',
					$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string))),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'location_id', $elm$json$Json$Decode$string),
				$elm$json$Json$Decode$succeed($author$project$Data$RouteResponse$LocationAddress)))));
var $author$project$Data$RouteResponse$activityDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2($elm$json$Json$Decode$field, 'end_time', $elm$json$Json$Decode$int),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'operation_time', $elm$json$Json$Decode$int),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'waiting_time', $elm$json$Json$Decode$int),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'preparation_time', $elm$json$Json$Decode$int),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'driving_time', $elm$json$Json$Decode$int),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'distance', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'address', $author$project$Data$RouteResponse$locationAddressDecoder),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'location_id', $elm$json$Json$Decode$string),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2(
										$elm$json$Json$Decode$field,
										'type',
										A2($elm$json$Json$Decode$andThen, $author$project$Data$RouteResponse$activityTypeDecoder, $elm$json$Json$Decode$string)),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string),
										$elm$json$Json$Decode$succeed($author$project$Data$RouteResponse$Activity)))))))))));
var $author$project$Data$RouteResponse$LineString = function (coordinates) {
	return {bR: coordinates};
};
var $author$project$Data$RouteResponse$LineStringGM = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$index = _Json_decodeIndex;
var $author$project$Data$RouteResponse$jsonDecodePointCoordinates = A3(
	$elm$json$Json$Decode$map2,
	F2(
		function (a, b) {
			return _Utils_Tuple2(a, b);
		}),
	A2($elm$json$Json$Decode$index, 0, $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$index, 1, $elm$json$Json$Decode$float));
var $author$project$Data$RouteResponse$decodeLineString = A2(
	$elm$json$Json$Decode$map,
	$author$project$Data$RouteResponse$LineStringGM,
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2(
			$elm$json$Json$Decode$field,
			'coordinates',
			$elm$json$Json$Decode$list($author$project$Data$RouteResponse$jsonDecodePointCoordinates)),
		$elm$json$Json$Decode$succeed($author$project$Data$RouteResponse$LineString)));
var $author$project$Data$RouteResponse$Point = function (coordinates) {
	return {bR: coordinates};
};
var $author$project$Data$RouteResponse$PointGM = function (a) {
	return {$: 0, a: a};
};
var $author$project$Data$RouteResponse$decodePoint = A2(
	$elm$json$Json$Decode$map,
	$author$project$Data$RouteResponse$PointGM,
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'coordinates', $author$project$Data$RouteResponse$jsonDecodePointCoordinates),
		$elm$json$Json$Decode$succeed($author$project$Data$RouteResponse$Point)));
var $author$project$Data$RouteResponse$pointsGeometryDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[$author$project$Data$RouteResponse$decodePoint, $author$project$Data$RouteResponse$decodeLineString]));
var $author$project$Data$RouteResponse$routeDataDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'driver',
	$elm$json$Json$Decode$maybe($author$project$Data$Driver$decoder),
	$elm$core$Maybe$Nothing,
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2(
			$elm$json$Json$Decode$field,
			'points',
			$elm$json$Json$Decode$list($author$project$Data$RouteResponse$pointsGeometryDecoder)),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2(
				$elm$json$Json$Decode$field,
				'activities',
				$elm$json$Json$Decode$list($author$project$Data$RouteResponse$activityDecoder)),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'preparation_time', $elm$json$Json$Decode$int),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'service_duration', $elm$json$Json$Decode$int),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'waiting_time', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'completion_time', $elm$json$Json$Decode$int),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'transport_time', $elm$json$Json$Decode$int),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'distance', $elm$json$Json$Decode$int),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'vehicle_id', $elm$json$Json$Decode$string),
										A4(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
											'uuid',
											$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
											$elm$core$Maybe$Nothing,
											$elm$json$Json$Decode$succeed($author$project$Data$RouteResponse$RouteData))))))))))));
var $author$project$Data$RouteResponse$solutionDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'id',
	$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
	$elm$core$Maybe$Nothing,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'routes',
		$elm$json$Json$Decode$list($author$project$Data$RouteResponse$routeDataDecoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'no_unassigned',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'no_vehicles',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'preparation_time',
					$elm$json$Json$Decode$int,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'service_duration',
						$elm$json$Json$Decode$int,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'waiting_time',
							$elm$json$Json$Decode$int,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'max_operation_time',
								$elm$json$Json$Decode$int,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'completion_time',
									$elm$json$Json$Decode$int,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'transport_time',
										$elm$json$Json$Decode$int,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'time',
											$elm$json$Json$Decode$int,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'distance',
												$elm$json$Json$Decode$int,
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'costs',
													$elm$json$Json$Decode$int,
													$elm$json$Json$Decode$succeed($author$project$Data$RouteResponse$Solution))))))))))))));
var $author$project$Data$Route$decoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Data$Route$centerDecoder,
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2(
			$elm_community$json_extra$Json$Decode$Extra$optionalField,
			'tags',
			$elm$json$Json$Decode$list($elm$json$Json$Decode$string)),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'status', $author$project$Data$Route$routeStatusDecoder),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2(
					$elm$json$Json$Decode$field,
					'data',
					$elm$json$Json$Decode$maybe($author$project$Data$RouteResponse$solutionDecoder)),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'created_at', $rtfeldman$elm_iso8601_date_strings$Iso8601$decoder),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2(
							$elm$json$Json$Decode$field,
							'driver',
							$elm$json$Json$Decode$maybe($author$project$Data$Driver$decoder)),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2(
								$elm$json$Json$Decode$field,
								'orders',
								$elm$json$Json$Decode$list($author$project$Data$Order$decoder)),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$int),
									$elm$json$Json$Decode$succeed($author$project$Data$Route$Route))))))))));
var $author$project$Request$Route$fetchRouteUrl = F2(
	function (env, page) {
		return $author$project$Request$Url$apiServiceUrl(env) + ('api/v2/routes/fetch_active_routes?page=' + ($elm$core$String$fromInt(page) + '&page_size=10'));
	});
var $author$project$Request$Route$loadRoutes = F3(
	function (_v0, page, msg) {
		var user = _v0.d7;
		var env = _v0.fr;
		return A4(
			$author$project$Request$HttpClient$authenticatedGet,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.bs;
					},
					user)),
			A2($author$project$Request$Route$fetchRouteUrl, env, page),
			A3(
				$elm$json$Json$Decode$map2,
				$elm$core$Tuple$pair,
				A2(
					$elm$json$Json$Decode$field,
					'data',
					$elm$json$Json$Decode$list($author$project$Data$Route$decoder)),
				A2($elm$json$Json$Decode$field, 'meta', $author$project$Views$Common$Pagination$decoder)),
			msg);
	});
var $author$project$Views$RetailerRoundIntegration$Model$fetchRoutes = F2(
	function (context, page) {
		return A3(
			$author$project$Request$Route$loadRoutes,
			context,
			page,
			A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Views$RetailerRoundIntegration$Msg$FetchRoutes));
	});
var $author$project$Views$RetailerRoundIntegration$Model$PeixosPuignau = 2;
var $author$project$Views$RetailerRoundIntegration$Model$Refruiting = 0;
var $author$project$Views$RetailerRoundIntegration$Model$Ulabox = 1;
var $author$project$Views$RetailerRoundIntegration$Model$stringToRetailer = function (str) {
	switch (str) {
		case 'refruiting':
			return $elm$core$Maybe$Just(0);
		case 'ulabox':
			return $elm$core$Maybe$Just(1);
		case 'puignau':
			return $elm$core$Maybe$Just(2);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Views$RetailerRoundIntegration$Model$init = F2(
	function (context, retailerStr) {
		var _v0 = $author$project$Views$RetailerRoundIntegration$Model$stringToRetailer(retailerStr);
		if (!_v0.$) {
			var retailer = _v0.a;
			return _Utils_Tuple2(
				A3($author$project$Views$RetailerRoundIntegration$Model$LoadingRoutes, 1, _List_Nil, retailer),
				A2($author$project$Views$RetailerRoundIntegration$Model$fetchRoutes, context, 1));
		} else {
			return _Utils_Tuple2($author$project$Views$RetailerRoundIntegration$Model$RetailerNotSupported, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Views$ForgotPassword$ForgotPassword$initialModel = {fn: '', b1: 3, ft: _List_Nil};
var $krisajenkins$remotedata$RemoteData$NotAsked = {$: 0};
var $author$project$Views$MapArea$Model$initialModel = function (_v0) {
	return {fB: $elm$core$Maybe$Nothing, dq: $krisajenkins$remotedata$RemoteData$NotAsked};
};
var $author$project$Views$MapAssignment$Msg$CheckIfIsGeneratingRoutes = F2(
	function (a, b) {
		return {$: 108, a: a, b: b};
	});
var $author$project$Ports$CheckStoredRouteJobInfo = {$: 4};
var $author$project$Views$MapAssignment$Msg$Closed = {$: 0};
var $author$project$Views$MapAssignment$Msg$FetchAreas = function (a) {
	return {$: 7, a: a};
};
var $author$project$Views$MapAssignment$Msg$FetchDrivers = function (a) {
	return {$: 1, a: a};
};
var $author$project$Views$MapAssignment$Msg$FetchMapConfig = function (a) {
	return {$: 69, a: a};
};
var $author$project$Views$MapAssignment$Msg$FetchRoutes = function (a) {
	return {$: 3, a: a};
};
var $author$project$Views$MapAssignment$Msg$FetchWarehouseCompanies = function (a) {
	return {$: 5, a: a};
};
var $author$project$Views$MapAssignment$Msg$FetchWarehouses = function (a) {
	return {$: 6, a: a};
};
var $author$project$Views$MapAssignment$Model$LoadingNotStarted = {$: 0};
var $author$project$Views$MapAssignment$Types$ShowingStatsForFilteredOrders = 0;
var $author$project$Data$RouteResponse$ActiveSolutions = F4(
	function (jobId, solutions, areaId, status) {
		return {ev: areaId, f3: jobId, hp: solutions, ht: status};
	});
var $author$project$Data$RouteResponse$Completed = 2;
var $author$project$Data$RouteResponse$NotProcessing = 0;
var $author$project$Data$RouteResponse$Processing = 1;
var $author$project$Data$RouteResponse$activeSolutionsStatusDecoder = function (str) {
	switch (str) {
		case 'processing':
			return $elm$json$Json$Decode$succeed(1);
		case 'completed':
			return $elm$json$Json$Decode$succeed(2);
		default:
			return $elm$json$Json$Decode$succeed(0);
	}
};
var $elm$json$Json$Decode$map4 = _Json_map4;
var $author$project$Data$RouteResponse$activeSolutionsDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Data$RouteResponse$ActiveSolutions,
	A2($elm$json$Json$Decode$field, 'job_id', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$field,
		'solutions',
		$elm$json$Json$Decode$list($author$project$Data$RouteResponse$solutionDecoder)),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$withDefault,
		$elm$core$Maybe$Nothing,
		A2(
			$elm$json$Json$Decode$field,
			'area_id',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string))),
	A2(
		$elm$json$Json$Decode$field,
		'status',
		A2($elm$json$Json$Decode$andThen, $author$project$Data$RouteResponse$activeSolutionsStatusDecoder, $elm$json$Json$Decode$string)));
var $author$project$Request$RouteRequest$checkGeneratingRoutes = F2(
	function (_v0, msg) {
		var user = _v0.d7;
		var env = _v0.fr;
		return A4(
			$author$project$Request$HttpClient$authenticatedGet,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.bs;
					},
					user)),
			$author$project$Request$Url$apiRailsUrl(env) + 'api/v2/routes/active_solutions',
			$elm$json$Json$Decode$list($author$project$Data$RouteResponse$activeSolutionsDecoder),
			msg);
	});
var $author$project$Views$Helpers$GoogleMap$Bounds = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $author$project$Config$defaultMapBounds = A4($author$project$Views$Helpers$GoogleMap$Bounds, -180, -90, 180, 90);
var $elm$http$Http$expectString = function (toMsg) {
	return A2(
		$elm$http$Http$expectStringResponse,
		toMsg,
		$elm$http$Http$resolve($elm$core$Result$Ok));
};
var $author$project$Request$Map$fetchConfig = function (msg) {
	return $elm$http$Http$request(
		{
			eC: $elm$http$Http$emptyBody,
			b6: $elm$http$Http$expectString(msg),
			ch: _List_fromArray(
				[
					A2($elm$http$Http$header, 'Content-type', 'application/json')
				]),
			cM: 'GET',
			d$: $elm$core$Maybe$Nothing,
			d2: $elm$core$Maybe$Nothing,
			hV: '/map_config.json'
		});
};
var $author$project$Request$Driver$driversUrl = function (env) {
	return $author$project$Request$Url$apiServiceUrl(env) + 'api/v2/users?roles=driver';
};
var $author$project$Request$Driver$fetchDrivers = F2(
	function (_v0, msg) {
		var user = _v0.d7;
		var env = _v0.fr;
		return A4(
			$author$project$Request$HttpClient$authenticatedGet,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.bs;
					},
					user)),
			$author$project$Request$Driver$driversUrl(env),
			$elm$json$Json$Decode$list($author$project$Data$Driver$decoder),
			msg);
	});
var $author$project$Request$Company$warehouseCompaniesUrl = function (env) {
	return $author$project$Request$Url$apiRailsUrl(env) + 'api/v2/warehouses';
};
var $author$project$Request$Company$fetchWarehouseCompanies = F2(
	function (_v0, msg) {
		var user = _v0.d7;
		var env = _v0.fr;
		return A4(
			$author$project$Request$HttpClient$authenticatedGet,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.bs;
					},
					user)),
			$author$project$Request$Company$warehouseCompaniesUrl(env),
			$elm$json$Json$Decode$list($author$project$Data$Company$decoder),
			msg);
	});
var $author$project$Request$Company$warehousesUrl = function (env) {
	return $author$project$Request$Url$apiRailsUrl(env) + 'api/v2/stores';
};
var $author$project$Request$Company$fetchWarehouses = F2(
	function (_v0, msg) {
		var user = _v0.d7;
		var env = _v0.fr;
		return A4(
			$author$project$Request$HttpClient$authenticatedGet,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.bs;
					},
					user)),
			$author$project$Request$Company$warehousesUrl(env),
			$elm$json$Json$Decode$list($author$project$Data$Store$decoder),
			msg);
	});
var $author$project$Data$Area$Area = F3(
	function (id, name, polygon) {
		return {fS: id, gs: name, gV: polygon};
	});
var $author$project$Data$Area$decoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2(
		$elm$json$Json$Decode$field,
		'polygon',
		$elm$json$Json$Decode$list($author$project$Data$Map$coordinatesDecoder)),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string),
			$elm$json$Json$Decode$succeed($author$project$Data$Area$Area))));
var $author$project$Common$ExpectJsonWithError$BadStatusWithErrorMessageNotParsed = function (a) {
	return {$: 4, a: a};
};
var $author$project$Common$ExpectJsonWithError$BadStatusWithErrorMessageParsed = function (a) {
	return {$: 3, a: a};
};
var $author$project$Common$ExpectJsonWithError$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $author$project$Views$Common$Alert$ErrorData = F2(
	function (message, statusCode) {
		return {gm: message, dQ: statusCode};
	});
var $author$project$Common$ExpectJsonWithError$GoodStatusWithResponseParsingError = function (a) {
	return {$: 5, a: a};
};
var $author$project$Common$ExpectJsonWithError$NetworkError = function (a) {
	return {$: 2, a: a};
};
var $author$project$Common$ExpectJsonWithError$Timeout = function (a) {
	return {$: 1, a: a};
};
var $author$project$Common$ExpectJsonWithError$expectJsonWithError = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			function (response) {
				switch (response.$) {
					case 0:
						var url = response.a;
						return $elm$core$Result$Err(
							$author$project$Common$ExpectJsonWithError$BadUrl(
								A2($author$project$Views$Common$Alert$ErrorData, 'The requested url i.e. ' + (url + ' is not valid'), $elm$core$Maybe$Nothing)));
					case 1:
						return $elm$core$Result$Err(
							$author$project$Common$ExpectJsonWithError$Timeout(
								A2($author$project$Views$Common$Alert$ErrorData, 'The request has timed out', $elm$core$Maybe$Nothing)));
					case 2:
						return $elm$core$Result$Err(
							$author$project$Common$ExpectJsonWithError$NetworkError(
								A2($author$project$Views$Common$Alert$ErrorData, 'Looks like there is a problem with your network', $elm$core$Maybe$Nothing)));
					case 3:
						var statusCode = response.a.dQ;
						var body = response.b;
						var _v1 = A2(
							$elm$json$Json$Decode$decodeString,
							A2(
								$elm$json$Json$Decode$at,
								_List_fromArray(
									['error', 'message']),
								$elm$json$Json$Decode$string),
							body);
						if (!_v1.$) {
							var error = _v1.a;
							return $elm$core$Result$Err(
								$author$project$Common$ExpectJsonWithError$BadStatusWithErrorMessageParsed(
									A2(
										$author$project$Views$Common$Alert$ErrorData,
										error,
										$elm$core$Maybe$Just(statusCode))));
						} else {
							return $elm$core$Result$Err(
								$author$project$Common$ExpectJsonWithError$BadStatusWithErrorMessageNotParsed(
									A2(
										$author$project$Views$Common$Alert$ErrorData,
										'We couldn\'t parse the server response for an error message',
										$elm$core$Maybe$Just(statusCode))));
						}
					default:
						var statusCode = response.a.dQ;
						var body = response.b;
						var _v2 = A2($elm$json$Json$Decode$decodeString, decoder, body);
						if (!_v2.$) {
							var value = _v2.a;
							return $elm$core$Result$Ok(value);
						} else {
							var err = _v2.a;
							return $elm$core$Result$Err(
								$author$project$Common$ExpectJsonWithError$GoodStatusWithResponseParsingError(
									A2(
										$author$project$Views$Common$Alert$ErrorData,
										'Response parsing error: ' + $elm$json$Json$Decode$errorToString(err),
										$elm$core$Maybe$Just(statusCode))));
						}
				}
			});
	});
var $author$project$Request$Area$getAllAreasUrl = function (env) {
	return $author$project$Request$Url$apiRailsUrl(env) + 'api/v2/routes/get_areas';
};
var $author$project$Request$Area$getAllAreas = F2(
	function (_v0, msg) {
		var user = _v0.d7;
		var env = _v0.fr;
		return $elm$http$Http$request(
			{
				eC: $elm$http$Http$emptyBody,
				b6: A2(
					$author$project$Common$ExpectJsonWithError$expectJsonWithError,
					msg,
					$elm$json$Json$Decode$list($author$project$Data$Area$decoder)),
				ch: _List_fromArray(
					[
						A2(
						$elm$http$Http$header,
						'X-Authentication',
						A2(
							$elm$core$Maybe$withDefault,
							'',
							A2(
								$elm$core$Maybe$map,
								function ($) {
									return $.bs;
								},
								user)))
					]),
				cM: 'GET',
				d$: $elm$core$Maybe$Nothing,
				d2: $elm$core$Maybe$Nothing,
				hV: $author$project$Request$Area$getAllAreasUrl(env)
			});
	});
var $FabienHenon$elm_infinite_scroll$InfiniteScroll$Bottom = 1;
var $FabienHenon$elm_infinite_scroll$InfiniteScroll$Model = $elm$core$Basics$identity;
var $FabienHenon$elm_infinite_scroll$InfiniteScroll$init = function (loadMoreFunc) {
	return {
		aa: 1,
		f0: false,
		at: $elm$time$Time$millisToPosix(0),
		av: loadMoreFunc,
		cV: 50,
		d$: 5 * 1000
	};
};
var $PaackEng$elm_alert_beta$Alert$State = $elm$core$Basics$identity;
var $PaackEng$elm_alert_beta$Alert$initState = {bv: _List_Nil, a0: 0};
var $author$project$Views$MapAssignment$Model$NotStarted = {$: 0};
var $author$project$Views$MapAssignment$Msg$PostalCodeDropdown = 0;
var $author$project$Views$Common$Select$Option = F3(
	function (value, selected, show) {
		return {D: selected, A: show, y: value};
	});
var $author$project$Views$Common$Select$init = function (options) {
	return {
		T: false,
		b: A2(
			$elm$core$List$map,
			function (option) {
				return A3($author$project$Views$Common$Select$Option, option, false, true);
			},
			options),
		aN: ''
	};
};
var $author$project$Views$MapAssignment$Model$initialFilters = {
	b_: $author$project$Views$Common$Select$init(_List_Nil),
	fL: $author$project$Views$Common$Select$init(_List_Nil),
	cW: false,
	c_: $elm$core$Maybe$Nothing,
	c6: $author$project$Views$Common$Select$init(_List_Nil),
	c8: $author$project$Views$MapAssignment$Model$NotStarted,
	aI: 0,
	gY: $author$project$Views$Common$Select$init(_List_Nil),
	gZ: '',
	g6: $author$project$Views$Common$Select$init(_List_Nil),
	hk: $author$project$Views$Common$Select$init(_List_Nil),
	dV: $author$project$Views$Common$Select$init(_List_Nil),
	bm: $author$project$Views$Common$Select$init(_List_Nil),
	bo: ''
};
var $author$project$Views$MapAssignment$Model$Closed = function (a) {
	return {$: 0, a: a};
};
var $author$project$Views$MapAssignment$Model$Unknown = 3;
var $elm$core$Set$Set_elm_builtin = $elm$core$Basics$identity;
var $elm$core$Set$empty = $elm$core$Dict$empty;
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $author$project$Page$AutomaticRouteSolution$Model$NoError = 0;
var $author$project$Page$AutomaticRouteSolution$Model$NoRouteSelected = {$: 1};
var $author$project$Page$AutomaticRouteSolution$Model$initialModel = F2(
	function (activeSolutions, mapStyle) {
		var selectedSol = A2(
			$elm$core$Maybe$map,
			function (x) {
				var routeUpdates = {fi: $elm$core$Dict$empty, g3: _List_Nil};
				return _Utils_Tuple3(0, x, routeUpdates);
			},
			$elm$core$List$head(activeSolutions.hp));
		return {
			R: _Utils_Tuple2(activeSolutions, _List_Nil),
			bI: $author$project$Page$AutomaticRouteSolution$Model$NoRouteSelected,
			b_: _List_Nil,
			b0: '',
			fs: 0,
			fv: '',
			cr: false,
			f_: false,
			cu: false,
			f0: false,
			cz: false,
			f6: $krisajenkins$remotedata$RemoteData$NotAsked,
			cG: mapStyle,
			gW: $elm$core$Maybe$Nothing,
			hh: selectedSol,
			ht: activeSolutions.ht,
			d0: false
		};
	});
var $author$project$Page$AutomaticRouteStep$Model$Google = 1;
var $author$project$Page$AutomaticRouteStep$Model$Manual = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$AutomaticRouteStep$Model$V6 = 2;
var $author$project$Page$AutomaticRouteStep$Model$VehiclesStep = 0;
var $author$project$Page$AutomaticRouteStep$VehicleDataForm$initialForm = function (context) {
	var intitForm = {
		bE: '100',
		ft: _List_Nil,
		cH: '55',
		cI: '1000',
		cK: '199',
		cL: '',
		cN: '',
		cO: '',
		df: '1',
		dN: '10',
		dO: '30',
		hX: $author$project$Common$Vehicle$toString(0),
		eg: '9'
	};
	var _v0 = context.dB;
	if (!_v0.$) {
		var settings = _v0.a;
		var workingTime = function () {
			var _v1 = A2(
				$elm$core$Maybe$map,
				$elm$core$String$split(':'),
				settings.aR.hs);
			if (((!_v1.$) && _v1.a.b) && _v1.a.b.b) {
				var _v2 = _v1.a;
				var hours = _v2.a;
				var _v3 = _v2.b;
				var minute = _v3.a;
				return {cn: hours, a4: minute};
			} else {
				return {cn: '10', a4: '30'};
			}
		}();
		var workingHours = $elm$core$String$fromInt(settings.aR.eg);
		var vehicle = $author$project$Common$Vehicle$toString(settings.aR.hX);
		return _Utils_update(
			intitForm,
			{dN: workingTime.cn, dO: workingTime.a4, hX: vehicle, eg: workingHours});
	} else {
		return intitForm;
	}
};
var $author$project$Page$AutomaticRouteStep$Model$initialModel = function (context) {
	return function (orders) {
		return function (stores) {
			return function (selectedVehicles) {
				return function (selectedStore) {
					return function (storeInputValue) {
						return function (jobId) {
							return function (name) {
								return function (areaId) {
									return function (uniqueLocation) {
										var totalOrders = $elm$core$List$length(orders);
										var defaultMaxDuration = $elm$core$Maybe$Just(
											(totalOrders < 1000) ? 20 : ((totalOrders < 3000) ? 25 : ((totalOrders < 5000) ? 60 : 120)));
										return {
											ev: areaId,
											bW: $author$project$Page$AutomaticRouteStep$Model$Manual(
												$elm$core$Maybe$Just(5.0)),
											bX: $elm$core$Maybe$Nothing,
											fK: false,
											ck: context.fr === 3,
											f3: jobId,
											cJ: defaultMaxDuration,
											gs: name,
											cX: 2,
											cY: 1,
											gL: orders,
											dt: $elm$core$Maybe$Nothing,
											dG: selectedStore,
											dH: selectedVehicles,
											dR: 0,
											dU: storeInputValue,
											dV: stores,
											hQ: uniqueLocation,
											d6: false,
											d8: $author$project$Page$AutomaticRouteStep$VehicleDataForm$initialForm(context)
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Page$AutomaticRoute$Model$buildInitialExecution = F7(
	function (context, orders, stores, selectedVehicles, solutions, mapStyle, areas) {
		return A2(
			$elm$core$List$indexedMap,
			F2(
				function (index, activeSolution) {
					var name = A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.gs;
						},
						$elm$core$List$head(
							A2(
								$elm$core$List$filter,
								function (area) {
									return _Utils_eq(
										$elm$core$Maybe$Just(area.fS),
										activeSolution.ev);
								},
								areas)));
					return _Utils_Tuple3(
						index,
						$author$project$Page$AutomaticRouteStep$Model$initialModel(context)(orders)(stores)(selectedVehicles)($elm$core$Maybe$Nothing)('')(
							$elm$core$Maybe$Just(activeSolution.f3))(name)(activeSolution.ev)($elm$core$Maybe$Nothing),
						A2($author$project$Page$AutomaticRouteSolution$Model$initialModel, activeSolution, mapStyle));
				}),
			solutions);
	});
var $author$project$Page$AutomaticRoute$Model$initialModel = F9(
	function (context, orders, stores, selectedVehicles, solutions, mapStyle, areas, split, uniqueLocation) {
		return {
			bx: areas,
			b_: _List_Nil,
			b3: $elm$core$Maybe$Nothing,
			b5: A7($author$project$Page$AutomaticRoute$Model$buildInitialExecution, context, orders, stores, selectedVehicles, solutions, mapStyle, areas),
			cG: mapStyle,
			gL: orders,
			dF: $elm$core$Maybe$Nothing,
			dM: split,
			dV: stores,
			hQ: uniqueLocation
		};
	});
var $author$project$Views$MapAssignment$Model$initialRouteContainerInfo = function (context) {
	var automaticRouteDialogState = $author$project$Views$MapAssignment$Model$Closed(
		A9($author$project$Page$AutomaticRoute$Model$initialModel, context, _List_Nil, _List_Nil, _List_Nil, _List_Nil, '', _List_Nil, false, $elm$core$Maybe$Nothing));
	return {
		R: {ev: $elm$core$Maybe$Nothing, f3: '', hp: _List_Nil, ht: 0},
		bz: 3,
		bA: automaticRouteDialogState,
		bH: $elm$core$Maybe$Nothing,
		b9: _List_Nil,
		cw: false,
		c0: $elm$core$Set$empty,
		g8: _List_Nil,
		bc: $author$project$Views$MapAssignment$Model$LoadingNotStarted,
		bd: $elm$core$Maybe$Nothing
	};
};
var $author$project$Views$MapAssignment$Model$initAutoRoutingSettings = function (context) {
	var _v0 = context.dB;
	if (!_v0.$) {
		var savedSettings = _v0.a;
		return {hs: savedSettings.aR.hs, hX: savedSettings.aR.hX, eg: savedSettings.aR.eg};
	} else {
		return {hs: $elm$core$Maybe$Nothing, hX: 0, eg: 10};
	}
};
var $author$project$Views$MapAssignment$Settings$ClusterSettings$initModelAsNotClustered = $author$project$Views$MapAssignment$Types$NotClustered;
var $author$project$Views$MapAssignment$Model$initRoutingSettings = {bW: '5', gN: $elm$core$Maybe$Nothing, c5: '17.0', ak: $elm$core$Maybe$Nothing, hr: $elm$core$Maybe$Nothing, h$: $elm$core$Maybe$Nothing};
var $author$project$Views$MapAssignment$Model$initialSettings = function (context) {
	return {
		aR: $author$project$Views$MapAssignment$Model$initAutoRoutingSettings(context),
		eQ: $author$project$Views$MapAssignment$Settings$ClusterSettings$initModelAsNotClustered,
		fo: false,
		ft: $elm$core$Dict$empty,
		T: false,
		f1: false,
		he: $author$project$Views$MapAssignment$Model$initRoutingSettings
	};
};
var $author$project$Views$MapAssignment$Msg$OnLoadMoreOrders = function (a) {
	return {$: 97, a: a};
};
var $author$project$Views$MapAssignment$Model$loadMoreOrders = function (dir) {
	return A2(
		$elm$core$Task$perform,
		$author$project$Views$MapAssignment$Msg$OnLoadMoreOrders,
		$elm$core$Task$succeed(dir));
};
var $author$project$Views$MapAssignment$Model$initialModel = function (context) {
	var initialRoutes = $author$project$Views$MapAssignment$Model$initialRouteContainerInfo(context);
	var initialFiltersModel = $author$project$Views$MapAssignment$Model$initialFilters;
	var model = {
		bv: $PaackEng$elm_alert_beta$Alert$initState,
		bx: _List_Nil,
		bZ: false,
		b_: _List_Nil,
		b$: _List_Nil,
		b2: $elm$core$Maybe$Nothing,
		fF: _List_Nil,
		fG: initialFiltersModel,
		cg: _List_Nil,
		cl: $author$project$Views$MapAssignment$Msg$Closed,
		cx: false,
		gh: {eD: $author$project$Config$defaultMapBounds, bR: $elm$core$Maybe$Nothing, ff: false, ca: false, fY: false, cG: '', h4: 10},
		c$: $elm$core$Maybe$Nothing,
		gL: $elm$core$Dict$empty,
		c2: false,
		c3: {
			bP: _List_Nil,
			co: $FabienHenon$elm_infinite_scroll$InfiniteScroll$init($author$project$Views$MapAssignment$Model$loadMoreOrders),
			cV: 0
		},
		c4: 0,
		c6: $elm$core$Dict$empty,
		ds: '',
		hc: _Utils_update(
			initialRoutes,
			{bc: $author$project$Views$MapAssignment$Model$LoadingStarted}),
		hl: A2(
			$elm$core$Maybe$withDefault,
			$author$project$Views$MapAssignment$Model$initialSettings(context),
			context.dB),
		hS: $author$project$Views$MapAssignment$Model$LoadingNotStarted,
		ec: _List_Nil,
		h0: _List_Nil
	};
	return _Utils_Tuple2(
		model,
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					$author$project$Request$Map$fetchConfig($author$project$Views$MapAssignment$Msg$FetchMapConfig),
					A2($author$project$Request$Area$getAllAreas, context, $author$project$Views$MapAssignment$Msg$FetchAreas),
					A3($author$project$Request$Route$loadRoutes, context, 1, $author$project$Views$MapAssignment$Msg$FetchRoutes),
					A2($author$project$Request$Driver$fetchDrivers, context, $author$project$Views$MapAssignment$Msg$FetchDrivers),
					A2($author$project$Request$Company$fetchWarehouseCompanies, context, $author$project$Views$MapAssignment$Msg$FetchWarehouseCompanies),
					A2($author$project$Request$Company$fetchWarehouses, context, $author$project$Views$MapAssignment$Msg$FetchWarehouses),
					A2(
					$author$project$Request$RouteRequest$checkGeneratingRoutes,
					context,
					$author$project$Views$MapAssignment$Msg$CheckIfIsGeneratingRoutes(false)),
					$author$project$Ports$sendMsgToUpsideDown($author$project$Ports$CheckStoredRouteJobInfo)
				])));
};
var $author$project$Update$loadDefaultRouteByUser = function (user) {
	var _v0 = user.aL;
	switch (_v0) {
		case 1:
			return $author$project$Route$MapAssignment;
		case 3:
			return $author$project$Route$MapAssignment;
		case 0:
			return $author$project$Route$Reports;
		default:
			return $author$project$Route$Home;
	}
};
var $author$project$Common$ReturnExtra$withNoCmd = function (model) {
	return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
};
var $author$project$Update$setRoute = F2(
	function (maybeRoute, model) {
		setRoute:
		while (true) {
			var context = model.eW;
			var _v0 = _Utils_Tuple2(maybeRoute, context.d7);
			_v0$9:
			while (true) {
				if (!_v0.a.$) {
					switch (_v0.a.a.$) {
						case 1:
							var _v1 = _v0.a.a;
							var redirectPath = _v1.a;
							var maybeToken = _v1.b;
							var _v2 = A3($author$project$Views$Login$Login$initialModel, context, redirectPath, maybeToken);
							var loginModel = _v2.a;
							var loginCmd = _v2.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										gP: $author$project$Page$Login(loginModel)
									}),
								A2($elm$core$Platform$Cmd$map, $author$project$Msg$LoginMsg, loginCmd));
						case 6:
							var _v3 = _v0.a.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										gP: $author$project$Page$ForgotPassword($author$project$Views$ForgotPassword$ForgotPassword$initialModel)
									}),
								$elm$core$Platform$Cmd$none);
						case 0:
							if (!_v0.b.$) {
								var _v4 = _v0.a.a;
								var user = _v0.b.a;
								var route = $author$project$Update$loadDefaultRouteByUser(user);
								var $temp$maybeRoute = $elm$core$Maybe$Just(route),
									$temp$model = model;
								maybeRoute = $temp$maybeRoute;
								model = $temp$model;
								continue setRoute;
							} else {
								var _v5 = _v0.a.a;
								var _v6 = _v0.b;
								var $temp$maybeRoute = $elm$core$Maybe$Just($author$project$Route$loginRouteWithoutParams),
									$temp$model = model;
								maybeRoute = $temp$maybeRoute;
								model = $temp$model;
								continue setRoute;
							}
						case 2:
							var _v7 = _v0.a.a;
							return $author$project$SessionUtils$logoutAndRedirectsToLogin(model);
						case 4:
							var _v8 = _v0.a.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										gP: $author$project$Page$MapArea(
											$author$project$Views$MapArea$Model$initialModel(context))
									}),
								$elm$core$Platform$Cmd$none);
						case 3:
							if (!_v0.b.$) {
								var _v9 = _v0.a.a;
								var _v10 = $author$project$Views$MapAssignment$Model$initialModel(context);
								var pageModel = _v10.a;
								var cmd = _v10.b;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											gP: $author$project$Page$MapAssignment(pageModel)
										}),
									A2($elm$core$Platform$Cmd$map, $author$project$Msg$MapAssignmentMsg, cmd));
							} else {
								break _v0$9;
							}
						case 7:
							if (!_v0.b.$) {
								var _v11 = _v0.a.a;
								var _v12 = $author$project$Views$Reports$Model$init(context);
								var pageModel = _v12.a;
								var cmd = _v12.b;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											gP: $author$project$Page$Reports(pageModel)
										}),
									A2($elm$core$Platform$Cmd$map, $author$project$Msg$ReportsMsg, cmd));
							} else {
								break _v0$9;
							}
						default:
							if (!_v0.b.$) {
								var retailerAlias = _v0.a.a.a;
								var _v13 = A2($author$project$Views$RetailerRoundIntegration$Model$init, context, retailerAlias);
								var pageModel = _v13.a;
								var cmd = _v13.b;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											gP: $author$project$Page$RetailerRoundIntegration(pageModel)
										}),
									A2($elm$core$Platform$Cmd$map, $author$project$Msg$RetailerRoundIntegrationMsg, cmd));
							} else {
								break _v0$9;
							}
					}
				} else {
					break _v0$9;
				}
			}
			return $author$project$Common$ReturnExtra$withNoCmd(model);
		}
	});
var $author$project$Route$modifyUrlWithReload = function (route) {
	return $elm$browser$Browser$Navigation$load(
		$author$project$Route$routeToString(route));
};
var $author$project$SessionUtils$setUserAndRedirectsTo = F4(
	function (route, user, shouldReload, model) {
		var routeCmd = shouldReload ? $author$project$Route$modifyUrlWithReload(route) : A2($author$project$Route$modifyUrl, model.f5, route);
		return A2(
			$Fresheyeball$elm_return$Return$command,
			$author$project$Ports$sendMsgToUpsideDown(
				$author$project$Ports$StoreSession(
					$elm$core$Maybe$Just(user))),
			A2(
				$Fresheyeball$elm_return$Return$command,
				routeCmd,
				$Fresheyeball$elm_return$Return$singleton(
					_Utils_update(
						model,
						{
							eW: A2(
								$author$project$Data$Context$updateUser,
								$elm$core$Maybe$Just(user),
								model.eW)
						}))));
	});
var $author$project$Route$stringToRoute = function (maybePath) {
	if (!maybePath.$) {
		var stringPath = maybePath.a;
		switch (stringPath) {
			case 'login':
				return A2($author$project$Route$Login, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
			case 'forgot_password':
				return $author$project$Route$ForgotPassword;
			case 'logout':
				return $author$project$Route$Logout;
			case 'map_area':
				return $author$project$Route$MapArea;
			case 'map_assignment':
				return $author$project$Route$MapAssignment;
			case 'reports':
				return $author$project$Route$Reports;
			case 'routes/refruiting':
				return $author$project$Route$RetailerRoundIntegration('refruiting');
			case 'routes/ulabox':
				return $author$project$Route$RetailerRoundIntegration('ulabox');
			case 'routes/puignau':
				return $author$project$Route$RetailerRoundIntegration('puignau');
			default:
				return $author$project$Route$Home;
		}
	} else {
		return $author$project$Route$Home;
	}
};
var $elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + $elm$core$String$fromInt(port_));
		}
	});
var $elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var $elm$url$Url$toString = function (url) {
	var http = function () {
		var _v0 = url.de;
		if (!_v0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		$elm$url$Url$addPrefixed,
		'#',
		url.cd,
		A3(
			$elm$url$Url$addPrefixed,
			'?',
			url.g0,
			_Utils_ap(
				A2(
					$elm$url$Url$addPort,
					url.c9,
					_Utils_ap(http, url.cm)),
				url.gR)));
};
var $author$project$Data$Context$update = F2(
	function (msg, context) {
		if (!msg.$) {
			var zone = msg.a;
			return $author$project$Common$ReturnExtra$withNoCmd(
				_Utils_update(
					context,
					{em: zone}));
		} else {
			var newTime = msg.a;
			return $author$project$Common$ReturnExtra$withNoCmd(
				_Utils_update(
					context,
					{d_: newTime}));
		}
	});
var $author$project$Views$Login$Login$Form = 0;
var $author$project$Views$Login$Login$Login = function (a) {
	return {$: 1, a: a};
};
var $author$project$Views$Login$Login$NoOp = {$: 0};
var $author$project$Views$Login$Login$SetUser = function (a) {
	return {$: 1, a: a};
};
var $author$project$Views$Login$Login$SetUserAndRedirect = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Request$User$loginEncoder = F2(
	function (email, password) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'email',
					$elm$json$Json$Encode$string(email)),
					_Utils_Tuple2(
					'password',
					$elm$json$Json$Encode$string(password))
				]));
	});
var $author$project$Request$User$loginUrl = function (env) {
	return $author$project$Request$Url$apiRailsUrl(env) + 'api/v1/users/email_login';
};
var $elm$http$Http$post = function (r) {
	return $elm$http$Http$request(
		{eC: r.eC, b6: r.b6, ch: _List_Nil, cM: 'POST', d$: $elm$core$Maybe$Nothing, d2: $elm$core$Maybe$Nothing, hV: r.hV});
};
var $author$project$Request$User$login = F3(
	function (_v0, env, msg) {
		var email = _v0.fn;
		var password = _v0.gQ;
		return $elm$http$Http$post(
			{
				eC: $elm$http$Http$jsonBody(
					A2($author$project$Request$User$loginEncoder, email, password)),
				b6: A2(
					$elm$http$Http$expectStringResponse,
					msg,
					function (response) {
						switch (response.$) {
							case 3:
								var metadata = response.a;
								var body = response.b;
								var _v2 = A2(
									$elm$json$Json$Decode$decodeString,
									A2(
										$elm$json$Json$Decode$at,
										_List_fromArray(
											['error', 'message']),
										$elm$json$Json$Decode$string),
									body);
								if (!_v2.$) {
									var error = _v2.a;
									return $elm$core$Result$Err(error);
								} else {
									return $elm$core$Result$Err(
										'Login error occured with status code ' + $elm$core$String$fromInt(metadata.dQ));
								}
							case 4:
								var body = response.b;
								var _v3 = A2($elm$json$Json$Decode$decodeString, $author$project$Data$User$decoderFromLoginResponse, body);
								if (!_v3.$) {
									var user = _v3.a;
									return $elm$core$Result$Ok(user);
								} else {
									return $elm$core$Result$Err('We received a success response but user data couldn\'t be parsed');
								}
							default:
								return $elm$core$Result$Err('Error logging in');
						}
					}),
				hV: $author$project$Request$User$loginUrl(env)
			});
	});
var $author$project$Views$Login$Login$Email = 1;
var $author$project$Views$Login$Login$Password = 2;
var $author$project$Views$Login$Login$validateForm = function (model) {
	var passwordError = ($elm$core$String$length(model.gQ) < 8) ? $elm$core$Maybe$Just(
		_Utils_Tuple2(2, 'Password is not valid')) : $elm$core$Maybe$Nothing;
	var emailError = (($elm$core$String$length(model.fn) < 5) || (!A2($elm$core$String$contains, '@', model.fn))) ? $elm$core$Maybe$Just(
		_Utils_Tuple2(1, 'Email is not valid')) : $elm$core$Maybe$Nothing;
	return A2(
		$elm$core$List$filterMap,
		function (a) {
			return a;
		},
		_List_fromArray(
			[emailError, passwordError]));
};
var $author$project$Views$Login$Login$update = F3(
	function (_v0, msg, model) {
		var env = _v0.fr;
		switch (msg.$) {
			case 0:
				var errors = $author$project$Views$Login$Login$validateForm(model);
				var cmd = (!$elm$core$List$length(errors)) ? A3($author$project$Request$User$login, model, env, $author$project$Views$Login$Login$Login) : $elm$core$Platform$Cmd$none;
				return _Utils_Tuple2(
					_Utils_Tuple2(
						_Utils_update(
							model,
							{ft: errors}),
						cmd),
					$author$project$Views$Login$Login$NoOp);
			case 3:
				var password = msg.a;
				return _Utils_Tuple2(
					_Utils_Tuple2(
						_Utils_update(
							model,
							{gQ: password}),
						$elm$core$Platform$Cmd$none),
					$author$project$Views$Login$Login$NoOp);
			case 2:
				var email = msg.a;
				return _Utils_Tuple2(
					_Utils_Tuple2(
						_Utils_update(
							model,
							{fn: email}),
						$elm$core$Platform$Cmd$none),
					$author$project$Views$Login$Login$NoOp);
			case 1:
				if (!msg.a.$) {
					var user = msg.a.a;
					return _Utils_Tuple2(
						_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
						$author$project$Views$Login$Login$SetUser(user));
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_Tuple2(
							_Utils_update(
								model,
								{
									ft: _List_fromArray(
										[
											_Utils_Tuple2(0, error)
										])
								}),
							$elm$core$Platform$Cmd$none),
						$author$project$Views$Login$Login$NoOp);
				}
			default:
				if (!msg.a.$) {
					var user = msg.a.a;
					return _Utils_Tuple2(
						_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
						A2($author$project$Views$Login$Login$SetUserAndRedirect, user, model.aK));
				} else {
					return _Utils_Tuple2(
						_Utils_Tuple2(
							_Utils_update(
								model,
								{as: false, aK: $elm$core$Maybe$Nothing}),
							$elm$core$Platform$Cmd$none),
						$author$project$Views$Login$Login$NoOp);
				}
		}
	});
var $krisajenkins$remotedata$RemoteData$Loading = {$: 1};
var $author$project$Views$MapArea$Msg$MapAreaUpload = function (a) {
	return {$: 3, a: a};
};
var $author$project$Views$MapArea$Msg$MapLoaded = function (a) {
	return {$: 2, a: a};
};
var $elm$file$File$Select$file = F2(
	function (mimes, toMsg) {
		return A2(
			$elm$core$Task$perform,
			toMsg,
			_File_uploadOne(mimes));
	});
var $author$project$Request$HttpClient$authenticatedUploadWithStringResponse = F4(
	function (token, url, value, msg) {
		return $elm$http$Http$request(
			A5(
				$author$project$Request$HttpClient$requestParams,
				'POST',
				_List_fromArray(
					[
						A2($elm$http$Http$header, 'X-Authentication', token)
					]),
				url,
				value,
				$elm$http$Http$expectString(msg)));
	});
var $elm$http$Http$filePart = _Http_pair;
var $author$project$Request$MapArea$mapAreaUrl = function (env) {
	return $author$project$Request$Url$apiRailsUrl(env) + 'api/v2/routes/upload_map';
};
var $elm$http$Http$multipartBody = function (parts) {
	return A2(
		_Http_pair,
		'',
		_Http_toFormData(parts));
};
var $author$project$Request$MapArea$mapArea = F3(
	function (file, _v0, msg) {
		var user = _v0.d7;
		var env = _v0.fr;
		return A4(
			$author$project$Request$HttpClient$authenticatedUploadWithStringResponse,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.bs;
					},
					user)),
			$author$project$Request$MapArea$mapAreaUrl(env),
			$elm$http$Http$multipartBody(
				_List_fromArray(
					[
						A2($elm$http$Http$filePart, 'map', file)
					])),
			msg);
	});
var $author$project$Views$MapArea$Update$update = F3(
	function (context, msg, model) {
		switch (msg.$) {
			case 0:
				var _v1 = model.fB;
				if (!_v1.$) {
					var cmd = A2(
						$elm$core$Maybe$withDefault,
						$elm$core$Platform$Cmd$none,
						A2(
							$elm$core$Maybe$map,
							function (file) {
								return A3($author$project$Request$MapArea$mapArea, file, context, $author$project$Views$MapArea$Msg$MapAreaUpload);
							},
							model.fB));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{dq: $krisajenkins$remotedata$RemoteData$Loading}),
						cmd);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dq: $krisajenkins$remotedata$RemoteData$NotAsked}),
					A2($elm$file$File$Select$file, _List_Nil, $author$project$Views$MapArea$Msg$MapLoaded));
			case 2:
				var file = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							fB: $elm$core$Maybe$Just(file)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				var result = msg.a;
				if (!result.$) {
					var value = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								dq: $krisajenkins$remotedata$RemoteData$Success(value)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								dq: $krisajenkins$remotedata$RemoteData$Failure(error)
							}),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Views$MapAssignment$Msg$AccuracyQualityMsg = function (a) {
	return {$: 17, a: a};
};
var $author$project$Page$AutomaticRoute$Msg$AddAreas = function (a) {
	return {$: 12, a: a};
};
var $author$project$Views$MapAssignment$Msg$AddDriverToRoute = function (a) {
	return {$: 32, a: a};
};
var $author$project$Views$MapAssignment$Msg$AddOrderToRoute = F2(
	function (a, b) {
		return {$: 24, a: a, b: b};
	});
var $author$project$Page$AutomaticRoute$Msg$AddOrders = function (a) {
	return {$: 11, a: a};
};
var $author$project$Views$MapAssignment$Msg$AddToRouteContainer = function (a) {
	return {$: 22, a: a};
};
var $author$project$Page$AutomaticRoute$Msg$AddWarehouses = function (a) {
	return {$: 13, a: a};
};
var $author$project$Views$MapAssignment$Msg$AlertMsg = function (a) {
	return {$: 85, a: a};
};
var $author$project$Views$MapAssignment$Msg$AttachOrderRequest = function (a) {
	return {$: 34, a: a};
};
var $author$project$Ports$Both = 2;
var $author$project$Views$MapAssignment$RouteContainer$Changing = function (a) {
	return {$: 2, a: a};
};
var $author$project$Views$MapAssignment$Msg$CheckRouteJob = F2(
	function (a, b) {
		return {$: 94, a: a, b: b};
	});
var $author$project$Views$MapAssignment$Msg$CreateRoute = function (a) {
	return {$: 8, a: a};
};
var $author$project$Views$MapAssignment$Msg$DeleteRouteRequest = function (a) {
	return {$: 38, a: a};
};
var $author$project$Ports$DiscardRouteJobId = function (a) {
	return {$: 12, a: a};
};
var $author$project$Ports$DiscardRouteJobInfo = {$: 3};
var $author$project$Views$MapAssignment$Msg$DriversMsg = function (a) {
	return {$: 13, a: a};
};
var $author$project$Views$MapAssignment$RouteContainer$Errored = function (a) {
	return {$: 3, a: a};
};
var $author$project$Views$MapAssignment$Model$Failed = 1;
var $author$project$Views$MapAssignment$Msg$FetchKpisForUnsavedExecutions = F2(
	function (a, b) {
		return {$: 111, a: a, b: b};
	});
var $author$project$Views$MapAssignment$Msg$FetchRouteData = F2(
	function (a, b) {
		return {$: 63, a: a, b: b};
	});
var $author$project$Views$MapAssignment$Model$Finished = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Views$MapAssignment$Model$Generating = 2;
var $author$project$Views$MapAssignment$Msg$GetKpiResponse = F2(
	function (a, b) {
		return {$: 112, a: a, b: b};
	});
var $author$project$Views$MapAssignment$Msg$HideAllRoutesRequest = function (a) {
	return {$: 40, a: a};
};
var $author$project$Views$MapAssignment$Msg$HideSelectedRoutesRequest = function (a) {
	return {$: 79, a: a};
};
var $author$project$Views$MapAssignment$Model$NotGenerating = 0;
var $author$project$Views$MapAssignment$Model$Opened = function (a) {
	return {$: 1, a: a};
};
var $author$project$Views$MapAssignment$RouteContainer$Optimized = {$: 2};
var $author$project$Views$MapAssignment$RouteContainer$OptimizingInProgress = {$: 1};
var $author$project$Views$MapAssignment$Msg$OrdersScrollMsg = function (a) {
	return {$: 96, a: a};
};
var $author$project$Views$MapAssignment$Msg$PackagesMsg = function (a) {
	return {$: 12, a: a};
};
var $author$project$Ports$PlayAlertSound = {$: 7};
var $author$project$Views$MapAssignment$Msg$PostalCodeMsg = function (a) {
	return {$: 11, a: a};
};
var $author$project$Views$MapAssignment$Msg$RetailersMsg = function (a) {
	return {$: 14, a: a};
};
var $author$project$Views$MapAssignment$Msg$RouteResetResponseReceived = function (a) {
	return {$: 27, a: a};
};
var $author$project$Views$MapAssignment$RouteContainer$SaveInProgress = {$: 1};
var $author$project$Views$MapAssignment$RouteContainer$Saved = function (a) {
	return {$: 0, a: a};
};
var $author$project$Ports$SelectedWarehouse = 1;
var $author$project$Views$MapAssignment$Model$Selecting = F4(
	function (a, b, c, d) {
		return {$: 1, a: a, b: b, c: c, d: d};
	});
var $author$project$Views$MapAssignment$Msg$ServiceTypeMsg = function (a) {
	return {$: 16, a: a};
};
var $author$project$Views$MapAssignment$Msg$SetIfRouteCanBeReset = function (a) {
	return {$: 28, a: a};
};
var $author$project$Ports$SetManualRouteSettingsExpired = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $author$project$Views$MapAssignment$Msg$SetNameToRouteResponse = function (a) {
	return {$: 68, a: a};
};
var $author$project$Ports$SetTimerToKeepOptimizeButtonsDisabled = {$: 10};
var $author$project$Views$MapAssignment$Update$ShouldFetchUpcomingOrders = 0;
var $author$project$Views$MapAssignment$Types$ShowingTotalStats = 1;
var $author$project$Ports$StartJobTimer = function (a) {
	return {$: 5, a: a};
};
var $author$project$Ports$StartTime = 0;
var $author$project$Ports$StopJobTimer = function (a) {
	return {$: 6, a: a};
};
var $author$project$Ports$StoreRouteJobId = function (a) {
	return {$: 11, a: a};
};
var $author$project$Views$MapAssignment$Msg$StoresMsg = function (a) {
	return {$: 15, a: a};
};
var $author$project$Views$MapAssignment$Msg$SyncOptimizeRequest = F2(
	function (a, b) {
		return {$: 62, a: a, b: b};
	});
var $author$project$Views$MapAssignment$Msg$TimeRangeMsg = function (a) {
	return {$: 10, a: a};
};
var $author$project$Views$MapAssignment$RouteContainer$UnAssigned = {$: 0};
var $author$project$Page$AutomaticRoute$Msg$UpdateMapStyle = function (a) {
	return {$: 19, a: a};
};
var $author$project$Ports$UpdatePageTitle = function (a) {
	return {$: 8, a: a};
};
var $author$project$Views$MapAssignment$Msg$ZoomToLocation = function (a) {
	return {$: 20, a: a};
};
var $author$project$Views$MapAssignment$Update$accuracyQualityMapperFn = function (orders) {
	return A2(
		$elm$core$List$map,
		function (order) {
			var _v0 = order.gK;
			if (_v0 === 1) {
				return order.gT.fL;
			} else {
				return order.e6.fL;
			}
		},
		orders);
};
var $author$project$Data$Address$accuracyQualityToString = function (quality) {
	switch (quality) {
		case 0:
			return 'Good';
		case 1:
			return 'Medium';
		case 2:
			return 'Low';
		default:
			return 'Unknown';
	}
};
var $author$project$Request$Route$addOrdersToRouteUrl = function (env) {
	return $author$project$Request$Url$apiRailsUrl(env) + 'api/v2/routes/add_orders';
};
var $author$project$Request$HttpClient$authenticatedPost = F5(
	function (token, url, value, decoder, msg) {
		return $elm$http$Http$request(
			A5(
				$author$project$Request$HttpClient$requestParams,
				'POST',
				_List_fromArray(
					[
						A2($elm$http$Http$header, 'X-Authentication', token)
					]),
				url,
				$elm$http$Http$jsonBody(value),
				A2($elm$http$Http$expectJson, msg, decoder)));
	});
var $author$project$Request$Route$ordersToRouteEncoder = F2(
	function (routeId, order_ids) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'route_id',
					$elm$json$Json$Encode$string(
						$elm$core$String$fromInt(routeId))),
					_Utils_Tuple2(
					'order_ids',
					$elm$json$Json$Encode$string(order_ids))
				]));
	});
var $author$project$Request$Route$addOrdersToRoute = F4(
	function (_v0, routeId, orders, msg) {
		var user = _v0.d7;
		var env = _v0.fr;
		var order_ids = A2(
			$elm$core$String$join,
			',',
			A2(
				$elm$core$List$map,
				$elm$core$String$fromInt,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.fS;
					},
					orders)));
		return A5(
			$author$project$Request$HttpClient$authenticatedPost,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.bs;
					},
					user)),
			$author$project$Request$Route$addOrdersToRouteUrl(env),
			A2($author$project$Request$Route$ordersToRouteEncoder, routeId, order_ids),
			$author$project$Data$Route$decoder,
			msg);
	});
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			$elm$core$List$any,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, isOkay),
			list);
	});
var $elm$core$Dict$filter = F2(
	function (isGood, dict) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, v, d) {
					return A2(isGood, k, v) ? A3($elm$core$Dict$insert, k, v, d) : d;
				}),
			$elm$core$Dict$empty,
			dict);
	});
var $author$project$Views$Common$Select$getSelectedOptions = function (model) {
	return A2(
		$elm$core$List$map,
		function (_v1) {
			var value = _v1.y;
			return value;
		},
		A2(
			$elm$core$List$filter,
			function (_v0) {
				var selected = _v0.D;
				return selected;
			},
			model.b));
};
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $author$project$Views$MapAssignment$Update$filterByAddressAccuracy = F2(
	function (select, orders) {
		var _v0 = $author$project$Views$Common$Select$getSelectedOptions(select);
		if (!_v0.b) {
			return orders;
		} else {
			var accuracyPoints = _v0;
			return A2(
				$elm$core$Dict$filter,
				F2(
					function (_v1, order) {
						var accuracyQuality = function () {
							var _v2 = order.gK;
							if (_v2 === 1) {
								return order.gT.fL;
							} else {
								return order.e6.fL;
							}
						}();
						return A2($elm$core$List$member, accuracyQuality, accuracyPoints);
					}),
				orders);
		}
	});
var $author$project$Views$MapAssignment$Update$filterByCompany = F3(
	function (companies, _v0, order) {
		return A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				function (ocompany) {
					return A2(
						$elm$core$List$any,
						function (company) {
							return _Utils_eq(ocompany.fS, company.fS);
						},
						companies);
				},
				order.eT));
	});
var $author$project$Views$MapAssignment$Update$filterByCompanies = F2(
	function (select, orders) {
		var _v0 = $author$project$Views$Common$Select$getSelectedOptions(select);
		if (!_v0.b) {
			return orders;
		} else {
			var companies = _v0;
			return A2(
				$elm$core$Dict$filter,
				$author$project$Views$MapAssignment$Update$filterByCompany(companies),
				orders);
		}
	});
var $author$project$Views$Helpers$Date$isSameDay = F3(
	function (zone, date1, date2) {
		return _Utils_eq(
			A2($elm$time$Time$toDay, zone, date1),
			A2($elm$time$Time$toDay, zone, date2)) && (_Utils_eq(
			A2($elm$time$Time$toMonth, zone, date1),
			A2($elm$time$Time$toMonth, zone, date2)) && _Utils_eq(
			A2($elm$time$Time$toYear, zone, date1),
			A2($elm$time$Time$toYear, zone, date2)));
	});
var $author$project$Views$MapAssignment$Model$filterByDate = F2(
	function (_v0, orders) {
		var zone = _v0.em;
		var time = _v0.d_;
		return A2(
			$elm$core$Dict$filter,
			F2(
				function (_v1, order) {
					return A3($author$project$Views$Helpers$Date$isSameDay, zone, order.e7.fp, time);
				}),
			orders);
	});
var $author$project$Views$MapAssignment$Update$filterByDriver = F3(
	function (drivers, _v0, order) {
		return A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				function (odriver) {
					return A2(
						$elm$core$List$any,
						function (driver) {
							return _Utils_eq(odriver.fS, driver.fS);
						},
						drivers);
				},
				order.fh));
	});
var $author$project$Views$MapAssignment$Update$filterByDrivers = F2(
	function (select, orders) {
		var _v0 = $author$project$Views$Common$Select$getSelectedOptions(select);
		if (!_v0.b) {
			return orders;
		} else {
			var drivers = _v0;
			return A2(
				$elm$core$Dict$filter,
				$author$project$Views$MapAssignment$Update$filterByDriver(drivers),
				orders);
		}
	});
var $author$project$Views$MapAssignment$Update$filterByNumPackage = F3(
	function (nums, _v0, order) {
		return A2(
			$elm$core$List$any,
			function (num) {
				return (order.gK === 1) ? true : _Utils_eq(order.gD, num);
			},
			nums);
	});
var $author$project$Views$MapAssignment$Update$filterByNumPackages = F2(
	function (select, orders) {
		var _v0 = $author$project$Views$Common$Select$getSelectedOptions(select);
		if (!_v0.b) {
			return orders;
		} else {
			var num = _v0;
			return A2(
				$elm$core$Dict$filter,
				$author$project$Views$MapAssignment$Update$filterByNumPackage(num),
				orders);
		}
	});
var $elm$core$String$toLower = _String_toLower;
var $author$project$Views$MapAssignment$Update$filterByOrderSearch = F2(
	function (maybeString, orders) {
		if (!maybeString.$) {
			var query = maybeString.a;
			return A2(
				$elm$core$Dict$filter,
				F2(
					function (_v1, order) {
						return A2($elm$core$String$contains, query, order.gO) || (A2(
							$elm$core$String$contains,
							query,
							$elm$core$String$toLower(
								A2($elm$core$Maybe$withDefault, '', order.hf))) || (A2(
							$elm$core$String$contains,
							query,
							$elm$core$String$toLower(
								A2($elm$core$Maybe$withDefault, '', order.g5))) || (A2(
							$elm$core$String$contains,
							query,
							$elm$core$String$toLower(
								A2($elm$core$Maybe$withDefault, '', order.e6.gs))) || A2(
							$elm$core$String$contains,
							query,
							$elm$core$String$toLower(
								A2($elm$core$Maybe$withDefault, '', order.e6.hH))))));
					}),
				orders);
		} else {
			return orders;
		}
	});
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $elm$core$Set$member = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$member, key, dict);
	});
var $author$project$Utils$ifThenElse = F3(
	function (cond, expA, expB) {
		return cond ? expA : expB;
	});
var $author$project$Views$MapAssignment$Utils$addressPinLatLon = function (order) {
	return A3($author$project$Utils$ifThenElse, order.gK === 1, order.gT.ga, order.e6.ga);
};
var $elm$core$Elm$JsArray$foldl = _JsArray_foldl;
var $elm$core$Array$foldl = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldl, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldl, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldl,
			func,
			A3($elm$core$Elm$JsArray$foldl, helper, baseCase, tree),
			tail);
	});
var $elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, list);
			var jsArray = _v0.a;
			var remainingItems = _v0.b;
			if (_Utils_cmp(
				$elm$core$Elm$JsArray$length(jsArray),
				$elm$core$Array$branchFactor) < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					true,
					{i: nodeList, f: nodeListSize, h: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					$elm$core$List$cons,
					$elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var $elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return $elm$core$Array$empty;
	} else {
		return A3($elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var $elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = $elm$core$Array$bitMask & (index >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_v0.$) {
				var subTree = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _v0.a;
				return A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, values);
			}
		}
	});
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var $elm$core$Array$get = F2(
	function (index, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? $elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? $elm$core$Maybe$Just(
			A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, tail)) : $elm$core$Maybe$Just(
			A3($elm$core$Array$getHelp, startShift, index, tree)));
	});
var $author$project$Data$Map$isThePointInsideOfArea = F2(
	function (poly, point) {
		var _v0 = $elm$core$List$head(poly);
		if (!_v0.$) {
			var head = _v0.a;
			var initialAcc = {ax: head.f8, ay: head.gb, az: head.f8, aA: head.gb};
			return function (finalAcc) {
				return ((_Utils_cmp(point.f8, finalAcc.az) < 0) || ((_Utils_cmp(point.f8, finalAcc.ax) > 0) || ((_Utils_cmp(point.gb, finalAcc.aA) < 0) || (_Utils_cmp(point.gb, finalAcc.ay) > 0)))) ? false : A3(
					$elm$core$Array$foldl,
					F2(
						function (_v1, acc) {
							var result = function () {
								var _v2 = _Utils_Tuple2(
									A2($elm$core$Array$get, acc.ac, acc.ai),
									A2($elm$core$Array$get, acc.a$, acc.ai));
								if ((!_v2.a.$) && (!_v2.b.$)) {
									var pointI = _v2.a.a;
									var pointJ = _v2.b.a;
									return ((!_Utils_eq(
										_Utils_cmp(pointI.gb, point.gb) > 0,
										_Utils_cmp(pointJ.gb, point.gb) > 0)) && (_Utils_cmp(point.f8, (((pointJ.f8 - pointI.f8) * (point.gb - pointI.gb)) / (pointJ.gb - pointI.gb)) + pointI.f8) < 0)) ? (!acc.W) : acc.W;
								} else {
									return acc.W;
								}
							}();
							return {ac: acc.ac + 1, a$: acc.ac, W: result, ai: acc.ai};
						}),
					{
						ac: 0,
						a$: $elm$core$List$length(poly) - 1,
						W: false,
						ai: $elm$core$Array$fromList(poly)
					},
					$elm$core$Array$fromList(poly)).W;
			}(
				A3(
					$elm$core$List$foldl,
					F2(
						function (currentVal, acc) {
							return {
								ax: A2($elm$core$Basics$max, currentVal.f8, acc.ax),
								ay: A2($elm$core$Basics$max, currentVal.gb, acc.ay),
								az: A2($elm$core$Basics$min, currentVal.f8, acc.az),
								aA: A2($elm$core$Basics$min, currentVal.gb, acc.aA)
							};
						}),
					initialAcc,
					A2($elm$core$List$drop, 1, poly)));
		} else {
			return false;
		}
	});
var $author$project$Views$MapAssignment$Update$ordersInsidePolygon = F2(
	function (orders, polygon) {
		var isOrderInsideOfArea = function (order) {
			return A2(
				$elm$core$Maybe$withDefault,
				false,
				A2(
					$elm$core$Maybe$map,
					$author$project$Data$Map$isThePointInsideOfArea(polygon),
					$author$project$Views$MapAssignment$Utils$addressPinLatLon(order)));
		};
		return A2($elm$core$List$filter, isOrderInsideOfArea, orders);
	});
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $author$project$Views$MapAssignment$Model$ordersListToDict = function (orders) {
	return $elm$core$Dict$fromList(
		A2(
			$elm$core$List$map,
			function (order) {
				return _Utils_Tuple2(order.fS, order);
			},
			orders));
};
var $elm_community$list_extra$List$Extra$uniqueHelp = F4(
	function (f, existing, remaining, accumulator) {
		uniqueHelp:
		while (true) {
			if (!remaining.b) {
				return $elm$core$List$reverse(accumulator);
			} else {
				var first = remaining.a;
				var rest = remaining.b;
				var computedFirst = f(first);
				if (A2($elm$core$List$member, computedFirst, existing)) {
					var $temp$f = f,
						$temp$existing = existing,
						$temp$remaining = rest,
						$temp$accumulator = accumulator;
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				} else {
					var $temp$f = f,
						$temp$existing = A2($elm$core$List$cons, computedFirst, existing),
						$temp$remaining = rest,
						$temp$accumulator = A2($elm$core$List$cons, first, accumulator);
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$uniqueBy = F2(
	function (f, list) {
		return A4($elm_community$list_extra$List$Extra$uniqueHelp, f, _List_Nil, list, _List_Nil);
	});
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $author$project$Views$MapAssignment$Update$ordersInsidePolygons = F3(
	function (excluded, allOrders, polygons) {
		var orders = A2(
			$elm$core$Dict$filter,
			F2(
				function (_v0, order) {
					return !A2($elm$core$Set$member, order.fS, excluded);
				}),
			allOrders);
		return $author$project$Views$MapAssignment$Model$ordersListToDict(
			A2(
				$elm_community$list_extra$List$Extra$uniqueBy,
				function ($) {
					return $.fS;
				},
				A2(
					$elm$core$List$concatMap,
					$author$project$Views$MapAssignment$Update$ordersInsidePolygon(
						$elm$core$Dict$values(orders)),
					polygons)));
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $elm$core$Set$union = F2(
	function (_v0, _v1) {
		var dict1 = _v0;
		var dict2 = _v1;
		return A2($elm$core$Dict$union, dict1, dict2);
	});
var $author$project$Views$MapAssignment$Update$filterByPolygons = F2(
	function (model, orders) {
		var _v0 = model.fG.c8;
		switch (_v0.$) {
			case 0:
				return orders;
			case 1:
				return orders;
			default:
				var excluded = _v0.a;
				var polygons = _v0.b;
				var allExcluded = A2($elm$core$Set$union, model.hc.c0, excluded);
				return A3($author$project$Views$MapAssignment$Update$ordersInsidePolygons, allExcluded, orders, polygons);
		}
	});
var $author$project$Views$MapAssignment$Update$filterByPostalCode = F3(
	function (codes, _v0, _v1) {
		var orderType = _v1.gK;
		var deliveryAddress = _v1.e6;
		var pickupAddress = _v1.gT;
		if (orderType === 1) {
			return A2(
				$elm$core$Maybe$withDefault,
				false,
				A2(
					$elm$core$Maybe$map,
					function (code) {
						return A2($elm$core$List$member, code, codes);
					},
					pickupAddress.gX));
		} else {
			return A2(
				$elm$core$Maybe$withDefault,
				false,
				A2(
					$elm$core$Maybe$map,
					function (code) {
						return A2($elm$core$List$member, code, codes);
					},
					deliveryAddress.gX));
		}
	});
var $elm$core$String$trim = _String_trim;
var $author$project$Views$MapAssignment$Update$filterByPostalCodesList = F2(
	function (postalCodes, orders) {
		var cleanPostalCodes = A2(
			$elm$core$List$filter,
			A2($elm$core$Basics$composeR, $elm$core$String$isEmpty, $elm$core$Basics$not),
			A2(
				$elm$core$List$map,
				$elm$core$String$trim,
				A2($elm$core$String$split, '\n', postalCodes)));
		if (!cleanPostalCodes.b) {
			return orders;
		} else {
			var codes = cleanPostalCodes;
			return A2(
				$elm$core$Dict$filter,
				$author$project$Views$MapAssignment$Update$filterByPostalCode(codes),
				orders);
		}
	});
var $author$project$Views$MapAssignment$Update$filterByPostalCodesValues = F2(
	function (select, orders) {
		var _v0 = $author$project$Views$Common$Select$getSelectedOptions(select);
		if (!_v0.b) {
			return orders;
		} else {
			var codes = _v0;
			return A2(
				$elm$core$Dict$filter,
				$author$project$Views$MapAssignment$Update$filterByPostalCode(codes),
				orders);
		}
	});
var $author$project$Views$MapAssignment$Update$filterByPostalCodes = F2(
	function (model, orders) {
		var _v0 = model.fG.aI;
		if (!_v0) {
			return A2($author$project$Views$MapAssignment$Update$filterByPostalCodesValues, model.fG.gY, orders);
		} else {
			return A2($author$project$Views$MapAssignment$Update$filterByPostalCodesList, model.fG.gZ, orders);
		}
	});
var $author$project$Views$MapAssignment$Update$filterByServiceType = F3(
	function (serviceTypes, _v0, order) {
		return A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				function (serviceType) {
					return A2($elm$core$List$member, serviceType, serviceTypes);
				},
				order.hj));
	});
var $author$project$Views$MapAssignment$Update$filterByServiceTypes = F2(
	function (select, orders) {
		var _v0 = $author$project$Views$Common$Select$getSelectedOptions(select);
		if (!_v0.b) {
			return orders;
		} else {
			var serviceTypes = _v0;
			return A2(
				$elm$core$Dict$filter,
				$author$project$Views$MapAssignment$Update$filterByServiceType(serviceTypes),
				orders);
		}
	});
var $author$project$Views$MapAssignment$Update$filterByStore = F3(
	function (stores, _v0, order) {
		return A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				function (ostore) {
					return A2(
						$elm$core$List$any,
						function (store) {
							return _Utils_eq(ostore.fS, store.fS);
						},
						stores);
				},
				order.hw));
	});
var $author$project$Views$MapAssignment$Update$filterByStores = F2(
	function (select, orders) {
		var _v0 = $author$project$Views$Common$Select$getSelectedOptions(select);
		if (!_v0.b) {
			return orders;
		} else {
			var stores = _v0;
			return A2(
				$elm$core$Dict$filter,
				$author$project$Views$MapAssignment$Update$filterByStore(stores),
				orders);
		}
	});
var $author$project$Views$MapAssignment$Update$inTimeRange = F3(
	function (zone, order, _v0) {
		var start = _v0.a;
		var end = _v0.b;
		return _Utils_eq(
			A2($elm$time$Time$toHour, zone, order.e7.hq),
			A2($elm$time$Time$toHour, zone, start)) && _Utils_eq(
			A2($elm$time$Time$toHour, zone, order.e7.fp),
			A2($elm$time$Time$toHour, zone, end));
	});
var $author$project$Views$MapAssignment$Update$filterByTimeRange = F3(
	function (zone, timeRange, orders) {
		var _v0 = $author$project$Views$Common$Select$getSelectedOptions(timeRange);
		if (!_v0.b) {
			return orders;
		} else {
			var times = _v0;
			return A2(
				$elm$core$Dict$filter,
				F2(
					function (_v1, order) {
						return A2(
							$elm$core$List$any,
							A2($author$project$Views$MapAssignment$Update$inTimeRange, zone, order),
							times);
					}),
				orders);
		}
	});
var $elm$core$Maybe$map3 = F4(
	function (func, ma, mb, mc) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				if (mc.$ === 1) {
					return $elm$core$Maybe$Nothing;
				} else {
					var c = mc.a;
					return $elm$core$Maybe$Just(
						A3(func, a, b, c));
				}
			}
		}
	});
var $author$project$Views$Helpers$Maybe$maybeOr = F2(
	function (maybeA, maybeB) {
		if (!maybeA.$) {
			return maybeA;
		} else {
			return maybeB;
		}
	});
var $author$project$Views$MapAssignment$Utils$calculateVolume = function (_package) {
	var dimVolume = A2(
		$elm$core$Maybe$map,
		function (vol) {
			var units = A2($elm$core$Maybe$withDefault, 1, _package.hR);
			return units * vol;
		},
		A4(
			$elm$core$Maybe$map3,
			F3(
				function (w, h, l) {
					return ((w * h) * l) / 1000;
				}),
			_package.ef,
			_package.ci,
			_package.cE));
	return A2(
		$elm$core$Maybe$withDefault,
		0,
		A2($author$project$Views$Helpers$Maybe$maybeOr, _package.bo, dimVolume));
};
var $elm$core$List$sum = function (numbers) {
	return A3($elm$core$List$foldl, $elm$core$Basics$add, 0, numbers);
};
var $author$project$Views$MapAssignment$Utils$calculateTotalVolume = function (packages) {
	return $elm$core$List$sum(
		A2($elm$core$List$map, $author$project$Views$MapAssignment$Utils$calculateVolume, packages));
};
var $author$project$Views$MapAssignment$Update$filterByPackagesVolume = F3(
	function (_v0, fx, packages) {
		return A2(
			$elm$core$Dict$filter,
			F2(
				function (_v1, subPackages) {
					return fx(
						$author$project$Views$MapAssignment$Utils$calculateTotalVolume(subPackages));
				}),
			packages);
	});
var $author$project$Views$MapAssignment$Update$getOrdersDict = F2(
	function (orders, dictWithIds) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (id, _v0, acc) {
					var _v1 = A2($elm$core$Dict$get, id, orders);
					if (!_v1.$) {
						var order = _v1.a;
						return A3($elm$core$Dict$insert, id, order, acc);
					} else {
						return acc;
					}
				}),
			$elm$core$Dict$empty,
			dictWithIds);
	});
var $author$project$Views$MapAssignment$Update$Equal = function (a) {
	return {$: 2, a: a};
};
var $author$project$Views$MapAssignment$Update$Invalid = {$: 3};
var $author$project$Views$MapAssignment$Update$Less = function (a) {
	return {$: 0, a: a};
};
var $author$project$Views$MapAssignment$Update$More = function (a) {
	return {$: 1, a: a};
};
var $author$project$Views$MapAssignment$Update$stringToVolumeQuery = function (str) {
	var _v0 = $elm$core$String$uncons(str);
	_v0$3:
	while (true) {
		if (!_v0.$) {
			switch (_v0.a.a) {
				case '>':
					var _v1 = _v0.a;
					var x = _v1.b;
					return A2(
						$elm$core$Maybe$withDefault,
						$author$project$Views$MapAssignment$Update$Invalid,
						A2(
							$elm$core$Maybe$map,
							$author$project$Views$MapAssignment$Update$More,
							$elm$core$String$toFloat(x)));
				case '<':
					var _v2 = _v0.a;
					var x = _v2.b;
					return A2(
						$elm$core$Maybe$withDefault,
						$author$project$Views$MapAssignment$Update$Invalid,
						A2(
							$elm$core$Maybe$map,
							$author$project$Views$MapAssignment$Update$Less,
							$elm$core$String$toFloat(x)));
				case '=':
					var _v3 = _v0.a;
					var x = _v3.b;
					return A2(
						$elm$core$Maybe$withDefault,
						$author$project$Views$MapAssignment$Update$Invalid,
						A2(
							$elm$core$Maybe$map,
							$author$project$Views$MapAssignment$Update$Equal,
							$elm$core$String$toFloat(x)));
				default:
					break _v0$3;
			}
		} else {
			break _v0$3;
		}
	}
	return $author$project$Views$MapAssignment$Update$Invalid;
};
var $author$project$Views$MapAssignment$Update$filterByVolume = F3(
	function (query, packages, orders) {
		var _v0 = $author$project$Views$MapAssignment$Update$stringToVolumeQuery(query);
		switch (_v0.$) {
			case 0:
				var val = _v0.a;
				return A2(
					$author$project$Views$MapAssignment$Update$getOrdersDict,
					orders,
					A3(
						$author$project$Views$MapAssignment$Update$filterByPackagesVolume,
						val,
						function (vol) {
							return _Utils_cmp(vol, val) < 0;
						},
						packages));
			case 1:
				var val = _v0.a;
				return A2(
					$author$project$Views$MapAssignment$Update$getOrdersDict,
					orders,
					A3(
						$author$project$Views$MapAssignment$Update$filterByPackagesVolume,
						val,
						function (vol) {
							return _Utils_cmp(vol, val) > 0;
						},
						packages));
			case 2:
				var val = _v0.a;
				return A2(
					$author$project$Views$MapAssignment$Update$getOrdersDict,
					orders,
					A3(
						$author$project$Views$MapAssignment$Update$filterByPackagesVolume,
						val,
						function (vol) {
							return _Utils_eq(vol, val);
						},
						packages));
			default:
				return orders;
		}
	});
var $author$project$Views$MapAssignment$Utils$filterTimeWindowIssues = function (orders) {
	return A2(
		$elm$core$Dict$filter,
		F2(
			function (_v0, order) {
				return (_Utils_cmp(
					A2($elm$time$Time$toHour, $elm$time$Time$utc, order.e7.hq),
					A2($elm$time$Time$toHour, $elm$time$Time$utc, order.e7.fp)) < 1) && _Utils_eq(
					A2($elm$time$Time$toDay, $elm$time$Time$utc, order.e7.hq),
					A2($elm$time$Time$toDay, $elm$time$Time$utc, order.e7.fp));
			}),
		orders);
};
var $author$project$Views$MapAssignment$Update$filterOrdersWithUnroutedIncluded = F3(
	function (context, model, orders) {
		return $author$project$Views$MapAssignment$Utils$filterTimeWindowIssues(
			A2(
				$author$project$Views$MapAssignment$Update$filterByPolygons,
				model,
				A3(
					$author$project$Views$MapAssignment$Update$filterByVolume,
					model.fG.bo,
					model.c6,
					A2(
						$author$project$Views$MapAssignment$Update$filterByAddressAccuracy,
						model.fG.fL,
						A2(
							$author$project$Views$MapAssignment$Update$filterByServiceTypes,
							model.fG.hk,
							A2(
								$author$project$Views$MapAssignment$Update$filterByStores,
								model.fG.dV,
								A2(
									$author$project$Views$MapAssignment$Update$filterByCompanies,
									model.fG.g6,
									A2(
										$author$project$Views$MapAssignment$Update$filterByDrivers,
										model.fG.b_,
										A2(
											$author$project$Views$MapAssignment$Update$filterByNumPackages,
											model.fG.c6,
											A2(
												$author$project$Views$MapAssignment$Update$filterByOrderSearch,
												model.fG.c_,
												A2(
													$author$project$Views$MapAssignment$Update$filterByPostalCodes,
													model,
													A2(
														$author$project$Views$MapAssignment$Model$filterByDate,
														context,
														A3($author$project$Views$MapAssignment$Update$filterByTimeRange, context.em, model.fG.bm, orders)))))))))))));
	});
var $author$project$Views$MapAssignment$Utils$inOrderList = F2(
	function (order, orders) {
		return A2($elm$core$Dict$member, order.fS, orders);
	});
var $author$project$Views$MapAssignment$Utils$isUnroutedOrder = F2(
	function (routeOrders, order) {
		return ((!order.ht) || (order.ht === 1)) && (!A2($author$project$Views$MapAssignment$Utils$inOrderList, order, routeOrders));
	});
var $author$project$Views$MapAssignment$Update$filterUnroutedOrders = F2(
	function (routeOrders, orders) {
		return A2(
			$elm$core$Dict$filter,
			F2(
				function (_v0, order) {
					return A2($author$project$Views$MapAssignment$Utils$isUnroutedOrder, routeOrders, order);
				}),
			orders);
	});
var $author$project$Views$MapAssignment$Model$routeOrders = function (model) {
	return $elm$core$Dict$fromList(
		A2(
			$elm$core$List$map,
			function (_v0) {
				var order = _v0.cZ;
				return _Utils_Tuple2(order.fS, order);
			},
			A2(
				$elm$core$List$concatMap,
				function (c) {
					return c.g9.gL;
				},
				model.hc.g8)));
};
var $author$project$Views$MapAssignment$Update$applyOrdersFilters = F2(
	function (context, model) {
		var filteredOrdersIds = $elm$core$Dict$keys(
			A3(
				$author$project$Views$MapAssignment$Update$filterOrdersWithUnroutedIncluded,
				context,
				model,
				A2(
					$author$project$Views$MapAssignment$Update$filterUnroutedOrders,
					$author$project$Views$MapAssignment$Model$routeOrders(model),
					model.gL)));
		return _Utils_update(
			model,
			{fF: filteredOrdersIds});
	});
var $author$project$Views$MapAssignment$Model$orderIdsToOrders = F2(
	function (orders, ids) {
		return A2(
			$elm$core$List$filterMap,
			function (id) {
				return A2($elm$core$Dict$get, id, orders);
			},
			ids);
	});
var $author$project$Config$ordersListScrollWindow = 10;
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $author$project$Views$MapAssignment$Update$updateOrdersScroll = function (model) {
	var ordersScroll = model.c3;
	var newOffset = (_Utils_cmp(ordersScroll.cV, $author$project$Config$ordersListScrollWindow) > 0) ? ordersScroll.cV : $author$project$Config$ordersListScrollWindow;
	var newContent = A2(
		$author$project$Views$MapAssignment$Model$orderIdsToOrders,
		model.gL,
		A2($elm$core$List$take, newOffset, model.fF));
	var newOrdersScroll = _Utils_update(
		ordersScroll,
		{bP: newContent, cV: newOffset});
	return _Utils_update(
		model,
		{c3: newOrdersScroll});
};
var $author$project$Views$MapAssignment$Update$filterOrders = F2(
	function (context, model) {
		return $author$project$Views$MapAssignment$Update$updateOrdersScroll(
			A2($author$project$Views$MapAssignment$Update$applyOrdersFilters, context, model));
	});
var $author$project$Views$MapAssignment$RouteContainer$replaceRouteContainer = F2(
	function (newRouteContainer, routeContainers) {
		return A2(
			$elm$core$List$map,
			function (container) {
				return _Utils_eq(container.g9.fS, newRouteContainer.g9.fS) ? newRouteContainer : container;
			},
			routeContainers);
	});
var $author$project$Views$MapAssignment$RouteContainer$maybeReplaceRouteContainer = F2(
	function (maybeRouteContainer, routeContainers) {
		if (!maybeRouteContainer.$) {
			var routeContainer = maybeRouteContainer.a;
			return A2($author$project$Views$MapAssignment$RouteContainer$replaceRouteContainer, routeContainer, routeContainers);
		} else {
			return routeContainers;
		}
	});
var $author$project$Views$MapAssignment$Update$addOrdersToSelectedRouteContainer = F3(
	function (context, orders, model) {
		var routes = model.hc;
		var maybeNewRouteContainer = A2(
			$elm$core$Maybe$map,
			function (container) {
				var oldCurrentRoute = container.g9;
				var oldOrders = A2(
					$elm$core$List$map,
					function ($) {
						return $.cZ;
					},
					oldCurrentRoute.gL);
				var isNewOrder = A2(
					$elm$core$List$any,
					function (order) {
						return A2(
							$elm$core$List$all,
							function (o) {
								return !_Utils_eq(o.fS, order.fS);
							},
							oldOrders);
					},
					orders);
				var newOrders = isNewOrder ? A2(
					$elm$core$List$indexedMap,
					F2(
						function (i, o) {
							return {b7: 0, cZ: o, gW: i};
						}),
					A2(
						$elm_community$list_extra$List$Extra$uniqueBy,
						function ($) {
							return $.fS;
						},
						A2($elm$core$List$append, oldOrders, orders))) : oldCurrentRoute.gL;
				var routeData = _Utils_update(
					oldCurrentRoute,
					{gL: newOrders});
				var saved = $author$project$Views$MapAssignment$RouteContainer$Saved(
					(!isNewOrder) && _Utils_eq(
						container.dA,
						$author$project$Views$MapAssignment$RouteContainer$Saved(true)));
				return _Utils_update(
					container,
					{g9: routeData, dA: saved});
			},
			routes.bd);
		var newRoutes = _Utils_update(
			routes,
			{
				b9: A2($author$project$Views$MapAssignment$RouteContainer$maybeReplaceRouteContainer, maybeNewRouteContainer, routes.b9),
				g8: A2($author$project$Views$MapAssignment$RouteContainer$maybeReplaceRouteContainer, maybeNewRouteContainer, routes.g8),
				bd: maybeNewRouteContainer
			});
		var containsOrders = F2(
			function (orders1, orders2) {
				return A2(
					$elm$core$List$any,
					function (order1) {
						return A2(
							$elm$core$List$any,
							function (order2) {
								return _Utils_eq(order1.fS, order2.fS);
							},
							orders2);
					},
					orders1);
			});
		var groupedOrders = (($elm$core$List$length(orders) > 1) && A2(containsOrders, model.cg, orders)) ? orders : model.cg;
		return A2(
			$author$project$Views$MapAssignment$Update$filterOrders,
			context,
			_Utils_update(
				model,
				{cg: groupedOrders, hc: newRoutes}));
	});
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Views$MapAssignment$Msg$AutomaticRouteMsg = function (a) {
	return {$: 92, a: a};
};
var $author$project$Views$MapAssignment$Msg$CloseDriverSelectionDialog = {$: 91};
var $author$project$Views$MapAssignment$Translators$automaticRoute = function (msg) {
	if (!msg.$) {
		return $author$project$Views$MapAssignment$Msg$CloseDriverSelectionDialog;
	} else {
		var msg_ = msg;
		return $author$project$Views$MapAssignment$Msg$AutomaticRouteMsg(msg_);
	}
};
var $author$project$Views$MapAssignment$RouteContainer$Assigned = function (a) {
	return {$: 1, a: a};
};
var $author$project$Views$MapAssignment$RouteContainer$Idle = {$: 0};
var $elm$core$List$sortBy = _List_sortBy;
var $author$project$Views$MapAssignment$Update$backendRouteTorouteContainer = function (backendRoute) {
	var routeDriver = function () {
		var _v0 = backendRoute.fh;
		if (!_v0.$) {
			var driver = _v0.a;
			return $author$project$Views$MapAssignment$RouteContainer$Assigned(driver);
		} else {
			return $author$project$Views$MapAssignment$RouteContainer$UnAssigned;
		}
	}();
	var numOfOrders = $elm$core$List$length(backendRoute.gL);
	var route = {
		eH: backendRoute.eH,
		e$: backendRoute.e$,
		fh: backendRoute.fh,
		fS: backendRoute.fS,
		gs: backendRoute.gs,
		gL: A2(
			$elm$core$List$indexedMap,
			F2(
				function (i, o) {
					return {b7: 0, cZ: o, gW: i};
				}),
			A2(
				$elm$core$List$sortBy,
				function (o) {
					return A2($elm$core$Maybe$withDefault, numOfOrders, o.gW);
				},
				backendRoute.gL)),
		ht: backendRoute.ht,
		hE: A2($elm$core$Maybe$withDefault, _List_Nil, backendRoute.hE),
		hW: ''
	};
	return {
		bw: false,
		bF: '',
		fk: false,
		fl: '',
		ct: false,
		f2: false,
		f3: $elm$core$Maybe$Nothing,
		f6: $krisajenkins$remotedata$RemoteData$NotAsked,
		ae: $author$project$Views$MapAssignment$RouteContainer$Idle,
		g9: route,
		ha: routeDriver,
		dA: $author$project$Views$MapAssignment$RouteContainer$Saved(true),
		ho: backendRoute.e2,
		dP: '',
		eh: ''
	};
};
var $author$project$Data$Job$JobStatusResponse = F2(
	function (jobId, status) {
		return {f3: jobId, ht: status};
	});
var $author$project$Data$Job$CANCELLED = 5;
var $author$project$Data$Job$COMPLETED = 1;
var $author$project$Data$Job$ENQUEUED = 4;
var $author$project$Data$Job$ERROR = 6;
var $author$project$Data$Job$INPROGRESS = 2;
var $author$project$Data$Job$OTHER = 7;
var $author$project$Data$Job$STARTED = 0;
var $author$project$Data$Job$UNKNOWN = 3;
var $author$project$Data$Job$jobStateDecoder = function (str) {
	switch (str) {
		case 'started':
			return $elm$json$Json$Decode$succeed(0);
		case 'completed':
			return $elm$json$Json$Decode$succeed(1);
		case 'in_progress':
			return $elm$json$Json$Decode$succeed(2);
		case 'unknown':
			return $elm$json$Json$Decode$succeed(3);
		case 'enqueued':
			return $elm$json$Json$Decode$succeed(4);
		case 'cancelled':
			return $elm$json$Json$Decode$succeed(5);
		case 'error':
			return $elm$json$Json$Decode$succeed(6);
		case 'failed':
			return $elm$json$Json$Decode$succeed(6);
		default:
			return $elm$json$Json$Decode$succeed(7);
	}
};
var $author$project$Data$Job$decoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2(
		$elm$json$Json$Decode$field,
		'status',
		A2($elm$json$Json$Decode$andThen, $author$project$Data$Job$jobStateDecoder, $elm$json$Json$Decode$string)),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'job_id', $elm$json$Json$Decode$string),
		$elm$json$Json$Decode$succeed($author$project$Data$Job$JobStatusResponse)));
var $author$project$Request$RouteResponse$routesJobsUrl = F2(
	function (env, jobId) {
		return $author$project$Request$Url$apiRailsUrl(env) + ('api/v2/routes/jobs/' + jobId);
	});
var $author$project$Request$RouteResponse$checkRouteJob = F3(
	function (_v0, jobId, msg) {
		var user = _v0.d7;
		var env = _v0.fr;
		return A4(
			$author$project$Request$HttpClient$authenticatedGet,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.bs;
					},
					user)),
			A2($author$project$Request$RouteResponse$routesJobsUrl, env, jobId),
			$author$project$Data$Job$decoder,
			msg);
	});
var $author$project$Views$MapAssignment$RouteContainer$closeAndResetForm = function (rc) {
	return _Utils_update(
		rc,
		{bF: '', ct: false, dP: '', eh: ''});
};
var $author$project$Utils$createReplaceAllIfNew = F2(
	function (newlyLoadedOrders, preExistingOrders) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (_v0, newlyLoadedOrder, acc) {
					var _v1 = A2($elm$core$Dict$get, newlyLoadedOrder.fS, acc);
					if (!_v1.$) {
						var preExistingOrder = _v1.a;
						return (_Utils_cmp(
							$elm$time$Time$posixToMillis(newlyLoadedOrder.hU),
							$elm$time$Time$posixToMillis(preExistingOrder.hU)) > 0) ? A3(
							$elm$core$Dict$update,
							preExistingOrder.fS,
							function (_v2) {
								return $elm$core$Maybe$Just(newlyLoadedOrder);
							},
							acc) : acc;
					} else {
						return A3($elm$core$Dict$insert, newlyLoadedOrder.fS, newlyLoadedOrder, acc);
					}
				}),
			preExistingOrders,
			newlyLoadedOrders);
	});
var $author$project$Request$HttpClient$authenticatedPostWithEmptyBody = F4(
	function (token, url, decoder, msg) {
		return $elm$http$Http$request(
			A5(
				$author$project$Request$HttpClient$requestParams,
				'POST',
				_List_fromArray(
					[
						A2($elm$http$Http$header, 'X-Authentication', token)
					]),
				url,
				$elm$http$Http$emptyBody,
				A2($elm$http$Http$expectJson, msg, decoder)));
	});
var $author$project$Request$Route$createRouteUrl = function (env) {
	return $author$project$Request$Url$apiRailsUrl(env) + 'api/v2/routes/new';
};
var $author$project$Request$Route$createRoute = F2(
	function (_v0, msg) {
		var user = _v0.d7;
		var env = _v0.fr;
		return A4(
			$author$project$Request$HttpClient$authenticatedPostWithEmptyBody,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.bs;
					},
					user)),
			$author$project$Request$Route$createRouteUrl(env),
			$author$project$Data$Route$decoder,
			msg);
	});
var $author$project$Ports$None = 3;
var $author$project$Ports$decodeExpirableSetting = function (data) {
	var _v0 = A2(
		$elm$json$Json$Decode$decodeValue,
		A2($elm$json$Json$Decode$field, 'setting', $elm$json$Json$Decode$string),
		data);
	if (!_v0.$) {
		var setting = _v0.a;
		switch (setting) {
			case 'StartTime':
				return 0;
			case 'SelectedWarehouse':
				return 1;
			case 'Both':
				return 2;
			default:
				return 3;
		}
	} else {
		return 3;
	}
};
var $author$project$Data$Route$RouteJobInfo = F2(
	function (jobId, orderIds) {
		return {f3: jobId, gJ: orderIds};
	});
var $author$project$Data$Route$decodeRouteJobInfo = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'orderIds',
	$elm$json$Json$Decode$list($elm$json$Json$Decode$int),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'jobId',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Data$Route$RouteJobInfo)));
var $author$project$Config$defaultLocation = A2($author$project$Data$Map$Coordinates, 40.418889, -3.691944);
var $author$project$Request$Route$deleteRouteUrl = function (env) {
	return $author$project$Request$Url$apiRailsUrl(env) + 'api/v2/routes/delete';
};
var $author$project$Request$Route$deleteRoute = F3(
	function (_v0, routeId, msg) {
		var user = _v0.d7;
		var env = _v0.fr;
		return A5(
			$author$project$Request$HttpClient$authenticatedPost,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.bs;
					},
					user)),
			$author$project$Request$Route$deleteRouteUrl(env),
			A2($author$project$Request$Route$ordersToRouteEncoder, routeId, ''),
			$author$project$Data$Route$decoder,
			msg);
	});
var $elm$core$Dict$diff = F2(
	function (t1, t2) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, v, t) {
					return A2($elm$core$Dict$remove, k, t);
				}),
			t1,
			t2);
	});
var $elm$core$Set$diff = F2(
	function (_v0, _v1) {
		var dict1 = _v0;
		var dict2 = _v1;
		return A2($elm$core$Dict$diff, dict1, dict2);
	});
var $PaackEng$elm_alert_beta$Alert$Error = 2;
var $PaackEng$elm_alert_beta$Alert$OnAlertAdded = function (a) {
	return {$: 0, a: a};
};
var $PaackEng$elm_alert_beta$Alert$Started = 0;
var $elm$core$Process$sleep = _Process_sleep;
var $PaackEng$elm_alert_beta$Alert$genericAlert = F3(
	function (message, alertType, _v0) {
		var state = _v0;
		var newId = state.a0 + 1;
		var newAlert = {fS: newId, gm: message, ht: 0, d5: alertType};
		return _Utils_Tuple2(
			_Utils_update(
				state,
				{
					bv: _Utils_ap(
						state.bv,
						_List_fromArray(
							[newAlert])),
					a0: newId
				}),
			A2(
				$elm$core$Task$perform,
				function (_v1) {
					return $PaackEng$elm_alert_beta$Alert$OnAlertAdded(newId);
				},
				$elm$core$Process$sleep(1)));
	});
var $PaackEng$elm_alert_beta$Alert$error = F2(
	function (message, state) {
		return A3($PaackEng$elm_alert_beta$Alert$genericAlert, message, 2, state);
	});
var $author$project$Data$RouteResponse$ExecutionKpiResponse = F5(
	function (id, routeKPIs, areaKPIs, status, error) {
		return {ew: areaKPIs, fs: error, fS: id, hb: routeKPIs, ht: status};
	});
var $author$project$Data$RouteResponse$AreaKPI = function (assignedOrdersCount) {
	return function (unassignedOrdersCount) {
		return function (productivity) {
			return function (distance) {
				return function (ordersCount) {
					return function (routeItemsCount) {
						return function (routesCount) {
							return function (stops) {
								return function (waitingHours) {
									return function (workingHours) {
										return {ez: assignedOrdersCount, ab: distance, c1: ordersCount, dd: productivity, dv: routeItemsCount, hd: routesCount, dS: stops, hP: unassignedOrdersCount, eb: waitingHours, eg: workingHours};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Data$RouteResponse$areaKpiDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'total_working_hours',
	$elm$json$Json$Decode$float,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'total_waiting_hours',
		$elm$json$Json$Decode$float,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'total_stops',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
			$elm$core$Maybe$Nothing,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'total_routes',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'total_route_items',
					$elm$json$Json$Decode$int,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'total_orders',
						$elm$json$Json$Decode$int,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'total_distance',
							$elm$json$Json$Decode$float,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'productivity',
								$elm$json$Json$Decode$float,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'unassigned_orders',
									$elm$json$Json$Decode$int,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'assigned_orders',
										$elm$json$Json$Decode$int,
										$elm$json$Json$Decode$succeed($author$project$Data$RouteResponse$AreaKPI)))))))))));
var $author$project$Data$RouteResponse$RouteKPI = F8(
	function (routeId, stops, distance, ordersCount, routeItemsCount, productivity, waitingHours, workingHours) {
		return {ab: distance, c1: ordersCount, dd: productivity, du: routeId, dv: routeItemsCount, dS: stops, eb: waitingHours, eg: workingHours};
	});
var $author$project$Data$RouteResponse$routeKpiDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'working_hours',
	$elm$json$Json$Decode$float,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'waiting_hours',
		$elm$json$Json$Decode$float,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'productivity',
			$elm$json$Json$Decode$float,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'number_of_route_items',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'orders',
					$elm$json$Json$Decode$int,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'distance',
						$elm$json$Json$Decode$float,
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'stops',
							$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
							$elm$core$Maybe$Nothing,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'route_id',
								$elm$json$Json$Decode$string,
								$elm$json$Json$Decode$succeed($author$project$Data$RouteResponse$RouteKPI)))))))));
var $author$project$Data$RouteResponse$executionKpiDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'errors',
	A2(
		$elm$json$Json$Decode$map,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$head,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$map($elm$core$Basics$identity),
				$elm$core$Maybe$withDefault(''))),
		$elm$json$Json$Decode$list(
			A2($elm$json$Json$Decode$field, 'message', $elm$json$Json$Decode$string))),
	'',
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'status',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'global_kpis',
			$elm$json$Json$Decode$maybe($author$project$Data$RouteResponse$areaKpiDecoder),
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'routes_kpis',
				$elm$json$Json$Decode$maybe(
					$elm$json$Json$Decode$list($author$project$Data$RouteResponse$routeKpiDecoder)),
				$elm$core$Maybe$Nothing,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'id',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Data$RouteResponse$ExecutionKpiResponse))))));
var $author$project$Request$RouteRequest$fetchExecution = F3(
	function (_v0, executionId, msg) {
		var user = _v0.d7;
		var env = _v0.fr;
		return A4(
			$author$project$Request$HttpClient$authenticatedGet,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.bs;
					},
					user)),
			$author$project$Request$Url$apiRailsUrl(env) + ('api/v2/routes/executions/' + executionId),
			$author$project$Data$RouteResponse$executionKpiDecoder,
			A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, msg));
	});
var $author$project$Views$MapAssignment$RouteContainer$filterByTerm = F2(
	function (term, routeContainers) {
		var contains = function (str2) {
			return A2(
				$elm$core$String$contains,
				$elm$core$String$toLower(term),
				$elm$core$String$toLower(str2));
		};
		var mayCon = F2(
			function (maybeRecord, field) {
				return A2(
					$elm$core$Maybe$withDefault,
					false,
					A2(
						$elm$core$Maybe$map,
						contains,
						A2($elm$core$Maybe$map, field, maybeRecord)));
			});
		var maybeContains = function (maybeStr) {
			return A2(
				$elm$core$Maybe$withDefault,
				false,
				A2($elm$core$Maybe$map, contains, maybeStr));
		};
		var filterCond = function (_v0) {
			var order = _v0.cZ;
			return contains(order.gO) || (maybeContains(order.hf) || (maybeContains(order.g5) || (maybeContains(order.e6.gs) || A2(
				mayCon,
				order.g_,
				function ($) {
					return $.fJ;
				}))));
		};
		return (term === '') ? routeContainers : A2(
			$elm$core$List$filter,
			function (rc) {
				return A2($elm$core$List$any, filterCond, rc.g9.gL) || A2($elm$core$List$any, contains, rc.g9.hE);
			},
			routeContainers);
	});
var $author$project$Views$MapAssignment$Update$applyRoutesFilters = function (model) {
	var routes = model.hc;
	var filteredRouteContainers = A2($author$project$Views$MapAssignment$RouteContainer$filterByTerm, model.ds, routes.g8);
	var newRoutes = _Utils_update(
		routes,
		{b9: filteredRouteContainers});
	return _Utils_update(
		model,
		{hc: newRoutes});
};
var $author$project$Views$MapAssignment$Update$filterRouteContainers = function (model) {
	return $author$project$Views$MapAssignment$Update$applyRoutesFilters(model);
};
var $author$project$Views$MapAssignment$Update$filterdOrdersWithAddresses = function (model) {
	return A2(
		$elm$core$List$filter,
		function (o) {
			return !_Utils_eq(o.e6.ga, $elm$core$Maybe$Nothing);
		},
		A2($author$project$Views$MapAssignment$Model$orderIdsToOrders, model.gL, model.fF));
};
var $author$project$Views$MapAssignment$RouteContainer$findByRouteId = F2(
	function (routeId, routeContainers) {
		return $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.g9;
					},
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.fS;
						},
						$elm$core$Basics$eq(routeId))),
				routeContainers));
	});
var $author$project$Views$MapAssignment$Update$findWarehouse = F2(
	function (warehouses, maybeWarehouseId) {
		return $elm$core$List$head(
			A2(
				$elm$core$Maybe$withDefault,
				warehouses,
				A2(
					$elm$core$Maybe$map,
					function (warehouseId) {
						return A2(
							$elm$core$List$filter,
							function (wh) {
								return _Utils_eq(wh.fS, warehouseId);
							},
							warehouses);
					},
					maybeWarehouseId)));
	});
var $author$project$Views$MapAssignment$Msg$NOOP = {$: 36};
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $elm$browser$Browser$Dom$getElement = _Browser_getElement;
var $elm$browser$Browser$Dom$setViewportOf = _Browser_setViewportOf;
var $author$project$Views$MapAssignment$Update$focusOnRoutesList = function (routeId) {
	return A2(
		$elm$core$Task$attempt,
		function (_v1) {
			return $author$project$Views$MapAssignment$Msg$NOOP;
		},
		A2(
			$elm$core$Task$andThen,
			function (_v0) {
				var element = _v0.fm;
				return A3($elm$browser$Browser$Dom$setViewportOf, 'routes-list', 0, element.h3);
			},
			$elm$browser$Browser$Dom$getElement(
				'route-' + $elm$core$String$fromInt(routeId))));
};
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0;
		return A3($elm$core$Dict$insert, key, 0, dict);
	});
var $elm$core$Set$fromList = function (list) {
	return A3($elm$core$List$foldl, $elm$core$Set$insert, $elm$core$Set$empty, list);
};
var $elm_community$list_extra$List$Extra$find = F2(
	function (predicate, list) {
		find:
		while (true) {
			if (!list.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var first = list.a;
				var rest = list.b;
				if (predicate(first)) {
					return $elm$core$Maybe$Just(first);
				} else {
					var $temp$predicate = predicate,
						$temp$list = rest;
					predicate = $temp$predicate;
					list = $temp$list;
					continue find;
				}
			}
		}
	});
var $author$project$Views$MapAssignment$Update$getFirstOrderLocation = function (routeContainer) {
	return A2(
		$elm$core$Maybe$map,
		function (c) {
			return _Utils_Tuple2(c.f8, c.gb);
		},
		A2(
			$elm$core$Maybe$andThen,
			function (o) {
				return o.e6.ga;
			},
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.cZ;
				},
				A2(
					$elm_community$list_extra$List$Extra$find,
					function (_v0) {
						var order = _v0.cZ;
						return !_Utils_eq(order.e6.ga, $elm$core$Maybe$Nothing);
					},
					routeContainer.g9.gL))));
};
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $author$project$Views$MapAssignment$RouteContainer$setExpectedDeliveryTimesToOrders = F2(
	function (solution, routeOrders) {
		var bestRoute = $elm$core$List$head(solution.hc);
		var expectedDeliveryTimesTuple = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				function (r) {
					return A2(
						$elm$core$List$map,
						function (a) {
							return _Utils_Tuple2(a.fS, a.fq);
						},
						A2(
							$elm$core$List$filter,
							function (a) {
								return a.ep === 3;
							},
							r.eo));
				},
				bestRoute));
		var expectedDeliveryTimeFromTuple = function (order) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$List$head(
					A2(
						$elm$core$List$map,
						$elm$core$Tuple$second,
						A2(
							$elm$core$List$filter,
							function (_v1) {
								var activityId = _v1.a;
								return _Utils_eq(
									activityId,
									$elm$core$String$fromInt(order.fS));
							},
							expectedDeliveryTimesTuple))));
		};
		var uuid = A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Maybe$Nothing,
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.hW;
				},
				bestRoute));
		return _Utils_Tuple2(
			uuid,
			A2(
				$elm$core$List$map,
				function (_v0) {
					var position = _v0.gW;
					var order = _v0.cZ;
					return {
						b7: expectedDeliveryTimeFromTuple(order),
						cZ: order,
						gW: position
					};
				},
				routeOrders));
	});
var $author$project$Views$MapAssignment$Update$getRouteOrdersListFromSolution = F2(
	function (routeContainer, solution) {
		var _v0 = A2($author$project$Views$MapAssignment$RouteContainer$setExpectedDeliveryTimesToOrders, solution, routeContainer.g9.gL);
		var uuid = _v0.a;
		var orders = _v0.b;
		return _Utils_Tuple2(uuid, orders);
	});
var $author$project$Request$Route$hideAllRoutesUrl = function (env) {
	return $author$project$Request$Url$apiRailsUrl(env) + 'api/v2/routes/hide_all';
};
var $author$project$Request$Route$hideAllRoutes = F2(
	function (_v0, msg) {
		var user = _v0.d7;
		var env = _v0.fr;
		return A4(
			$author$project$Request$HttpClient$authenticatedPostWithEmptyBody,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.bs;
					},
					user)),
			$author$project$Request$Route$hideAllRoutesUrl(env),
			$elm$json$Json$Decode$list($author$project$Data$Route$decoder),
			msg);
	});
var $author$project$Request$Route$hideMultipleRoutesUrl = function (env) {
	return $author$project$Request$Url$apiRailsUrl(env) + 'api/v2/routes/hide_multiple';
};
var $author$project$Request$Route$hideMultipleRoutes = F3(
	function (_v0, routeIds, msg) {
		var user = _v0.d7;
		var env = _v0.fr;
		return A5(
			$author$project$Request$HttpClient$authenticatedPost,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.bs;
					},
					user)),
			$author$project$Request$Route$hideMultipleRoutesUrl(env),
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'route_ids',
						A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$int, routeIds))
					])),
			$elm$json$Json$Decode$list($author$project$Data$Route$decoder),
			msg);
	});
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Views$MapAssignment$RouteContainer$isSameRouteContainer = F2(
	function (routeContainer1, routeContainer2) {
		return _Utils_eq(routeContainer1.g9.fS, routeContainer2.g9.fS);
	});
var $author$project$Page$AutomaticRoute$Model$isThereAnOngoingExecution = function (executions) {
	return A2(
		$elm$core$List$any,
		function (_v0) {
			var jobId = _v0.b.f3;
			return !_Utils_eq(jobId, $elm$core$Maybe$Nothing);
		},
		executions);
};
var $author$project$Views$MapAssignment$Msg$FetchPackages = function (a) {
	return {$: 4, a: a};
};
var $elm_community$list_extra$List$Extra$unique = function (list) {
	return A4($elm_community$list_extra$List$Extra$uniqueHelp, $elm$core$Basics$identity, _List_Nil, list, _List_Nil);
};
var $author$project$Views$Common$Select$getAllOptions = function (model) {
	return $elm_community$list_extra$List$Extra$unique(
		A2(
			$elm$core$List$map,
			function (_v0) {
				var value = _v0.y;
				return value;
			},
			model.b));
};
var $author$project$Views$Common$Select$updateOptionSelectionStatus = F3(
	function (optionClicked, selected, option) {
		return _Utils_eq(option.y, optionClicked) ? _Utils_update(
			option,
			{D: selected}) : option;
	});
var $author$project$Views$Common$Select$setSelectedOptions = F2(
	function (valuesToSelect, model) {
		var options = model.b;
		return _Utils_update(
			model,
			{
				b: A2(
					$elm$core$List$map,
					function (option) {
						return A2($elm$core$List$member, option.y, valuesToSelect) ? A3($author$project$Views$Common$Select$updateOptionSelectionStatus, option.y, true, option) : option;
					},
					options)
			});
	});
var $elm$core$List$sort = function (xs) {
	return A2($elm$core$List$sortBy, $elm$core$Basics$identity, xs);
};
var $author$project$Views$MapAssignment$Update$sortTimeslots = F3(
	function (zone, _v0, _v1) {
		var start1 = _v0.a;
		var end1 = _v0.b;
		var start2 = _v1.a;
		var end2 = _v1.b;
		var _v2 = _Utils_Tuple2(
			A2(
				$elm$core$Basics$compare,
				A2($elm$time$Time$toHour, zone, start1),
				A2($elm$time$Time$toHour, zone, start2)),
			A2(
				$elm$core$Basics$compare,
				A2($elm$time$Time$toHour, zone, end1),
				A2($elm$time$Time$toHour, zone, end2)));
		switch (_v2.a) {
			case 0:
				switch (_v2.b) {
					case 0:
						var _v3 = _v2.a;
						var _v4 = _v2.b;
						return 0;
					case 2:
						var _v5 = _v2.a;
						var _v6 = _v2.b;
						return 0;
					default:
						var _v7 = _v2.a;
						var _v8 = _v2.b;
						return 0;
				}
			case 2:
				switch (_v2.b) {
					case 2:
						var _v9 = _v2.a;
						var _v10 = _v2.b;
						return 2;
					case 0:
						var _v11 = _v2.a;
						var _v12 = _v2.b;
						return 2;
					default:
						var _v13 = _v2.a;
						var _v14 = _v2.b;
						return 2;
				}
			default:
				switch (_v2.b) {
					case 1:
						var _v15 = _v2.a;
						var _v16 = _v2.b;
						return 1;
					case 2:
						var _v17 = _v2.a;
						var _v18 = _v2.b;
						return 2;
					default:
						var _v19 = _v2.a;
						var _v20 = _v2.b;
						return 0;
				}
		}
	});
var $elm$core$List$sortWith = _List_sortWith;
var $author$project$Views$MapAssignment$Update$uniqueHourTimeSlot = F2(
	function (zone, _v0) {
		var start = _v0.a;
		var end = _v0.b;
		return A2(
			$elm$core$String$join,
			',',
			A2(
				$elm$core$List$map,
				$elm$core$String$fromInt,
				_List_fromArray(
					[
						A2($elm$time$Time$toHour, zone, start),
						A2($elm$time$Time$toHour, zone, end)
					])));
	});
var $author$project$Views$MapAssignment$Update$updateFilterOptions = F3(
	function (zone, filters, orders) {
		var uniqueTimeRangeOptions = F2(
			function (oldOptions, newOptions) {
				return A2(
					$elm$core$List$sortWith,
					$author$project$Views$MapAssignment$Update$sortTimeslots(zone),
					A2(
						$elm_community$list_extra$List$Extra$uniqueBy,
						$author$project$Views$MapAssignment$Update$uniqueHourTimeSlot(zone),
						A2($elm$core$List$append, newOptions, oldOptions)));
			});
		var uniqueStoresOptions = F2(
			function (oldOptions, newOptions) {
				return A2(
					$elm$core$List$sortBy,
					function ($) {
						return $.gs;
					},
					A2(
						$elm_community$list_extra$List$Extra$uniqueBy,
						function ($) {
							return $.fS;
						},
						A2($elm$core$List$append, newOptions, oldOptions)));
			});
		var uniqueRetailersOptions = F2(
			function (oldOptions, newOptions) {
				return A2(
					$elm$core$List$sortBy,
					function ($) {
						return $.gs;
					},
					A2(
						$elm_community$list_extra$List$Extra$uniqueBy,
						function ($) {
							return $.fS;
						},
						A2($elm$core$List$append, newOptions, oldOptions)));
			});
		var uniqueOptions = F2(
			function (oldOptions, newOptions) {
				return $elm$core$List$sort(
					$elm_community$list_extra$List$Extra$unique(
						A2($elm$core$List$append, newOptions, oldOptions)));
			});
		var uniqueGeoLocationAccuracyOptions = F2(
			function (oldOptions, newOptions) {
				return A2(
					$elm$core$List$sortBy,
					$author$project$Data$Address$accuracyQualityToString,
					$elm_community$list_extra$List$Extra$unique(
						A2($elm$core$List$append, newOptions, oldOptions)));
			});
		var uniqueDriverOptions = F2(
			function (oldOptions, newOptions) {
				return A2(
					$elm$core$List$sortBy,
					function ($) {
						return $.fJ;
					},
					A2(
						$elm_community$list_extra$List$Extra$uniqueBy,
						function ($) {
							return $.fS;
						},
						A2($elm$core$List$append, newOptions, oldOptions)));
			});
		var timeRangeOptions = A2(
			uniqueTimeRangeOptions,
			$author$project$Views$Common$Select$getAllOptions(filters.bm),
			A2(
				$elm_community$list_extra$List$Extra$uniqueBy,
				$author$project$Views$MapAssignment$Update$uniqueHourTimeSlot(zone),
				A2(
					$elm$core$List$map,
					function (o) {
						return _Utils_Tuple2(o.e7.hq, o.e7.fp);
					},
					orders)));
		var storesOptions = A2(
			uniqueStoresOptions,
			$author$project$Views$Common$Select$getAllOptions(filters.dV),
			A2(
				$elm_community$list_extra$List$Extra$uniqueBy,
				function ($) {
					return $.fS;
				},
				A2(
					$elm$core$List$filterMap,
					function ($) {
						return $.hw;
					},
					orders)));
		var serviceTypeOptions = A2(
			uniqueOptions,
			$author$project$Views$Common$Select$getAllOptions(filters.hk),
			$elm_community$list_extra$List$Extra$unique(
				A2(
					$elm$core$List$filterMap,
					function ($) {
						return $.hj;
					},
					orders)));
		var retailersOptions = A2(
			uniqueRetailersOptions,
			$author$project$Views$Common$Select$getAllOptions(filters.g6),
			A2(
				$elm_community$list_extra$List$Extra$uniqueBy,
				function ($) {
					return $.fS;
				},
				A2(
					$elm$core$List$filterMap,
					function ($) {
						return $.eT;
					},
					orders)));
		var postalCodesOptions = A2(
			uniqueOptions,
			$author$project$Views$Common$Select$getAllOptions(filters.gY),
			$elm_community$list_extra$List$Extra$unique(
				A2(
					$elm$core$List$filterMap,
					function (_v2) {
						var orderType = _v2.gK;
						var deliveryAddress = _v2.e6;
						var pickupAddress = _v2.gT;
						if (orderType === 1) {
							return pickupAddress.gX;
						} else {
							return deliveryAddress.gX;
						}
					},
					orders)));
		var packagesOptions = A2(
			uniqueOptions,
			$author$project$Views$Common$Select$getAllOptions(filters.c6),
			$elm_community$list_extra$List$Extra$unique(
				A2(
					$elm$core$List$map,
					function ($) {
						return $.gD;
					},
					orders)));
		var geoLocationAccuracyOptions = A2(
			uniqueGeoLocationAccuracyOptions,
			$author$project$Views$Common$Select$getAllOptions(filters.fL),
			$elm_community$list_extra$List$Extra$unique(
				A2(
					$elm$core$List$map,
					function (_v0) {
						var orderType = _v0.gK;
						var deliveryAddress = _v0.e6;
						var pickupAddress = _v0.gT;
						if (orderType === 1) {
							return pickupAddress.fL;
						} else {
							return deliveryAddress.fL;
						}
					},
					orders)));
		var driverOptions = A2(
			uniqueDriverOptions,
			$author$project$Views$Common$Select$getAllOptions(filters.b_),
			A2(
				$elm_community$list_extra$List$Extra$uniqueBy,
				function ($) {
					return $.fS;
				},
				A2(
					$elm$core$List$filterMap,
					function ($) {
						return $.fh;
					},
					orders)));
		return _Utils_update(
			filters,
			{
				b_: $author$project$Views$Common$Select$init(driverOptions),
				fL: $author$project$Views$Common$Select$init(geoLocationAccuracyOptions),
				c6: A2(
					$author$project$Views$Common$Select$setSelectedOptions,
					A2(
						$elm$core$List$filter,
						function (opt) {
							return !(!opt);
						},
						packagesOptions),
					$author$project$Views$Common$Select$init(packagesOptions)),
				gY: $author$project$Views$Common$Select$init(postalCodesOptions),
				g6: $author$project$Views$Common$Select$init(retailersOptions),
				hk: $author$project$Views$Common$Select$init(serviceTypeOptions),
				dV: $author$project$Views$Common$Select$init(storesOptions),
				bm: $author$project$Views$Common$Select$init(timeRangeOptions)
			});
	});
var $author$project$Views$MapAssignment$Update$appendNewOrders = F4(
	function (context, filtersShouldBeUpdated, orders, model) {
		var zone = context.em;
		var newOrders = A2(
			$author$project$Utils$createReplaceAllIfNew,
			$author$project$Views$MapAssignment$Model$ordersListToDict(orders),
			model.gL);
		var newFilters = filtersShouldBeUpdated ? A3($author$project$Views$MapAssignment$Update$updateFilterOptions, zone, model.fG, orders) : model.fG;
		var newModel = $author$project$Views$MapAssignment$Update$updateOrdersScroll(
			A2(
				$author$project$Views$MapAssignment$Update$applyOrdersFilters,
				context,
				_Utils_update(
					model,
					{fG: newFilters, gL: newOrders})));
		return newModel;
	});
var $author$project$Data$Package$Package = F8(
	function (id, orderId, width, height, length, barcode, units, volume) {
		return {eA: barcode, ci: height, fS: id, cE: length, gI: orderId, hR: units, bo: volume, ef: width};
	});
var $elm_community$json_extra$Json$Decode$Extra$fromMaybe = F2(
	function (error, val) {
		if (!val.$) {
			var v = val.a;
			return $elm$json$Json$Decode$succeed(v);
		} else {
			return $elm$json$Json$Decode$fail(error);
		}
	});
var $elm_community$json_extra$Json$Decode$Extra$parseFloat = A2(
	$elm$json$Json$Decode$andThen,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$String$toFloat,
		$elm_community$json_extra$Json$Decode$Extra$fromMaybe('failed to parse as float')),
	$elm$json$Json$Decode$string);
var $elm_community$json_extra$Json$Decode$Extra$parseInt = A2(
	$elm$json$Json$Decode$andThen,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$String$toInt,
		$elm_community$json_extra$Json$Decode$Extra$fromMaybe('Failed to parse as int')),
	$elm$json$Json$Decode$string);
var $author$project$Data$Package$decoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'volume',
	$elm$json$Json$Decode$maybe($elm_community$json_extra$Json$Decode$Extra$parseFloat),
	$elm$core$Maybe$Nothing,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'units',
		$elm$json$Json$Decode$maybe($elm_community$json_extra$Json$Decode$Extra$parseInt),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'barcode',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'length',
				$elm$json$Json$Decode$maybe($elm_community$json_extra$Json$Decode$Extra$parseFloat),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'height',
					$elm$json$Json$Decode$maybe($elm_community$json_extra$Json$Decode$Extra$parseFloat),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'width',
						$elm$json$Json$Decode$maybe($elm_community$json_extra$Json$Decode$Extra$parseFloat),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'order_id',
							$elm$json$Json$Decode$int,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'id',
								$elm$json$Json$Decode$int,
								$elm$json$Json$Decode$succeed($author$project$Data$Package$Package)))))))));
var $author$project$Request$Package$fetchOrdersPackagesUrl = function (env) {
	return $author$project$Request$Url$apiServiceUrl(env) + 'api/v2/packages/orders';
};
var $author$project$Request$Package$fetchOrdersPackages = F3(
	function (_v0, orders, msg) {
		var user = _v0.d7;
		var env = _v0.fr;
		var orderIds = A2(
			$elm$core$String$join,
			',',
			A2(
				$elm$core$List$map,
				$elm$core$String$fromInt,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.fS;
					},
					orders)));
		return A5(
			$author$project$Request$HttpClient$authenticatedPost,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.bs;
					},
					user)),
			$author$project$Request$Package$fetchOrdersPackagesUrl(env),
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'order_ids',
						$elm$json$Json$Encode$string(orderIds))
					])),
			$elm$json$Json$Decode$list($author$project$Data$Package$decoder),
			msg);
	});
var $author$project$Views$MapAssignment$Model$Loaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Views$MapAssignment$Model$LoadingFinished = {$: 3};
var $author$project$Views$MapAssignment$Update$newBatchLoadingStatus = F3(
	function (currentBatchSize, paginationData, batchLoadingStatus) {
		var morePagesToLoad = _Utils_cmp(paginationData.e1, paginationData.hN) < 0;
		if (morePagesToLoad) {
			switch (batchLoadingStatus.$) {
				case 1:
					return $author$project$Views$MapAssignment$Model$Loaded(
						{a1: currentBatchSize, bn: paginationData.eZ});
				case 2:
					var loaded = batchLoadingStatus.a.a1;
					var total = batchLoadingStatus.a.bn;
					return $author$project$Views$MapAssignment$Model$Loaded(
						{a1: loaded + currentBatchSize, bn: total});
				default:
					return batchLoadingStatus;
			}
		} else {
			return $author$project$Views$MapAssignment$Model$LoadingFinished;
		}
	});
var $author$project$Views$MapAssignment$Update$loadOrders = F6(
	function (context, model, orders, paginationData, orderFetchingType, loadOrdersFunction) {
		var newModel = function () {
			var currentBatchSize = $elm$core$List$length(orders);
			var newUpcomingOrdersLoadingStatus = A3($author$project$Views$MapAssignment$Update$newBatchLoadingStatus, currentBatchSize, paginationData, model.hS);
			return _Utils_update(
				model,
				{hS: newUpcomingOrdersLoadingStatus});
		}();
		var morePagesToLoad = _Utils_cmp(paginationData.e1, paginationData.hN) < 0;
		var nextPageCmd = function () {
			if (morePagesToLoad) {
				var fetchOrdersMsg = $author$project$Views$MapAssignment$Msg$FetchUpcomingOrders;
				return A4(loadOrdersFunction, context, model.gh.eD, paginationData.e1 + 1, fetchOrdersMsg);
			} else {
				return $elm$core$Platform$Cmd$none;
			}
		}();
		return _Utils_Tuple2(
			A4($author$project$Views$MapAssignment$Update$appendNewOrders, context, true, orders, newModel),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A3($author$project$Request$Package$fetchOrdersPackages, context, orders, $author$project$Views$MapAssignment$Msg$FetchPackages),
						nextPageCmd
					])));
	});
var $author$project$Data$RouteResponse$RouteResponse = F3(
	function (solution, jobId, routeId) {
		return {f3: jobId, du: routeId, ho: solution};
	});
var $author$project$Data$RouteResponse$decoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2($elm$json$Json$Decode$field, 'route_id', $elm$json$Json$Decode$string),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'job_id', $elm$json$Json$Decode$string),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'solution', $author$project$Data$RouteResponse$solutionDecoder),
			$elm$json$Json$Decode$succeed($author$project$Data$RouteResponse$RouteResponse))));
var $author$project$Request$Route$fetchRouteDataUrl = F2(
	function (env, routeId) {
		return $author$project$Request$Url$apiRailsUrl(env) + ('api/v2/routes/' + ($elm$core$String$fromInt(routeId) + '/data'));
	});
var $author$project$Request$Route$loadRouteData = F3(
	function (_v0, route_id, msg) {
		var user = _v0.d7;
		var env = _v0.fr;
		return A4(
			$author$project$Request$HttpClient$authenticatedGet,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.bs;
					},
					user)),
			A2($author$project$Request$Route$fetchRouteDataUrl, env, route_id),
			$author$project$Data$RouteResponse$decoder,
			msg);
	});
var $author$project$Views$MapAssignment$Update$appendNewRoutes = F2(
	function (routeContainers, model) {
		var routes = model.hc;
		var newRouteContainers = A2($elm$core$List$append, routes.g8, routeContainers);
		var filteredModel = $author$project$Views$MapAssignment$Update$applyRoutesFilters(
			_Utils_update(
				model,
				{
					hc: _Utils_update(
						routes,
						{g8: routeContainers})
				}));
		var newFilteredRouteContainers = A2($elm$core$List$append, routes.b9, filteredModel.hc.b9);
		var newModel = _Utils_update(
			model,
			{
				hc: _Utils_update(
					routes,
					{b9: newFilteredRouteContainers, g8: newRouteContainers})
			});
		return newModel;
	});
var $author$project$Views$MapAssignment$Update$loadRoutes = F6(
	function (context, model, routes, paginationData, fetchRoutesMsg, loadRoutesFunction) {
		var routesModel = model.hc;
		var routeContainers = A2(
			$elm$core$List$map,
			$author$project$Views$MapAssignment$Update$backendRouteTorouteContainer,
			$elm$core$List$reverse(
				A2(
					$elm$core$List$sortBy,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.e$;
						},
						$elm$time$Time$posixToMillis),
					routes)));
		var orders = A2(
			$elm$core$List$map,
			function ($) {
				return $.cZ;
			},
			A2(
				$elm$core$List$concatMap,
				function (o) {
					return o.g9.gL;
				},
				routeContainers));
		var currentBatchSize = $elm$core$List$length(routes);
		var newRoutesLoadingStatus = A3($author$project$Views$MapAssignment$Update$newBatchLoadingStatus, currentBatchSize, paginationData, routesModel.bc);
		var _v0 = paginationData;
		var currentPage = _v0.e1;
		var totalPages = _v0.hN;
		var updateRouteContainers = function (routes_) {
			return (currentPage === 1) ? _Utils_update(
				routes_,
				{b9: _List_Nil, g8: _List_Nil, bc: newRoutesLoadingStatus}) : _Utils_update(
				routes_,
				{b9: routes_.b9, g8: routes_.g8, bc: newRoutesLoadingStatus});
		};
		var newRoutesModel = updateRouteContainers(routesModel);
		var newModel = A4(
			$author$project$Views$MapAssignment$Update$appendNewOrders,
			context,
			false,
			orders,
			A2(
				$author$project$Views$MapAssignment$Update$appendNewRoutes,
				routeContainers,
				_Utils_update(
					model,
					{hc: newRoutesModel})));
		var morePagesToLoad = _Utils_cmp(currentPage, totalPages) < 0;
		var nextPageCmd = morePagesToLoad ? A3(loadRoutesFunction, context, paginationData.e1 + 1, fetchRoutesMsg) : $elm$core$Platform$Cmd$none;
		return _Utils_Tuple2(
			newModel,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A3($author$project$Request$Package$fetchOrdersPackages, context, orders, $author$project$Views$MapAssignment$Msg$FetchPackages),
						nextPageCmd
					])));
	});
var $elm$core$Maybe$map2 = F3(
	function (func, ma, mb) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				return $elm$core$Maybe$Just(
					A2(func, a, b));
			}
		}
	});
var $Fresheyeball$elm_return$Return$mapBoth = F3(
	function (f, f_, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		return _Utils_Tuple2(
			f_(model),
			A2($elm$core$Platform$Cmd$map, f, cmd));
	});
var $author$project$Views$MapAssignment$Update$modifyAllRoutes = F2(
	function (model, requestCmd) {
		var routes = model.hc;
		var newRoutes = _Utils_update(
			routes,
			{cw: true});
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{cl: $author$project$Views$MapAssignment$Msg$Closed, hc: newRoutes}),
			requestCmd);
	});
var $author$project$Views$MapAssignment$Update$modifyRoutes = F3(
	function (model, msg, modifyMultipleFn) {
		var routes = model.hc;
		var selectedRouteIds = A2(
			$elm$core$List$map,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.g9;
				},
				function ($) {
					return $.fS;
				}),
			A2(
				$elm$core$List$filter,
				function ($) {
					return $.f2;
				},
				routes.g8));
		var hasSelectedRouteIds = !$elm$core$List$isEmpty(selectedRouteIds);
		var newRoutes = _Utils_update(
			routes,
			{cw: hasSelectedRouteIds});
		var cmd = hasSelectedRouteIds ? A2(modifyMultipleFn, selectedRouteIds, msg) : $elm$core$Platform$Cmd$none;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{cl: $author$project$Views$MapAssignment$Msg$Closed, hc: newRoutes}),
			cmd);
	});
var $author$project$Views$MapAssignment$Update$modifyRoutesFailureResponse = function (model) {
	var routes = model.hc;
	var newRoutes = _Utils_update(
		routes,
		{cw: false});
	var _v0 = A2($PaackEng$elm_alert_beta$Alert$error, 'Routes could not be modified', model.bv);
	var alerts = _v0.a;
	var cmd = _v0.b;
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{bv: alerts, hc: newRoutes}),
		A2($elm$core$Platform$Cmd$map, $author$project$Views$MapAssignment$Msg$AlertMsg, cmd));
};
var $author$project$Views$MapAssignment$Update$modifyRoutesSuccessResponse = F3(
	function (context, model, routes) {
		var routesData = model.hc;
		var newContainers = A2($elm$core$List$map, $author$project$Views$MapAssignment$Update$backendRouteTorouteContainer, routes);
		var routeContainers = A2(
			$elm$core$List$filter,
			function (container) {
				return A2(
					$elm$core$List$all,
					function (rc) {
						return !_Utils_eq(container.g9.fS, rc.g9.fS);
					},
					newContainers);
			},
			routesData.g8);
		var selectedRouteContainer = A2(
			$elm$core$Maybe$andThen,
			function (container) {
				return A2(
					$elm$core$List$any,
					function (rc) {
						return _Utils_eq(container.g9.fS, rc.g9.fS);
					},
					newContainers) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(container);
			},
			routesData.bd);
		var newRoutesData = _Utils_update(
			routesData,
			{cw: false, g8: routeContainers, bd: selectedRouteContainer});
		var newModel = $author$project$Views$MapAssignment$Update$filterRouteContainers(
			A2(
				$author$project$Views$MapAssignment$Update$filterOrders,
				context,
				_Utils_update(
					model,
					{hc: newRoutesData})));
		return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
	});
var $author$project$Views$MapAssignment$Model$orderIdsToOrdersDict = F2(
	function (orders, ids) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (id, acc) {
					var _v0 = A2($elm$core$Dict$get, id, orders);
					if (!_v0.$) {
						var order = _v0.a;
						return A3($elm$core$Dict$insert, id, order, acc);
					} else {
						return acc;
					}
				}),
			$elm$core$Dict$empty,
			ids);
	});
var $author$project$Views$MapAssignment$Update$postalCodesMapperFn = function (orders) {
	return A2(
		$elm$core$List$filterMap,
		function (_v0) {
			var orderType = _v0.gK;
			var deliveryAddress = _v0.e6;
			var pickupAddress = _v0.gT;
			if (orderType === 1) {
				return pickupAddress.gX;
			} else {
				return deliveryAddress.gX;
			}
		},
		orders);
};
var $author$project$Views$MapAssignment$RouteContainer$removeRouteContainer = F2(
	function (newRouteContainer, routeContainers) {
		return A2(
			$elm$core$List$filter,
			function (container) {
				return !_Utils_eq(container.g9.fS, newRouteContainer.g9.fS);
			},
			routeContainers);
	});
var $author$project$Request$Route$removeRouteDriverUrl = function (env) {
	return $author$project$Request$Url$apiRailsUrl(env) + 'api/v2/routes/remove_driver';
};
var $author$project$Request$Route$removeRouteDriver = F3(
	function (_v0, routeId, msg) {
		var user = _v0.d7;
		var env = _v0.fr;
		return A5(
			$author$project$Request$HttpClient$authenticatedPost,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.bs;
					},
					user)),
			$author$project$Request$Route$removeRouteDriverUrl(env),
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'route_id',
						$elm$json$Json$Encode$string(
							$elm$core$String$fromInt(routeId)))
					])),
			$author$project$Data$Route$decoder,
			msg);
	});
var $author$project$Request$RouteRequest$resetRouteUrl = F3(
	function (env, routeId, jobId) {
		return $author$project$Request$Url$apiRailsUrl(env) + ('api/v2/routes/reset_route?route_id=' + ($elm$core$String$fromInt(routeId) + ('&job_id=' + jobId)));
	});
var $author$project$Request$RouteRequest$resetRoute = F4(
	function (_v0, routeId, jobId, msg) {
		var env = _v0.fr;
		var user = _v0.d7;
		return A4(
			$author$project$Request$HttpClient$authenticatedPostWithEmptyBody,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.bs;
					},
					user)),
			A3($author$project$Request$RouteRequest$resetRouteUrl, env, routeId, jobId),
			$author$project$Data$Route$decoder,
			msg);
	});
var $author$project$Views$MapAssignment$Update$routeRequestParams = F2(
	function (model, routeContainer) {
		var originWarehouse = A2($author$project$Views$MapAssignment$Update$findWarehouse, model.h0, model.hl.he.gN);
		return {
			bt: false,
			bE: routeContainer.bF,
			bW: $elm$core$String$toFloat(model.hl.he.bW),
			bY: true,
			b_: _List_Nil,
			cF: $elm$core$Maybe$Just(250),
			gL: A2(
				$elm$core$List$map,
				function ($) {
					return $.cZ;
				},
				routeContainer.g9.gL),
			aE: originWarehouse,
			c5: $elm$core$String$toFloat(model.hl.he.c5),
			c6: model.c6,
			ak: routeContainer.dP,
			d6: false,
			eg: routeContainer.eh
		};
	});
var $author$project$Views$MapAssignment$Settings$ClusterSettings$setGridSize = F2(
	function (val, clusterSettings) {
		if (clusterSettings.$ === 1) {
			var settings = clusterSettings.a;
			var _v1 = $elm$core$String$toInt(val);
			if (!_v1.$) {
				var gridSize = _v1.a;
				return $author$project$Views$MapAssignment$Types$Clustered(
					_Utils_update(
						settings,
						{fN: gridSize}));
			} else {
				return clusterSettings;
			}
		} else {
			return $author$project$Views$MapAssignment$Types$NotClustered;
		}
	});
var $author$project$Views$MapAssignment$Settings$ClusterSettings$setMinPinToCluster = F2(
	function (val, clusterSettings) {
		if (clusterSettings.$ === 1) {
			var settings = clusterSettings.a;
			var _v1 = $elm$core$String$toInt(val);
			if (!_v1.$) {
				var minPinsToCluster = _v1.a;
				return $author$project$Views$MapAssignment$Types$Clustered(
					_Utils_update(
						settings,
						{go: minPinsToCluster}));
			} else {
				return clusterSettings;
			}
		} else {
			return $author$project$Views$MapAssignment$Types$NotClustered;
		}
	});
var $author$project$Request$Route$setOrdersToRouteUrl = function (env) {
	return $author$project$Request$Url$apiRailsUrl(env) + 'api/v2/routes/set_orders';
};
var $author$project$Request$Route$setOrdersToRoute = F4(
	function (_v0, routeId, orders, msg) {
		var user = _v0.d7;
		var env = _v0.fr;
		var order_ids = A2(
			$elm$core$String$join,
			',',
			A2(
				$elm$core$List$map,
				$elm$core$String$fromInt,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.fS;
					},
					orders)));
		return A5(
			$author$project$Request$HttpClient$authenticatedPost,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.bs;
					},
					user)),
			$author$project$Request$Route$setOrdersToRouteUrl(env),
			A2($author$project$Request$Route$ordersToRouteEncoder, routeId, order_ids),
			$author$project$Data$Route$decoder,
			msg);
	});
var $author$project$Request$Route$driverRouteEncode = F2(
	function (routeId, driver) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'route_id',
					$elm$json$Json$Encode$int(routeId)),
					_Utils_Tuple2(
					'driver_id',
					$elm$json$Json$Encode$int(driver.fS))
				]));
	});
var $author$project$Request$Route$setRouteDriverUrl = function (env) {
	return $author$project$Request$Url$apiRailsUrl(env) + 'api/v2/routes/assign_driver';
};
var $author$project$Request$Route$setRouteDriver = F4(
	function (_v0, routeId, driver, msg) {
		var user = _v0.d7;
		var env = _v0.fr;
		return A5(
			$author$project$Request$HttpClient$authenticatedPost,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.bs;
					},
					user)),
			$author$project$Request$Route$setRouteDriverUrl(env),
			A2($author$project$Request$Route$driverRouteEncode, routeId, driver),
			$author$project$Data$Route$decoder,
			msg);
	});
var $author$project$Request$Route$routeNameEncode = F2(
	function (routeId, name) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'route_id',
					$elm$json$Json$Encode$int(routeId)),
					_Utils_Tuple2(
					'name',
					$elm$json$Json$Encode$string(name))
				]));
	});
var $author$project$Request$Route$setRouteNameUrl = function (env) {
	return $author$project$Request$Url$apiRailsUrl(env) + 'api/v2/routes/assign_name';
};
var $author$project$Request$Route$setRouteName = F4(
	function (_v0, routeId, name, msg) {
		var user = _v0.d7;
		var env = _v0.fr;
		return A5(
			$author$project$Request$HttpClient$authenticatedPost,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.bs;
					},
					user)),
			$author$project$Request$Route$setRouteNameUrl(env),
			A2($author$project$Request$Route$routeNameEncode, routeId, name),
			$author$project$Data$Route$decoder,
			msg);
	});
var $author$project$Views$MapAssignment$RouteContainer$setRouteToCompleteStatus = function (rc) {
	var routeData = rc.g9;
	return _Utils_update(
		rc,
		{
			bw: false,
			ae: $author$project$Views$MapAssignment$RouteContainer$Optimized,
			g9: _Utils_update(
				routeData,
				{ht: 1}),
			dA: $author$project$Views$MapAssignment$RouteContainer$Saved(true)
		});
};
var $author$project$Views$MapAssignment$RouteContainer$setRouteToDraftStatus = function (rc) {
	var routeData = rc.g9;
	return _Utils_update(
		rc,
		{
			ae: $author$project$Views$MapAssignment$RouteContainer$Idle,
			g9: _Utils_update(
				routeData,
				{ht: 0})
		});
};
var $author$project$Views$MapAssignment$RouteContainer$setRouteToErrored = function (rc) {
	var routeData = rc.g9;
	return _Utils_update(
		rc,
		{
			ae: $author$project$Views$MapAssignment$RouteContainer$Errored(''),
			g9: _Utils_update(
				routeData,
				{ht: 3})
		});
};
var $author$project$Views$MapAssignment$RouteContainer$setRouteToInProgressStatus = function (rc) {
	var routeData = rc.g9;
	return _Utils_update(
		rc,
		{
			ae: $author$project$Views$MapAssignment$RouteContainer$OptimizingInProgress,
			g9: _Utils_update(
				routeData,
				{ht: 2})
		});
};
var $author$project$Views$MapAssignment$Update$sortByFullName = $elm$core$List$sortBy(
	A2(
		$elm$core$Basics$composeR,
		function ($) {
			return $.y;
		},
		function ($) {
			return $.fJ;
		}));
var $author$project$Views$MapAssignment$Update$sortByName = $elm$core$List$sortBy(
	A2(
		$elm$core$Basics$composeR,
		function ($) {
			return $.y;
		},
		function ($) {
			return $.gs;
		}));
var $author$project$Views$MapAssignment$Update$sortByValue = function (options) {
	return A2(
		$elm$core$List$sortBy,
		function ($) {
			return $.y;
		},
		options);
};
var $author$project$Views$MapAssignment$Update$sortTimeslotOptions = function (zone) {
	return $elm$core$List$sortWith(
		F2(
			function (opt1, opt2) {
				return A3($author$project$Views$MapAssignment$Update$sortTimeslots, zone, opt1.y, opt2.y);
			}));
};
var $author$project$Config$startTimeErrorKeyManualRouting = 'start_time_manual_error';
var $author$project$Views$MapAssignment$Update$settingExpired = F2(
	function (_v0, settingTime) {
		var time = _v0.d_;
		var millisInThreeHours = ((3 * 60) * 60) * 1000;
		if (settingTime.$ === 1) {
			return true;
		} else {
			var startTime = settingTime.a;
			return _Utils_cmp(
				$elm$time$Time$posixToMillis(time) - $elm$time$Time$posixToMillis(startTime),
				millisInThreeHours) > 0;
		}
	});
var $author$project$Views$MapAssignment$Update$startTimeNeedsReset = F3(
	function (context, routingSettings, _v0) {
		var startTime = routingSettings.ak;
		var startTimeSetAt = routingSettings.hr;
		var startTimeText = _v0.dP;
		var _v1 = _Utils_Tuple2(
			$elm$core$String$isEmpty(startTimeText),
			_Utils_eq(startTime, $elm$core$Maybe$Nothing) || (_Utils_eq(startTimeSetAt, $elm$core$Maybe$Nothing) || A2($author$project$Views$MapAssignment$Update$settingExpired, context, routingSettings.hr)));
		if (!_v1.a) {
			return false;
		} else {
			if (_v1.b) {
				return true;
			} else {
				return false;
			}
		}
	});
var $author$project$Common$Vehicle$stringToVehicle = function (val) {
	switch (val) {
		case 'Motorcycle':
			return $elm$core$Maybe$Just(0);
		case 'Van':
			return $elm$core$Maybe$Just(1);
		case 'Bicycle':
			return $elm$core$Maybe$Just(2);
		case 'Truck':
			return $elm$core$Maybe$Just(3);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $PaackEng$elm_alert_beta$Alert$Success = 0;
var $PaackEng$elm_alert_beta$Alert$success = F2(
	function (message, state) {
		return A3($PaackEng$elm_alert_beta$Alert$genericAlert, message, 0, state);
	});
var $author$project$Data$RouteJob$RouteJob = function (id) {
	return {fS: id};
};
var $author$project$Data$RouteJob$decoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2($elm$json$Json$Decode$field, 'job_id', $elm$json$Json$Decode$string),
	$elm$json$Json$Decode$succeed($author$project$Data$RouteJob$RouteJob));
var $author$project$Request$RouteRequest$syncOptimizeOrdersExpects = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			function (response) {
				switch (response.$) {
					case 4:
						var body = response.b;
						var _v1 = A2($elm$json$Json$Decode$decodeString, decoder, body);
						if (!_v1.$) {
							var value = _v1.a;
							return $elm$core$Result$Ok(value);
						} else {
							var err = _v1.a;
							return $elm$core$Result$Err(
								'Response parsing error: ' + $elm$json$Json$Decode$errorToString(err));
						}
					case 3:
						var statusCode = response.a.dQ;
						var body = response.b;
						var _v2 = A2(
							$elm$json$Json$Decode$decodeString,
							A2(
								$elm$json$Json$Decode$at,
								_List_fromArray(
									['error', 'message']),
								$elm$json$Json$Decode$string),
							body);
						if (!_v2.$) {
							var error = _v2.a;
							return $elm$core$Result$Err(error);
						} else {
							if (statusCode === 429) {
								return $elm$core$Result$Err('Another request running, wait until it is completed.');
							} else {
								return $elm$core$Result$Err('Ensure the delivery date of the orders is valid and try again');
							}
						}
					case 1:
						return $elm$core$Result$Err('Optimization failed because of request timed out');
					case 2:
						return $elm$core$Result$Err('Looks like there is an issue with your network');
					default:
						var url = response.a;
						return $elm$core$Result$Err('Bad url error, the url was: ' + url);
				}
			});
	});
var $author$project$Request$RouteRequest$syncOptimizeRouteUrl = function (env) {
	return $author$project$Request$Url$apiRailsUrl(env) + 'api/v2/routes/optimize_route';
};
var $elm$json$Json$Encode$float = _Json_wrap;
var $justinmimbs$time_extra$Time$Extra$partsToPosix = F2(
	function (zone, _v0) {
		var year = _v0.ej;
		var month = _v0.cQ;
		var day = _v0.bT;
		var hour = _v0.fQ;
		var minute = _v0.a4;
		var second = _v0.hg;
		var millisecond = _v0.a3;
		return A3(
			$justinmimbs$time_extra$Time$Extra$posixFromDateTime,
			zone,
			A3($justinmimbs$date$Date$fromCalendarDate, year, month, day),
			A4(
				$justinmimbs$time_extra$Time$Extra$timeFromClock,
				A3($elm$core$Basics$clamp, 0, 23, hour),
				A3($elm$core$Basics$clamp, 0, 59, minute),
				A3($elm$core$Basics$clamp, 0, 59, second),
				A3($elm$core$Basics$clamp, 0, 999, millisecond)));
	});
var $justinmimbs$time_extra$Time$Extra$posixToParts = F2(
	function (zone, posix) {
		return {
			bT: A2($elm$time$Time$toDay, zone, posix),
			fQ: A2($elm$time$Time$toHour, zone, posix),
			a3: A2($elm$time$Time$toMillis, zone, posix),
			a4: A2($elm$time$Time$toMinute, zone, posix),
			cQ: A2($elm$time$Time$toMonth, zone, posix),
			hg: A2($elm$time$Time$toSecond, zone, posix),
			ej: A2($elm$time$Time$toYear, zone, posix)
		};
	});
var $author$project$Request$RouteRequest$buildFakeVehicleIfNecessary = F3(
	function (zone, now, data) {
		var storeAddress = F2(
			function (address, latlon) {
				return $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'location_id',
							$elm$json$Json$Encode$string(
								$elm$core$String$fromInt(address.fS))),
							_Utils_Tuple2(
							'lat',
							$elm$json$Json$Encode$float(latlon.f8)),
							_Utils_Tuple2(
							'lon',
							$elm$json$Json$Encode$float(latlon.gb))
						]));
			});
		var getLatlon = function (maybeAddress) {
			if (maybeAddress.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var address = maybeAddress.a;
				return address.ga;
			}
		};
		var fakeVehicleId = _Utils_Tuple2(
			'vehicle_id',
			$elm$json$Json$Encode$string('truck'));
		var baseVehicleData = function (address) {
			return _List_fromArray(
				[
					fakeVehicleId,
					_Utils_Tuple2(
					'return_depot',
					$elm$json$Json$Encode$bool(false)),
					_Utils_Tuple2('start_address', address)
				]);
		};
		if ((data.ak !== '') || (data.eg !== '')) {
			var pickupAddress = function () {
				var address = A2(
					$elm$core$Maybe$andThen,
					function ($) {
						return $.er;
					},
					data.aE);
				return A3(
					$elm$core$Maybe$map2,
					storeAddress,
					address,
					getLatlon(address));
			}();
			var minutes = A2(
				$elm$core$Maybe$andThen,
				$elm$core$String$toInt,
				$elm$core$List$head(
					$elm$core$List$reverse(
						A2($elm$core$String$split, ':', data.ak))));
			var hours = A2(
				$elm$core$Maybe$andThen,
				$elm$core$String$toInt,
				$elm$core$List$head(
					A2($elm$core$String$split, ':', data.ak)));
			var startTime = A3(
				$elm$core$Maybe$map2,
				F2(
					function (hour, minute) {
						var parts = A2($justinmimbs$time_extra$Time$Extra$posixToParts, zone, now);
						var time = $elm$time$Time$posixToMillis(
							A2(
								$justinmimbs$time_extra$Time$Extra$partsToPosix,
								zone,
								_Utils_update(
									parts,
									{fQ: hour, a4: minute, hg: 0})));
						return (time / 1000) | 0;
					}),
				hours,
				minutes);
			var _v0 = _Utils_Tuple3(
				$elm$core$String$toInt(data.eg),
				startTime,
				pickupAddress);
			_v0$3:
			while (true) {
				if (!_v0.c.$) {
					if (!_v0.a.$) {
						if (!_v0.b.$) {
							var workingHours = _v0.a.a;
							var time = _v0.b.a;
							var address = _v0.c.a;
							return _List_fromArray(
								[
									$elm$json$Json$Encode$object(
									A2(
										$elm$core$List$append,
										baseVehicleData(address),
										_List_fromArray(
											[
												_Utils_Tuple2(
												'max_working_hours',
												$elm$json$Json$Encode$int(workingHours)),
												_Utils_Tuple2(
												'start_time',
												$elm$json$Json$Encode$int(time)),
												_Utils_Tuple2(
												'type_id',
												$elm$json$Json$Encode$string('truck'))
											])))
								]);
						} else {
							var workingHours = _v0.a.a;
							var address = _v0.c.a;
							return _List_fromArray(
								[
									$elm$json$Json$Encode$object(
									A2(
										$elm$core$List$append,
										baseVehicleData(address),
										_List_fromArray(
											[
												_Utils_Tuple2(
												'max_working_hours',
												$elm$json$Json$Encode$int(workingHours)),
												_Utils_Tuple2(
												'type_id',
												$elm$json$Json$Encode$string('truck'))
											])))
								]);
						}
					} else {
						if (!_v0.b.$) {
							var time = _v0.b.a;
							var address = _v0.c.a;
							return _List_fromArray(
								[
									$elm$json$Json$Encode$object(
									A2(
										$elm$core$List$append,
										baseVehicleData(address),
										_List_fromArray(
											[
												_Utils_Tuple2(
												'start_time',
												$elm$json$Json$Encode$int(time)),
												_Utils_Tuple2(
												'type_id',
												$elm$json$Json$Encode$string('truck'))
											])))
								]);
						} else {
							break _v0$3;
						}
					}
				} else {
					break _v0$3;
				}
			}
			return _List_Nil;
		} else {
			return _List_Nil;
		}
	});
var $author$project$Common$Address$fullAddress = function (_v0) {
	var title = _v0.hH;
	var postalCode = _v0.gX;
	var city = _v0.eL;
	return A2(
		$elm$core$String$join,
		' ',
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[title, postalCode, city])));
};
var $author$project$Request$Route$getLocationId = F2(
	function (_v0, warehouseId) {
		var orderType = _v0.gK;
		var pickupAddress = _v0.gT;
		var deliveryAddress = _v0.e6;
		switch (orderType) {
			case 1:
				return {I: warehouseId, J: pickupAddress.fS};
			case 0:
				return {I: deliveryAddress.fS, J: warehouseId};
			default:
				return {I: deliveryAddress.fS, J: warehouseId};
		}
	});
var $author$project$Request$Route$getServiceTime = F2(
	function (_v0, serviceTime) {
		var orderType = _v0.gK;
		switch (orderType) {
			case 1:
				return {
					I: $elm$core$Maybe$Just(0),
					J: serviceTime
				};
			case 0:
				return {
					I: serviceTime,
					J: $elm$core$Maybe$Just(0)
				};
			default:
				return {I: serviceTime, J: serviceTime};
		}
	});
var $myrho$elm_round$Round$funNum = F3(
	function (fun, s, fl) {
		return A2(
			$elm$core$Maybe$withDefault,
			0 / 0,
			$elm$core$String$toFloat(
				A2(fun, s, fl)));
	});
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $myrho$elm_round$Round$addSign = F2(
	function (signed, str) {
		var isNotZero = A2(
			$elm$core$List$any,
			function (c) {
				return (c !== '0') && (c !== '.');
			},
			$elm$core$String$toList(str));
		return _Utils_ap(
			(signed && isNotZero) ? '-' : '',
			str);
	});
var $elm$core$String$cons = _String_cons;
var $elm$core$Char$fromCode = _Char_fromCode;
var $myrho$elm_round$Round$increaseNum = function (_v0) {
	var head = _v0.a;
	var tail = _v0.b;
	if (head === '9') {
		var _v1 = $elm$core$String$uncons(tail);
		if (_v1.$ === 1) {
			return '01';
		} else {
			var headtail = _v1.a;
			return A2(
				$elm$core$String$cons,
				'0',
				$myrho$elm_round$Round$increaseNum(headtail));
		}
	} else {
		var c = $elm$core$Char$toCode(head);
		return ((c >= 48) && (c < 57)) ? A2(
			$elm$core$String$cons,
			$elm$core$Char$fromCode(c + 1),
			tail) : '0';
	}
};
var $elm$core$Basics$isInfinite = _Basics_isInfinite;
var $elm$core$Basics$isNaN = _Basics_isNaN;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padRight = F3(
	function (n, _char, string) {
		return _Utils_ap(
			string,
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)));
	});
var $elm$core$String$reverse = _String_reverse;
var $myrho$elm_round$Round$splitComma = function (str) {
	var _v0 = A2($elm$core$String$split, '.', str);
	if (_v0.b) {
		if (_v0.b.b) {
			var before = _v0.a;
			var _v1 = _v0.b;
			var after = _v1.a;
			return _Utils_Tuple2(before, after);
		} else {
			var before = _v0.a;
			return _Utils_Tuple2(before, '0');
		}
	} else {
		return _Utils_Tuple2('0', '0');
	}
};
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $myrho$elm_round$Round$toDecimal = function (fl) {
	var _v0 = A2(
		$elm$core$String$split,
		'e',
		$elm$core$String$fromFloat(
			$elm$core$Basics$abs(fl)));
	if (_v0.b) {
		if (_v0.b.b) {
			var num = _v0.a;
			var _v1 = _v0.b;
			var exp = _v1.a;
			var e = A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A2($elm$core$String$startsWith, '+', exp) ? A2($elm$core$String$dropLeft, 1, exp) : exp));
			var _v2 = $myrho$elm_round$Round$splitComma(num);
			var before = _v2.a;
			var after = _v2.b;
			var total = _Utils_ap(before, after);
			var zeroed = (e < 0) ? A2(
				$elm$core$Maybe$withDefault,
				'0',
				A2(
					$elm$core$Maybe$map,
					function (_v3) {
						var a = _v3.a;
						var b = _v3.b;
						return a + ('.' + b);
					},
					A2(
						$elm$core$Maybe$map,
						$elm$core$Tuple$mapFirst($elm$core$String$fromChar),
						$elm$core$String$uncons(
							_Utils_ap(
								A2(
									$elm$core$String$repeat,
									$elm$core$Basics$abs(e),
									'0'),
								total))))) : A3($elm$core$String$padRight, e + 1, '0', total);
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				zeroed);
		} else {
			var num = _v0.a;
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				num);
		}
	} else {
		return '';
	}
};
var $myrho$elm_round$Round$roundFun = F3(
	function (functor, s, fl) {
		if ($elm$core$Basics$isInfinite(fl) || $elm$core$Basics$isNaN(fl)) {
			return $elm$core$String$fromFloat(fl);
		} else {
			var signed = fl < 0;
			var _v0 = $myrho$elm_round$Round$splitComma(
				$myrho$elm_round$Round$toDecimal(
					$elm$core$Basics$abs(fl)));
			var before = _v0.a;
			var after = _v0.b;
			var r = $elm$core$String$length(before) + s;
			var normalized = _Utils_ap(
				A2($elm$core$String$repeat, (-r) + 1, '0'),
				A3(
					$elm$core$String$padRight,
					r,
					'0',
					_Utils_ap(before, after)));
			var totalLen = $elm$core$String$length(normalized);
			var roundDigitIndex = A2($elm$core$Basics$max, 1, r);
			var increase = A2(
				functor,
				signed,
				A3($elm$core$String$slice, roundDigitIndex, totalLen, normalized));
			var remains = A3($elm$core$String$slice, 0, roundDigitIndex, normalized);
			var num = increase ? $elm$core$String$reverse(
				A2(
					$elm$core$Maybe$withDefault,
					'1',
					A2(
						$elm$core$Maybe$map,
						$myrho$elm_round$Round$increaseNum,
						$elm$core$String$uncons(
							$elm$core$String$reverse(remains))))) : remains;
			var numLen = $elm$core$String$length(num);
			var numZeroed = (num === '0') ? num : ((s <= 0) ? _Utils_ap(
				num,
				A2(
					$elm$core$String$repeat,
					$elm$core$Basics$abs(s),
					'0')) : ((_Utils_cmp(
				s,
				$elm$core$String$length(after)) < 0) ? (A3($elm$core$String$slice, 0, numLen - s, num) + ('.' + A3($elm$core$String$slice, numLen - s, numLen, num))) : _Utils_ap(
				before + '.',
				A3($elm$core$String$padRight, s, '0', after))));
			return A2($myrho$elm_round$Round$addSign, signed, numZeroed);
		}
	});
var $myrho$elm_round$Round$round = $myrho$elm_round$Round$roundFun(
	F2(
		function (signed, str) {
			var _v0 = $elm$core$String$uncons(str);
			if (_v0.$ === 1) {
				return false;
			} else {
				if ('5' === _v0.a.a) {
					if (_v0.a.b === '') {
						var _v1 = _v0.a;
						return !signed;
					} else {
						var _v2 = _v0.a;
						return true;
					}
				} else {
					var _v3 = _v0.a;
					var _int = _v3.a;
					return function (i) {
						return ((i > 53) && signed) || ((i >= 53) && (!signed));
					}(
						$elm$core$Char$toCode(_int));
				}
			}
		}));
var $myrho$elm_round$Round$roundNum = $myrho$elm_round$Round$funNum($myrho$elm_round$Round$round);
var $author$project$Data$Package$maybeFloat = function (maybeF) {
	if (!maybeF.$) {
		var _float = maybeF.a;
		return $elm$json$Json$Encode$float(_float);
	} else {
		return $elm$json$Json$Encode$null;
	}
};
var $author$project$Data$Package$simpleEncoder = function (_v0) {
	var id = _v0.fS;
	var volume = _v0.bo;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(id)),
				_Utils_Tuple2(
				'volume',
				$author$project$Data$Package$maybeFloat(volume))
			]));
};
var $elm$core$Basics$truncate = _Basics_truncate;
var $author$project$Request$RouteRequest$buildShipments = F2(
	function (_v0, data) {
		var timeToSeconds = function (time) {
			return ($elm$time$Time$posixToMillis(time) / 1000) | 0;
		};
		var timewindow = function (order) {
			var latest = timeToSeconds(order.e7.fp);
			var earliest = timeToSeconds(order.e7.hq);
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'earliest',
						$elm$json$Json$Encode$int(earliest)),
						_Utils_Tuple2(
						'latest',
						$elm$json$Json$Encode$int(latest))
					]));
		};
		var getLatlon = function (maybeAddress) {
			return A2(
				$elm$core$Maybe$andThen,
				function ($) {
					return $.ga;
				},
				maybeAddress);
		};
		var addressEncode = F3(
			function (location_id, latlon, address) {
				return $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'location_id',
							$elm$json$Json$Encode$string(location_id)),
							_Utils_Tuple2(
							'name',
							$elm$json$Json$Encode$string(
								$author$project$Common$Address$fullAddress(address))),
							_Utils_Tuple2(
							'lat',
							$elm$json$Json$Encode$float(latlon.f8)),
							_Utils_Tuple2(
							'lon',
							$elm$json$Json$Encode$float(latlon.gb))
						]));
			});
		var _v1 = data;
		var orders = _v1.gL;
		var originWarehouse = _v1.aE;
		var packages = _v1.c6;
		var deliveryServiceTime = _v1.bW;
		var warehouseId = function (order) {
			return A2(
				$elm$core$Maybe$withDefault,
				order.gT.fS,
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.fS;
					},
					originWarehouse));
		};
		var deliveryAddress = function (order) {
			var location_id = $elm$core$String$fromInt(
				A2(
					$author$project$Request$Route$getLocationId,
					order,
					warehouseId(order)).I);
			var address = (order.gK === 1) ? A2(
				$elm$core$Maybe$withDefault,
				order.e6,
				A2(
					$elm$core$Maybe$andThen,
					function ($) {
						return $.er;
					},
					originWarehouse)) : order.e6;
			var addressEncoded = A3(
				$elm$core$Maybe$map2,
				addressEncode(location_id),
				getLatlon(
					$elm$core$Maybe$Just(address)),
				$elm$core$Maybe$Just(address));
			return A3(
				$elm$core$Maybe$map2,
				F2(
					function (address1, st) {
						return $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2('address', address1),
									_Utils_Tuple2(
									'time_windows',
									A2(
										$elm$json$Json$Encode$list,
										timewindow,
										_List_fromArray(
											[order]))),
									_Utils_Tuple2(
									'service_time',
									$elm$json$Json$Encode$int(
										A2(
											$elm$core$Maybe$withDefault,
											0,
											A2(
												$author$project$Request$Route$getServiceTime,
												order,
												$elm$core$Maybe$Just(
													A2($myrho$elm_round$Round$roundNum, 1, st) * 60)).I) | 0))
								]));
					}),
				addressEncoded,
				deliveryServiceTime);
		};
		var pickupAddress = function (order) {
			var tempPickupAddress = function () {
				var address = (order.gK === 1) ? $elm$core$Maybe$Just(order.gT) : A2(
					$elm$core$Maybe$andThen,
					function ($) {
						return $.er;
					},
					originWarehouse);
				return A2(
					$author$project$Views$Helpers$Maybe$maybeOr,
					address,
					$elm$core$Maybe$Just(order.gT));
			}();
			var location_id = $elm$core$String$fromInt(
				A2(
					$author$project$Request$Route$getLocationId,
					order,
					warehouseId(order)).J);
			var addressEncoded = A3(
				$elm$core$Maybe$map2,
				addressEncode(location_id),
				getLatlon(tempPickupAddress),
				tempPickupAddress);
			return A3(
				$elm$core$Maybe$map2,
				F2(
					function (address1, st) {
						return $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2('address', address1),
									_Utils_Tuple2(
									'time_windows',
									A2(
										$elm$json$Json$Encode$list,
										timewindow,
										_List_fromArray(
											[order]))),
									_Utils_Tuple2(
									'service_time',
									$elm$json$Json$Encode$int(
										A2(
											$elm$core$Maybe$withDefault,
											0,
											A2(
												$author$project$Request$Route$getServiceTime,
												order,
												$elm$core$Maybe$Just(
													A2($myrho$elm_round$Round$roundNum, 1, st) * 60)).J) | 0))
								]));
					}),
				addressEncoded,
				deliveryServiceTime);
		};
		var orderPackages = function (order) {
			return A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				A2($elm$core$Dict$get, order.fS, packages));
		};
		var finalShipment = F3(
			function (order, pickupData, deliveryData) {
				return $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'id',
							$elm$json$Json$Encode$string(
								$elm$core$String$fromInt(order.fS))),
							_Utils_Tuple2(
							'name',
							$elm$json$Json$Encode$string(
								$elm$core$String$fromInt(order.fS))),
							_Utils_Tuple2('pickup', pickupData),
							_Utils_Tuple2('delivery', deliveryData),
							_Utils_Tuple2(
							'packages',
							A2(
								$elm$json$Json$Encode$list,
								$author$project$Data$Package$simpleEncoder,
								orderPackages(order)))
						]));
			});
		var shipmentEncodeValue = function (order) {
			return A4(
				$elm$core$Maybe$map3,
				finalShipment,
				$elm$core$Maybe$Just(order),
				pickupAddress(order),
				deliveryAddress(order));
		};
		return A2($elm$core$List$filterMap, shipmentEncodeValue, orders);
	});
var $author$project$Request$RouteRequest$buildVehicles = function (data) {
	var vehicleData = F2(
		function (driver, pka) {
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'vehicle_id',
						$elm$json$Json$Encode$string(
							$elm$core$String$fromInt(driver.fS))),
						_Utils_Tuple2(
						'return_depot',
						$elm$json$Json$Encode$bool(false)),
						_Utils_Tuple2('start_address', pka)
					]));
		});
	var storeAddress = F2(
		function (address, latlon) {
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'location_id',
						$elm$json$Json$Encode$string(
							$elm$core$String$fromInt(address.fS))),
						_Utils_Tuple2(
						'lat',
						$elm$json$Json$Encode$float(latlon.f8)),
						_Utils_Tuple2(
						'lon',
						$elm$json$Json$Encode$float(latlon.gb))
					]));
		});
	var getLatlon = function (maybeAddress) {
		if (maybeAddress.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var address = maybeAddress.a;
			return address.ga;
		}
	};
	var pickupAddress = function () {
		var address = A2(
			$elm$core$Maybe$andThen,
			function ($) {
				return $.er;
			},
			data.aE);
		return A3(
			$elm$core$Maybe$map2,
			storeAddress,
			address,
			getLatlon(address));
	}();
	var allVehiclesData = A2(
		$elm$core$List$filterMap,
		function (d) {
			return A3(
				$elm$core$Maybe$map2,
				vehicleData,
				$elm$core$Maybe$Just(d),
				pickupAddress);
		},
		data.b_);
	return allVehiclesData;
};
var $author$project$Request$RouteRequest$configuration = function () {
	var routingConfig = function (calc_points) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'calc_points',
					$elm$json$Json$Encode$bool(calc_points))
				]));
	};
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'routing',
				routingConfig(true))
			]));
}();
var $author$project$Request$RouteRequest$syncRouteRequestEncoder = F4(
	function (zone, time, data, routeId) {
		var shouldAddFakeVehicle = (data.eg !== '') || ((data.ak !== '') || ((data.bE !== '') || data.bt));
		var vehicles = function () {
			var _v4 = _Utils_Tuple2(
				$author$project$Request$RouteRequest$buildVehicles(data),
				shouldAddFakeVehicle);
			if ((!_v4.a.b) && _v4.b) {
				return A3($author$project$Request$RouteRequest$buildFakeVehicleIfNecessary, zone, time, data);
			} else {
				var list = _v4.a;
				return list;
			}
		}();
		var vehiclesTypes = function () {
			var _v3 = _Utils_Tuple2(
				$author$project$Request$RouteRequest$buildVehicles(data),
				shouldAddFakeVehicle);
			if ((!_v3.a.b) && _v3.b) {
				var largeVehicleCapacity = 1000000;
				var defaultFakeCapacity = A2(
					$elm$core$Maybe$withDefault,
					largeVehicleCapacity,
					$elm$core$String$toFloat(data.bE));
				return _List_fromArray(
					[
						$elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'type_id',
								$elm$json$Json$Encode$string('truck')),
								_Utils_Tuple2(
								'profile',
								$elm$json$Json$Encode$string('truck')),
								_Utils_Tuple2(
								'capacity',
								A2(
									$elm$json$Json$Encode$list,
									$elm$json$Json$Encode$float,
									_List_fromArray(
										[defaultFakeCapacity])))
							]))
					]);
			} else {
				return _List_fromArray(
					[
						$elm$json$Json$Encode$object(_List_Nil)
					]);
			}
		}();
		var shipments = A2($author$project$Request$RouteRequest$buildShipments, zone, data);
		var addIf = F3(
			function (condition, item, list) {
				return condition ? A2($elm$core$List$cons, item, list) : list;
			});
		var _v0 = data;
		var unlimitedFleet = _v0.d6;
		var linearDistanceLimit = _v0.cF;
		var packageVolume = _v0.c5;
		var deliveryServiceTimeGrouped = _v0.bY;
		return $elm$json$Json$Encode$object(
			A3(
				addIf,
				shouldAddFakeVehicle,
				_Utils_Tuple2(
					'vehicle_types',
					A2($elm$json$Json$Encode$list, $elm$core$Basics$identity, vehiclesTypes)),
				function (list) {
					if (!packageVolume.$) {
						var pv = packageVolume.a;
						return A2(
							$elm$core$List$cons,
							_Utils_Tuple2(
								'default_package_volume',
								$elm$json$Json$Encode$float(pv)),
							list);
					} else {
						return list;
					}
				}(
					function (list) {
						if (!linearDistanceLimit.$) {
							var linearDistance = linearDistanceLimit.a;
							return A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									'max_node_distance',
									$elm$json$Json$Encode$int(linearDistance)),
								list);
						} else {
							return list;
						}
					}(
						_List_fromArray(
							[
								_Utils_Tuple2('configuration', $author$project$Request$RouteRequest$configuration),
								_Utils_Tuple2(
								'shipments',
								A2($elm$json$Json$Encode$list, $elm$core$Basics$identity, shipments)),
								_Utils_Tuple2(
								'unlimited_fleet',
								$elm$json$Json$Encode$bool(unlimitedFleet)),
								_Utils_Tuple2(
								'route_id',
								$elm$json$Json$Encode$int(routeId)),
								_Utils_Tuple2(
								'distance_matrix_algo',
								$elm$json$Json$Encode$string('graphhopper')),
								_Utils_Tuple2(
								'vehicles',
								A2($elm$json$Json$Encode$list, $elm$core$Basics$identity, vehicles)),
								_Utils_Tuple2(
								'delivery_service_time_grouped',
								$elm$json$Json$Encode$bool(deliveryServiceTimeGrouped))
							])))));
	});
var $author$project$Request$RouteRequest$syncOptimizeOrders = F4(
	function (_v0, routeId, data, msg) {
		var user = _v0.d7;
		var env = _v0.fr;
		var zone = _v0.em;
		var time = _v0.d_;
		return $elm$http$Http$request(
			{
				eC: $elm$http$Http$jsonBody(
					A4($author$project$Request$RouteRequest$syncRouteRequestEncoder, zone, time, data, routeId)),
				b6: A2($author$project$Request$RouteRequest$syncOptimizeOrdersExpects, msg, $author$project$Data$RouteJob$decoder),
				ch: _List_fromArray(
					[
						A2(
						$elm$http$Http$header,
						'X-Authentication',
						A2(
							$elm$core$Maybe$withDefault,
							'',
							A2(
								$elm$core$Maybe$map,
								function ($) {
									return $.bs;
								},
								user)))
					]),
				cM: 'POST',
				d$: $elm$core$Maybe$Nothing,
				d2: $elm$core$Maybe$Nothing,
				hV: $author$project$Request$RouteRequest$syncOptimizeRouteUrl(env)
			});
	});
var $author$project$Views$MapAssignment$Settings$ClusterSettings$initModelAsClustered = $author$project$Views$MapAssignment$Types$Clustered(
	{fN: 40, go: 2});
var $author$project$Views$MapAssignment$Settings$ClusterSettings$toggleIsClustered = function (clusterSettings) {
	if (!clusterSettings.$) {
		return $author$project$Views$MapAssignment$Settings$ClusterSettings$initModelAsClustered;
	} else {
		return $author$project$Views$MapAssignment$Settings$ClusterSettings$initModelAsNotClustered;
	}
};
var $PaackEng$elm_alert_beta$Alert$Animating = 1;
var $PaackEng$elm_alert_beta$Alert$Leaving = 2;
var $PaackEng$elm_alert_beta$Alert$OnAlertRemoved = function (a) {
	return {$: 2, a: a};
};
var $PaackEng$elm_alert_beta$Alert$OnAlertStartRemoving = function (a) {
	return {$: 1, a: a};
};
var $PaackEng$elm_alert_beta$Alert$update = F2(
	function (msg, _v0) {
		var state = _v0;
		switch (msg.$) {
			case 0:
				var alertId = msg.a;
				var newCmds = A2(
					$elm$core$List$map,
					function (alert) {
						return _Utils_eq(alert.fS, alertId) ? A2(
							$elm$core$Task$perform,
							function (_v2) {
								return $PaackEng$elm_alert_beta$Alert$OnAlertStartRemoving(alert.fS);
							},
							$elm$core$Process$sleep(5000)) : $elm$core$Platform$Cmd$none;
					},
					state.bv);
				var newAlerts = A2(
					$elm$core$List$map,
					function (alert) {
						return _Utils_eq(alert.fS, alertId) ? _Utils_update(
							alert,
							{ht: 1}) : alert;
					},
					state.bv);
				return _Utils_Tuple2(
					_Utils_update(
						state,
						{bv: newAlerts}),
					$elm$core$Platform$Cmd$batch(newCmds));
			case 1:
				var alertId = msg.a;
				var newCmds = A2(
					$elm$core$List$map,
					function (alert) {
						return _Utils_eq(alert.fS, alertId) ? A2(
							$elm$core$Task$perform,
							function (_v3) {
								return $PaackEng$elm_alert_beta$Alert$OnAlertRemoved(alert.fS);
							},
							$elm$core$Process$sleep(600)) : $elm$core$Platform$Cmd$none;
					},
					state.bv);
				var newAlerts = A2(
					$elm$core$List$map,
					function (alert) {
						return _Utils_eq(alert.fS, alertId) ? _Utils_update(
							alert,
							{ht: 2}) : alert;
					},
					state.bv);
				return _Utils_Tuple2(
					_Utils_update(
						state,
						{bv: newAlerts}),
					$elm$core$Platform$Cmd$batch(newCmds));
			default:
				var alertId = msg.a;
				var newAlerts = A2(
					$elm$core$List$filter,
					function (alert) {
						return !_Utils_eq(alertId, alert.fS);
					},
					state.bv);
				return _Utils_Tuple2(
					_Utils_update(
						state,
						{bv: newAlerts}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $FabienHenon$elm_infinite_scroll$InfiniteScroll$Timeout = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $FabienHenon$elm_infinite_scroll$InfiniteScroll$CurrTime = function (a) {
	return {$: 1, a: a};
};
var $FabienHenon$elm_infinite_scroll$InfiniteScroll$shouldLoadMore = F2(
	function (model, _v0) {
		var scrollTop = _v0.dD;
		var contentHeight = _v0.bQ;
		var containerHeight = _v0.bO;
		if (model.f0) {
			return false;
		} else {
			var _v1 = model.aa;
			if (!_v1) {
				return _Utils_cmp(scrollTop, model.cV) < 1;
			} else {
				var excessHeight = contentHeight - containerHeight;
				return _Utils_cmp(scrollTop, excessHeight - model.cV) > -1;
			}
		}
	});
var $FabienHenon$elm_infinite_scroll$InfiniteScroll$startLoading = function (_v0) {
	var model = _v0;
	return _Utils_update(
		model,
		{f0: true});
};
var $FabienHenon$elm_infinite_scroll$InfiniteScroll$scrollUpdate = F3(
	function (mapper, pos, _v0) {
		var model = _v0;
		return A2($FabienHenon$elm_infinite_scroll$InfiniteScroll$shouldLoadMore, model, pos) ? _Utils_Tuple2(
			$FabienHenon$elm_infinite_scroll$InfiniteScroll$startLoading(model),
			A2(
				$elm$core$Platform$Cmd$map,
				mapper,
				A2($elm$core$Task$perform, $FabienHenon$elm_infinite_scroll$InfiniteScroll$CurrTime, $elm$time$Time$now))) : _Utils_Tuple2(
			model,
			A2($elm$core$Platform$Cmd$map, mapper, $elm$core$Platform$Cmd$none));
	});
var $FabienHenon$elm_infinite_scroll$InfiniteScroll$stopLoading = function (_v0) {
	var model = _v0;
	return _Utils_update(
		model,
		{f0: false});
};
var $FabienHenon$elm_infinite_scroll$InfiniteScroll$update = F3(
	function (mapper, msg, _v0) {
		var model = _v0;
		switch (msg.$) {
			case 0:
				var pos = msg.a;
				return A3($FabienHenon$elm_infinite_scroll$InfiniteScroll$scrollUpdate, mapper, pos, model);
			case 1:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{at: time}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								model.av(model.aa),
								A2(
								$elm$core$Platform$Cmd$map,
								mapper,
								A2(
									$elm$core$Task$perform,
									$FabienHenon$elm_infinite_scroll$InfiniteScroll$Timeout(time),
									$elm$core$Process$sleep(model.d$)))
							])));
			default:
				var time = msg.a;
				return _Utils_eq(time, model.at) ? _Utils_Tuple2(
					$FabienHenon$elm_infinite_scroll$InfiniteScroll$stopLoading(model),
					A2($elm$core$Platform$Cmd$map, mapper, $elm$core$Platform$Cmd$none)) : _Utils_Tuple2(
					model,
					A2($elm$core$Platform$Cmd$map, mapper, $elm$core$Platform$Cmd$none));
		}
	});
var $author$project$Page$AutomaticRoute$Msg$AutomaticRouteSolutionMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$AutomaticRoute$Msg$CancelExecutionRequest = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $author$project$Page$AutomaticRoute$Msg$Close = {$: 0};
var $author$project$Page$AutomaticRoute$Msg$CloseSelectedExecution = {$: 3};
var $author$project$Page$AutomaticRoute$Msg$ExecutionQueuPositionRecieved = function (a) {
	return {$: 18, a: a};
};
var $author$project$Page$AutomaticRoute$Msg$FetchActiveSolutionInformation = {$: 16};
var $author$project$Page$AutomaticRoute$Msg$FetchOrdersCreateExecution = F3(
	function (a, b, c) {
		return {$: 22, a: a, b: b, c: c};
	});
var $author$project$Page$AutomaticRoute$Msg$FetchSelectedExecutionKpis = F2(
	function (a, b) {
		return {$: 24, a: a, b: b};
	});
var $author$project$Page$AutomaticRouteSolution$Msg$ReloadSolutions = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$AutomaticRouteSolution$Msg$SaveSolution = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$AutomaticRoute$Msg$SolutionInformationReceived = function (a) {
	return {$: 17, a: a};
};
var $author$project$Page$AutomaticRoute$Msg$SplitOrdersResponse = F2(
	function (a, b) {
		return {$: 15, a: a, b: b};
	});
var $author$project$Page$AutomaticRoute$Msg$AutomaticRouteStepMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$AutomaticRoute$Update$aRoute = function (msg) {
	if (msg.$ === 12) {
		return $author$project$Page$AutomaticRoute$Msg$CloseSelectedExecution;
	} else {
		var msg_ = msg;
		return $author$project$Page$AutomaticRoute$Msg$AutomaticRouteStepMsg(msg_);
	}
};
var $author$project$Page$AutomaticRoute$Msg$CancelExecution = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$AutomaticRoute$Msg$FetchSolutionOrders = F4(
	function (a, b, c, d) {
		return {$: 21, a: a, b: b, c: c, d: d};
	});
var $author$project$Page$AutomaticRoute$Update$atRoute = F2(
	function (model, msg) {
		switch (msg.$) {
			case 6:
				var _v1 = model.dF;
				if (!_v1.$) {
					var _v2 = _v1.a;
					var id = _v2.a;
					return $author$project$Page$AutomaticRoute$Msg$CancelExecution(id);
				} else {
					return $author$project$Page$AutomaticRoute$Msg$AutomaticRouteSolutionMsg(msg);
				}
			case 7:
				var execution = msg.a;
				return A2(
					$elm$core$Maybe$withDefault,
					$author$project$Page$AutomaticRoute$Msg$AutomaticRouteSolutionMsg(msg),
					A2(
						$elm$core$Maybe$map,
						function (_v3) {
							var exec = _v3.b;
							var _v4 = execution.hh;
							if (!_v4.$) {
								var _v5 = _v4.a;
								var sol = _v5.b;
								var routeUpdates = _v5.c;
								return A4($author$project$Page$AutomaticRoute$Msg$FetchSolutionOrders, sol.fS, routeUpdates.g3, exec.ev, exec.gs);
							} else {
								return $author$project$Page$AutomaticRoute$Msg$AutomaticRouteSolutionMsg(msg);
							}
						},
						model.dF));
			case 1:
				return $author$project$Page$AutomaticRoute$Msg$FetchActiveSolutionInformation;
			default:
				var msg_ = msg;
				return $author$project$Page$AutomaticRoute$Msg$AutomaticRouteSolutionMsg(msg_);
		}
	});
var $author$project$Request$HttpClient$authenticatedGetWithStringResponse = F3(
	function (token, url, msg) {
		return $elm$http$Http$request(
			A5(
				$author$project$Request$HttpClient$requestParams,
				'GET',
				_List_fromArray(
					[
						A2($elm$http$Http$header, 'X-Authentication', token)
					]),
				url,
				$elm$http$Http$emptyBody,
				$elm$http$Http$expectString(msg)));
	});
var $author$project$Request$RouteRequest$cancelJobGeneration = F3(
	function (_v0, jobId, msg) {
		var user = _v0.d7;
		var env = _v0.fr;
		return A3(
			$author$project$Request$HttpClient$authenticatedGetWithStringResponse,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.bs;
					},
					user)),
			$author$project$Request$Url$apiRailsUrl(env) + ('api/v2/routes/jobs/' + (jobId + '/cancel')),
			msg);
	});
var $author$project$Data$RouteJob$RouteJobPosition = F2(
	function (id, position) {
		return {fS: id, gW: position};
	});
var $author$project$Data$RouteJob$routeJobPositionDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2($elm$json$Json$Decode$field, 'position', $elm$json$Json$Decode$int),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string),
		$elm$json$Json$Decode$succeed($author$project$Data$RouteJob$RouteJobPosition)));
var $author$project$Request$RouteRequest$getExecutionPosition = F2(
	function (_v0, msg) {
		var user = _v0.d7;
		var env = _v0.fr;
		return A4(
			$author$project$Request$HttpClient$authenticatedGet,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.bs;
					},
					user)),
			$author$project$Request$Url$apiRailsUrl(env) + 'api/v2/routes/queue/peek',
			$elm$json$Json$Decode$list($author$project$Data$RouteJob$routeJobPositionDecoder),
			msg);
	});
var $author$project$Request$RouteRequest$getRouteOrdersListFromSolution = F4(
	function (_v0, solutionId, rejectedRoutes, msg) {
		var user = _v0.d7;
		var env = _v0.fr;
		var route_ids = A3(
			$elm$core$List$foldr,
			F2(
				function (x, a) {
					return x + (',' + a);
				}),
			'',
			rejectedRoutes);
		return A5(
			$author$project$Request$HttpClient$authenticatedPost,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.bs;
					},
					user)),
			$author$project$Request$Url$apiRailsUrl(env) + ('api/v2/routes/jobs/select/' + ($elm$core$String$fromInt(solutionId) + '/orders')),
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'route_ids',
						$elm$json$Json$Encode$string(route_ids))
					])),
			$elm$json$Json$Decode$list($author$project$Data$Order$decoder),
			msg);
	});
var $author$project$Page$AutomaticRoute$Model$initialSolution = function (mapStyle) {
	return A2(
		$author$project$Page$AutomaticRouteSolution$Model$initialModel,
		{ev: $elm$core$Maybe$Nothing, f3: '', hp: _List_Nil, ht: 0},
		mapStyle);
};
var $elm$core$List$maximum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$max, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$List$partition = F2(
	function (pred, list) {
		var step = F2(
			function (x, _v0) {
				var trues = _v0.a;
				var falses = _v0.b;
				return pred(x) ? _Utils_Tuple2(
					A2($elm$core$List$cons, x, trues),
					falses) : _Utils_Tuple2(
					trues,
					A2($elm$core$List$cons, x, falses));
			});
		return A3(
			$elm$core$List$foldr,
			step,
			_Utils_Tuple2(_List_Nil, _List_Nil),
			list);
	});
var $author$project$Data$Area$AreaOrdersSplit = F4(
	function (areaId, areaName, uniqueLocation, orders) {
		return {ev: areaId, ex: areaName, gL: orders, hQ: uniqueLocation};
	});
var $author$project$Data$Area$areaOrdersDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2(
		$elm$json$Json$Decode$field,
		'orders',
		$elm$json$Json$Decode$list($elm$json$Json$Decode$int)),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm_community$json_extra$Json$Decode$Extra$optionalField, 'unique_location', $elm$json$Json$Decode$int),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'area_name', $elm$json$Json$Decode$string),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'area_id', $elm$json$Json$Decode$string),
				$elm$json$Json$Decode$succeed($author$project$Data$Area$AreaOrdersSplit)))));
var $author$project$Request$Area$splitOrderByAreaUrl = function (env) {
	return $author$project$Request$Url$apiRailsUrl(env) + 'api/v2/routes/group_orders';
};
var $author$project$Request$Area$splitOrderByArea = F3(
	function (_v0, orders, msg) {
		var user = _v0.d7;
		var env = _v0.fr;
		var orderIds = A3(
			$elm$core$List$foldl,
			F2(
				function (a, b) {
					return a + (',' + b);
				}),
			'',
			A2(
				$elm$core$List$map,
				function (order) {
					return $elm$core$String$fromInt(order.fS);
				},
				orders));
		return $elm$http$Http$request(
			{
				eC: $elm$http$Http$jsonBody(
					$elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'order_ids',
								$elm$json$Json$Encode$string(orderIds))
							]))),
				b6: A2(
					$author$project$Common$ExpectJsonWithError$expectJsonWithError,
					msg,
					$elm$json$Json$Decode$list($author$project$Data$Area$areaOrdersDecoder)),
				ch: _List_fromArray(
					[
						A2(
						$elm$http$Http$header,
						'X-Authentication',
						A2(
							$elm$core$Maybe$withDefault,
							'',
							A2(
								$elm$core$Maybe$map,
								function ($) {
									return $.bs;
								},
								user)))
					]),
				cM: 'POST',
				d$: $elm$core$Maybe$Nothing,
				d2: $elm$core$Maybe$Nothing,
				hV: $author$project$Request$Area$splitOrderByAreaUrl(env)
			});
	});
var $author$project$Views$MapAssignment$Utils$pinCoordinates = function (order) {
	return ((order.gK === 1) ? order.gT : order.e6).ga;
};
var $elm$core$Dict$sizeHelp = F2(
	function (n, dict) {
		sizeHelp:
		while (true) {
			if (dict.$ === -2) {
				return n;
			} else {
				var left = dict.d;
				var right = dict.e;
				var $temp$n = A2($elm$core$Dict$sizeHelp, n + 1, right),
					$temp$dict = left;
				n = $temp$n;
				dict = $temp$dict;
				continue sizeHelp;
			}
		}
	});
var $elm$core$Dict$size = function (dict) {
	return A2($elm$core$Dict$sizeHelp, 0, dict);
};
var $elm$core$Set$size = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$size(dict);
};
var $author$project$Common$Locations$uniqueLocationsCount = function (orders) {
	return $elm$core$Set$size(
		$elm$core$Set$fromList(
			A2(
				$elm$core$List$filterMap,
				function (order) {
					return A2(
						$elm$core$Maybe$map,
						function (_v0) {
							var lat = _v0.f8;
							var lng = _v0.gb;
							return _Utils_Tuple2(lat, lng);
						},
						$author$project$Views$MapAssignment$Utils$pinCoordinates(order));
				},
				orders)));
};
var $author$project$Page$AutomaticRouteSolution$Msg$CancelProcessOutside = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$AutomaticRouteSolution$Model$ChangingRoute = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$AutomaticRouteSolution$Msg$GetExecutionKpiResponse = function (a) {
	return {$: 17, a: a};
};
var $author$project$Page$AutomaticRouteSolution$Model$GetKPIError = 2;
var $author$project$Page$AutomaticRouteSolution$Msg$GetKpiResponseJson = function (a) {
	return {$: 16, a: a};
};
var $author$project$Page$AutomaticRouteSolution$Msg$ReloadSolutionsOutside = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$AutomaticRouteSolution$Model$SaveSolutionError = 1;
var $author$project$Page$AutomaticRouteSolution$Msg$SaveSolutionRequest = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$AutomaticRouteSolution$Msg$SolutionSavedOutside = function (a) {
	return {$: 7, a: a};
};
var $author$project$Request$RouteRequest$fetchExecutionKPI = F3(
	function (_v0, executionId, msg) {
		var user = _v0.d7;
		var env = _v0.fr;
		return A3(
			$author$project$Request$HttpClient$authenticatedGetWithStringResponse,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.bs;
					},
					user)),
			$author$project$Request$Url$apiRailsUrl(env) + ('api/v2/routes/executions/' + executionId),
			msg);
	});
var $author$project$Request$HttpClient$authenticatedPostWithStringResponse = F4(
	function (token, url, value, msg) {
		return $elm$http$Http$request(
			A5(
				$author$project$Request$HttpClient$requestParams,
				'POST',
				_List_fromArray(
					[
						A2($elm$http$Http$header, 'X-Authentication', token)
					]),
				url,
				$elm$http$Http$jsonBody(value),
				$elm$http$Http$expectString(msg)));
	});
var $elm$core$Dict$map = F2(
	function (func, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				A2(func, key, value),
				A2($elm$core$Dict$map, func, left),
				A2($elm$core$Dict$map, func, right));
		}
	});
var $author$project$Request$RouteRequest$selectSolutionRejectRoutes = F4(
	function (_v0, solutionId, routeUpdates, msg) {
		var user = _v0.d7;
		var env = _v0.fr;
		var route_ids = A3(
			$elm$core$List$foldr,
			F2(
				function (x, a) {
					return x + (',' + a);
				}),
			'',
			routeUpdates.g3);
		var driverAssignments = $elm$core$Dict$values(
			A2(
				$elm$core$Dict$map,
				F2(
					function (routeId, driverId) {
						return _Utils_Tuple2(routeId, driverId);
					}),
				routeUpdates.fi));
		return A4(
			$author$project$Request$HttpClient$authenticatedPostWithStringResponse,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.bs;
					},
					user)),
			$author$project$Request$Url$apiRailsUrl(env) + ('api/v2/routes/jobs/select/' + $elm$core$String$fromInt(solutionId)),
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'assignments',
						A2(
							$elm$json$Json$Encode$list,
							function (_v1) {
								var routeId = _v1.a;
								var driverId = _v1.b;
								return $elm$json$Json$Encode$object(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'route_id',
											$elm$json$Json$Encode$string(routeId)),
											_Utils_Tuple2(
											'pre_driver_id',
											$elm$json$Json$Encode$string(
												$elm$core$String$fromInt(driverId)))
										]));
							},
							driverAssignments)),
						_Utils_Tuple2(
						'route_ids',
						$elm$json$Json$Encode$string(route_ids))
					])),
			msg);
	});
var $elm$file$File$Download$string = F3(
	function (name, mime, content) {
		return A2(
			$elm$core$Task$perform,
			$elm$core$Basics$never,
			A3(_File_download, name, mime, content));
	});
var $author$project$Common$ReturnExtra$withCmd = F2(
	function (msg, model) {
		return _Utils_Tuple2(model, msg);
	});
var $author$project$Page$AutomaticRouteSolution$Update$update = F3(
	function (context, msg, model) {
		switch (msg.$) {
			case 13:
				var _v1 = model.hh;
				if (!_v1.$) {
					var _v2 = _v1.a;
					var sol = _v2.b;
					var routeUpdates = _v2.c;
					return A2(
						$elm$core$Maybe$withDefault,
						_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
						A2(
							$elm$core$Maybe$map,
							function (id) {
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{f_: false, cz: true}),
									A4($author$project$Request$RouteRequest$selectSolutionRejectRoutes, context, id, routeUpdates, $author$project$Page$AutomaticRouteSolution$Msg$SaveSolutionRequest));
							},
							sol.fS));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 12:
				var val = msg.a;
				return $author$project$Common$ReturnExtra$withNoCmd(
					_Utils_update(
						model,
						{d0: val}));
			case 10:
				var routeData = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bI: $author$project$Page$AutomaticRouteSolution$Model$ChangingRoute(routeData)
						}),
					$elm$core$Platform$Cmd$none);
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bI: $author$project$Page$AutomaticRouteSolution$Model$NoRouteSelected}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var updateRoute = msg.a;
				var selectedDriver = msg.b;
				var _v3 = model.hh;
				if (!_v3.$) {
					var _v4 = _v3.a;
					var id = _v4.a;
					var sol = _v4.b;
					var routeUpdates = _v4.c;
					var driversAssigned = function () {
						var _v5 = _Utils_Tuple2(updateRoute.hW, selectedDriver);
						if (!_v5.a.$) {
							if (!_v5.b.$) {
								var uuid = _v5.a.a;
								var driver = _v5.b.a;
								return A3($elm$core$Dict$insert, uuid, driver.fS, routeUpdates.fi);
							} else {
								var uuid = _v5.a.a;
								var _v6 = _v5.b;
								return A2($elm$core$Dict$remove, uuid, routeUpdates.fi);
							}
						} else {
							var _v7 = _v5.a;
							return routeUpdates.fi;
						}
					}();
					var assignDriver = function (route) {
						return _Utils_eq(route.hW, updateRoute.hW) ? _Utils_update(
							route,
							{fh: selectedDriver}) : route;
					};
					return $author$project$Common$ReturnExtra$withNoCmd(
						_Utils_update(
							model,
							{
								bI: $author$project$Page$AutomaticRouteSolution$Model$NoRouteSelected,
								hh: $elm$core$Maybe$Just(
									_Utils_Tuple3(
										id,
										_Utils_update(
											sol,
											{
												hc: A2($elm$core$List$map, assignDriver, sol.hc)
											}),
										_Utils_update(
											routeUpdates,
											{fi: driversAssigned})))
							}));
				} else {
					return $author$project$Common$ReturnExtra$withNoCmd(model);
				}
			case 14:
				var val = msg.a;
				return $author$project$Common$ReturnExtra$withNoCmd(
					_Utils_update(
						model,
						{b0: val}));
			case 11:
				var uuid = msg.a;
				var newSelectedSolution = function () {
					var _v8 = model.hh;
					if (!_v8.$) {
						var _v9 = _v8.a;
						var id = _v9.a;
						var sol = _v9.b;
						var routeUpdates = _v9.c;
						return $elm$core$Maybe$Just(
							_Utils_Tuple3(
								id,
								sol,
								_Utils_update(
									routeUpdates,
									{
										fi: A2($elm$core$Dict$remove, uuid, routeUpdates.fi),
										g3: A2($elm$core$List$cons, uuid, routeUpdates.g3)
									})));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}();
				return $author$project$Common$ReturnExtra$withNoCmd(
					_Utils_update(
						model,
						{f_: true, hh: newSelectedSolution}));
			case 0:
				var executionId = msg.a;
				var executionCmd = A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$Maybe$map,
						function (id) {
							return A3($author$project$Request$RouteRequest$fetchExecution, context, id, $author$project$Page$AutomaticRouteSolution$Msg$GetExecutionKpiResponse);
						},
						executionId));
				return A2(
					$author$project$Common$ReturnExtra$withCmd,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								executionCmd,
								A2(
								$elm$core$Task$perform,
								$elm$core$Basics$identity,
								$elm$core$Task$succeed(
									$author$project$Page$AutomaticRouteSolution$Msg$ReloadSolutionsOutside(executionId)))
							])),
					_Utils_update(
						model,
						{f0: true, f6: $krisajenkins$remotedata$RemoteData$Loading}));
			case 1:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 2:
				var solution = msg.a;
				return $author$project$Common$ReturnExtra$withNoCmd(
					_Utils_update(
						model,
						{
							hh: $elm$core$Maybe$Just(solution)
						}));
			case 3:
				var _v10 = model.hh;
				if (!_v10.$) {
					var _v11 = _v10.a;
					var sol = _v11.b;
					var routeUpdates = _v11.c;
					return A2(
						$elm$core$Maybe$withDefault,
						_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
						A2(
							$elm$core$Maybe$map,
							function (id) {
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{f_: false, cz: true}),
									A4($author$project$Request$RouteRequest$selectSolutionRejectRoutes, context, id, routeUpdates, $author$project$Page$AutomaticRouteSolution$Msg$SaveSolutionRequest));
							},
							sol.fS));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 4:
				if (!msg.a.$) {
					return A2(
						$author$project$Common$ReturnExtra$withCmd,
						A2(
							$elm$core$Task$perform,
							$elm$core$Basics$identity,
							$elm$core$Task$succeed(
								$author$project$Page$AutomaticRouteSolution$Msg$SolutionSavedOutside(model))),
						_Utils_update(
							model,
							{cz: true}));
				} else {
					return $author$project$Common$ReturnExtra$withNoCmd(
						_Utils_update(
							model,
							{fs: 1, cz: false}));
				}
			case 5:
				var _v12 = model.R;
				var activeSol = _v12.a;
				return A2(
					$author$project$Common$ReturnExtra$withCmd,
					A2(
						$elm$core$Task$perform,
						$elm$core$Basics$identity,
						$elm$core$Task$succeed(
							$author$project$Page$AutomaticRouteSolution$Msg$CancelProcessOutside(activeSol.f3))),
					model);
			case 6:
				return $author$project$Common$ReturnExtra$withNoCmd(model);
			case 7:
				return $author$project$Common$ReturnExtra$withNoCmd(model);
			case 15:
				var executionId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{f_: false, cu: true}),
					A3($author$project$Request$RouteRequest$fetchExecutionKPI, context, executionId, $author$project$Page$AutomaticRouteSolution$Msg$GetKpiResponseJson));
			case 17:
				var response = msg.a;
				return $author$project$Common$ReturnExtra$withNoCmd(
					_Utils_update(
						model,
						{f6: response}));
			case 16:
				if (!msg.a.$) {
					var content = msg.a.a;
					return A2(
						$author$project$Common$ReturnExtra$withCmd,
						A3($elm$file$File$Download$string, 'kpi.json', 'application/json', content),
						_Utils_update(
							model,
							{cu: false}));
				} else {
					return $author$project$Common$ReturnExtra$withNoCmd(
						_Utils_update(
							model,
							{fs: 2, cu: false}));
				}
			default:
				return $author$project$Common$ReturnExtra$withNoCmd(
					_Utils_update(
						model,
						{fs: 0}));
		}
	});
var $author$project$Request$Route$AutomaticRouteSettings = function (orders) {
	return function (packages) {
		return function (vehiclesData) {
			return function (store) {
				return function (unlimitedFleet) {
					return function (deliveryServiceTime) {
						return function (packageVolume) {
							return function (linearDistanceLimit) {
								return function (maxDuration) {
									return function (optimizationType) {
										return function (optimizationImproveType) {
											return {bW: deliveryServiceTime, cF: linearDistanceLimit, cJ: maxDuration, cX: optimizationImproveType, cY: optimizationType, gL: orders, c5: packageVolume, c6: packages, hw: store, d6: unlimitedFleet, d9: vehiclesData};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Page$AutomaticRouteStep$Msg$CreateRouteJobRequest = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$AutomaticRouteStep$Msg$ExecutionStarted = {$: 12};
var $author$project$Page$AutomaticRouteStep$Model$SolutionStep = 3;
var $author$project$Request$Route$automaticRouteUrl = function (env) {
	return $author$project$Request$Url$apiRailsUrl(env) + 'api/v2/routes/optimize_orders';
};
var $author$project$Data$RouteResponse$JobResponse = F2(
	function (jobId, status) {
		return {f3: jobId, ht: status};
	});
var $author$project$Data$RouteResponse$jobResponseDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2($elm$json$Json$Decode$field, 'status', $elm$json$Json$Decode$string),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'job_id', $elm$json$Json$Decode$string),
		$elm$json$Json$Decode$succeed($author$project$Data$RouteResponse$JobResponse)));
var $author$project$Page$AutomaticRouteStep$Model$optimizationProviderToString = F2(
	function (type_, optimizationImproveType) {
		if (type_ === 1) {
			return 'google';
		} else {
			switch (optimizationImproveType) {
				case 0:
					return 'veeroute';
				case 1:
					return 'veeroute';
				default:
					return 'veeroute-v6';
			}
		}
	});
var $author$project$Page$AutomaticRouteStep$Model$QuickQualitative = 1;
var $author$project$Page$AutomaticRouteStep$Model$optimizationTypeToString = F2(
	function (type_, quickOptimize) {
		var addQuickType = F2(
			function (optimizationType, addquickOptimize) {
				return ((addquickOptimize === 1) || (addquickOptimize === 2)) ? (optimizationType + ',optimize_in_a_quick_and_qualitative_way') : optimizationType;
			});
		if (!type_) {
			return A2(addQuickType, 'optimize_cars_then_single_location_grouping', quickOptimize);
		} else {
			return 'default';
		}
	});
var $author$project$Request$Route$configuration = F2(
	function (optimizationType, addQuickVersion) {
		var routingConfig = function (calc_points) {
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'calc_points',
						$elm$json$Json$Encode$bool(calc_points))
					]));
		};
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'routing',
					routingConfig(true)),
					_Utils_Tuple2(
					'optimization_configuration',
					$elm$json$Json$Encode$string(
						A2($author$project$Page$AutomaticRouteStep$Model$optimizationTypeToString, optimizationType, addQuickVersion))),
					_Utils_Tuple2(
					'provider',
					$elm$json$Json$Encode$string(
						A2($author$project$Page$AutomaticRouteStep$Model$optimizationProviderToString, optimizationType, addQuickVersion)))
				]));
	});
var $author$project$Data$Package$encoder = function (_v0) {
	var id = _v0.fS;
	var width = _v0.ef;
	var height = _v0.ci;
	var length = _v0.cE;
	var volume = _v0.bo;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(id)),
				_Utils_Tuple2(
				'width',
				$author$project$Data$Package$maybeFloat(width)),
				_Utils_Tuple2(
				'height',
				$author$project$Data$Package$maybeFloat(height)),
				_Utils_Tuple2(
				'length',
				$author$project$Data$Package$maybeFloat(length)),
				_Utils_Tuple2(
				'volume',
				$author$project$Data$Package$maybeFloat(volume))
			]));
};
var $author$project$Request$Route$getLatLon = function (maybeAddress) {
	return A2(
		$elm$core$Maybe$andThen,
		function ($) {
			return $.ga;
		},
		maybeAddress);
};
var $elm$core$Maybe$map4 = F5(
	function (func, ma, mb, mc, md) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				if (mc.$ === 1) {
					return $elm$core$Maybe$Nothing;
				} else {
					var c = mc.a;
					if (md.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var d = md.a;
						return $elm$core$Maybe$Just(
							A4(func, a, b, c, d));
					}
				}
			}
		}
	});
var $author$project$Request$Route$encodeShipments = F2(
	function (_v0, _v1) {
		var orders = _v1.gL;
		var packages = _v1.c6;
		var store = _v1.hw;
		var deliveryServiceTime = _v1.bW;
		var warehouseId = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.fS;
				},
				store));
		var warehouseAddress = function (order) {
			return A2(
				$elm$core$Maybe$withDefault,
				order.e6,
				A2(
					$elm$core$Maybe$andThen,
					function ($) {
						return $.er;
					},
					store));
		};
		var timeToSeconds = function (time) {
			return ($elm$time$Time$posixToMillis(time) / 1000) | 0;
		};
		var serviceTime_ = function () {
			if (!deliveryServiceTime.$) {
				return $elm$core$Maybe$Just(5);
			} else {
				var time = deliveryServiceTime.a;
				return time;
			}
		}();
		var encodeOrderPackages = function (order) {
			return A2(
				$elm$core$List$filter,
				function (p) {
					return _Utils_eq(p.gI, order.fS);
				},
				packages);
		};
		var finalShipment = F3(
			function (order, pickupData, deliveryData) {
				var packageData = A2(
					$elm$json$Json$Encode$list,
					$author$project$Data$Package$encoder,
					encodeOrderPackages(order));
				return $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'id',
							$elm$json$Json$Encode$string(
								$elm$core$String$fromInt(order.fS))),
							_Utils_Tuple2(
							'name',
							$elm$json$Json$Encode$string(
								$elm$core$String$fromInt(order.fS))),
							_Utils_Tuple2('pickup', pickupData),
							_Utils_Tuple2('delivery', deliveryData),
							_Utils_Tuple2('packages', packageData)
						]));
			});
		var encodeDeliverySlot = function (_v2) {
			var start = _v2.hq;
			var end = _v2.fp;
			var latest = timeToSeconds(end);
			var earliest = timeToSeconds(start);
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'earliest',
						$elm$json$Json$Encode$int(earliest)),
						_Utils_Tuple2(
						'latest',
						$elm$json$Json$Encode$int(latest))
					]));
		};
		var encodeAddress = F4(
			function (locationId, address, deliverySlot, serviceTime) {
				var encodeAdress = F4(
					function (addressId, a, latlon, sv) {
						return $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'address',
									$elm$json$Json$Encode$object(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'location_id',
												$elm$json$Json$Encode$string(
													$elm$core$String$fromInt(addressId))),
												_Utils_Tuple2(
												'name',
												$elm$json$Json$Encode$string(
													$author$project$Common$Address$fullAddress(a))),
												_Utils_Tuple2(
												'lat',
												$elm$json$Json$Encode$float(latlon.f8)),
												_Utils_Tuple2(
												'lon',
												$elm$json$Json$Encode$float(latlon.gb))
											]))),
									_Utils_Tuple2(
									'time_windows',
									A2(
										$elm$json$Json$Encode$list,
										encodeDeliverySlot,
										_List_fromArray(
											[deliverySlot]))),
									_Utils_Tuple2(
									'service_time',
									$elm$json$Json$Encode$int(
										(A2($myrho$elm_round$Round$roundNum, 1, sv) * 60) | 0))
								]));
					});
				return A5(
					$elm$core$Maybe$map4,
					encodeAdress,
					$elm$core$Maybe$Just(locationId),
					address,
					$author$project$Request$Route$getLatLon(address),
					serviceTime);
			});
		var pickupAddress = function (order) {
			var location_id = A2($author$project$Request$Route$getLocationId, order, warehouseId).J;
			var address = (order.gK === 1) ? order.gT : warehouseAddress(order);
			return A4(
				encodeAddress,
				location_id,
				$elm$core$Maybe$Just(address),
				order.e7,
				A2($author$project$Request$Route$getServiceTime, order, serviceTime_).J);
		};
		var deliveryAddress = function (order) {
			var location_id = A2($author$project$Request$Route$getLocationId, order, warehouseId).I;
			var address = (order.gK === 1) ? warehouseAddress(order) : order.e6;
			return A4(
				encodeAddress,
				location_id,
				$elm$core$Maybe$Just(address),
				order.e7,
				A2($author$project$Request$Route$getServiceTime, order, serviceTime_).I);
		};
		var shipmentEncodeValue = function (order) {
			return A4(
				$elm$core$Maybe$map3,
				finalShipment,
				$elm$core$Maybe$Just(order),
				pickupAddress(order),
				deliveryAddress(order));
		};
		return A2(
			$elm$core$List$filterMap,
			shipmentEncodeValue,
			A2(
				$elm_community$list_extra$List$Extra$uniqueBy,
				function ($) {
					return $.fS;
				},
				orders));
	});
var $author$project$Data$Driver$vehicleToString = function (vehicle) {
	switch (vehicle) {
		case 0:
			return 'scooter';
		case 1:
			return 'small_truck';
		case 2:
			return 'bike';
		default:
			return 'truck';
	}
};
var $author$project$Request$Route$encodeVehicle = F6(
	function (vehicleData, selectedStore, zone, now, parentIndex, id) {
		var vehicleId = _Utils_ap(
			$author$project$Data$Driver$vehicleToString(vehicleData.hX),
			_Utils_ap(
				$elm$core$String$fromInt(parentIndex),
				$elm$core$String$fromInt(id)));
		var typeId = _Utils_ap(
			$author$project$Data$Driver$vehicleToString(vehicleData.hX),
			$elm$core$String$fromInt(parentIndex));
		var storeAddress = F2(
			function (address, latlon) {
				return $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'location_id',
							$elm$json$Json$Encode$string(
								$elm$core$String$fromInt(address.fS))),
							_Utils_Tuple2(
							'lat',
							$elm$json$Json$Encode$float(latlon.f8)),
							_Utils_Tuple2(
							'lon',
							$elm$json$Json$Encode$float(latlon.gb))
						]));
			});
		var startTime = function () {
			var parts = A2($justinmimbs$time_extra$Time$Extra$posixToParts, zone, now);
			var time = $elm$time$Time$posixToMillis(
				A2(
					$justinmimbs$time_extra$Time$Extra$partsToPosix,
					zone,
					_Utils_update(
						parts,
						{fQ: vehicleData.dN, a4: vehicleData.dO, hg: 0})));
			return (time / 1000) | 0;
		}();
		var pickupAddress = function () {
			var address = A2(
				$elm$core$Maybe$andThen,
				function ($) {
					return $.er;
				},
				selectedStore);
			return A3(
				$elm$core$Maybe$map2,
				storeAddress,
				address,
				$author$project$Request$Route$getLatLon(address));
		}();
		var encodeMaybeInt = F2(
			function (key, maybeInt) {
				if (maybeInt.$ === 1) {
					return _List_Nil;
				} else {
					var _int = maybeInt.a;
					return _List_fromArray(
						[
							_Utils_Tuple2(
							key,
							$elm$json$Json$Encode$int(_int))
						]);
				}
			});
		var vehicleEncoded = F2(
			function (_v0, startAddress) {
				return $elm$json$Json$Encode$object(
					_Utils_ap(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'vehicle_id',
								$elm$json$Json$Encode$string(vehicleId)),
								_Utils_Tuple2('start_address', startAddress),
								_Utils_Tuple2(
								'return_depot',
								$elm$json$Json$Encode$bool(false)),
								_Utils_Tuple2(
								'type_id',
								$elm$json$Json$Encode$string(typeId)),
								_Utils_Tuple2(
								'start_time',
								$elm$json$Json$Encode$int(startTime)),
								_Utils_Tuple2(
								'max_working_hours',
								$elm$json$Json$Encode$int(vehicleData.eg)),
								_Utils_Tuple2(
								'max_orders',
								$elm$json$Json$Encode$int(vehicleData.cH)),
								_Utils_Tuple2(
								'max_locations',
								$elm$json$Json$Encode$int(vehicleData.cK)),
								_Utils_Tuple2(
								'max_distance',
								$elm$json$Json$Encode$int(vehicleData.cI))
							]),
						_Utils_ap(
							A2(encodeMaybeInt, 'max_number_items', vehicleData.cL),
							_Utils_ap(
								A2(encodeMaybeInt, 'min_number_items', vehicleData.cO),
								A2(encodeMaybeInt, 'min_orders', vehicleData.cN)))));
			});
		return A3(
			$elm$core$Maybe$map2,
			vehicleEncoded,
			$elm$core$Maybe$Just(vehicleData.hX),
			pickupAddress);
	});
var $author$project$Data$Driver$vehicleRouteEncoder = function (vehicle) {
	return $elm$json$Json$Encode$string(
		$author$project$Data$Driver$vehicleToString(vehicle));
};
var $author$project$Request$Route$encodeVehicleDataWithIndex = function (_v0) {
	var index = _v0.a;
	var vehicleData = _v0.b;
	var typeId = _Utils_ap(
		$author$project$Data$Driver$vehicleToString(vehicleData.hX),
		$elm$core$String$fromInt(index));
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'type_id',
				$elm$json$Json$Encode$string(typeId)),
				_Utils_Tuple2(
				'profile',
				$author$project$Data$Driver$vehicleRouteEncoder(vehicleData.hX)),
				_Utils_Tuple2(
				'capacity',
				A2(
					$elm$json$Json$Encode$list,
					$elm$json$Json$Encode$float,
					_List_fromArray(
						[vehicleData.bE])))
			]));
};
var $elm_community$list_extra$List$Extra$groupWhile = F2(
	function (isSameGroup, items) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					if (!acc.b) {
						return _List_fromArray(
							[
								_Utils_Tuple2(x, _List_Nil)
							]);
					} else {
						var _v1 = acc.a;
						var y = _v1.a;
						var restOfGroup = _v1.b;
						var groups = acc.b;
						return A2(isSameGroup, x, y) ? A2(
							$elm$core$List$cons,
							_Utils_Tuple2(
								x,
								A2($elm$core$List$cons, y, restOfGroup)),
							groups) : A2(
							$elm$core$List$cons,
							_Utils_Tuple2(x, _List_Nil),
							acc);
					}
				}),
			_List_Nil,
			items);
	});
var $elm_community$list_extra$List$Extra$initialize = F2(
	function (n, f) {
		var step = F2(
			function (i, acc) {
				step:
				while (true) {
					if (i < 0) {
						return acc;
					} else {
						var $temp$i = i - 1,
							$temp$acc = A2(
							$elm$core$List$cons,
							f(i),
							acc);
						i = $temp$i;
						acc = $temp$acc;
						continue step;
					}
				}
			});
		return A2(step, n - 1, _List_Nil);
	});
var $author$project$Request$Route$routeRequestEncoder = F5(
	function (token, zone, now, settings, maybeArea) {
		var shipments = A2($author$project$Request$Route$encodeShipments, zone, settings);
		var serviceTimeJson = function (value) {
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'service_time',
						$elm$json$Json$Encode$bool(value))
					]));
		};
		var areaData = function () {
			if (!maybeArea.$) {
				var _v7 = maybeArea.a;
				var id = _v7.a;
				var name = _v7.b;
				return _List_fromArray(
					[
						_Utils_Tuple2(
						'area_id',
						$elm$json$Json$Encode$string(id)),
						_Utils_Tuple2(
						'area_name',
						$elm$json$Json$Encode$string(name))
					]);
			} else {
				return _List_Nil;
			}
		}();
		var _v0 = settings;
		var vehiclesData = _v0.d9;
		var store = _v0.hw;
		var unlimitedFleet = _v0.d6;
		var packageVolume = _v0.c5;
		var maxDuration = _v0.cJ;
		var optimizationType = _v0.cY;
		var optimizationImproveType = _v0.cX;
		var linearDistanceLimit = _v0.cF;
		var deliveryServiceTime = _v0.bW;
		var preRoutingValue = function () {
			if (!deliveryServiceTime.$) {
				return serviceTimeJson(true);
			} else {
				return serviceTimeJson(false);
			}
		}();
		var tupledVehicleData = A2(
			$elm$core$List$concatMap,
			$elm$core$List$indexedMap($elm$core$Tuple$pair),
			A2(
				$elm$core$List$map,
				function (_v4) {
					var head = _v4.a;
					var vd = _v4.b;
					return A2($elm$core$List$cons, head, vd);
				},
				A2(
					$elm_community$list_extra$List$Extra$groupWhile,
					F2(
						function (v1, v2) {
							return _Utils_eq(v1.hX, v2.hX);
						}),
					A2(
						$elm$core$List$sortBy,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.hX;
							},
							$author$project$Data$Driver$vehicleToString),
						vehiclesData))));
		var vehicles = A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			A2(
				$elm$core$List$concatMap,
				function (_v3) {
					var parentIndex = _v3.a;
					var vd = _v3.b;
					return A2(
						$elm_community$list_extra$List$Extra$initialize,
						vd.df,
						A5($author$project$Request$Route$encodeVehicle, vd, store, zone, now, parentIndex));
				},
				tupledVehicleData));
		return $elm$json$Json$Encode$object(
			function (list) {
				if (!linearDistanceLimit.$) {
					var linearDistance = linearDistanceLimit.a;
					return A2(
						$elm$core$List$cons,
						_Utils_Tuple2(
							'max_node_distance',
							$elm$json$Json$Encode$int(linearDistance)),
						list);
				} else {
					return list;
				}
			}(
				function (list) {
					if (!packageVolume.$) {
						var pv = packageVolume.a;
						return A2(
							$elm$core$List$cons,
							_Utils_Tuple2(
								'default_package_volume',
								$elm$json$Json$Encode$float(pv)),
							list);
					} else {
						return list;
					}
				}(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(
							'configuration',
							A2($author$project$Request$Route$configuration, optimizationType, optimizationImproveType)),
						A2(
							$elm$core$List$cons,
							_Utils_Tuple2(
								'shipments',
								A2($elm$json$Json$Encode$list, $elm$core$Basics$identity, shipments)),
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2('pre_routing', preRoutingValue),
								A2(
									$elm$core$List$cons,
									_Utils_Tuple2(
										'vehicles',
										A2($elm$json$Json$Encode$list, $elm$core$Basics$identity, vehicles)),
									A2(
										$elm$core$List$cons,
										_Utils_Tuple2(
											'unlimited_fleet',
											$elm$json$Json$Encode$bool(unlimitedFleet)),
										A2(
											$elm$core$List$cons,
											_Utils_Tuple2(
												'token',
												$elm$json$Json$Encode$string(token)),
											A2(
												$elm$core$List$cons,
												_Utils_Tuple2(
													'vehicle_types',
													A2($elm$json$Json$Encode$list, $author$project$Request$Route$encodeVehicleDataWithIndex, tupledVehicleData)),
												A2(
													$elm$core$List$cons,
													_Utils_Tuple2(
														'distance_matrix_algo',
														$elm$json$Json$Encode$string('graphhopper')),
													A2(
														$elm$core$List$cons,
														_Utils_Tuple2(
															'timeout',
															$elm$json$Json$Encode$int(
																60 * A2($elm$core$Maybe$withDefault, 0, maxDuration))),
														areaData))))))))))));
	});
var $author$project$Request$Route$automaticRouteRequest = F4(
	function (_v0, settings, maybeArea, msg) {
		var user = _v0.d7;
		var zone = _v0.em;
		var env = _v0.fr;
		var time = _v0.d_;
		var token = A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.bs;
				},
				user));
		return $elm$http$Http$request(
			{
				eC: $elm$http$Http$jsonBody(
					A5($author$project$Request$Route$routeRequestEncoder, token, zone, time, settings, maybeArea)),
				b6: A2($author$project$Common$ExpectJsonWithError$expectJsonWithError, msg, $author$project$Data$RouteResponse$jobResponseDecoder),
				ch: _List_fromArray(
					[
						A2($elm$http$Http$header, 'X-Authentication', token)
					]),
				cM: 'POST',
				d$: $elm$core$Maybe$Nothing,
				d2: $elm$core$Maybe$Nothing,
				hV: $author$project$Request$Route$automaticRouteUrl(env)
			});
	});
var $author$project$Page$AutomaticRouteStep$Model$VehicleData = function (vehicle) {
	return function (quantity) {
		return function (capacity) {
			return function (startTimeHour) {
				return function (startTimeMinute) {
					return function (workingHours) {
						return function (maxDeliveries) {
							return function (minDeliveries) {
								return function (maxLocations) {
									return function (maxDistance) {
										return function (maxRouteItems) {
											return function (minRouteItems) {
												return {bE: capacity, cH: maxDeliveries, cI: maxDistance, cK: maxLocations, cL: maxRouteItems, cN: minDeliveries, cO: minRouteItems, df: quantity, dN: startTimeHour, dO: startTimeMinute, hX: vehicle, eg: workingHours};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Page$AutomaticRouteStep$Model$formToVehicleData = function (form) {
	var workingHours = $elm$core$String$toInt(form.eg);
	var vehicle = $author$project$Common$Vehicle$stringToVehicle(form.hX);
	var startTimeMinute = $elm$core$String$toInt(form.dO);
	var startTimeHour = $elm$core$String$toInt(form.dN);
	var quantity = $elm$core$String$toInt(form.df);
	var minRouteItems = $elm$core$String$toInt(form.cO);
	var minDeliveries = $elm$core$String$toInt(form.cN);
	var maxRouteItems = $elm$core$String$toInt(form.cL);
	var maxLocations = $elm$core$String$toInt(form.cK);
	var maxDistance = A2(
		$elm$core$Maybe$map,
		function (value) {
			return value * 1000;
		},
		$elm$core$String$toInt(form.cI));
	var maxDeliveries = $elm$core$String$toInt(form.cH);
	var capacity = $elm$core$String$toFloat(form.bE);
	var _v0 = _Utils_Tuple3(vehicle, quantity, capacity);
	if (((!_v0.a.$) && (!_v0.b.$)) && (!_v0.c.$)) {
		var vehicle_ = _v0.a.a;
		var quantity_ = _v0.b.a;
		var capacity_ = _v0.c.a;
		var _v1 = _Utils_Tuple3(startTimeHour, startTimeMinute, workingHours);
		if (((!_v1.a.$) && (!_v1.b.$)) && (!_v1.c.$)) {
			var startTimeHour_ = _v1.a.a;
			var startTimeMinute_ = _v1.b.a;
			var workingHours_ = _v1.c.a;
			var _v2 = _Utils_Tuple3(maxDeliveries, maxLocations, maxDistance);
			if (((!_v2.a.$) && (!_v2.b.$)) && (!_v2.c.$)) {
				var maxDeliveries_ = _v2.a.a;
				var maxLocations_ = _v2.b.a;
				var maxDistance_ = _v2.c.a;
				return $elm$core$Maybe$Just(
					$author$project$Page$AutomaticRouteStep$Model$VehicleData(vehicle_)(quantity_)(capacity_)(startTimeHour_)(startTimeMinute_)(workingHours_)(maxDeliveries_)(minDeliveries)(maxLocations_)(maxDistance_)(maxRouteItems)(minRouteItems));
			} else {
				return $elm$core$Maybe$Nothing;
			}
		} else {
			return $elm$core$Maybe$Nothing;
		}
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Page$AutomaticRouteStep$VehicleDataForm$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var string = msg.a;
				return _Utils_update(
					model,
					{hX: string});
			case 1:
				var string = msg.a;
				return _Utils_update(
					model,
					{df: string});
			case 2:
				var string = msg.a;
				return _Utils_update(
					model,
					{dN: string});
			case 3:
				var string = msg.a;
				return _Utils_update(
					model,
					{dO: string});
			case 4:
				var string = msg.a;
				return _Utils_update(
					model,
					{eg: string});
			case 5:
				var string = msg.a;
				return _Utils_update(
					model,
					{cH: string});
			case 6:
				var string = msg.a;
				return _Utils_update(
					model,
					{cN: string});
			case 7:
				var string = msg.a;
				return _Utils_update(
					model,
					{cK: string});
			case 8:
				var string = msg.a;
				return _Utils_update(
					model,
					{cI: string});
			case 9:
				var string = msg.a;
				return _Utils_update(
					model,
					{cL: string});
			default:
				var string = msg.a;
				return _Utils_update(
					model,
					{cO: string});
		}
	});
var $rtfeldman$elm_validate$Validate$Valid = $elm$core$Basics$identity;
var $rtfeldman$elm_validate$Validate$validate = F2(
	function (_v0, subject) {
		var getErrors = _v0;
		var _v1 = getErrors(subject);
		if (!_v1.b) {
			return $elm$core$Result$Ok(subject);
		} else {
			var errors = _v1;
			return $elm$core$Result$Err(errors);
		}
	});
var $rtfeldman$elm_validate$Validate$Validator = $elm$core$Basics$identity;
var $rtfeldman$elm_validate$Validate$all = function (validators) {
	var newGetErrors = function (subject) {
		var accumulateErrors = F2(
			function (_v0, totalErrors) {
				var getErrors = _v0;
				return _Utils_ap(
					totalErrors,
					getErrors(subject));
			});
		return A3($elm$core$List$foldl, accumulateErrors, _List_Nil, validators);
	};
	return newGetErrors;
};
var $rtfeldman$elm_validate$Validate$ifTrue = F2(
	function (test, error) {
		var getErrors = function (subject) {
			return test(subject) ? _List_fromArray(
				[error]) : _List_Nil;
		};
		return getErrors;
	});
var $rtfeldman$elm_validate$Validate$isWhitespaceChar = function (_char) {
	return (_char === ' ') || ((_char === '\n') || ((_char === '\t') || (_char === '\u000D')));
};
var $rtfeldman$elm_validate$Validate$isBlank = function (str) {
	isBlank:
	while (true) {
		var _v0 = $elm$core$String$uncons(str);
		if (!_v0.$) {
			var _v1 = _v0.a;
			var _char = _v1.a;
			var rest = _v1.b;
			if ($rtfeldman$elm_validate$Validate$isWhitespaceChar(_char)) {
				var $temp$str = rest;
				str = $temp$str;
				continue isBlank;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}
};
var $rtfeldman$elm_validate$Validate$ifBlank = F2(
	function (subjectToString, error) {
		return A2(
			$rtfeldman$elm_validate$Validate$ifTrue,
			function (subject) {
				return $rtfeldman$elm_validate$Validate$isBlank(
					subjectToString(subject));
			},
			error);
	});
var $rtfeldman$elm_validate$Validate$ifFalse = F2(
	function (test, error) {
		var getErrors = function (subject) {
			return test(subject) ? _List_Nil : _List_fromArray(
				[error]);
		};
		return getErrors;
	});
var $rtfeldman$elm_validate$Validate$isInt = function (str) {
	var _v0 = $elm$core$String$toInt(str);
	if (_v0.$ === 1) {
		return false;
	} else {
		return true;
	}
};
var $rtfeldman$elm_validate$Validate$ifNotInt = F2(
	function (subjectToString, errorFromString) {
		var getErrors = function (subject) {
			var str = subjectToString(subject);
			return $rtfeldman$elm_validate$Validate$isInt(str) ? _List_Nil : _List_fromArray(
				[
					errorFromString(str)
				]);
		};
		return getErrors;
	});
var $author$project$Common$Dates$validateWorkHours = F3(
	function (hours, minutes, workingHours) {
		return _Utils_cmp(((hours + workingHours) * 60) + minutes, 60 * 24) < 1;
	});
var $author$project$Common$Dates$isValidWorkingHours = F3(
	function (startTimeHour, startTimeMinute, workingHours) {
		var workingHoursInt = $elm$core$String$toInt(workingHours);
		var startMinute = $elm$core$String$toInt(startTimeMinute);
		var startHour = $elm$core$String$toInt(startTimeHour);
		var _v0 = _Utils_Tuple3(startHour, startMinute, workingHoursInt);
		if (((!_v0.a.$) && (!_v0.b.$)) && (!_v0.c.$)) {
			var sh = _v0.a.a;
			var sm = _v0.b.a;
			var wh = _v0.c.a;
			return A3($author$project$Common$Dates$validateWorkHours, sh, sm, wh);
		} else {
			return false;
		}
	});
var $author$project$Page$AutomaticRouteStep$VehicleDataForm$validator = function () {
	var validWorkingHours = function (label) {
		return A2(
			$rtfeldman$elm_validate$Validate$ifFalse,
			function (form) {
				return A3($author$project$Common$Dates$isValidWorkingHours, form.dN, form.dO, form.eg);
			},
			label + ' Remember that Start Working Time plus Working Hours should not exceed current day (00:00)');
	};
	var shouldBeLess = F3(
		function (fn1, fn2, error) {
			return A2(
				$rtfeldman$elm_validate$Validate$ifTrue,
				function (form) {
					return A2(
						$elm$core$Maybe$withDefault,
						false,
						A3(
							$elm$core$Maybe$map2,
							F2(
								function (expectedToBeLess, expectedToBeGreater) {
									return _Utils_cmp(expectedToBeLess, expectedToBeGreater) > -1;
								}),
							$elm$core$String$toInt(
								fn1(form)),
							$elm$core$String$toInt(
								fn2(form))));
				},
				error);
		});
	var ifNotInt = F2(
		function (fn, label) {
			return A2(
				$rtfeldman$elm_validate$Validate$ifNotInt,
				fn,
				function (_v1) {
					return label + ' should be a number';
				});
		});
	var ifIntMoreThan = F3(
		function (fn, number, label) {
			return A2(
				$rtfeldman$elm_validate$Validate$ifFalse,
				function (form) {
					return A2(
						$elm$core$Maybe$withDefault,
						false,
						A2(
							$elm$core$Maybe$map,
							function (v) {
								return _Utils_cmp(v, number) > -1;
							},
							$elm$core$String$toInt(
								fn(form))));
				},
				label + (' must be more than or equal to ' + $elm$core$String$fromInt(number)));
		});
	var ifIntLessThan = F3(
		function (fn, number, label) {
			return A2(
				$rtfeldman$elm_validate$Validate$ifFalse,
				function (form) {
					return A2(
						$elm$core$Maybe$withDefault,
						false,
						A2(
							$elm$core$Maybe$map,
							function (v) {
								return _Utils_cmp(v, number) < 1;
							},
							$elm$core$String$toInt(
								fn(form))));
				},
				label + (' must be less than ' + $elm$core$String$fromInt(number)));
		});
	return $rtfeldman$elm_validate$Validate$all(
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_validate$Validate$ifBlank,
				function ($) {
					return $.hX;
				},
				'Please enter a vehicle type'),
				A2(
				$rtfeldman$elm_validate$Validate$ifFalse,
				function (_v0) {
					var vehicle = _v0.hX;
					return !_Utils_eq(
						$author$project$Common$Vehicle$stringToVehicle(vehicle),
						$elm$core$Maybe$Nothing);
				},
				'Invalid vehicle type'),
				A2(
				$rtfeldman$elm_validate$Validate$ifBlank,
				function ($) {
					return $.df;
				},
				'Please enter a quantity'),
				A3(
				ifIntMoreThan,
				function ($) {
					return $.df;
				},
				1,
				'Quantity'),
				A2(
				ifNotInt,
				function ($) {
					return $.df;
				},
				'Quantity'),
				A2(
				$rtfeldman$elm_validate$Validate$ifBlank,
				function ($) {
					return $.bE;
				},
				'Please enter a capacity'),
				A2(
				ifNotInt,
				function ($) {
					return $.bE;
				},
				'Capacity'),
				A2(
				$rtfeldman$elm_validate$Validate$ifBlank,
				function ($) {
					return $.eg;
				},
				'Please enter a working hours'),
				A2(
				ifNotInt,
				function ($) {
					return $.eg;
				},
				'Working hours'),
				validWorkingHours('Please enter valid working hours'),
				A2(
				$rtfeldman$elm_validate$Validate$ifBlank,
				function ($) {
					return $.dN;
				},
				'Please enter a start time hour'),
				A2(
				ifNotInt,
				function ($) {
					return $.dN;
				},
				'Start time hour'),
				A3(
				ifIntLessThan,
				function ($) {
					return $.dN;
				},
				24,
				'Start working time'),
				A3(
				ifIntMoreThan,
				function ($) {
					return $.dN;
				},
				7,
				'Start working time'),
				A2(
				$rtfeldman$elm_validate$Validate$ifBlank,
				function ($) {
					return $.dO;
				},
				'Please enter a start time minute'),
				A2(
				ifNotInt,
				function ($) {
					return $.dO;
				},
				'Start time minute'),
				A3(
				ifIntLessThan,
				function ($) {
					return $.dO;
				},
				60,
				'Start time minute'),
				A3(
				ifIntLessThan,
				function ($) {
					return $.bE;
				},
				2500,
				'Max volume'),
				A3(
				ifIntLessThan,
				function ($) {
					return $.cH;
				},
				200,
				'Max Deliveries is limited to 200'),
				A2(
				ifNotInt,
				function ($) {
					return $.cH;
				},
				'Max Deliveries is not valid'),
				A3(
				shouldBeLess,
				function ($) {
					return $.cN;
				},
				function ($) {
					return $.cH;
				},
				'Minimum orders should be less than maximum orders'),
				A3(
				shouldBeLess,
				function ($) {
					return $.cO;
				},
				function ($) {
					return $.cL;
				},
				'Maximum route items should be more than minimum route items'),
				A2(
				$rtfeldman$elm_validate$Validate$ifBlank,
				function ($) {
					return $.cH;
				},
				'Max Deliveries is not valid'),
				A3(
				ifIntLessThan,
				function ($) {
					return $.cK;
				},
				200,
				'Max Locations is limited to 200'),
				A2(
				ifNotInt,
				function ($) {
					return $.cK;
				},
				'Max Locations is not valid'),
				A2(
				$rtfeldman$elm_validate$Validate$ifBlank,
				function ($) {
					return $.cK;
				},
				'Max Locations is not valid'),
				A2(
				$rtfeldman$elm_validate$Validate$ifBlank,
				function ($) {
					return $.cI;
				},
				'Please enter a Max Distance value'),
				A2(
				ifNotInt,
				function ($) {
					return $.cI;
				},
				'Max Distance is not valid')
			]));
}();
var $author$project$Page$AutomaticRouteStep$VehicleDataForm$validate = function (vehicleDataForm) {
	var _v0 = A2($rtfeldman$elm_validate$Validate$validate, $author$project$Page$AutomaticRouteStep$VehicleDataForm$validator, vehicleDataForm);
	if (!_v0.$) {
		return _Utils_update(
			vehicleDataForm,
			{ft: _List_Nil});
	} else {
		var errors = _v0.a;
		return _Utils_update(
			vehicleDataForm,
			{ft: errors});
	}
};
var $author$project$Page$AutomaticRouteStep$Update$update = F3(
	function (context, msg, model) {
		switch (msg.$) {
			case 0:
				var step = msg.a;
				return $author$project$Common$ReturnExtra$withNoCmd(
					_Utils_update(
						model,
						{dR: step}));
			case 1:
				var val = msg.a;
				return $author$project$Common$ReturnExtra$withNoCmd(
					_Utils_update(
						model,
						{dU: val}));
			case 2:
				var checked = msg.a;
				return $author$project$Common$ReturnExtra$withNoCmd(
					_Utils_update(
						model,
						{d6: checked}));
			case 12:
				return $author$project$Common$ReturnExtra$withNoCmd(model);
			case 11:
				if (!msg.a.$) {
					var jobId = msg.a.a.f3;
					return A2(
						$author$project$Common$ReturnExtra$withCmd,
						A2(
							$elm$core$Task$perform,
							$elm$core$Basics$identity,
							$elm$core$Task$succeed($author$project$Page$AutomaticRouteStep$Msg$ExecutionStarted)),
						_Utils_update(
							model,
							{
								fK: false,
								f3: $elm$core$Maybe$Just(jobId),
								dt: $elm$core$Maybe$Nothing,
								dR: 3
							}));
				} else {
					var error = msg.a.a;
					var setError = function (errorData) {
						return $author$project$Common$ReturnExtra$withNoCmd(
							_Utils_update(
								model,
								{
									fK: false,
									dt: $elm$core$Maybe$Just(errorData)
								}));
					};
					switch (error.$) {
						case 2:
							var errorData = error.a;
							return setError(errorData);
						case 0:
							var errorData = error.a;
							return setError(errorData);
						case 1:
							var errorData = error.a;
							return setError(errorData);
						case 3:
							var errorData = error.a;
							return setError(errorData);
						case 4:
							var errorData = error.a;
							return setError(errorData);
						default:
							var errorData = error.a;
							return setError(errorData);
					}
				}
			case 3:
				var store = msg.a;
				var newSelectedStore = function () {
					var _v2 = model.dG;
					if (!_v2.$) {
						var s = _v2.a;
						return _Utils_eq(s.fS, store.fS) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(store);
					} else {
						return $elm$core$Maybe$Just(store);
					}
				}();
				return $author$project$Common$ReturnExtra$withNoCmd(
					_Utils_update(
						model,
						{dG: newSelectedStore}));
			case 4:
				var maybeArea = function () {
					var _v4 = _Utils_Tuple2(model.ev, model.gs);
					if ((!_v4.a.$) && (!_v4.b.$)) {
						var id = _v4.a.a;
						var name = _v4.b.a;
						return $elm$core$Maybe$Just(
							_Utils_Tuple2(id, name));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}();
				var _v3 = model;
				var orders = _v3.gL;
				var selectedVehicles = _v3.dH;
				var selectedStore = _v3.dG;
				var unlimitedFleet = _v3.d6;
				var maxDuration = _v3.cJ;
				var optimizationType = _v3.cY;
				var optimizationImproveType = _v3.cX;
				var deliveryServiceTime = _v3.bW;
				var requestSettings = $author$project$Request$Route$AutomaticRouteSettings(orders)(_List_Nil)(selectedVehicles)(selectedStore)(unlimitedFleet)(deliveryServiceTime)(
					$elm$core$Maybe$Just(17.0))(
					$elm$core$Maybe$Just(250))(maxDuration)(optimizationType)(optimizationImproveType);
				var cmd = A4($author$project$Request$Route$automaticRouteRequest, context, requestSettings, maybeArea, $author$project$Page$AutomaticRouteStep$Msg$CreateRouteJobRequest);
				return A2(
					$author$project$Common$ReturnExtra$withCmd,
					cmd,
					_Utils_update(
						model,
						{fK: true}));
			case 5:
				var newForm = $author$project$Page$AutomaticRouteStep$VehicleDataForm$validate(model.d8);
				if ($elm$core$List$length(newForm.ft) > 0) {
					return $author$project$Common$ReturnExtra$withNoCmd(
						_Utils_update(
							model,
							{d8: newForm}));
				} else {
					var _v5 = $author$project$Page$AutomaticRouteStep$Model$formToVehicleData(model.d8);
					if (!_v5.$) {
						var vehicleData = _v5.a;
						return $author$project$Common$ReturnExtra$withNoCmd(
							_Utils_update(
								model,
								{
									dH: A2($elm$core$List$cons, vehicleData, model.dH),
									d8: $author$project$Page$AutomaticRouteStep$VehicleDataForm$initialForm(context)
								}));
					} else {
						return $author$project$Common$ReturnExtra$withNoCmd(model);
					}
				}
			case 6:
				var index = msg.a;
				var newVehicleData = A2(
					$elm$core$List$map,
					$elm$core$Tuple$second,
					A2(
						$elm$core$List$filter,
						function (_v6) {
							var i = _v6.a;
							return !_Utils_eq(i, index);
						},
						A2($elm$core$List$indexedMap, $elm$core$Tuple$pair, model.dH)));
				return $author$project$Common$ReturnExtra$withNoCmd(
					_Utils_update(
						model,
						{dH: newVehicleData}));
			case 7:
				var formMsg = msg.a;
				return $author$project$Common$ReturnExtra$withNoCmd(
					_Utils_update(
						model,
						{
							d8: A2($author$project$Page$AutomaticRouteStep$VehicleDataForm$update, formMsg, model.d8)
						}));
			case 8:
				var value = msg.a;
				return $author$project$Common$ReturnExtra$withNoCmd(
					_Utils_update(
						model,
						{
							cJ: $elm$core$String$toInt(value)
						}));
			case 9:
				var type_ = msg.a;
				return $author$project$Common$ReturnExtra$withNoCmd(
					_Utils_update(
						model,
						{cY: type_}));
			case 10:
				var quickOptimize = msg.a;
				return $author$project$Common$ReturnExtra$withNoCmd(
					_Utils_update(
						model,
						{cX: quickOptimize}));
			case 13:
				return $author$project$Common$ReturnExtra$withNoCmd(
					_Utils_update(
						model,
						{dt: $elm$core$Maybe$Nothing}));
			case 15:
				var serviceTime = msg.a;
				return $author$project$Common$ReturnExtra$withNoCmd(
					_Utils_update(
						model,
						{bW: serviceTime, bX: $elm$core$Maybe$Nothing}));
			default:
				var value = msg.a;
				var serviceTime = $elm$core$String$toFloat(value);
				if (!serviceTime.$) {
					var time = serviceTime.a;
					return ((time < 2) || (time > 10)) ? $author$project$Common$ReturnExtra$withNoCmd(
						_Utils_update(
							model,
							{
								bW: $author$project$Page$AutomaticRouteStep$Model$Manual(serviceTime),
								bX: $elm$core$Maybe$Just('Service time must be between 2 and 10 minutes')
							})) : $author$project$Common$ReturnExtra$withNoCmd(
						_Utils_update(
							model,
							{
								bW: $author$project$Page$AutomaticRouteStep$Model$Manual(serviceTime),
								bX: $elm$core$Maybe$Nothing
							}));
				} else {
					return $author$project$Common$ReturnExtra$withNoCmd(
						_Utils_update(
							model,
							{
								bW: $author$project$Page$AutomaticRouteStep$Model$Manual(serviceTime),
								bX: $elm$core$Maybe$Just('Service time must be a number and cannot be left blank')
							}));
				}
		}
	});
var $author$project$Common$ExecutionKpi$updateExecutionKpiValue = F3(
	function (kpis, jobId, execution) {
		var _v0 = execution;
		var index = _v0.a;
		var stepModel = _v0.b;
		var solutionModel = _v0.c;
		var _v1 = stepModel.f3;
		if (!_v1.$) {
			var id = _v1.a;
			return _Utils_eq(id, jobId) ? _Utils_Tuple3(
				index,
				stepModel,
				_Utils_update(
					solutionModel,
					{f6: kpis})) : execution;
		} else {
			return execution;
		}
	});
var $author$project$Page$AutomaticRoute$Update$update = F4(
	function (context, msg, model, filteredOrdersWithAddresses) {
		switch (msg.$) {
			case 18:
				if (!msg.a.$) {
					var routePositions = msg.a.a;
					var getPosition = function (id) {
						return A2(
							$elm$core$Maybe$map,
							function (rp) {
								return rp.gW + 1;
							},
							$elm$core$List$head(
								A2(
									$elm$core$List$filter,
									function (rp) {
										return _Utils_eq(rp.fS, id);
									},
									routePositions)));
					};
					var jobPosition = function (jobId) {
						return A2($elm$core$Maybe$andThen, getPosition, jobId);
					};
					var updateExecution = function (_v1) {
						var index = _v1.a;
						var exec = _v1.b;
						var sol = _v1.c;
						return _Utils_Tuple3(
							index,
							exec,
							_Utils_update(
								sol,
								{
									gW: jobPosition(exec.f3)
								}));
					};
					var newExecutions = A2($elm$core$List$map, updateExecution, model.b5);
					var newSelectedExecution = A2($elm$core$Maybe$map, updateExecution, model.dF);
					return $author$project$Common$ReturnExtra$withNoCmd(
						_Utils_update(
							model,
							{b5: newExecutions, dF: newSelectedExecution}));
				} else {
					return $author$project$Common$ReturnExtra$withNoCmd(model);
				}
			case 22:
				if (!msg.c.$) {
					var areaId = msg.a;
					var areaName = msg.b;
					var orders = msg.c.a;
					var _v2 = A4($author$project$Page$AutomaticRoute$Update$update, context, $author$project$Page$AutomaticRoute$Msg$CloseSelectedExecution, model, filteredOrdersWithAddresses);
					var newModel = _v2.a;
					var execution = $author$project$Page$AutomaticRouteStep$Model$initialModel(context)(orders)(newModel.dV)(_List_Nil)($elm$core$Maybe$Nothing)('')($elm$core$Maybe$Nothing)(areaName)(areaId)(
						$elm$core$Maybe$Just(
							$author$project$Common$Locations$uniqueLocationsCount(orders)));
					var index = function () {
						var max = $elm$core$List$maximum(
							A2(
								$elm$core$List$map,
								function (_v4) {
									var id = _v4.a;
									return id;
								},
								newModel.b5));
						if (max.$ === 1) {
							return 0;
						} else {
							var id = max.a;
							return id + 1;
						}
					}();
					var solution = $author$project$Page$AutomaticRoute$Model$initialSolution(newModel.cG);
					var newExecutions = A2(
						$elm$core$List$cons,
						_Utils_Tuple3(index, execution, solution),
						newModel.b5);
					return $author$project$Common$ReturnExtra$withNoCmd(
						_Utils_update(
							newModel,
							{
								b5: newExecutions,
								dF: $elm$core$Maybe$Just(
									_Utils_Tuple3(
										index,
										execution,
										_Utils_update(
											solution,
											{b_: model.b_})))
							}));
				} else {
					return $author$project$Common$ReturnExtra$withNoCmd(model);
				}
			case 4:
				return $author$project$Common$ReturnExtra$withNoCmd(
					A9($author$project$Page$AutomaticRoute$Model$initialModel, context, filteredOrdersWithAddresses, model.dV, _List_Nil, _List_Nil, model.cG, model.bx, false, $elm$core$Maybe$Nothing));
			case 21:
				var maybeId = msg.a;
				var routeIds = msg.b;
				var maybeAreaId = msg.c;
				var maybeAreaName = msg.d;
				return A2(
					$elm$core$Maybe$withDefault,
					$author$project$Common$ReturnExtra$withNoCmd(model),
					A2(
						$elm$core$Maybe$map,
						function (id) {
							return ($elm$core$List$length(routeIds) > 0) ? _Utils_Tuple2(
								model,
								A4(
									$author$project$Request$RouteRequest$getRouteOrdersListFromSolution,
									context,
									id,
									routeIds,
									A2($author$project$Page$AutomaticRoute$Msg$FetchOrdersCreateExecution, maybeAreaId, maybeAreaName))) : $author$project$Common$ReturnExtra$withNoCmd(model);
						},
						maybeId));
			case 20:
				var solution = msg.a;
				var _v5 = msg.b;
				var id = _v5.a;
				var exec = _v5.b;
				var sol = _v5.c;
				var _v6 = A3(
					$author$project$Page$AutomaticRouteSolution$Update$update,
					context,
					$author$project$Page$AutomaticRouteSolution$Msg$SaveSolution(solution),
					sol);
				var newSol = _v6.a;
				var cmd = _v6.b;
				var finalCmd = A2(
					$elm$core$Platform$Cmd$map,
					$author$project$Page$AutomaticRoute$Update$atRoute(model),
					cmd);
				var newExecutions = A2(
					$elm$core$List$map,
					function (_v7) {
						var idx = _v7.a;
						var execx = _v7.b;
						var solx = _v7.c;
						return _Utils_eq(id, idx) ? _Utils_Tuple3(id, exec, newSol) : _Utils_Tuple3(idx, execx, solx);
					},
					model.b5);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{b5: newExecutions}),
					finalCmd);
			case 19:
				var mapStyle = msg.a;
				var newSelectedExecution = function () {
					var _v9 = model.dF;
					if (!_v9.$) {
						var _v10 = _v9.a;
						var id = _v10.a;
						var ex = _v10.b;
						var sol = _v10.c;
						var newSol = _Utils_update(
							sol,
							{cG: mapStyle});
						return $elm$core$Maybe$Just(
							_Utils_Tuple3(id, ex, newSol));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}();
				var newExecutions = A2(
					$elm$core$List$map,
					function (_v8) {
						var id = _v8.a;
						var ex = _v8.b;
						var sol = _v8.c;
						var newSol = _Utils_update(
							sol,
							{cG: mapStyle});
						return _Utils_Tuple3(id, ex, newSol);
					},
					model.b5);
				return $author$project$Common$ReturnExtra$withNoCmd(
					_Utils_update(
						model,
						{b5: newExecutions, cG: mapStyle, dF: newSelectedExecution}));
			case 16:
				return _Utils_Tuple2(
					model,
					A2($author$project$Request$RouteRequest$checkGeneratingRoutes, context, $author$project$Page$AutomaticRoute$Msg$SolutionInformationReceived));
			case 23:
				var executions = A2(
					$elm$core$List$map,
					function (_v13) {
						var id = _v13.a;
						var ex = _v13.b;
						var sol = _v13.c;
						return _Utils_Tuple3(
							id,
							_Utils_update(
								ex,
								{
									d8: $author$project$Page$AutomaticRouteStep$VehicleDataForm$initialForm(context)
								}),
							sol);
					},
					model.b5);
				var execution = model.dF;
				var selectedExecution = function () {
					if (!execution.$) {
						var _v12 = execution.a;
						var id = _v12.a;
						var exec = _v12.b;
						var solution = _v12.c;
						return $elm$core$Maybe$Just(
							_Utils_Tuple3(
								id,
								_Utils_update(
									exec,
									{
										d8: $author$project$Page$AutomaticRouteStep$VehicleDataForm$initialForm(context)
									}),
								solution));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}();
				return $author$project$Common$ReturnExtra$withNoCmd(
					_Utils_update(
						model,
						{b5: executions, dF: selectedExecution}));
			case 17:
				if (!msg.a.$) {
					var activeSolutions = msg.a.a;
					var newSelectedExecution = function () {
						var _v18 = model.dF;
						if (!_v18.$) {
							var _v19 = _v18.a;
							var id = _v19.a;
							var ex = _v19.b;
							var sol = _v19.c;
							var _v20 = ex.f3;
							if (!_v20.$) {
								var jobId = _v20.a;
								var finalSol = $elm$core$List$head(
									A2(
										$elm$core$List$filter,
										function (ac) {
											return _Utils_eq(jobId, ac.f3);
										},
										activeSolutions));
								if (!finalSol.$) {
									var solx = finalSol.a;
									var _v22 = sol.R;
									var delR = _v22.b;
									var newSol = _Utils_update(
										sol,
										{
											R: _Utils_Tuple2(solx, delR),
											f0: false,
											ht: solx.ht
										});
									return $elm$core$Maybe$Just(
										_Utils_Tuple3(id, ex, newSol));
								} else {
									return $elm$core$Maybe$Just(
										_Utils_Tuple3(id, ex, sol));
								}
							} else {
								return $elm$core$Maybe$Just(
									_Utils_Tuple3(id, ex, sol));
							}
						} else {
							return $elm$core$Maybe$Nothing;
						}
					}();
					var newExecutions = A2(
						$elm$core$List$map,
						function (_v14) {
							var id = _v14.a;
							var ex = _v14.b;
							var sol = _v14.c;
							var _v15 = ex.f3;
							if (!_v15.$) {
								var jobId = _v15.a;
								var finalSol = $elm$core$List$head(
									A2(
										$elm$core$List$filter,
										function (ac) {
											return _Utils_eq(jobId, ac.f3);
										},
										activeSolutions));
								if (!finalSol.$) {
									var solx = finalSol.a;
									var _v17 = sol.R;
									var delR = _v17.b;
									var newSol = _Utils_update(
										sol,
										{
											R: _Utils_Tuple2(solx, delR),
											ht: solx.ht
										});
									return _Utils_Tuple3(id, ex, newSol);
								} else {
									return _Utils_Tuple3(id, ex, sol);
								}
							} else {
								return _Utils_Tuple3(id, ex, sol);
							}
						},
						model.b5);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b5: newExecutions, dF: newSelectedExecution}),
						A2($author$project$Request$RouteRequest$getExecutionPosition, context, $author$project$Page$AutomaticRoute$Msg$ExecutionQueuPositionRecieved));
				} else {
					return $author$project$Common$ReturnExtra$withNoCmd(model);
				}
			case 13:
				var warehouses = msg.a;
				return $author$project$Common$ReturnExtra$withNoCmd(
					_Utils_update(
						model,
						{dV: warehouses}));
			case 14:
				var cmd = A3(
					$author$project$Request$Area$splitOrderByArea,
					context,
					model.gL,
					$author$project$Page$AutomaticRoute$Msg$SplitOrdersResponse(model.gL));
				return A2(
					$author$project$Common$ReturnExtra$withCmd,
					cmd,
					_Utils_update(
						model,
						{dM: true}));
			case 15:
				if (!msg.b.$) {
					var areaOrders = msg.b.a;
					var solution = $author$project$Page$AutomaticRoute$Model$initialSolution(model.cG);
					var newExecutions = A2(
						$elm$core$List$indexedMap,
						F2(
							function (index, areaSplit) {
								var executionOrders = A2(
									$elm$core$List$filter,
									function (o) {
										return A2($elm$core$List$member, o.fS, areaSplit.gL);
									},
									model.gL);
								return _Utils_Tuple3(
									index,
									$author$project$Page$AutomaticRouteStep$Model$initialModel(context)(executionOrders)(model.dV)(_List_Nil)($elm$core$Maybe$Nothing)('')($elm$core$Maybe$Nothing)(
										$elm$core$Maybe$Just(areaSplit.ex))(
										$elm$core$Maybe$Just(areaSplit.ev))(areaSplit.hQ),
									solution);
							}),
						areaOrders);
					var combinedOrderIds = A3(
						$elm$core$List$foldl,
						$elm$core$Basics$append,
						_List_Nil,
						A2(
							$elm$core$List$map,
							function ($) {
								return $.gL;
							},
							areaOrders));
					var newOrders = A2(
						$elm$core$List$filter,
						function (order) {
							return !A2($elm$core$List$member, order.fS, combinedOrderIds);
						},
						model.gL);
					return $author$project$Common$ReturnExtra$withNoCmd(
						_Utils_update(
							model,
							{b3: $elm$core$Maybe$Nothing, b5: newExecutions, gL: newOrders}));
				} else {
					var error = msg.b.a;
					var setError = function (errorData) {
						return $author$project$Common$ReturnExtra$withNoCmd(
							_Utils_update(
								model,
								{
									b3: $elm$core$Maybe$Just(errorData),
									dM: false
								}));
					};
					switch (error.$) {
						case 0:
							var errorData = error.a;
							return setError(errorData);
						case 2:
							var errorData = error.a;
							return setError(errorData);
						case 1:
							var errorData = error.a;
							return setError(errorData);
						case 3:
							var errorData = error.a;
							return setError(errorData);
						case 4:
							var errorData = error.a;
							return setError(errorData);
						default:
							var errorData = error.a;
							return setError(errorData);
					}
				}
			case 12:
				var areas = msg.a;
				return $author$project$Common$ReturnExtra$withNoCmd(
					_Utils_update(
						model,
						{bx: areas}));
			case 11:
				var orders = msg.a;
				var newOrders = ($elm$core$List$length(model.b5) > 0) ? model.gL : orders;
				return $author$project$Common$ReturnExtra$withNoCmd(
					_Utils_update(
						model,
						{gL: newOrders}));
			case 0:
				return $author$project$Common$ReturnExtra$withNoCmd(model);
			case 1:
				return A2(
					$author$project$Common$ReturnExtra$withCmd,
					A2(
						$elm$core$Task$perform,
						$elm$core$Basics$identity,
						$elm$core$Task$succeed($author$project$Page$AutomaticRoute$Msg$Close)),
					model);
			case 2:
				var solution = $author$project$Page$AutomaticRoute$Model$initialSolution(model.cG);
				var index = function () {
					var max = $elm$core$List$maximum(
						A2(
							$elm$core$List$map,
							function (_v25) {
								var id = _v25.a;
								return id;
							},
							model.b5));
					if (max.$ === 1) {
						return 0;
					} else {
						var id = max.a;
						return id + 1;
					}
				}();
				var execution = $author$project$Page$AutomaticRouteStep$Model$initialModel(context)(model.gL)(model.dV)(_List_Nil)($elm$core$Maybe$Nothing)('')($elm$core$Maybe$Nothing)($elm$core$Maybe$Nothing)($elm$core$Maybe$Nothing)($elm$core$Maybe$Nothing);
				var newExecutions = A2(
					$elm$core$List$cons,
					_Utils_Tuple3(index, execution, solution),
					model.b5);
				return $author$project$Common$ReturnExtra$withNoCmd(
					_Utils_update(
						model,
						{
							b5: newExecutions,
							dF: $elm$core$Maybe$Just(
								_Utils_Tuple3(index, execution, solution))
						}));
			case 6:
				var subMsg = msg.a;
				var _v26 = model.dF;
				if (!_v26.$) {
					var _v27 = _v26.a;
					var id = _v27.a;
					var execution = _v27.b;
					var sol = _v27.c;
					var _v28 = A3(
						$author$project$Page$AutomaticRouteSolution$Update$update,
						context,
						$author$project$Page$AutomaticRouteSolution$Msg$ReloadSolutions(execution.f3),
						sol);
					var cmd1 = _v28.b;
					var finalCmdReload = A2(
						$elm$core$Platform$Cmd$map,
						$author$project$Page$AutomaticRoute$Update$atRoute(model),
						cmd1);
					var _v29 = A3($author$project$Page$AutomaticRouteStep$Update$update, context, subMsg, execution);
					var subModel = _v29.a;
					var cmd = _v29.b;
					var finalCmd = A2($elm$core$Platform$Cmd$map, $author$project$Page$AutomaticRoute$Update$aRoute, cmd);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								dF: $elm$core$Maybe$Just(
									_Utils_Tuple3(id, subModel, sol))
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[finalCmd, finalCmdReload])));
				} else {
					return $author$project$Common$ReturnExtra$withNoCmd(model);
				}
			case 7:
				var subMsg = msg.a;
				var _v30 = model.dF;
				if (!_v30.$) {
					var _v31 = _v30.a;
					var id = _v31.a;
					var execution = _v31.b;
					var sol = _v31.c;
					var _v32 = A3($author$project$Page$AutomaticRouteSolution$Update$update, context, subMsg, sol);
					var subModel = _v32.a;
					var cmd = _v32.b;
					var finalCmd = A2(
						$elm$core$Platform$Cmd$map,
						$author$project$Page$AutomaticRoute$Update$atRoute(model),
						cmd);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								dF: $elm$core$Maybe$Just(
									_Utils_Tuple3(id, execution, subModel))
							}),
						finalCmd);
				} else {
					return $author$project$Common$ReturnExtra$withNoCmd(model);
				}
			case 5:
				var index = msg.a;
				var selectedExecution = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (_v37) {
							var id = _v37.a;
							return _Utils_eq(id, index);
						},
						model.b5));
				var _v33 = function () {
					if (!selectedExecution.$) {
						var _v35 = selectedExecution.a;
						var id = _v35.a;
						var st = _v35.b;
						var se = _v35.c;
						if (se.cz) {
							return _Utils_Tuple2(
								$elm$core$Maybe$Just(
									_Utils_Tuple3(id, st, se)),
								$elm$core$Platform$Cmd$none);
						} else {
							var _v36 = A3(
								$author$project$Page$AutomaticRouteSolution$Update$update,
								context,
								$author$project$Page$AutomaticRouteSolution$Msg$ReloadSolutions(st.f3),
								se);
							var sm = _v36.a;
							var cmd1 = _v36.b;
							return _Utils_Tuple2(
								$elm$core$Maybe$Just(
									_Utils_Tuple3(id, st, sm)),
								A2($elm$core$Platform$Cmd$map, $author$project$Page$AutomaticRoute$Msg$AutomaticRouteSolutionMsg, cmd1));
						}
					} else {
						return _Utils_Tuple2(selectedExecution, $elm$core$Platform$Cmd$none);
					}
				}();
				var cmd = _v33.b;
				return A2(
					$author$project$Common$ReturnExtra$withCmd,
					cmd,
					_Utils_update(
						model,
						{dF: selectedExecution}));
			case 9:
				var index = msg.a;
				var selectedExecution = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (_v41) {
							var id = _v41.a;
							return _Utils_eq(id, index);
						},
						model.b5));
				var cmd = function () {
					if (!selectedExecution.$) {
						var _v39 = selectedExecution.a;
						var exec = _v39.b;
						var _v40 = exec.f3;
						if (!_v40.$) {
							var id = _v40.a;
							return A3(
								$author$project$Request$RouteRequest$cancelJobGeneration,
								context,
								id,
								$author$project$Page$AutomaticRoute$Msg$CancelExecutionRequest(index));
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					} else {
						return $elm$core$Platform$Cmd$none;
					}
				}();
				return _Utils_Tuple2(model, cmd);
			case 10:
				if (!msg.b.$) {
					var id = msg.a;
					var newExecutions = A2(
						$elm$core$List$filter,
						function (_v42) {
							var idx = _v42.a;
							return !_Utils_eq(idx, id);
						},
						model.b5);
					return $author$project$Common$ReturnExtra$withNoCmd(
						_Utils_update(
							model,
							{b5: newExecutions, dF: $elm$core$Maybe$Nothing}));
				} else {
					return $author$project$Common$ReturnExtra$withNoCmd(model);
				}
			case 8:
				var index = msg.a;
				var saveOrders = msg.b;
				var solSaved = msg.c;
				var newSelectedExecution = function () {
					var _v47 = model.dF;
					if (!_v47.$) {
						var _v48 = _v47.a;
						var id = _v48.a;
						var exec = _v48.b;
						var sol = _v48.c;
						return _Utils_eq(id, index) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
							_Utils_Tuple3(
								id,
								exec,
								_Utils_update(
									sol,
									{cz: solSaved})));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}();
				var _v43 = A2(
					$elm$core$List$partition,
					function (_v44) {
						var id = _v44.a;
						return _Utils_eq(id, index);
					},
					model.b5);
				var ex = _v43.a;
				var newExecutions = _v43.b;
				var newOrders = function () {
					var _v45 = _Utils_Tuple2(
						saveOrders,
						$elm$core$List$head(ex));
					if (_v45.a && (!_v45.b.$)) {
						var _v46 = _v45.b.a;
						var e = _v46.b;
						return _Utils_ap(model.gL, e.gL);
					} else {
						return model.gL;
					}
				}();
				return $author$project$Common$ReturnExtra$withNoCmd(
					_Utils_update(
						model,
						{b5: newExecutions, gL: newOrders, dF: newSelectedExecution}));
			case 3:
				var newExecutions = function () {
					var _v53 = model.dF;
					if (!_v53.$) {
						var _v54 = _v53.a;
						var index = _v54.a;
						var execution = _v54.b;
						var sol = _v54.c;
						return A2(
							$elm$core$List$map,
							function (_v55) {
								var id = _v55.a;
								var ex = _v55.b;
								var so = _v55.c;
								if (_Utils_eq(id, index)) {
									var _v56 = so.R;
									var ass = _v56.a;
									var deletedRoutes = _v56.b;
									var newAss = _Utils_update(
										ass,
										{
											f3: A2($elm$core$Maybe$withDefault, '', ex.f3)
										});
									var newSolution = _Utils_update(
										sol,
										{
											R: _Utils_Tuple2(newAss, deletedRoutes)
										});
									return _Utils_Tuple3(id, execution, newSolution);
								} else {
									return _Utils_Tuple3(id, ex, so);
								}
							},
							model.b5);
					} else {
						return model.b5;
					}
				}();
				var kpisCmd = function () {
					var _v50 = model.dF;
					if (!_v50.$) {
						var _v51 = _v50.a;
						var execution = _v51.b;
						var _v52 = execution.f3;
						if (!_v52.$) {
							var id = _v52.a;
							return A3(
								$author$project$Request$RouteRequest$fetchExecution,
								context,
								id,
								$author$project$Page$AutomaticRoute$Msg$FetchSelectedExecutionKpis(id));
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					} else {
						return $elm$core$Platform$Cmd$none;
					}
				}();
				var cmd = A2(
					$elm$core$Task$perform,
					function (_v49) {
						return $author$project$Page$AutomaticRoute$Msg$FetchActiveSolutionInformation;
					},
					$elm$core$Task$succeed(0));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{b5: newExecutions, dF: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[cmd, kpisCmd])));
			case 24:
				var jobId = msg.a;
				var response = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							b5: A2(
								$elm$core$List$map,
								A2($author$project$Common$ExecutionKpi$updateExecutionKpiValue, response, jobId),
								model.b5)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{b3: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Views$Common$Select$Empty = {$: 3};
var $author$project$Views$Common$Select$FocusResult = function (a) {
	return {$: 7, a: a};
};
var $author$project$Views$Common$Select$Multiple = function (a) {
	return {$: 2, a: a};
};
var $author$project$Views$Common$Select$Rgx = function (a) {
	return {$: 0, a: a};
};
var $author$project$Views$Common$Select$Single = function (a) {
	return {$: 1, a: a};
};
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {fV: index, gj: match, gE: number, hz: submatches};
	});
var $elm$regex$Regex$contains = _Regex_contains;
var $author$project$Views$Common$Select$contains_asterisk = function (str) {
	return A2($elm$core$String$contains, '*', str);
};
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{eG: false, gr: false},
		string);
};
var $elm$regex$Regex$never = _Regex_never;
var $elm$regex$Regex$replace = _Regex_replaceAtMost(_Regex_infinity);
var $author$project$Views$Common$Select$convertToRegex = function (str) {
	var matcher = function (_v0) {
		var number = _v0.gE;
		return (number === 1) ? '[\\w\\s]+' : '';
	};
	var newStr = A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			function (r) {
				return A3($elm$regex$Regex$replace, r, matcher, str);
			},
			$elm$regex$Regex$fromString('\\*')));
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$regex$Regex$never,
		$elm$regex$Regex$fromString(newStr));
};
var $elm$browser$Browser$Dom$focus = _Browser_call('focus');
var $author$project$Views$Common$Select$updateAll = F3(
	function (selected, updateOnlyWhichAreShowingInDropdown, options) {
		return A2(
			$elm$core$List$map,
			function (option) {
				return (updateOnlyWhichAreShowingInDropdown && option.A) ? _Utils_update(
					option,
					{D: selected}) : ((!updateOnlyWhichAreShowingInDropdown) ? _Utils_update(
					option,
					{D: selected}) : option);
			},
			options);
	});
var $author$project$Views$Common$Select$update = F3(
	function (msg, model, strVal) {
		switch (msg.$) {
			case 3:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							b: A3($author$project$Views$Common$Select$updateAll, true, true, model.b)
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							b: A3($author$project$Views$Common$Select$updateAll, false, true, model.b)
						}),
					$elm$core$Platform$Cmd$none);
			case 0:
				var item = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							b: A2(
								$elm$core$List$map,
								A2($author$project$Views$Common$Select$updateOptionSelectionStatus, item, true),
								model.b)
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var item = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							b: A2(
								$elm$core$List$map,
								A2($author$project$Views$Common$Select$updateOptionSelectionStatus, item, false),
								model.b)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							b: A3($author$project$Views$Common$Select$updateAll, false, false, model.b)
						}),
					$elm$core$Platform$Cmd$none);
			case 5:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{T: !model.T, aN: ''}),
					A2(
						$elm$core$Task$attempt,
						$author$project$Views$Common$Select$FocusResult,
						A2(
							$elm$core$Task$andThen,
							function (_v1) {
								return $elm$browser$Browser$Dom$focus('select');
							},
							$elm$core$Process$sleep(500))));
			case 6:
				var value = msg.a;
				var searchType = function () {
					if ($author$project$Views$Common$Select$contains_asterisk(value)) {
						return $author$project$Views$Common$Select$Rgx(
							$author$project$Views$Common$Select$convertToRegex(
								$elm$core$String$toLower(value)));
					} else {
						var clean_string = $elm$core$String$trim(value);
						var _v3 = A2($elm$core$String$split, ',', clean_string);
						if (!_v3.b) {
							return $author$project$Views$Common$Select$Empty;
						} else {
							if (!_v3.b.b) {
								var x = _v3.a;
								return $author$project$Views$Common$Select$Single(x);
							} else {
								var vals = _v3;
								return $author$project$Views$Common$Select$Multiple(vals);
							}
						}
					}
				}();
				var updatedOptions = function () {
					switch (searchType.$) {
						case 3:
							return model.b;
						case 1:
							var searchQuery = searchType.a;
							return A2(
								$elm$core$List$map,
								function (option) {
									return (!A2(
										$elm$core$String$contains,
										searchQuery,
										strVal(option.y))) ? _Utils_update(
										option,
										{A: false}) : _Utils_update(
										option,
										{A: true});
								},
								model.b);
						case 2:
							var searchQueries = searchType.a;
							return A2(
								$elm$core$List$map,
								function (option) {
									return (!A2(
										$elm$core$List$any,
										function (query) {
											return A2(
												$elm$core$String$contains,
												query,
												strVal(option.y));
										},
										searchQueries)) ? _Utils_update(
										option,
										{A: false}) : _Utils_update(
										option,
										{A: true});
								},
								model.b);
						default:
							var reg = searchType.a;
							return A2(
								$elm$core$List$map,
								function (option) {
									return (!A2(
										$elm$regex$Regex$contains,
										reg,
										$elm$core$String$toLower(
											strVal(option.y)))) ? _Utils_update(
										option,
										{A: false}) : _Utils_update(
										option,
										{A: true});
								},
								model.b);
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{b: updatedOptions, aN: value}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$AutomaticRoute$Msg$UpdateExecutionSettings = {$: 23};
var $author$project$Views$MapAssignment$Update$updateAutomaticRouteModel = F3(
	function (context, dialogState, model) {
		if (!dialogState.$) {
			var modelRoute = dialogState.a;
			var _v1 = A4(
				$author$project$Page$AutomaticRoute$Update$update,
				context,
				$author$project$Page$AutomaticRoute$Msg$UpdateExecutionSettings,
				modelRoute,
				$author$project$Views$MapAssignment$Update$filterdOrdersWithAddresses(model));
			var newModelSettings = _v1.a;
			return $author$project$Views$MapAssignment$Model$Closed(
				_Utils_update(
					modelRoute,
					{b5: newModelSettings.b5, dF: newModelSettings.dF}));
		} else {
			var modelRoute = dialogState.a;
			var _v2 = A4(
				$author$project$Page$AutomaticRoute$Update$update,
				context,
				$author$project$Page$AutomaticRoute$Msg$UpdateExecutionSettings,
				modelRoute,
				$author$project$Views$MapAssignment$Update$filterdOrdersWithAddresses(model));
			var newModelSettings = _v2.a;
			return $author$project$Views$MapAssignment$Model$Opened(
				_Utils_update(
					modelRoute,
					{b5: newModelSettings.b5, dF: newModelSettings.dF}));
		}
	});
var $author$project$Views$Common$Select$changesSelectedOptions = function (msg) {
	switch (msg.$) {
		case 5:
			return false;
		case 6:
			return false;
		case 7:
			return false;
		default:
			return true;
	}
};
var $author$project$Views$Common$Select$sortOptions = F2(
	function (sortFn, model) {
		return _Utils_update(
			model,
			{
				b: sortFn(model.b)
			});
	});
var $author$project$Views$Common$Select$updateOptions = F2(
	function (model, newOptionValues) {
		var options = model.b;
		var optionsAlreadySelected = A2(
			$elm$core$List$filter,
			function ($) {
				return $.D;
			},
			options);
		var newOptions = A3(
			$elm$core$List$foldl,
			F2(
				function (value, acc) {
					return A2(
						$elm$core$List$any,
						function (option) {
							return _Utils_eq(option.y, value);
						},
						acc) ? acc : A2(
						$elm$core$List$cons,
						A3($author$project$Views$Common$Select$Option, value, false, true),
						acc);
				}),
			optionsAlreadySelected,
			newOptionValues);
		return _Utils_update(
			model,
			{b: newOptions});
	});
var $author$project$Views$MapAssignment$Update$updateFilter = F6(
	function (subMsg, filteredOrders, uniqueByFn, mapFn, sortFn, targetFilter) {
		var updatedFilter = A2(
			$author$project$Views$Common$Select$sortOptions,
			sortFn,
			A2(
				$author$project$Views$Common$Select$updateOptions,
				targetFilter,
				A2(
					$elm_community$list_extra$List$Extra$uniqueBy,
					uniqueByFn,
					mapFn(filteredOrders))));
		if (!subMsg.$) {
			var msg = subMsg.a;
			return $author$project$Views$Common$Select$changesSelectedOptions(msg) ? updatedFilter : targetFilter;
		} else {
			return updatedFilter;
		}
	});
var $author$project$Views$MapAssignment$Update$updateFilters = F2(
	function (fn, m) {
		return _Utils_update(
			m,
			{
				fG: fn(m.fG)
			});
	});
var $author$project$Views$MapAssignment$Update$updatePolygonFilter = F3(
	function (ordersBeingProcessed, model, filter) {
		if (filter.$ === 1) {
			var drawing = filter.a;
			var excluded = filter.c;
			var polygons = filter.d;
			var justAddedOrderIds = $elm$core$Set$fromList(
				A2(
					$elm$core$List$map,
					function ($) {
						return $.fS;
					},
					$elm$core$Dict$values(
						A3(
							$author$project$Views$MapAssignment$Update$ordersInsidePolygons,
							ordersBeingProcessed,
							A2($author$project$Views$MapAssignment$Model$orderIdsToOrdersDict, model.gL, model.fF),
							polygons))));
			var newExcluded = A2($elm$core$Set$diff, excluded, justAddedOrderIds);
			var ordersInside = $elm$core$Dict$values(
				A3(
					$author$project$Views$MapAssignment$Update$ordersInsidePolygons,
					newExcluded,
					A2($author$project$Views$MapAssignment$Model$orderIdsToOrdersDict, model.gL, model.fF),
					polygons));
			return A4($author$project$Views$MapAssignment$Model$Selecting, drawing, ordersInside, newExcluded, polygons);
		} else {
			return filter;
		}
	});
var $author$project$Views$MapAssignment$Update$updateOptionsForAllFilters = F3(
	function (zone, ordersBeingProcessed, model) {
		var filteredOrdersIds = model.fF;
		var orders = model.gL;
		var filters = model.fG;
		var filteredOrders = A2($author$project$Views$MapAssignment$Model$orderIdsToOrders, orders, filteredOrdersIds);
		return _Utils_update(
			filters,
			{
				b_: A6(
					$author$project$Views$MapAssignment$Update$updateFilter,
					$elm$core$Maybe$Nothing,
					filteredOrders,
					function ($) {
						return $.fS;
					},
					$elm$core$List$filterMap(
						function ($) {
							return $.fh;
						}),
					$author$project$Views$MapAssignment$Update$sortByFullName,
					filters.b_),
				fL: A6($author$project$Views$MapAssignment$Update$updateFilter, $elm$core$Maybe$Nothing, filteredOrders, $elm$core$Basics$identity, $author$project$Views$MapAssignment$Update$accuracyQualityMapperFn, $elm$core$Basics$identity, filters.fL),
				c6: A6(
					$author$project$Views$MapAssignment$Update$updateFilter,
					$elm$core$Maybe$Nothing,
					filteredOrders,
					$elm$core$Basics$identity,
					$elm$core$List$map(
						function ($) {
							return $.gD;
						}),
					$author$project$Views$MapAssignment$Update$sortByValue,
					filters.c6),
				c8: A3($author$project$Views$MapAssignment$Update$updatePolygonFilter, ordersBeingProcessed, model, filters.c8),
				gY: A6($author$project$Views$MapAssignment$Update$updateFilter, $elm$core$Maybe$Nothing, filteredOrders, $elm$core$Basics$identity, $author$project$Views$MapAssignment$Update$postalCodesMapperFn, $author$project$Views$MapAssignment$Update$sortByValue, filters.gY),
				g6: A6(
					$author$project$Views$MapAssignment$Update$updateFilter,
					$elm$core$Maybe$Nothing,
					filteredOrders,
					function ($) {
						return $.fS;
					},
					$elm$core$List$filterMap(
						function ($) {
							return $.eT;
						}),
					$author$project$Views$MapAssignment$Update$sortByName,
					filters.g6),
				hk: A6(
					$author$project$Views$MapAssignment$Update$updateFilter,
					$elm$core$Maybe$Nothing,
					filteredOrders,
					$elm$core$Basics$identity,
					$elm$core$List$filterMap(
						function ($) {
							return $.hj;
						}),
					$author$project$Views$MapAssignment$Update$sortByValue,
					filters.hk),
				dV: A6(
					$author$project$Views$MapAssignment$Update$updateFilter,
					$elm$core$Maybe$Nothing,
					filteredOrders,
					function ($) {
						return $.fS;
					},
					$elm$core$List$filterMap(
						function ($) {
							return $.hw;
						}),
					$author$project$Views$MapAssignment$Update$sortByName,
					filters.dV),
				bm: A6(
					$author$project$Views$MapAssignment$Update$updateFilter,
					$elm$core$Maybe$Nothing,
					filteredOrders,
					$author$project$Views$MapAssignment$Update$uniqueHourTimeSlot(zone),
					$elm$core$List$map(
						function (o) {
							return _Utils_Tuple2(o.e7.hq, o.e7.fp);
						}),
					$author$project$Views$MapAssignment$Update$sortTimeslotOptions(zone),
					filters.bm)
			});
	});
var $author$project$Views$MapAssignment$Update$updatePackagesFromList = F2(
	function (packagesList, model) {
		var updateFn = F2(
			function (packageToBeAdded, maybePackages) {
				if (!maybePackages.$) {
					var oldPackages = maybePackages.a;
					return $elm$core$Maybe$Just(
						A2(
							$elm_community$list_extra$List$Extra$uniqueBy,
							function ($) {
								return $.fS;
							},
							A2($elm$core$List$cons, packageToBeAdded, oldPackages)));
				} else {
					return $elm$core$Maybe$Just(
						_List_fromArray(
							[packageToBeAdded]));
				}
			});
		var newPackagesDict = A3(
			$elm$core$List$foldl,
			F2(
				function (_package, acc) {
					return A3(
						$elm$core$Dict$update,
						_package.gI,
						updateFn(_package),
						acc);
				}),
			model.c6,
			packagesList);
		return _Utils_update(
			model,
			{c6: newPackagesDict});
	});
var $author$project$Views$MapAssignment$Update$updateRouteContainer = F2(
	function (newRouteContainer, model) {
		var routes = model.hc;
		var isTheSelectedContainer = A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				$author$project$Views$MapAssignment$RouteContainer$isSameRouteContainer(newRouteContainer),
				routes.bd));
		var newRoutes = _Utils_update(
			routes,
			{
				b9: A2($author$project$Views$MapAssignment$RouteContainer$replaceRouteContainer, newRouteContainer, routes.b9),
				g8: A2($author$project$Views$MapAssignment$RouteContainer$replaceRouteContainer, newRouteContainer, routes.g8),
				bd: A3(
					$author$project$Utils$ifThenElse,
					isTheSelectedContainer,
					$elm$core$Maybe$Just(newRouteContainer),
					routes.bd)
			});
		var newModel = _Utils_update(
			model,
			{hc: newRoutes});
		return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
	});
var $author$project$Views$MapAssignment$Update$updateScrollList = F3(
	function (scrollInfo, list, windowSize) {
		var listSize = $elm$core$List$length(list);
		var newScrollInfo = function () {
			if (_Utils_cmp(listSize, scrollInfo.cV) > 0) {
				var newOffset = scrollInfo.cV + windowSize;
				var infiniteScroll = $FabienHenon$elm_infinite_scroll$InfiniteScroll$stopLoading(scrollInfo.co);
				var content = A2(
					$elm$core$List$append,
					scrollInfo.bP,
					A2(
						$elm$core$List$take,
						windowSize,
						A2($elm$core$List$drop, scrollInfo.cV, list)));
				return _Utils_update(
					scrollInfo,
					{bP: content, co: infiniteScroll, cV: newOffset});
			} else {
				return scrollInfo;
			}
		}();
		return newScrollInfo;
	});
var $author$project$Config$startTimeErrorAutoRouting = 'start_time_auto_error';
var $author$project$Common$Dates$timeFromString = function (time) {
	var _v0 = A2(
		$elm$core$Maybe$map,
		$elm$core$String$split(':'),
		time);
	if (((!_v0.$) && _v0.a.b) && _v0.a.b.b) {
		var _v1 = _v0.a;
		var hours = _v1.a;
		var _v2 = _v1.b;
		var minutes = _v2.a;
		var startMinute = $elm$core$String$toInt(minutes);
		var startHour = $elm$core$String$toInt(hours);
		var _v3 = _Utils_Tuple2(startHour, startMinute);
		if ((!_v3.a.$) && (!_v3.b.$)) {
			var hs = _v3.a.a;
			var ms = _v3.b.a;
			return $elm$core$Maybe$Just(
				{cn: hs, cP: ms});
		} else {
			return $elm$core$Maybe$Nothing;
		}
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Views$MapAssignment$Update$validateAutoRoutingSettings = F2(
	function (autoRoutingSettings, errorsDict) {
		var _v0 = $author$project$Common$Dates$timeFromString(autoRoutingSettings.hs);
		if (!_v0.$) {
			var wt = _v0.a;
			return A3($author$project$Common$Dates$validateWorkHours, wt.cn, wt.cP, autoRoutingSettings.eg) ? A2($elm$core$Dict$remove, $author$project$Config$startTimeErrorAutoRouting, errorsDict) : A3(
				$elm$core$Dict$update,
				$author$project$Config$startTimeErrorAutoRouting,
				function (_v1) {
					return $elm$core$Maybe$Just('Remember that Start Working Time plus Working Hours should not exceed current day (00:00)');
				},
				errorsDict);
		} else {
			return A3(
				$elm$core$Dict$update,
				$author$project$Config$startTimeErrorAutoRouting,
				function (_v2) {
					return $elm$core$Maybe$Just('Remember to select start working time');
				},
				errorsDict);
		}
	});
var $author$project$Views$MapAssignment$Update$waitTimeForResetButtonToAppear = function (_v0) {
	var env = _v0.fr;
	if (env === 3) {
		return (2 * 60) * 1000;
	} else {
		return 20 * 1000;
	}
};
var $author$project$Config$warehouseErrorKeyManualRouting = 'warehouse_manual_error';
var $author$project$Views$MapAssignment$Update$warehouseSettingNeedsReset = F3(
	function (context, _v0, warehouses) {
		var warehouseSelectedAt = _v0.h$;
		var originWarehouseId = _v0.gN;
		if (originWarehouseId.$ === 1) {
			return true;
		} else {
			return ($elm$core$List$length(warehouses) > 1) ? A2($author$project$Views$MapAssignment$Update$settingExpired, context, warehouseSelectedAt) : false;
		}
	});
var $PaackEng$elm_alert_beta$Alert$Warning = 1;
var $PaackEng$elm_alert_beta$Alert$warning = F2(
	function (message, state) {
		return A3($PaackEng$elm_alert_beta$Alert$genericAlert, message, 1, state);
	});
var $author$project$Request$RouteRequest$websocketRouteMessageDecoder = A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$int);
var $author$project$Views$MapAssignment$Update$update = F3(
	function (context, msg, model) {
		var zone = context.em;
		var routes = model.hc;
		var ordersBeingProcessed = model.hc.c0;
		var maps = model.gh;
		var filteredOrdersWithAddresses_ = $author$project$Views$MapAssignment$Update$filterdOrdersWithAddresses(model);
		switch (msg.$) {
			case 74:
				var bounds = msg.a;
				var newMap = _Utils_update(
					maps,
					{eD: bounds});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{gh: newMap}),
					$elm$core$Platform$Cmd$none);
			case 73:
				var zoom = msg.a;
				var newMap = _Utils_update(
					maps,
					{h4: zoom});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{gh: newMap}),
					$elm$core$Platform$Cmd$none);
			case 69:
				if (!msg.a.$) {
					var mapStyle = msg.a.a;
					var automaticRouteDialogState = function () {
						var _v1 = routes.bA;
						if (!_v1.$) {
							var a = _v1.a;
							var _v2 = A4(
								$author$project$Page$AutomaticRoute$Update$update,
								context,
								$author$project$Page$AutomaticRoute$Msg$UpdateMapStyle(mapStyle),
								a,
								filteredOrdersWithAddresses_);
							var newModel = _v2.a;
							return $author$project$Views$MapAssignment$Model$Closed(newModel);
						} else {
							var a = _v1.a;
							var _v3 = A4(
								$author$project$Page$AutomaticRoute$Update$update,
								context,
								$author$project$Page$AutomaticRoute$Msg$UpdateMapStyle(mapStyle),
								a,
								filteredOrdersWithAddresses_);
							var newModel = _v3.a;
							return $author$project$Views$MapAssignment$Model$Opened(newModel);
						}
					}();
					var newRoutes = _Utils_update(
						routes,
						{bA: automaticRouteDialogState});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								gh: _Utils_update(
									maps,
									{cG: mapStyle}),
								hc: newRoutes
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 68:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 67:
				var routeContainer = msg.a;
				var newRouteContainer = function () {
					var routeData = routeContainer.g9;
					var newCurrentRoute = _Utils_update(
						routeData,
						{gs: routeContainer.fl});
					return _Utils_update(
						routeContainer,
						{fk: false, g9: newCurrentRoute});
				}();
				var selectedRouteContainer = $elm$core$Maybe$Just(newRouteContainer);
				var newRoutes = _Utils_update(
					routes,
					{
						b9: A2($author$project$Views$MapAssignment$RouteContainer$replaceRouteContainer, newRouteContainer, routes.b9),
						g8: A2($author$project$Views$MapAssignment$RouteContainer$replaceRouteContainer, newRouteContainer, routes.g8),
						bd: selectedRouteContainer
					});
				var newModel = _Utils_update(
					model,
					{hc: newRoutes});
				var cmd = A4($author$project$Request$Route$setRouteName, context, routeContainer.g9.fS, routeContainer.fl, $author$project$Views$MapAssignment$Msg$SetNameToRouteResponse);
				return _Utils_Tuple2(newModel, cmd);
			case 65:
				var routeContainer = msg.a;
				var string = msg.b;
				var newRouteContainer = _Utils_update(
					routeContainer,
					{fl: string});
				var newRoutes = _Utils_update(
					routes,
					{
						b9: A2($author$project$Views$MapAssignment$RouteContainer$replaceRouteContainer, newRouteContainer, routes.b9),
						g8: A2($author$project$Views$MapAssignment$RouteContainer$replaceRouteContainer, newRouteContainer, routes.g8),
						bd: $elm$core$Maybe$Just(newRouteContainer)
					});
				var newModel = _Utils_update(
					model,
					{hc: newRoutes});
				return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			case 66:
				var routeContainer = msg.a;
				var newRouteContainer = _Utils_update(
					routeContainer,
					{fk: !routeContainer.fk});
				var selectedRouteContainer = $elm$core$Maybe$Just(newRouteContainer);
				var newRoutes = _Utils_update(
					routes,
					{
						b9: A2($author$project$Views$MapAssignment$RouteContainer$replaceRouteContainer, newRouteContainer, routes.b9),
						g8: A2($author$project$Views$MapAssignment$RouteContainer$replaceRouteContainer, newRouteContainer, routes.g8),
						bd: selectedRouteContainer
					});
				var newModel = _Utils_update(
					model,
					{hc: newRoutes});
				return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			case 103:
				var routeContainer = msg.a;
				return A2(
					$author$project$Views$MapAssignment$Update$updateRouteContainer,
					_Utils_update(
						routeContainer,
						{ct: true}),
					model);
			case 104:
				var routeContainer = msg.a;
				return A2(
					$author$project$Views$MapAssignment$Update$updateRouteContainer,
					$author$project$Views$MapAssignment$RouteContainer$closeAndResetForm(routeContainer),
					model);
			case 105:
				var routeContainer = msg.a;
				var string = msg.b;
				return A2(
					$author$project$Views$MapAssignment$Update$updateRouteContainer,
					_Utils_update(
						routeContainer,
						{eh: string}),
					model);
			case 106:
				var routeContainer = msg.a;
				var string = msg.b;
				return A2(
					$author$project$Views$MapAssignment$Update$updateRouteContainer,
					_Utils_update(
						routeContainer,
						{dP: string}),
					model);
			case 107:
				var routeContainer = msg.a;
				var string = msg.b;
				return A2(
					$author$project$Views$MapAssignment$Update$updateRouteContainer,
					_Utils_update(
						routeContainer,
						{bF: string}),
					model);
			case 64:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cg: _List_Nil}),
					$elm$core$Platform$Cmd$none);
			case 19:
				var routeContainer = msg.a;
				var coordinates = $author$project$Views$MapAssignment$Update$getFirstOrderLocation(routeContainer);
				var newMap = _Utils_update(
					maps,
					{bR: coordinates, h4: 15});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{gh: newMap}),
					$elm$core$Platform$Cmd$none);
			case 20:
				var coordinates = msg.a;
				var newCoordinates = _Utils_Tuple2(coordinates.f8, coordinates.gb);
				var newMap = _Utils_update(
					maps,
					{
						bR: $elm$core$Maybe$Just(newCoordinates),
						h4: 15
					});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{gh: newMap}),
					$elm$core$Platform$Cmd$none);
			case 112:
				var routeId = msg.a;
				var response = msg.b;
				var updateContainer = function (container) {
					return _Utils_eq(container.g9.fS, routeId) ? _Utils_update(
						container,
						{f6: response}) : container;
				};
				var selectedRouteContainer = A2($elm$core$Maybe$map, updateContainer, routes.bd);
				var routeContainers = A2($elm$core$List$map, updateContainer, routes.g8);
				var newRoutes = _Utils_update(
					routes,
					{g8: routeContainers, bd: selectedRouteContainer});
				return _Utils_Tuple2(
					$author$project$Views$MapAssignment$Update$filterRouteContainers(
						_Utils_update(
							model,
							{hc: newRoutes})),
					$elm$core$Platform$Cmd$none);
			case 63:
				if (!msg.b.$) {
					var routeId = msg.a;
					var solution = msg.b.a;
					var mergeOrders = F2(
						function (routeOrders, solutionOrders) {
							var solutionOrderIds = $elm$core$Set$fromList(
								A2(
									$elm$core$List$map,
									A2(
										$elm$core$Basics$composeR,
										function ($) {
											return $.cZ;
										},
										function ($) {
											return $.fS;
										}),
									solutionOrders));
							var newRouteOrders = A2(
								$elm$core$List$filter,
								function (o) {
									return !A2($elm$core$Set$member, o.cZ.fS, solutionOrderIds);
								},
								routeOrders);
							return A2(
								$elm$core$List$indexedMap,
								F2(
									function (i, o) {
										return _Utils_update(
											o,
											{gW: i});
									}),
								_Utils_ap(solutionOrders, newRouteOrders));
						});
					var routeContainers = A2(
						$elm$core$List$map,
						function (container) {
							if (_Utils_eq(container.g9.fS, routeId)) {
								var route = container.g9;
								var _v6 = A2($author$project$Views$MapAssignment$Update$getRouteOrdersListFromSolution, container, solution.ho);
								var uuid = _v6.a;
								var orders = _v6.b;
								var newOrders = A2(mergeOrders, route.gL, orders);
								var newRoute = _Utils_update(
									route,
									{
										gL: newOrders,
										hW: A2($elm$core$Maybe$withDefault, '', uuid)
									});
								return _Utils_update(
									container,
									{
										g9: newRoute,
										ho: $elm$core$Maybe$Just(solution.ho)
									});
							} else {
								return container;
							}
						},
						routes.g8);
					var selectedRouteContainer = A2(
						$elm$core$Maybe$map,
						function (container) {
							if (_Utils_eq(container.g9.fS, routeId)) {
								var route = container.g9;
								var _v5 = A2($author$project$Views$MapAssignment$Update$getRouteOrdersListFromSolution, container, solution.ho);
								var uuid = _v5.a;
								var orders = _v5.b;
								var newOrders = A2(mergeOrders, route.gL, orders);
								var newRoute = _Utils_update(
									route,
									{
										gL: newOrders,
										hW: A2($elm$core$Maybe$withDefault, '', uuid)
									});
								return _Utils_update(
									container,
									{
										ae: $author$project$Views$MapAssignment$RouteContainer$Optimized,
										g9: newRoute,
										ho: $elm$core$Maybe$Just(solution.ho)
									});
							} else {
								return container;
							}
						},
						routes.bd);
					var newRoutes = _Utils_update(
						routes,
						{g8: routeContainers, bd: selectedRouteContainer});
					var newModel = $author$project$Views$MapAssignment$Update$filterRouteContainers(
						_Utils_update(
							model,
							{hc: newRoutes}));
					var cmd = function () {
						if (selectedRouteContainer.$ === 1) {
							return $elm$core$Platform$Cmd$none;
						} else {
							var routeContainer = selectedRouteContainer.a;
							return (routeContainer.g9.ht === 1) ? A3(
								$author$project$Request$RouteRequest$fetchExecution,
								context,
								solution.f3,
								$author$project$Views$MapAssignment$Msg$GetKpiResponse(routeId)) : $elm$core$Platform$Cmd$none;
						}
					}();
					return _Utils_Tuple2(newModel, cmd);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 58:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 44:
				var currentStatus = msg.a;
				if (!currentStatus) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{c4: 1}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{c4: 0}),
						$elm$core$Platform$Cmd$none);
				}
			case 45:
				var volume_query = msg.a;
				var modelWithOrdersFiltered = A2(
					$author$project$Views$MapAssignment$Update$filterOrders,
					context,
					A2(
						$author$project$Views$MapAssignment$Update$updateFilters,
						function (filters_) {
							return _Utils_update(
								filters_,
								{bo: volume_query});
						},
						model));
				return _Utils_Tuple2(
					_Utils_update(
						modelWithOrdersFiltered,
						{
							fG: A3($author$project$Views$MapAssignment$Update$updateOptionsForAllFilters, zone, ordersBeingProcessed, modelWithOrdersFiltered)
						}),
					$elm$core$Platform$Cmd$none);
			case 46:
				var postalCodeSearch = msg.a;
				var newModel = A2(
					$author$project$Views$MapAssignment$Update$filterOrders,
					context,
					A2(
						$author$project$Views$MapAssignment$Update$updateFilters,
						function (filters) {
							return _Utils_update(
								filters,
								{aI: postalCodeSearch});
						},
						model));
				if (!postalCodeSearch) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					return $elm$core$List$isEmpty(
						$author$project$Views$Common$Select$getSelectedOptions(newModel.fG.gY)) ? _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 47:
				var newModel = A2(
					$author$project$Views$MapAssignment$Update$filterOrders,
					context,
					A2(
						$author$project$Views$MapAssignment$Update$updateFilters,
						function (filters) {
							return _Utils_update(
								filters,
								{
									c8: A4($author$project$Views$MapAssignment$Model$Selecting, true, _List_Nil, $elm$core$Set$empty, _List_Nil)
								});
						},
						model));
				return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			case 48:
				var newPolygonFilter = function () {
					var _v9 = model.fG.c8;
					switch (_v9.$) {
						case 0:
							return $author$project$Views$MapAssignment$Model$NotStarted;
						case 1:
							var excluded = _v9.c;
							var polygons = _v9.d;
							return A2($author$project$Views$MapAssignment$Model$Finished, excluded, polygons);
						default:
							var excluded = _v9.a;
							var polygons = _v9.b;
							return A2($author$project$Views$MapAssignment$Model$Finished, excluded, polygons);
					}
				}();
				var modelWithOrdersFiltered = A2(
					$author$project$Views$MapAssignment$Update$filterOrders,
					context,
					A2(
						$author$project$Views$MapAssignment$Update$updateFilters,
						function (filters_) {
							return _Utils_update(
								filters_,
								{c8: newPolygonFilter});
						},
						model));
				return _Utils_Tuple2(
					_Utils_update(
						modelWithOrdersFiltered,
						{
							fG: A3($author$project$Views$MapAssignment$Update$updateOptionsForAllFilters, zone, ordersBeingProcessed, modelWithOrdersFiltered)
						}),
					$elm$core$Platform$Cmd$none);
			case 49:
				var newModel = A2(
					$author$project$Views$MapAssignment$Update$updateFilters,
					function (filters) {
						return _Utils_update(
							filters,
							{c8: $author$project$Views$MapAssignment$Model$NotStarted});
					},
					model);
				return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			case 50:
				var newPolygonFilter = function () {
					var _v10 = model.fG.c8;
					switch (_v10.$) {
						case 0:
							return $author$project$Views$MapAssignment$Model$NotStarted;
						case 1:
							var count = _v10.b;
							var excluded = _v10.c;
							var polygons = _v10.d;
							return A4($author$project$Views$MapAssignment$Model$Selecting, true, count, excluded, polygons);
						default:
							var excluded = _v10.a;
							var polygons = _v10.b;
							return A2($author$project$Views$MapAssignment$Model$Finished, excluded, polygons);
					}
				}();
				var newModel = A2(
					$author$project$Views$MapAssignment$Update$updateFilters,
					function (filters) {
						return _Utils_update(
							filters,
							{c8: newPolygonFilter});
					},
					model);
				return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			case 51:
				var order = msg.a;
				var polygonFilter = model.fG.c8;
				var newPolygonFilter = function () {
					switch (polygonFilter.$) {
						case 0:
							return $author$project$Views$MapAssignment$Model$NotStarted;
						case 1:
							var isDrawing = polygonFilter.a;
							var excluded = polygonFilter.c;
							var polygons = polygonFilter.d;
							var newExcluded = A2($elm$core$Set$insert, order.fS, excluded);
							var allExcluded = A2($elm$core$Set$union, model.hc.c0, newExcluded);
							var ordersInside = $elm$core$Dict$values(
								A3(
									$author$project$Views$MapAssignment$Update$ordersInsidePolygons,
									allExcluded,
									A2($author$project$Views$MapAssignment$Model$orderIdsToOrdersDict, model.gL, model.fF),
									polygons));
							return A4($author$project$Views$MapAssignment$Model$Selecting, isDrawing, ordersInside, newExcluded, polygons);
						default:
							var polygons = polygonFilter.a;
							var excluded = polygonFilter.b;
							return A2($author$project$Views$MapAssignment$Model$Finished, polygons, excluded);
					}
				}();
				var newModel = A2(
					$author$project$Views$MapAssignment$Update$updateFilters,
					function (filters) {
						return _Utils_update(
							filters,
							{c8: newPolygonFilter});
					},
					model);
				return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			case 52:
				var polygon = msg.a;
				var polygonFilter = model.fG.c8;
				var newPolygonFilter = function () {
					switch (polygonFilter.$) {
						case 0:
							return $author$project$Views$MapAssignment$Model$NotStarted;
						case 1:
							var excluded = polygonFilter.c;
							var polygons = polygonFilter.d;
							var newPolygons = A2($elm$core$List$cons, polygon, polygons);
							var justAddedOrders = $elm$core$Dict$values(
								A3(
									$author$project$Views$MapAssignment$Update$ordersInsidePolygons,
									model.hc.c0,
									A2($author$project$Views$MapAssignment$Model$orderIdsToOrdersDict, model.gL, model.fF),
									_List_fromArray(
										[polygon])));
							var justAddedOrderIds = $elm$core$Set$fromList(
								A2(
									$elm$core$List$map,
									function ($) {
										return $.fS;
									},
									justAddedOrders));
							var newExcluded = A2($elm$core$Set$diff, excluded, justAddedOrderIds);
							var ordersInside = $elm$core$Dict$values(
								A3(
									$author$project$Views$MapAssignment$Update$ordersInsidePolygons,
									newExcluded,
									A2($author$project$Views$MapAssignment$Model$orderIdsToOrdersDict, model.gL, model.fF),
									newPolygons));
							return A4($author$project$Views$MapAssignment$Model$Selecting, false, ordersInside, newExcluded, newPolygons);
						default:
							var polygons = polygonFilter.a;
							var excluded = polygonFilter.b;
							return A2($author$project$Views$MapAssignment$Model$Finished, polygons, excluded);
					}
				}();
				var newModel = A2(
					$author$project$Views$MapAssignment$Update$updateFilters,
					function (filters) {
						return _Utils_update(
							filters,
							{c8: newPolygonFilter});
					},
					model);
				return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			case 53:
				var postalCodesList = msg.a;
				var newModel = A2(
					$author$project$Views$MapAssignment$Update$updateFilters,
					function (filters) {
						return _Utils_update(
							filters,
							{gZ: postalCodesList});
					},
					model);
				return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			case 54:
				var modelWithOrdersFiltered = A2($author$project$Views$MapAssignment$Update$filterOrders, context, model);
				return _Utils_Tuple2(
					_Utils_update(
						modelWithOrdersFiltered,
						{
							fG: A3($author$project$Views$MapAssignment$Update$updateOptionsForAllFilters, zone, ordersBeingProcessed, modelWithOrdersFiltered)
						}),
					$elm$core$Platform$Cmd$none);
			case 39:
				return A2(
					$author$project$Views$MapAssignment$Update$modifyAllRoutes,
					model,
					A2($author$project$Request$Route$hideAllRoutes, context, $author$project$Views$MapAssignment$Msg$HideAllRoutesRequest));
			case 40:
				if (!msg.a.$) {
					var newRoutes = msg.a.a;
					return A3($author$project$Views$MapAssignment$Update$modifyRoutesSuccessResponse, context, model, newRoutes);
				} else {
					return $author$project$Views$MapAssignment$Update$modifyRoutesFailureResponse(model);
				}
			case 7:
				if (!msg.a.$) {
					var areas = msg.a.a;
					var newAutomaticRouteDialogState = function () {
						var _v13 = routes.bA;
						if (_v13.$ === 1) {
							var a = _v13.a;
							var _v14 = A4(
								$author$project$Page$AutomaticRoute$Update$update,
								context,
								$author$project$Page$AutomaticRoute$Msg$AddAreas(areas),
								a,
								filteredOrdersWithAddresses_);
							var md = _v14.a;
							return $author$project$Views$MapAssignment$Model$Opened(md);
						} else {
							var a = _v13.a;
							var _v15 = A4(
								$author$project$Page$AutomaticRoute$Update$update,
								context,
								$author$project$Page$AutomaticRoute$Msg$AddAreas(areas),
								a,
								filteredOrdersWithAddresses_);
							var md = _v15.a;
							return $author$project$Views$MapAssignment$Model$Closed(md);
						}
					}();
					var newRoute = _Utils_update(
						routes,
						{bA: newAutomaticRouteDialogState});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bx: areas, b2: $elm$core$Maybe$Nothing, hc: newRoute}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					var setError = function (errorData) {
						return $author$project$Common$ReturnExtra$withNoCmd(
							_Utils_update(
								model,
								{
									b2: $elm$core$Maybe$Just(errorData)
								}));
					};
					switch (error.$) {
						case 0:
							var errorData = error.a;
							return setError(errorData);
						case 2:
							var errorData = error.a;
							return setError(errorData);
						case 1:
							var errorData = error.a;
							return setError(errorData);
						case 3:
							var errorData = error.a;
							return setError(errorData);
						case 4:
							var errorData = error.a;
							return setError(errorData);
						default:
							var errorData = error.a;
							return setError(errorData);
					}
				}
			case 0:
				if (!msg.a.$) {
					var _v17 = msg.a.a;
					var orders = _v17.a;
					var paginationData = _v17.b;
					return A6($author$project$Views$MapAssignment$Update$loadOrders, context, model, orders, paginationData, 0, $author$project$Request$Order$loadUpcomingOrders);
				} else {
					var _v18 = A2($PaackEng$elm_alert_beta$Alert$error, 'There was an error fetching upcoming orders', model.bv);
					var alerts = _v18.a;
					var alertCmd = _v18.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bv: alerts, hS: $author$project$Views$MapAssignment$Model$LoadingNotStarted}),
						A2($elm$core$Platform$Cmd$map, $author$project$Views$MapAssignment$Msg$AlertMsg, alertCmd));
				}
			case 1:
				if (!msg.a.$) {
					var drivers = msg.a.a;
					var newModel = _Utils_update(
						model,
						{b_: drivers, b$: drivers});
					return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							hc: _Utils_update(
								routes,
								{bc: $author$project$Views$MapAssignment$Model$LoadingStarted})
						}),
					A3($author$project$Request$Route$loadRoutes, context, 1, $author$project$Views$MapAssignment$Msg$FetchRoutes));
			case 3:
				if (!msg.a.$) {
					var _v19 = msg.a.a;
					var newRoutes = _v19.a;
					var paginationData = _v19.b;
					return A6($author$project$Views$MapAssignment$Update$loadRoutes, context, model, newRoutes, paginationData, $author$project$Views$MapAssignment$Msg$FetchRoutes, $author$project$Request$Route$loadRoutes);
				} else {
					var _v20 = A2($PaackEng$elm_alert_beta$Alert$error, 'There was an error fetching the routes', model.bv);
					var alerts = _v20.a;
					var alertCmd = _v20.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bv: alerts,
								hc: _Utils_update(
									routes,
									{bc: $author$project$Views$MapAssignment$Model$LoadingNotStarted})
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$Views$MapAssignment$Msg$AlertMsg, alertCmd));
				}
			case 6:
				if (!msg.a.$) {
					var warehouses = msg.a.a;
					var filteredWarehouses = A2(
						$elm$core$List$filter,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.gs;
							},
							A2(
								$elm$core$Basics$composeR,
								$elm$core$String$toLower,
								A2(
									$elm$core$Basics$composeR,
									$elm$core$String$contains('default'),
									$elm$core$Basics$not))),
						warehouses);
					var newAutomaticRouteDialogState = function () {
						var _v22 = routes.bA;
						if (_v22.$ === 1) {
							var a = _v22.a;
							var _v23 = A4(
								$author$project$Page$AutomaticRoute$Update$update,
								context,
								$author$project$Page$AutomaticRoute$Msg$AddWarehouses(filteredWarehouses),
								a,
								filteredOrdersWithAddresses_);
							var md = _v23.a;
							return $author$project$Views$MapAssignment$Model$Opened(md);
						} else {
							var a = _v22.a;
							var _v24 = A4(
								$author$project$Page$AutomaticRoute$Update$update,
								context,
								$author$project$Page$AutomaticRoute$Msg$AddWarehouses(filteredWarehouses),
								a,
								filteredOrdersWithAddresses_);
							var md = _v24.a;
							return $author$project$Views$MapAssignment$Model$Closed(md);
						}
					}();
					var newRoutes = _Utils_update(
						routes,
						{bA: newAutomaticRouteDialogState});
					var warehouseIdForManualRoutes = function () {
						if (filteredWarehouses.b && (!filteredWarehouses.b.b)) {
							var warehouse = filteredWarehouses.a;
							return $elm$core$Maybe$Just(warehouse.fS);
						} else {
							return A2(
								$elm$core$Maybe$andThen,
								A2(
									$elm$core$Basics$composeR,
									function ($) {
										return $.he;
									},
									function ($) {
										return $.gN;
									}),
								context.dB);
						}
					}();
					var newSettings = function () {
						var settings = model.hl;
						var routeSettings = model.hl.he;
						var newRoutingSettings = _Utils_update(
							routeSettings,
							{gN: warehouseIdForManualRoutes});
						return _Utils_update(
							settings,
							{he: newRoutingSettings});
					}();
					var warehouseToZoomAt = A2($author$project$Views$MapAssignment$Update$findWarehouse, filteredWarehouses, warehouseIdForManualRoutes);
					var cmd = A2(
						$elm$core$Task$perform,
						$author$project$Views$MapAssignment$Msg$ZoomToLocation,
						$elm$core$Task$succeed(
							A2(
								$elm$core$Maybe$withDefault,
								$author$project$Config$defaultLocation,
								A2(
									$elm$core$Maybe$andThen,
									function ($) {
										return $.ga;
									},
									A2(
										$elm$core$Maybe$andThen,
										function ($) {
											return $.er;
										},
										warehouseToZoomAt)))));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hc: newRoutes, hl: newSettings, h0: filteredWarehouses}),
						cmd);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 5:
				if (!msg.a.$) {
					var warehouseCompanies = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ec: warehouseCompanies}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 4:
				if (!msg.a.$) {
					var packages = msg.a.a;
					return _Utils_Tuple2(
						A2($author$project$Views$MapAssignment$Update$updatePackagesFromList, packages, model),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 9:
				return _Utils_Tuple2(
					model,
					A2($author$project$Request$Route$createRoute, context, $author$project$Views$MapAssignment$Msg$CreateRoute));
			case 8:
				if (!msg.a.$) {
					var route = msg.a.a;
					var routeContainers = A2(
						$elm$core$List$append,
						_List_fromArray(
							[
								$author$project$Views$MapAssignment$Update$backendRouteTorouteContainer(route)
							]),
						A2(
							$elm$core$List$filter,
							function (container) {
								return !_Utils_eq(container.g9.fS, route.fS);
							},
							routes.g8));
					var newRoutes = _Utils_update(
						routes,
						{g8: routeContainers});
					var newModel = $author$project$Views$MapAssignment$Update$filterRouteContainers(
						_Utils_update(
							model,
							{hc: newRoutes}));
					return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 10:
				var subMsg = msg.a;
				var formatHour = function (x) {
					return $elm$core$String$fromInt(
						A2($elm$time$Time$toHour, zone, x));
				};
				var formatHourFromTuple = function (_v27) {
					var x = _v27.a;
					var y = _v27.b;
					return _Utils_ap(
						formatHour(x),
						formatHour(y));
				};
				var _v25 = A3($author$project$Views$Common$Select$update, subMsg, model.fG.bm, formatHourFromTuple);
				var newTimeRangeFilter = _v25.a;
				var cmd = _v25.b;
				var modelWithOrdersFiltered = A2(
					$author$project$Views$MapAssignment$Update$filterOrders,
					context,
					A2(
						$author$project$Views$MapAssignment$Update$updateFilters,
						function (filters_) {
							return _Utils_update(
								filters_,
								{bm: newTimeRangeFilter});
						},
						model));
				var filters = modelWithOrdersFiltered.fG;
				var orders = modelWithOrdersFiltered.gL;
				var filteredOrdersIds = modelWithOrdersFiltered.fF;
				var filteredOrders = A2($author$project$Views$MapAssignment$Model$orderIdsToOrders, orders, filteredOrdersIds);
				var filtersUpdated = function () {
					var otherFiltersUpdated = _Utils_update(
						filters,
						{
							b_: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								function ($) {
									return $.fS;
								},
								$elm$core$List$filterMap(
									function ($) {
										return $.fh;
									}),
								$author$project$Views$MapAssignment$Update$sortByFullName,
								filters.b_),
							fL: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$elm$core$Basics$identity,
								$author$project$Views$MapAssignment$Update$accuracyQualityMapperFn,
								$elm$core$Basics$identity,
								filters.fL),
							c6: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$elm$core$Basics$identity,
								$elm$core$List$map(
									function ($) {
										return $.gD;
									}),
								$author$project$Views$MapAssignment$Update$sortByValue,
								filters.c6),
							c8: A3($author$project$Views$MapAssignment$Update$updatePolygonFilter, ordersBeingProcessed, modelWithOrdersFiltered, filters.c8),
							gY: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$elm$core$Basics$identity,
								$author$project$Views$MapAssignment$Update$postalCodesMapperFn,
								$author$project$Views$MapAssignment$Update$sortByValue,
								filters.gY),
							g6: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								function ($) {
									return $.fS;
								},
								$elm$core$List$filterMap(
									function ($) {
										return $.eT;
									}),
								$author$project$Views$MapAssignment$Update$sortByName,
								filters.g6),
							hk: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$elm$core$Basics$identity,
								$elm$core$List$filterMap(
									function ($) {
										return $.hj;
									}),
								$author$project$Views$MapAssignment$Update$sortByValue,
								filters.hk),
							dV: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								function ($) {
									return $.fS;
								},
								$elm$core$List$filterMap(
									function ($) {
										return $.hw;
									}),
								$author$project$Views$MapAssignment$Update$sortByName,
								filters.dV)
						});
					switch (subMsg.$) {
						case 1:
							return ($elm$core$List$length(
								$author$project$Views$Common$Select$getSelectedOptions(newTimeRangeFilter)) > 0) ? otherFiltersUpdated : A3($author$project$Views$MapAssignment$Update$updateOptionsForAllFilters, zone, ordersBeingProcessed, modelWithOrdersFiltered);
						case 2:
							return A3($author$project$Views$MapAssignment$Update$updateOptionsForAllFilters, zone, ordersBeingProcessed, modelWithOrdersFiltered);
						case 4:
							return A3($author$project$Views$MapAssignment$Update$updateOptionsForAllFilters, zone, ordersBeingProcessed, modelWithOrdersFiltered);
						default:
							return otherFiltersUpdated;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						modelWithOrdersFiltered,
						{fG: filtersUpdated}),
					A2($elm$core$Platform$Cmd$map, $author$project$Views$MapAssignment$Msg$TimeRangeMsg, cmd));
			case 11:
				var subMsg = msg.a;
				var filters = model.fG;
				var _v28 = A3($author$project$Views$Common$Select$update, subMsg, filters.gY, $elm$core$Basics$identity);
				var newPostalCodeFilter = _v28.a;
				var cmd = _v28.b;
				var postalCodeFilterUpdated = _Utils_update(
					filters,
					{gY: newPostalCodeFilter});
				var modelWithOrdersFiltered = A2(
					$author$project$Views$MapAssignment$Update$filterOrders,
					context,
					_Utils_update(
						model,
						{fG: postalCodeFilterUpdated}));
				var filteredOrders = A2($author$project$Views$MapAssignment$Model$orderIdsToOrders, modelWithOrdersFiltered.gL, modelWithOrdersFiltered.fF);
				var filtersUpdated = function () {
					var otherFiltersUpdated = _Utils_update(
						postalCodeFilterUpdated,
						{
							b_: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								function ($) {
									return $.fS;
								},
								$elm$core$List$filterMap(
									function ($) {
										return $.fh;
									}),
								$author$project$Views$MapAssignment$Update$sortByFullName,
								filters.b_),
							fL: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$elm$core$Basics$identity,
								$author$project$Views$MapAssignment$Update$accuracyQualityMapperFn,
								$elm$core$Basics$identity,
								filters.fL),
							c6: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$elm$core$Basics$identity,
								$elm$core$List$map(
									function ($) {
										return $.gD;
									}),
								$author$project$Views$MapAssignment$Update$sortByValue,
								filters.c6),
							c8: A3($author$project$Views$MapAssignment$Update$updatePolygonFilter, ordersBeingProcessed, modelWithOrdersFiltered, modelWithOrdersFiltered.fG.c8),
							g6: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								function ($) {
									return $.fS;
								},
								$elm$core$List$filterMap(
									function ($) {
										return $.eT;
									}),
								$author$project$Views$MapAssignment$Update$sortByName,
								filters.g6),
							hk: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$elm$core$Basics$identity,
								$elm$core$List$filterMap(
									function ($) {
										return $.hj;
									}),
								$author$project$Views$MapAssignment$Update$sortByValue,
								filters.hk),
							dV: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								function ($) {
									return $.fS;
								},
								$elm$core$List$filterMap(
									function ($) {
										return $.hw;
									}),
								$author$project$Views$MapAssignment$Update$sortByName,
								filters.dV),
							bm: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$author$project$Views$MapAssignment$Update$uniqueHourTimeSlot(zone),
								$elm$core$List$map(
									function (o) {
										return _Utils_Tuple2(o.e7.hq, o.e7.fp);
									}),
								$author$project$Views$MapAssignment$Update$sortTimeslotOptions(zone),
								filters.bm)
						});
					switch (subMsg.$) {
						case 1:
							return ($elm$core$List$length(
								$author$project$Views$Common$Select$getSelectedOptions(newPostalCodeFilter)) > 0) ? otherFiltersUpdated : A3($author$project$Views$MapAssignment$Update$updateOptionsForAllFilters, zone, ordersBeingProcessed, modelWithOrdersFiltered);
						case 2:
							return A3($author$project$Views$MapAssignment$Update$updateOptionsForAllFilters, zone, ordersBeingProcessed, modelWithOrdersFiltered);
						case 4:
							return A3($author$project$Views$MapAssignment$Update$updateOptionsForAllFilters, zone, ordersBeingProcessed, modelWithOrdersFiltered);
						default:
							return otherFiltersUpdated;
					}
				}();
				var _v29 = filtersUpdated;
				var packages = _v29.c6;
				var drivers = _v29.b_;
				var retailers = _v29.g6;
				var stores = _v29.dV;
				var serviceTypes = _v29.hk;
				var geoLocationAccuracy = _v29.fL;
				var closedDrivers = _Utils_update(
					drivers,
					{T: false});
				var closedAddressAccuracy = _Utils_update(
					geoLocationAccuracy,
					{T: false});
				var closedPackages = _Utils_update(
					packages,
					{T: false});
				var closedRetailers = _Utils_update(
					retailers,
					{T: false});
				var closedServiceTypes = _Utils_update(
					serviceTypes,
					{T: false});
				var closedStores = _Utils_update(
					stores,
					{T: false});
				var filtersClosedAndUpdated = _Utils_update(
					filtersUpdated,
					{b_: closedDrivers, fL: closedAddressAccuracy, c6: closedPackages, g6: closedRetailers, hk: closedServiceTypes, dV: closedStores});
				return _Utils_Tuple2(
					_Utils_update(
						modelWithOrdersFiltered,
						{fG: filtersClosedAndUpdated}),
					A2($elm$core$Platform$Cmd$map, $author$project$Views$MapAssignment$Msg$PostalCodeMsg, cmd));
			case 16:
				var subMsg = msg.a;
				var filters = model.fG;
				var _v31 = A3($author$project$Views$Common$Select$update, subMsg, filters.hk, $elm$core$Basics$identity);
				var newServiceTypesFilter = _v31.a;
				var cmd = _v31.b;
				var serviceTypeFilterUpdated = _Utils_update(
					filters,
					{hk: newServiceTypesFilter});
				var modelWithOrdersFiltered = A2(
					$author$project$Views$MapAssignment$Update$filterOrders,
					context,
					_Utils_update(
						model,
						{fG: serviceTypeFilterUpdated}));
				var filteredOrdersIds = modelWithOrdersFiltered.fF;
				var orders = modelWithOrdersFiltered.gL;
				var filteredOrders = A2($author$project$Views$MapAssignment$Model$orderIdsToOrders, orders, filteredOrdersIds);
				var filtersUpdated = function () {
					var otherFiltersUpdated = _Utils_update(
						serviceTypeFilterUpdated,
						{
							b_: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								function ($) {
									return $.fS;
								},
								$elm$core$List$filterMap(
									function ($) {
										return $.fh;
									}),
								$author$project$Views$MapAssignment$Update$sortByFullName,
								filters.b_),
							fL: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$elm$core$Basics$identity,
								$author$project$Views$MapAssignment$Update$accuracyQualityMapperFn,
								$elm$core$Basics$identity,
								filters.fL),
							c6: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$elm$core$Basics$identity,
								$elm$core$List$map(
									function ($) {
										return $.gD;
									}),
								$author$project$Views$MapAssignment$Update$sortByValue,
								filters.c6),
							c8: A3($author$project$Views$MapAssignment$Update$updatePolygonFilter, ordersBeingProcessed, modelWithOrdersFiltered, modelWithOrdersFiltered.fG.c8),
							gY: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$elm$core$Basics$identity,
								$author$project$Views$MapAssignment$Update$postalCodesMapperFn,
								$author$project$Views$MapAssignment$Update$sortByValue,
								filters.gY),
							g6: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								function ($) {
									return $.fS;
								},
								$elm$core$List$filterMap(
									function ($) {
										return $.eT;
									}),
								$author$project$Views$MapAssignment$Update$sortByName,
								filters.g6),
							dV: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								function ($) {
									return $.fS;
								},
								$elm$core$List$filterMap(
									function ($) {
										return $.hw;
									}),
								$author$project$Views$MapAssignment$Update$sortByName,
								filters.dV),
							bm: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$author$project$Views$MapAssignment$Update$uniqueHourTimeSlot(zone),
								$elm$core$List$map(
									function (o) {
										return _Utils_Tuple2(o.e7.hq, o.e7.fp);
									}),
								$author$project$Views$MapAssignment$Update$sortTimeslotOptions(zone),
								filters.bm)
						});
					switch (subMsg.$) {
						case 1:
							return ($elm$core$List$length(
								$author$project$Views$Common$Select$getSelectedOptions(newServiceTypesFilter)) > 0) ? otherFiltersUpdated : A3($author$project$Views$MapAssignment$Update$updateOptionsForAllFilters, zone, ordersBeingProcessed, modelWithOrdersFiltered);
						case 2:
							return A3($author$project$Views$MapAssignment$Update$updateOptionsForAllFilters, zone, ordersBeingProcessed, modelWithOrdersFiltered);
						case 4:
							return A3($author$project$Views$MapAssignment$Update$updateOptionsForAllFilters, zone, ordersBeingProcessed, modelWithOrdersFiltered);
						default:
							return otherFiltersUpdated;
					}
				}();
				var _v32 = filtersUpdated;
				var packages = _v32.c6;
				var drivers = _v32.b_;
				var retailers = _v32.g6;
				var stores = _v32.dV;
				var postalCodes = _v32.gY;
				var geoLocationAccuracy = _v32.fL;
				var closedDrivers = _Utils_update(
					drivers,
					{T: false});
				var closedAddressAccuracy = _Utils_update(
					geoLocationAccuracy,
					{T: false});
				var closedPackages = _Utils_update(
					packages,
					{T: false});
				var closedPostalCodes = _Utils_update(
					postalCodes,
					{T: false});
				var closedRetailers = _Utils_update(
					retailers,
					{T: false});
				var closedStores = _Utils_update(
					stores,
					{T: false});
				var filtersUpdatedAndClosed = _Utils_update(
					filtersUpdated,
					{b_: closedDrivers, fL: closedAddressAccuracy, c6: closedPackages, gY: closedPostalCodes, g6: closedRetailers, dV: closedStores});
				return _Utils_Tuple2(
					_Utils_update(
						modelWithOrdersFiltered,
						{fG: filtersUpdatedAndClosed}),
					A2($elm$core$Platform$Cmd$map, $author$project$Views$MapAssignment$Msg$ServiceTypeMsg, cmd));
			case 17:
				var subMsg = msg.a;
				var filters = model.fG;
				var _v34 = A3($author$project$Views$Common$Select$update, subMsg, filters.fL, $author$project$Data$Address$accuracyQualityToString);
				var newAccuracyQualityFilter = _v34.a;
				var cmd = _v34.b;
				var accuracyQualityFilterUpdated = _Utils_update(
					filters,
					{fL: newAccuracyQualityFilter});
				var modelWithOrdersFiltered = A2(
					$author$project$Views$MapAssignment$Update$filterOrders,
					context,
					_Utils_update(
						model,
						{fG: accuracyQualityFilterUpdated}));
				var filteredOrdersIds = modelWithOrdersFiltered.fF;
				var orders = modelWithOrdersFiltered.gL;
				var filteredOrders = A2($author$project$Views$MapAssignment$Model$orderIdsToOrders, orders, filteredOrdersIds);
				var filtersUpdated = function () {
					var otherFiltersUpdated = _Utils_update(
						accuracyQualityFilterUpdated,
						{
							b_: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								function ($) {
									return $.fS;
								},
								$elm$core$List$filterMap(
									function ($) {
										return $.fh;
									}),
								$author$project$Views$MapAssignment$Update$sortByFullName,
								filters.b_),
							c6: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$elm$core$Basics$identity,
								$elm$core$List$map(
									function ($) {
										return $.gD;
									}),
								$author$project$Views$MapAssignment$Update$sortByValue,
								filters.c6),
							c8: A3($author$project$Views$MapAssignment$Update$updatePolygonFilter, ordersBeingProcessed, modelWithOrdersFiltered, modelWithOrdersFiltered.fG.c8),
							gY: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$elm$core$Basics$identity,
								$author$project$Views$MapAssignment$Update$postalCodesMapperFn,
								$author$project$Views$MapAssignment$Update$sortByValue,
								filters.gY),
							g6: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								function ($) {
									return $.fS;
								},
								$elm$core$List$filterMap(
									function ($) {
										return $.eT;
									}),
								$author$project$Views$MapAssignment$Update$sortByName,
								filters.g6),
							hk: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$elm$core$Basics$identity,
								$elm$core$List$filterMap(
									function ($) {
										return $.hj;
									}),
								$author$project$Views$MapAssignment$Update$sortByValue,
								filters.hk),
							dV: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								function ($) {
									return $.fS;
								},
								$elm$core$List$filterMap(
									function ($) {
										return $.hw;
									}),
								$author$project$Views$MapAssignment$Update$sortByName,
								filters.dV),
							bm: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$author$project$Views$MapAssignment$Update$uniqueHourTimeSlot(zone),
								$elm$core$List$map(
									function (o) {
										return _Utils_Tuple2(o.e7.hq, o.e7.fp);
									}),
								$author$project$Views$MapAssignment$Update$sortTimeslotOptions(zone),
								filters.bm)
						});
					switch (subMsg.$) {
						case 1:
							return ($elm$core$List$length(
								$author$project$Views$Common$Select$getSelectedOptions(newAccuracyQualityFilter)) > 0) ? otherFiltersUpdated : A3($author$project$Views$MapAssignment$Update$updateOptionsForAllFilters, zone, ordersBeingProcessed, modelWithOrdersFiltered);
						case 2:
							return A3($author$project$Views$MapAssignment$Update$updateOptionsForAllFilters, zone, ordersBeingProcessed, modelWithOrdersFiltered);
						case 4:
							return A3($author$project$Views$MapAssignment$Update$updateOptionsForAllFilters, zone, ordersBeingProcessed, modelWithOrdersFiltered);
						default:
							return otherFiltersUpdated;
					}
				}();
				var _v35 = filtersUpdated;
				var packages = _v35.c6;
				var drivers = _v35.b_;
				var retailers = _v35.g6;
				var stores = _v35.dV;
				var postalCodes = _v35.gY;
				var serviceTypes = _v35.hk;
				var closedDrivers = _Utils_update(
					drivers,
					{T: false});
				var closedPackages = _Utils_update(
					packages,
					{T: false});
				var closedPostalCodes = _Utils_update(
					postalCodes,
					{T: false});
				var closedRetailers = _Utils_update(
					retailers,
					{T: false});
				var closedServiceTypes = _Utils_update(
					serviceTypes,
					{T: false});
				var closedStores = _Utils_update(
					stores,
					{T: false});
				var filtersUpdatedAndClosed = _Utils_update(
					filtersUpdated,
					{b_: closedDrivers, c6: closedPackages, gY: closedPostalCodes, g6: closedRetailers, hk: closedServiceTypes, dV: closedStores});
				return _Utils_Tuple2(
					_Utils_update(
						modelWithOrdersFiltered,
						{fG: filtersUpdatedAndClosed}),
					A2($elm$core$Platform$Cmd$map, $author$project$Views$MapAssignment$Msg$AccuracyQualityMsg, cmd));
			case 12:
				var subMsg = msg.a;
				var filters = model.fG;
				var _v37 = A3($author$project$Views$Common$Select$update, subMsg, filters.c6, $elm$core$String$fromInt);
				var newPackagesFilter = _v37.a;
				var cmd = _v37.b;
				var packagesFilterUpdated = _Utils_update(
					filters,
					{c6: newPackagesFilter});
				var modelWithOrdersFiltered = A2(
					$author$project$Views$MapAssignment$Update$filterOrders,
					context,
					_Utils_update(
						model,
						{fG: packagesFilterUpdated}));
				var filteredOrdersIds = modelWithOrdersFiltered.fF;
				var orders = modelWithOrdersFiltered.gL;
				var filteredOrders = A2($author$project$Views$MapAssignment$Model$orderIdsToOrders, orders, filteredOrdersIds);
				var filtersUpdated = function () {
					var otherFiltersUpdated = _Utils_update(
						packagesFilterUpdated,
						{
							b_: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								function ($) {
									return $.fS;
								},
								$elm$core$List$filterMap(
									function ($) {
										return $.fh;
									}),
								$author$project$Views$MapAssignment$Update$sortByFullName,
								filters.b_),
							fL: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$elm$core$Basics$identity,
								$author$project$Views$MapAssignment$Update$accuracyQualityMapperFn,
								$elm$core$Basics$identity,
								filters.fL),
							c8: A3($author$project$Views$MapAssignment$Update$updatePolygonFilter, ordersBeingProcessed, modelWithOrdersFiltered, modelWithOrdersFiltered.fG.c8),
							gY: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$elm$core$Basics$identity,
								$author$project$Views$MapAssignment$Update$postalCodesMapperFn,
								$author$project$Views$MapAssignment$Update$sortByValue,
								filters.gY),
							g6: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								function ($) {
									return $.fS;
								},
								$elm$core$List$filterMap(
									function ($) {
										return $.eT;
									}),
								$author$project$Views$MapAssignment$Update$sortByName,
								filters.g6),
							hk: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$elm$core$Basics$identity,
								$elm$core$List$filterMap(
									function ($) {
										return $.hj;
									}),
								$author$project$Views$MapAssignment$Update$sortByValue,
								filters.hk),
							dV: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								function ($) {
									return $.fS;
								},
								$elm$core$List$filterMap(
									function ($) {
										return $.hw;
									}),
								$author$project$Views$MapAssignment$Update$sortByName,
								filters.dV),
							bm: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$author$project$Views$MapAssignment$Update$uniqueHourTimeSlot(zone),
								$elm$core$List$map(
									function (o) {
										return _Utils_Tuple2(o.e7.hq, o.e7.fp);
									}),
								$author$project$Views$MapAssignment$Update$sortTimeslotOptions(zone),
								filters.bm)
						});
					switch (subMsg.$) {
						case 1:
							return ($elm$core$List$length(
								$author$project$Views$Common$Select$getSelectedOptions(newPackagesFilter)) > 0) ? otherFiltersUpdated : A3($author$project$Views$MapAssignment$Update$updateOptionsForAllFilters, zone, ordersBeingProcessed, modelWithOrdersFiltered);
						case 2:
							return A3($author$project$Views$MapAssignment$Update$updateOptionsForAllFilters, zone, ordersBeingProcessed, modelWithOrdersFiltered);
						case 4:
							return A3($author$project$Views$MapAssignment$Update$updateOptionsForAllFilters, zone, ordersBeingProcessed, modelWithOrdersFiltered);
						default:
							return otherFiltersUpdated;
					}
				}();
				var _v38 = filtersUpdated;
				var postalCodes = _v38.gY;
				var drivers = _v38.b_;
				var retailers = _v38.g6;
				var stores = _v38.dV;
				var serviceTypes = _v38.hk;
				var geoLocationAccuracy = _v38.fL;
				var closedDrivers = _Utils_update(
					drivers,
					{T: false});
				var closedAddressAccuracy = _Utils_update(
					geoLocationAccuracy,
					{T: false});
				var closedPostalCodes = _Utils_update(
					postalCodes,
					{T: false});
				var closedRetailers = _Utils_update(
					retailers,
					{T: false});
				var closedServiceTypes = _Utils_update(
					serviceTypes,
					{T: false});
				var closedStores = _Utils_update(
					stores,
					{T: false});
				var filtersClosedAndUpdated = _Utils_update(
					filtersUpdated,
					{b_: closedDrivers, fL: closedAddressAccuracy, gY: closedPostalCodes, g6: closedRetailers, hk: closedServiceTypes, dV: closedStores});
				return _Utils_Tuple2(
					_Utils_update(
						modelWithOrdersFiltered,
						{fG: filtersClosedAndUpdated}),
					A2($elm$core$Platform$Cmd$map, $author$project$Views$MapAssignment$Msg$PackagesMsg, cmd));
			case 13:
				var subMsg = msg.a;
				var filters = model.fG;
				var _v40 = A3(
					$author$project$Views$Common$Select$update,
					subMsg,
					filters.b_,
					function ($) {
						return $.fJ;
					});
				var newDriversFilter = _v40.a;
				var cmd = _v40.b;
				var driversFilterUpdated = _Utils_update(
					filters,
					{b_: newDriversFilter});
				var modelWithOrdersFiltered = A2(
					$author$project$Views$MapAssignment$Update$filterOrders,
					context,
					_Utils_update(
						model,
						{fG: driversFilterUpdated}));
				var filteredOrdersIds = modelWithOrdersFiltered.fF;
				var orders = modelWithOrdersFiltered.gL;
				var filteredOrders = A2($author$project$Views$MapAssignment$Model$orderIdsToOrders, orders, filteredOrdersIds);
				var filtersUpdated = function () {
					var otherFiltersUpdated = _Utils_update(
						driversFilterUpdated,
						{
							fL: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$elm$core$Basics$identity,
								$author$project$Views$MapAssignment$Update$accuracyQualityMapperFn,
								$elm$core$Basics$identity,
								filters.fL),
							c6: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$elm$core$Basics$identity,
								$elm$core$List$map(
									function ($) {
										return $.gD;
									}),
								$author$project$Views$MapAssignment$Update$sortByValue,
								filters.c6),
							c8: A3($author$project$Views$MapAssignment$Update$updatePolygonFilter, ordersBeingProcessed, modelWithOrdersFiltered, modelWithOrdersFiltered.fG.c8),
							gY: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$elm$core$Basics$identity,
								$author$project$Views$MapAssignment$Update$postalCodesMapperFn,
								$author$project$Views$MapAssignment$Update$sortByValue,
								filters.gY),
							g6: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								function ($) {
									return $.fS;
								},
								$elm$core$List$filterMap(
									function ($) {
										return $.eT;
									}),
								$author$project$Views$MapAssignment$Update$sortByName,
								filters.g6),
							hk: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$elm$core$Basics$identity,
								$elm$core$List$filterMap(
									function ($) {
										return $.hj;
									}),
								$author$project$Views$MapAssignment$Update$sortByValue,
								filters.hk),
							dV: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								function ($) {
									return $.fS;
								},
								$elm$core$List$filterMap(
									function ($) {
										return $.hw;
									}),
								$author$project$Views$MapAssignment$Update$sortByName,
								filters.dV),
							bm: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$author$project$Views$MapAssignment$Update$uniqueHourTimeSlot(zone),
								$elm$core$List$map(
									function (o) {
										return _Utils_Tuple2(o.e7.hq, o.e7.fp);
									}),
								$author$project$Views$MapAssignment$Update$sortTimeslotOptions(zone),
								filters.bm)
						});
					switch (subMsg.$) {
						case 1:
							return ($elm$core$List$length(
								$author$project$Views$Common$Select$getSelectedOptions(newDriversFilter)) > 0) ? otherFiltersUpdated : A3($author$project$Views$MapAssignment$Update$updateOptionsForAllFilters, zone, ordersBeingProcessed, modelWithOrdersFiltered);
						case 2:
							return A3($author$project$Views$MapAssignment$Update$updateOptionsForAllFilters, zone, ordersBeingProcessed, modelWithOrdersFiltered);
						case 4:
							return A3($author$project$Views$MapAssignment$Update$updateOptionsForAllFilters, zone, ordersBeingProcessed, modelWithOrdersFiltered);
						default:
							return otherFiltersUpdated;
					}
				}();
				var _v41 = filtersUpdated;
				var postalCodes = _v41.gY;
				var packages = _v41.c6;
				var retailers = _v41.g6;
				var stores = _v41.dV;
				var serviceTypes = _v41.hk;
				var geoLocationAccuracy = _v41.fL;
				var closedAddressAccuracy = _Utils_update(
					geoLocationAccuracy,
					{T: false});
				var closedPackages = _Utils_update(
					packages,
					{T: false});
				var closedPostalCodes = _Utils_update(
					postalCodes,
					{T: false});
				var closedRetailers = _Utils_update(
					retailers,
					{T: false});
				var closedServiceTypes = _Utils_update(
					serviceTypes,
					{T: false});
				var closedStores = _Utils_update(
					stores,
					{T: false});
				var filtersClosedAndUpdated = _Utils_update(
					filtersUpdated,
					{fL: closedAddressAccuracy, c6: closedPackages, gY: closedPostalCodes, g6: closedRetailers, hk: closedServiceTypes, dV: closedStores});
				return _Utils_Tuple2(
					_Utils_update(
						modelWithOrdersFiltered,
						{fG: filtersClosedAndUpdated}),
					A2($elm$core$Platform$Cmd$map, $author$project$Views$MapAssignment$Msg$DriversMsg, cmd));
			case 14:
				var subMsg = msg.a;
				var filters = model.fG;
				var _v43 = A3(
					$author$project$Views$Common$Select$update,
					subMsg,
					filters.g6,
					function ($) {
						return $.gs;
					});
				var newRetailersFilter = _v43.a;
				var cmd = _v43.b;
				var retailersFilterUpdated = _Utils_update(
					filters,
					{g6: newRetailersFilter});
				var modelWithOrdersFiltered = A2(
					$author$project$Views$MapAssignment$Update$filterOrders,
					context,
					_Utils_update(
						model,
						{fG: retailersFilterUpdated}));
				var filteredOrdersIds = modelWithOrdersFiltered.fF;
				var orders = modelWithOrdersFiltered.gL;
				var filteredOrders = A2($author$project$Views$MapAssignment$Model$orderIdsToOrders, orders, filteredOrdersIds);
				var filtersUpdated = function () {
					var otherFiltersUpdated = _Utils_update(
						retailersFilterUpdated,
						{
							b_: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								function ($) {
									return $.fS;
								},
								$elm$core$List$filterMap(
									function ($) {
										return $.fh;
									}),
								$author$project$Views$MapAssignment$Update$sortByFullName,
								filters.b_),
							fL: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$elm$core$Basics$identity,
								$author$project$Views$MapAssignment$Update$accuracyQualityMapperFn,
								$elm$core$Basics$identity,
								filters.fL),
							c6: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$elm$core$Basics$identity,
								$elm$core$List$map(
									function ($) {
										return $.gD;
									}),
								$author$project$Views$MapAssignment$Update$sortByValue,
								filters.c6),
							c8: A3($author$project$Views$MapAssignment$Update$updatePolygonFilter, ordersBeingProcessed, modelWithOrdersFiltered, modelWithOrdersFiltered.fG.c8),
							gY: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$elm$core$Basics$identity,
								$author$project$Views$MapAssignment$Update$postalCodesMapperFn,
								$author$project$Views$MapAssignment$Update$sortByValue,
								filters.gY),
							hk: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$elm$core$Basics$identity,
								$elm$core$List$filterMap(
									function ($) {
										return $.hj;
									}),
								$author$project$Views$MapAssignment$Update$sortByValue,
								filters.hk),
							dV: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								function ($) {
									return $.fS;
								},
								$elm$core$List$filterMap(
									function ($) {
										return $.hw;
									}),
								$author$project$Views$MapAssignment$Update$sortByName,
								filters.dV),
							bm: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$author$project$Views$MapAssignment$Update$uniqueHourTimeSlot(zone),
								$elm$core$List$map(
									function (o) {
										return _Utils_Tuple2(o.e7.hq, o.e7.fp);
									}),
								$author$project$Views$MapAssignment$Update$sortTimeslotOptions(zone),
								filters.bm)
						});
					switch (subMsg.$) {
						case 1:
							return ($elm$core$List$length(
								$author$project$Views$Common$Select$getSelectedOptions(newRetailersFilter)) > 0) ? otherFiltersUpdated : A3($author$project$Views$MapAssignment$Update$updateOptionsForAllFilters, zone, ordersBeingProcessed, modelWithOrdersFiltered);
						case 2:
							return A3($author$project$Views$MapAssignment$Update$updateOptionsForAllFilters, zone, ordersBeingProcessed, modelWithOrdersFiltered);
						case 4:
							return A3($author$project$Views$MapAssignment$Update$updateOptionsForAllFilters, zone, ordersBeingProcessed, modelWithOrdersFiltered);
						default:
							return otherFiltersUpdated;
					}
				}();
				var _v44 = filtersUpdated;
				var postalCodes = _v44.gY;
				var packages = _v44.c6;
				var drivers = _v44.b_;
				var stores = _v44.dV;
				var serviceTypes = _v44.hk;
				var geoLocationAccuracy = _v44.fL;
				var closedDrivers = _Utils_update(
					drivers,
					{T: false});
				var closedAddressAccuracy = _Utils_update(
					geoLocationAccuracy,
					{T: false});
				var closedPackages = _Utils_update(
					packages,
					{T: false});
				var closedPostalCodes = _Utils_update(
					postalCodes,
					{T: false});
				var closedServiceTypes = _Utils_update(
					serviceTypes,
					{T: false});
				var closedStores = _Utils_update(
					stores,
					{T: false});
				var filtersClosedAndUpdated = _Utils_update(
					filtersUpdated,
					{b_: closedDrivers, fL: closedAddressAccuracy, c6: closedPackages, gY: closedPostalCodes, hk: closedServiceTypes, dV: closedStores});
				return _Utils_Tuple2(
					_Utils_update(
						modelWithOrdersFiltered,
						{fG: filtersClosedAndUpdated}),
					A2($elm$core$Platform$Cmd$map, $author$project$Views$MapAssignment$Msg$RetailersMsg, cmd));
			case 15:
				var subMsg = msg.a;
				var filters = model.fG;
				var _v46 = A3(
					$author$project$Views$Common$Select$update,
					subMsg,
					filters.dV,
					function ($) {
						return $.gs;
					});
				var newStoresFilter = _v46.a;
				var cmd = _v46.b;
				var storesFilterUpdated = _Utils_update(
					filters,
					{dV: newStoresFilter});
				var modelWithOrdersFiltered = A2(
					$author$project$Views$MapAssignment$Update$filterOrders,
					context,
					_Utils_update(
						model,
						{fG: storesFilterUpdated}));
				var filteredOrdersIds = modelWithOrdersFiltered.fF;
				var orders = modelWithOrdersFiltered.gL;
				var filteredOrders = A2($author$project$Views$MapAssignment$Model$orderIdsToOrders, orders, filteredOrdersIds);
				var filtersUpdated = function () {
					var otherFiltersUpdated = _Utils_update(
						storesFilterUpdated,
						{
							b_: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								function ($) {
									return $.fS;
								},
								$elm$core$List$filterMap(
									function ($) {
										return $.fh;
									}),
								$author$project$Views$MapAssignment$Update$sortByFullName,
								filters.b_),
							fL: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$elm$core$Basics$identity,
								$author$project$Views$MapAssignment$Update$accuracyQualityMapperFn,
								$elm$core$Basics$identity,
								filters.fL),
							c6: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$elm$core$Basics$identity,
								$elm$core$List$map(
									function ($) {
										return $.gD;
									}),
								$author$project$Views$MapAssignment$Update$sortByValue,
								filters.c6),
							c8: A3($author$project$Views$MapAssignment$Update$updatePolygonFilter, ordersBeingProcessed, modelWithOrdersFiltered, modelWithOrdersFiltered.fG.c8),
							gY: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$elm$core$Basics$identity,
								$author$project$Views$MapAssignment$Update$postalCodesMapperFn,
								$author$project$Views$MapAssignment$Update$sortByValue,
								filters.gY),
							g6: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								function ($) {
									return $.fS;
								},
								$elm$core$List$filterMap(
									function ($) {
										return $.eT;
									}),
								$author$project$Views$MapAssignment$Update$sortByName,
								filters.g6),
							hk: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$elm$core$Basics$identity,
								$elm$core$List$filterMap(
									function ($) {
										return $.hj;
									}),
								$author$project$Views$MapAssignment$Update$sortByValue,
								filters.hk),
							bm: A6(
								$author$project$Views$MapAssignment$Update$updateFilter,
								$elm$core$Maybe$Just(subMsg),
								filteredOrders,
								$author$project$Views$MapAssignment$Update$uniqueHourTimeSlot(zone),
								$elm$core$List$map(
									function (o) {
										return _Utils_Tuple2(o.e7.hq, o.e7.fp);
									}),
								$author$project$Views$MapAssignment$Update$sortTimeslotOptions(zone),
								filters.bm)
						});
					switch (subMsg.$) {
						case 1:
							return ($elm$core$List$length(
								$author$project$Views$Common$Select$getSelectedOptions(newStoresFilter)) > 0) ? otherFiltersUpdated : A3($author$project$Views$MapAssignment$Update$updateOptionsForAllFilters, zone, ordersBeingProcessed, modelWithOrdersFiltered);
						case 2:
							return A3($author$project$Views$MapAssignment$Update$updateOptionsForAllFilters, zone, ordersBeingProcessed, modelWithOrdersFiltered);
						case 4:
							return A3($author$project$Views$MapAssignment$Update$updateOptionsForAllFilters, zone, ordersBeingProcessed, modelWithOrdersFiltered);
						default:
							return otherFiltersUpdated;
					}
				}();
				var _v47 = filtersUpdated;
				var postalCodes = _v47.gY;
				var packages = _v47.c6;
				var drivers = _v47.b_;
				var retailers = _v47.g6;
				var serviceTypes = _v47.hk;
				var geoLocationAccuracy = _v47.fL;
				var closedDrivers = _Utils_update(
					drivers,
					{T: false});
				var closedAddressAccuracy = _Utils_update(
					geoLocationAccuracy,
					{T: false});
				var closedPackages = _Utils_update(
					packages,
					{T: false});
				var closedPostalCodes = _Utils_update(
					postalCodes,
					{T: false});
				var closedRetailers = _Utils_update(
					retailers,
					{T: false});
				var closedServiceTypes = _Utils_update(
					serviceTypes,
					{T: false});
				var filtersClosedAndUpdated = _Utils_update(
					filtersUpdated,
					{b_: closedDrivers, fL: closedAddressAccuracy, c6: closedPackages, gY: closedPostalCodes, g6: closedRetailers, hk: closedServiceTypes});
				return _Utils_Tuple2(
					_Utils_update(
						modelWithOrdersFiltered,
						{fG: filtersClosedAndUpdated}),
					A2($elm$core$Platform$Cmd$map, $author$project$Views$MapAssignment$Msg$StoresMsg, cmd));
			case 18:
				var routeContainer = msg.a;
				var selectedRouteContainer = function () {
					var _v52 = routes.bd;
					if (_v52.$ === 1) {
						return $elm$core$Maybe$Just(routeContainer);
					} else {
						var container = _v52.a;
						return A2($author$project$Views$MapAssignment$RouteContainer$isSameRouteContainer, container, routeContainer) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(routeContainer);
					}
				}();
				var routeContainers = A2(
					$elm$core$List$map,
					function (container) {
						var _v51 = container.ha;
						if (_v51.$ === 2) {
							return _Utils_update(
								container,
								{ha: $author$project$Views$MapAssignment$RouteContainer$UnAssigned});
						} else {
							return container;
						}
					},
					routes.g8);
				var newRoutes = _Utils_update(
					routes,
					{g8: routeContainers, bd: selectedRouteContainer});
				var newMap = function () {
					if (!selectedRouteContainer.$) {
						var container = selectedRouteContainer.a;
						return $elm$core$List$isEmpty(container.g9.gL) ? maps : _Utils_update(
							maps,
							{
								bR: $author$project$Views$MapAssignment$Update$getFirstOrderLocation(container),
								h4: 13
							});
					} else {
						return maps;
					}
				}();
				var newModel = _Utils_update(
					model,
					{
						cg: _List_Nil,
						gh: _Utils_update(
							newMap,
							{
								fY: _Utils_eq(selectedRouteContainer, $elm$core$Maybe$Nothing) ? false : model.gh.fY
							}),
						c$: $elm$core$Maybe$Nothing,
						hc: newRoutes
					});
				var cmd = function () {
					if (selectedRouteContainer.$ === 1) {
						return $elm$core$Platform$Cmd$none;
					} else {
						var container = selectedRouteContainer.a;
						return A3(
							$author$project$Request$Route$loadRouteData,
							context,
							container.g9.fS,
							$author$project$Views$MapAssignment$Msg$FetchRouteData(container.g9.fS));
					}
				}();
				return _Utils_Tuple2(newModel, cmd);
			case 21:
				var routeContainer = msg.a;
				var orders = A2(
					$elm$core$List$map,
					function ($) {
						return $.cZ;
					},
					routeContainer.g9.gL);
				var maybeNewRouteContainer = A2(
					$elm$core$Maybe$map,
					function (selected) {
						return _Utils_update(
							selected,
							{dA: $author$project$Views$MapAssignment$RouteContainer$SaveInProgress});
					},
					routes.bd);
				var newModel = _Utils_update(
					model,
					{
						hc: _Utils_update(
							routes,
							{
								b9: A2($author$project$Views$MapAssignment$RouteContainer$maybeReplaceRouteContainer, maybeNewRouteContainer, routes.b9),
								g8: A2($author$project$Views$MapAssignment$RouteContainer$maybeReplaceRouteContainer, maybeNewRouteContainer, routes.g8)
							})
					});
				var cmd = A4(
					$author$project$Request$Route$setOrdersToRoute,
					context,
					routeContainer.g9.fS,
					orders,
					$author$project$Views$MapAssignment$Msg$AddOrderToRoute(routeContainer));
				return _Utils_Tuple2(newModel, cmd);
			case 22:
				var orders = msg.a;
				var newModel = A3($author$project$Views$MapAssignment$Update$addOrdersToSelectedRouteContainer, context, orders, model);
				return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			case 23:
				var orders = msg.a;
				var maybeNewRouteContainer = A2(
					$elm$core$Maybe$map,
					function (container) {
						var oldCurrentRoute = container.g9;
						var oldOrders = A2(
							$elm$core$List$map,
							function ($) {
								return $.cZ;
							},
							oldCurrentRoute.gL);
						var newOrders = A2(
							$elm$core$List$indexedMap,
							F2(
								function (i, o) {
									return {b7: 0, cZ: o, gW: i};
								}),
							A2(
								$elm$core$List$filter,
								function (o) {
									return !A2(
										$elm$core$List$any,
										function (order) {
											return _Utils_eq(o.fS, order.fS);
										},
										orders);
								},
								oldOrders));
						var routeData = _Utils_update(
							oldCurrentRoute,
							{gL: newOrders});
						var saved = $author$project$Views$MapAssignment$RouteContainer$Saved(
							_Utils_eq(
								$elm$core$List$length(newOrders),
								$elm$core$List$length(oldOrders)));
						return _Utils_update(
							container,
							{g9: routeData, dA: saved, ho: $elm$core$Maybe$Nothing});
					},
					routes.bd);
				var newRoutes = _Utils_update(
					routes,
					{
						b9: A2($author$project$Views$MapAssignment$RouteContainer$maybeReplaceRouteContainer, maybeNewRouteContainer, routes.b9),
						g8: A2($author$project$Views$MapAssignment$RouteContainer$maybeReplaceRouteContainer, maybeNewRouteContainer, routes.g8),
						bd: maybeNewRouteContainer
					});
				var newModel = A2(
					$author$project$Views$MapAssignment$Update$filterOrders,
					context,
					_Utils_update(
						model,
						{hc: newRoutes}));
				return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			case 25:
				var routeContainer = msg.a;
				var settings = model.hl;
				var shouldResetSelectedWarehouse = A3($author$project$Views$MapAssignment$Update$warehouseSettingNeedsReset, context, settings.he, model.h0);
				var shouldResetStartTime = A3($author$project$Views$MapAssignment$Update$startTimeNeedsReset, context, settings.he, routeContainer);
				var startTimeText = (routeContainer.dP === '') ? A2($elm$core$Maybe$withDefault, '', settings.he.ak) : routeContainer.dP;
				var originWarehouse = A2($author$project$Views$MapAssignment$Update$findWarehouse, model.h0, model.hl.he.gN);
				var requestParams = {
					bt: false,
					bE: routeContainer.bF,
					bW: $elm$core$String$toFloat(model.hl.he.bW),
					bY: true,
					b_: _List_Nil,
					cF: $elm$core$Maybe$Just(250),
					gL: A2(
						$elm$core$List$map,
						function ($) {
							return $.cZ;
						},
						routeContainer.g9.gL),
					aE: originWarehouse,
					c5: $elm$core$String$toFloat(model.hl.he.c5),
					c6: model.c6,
					ak: startTimeText,
					d6: false,
					eg: routeContainer.eh
				};
				var newRouteContainer = _Utils_update(
					routeContainer,
					{ae: $author$project$Views$MapAssignment$RouteContainer$OptimizingInProgress});
				var newRoutes = (shouldResetStartTime || shouldResetSelectedWarehouse) ? routes : _Utils_update(
					routes,
					{
						b9: A2($author$project$Views$MapAssignment$RouteContainer$replaceRouteContainer, newRouteContainer, routes.b9),
						g8: A2($author$project$Views$MapAssignment$RouteContainer$replaceRouteContainer, newRouteContainer, routes.g8)
					});
				var newModel = _Utils_update(
					model,
					{bZ: !shouldResetStartTime, hc: newRoutes});
				var _v53 = {gN: 'originWarehouseId', ak: 'startTime', hr: 'startTimeSetAt', h$: 'warehouseSelectedAt'};
				var startTime = _v53.ak;
				var startTimeSetAt = _v53.hr;
				var warehouseSelectedAt = _v53.h$;
				var originWarehouseId = _v53.gN;
				var cmds = (shouldResetStartTime && shouldResetSelectedWarehouse) ? $author$project$Ports$sendMsgToUpsideDown(
					A2(
						$author$project$Ports$SetManualRouteSettingsExpired,
						2,
						_List_fromArray(
							[startTime, startTimeSetAt, originWarehouseId, warehouseSelectedAt]))) : (shouldResetStartTime ? $author$project$Ports$sendMsgToUpsideDown(
					A2(
						$author$project$Ports$SetManualRouteSettingsExpired,
						0,
						_List_fromArray(
							[startTime, startTimeSetAt]))) : (shouldResetSelectedWarehouse ? $author$project$Ports$sendMsgToUpsideDown(
					A2(
						$author$project$Ports$SetManualRouteSettingsExpired,
						1,
						_List_fromArray(
							[originWarehouseId, warehouseSelectedAt]))) : $elm$core$Platform$Cmd$batch(
					_List_fromArray(
						[
							A4(
							$author$project$Request$RouteRequest$syncOptimizeOrders,
							context,
							routeContainer.g9.fS,
							requestParams,
							$author$project$Views$MapAssignment$Msg$SyncOptimizeRequest(routeContainer)),
							A2(
							$elm$core$Task$perform,
							function (_v54) {
								return $author$project$Views$MapAssignment$Msg$SetIfRouteCanBeReset(routeContainer.g9.fS);
							},
							$elm$core$Process$sleep(
								$author$project$Views$MapAssignment$Update$waitTimeForResetButtonToAppear(context)))
						]))));
				return _Utils_Tuple2(newModel, cmds);
			case 114:
				var data = msg.a;
				var warehouseSettingError = 'A warehouse for new routes should be selected';
				var startTimeError = 'Start time for manual routes should be set up before continuing';
				var settings = model.hl;
				var _v55 = settings;
				var routingSettings = _v55.he;
				var updatedSelectedWarehouseSetting = _Utils_update(
					routingSettings,
					{gN: $elm$core$Maybe$Nothing, h$: $elm$core$Maybe$Nothing});
				var updatedStartTimeAndWarehouseSetting = _Utils_update(
					routingSettings,
					{gN: $elm$core$Maybe$Nothing, ak: $elm$core$Maybe$Nothing, hr: $elm$core$Maybe$Nothing, h$: $elm$core$Maybe$Nothing});
				var updatedStartTimeSetting = _Utils_update(
					routingSettings,
					{ak: $elm$core$Maybe$Nothing, hr: $elm$core$Maybe$Nothing});
				var updatedSettings = function () {
					var _v56 = $author$project$Ports$decodeExpirableSetting(data);
					switch (_v56) {
						case 0:
							return _Utils_update(
								settings,
								{
									ft: A3($elm$core$Dict$insert, $author$project$Config$startTimeErrorKeyManualRouting, startTimeError, settings.ft),
									T: true,
									he: updatedStartTimeSetting
								});
						case 1:
							return _Utils_update(
								settings,
								{
									ft: A3($elm$core$Dict$insert, $author$project$Config$warehouseErrorKeyManualRouting, warehouseSettingError, settings.ft),
									T: true,
									he: updatedSelectedWarehouseSetting
								});
						case 2:
							return _Utils_update(
								settings,
								{
									ft: A3(
										$elm$core$Dict$insert,
										$author$project$Config$warehouseErrorKeyManualRouting,
										warehouseSettingError,
										A3($elm$core$Dict$insert, $author$project$Config$startTimeErrorKeyManualRouting, startTimeError, settings.ft)),
									T: true,
									he: updatedStartTimeAndWarehouseSetting
								});
						default:
							return settings;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hl: updatedSettings}),
					$elm$core$Platform$Cmd$none);
			case 28:
				var routeId = msg.a;
				var routeToBeResetMaybe = A2($author$project$Views$MapAssignment$RouteContainer$findByRouteId, routeId, routes.g8);
				var routeToBeResetUpdated = A2(
					$elm$core$Maybe$map,
					function (route) {
						return _Utils_update(
							route,
							{
								bw: ((route.g9.ht === 2) || (!route.g9.ht)) && _Utils_eq(route.ae, $author$project$Views$MapAssignment$RouteContainer$OptimizingInProgress)
							});
					},
					routeToBeResetMaybe);
				var newRoutes = _Utils_update(
					routes,
					{
						b9: A2($author$project$Views$MapAssignment$RouteContainer$maybeReplaceRouteContainer, routeToBeResetUpdated, routes.b9),
						g8: A2($author$project$Views$MapAssignment$RouteContainer$maybeReplaceRouteContainer, routeToBeResetUpdated, routes.g8)
					});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hc: newRoutes}),
					$elm$core$Platform$Cmd$none);
			case 62:
				if (!msg.b.$) {
					var routeContainer = msg.a;
					var id = msg.b.a.fS;
					var targetRoute = A2($author$project$Views$MapAssignment$RouteContainer$findByRouteId, routeContainer.g9.fS, routes.g8);
					var jobIdUpdated = A2(
						$elm$core$Maybe$map,
						function (route) {
							return _Utils_update(
								route,
								{
									f3: $elm$core$Maybe$Just(id)
								});
						},
						targetRoute);
					var newRoutes = _Utils_update(
						routes,
						{
							b9: A2($author$project$Views$MapAssignment$RouteContainer$maybeReplaceRouteContainer, jobIdUpdated, routes.b9),
							g8: A2($author$project$Views$MapAssignment$RouteContainer$maybeReplaceRouteContainer, jobIdUpdated, routes.g8)
						});
					var cmds = $elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$Ports$sendMsgToUpsideDown($author$project$Ports$SetTimerToKeepOptimizeButtonsDisabled),
								$author$project$Ports$sendMsgToUpsideDown(
								$author$project$Ports$StoreRouteJobId(
									{f3: id, du: routeContainer.g9.fS}))
							]));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hc: newRoutes}),
						cmds);
				} else {
					var routeContainer = msg.a;
					var error = msg.b.a;
					var _v57 = A2(
						$author$project$Views$MapAssignment$Update$updateRouteContainer,
						_Utils_update(
							routeContainer,
							{
								ae: $author$project$Views$MapAssignment$RouteContainer$Errored(error)
							}),
						model);
					var modelWithRouteContainerUpdated = _v57.a;
					return _Utils_Tuple2(
						_Utils_update(
							modelWithRouteContainerUpdated,
							{bZ: false}),
						$elm$core$Platform$Cmd$none);
				}
			case 115:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bZ: false}),
					$elm$core$Platform$Cmd$none);
			case 26:
				var routeId = msg.a;
				var maybeJobId = msg.b;
				return _Utils_Tuple2(
					model,
					function () {
						if (!maybeJobId.$) {
							var jobId = maybeJobId.a;
							return A4($author$project$Request$RouteRequest$resetRoute, context, routeId, jobId, $author$project$Views$MapAssignment$Msg$RouteResetResponseReceived);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 27:
				if (!msg.a.$) {
					var route = msg.a.a;
					var routeAfterReset = $author$project$Views$MapAssignment$Update$backendRouteTorouteContainer(route);
					var newRoutes = _Utils_update(
						routes,
						{
							b9: A2(
								$author$project$Views$MapAssignment$RouteContainer$maybeReplaceRouteContainer,
								$elm$core$Maybe$Just(routeAfterReset),
								routes.b9),
							g8: A2(
								$author$project$Views$MapAssignment$RouteContainer$maybeReplaceRouteContainer,
								$elm$core$Maybe$Just(routeAfterReset),
								routes.g8)
						});
					var newModel = _Utils_update(
						model,
						{hc: newRoutes});
					return _Utils_Tuple2(
						newModel,
						$author$project$Ports$sendMsgToUpsideDown(
							$author$project$Ports$DiscardRouteJobId(routeAfterReset.g9.fS)));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 29:
				var routeContainer = msg.a;
				var driver = function () {
					var _v59 = routeContainer.ha;
					switch (_v59.$) {
						case 0:
							return $elm$core$Maybe$Nothing;
						case 1:
							var d = _v59.a;
							return $elm$core$Maybe$Just(d);
						default:
							var x = _v59.a;
							return x;
					}
				}();
				var newRouteContainer = _Utils_update(
					routeContainer,
					{
						ha: $author$project$Views$MapAssignment$RouteContainer$Changing(driver)
					});
				var newRoutes = _Utils_update(
					routes,
					{
						bH: $elm$core$Maybe$Just(newRouteContainer)
					});
				var newModel = _Utils_update(
					model,
					{hc: newRoutes});
				return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			case 30:
				var routeContainer = msg.a;
				var newModel = _Utils_update(
					model,
					{
						hc: _Utils_update(
							routes,
							{bH: $elm$core$Maybe$Nothing})
					});
				var cmd = A3($author$project$Request$Route$removeRouteDriver, context, routeContainer.g9.fS, $author$project$Views$MapAssignment$Msg$AddDriverToRoute);
				return _Utils_Tuple2(newModel, cmd);
			case 31:
				var routeContainer = msg.a;
				var driver = msg.b;
				var newRoutes = _Utils_update(
					routes,
					{bH: $elm$core$Maybe$Nothing});
				var cmd = A4($author$project$Request$Route$setRouteDriver, context, routeContainer.g9.fS, driver, $author$project$Views$MapAssignment$Msg$AddDriverToRoute);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hc: newRoutes}),
					cmd);
			case 32:
				if (!msg.a.$) {
					var route = msg.a.a;
					var routeContainer = $author$project$Views$MapAssignment$Update$backendRouteTorouteContainer(route);
					var orders = A2(
						$author$project$Utils$createReplaceAllIfNew,
						$author$project$Views$MapAssignment$Model$ordersListToDict(route.gL),
						model.gL);
					var newRoutes = _Utils_update(
						routes,
						{
							bH: $elm$core$Maybe$Just(routeContainer),
							b9: A2($author$project$Views$MapAssignment$RouteContainer$replaceRouteContainer, routeContainer, routes.b9),
							g8: A2($author$project$Views$MapAssignment$RouteContainer$replaceRouteContainer, routeContainer, routes.g8)
						});
					var newModel = A2(
						$author$project$Views$MapAssignment$Update$filterOrders,
						context,
						_Utils_update(
							model,
							{gL: orders, hc: newRoutes}));
					return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 24:
				if (!msg.b.$) {
					var route = msg.b.a;
					var orders = A2(
						$author$project$Utils$createReplaceAllIfNew,
						$author$project$Views$MapAssignment$Model$ordersListToDict(route.gL),
						model.gL);
					var newRouteContainer = $author$project$Views$MapAssignment$Update$backendRouteTorouteContainer(route);
					var newRoutes = _Utils_update(
						routes,
						{
							b9: A2($author$project$Views$MapAssignment$RouteContainer$replaceRouteContainer, newRouteContainer, routes.b9),
							g8: A2($author$project$Views$MapAssignment$RouteContainer$replaceRouteContainer, newRouteContainer, routes.g8),
							bd: $elm$core$Maybe$Just(newRouteContainer)
						});
					var newModel = A2(
						$author$project$Views$MapAssignment$Update$filterOrders,
						context,
						_Utils_update(
							model,
							{gL: orders, hc: newRoutes}));
					return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
				} else {
					var routeContainer = msg.a;
					var newRouteContainer = _Utils_update(
						routeContainer,
						{
							dA: $author$project$Views$MapAssignment$RouteContainer$Saved(false)
						});
					var newRoutes = _Utils_update(
						routes,
						{
							b9: A2($author$project$Views$MapAssignment$RouteContainer$replaceRouteContainer, newRouteContainer, routes.b9),
							g8: A2($author$project$Views$MapAssignment$RouteContainer$replaceRouteContainer, newRouteContainer, routes.g8),
							bd: $elm$core$Maybe$Just(newRouteContainer)
						});
					var newModel = _Utils_update(
						model,
						{hc: newRoutes});
					return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
				}
			case 33:
				var routeContainer = msg.a;
				var order = msg.b;
				var orders = A2(
					$elm$core$List$filter,
					function (x) {
						return !_Utils_eq(x.fS, order.fS);
					},
					model.cg);
				var cmd = A4(
					$author$project$Request$Route$addOrdersToRoute,
					context,
					routeContainer.g9.fS,
					_List_fromArray(
						[order]),
					$author$project$Views$MapAssignment$Msg$AttachOrderRequest);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cg: orders, c$: $elm$core$Maybe$Nothing}),
					cmd);
			case 34:
				if (!msg.a.$) {
					var route = msg.a.a;
					var replaceRouteContainer = $author$project$Views$MapAssignment$RouteContainer$replaceRouteContainer(
						$author$project$Views$MapAssignment$RouteContainer$setRouteToDraftStatus(
							$author$project$Views$MapAssignment$Update$backendRouteTorouteContainer(route)));
					var routeContainers = replaceRouteContainer(routes.g8);
					var orders = A2(
						$author$project$Utils$createReplaceAllIfNew,
						$author$project$Views$MapAssignment$Model$ordersListToDict(route.gL),
						model.gL);
					var filteredRouteContainers = replaceRouteContainer(routes.b9);
					var newRoutes = _Utils_update(
						routes,
						{
							b9: filteredRouteContainers,
							g8: routeContainers,
							bd: $elm$core$Maybe$Just(
								$author$project$Views$MapAssignment$Update$backendRouteTorouteContainer(route))
						});
					var newModel = A2(
						$author$project$Views$MapAssignment$Update$filterOrders,
						context,
						_Utils_update(
							model,
							{gL: orders, hc: newRoutes}));
					return _Utils_Tuple2(
						newModel,
						$author$project$Views$MapAssignment$Update$focusOnRoutesList(route.fS));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 35:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c2: !model.c2}),
					$elm$core$Platform$Cmd$none);
			case 36:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 37:
				var routeContainer = msg.a;
				var cmd = A3($author$project$Request$Route$deleteRoute, context, routeContainer.g9.fS, $author$project$Views$MapAssignment$Msg$DeleteRouteRequest);
				return _Utils_Tuple2(model, cmd);
			case 38:
				if (!msg.a.$) {
					var route = msg.a.a;
					var newRouteContainer = $author$project$Views$MapAssignment$Update$backendRouteTorouteContainer(route);
					var selectedRouteContainer = A2(
						$elm$core$Maybe$andThen,
						function (container) {
							return _Utils_eq(container.g9.fS, newRouteContainer.g9.fS) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(container);
						},
						routes.bd);
					var newRoutes = _Utils_update(
						routes,
						{
							b9: A2($author$project$Views$MapAssignment$RouteContainer$removeRouteContainer, newRouteContainer, routes.b9),
							g8: A2($author$project$Views$MapAssignment$RouteContainer$removeRouteContainer, newRouteContainer, routes.g8),
							bd: selectedRouteContainer
						});
					var newModel = A2(
						$author$project$Views$MapAssignment$Update$filterOrders,
						context,
						_Utils_update(
							model,
							{hc: newRoutes}));
					return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 42:
				var newModel = A2(
					$author$project$Views$MapAssignment$Update$filterOrders,
					context,
					A2(
						$author$project$Views$MapAssignment$Update$updateFilters,
						function (filters) {
							return _Utils_update(
								filters,
								{cW: !model.fG.cW});
						},
						model));
				return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			case 43:
				var str = msg.a;
				var val = $elm$core$String$isEmpty(str) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(str);
				var newModel = A2(
					$author$project$Views$MapAssignment$Update$filterOrders,
					context,
					A2(
						$author$project$Views$MapAssignment$Update$updateFilters,
						function (filters) {
							return _Utils_update(
								filters,
								{c_: val});
						},
						model));
				return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			case 55:
				var orders = msg.a;
				var _v60 = A3(
					$author$project$Views$MapAssignment$Update$update,
					context,
					$author$project$Views$MapAssignment$Msg$AddToRouteContainer(orders),
					model);
				var model1 = _v60.a;
				var cmd = _v60.b;
				return _Utils_Tuple2(
					_Utils_update(
						model1,
						{cg: orders}),
					cmd);
			case 56:
				var order = msg.a;
				var orders = A2(
					$elm$core$List$filter,
					function (x) {
						return !_Utils_eq(x.fS, order.fS);
					},
					model.cg);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cg: orders, c$: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 57:
				var id = msg.a.fS;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c$: $elm$core$Maybe$Just(id)
						}),
					$elm$core$Platform$Cmd$none);
			case 41:
				var filterUnOptimizedRoute = function (route) {
					return $elm$core$List$length(route.g9.gL) > 2;
				};
				var unOptimizedRoutes = A2($elm$core$List$filter, filterUnOptimizedRoute, model.hc.g8);
				var buildOptimizeRequest = function (_v63) {
					var routeContainer = _v63.a;
					var requestParams = _v63.b;
					return A4(
						$author$project$Request$RouteRequest$syncOptimizeOrders,
						context,
						routeContainer.g9.fS,
						requestParams,
						$author$project$Views$MapAssignment$Msg$SyncOptimizeRequest(routeContainer));
				};
				var cmd = A2(
					$elm$core$List$map,
					buildOptimizeRequest,
					A2(
						$elm$core$List$map,
						function (route) {
							return _Utils_Tuple2(
								route,
								A2($author$project$Views$MapAssignment$Update$routeRequestParams, model, route));
						},
						unOptimizedRoutes));
				if (A2(
					$elm$core$List$all,
					$elm$core$Basics$eq($elm$core$Platform$Cmd$none),
					cmd)) {
					var _v61 = A2($PaackEng$elm_alert_beta$Alert$warning, 'No route can be improved', model.bv);
					var alerts = _v61.a;
					var alertCmd = _v61.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bv: alerts}),
						A2($elm$core$Platform$Cmd$map, $author$project$Views$MapAssignment$Msg$AlertMsg, alertCmd));
				} else {
					var _v62 = A2($PaackEng$elm_alert_beta$Alert$success, 'Route optimization in progress', model.bv);
					var alerts = _v62.a;
					var alertCmd = _v62.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bv: alerts}),
						$elm$core$Platform$Cmd$batch(
							A2(
								$elm$core$List$cons,
								A2($elm$core$Platform$Cmd$map, $author$project$Views$MapAssignment$Msg$AlertMsg, alertCmd),
								cmd)));
				}
			case 75:
				var val = msg.a;
				var contains = F2(
					function (str1, driver) {
						return A2(
							$elm$core$String$contains,
							$elm$core$String$toLower(str1),
							$elm$core$String$toLower(driver.fJ));
					});
				var filteredDrivers = (val === '') ? model.b_ : A2(
					$elm$core$List$filter,
					contains(val),
					model.b_);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{b$: filteredDrivers}),
					$elm$core$Platform$Cmd$none);
			case 70:
				var modalStatus = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cl: modalStatus}),
					$elm$core$Platform$Cmd$none);
			case 71:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cl: $author$project$Views$MapAssignment$Msg$Closed}),
					$elm$core$Platform$Cmd$none);
			case 76:
				var val = msg.a;
				var newModel = $author$project$Views$MapAssignment$Update$filterRouteContainers(
					_Utils_update(
						model,
						{ds: val}));
				return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			case 77:
				var routeContainer = msg.a;
				var newRouteContainer = _Utils_update(
					routeContainer,
					{f2: !routeContainer.f2});
				var newRoutes = _Utils_update(
					routes,
					{
						b9: A2($author$project$Views$MapAssignment$RouteContainer$replaceRouteContainer, newRouteContainer, routes.b9),
						g8: A2($author$project$Views$MapAssignment$RouteContainer$replaceRouteContainer, newRouteContainer, routes.g8)
					});
				var newModel = _Utils_update(
					model,
					{hc: newRoutes});
				return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			case 78:
				return A3(
					$author$project$Views$MapAssignment$Update$modifyRoutes,
					model,
					$author$project$Views$MapAssignment$Msg$HideSelectedRoutesRequest,
					$author$project$Request$Route$hideMultipleRoutes(context));
			case 79:
				if (!msg.a.$) {
					var updatedRoutes = msg.a.a;
					return A3($author$project$Views$MapAssignment$Update$modifyRoutesSuccessResponse, context, model, updatedRoutes);
				} else {
					return $author$project$Views$MapAssignment$Update$modifyRoutesFailureResponse(model);
				}
			case 80:
				var settings = model.hl;
				var newSettings = _Utils_update(
					settings,
					{T: !settings.T});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hl: newSettings}),
					$elm$core$Platform$Cmd$none);
			case 81:
				var settings = model.hl;
				var newSettings = _Utils_update(
					settings,
					{
						eQ: $author$project$Views$MapAssignment$Settings$ClusterSettings$toggleIsClustered(settings.eQ)
					});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hl: newSettings}),
					$elm$core$Platform$Cmd$none);
			case 82:
				var settings = model.hl;
				var newSettings = _Utils_update(
					settings,
					{fo: !settings.fo});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hl: newSettings}),
					$elm$core$Platform$Cmd$none);
			case 83:
				var val = msg.a;
				var settings = model.hl;
				var newSettings = _Utils_update(
					settings,
					{
						eQ: A2($author$project$Views$MapAssignment$Settings$ClusterSettings$setGridSize, val, settings.eQ)
					});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hl: newSettings}),
					$elm$core$Platform$Cmd$none);
			case 84:
				var val = msg.a;
				var settings = model.hl;
				var newSettings = _Utils_update(
					settings,
					{
						eQ: A2($author$project$Views$MapAssignment$Settings$ClusterSettings$setMinPinToCluster, val, settings.eQ)
					});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hl: newSettings}),
					$elm$core$Platform$Cmd$none);
			case 85:
				var subMsg = msg.a;
				var _v64 = A2($PaackEng$elm_alert_beta$Alert$update, subMsg, model.bv);
				var alerts = _v64.a;
				var cmd = _v64.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bv: alerts}),
					A2($elm$core$Platform$Cmd$map, $author$project$Views$MapAssignment$Msg$AlertMsg, cmd));
			case 86:
				var polygon = msg.a;
				var orders = $elm$core$Dict$values(
					A3(
						$author$project$Views$MapAssignment$Update$ordersInsidePolygons,
						model.hc.c0,
						A2($author$project$Views$MapAssignment$Model$orderIdsToOrdersDict, model.gL, model.fF),
						_List_fromArray(
							[polygon])));
				var newModel = A3($author$project$Views$MapAssignment$Update$addOrdersToSelectedRouteContainer, context, orders, model);
				return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			case 87:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							gh: _Utils_update(
								maps,
								{fY: true})
						}),
					$elm$core$Platform$Cmd$none);
			case 88:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							gh: _Utils_update(
								maps,
								{fY: false})
						}),
					$elm$core$Platform$Cmd$none);
			case 59:
				var value = msg.a;
				var _v65 = A2($elm$json$Json$Decode$decodeValue, $author$project$Request$RouteRequest$websocketRouteMessageDecoder, value);
				if (!_v65.$) {
					var routeId = _v65.a;
					var maybeRouteContainer = A2(
						$elm$core$Maybe$map,
						$author$project$Views$MapAssignment$RouteContainer$setRouteToCompleteStatus,
						A2($author$project$Views$MapAssignment$RouteContainer$findByRouteId, routeId, routes.g8));
					var newRoutes = _Utils_update(
						routes,
						{
							b9: A2($author$project$Views$MapAssignment$RouteContainer$maybeReplaceRouteContainer, maybeRouteContainer, routes.b9),
							g8: A2($author$project$Views$MapAssignment$RouteContainer$maybeReplaceRouteContainer, maybeRouteContainer, routes.g8)
						});
					var newModel = _Utils_update(
						model,
						{bZ: false, hc: newRoutes});
					var cmd = A2(
						$elm$core$Maybe$withDefault,
						$elm$core$Platform$Cmd$none,
						A3(
							$elm$core$Maybe$map2,
							F2(
								function (selectedRouteContainer, container) {
									return A2($author$project$Views$MapAssignment$RouteContainer$isSameRouteContainer, selectedRouteContainer, container) ? A3(
										$author$project$Request$Route$loadRouteData,
										context,
										container.g9.fS,
										$author$project$Views$MapAssignment$Msg$FetchRouteData(container.g9.fS)) : $elm$core$Platform$Cmd$none;
								}),
							routes.bd,
							maybeRouteContainer));
					return _Utils_Tuple2(
						newModel,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									cmd,
									$author$project$Ports$sendMsgToUpsideDown(
									$author$project$Ports$DiscardRouteJobId(routeId))
								])));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 60:
				var value = msg.a;
				var _v66 = A2($elm$json$Json$Decode$decodeValue, $author$project$Request$RouteRequest$websocketRouteMessageDecoder, value);
				if (!_v66.$) {
					var routeId = _v66.a;
					var maybeRouteContainer = A2(
						$elm$core$Maybe$map,
						$author$project$Views$MapAssignment$RouteContainer$setRouteToInProgressStatus,
						A2($author$project$Views$MapAssignment$RouteContainer$findByRouteId, routeId, routes.g8));
					var newRoutes = _Utils_update(
						routes,
						{
							b9: A2($author$project$Views$MapAssignment$RouteContainer$maybeReplaceRouteContainer, maybeRouteContainer, routes.b9),
							g8: A2($author$project$Views$MapAssignment$RouteContainer$maybeReplaceRouteContainer, maybeRouteContainer, routes.g8)
						});
					var newModel = _Utils_update(
						model,
						{hc: newRoutes});
					return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 61:
				var value = msg.a;
				var _v67 = A2($elm$json$Json$Decode$decodeValue, $author$project$Request$RouteRequest$websocketRouteMessageDecoder, value);
				if (!_v67.$) {
					var routeId = _v67.a;
					var maybeRouteContainer = A2($author$project$Views$MapAssignment$RouteContainer$findByRouteId, routeId, routes.g8);
					var routeContainerSetToErrored = A2($elm$core$Maybe$map, $author$project$Views$MapAssignment$RouteContainer$setRouteToErrored, maybeRouteContainer);
					var newRoutes = _Utils_update(
						routes,
						{
							b9: A2($author$project$Views$MapAssignment$RouteContainer$maybeReplaceRouteContainer, routeContainerSetToErrored, routes.b9),
							g8: A2($author$project$Views$MapAssignment$RouteContainer$maybeReplaceRouteContainer, routeContainerSetToErrored, routes.g8)
						});
					var newModel = _Utils_update(
						model,
						{hc: newRoutes});
					var maybeJobId = function () {
						if (!maybeRouteContainer.$) {
							var jobId = maybeRouteContainer.a.f3;
							return jobId;
						} else {
							return $elm$core$Maybe$Nothing;
						}
					}();
					var executionRequest = function () {
						if (!maybeJobId.$) {
							var jobId = maybeJobId.a;
							return A3(
								$author$project$Request$RouteRequest$fetchExecution,
								context,
								jobId,
								$author$project$Views$MapAssignment$Msg$GetKpiResponse(routeId));
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}();
					return _Utils_Tuple2(newModel, executionRequest);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 89:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 91:
				var newRoutes = function () {
					var _v70 = routes.bA;
					if (_v70.$ === 1) {
						var subModel = _v70.a;
						return _Utils_update(
							routes,
							{
								bA: $author$project$Views$MapAssignment$Model$Closed(subModel)
							});
					} else {
						var subModel = _v70.a;
						return _Utils_update(
							routes,
							{
								bA: $author$project$Views$MapAssignment$Model$Closed(subModel)
							});
					}
				}();
				return $author$project$Common$ReturnExtra$withNoCmd(
					_Utils_update(
						model,
						{hc: newRoutes}));
			case 92:
				var subMsg = msg.a;
				var updateDialogState = function (a) {
					var newRoutes = _Utils_update(
						routes,
						{bA: a});
					return _Utils_update(
						model,
						{hc: newRoutes});
				};
				var _v71 = model.hc.bA;
				if (_v71.$ === 1) {
					var subModel = _v71.a;
					return A2(
						$Fresheyeball$elm_return$Return$map,
						updateDialogState,
						A3(
							$Fresheyeball$elm_return$Return$mapBoth,
							$author$project$Views$MapAssignment$Translators$automaticRoute,
							$author$project$Views$MapAssignment$Model$Opened,
							A4($author$project$Page$AutomaticRoute$Update$update, context, subMsg, subModel, filteredOrdersWithAddresses_)));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 93:
				var value = msg.a;
				var _v72 = A2($elm$json$Json$Decode$decodeValue, $elm$json$Json$Decode$string, value);
				if (!_v72.$) {
					var jobId = _v72.a;
					return _Utils_Tuple2(
						model,
						A3(
							$author$project$Request$RouteResponse$checkRouteJob,
							context,
							jobId,
							$author$project$Views$MapAssignment$Msg$CheckRouteJob(jobId)));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 94:
				if (!msg.b.$) {
					var jobId = msg.a;
					var jobStatusResponse = msg.b.a;
					var _v73 = jobStatusResponse.ht;
					switch (_v73) {
						case 1:
							var newRoutes = _Utils_update(
								routes,
								{bz: 0, b9: _List_Nil, c0: $elm$core$Set$empty, g8: _List_Nil, bc: $author$project$Views$MapAssignment$Model$LoadingStarted});
							var newModel = _Utils_update(
								model,
								{hc: newRoutes});
							var _v74 = A2($PaackEng$elm_alert_beta$Alert$success, 'Route generated!', model.bv);
							var alerts = _v74.a;
							var alertCmd = _v74.b;
							var cmds = $elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										$author$project$Ports$sendMsgToUpsideDown($author$project$Ports$DiscardRouteJobInfo),
										$author$project$Ports$sendMsgToUpsideDown(
										$author$project$Ports$StopJobTimer(jobId)),
										A3($author$project$Request$Route$loadRoutes, context, 1, $author$project$Views$MapAssignment$Msg$FetchRoutes),
										A2($elm$core$Platform$Cmd$map, $author$project$Views$MapAssignment$Msg$AlertMsg, alertCmd)
									]));
							return _Utils_Tuple2(
								_Utils_update(
									newModel,
									{bv: alerts}),
								cmds);
						case 5:
							var newRoutes = _Utils_update(
								routes,
								{bz: 0, b9: _List_Nil, c0: $elm$core$Set$empty, g8: _List_Nil, bc: $author$project$Views$MapAssignment$Model$LoadingStarted});
							var newModel = _Utils_update(
								model,
								{hc: newRoutes});
							var _v75 = A2($PaackEng$elm_alert_beta$Alert$success, 'Route cancelled!', model.bv);
							var alerts = _v75.a;
							var alertCmd = _v75.b;
							var cmds = $elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										$author$project$Ports$sendMsgToUpsideDown($author$project$Ports$DiscardRouteJobInfo),
										$author$project$Ports$sendMsgToUpsideDown(
										$author$project$Ports$StopJobTimer(jobId)),
										A3($author$project$Request$Route$loadRoutes, context, 1, $author$project$Views$MapAssignment$Msg$FetchRoutes),
										A2($elm$core$Platform$Cmd$map, $author$project$Views$MapAssignment$Msg$AlertMsg, alertCmd)
									]));
							return _Utils_Tuple2(
								_Utils_update(
									newModel,
									{bv: alerts}),
								cmds);
						case 6:
							var newRoutes = _Utils_update(
								routes,
								{bz: 1, b9: _List_Nil, c0: $elm$core$Set$empty, g8: _List_Nil, bc: $author$project$Views$MapAssignment$Model$LoadingStarted});
							var newModel = _Utils_update(
								model,
								{hc: newRoutes});
							var cmds = $elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										$author$project$Ports$sendMsgToUpsideDown($author$project$Ports$DiscardRouteJobInfo),
										$author$project$Ports$sendMsgToUpsideDown(
										$author$project$Ports$StopJobTimer(jobId)),
										A3($author$project$Request$Route$loadRoutes, context, 1, $author$project$Views$MapAssignment$Msg$FetchRoutes),
										$author$project$Ports$sendMsgToUpsideDown($author$project$Ports$PlayAlertSound),
										$author$project$Ports$sendMsgToUpsideDown(
										$author$project$Ports$UpdatePageTitle('Error during route generation'))
									]));
							return _Utils_Tuple2(newModel, cmds);
						default:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					var jobId = msg.a;
					var newRoutes = _Utils_update(
						routes,
						{bz: 0, b9: _List_Nil, c0: $elm$core$Set$empty, g8: _List_Nil, bc: $author$project$Views$MapAssignment$Model$LoadingStarted});
					var _v76 = A2($PaackEng$elm_alert_beta$Alert$error, 'There was a problem generating the routes, try again later', model.bv);
					var alerts = _v76.a;
					var cmd = _v76.b;
					var newModel = _Utils_update(
						model,
						{bv: alerts, hc: newRoutes});
					var cmds = $elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$Ports$sendMsgToUpsideDown($author$project$Ports$DiscardRouteJobInfo),
								$author$project$Ports$sendMsgToUpsideDown(
								$author$project$Ports$StopJobTimer(jobId)),
								A3($author$project$Request$Route$loadRoutes, context, 1, $author$project$Views$MapAssignment$Msg$FetchRoutes),
								A2($elm$core$Platform$Cmd$map, $author$project$Views$MapAssignment$Msg$AlertMsg, cmd)
							]));
					return _Utils_Tuple2(newModel, cmds);
				}
			case 95:
				var value = msg.a;
				var _v77 = A2($elm$json$Json$Decode$decodeValue, $author$project$Data$Route$decodeRouteJobInfo, value);
				if (!_v77.$) {
					var routeJobInfo = _v77.a;
					var newRoutes = _Utils_update(
						routes,
						{bz: 2, c0: $elm$core$Set$empty});
					var newModel = _Utils_update(
						model,
						{hc: newRoutes});
					return _Utils_Tuple2(
						newModel,
						$author$project$Ports$sendMsgToUpsideDown(
							$author$project$Ports$StartJobTimer(routeJobInfo.f3)));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 96:
				var msg_ = msg.a;
				var scrollInfo = model.c3;
				var _v78 = A3($FabienHenon$elm_infinite_scroll$InfiniteScroll$update, $author$project$Views$MapAssignment$Msg$OrdersScrollMsg, msg_, scrollInfo.co);
				var infiniteScroll = _v78.a;
				var cmd = _v78.b;
				var newScrollInfo = _Utils_update(
					scrollInfo,
					{co: infiniteScroll});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c3: newScrollInfo}),
					cmd);
			case 97:
				var newOrdersScroll = A3(
					$author$project$Views$MapAssignment$Update$updateScrollList,
					model.c3,
					A2($author$project$Views$MapAssignment$Model$orderIdsToOrders, model.gL, model.fF),
					$author$project$Config$ordersListScrollWindow);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c3: newOrdersScroll}),
					$elm$core$Platform$Cmd$none);
			case 101:
				var warehouseId = msg.a;
				var warehouseIdToInt = $elm$core$String$toInt(warehouseId);
				var settings = model.hl;
				var routingSettings = settings.he;
				var newRouteSettings = _Utils_update(
					routingSettings,
					{
						gN: warehouseIdToInt,
						h$: $elm$core$Maybe$Just(context.d_)
					});
				var errorsUpdated = function () {
					if (!warehouseIdToInt.$) {
						return A2($elm$core$Dict$remove, $author$project$Config$warehouseErrorKeyManualRouting, settings.ft);
					} else {
						return settings.ft;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							hl: _Utils_update(
								settings,
								{ft: errorsUpdated, he: newRouteSettings})
						}),
					$elm$core$Platform$Cmd$none);
			case 98:
				var str = msg.a;
				var settings = model.hl;
				var routingSettings = settings.he;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							hl: _Utils_update(
								settings,
								{
									he: _Utils_update(
										routingSettings,
										{bW: str})
								})
						}),
					$elm$core$Platform$Cmd$none);
			case 99:
				var str = msg.a;
				var settings = model.hl;
				var routingSettings = settings.he;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							hl: _Utils_update(
								settings,
								{
									he: _Utils_update(
										routingSettings,
										{c5: str})
								})
						}),
					$elm$core$Platform$Cmd$none);
			case 102:
				var str = msg.a;
				var startTime = function () {
					if (str === '') {
						return $elm$core$Maybe$Nothing;
					} else {
						return $elm$core$Maybe$Just(str);
					}
				}();
				var settings = model.hl;
				var routingSettings = settings.he;
				var errors = settings.ft;
				var errorsUpdated = A2($elm$core$Dict$remove, $author$project$Config$startTimeErrorKeyManualRouting, errors);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							hl: _Utils_update(
								settings,
								{
									ft: errorsUpdated,
									he: _Utils_update(
										routingSettings,
										{
											ak: startTime,
											hr: $elm$core$Maybe$Just(context.d_)
										})
								})
						}),
					$elm$core$Platform$Cmd$none);
			case 90:
				var fetchKpisCmds = function () {
					var _v83 = _Utils_Tuple2(model.hc.bz, model.hc.bA);
					if ((_v83.a === 2) && (!_v83.b.$)) {
						var _v84 = _v83.a;
						var executions = _v83.b.a.b5;
						return A2(
							$elm$core$List$map,
							function (execution) {
								var jobId = execution.b.f3;
								var status = execution.c.ht;
								var _v86 = _Utils_Tuple2(jobId, status);
								if ((!_v86.a.$) && (_v86.b === 2)) {
									var id = _v86.a.a;
									var _v87 = _v86.b;
									return A3(
										$author$project$Request$RouteRequest$fetchExecution,
										context,
										id,
										$author$project$Views$MapAssignment$Msg$FetchKpisForUnsavedExecutions(id));
								} else {
									return $elm$core$Platform$Cmd$none;
								}
							},
							executions);
					} else {
						return _List_Nil;
					}
				}();
				var automaticRouteDialogState = function () {
					var _v81 = routes.bA;
					if (!_v81.$) {
						var a = _v81.a;
						var routesDialogModel = $author$project$Page$AutomaticRoute$Model$isThereAnOngoingExecution(a.b5) ? a : A9($author$project$Page$AutomaticRoute$Model$initialModel, context, _List_Nil, model.h0, _List_Nil, _List_Nil, model.gh.cG, model.bx, false, $elm$core$Maybe$Nothing);
						var _v82 = A4(
							$author$project$Page$AutomaticRoute$Update$update,
							context,
							$author$project$Page$AutomaticRoute$Msg$AddOrders(filteredOrdersWithAddresses_),
							routesDialogModel,
							filteredOrdersWithAddresses_);
						var newModel = _v82.a;
						return $author$project$Views$MapAssignment$Model$Opened(newModel);
					} else {
						return routes.bA;
					}
				}();
				var newRoutes = _Utils_update(
					routes,
					{bA: automaticRouteDialogState});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hc: newRoutes}),
					$elm$core$Platform$Cmd$batch(fetchKpisCmds));
			case 111:
				var jobId = msg.a;
				var response = msg.b;
				var updatedDialogState = function () {
					var _v88 = model.hc.bA;
					if (_v88.$ === 1) {
						var state = _v88.a;
						var currentExecutions = function () {
							var _v89 = model.hc.bA;
							if (_v89.$ === 1) {
								var executions = _v89.a.b5;
								return executions;
							} else {
								return _List_Nil;
							}
						}();
						var updatedExecutions = A2(
							$elm$core$List$map,
							A2($author$project$Common$ExecutionKpi$updateExecutionKpiValue, response, jobId),
							currentExecutions);
						return $author$project$Views$MapAssignment$Model$Opened(
							_Utils_update(
								state,
								{b5: updatedExecutions}));
					} else {
						return model.hc.bA;
					}
				}();
				var currentRoutes = model.hc;
				var updatedRoutes = _Utils_update(
					currentRoutes,
					{bA: updatedDialogState});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hc: updatedRoutes}),
					$elm$core$Platform$Cmd$none);
			case 72:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							hc: _Utils_update(
								routes,
								{bz: 0})
						}),
					$author$project$Ports$sendMsgToUpsideDown(
						$author$project$Ports$UpdatePageTitle('Paack')));
			case 108:
				if (!msg.b.$) {
					var shouldOpenDialog = msg.a;
					var activeSolutions = msg.b.a;
					var newModel = A9(
						$author$project$Page$AutomaticRoute$Model$initialModel,
						context,
						A2($author$project$Views$MapAssignment$Model$orderIdsToOrders, model.gL, model.fF),
						model.h0,
						_List_Nil,
						activeSolutions,
						model.gh.cG,
						model.bx,
						$elm$core$List$length(activeSolutions) > 0,
						$elm$core$Maybe$Nothing);
					var automaticRouteDialogState = shouldOpenDialog ? $author$project$Views$MapAssignment$Model$Opened(newModel) : $author$project$Views$MapAssignment$Model$Closed(newModel);
					var autoGenerateRouteState = ($elm$core$List$length(activeSolutions) > 0) ? 2 : 0;
					var newRoutes = _Utils_update(
						routes,
						{bz: autoGenerateRouteState, bA: automaticRouteDialogState});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hc: newRoutes}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 100:
				var param = msg.a;
				var settings = model.hl;
				var autoRoutingSettings = settings.aR;
				switch (param.$) {
					case 2:
						var str = param.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									hl: _Utils_update(
										settings,
										{
											aR: _Utils_update(
												autoRoutingSettings,
												{
													hX: A2(
														$elm$core$Maybe$withDefault,
														0,
														$author$project$Common$Vehicle$stringToVehicle(str))
												})
										})
								}),
							$elm$core$Platform$Cmd$none);
					case 0:
						var swt = param.a;
						var startWorkingTime = $elm$core$String$isEmpty(swt) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(swt);
						var newAutoRoutingSettings = _Utils_update(
							autoRoutingSettings,
							{hs: startWorkingTime});
						var errors = A2($author$project$Views$MapAssignment$Update$validateAutoRoutingSettings, newAutoRoutingSettings, settings.ft);
						var validatedSettings = _Utils_update(
							settings,
							{ft: errors});
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									hl: _Utils_update(
										validatedSettings,
										{
											aR: _Utils_update(
												autoRoutingSettings,
												{hs: startWorkingTime})
										})
								}),
							$elm$core$Platform$Cmd$none);
					default:
						var wh = param.a;
						var newAutoRoutingSettings = _Utils_update(
							autoRoutingSettings,
							{
								eg: A2(
									$elm$core$Maybe$withDefault,
									0,
									$elm$core$String$toInt(wh))
							});
						var errors = A2($author$project$Views$MapAssignment$Update$validateAutoRoutingSettings, newAutoRoutingSettings, settings.ft);
						var validatedSettings = _Utils_update(
							settings,
							{ft: errors});
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									hl: _Utils_update(
										validatedSettings,
										{aR: newAutoRoutingSettings, ft: errors})
								}),
							$elm$core$Platform$Cmd$none);
				}
			case 109:
				var settings = model.hl;
				var cmd = $author$project$Ports$sendMsgToUpsideDown(
					$author$project$Ports$SaveSettings(
						$elm$core$Maybe$Just(settings)));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							hl: _Utils_update(
								settings,
								{f1: true})
						}),
					cmd);
			case 110:
				var settings = model.hl;
				var automaticRouteModel = A3($author$project$Views$MapAssignment$Update$updateAutomaticRouteModel, context, routes.bA, model);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							hc: _Utils_update(
								routes,
								{bA: automaticRouteModel}),
							hl: _Utils_update(
								settings,
								{f1: false})
						}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{b2: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Views$Reports$Msg$CompaniesSelectMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Views$Reports$Msg$CountriesSelectMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$Views$Reports$Msg$CreateReportRequest = F2(
	function (a, b) {
		return {$: 12, a: a, b: b};
	});
var $author$project$Views$Reports$Msg$StoresSelectMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$Request$Report$createReportDataEncode = function (data) {
	var startTime = ($elm$time$Time$posixToMillis(data.bm.hq) / 1000) | 0;
	var endTime = function () {
		var _v0 = data.bm.fp;
		if (!_v0.$) {
			var time = _v0.a;
			return ($elm$time$Time$posixToMillis(time) / 1000) | 0;
		} else {
			return startTime;
		}
	}();
	var deliverySlot = $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'start_time',
				$elm$json$Json$Encode$int(startTime)),
				_Utils_Tuple2(
				'end_time',
				$elm$json$Json$Encode$int(endTime))
			]));
	var valuesList = _List_fromArray(
		[
			_Utils_Tuple2(
			'type',
			$elm$json$Json$Encode$string(data.d5)),
			_Utils_Tuple2(
			'countries',
			$elm$json$Json$Encode$string(data.bS)),
			_Utils_Tuple2(
			'store_ids',
			$elm$json$Json$Encode$string(data.dT)),
			_Utils_Tuple2(
			'company_ids',
			$elm$json$Json$Encode$string(data.bM)),
			_Utils_Tuple2('delivery_slot', deliverySlot)
		]);
	return $elm$json$Json$Encode$object(valuesList);
};
var $author$project$Request$Report$createReportResponseDecoder = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['data']),
	$elm$json$Json$Decode$string);
var $author$project$Request$Report$createReport = F3(
	function (_v0, data, msg) {
		var user = _v0.d7;
		var env = _v0.fr;
		return A5(
			$author$project$Request$HttpClient$authenticatedPost,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.bs;
					},
					user)),
			$author$project$Request$Report$reportsUrl(env),
			$author$project$Request$Report$createReportDataEncode(data),
			$author$project$Request$Report$createReportResponseDecoder,
			msg);
	});
var $author$project$Views$Reports$AdvancedFilters$getCompaniesSelect = function (_v0) {
	var state = _v0;
	if (state.$ === 1) {
		var companiesSelect = state.a;
		return $elm$core$Maybe$Just(companiesSelect);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Views$Reports$AdvancedFilters$getCountriesSelect = function (_v0) {
	var state = _v0;
	if (state.$ === 1) {
		var countriesSelect = state.c;
		return $elm$core$Maybe$Just(countriesSelect);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Views$Reports$AdvancedFilters$getStoresSelect = function (_v0) {
	var state = _v0;
	if (state.$ === 1) {
		var storesSelect = state.b;
		return $elm$core$Maybe$Just(storesSelect);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Views$Reports$AdvancedFilters$Opened = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$Views$Reports$AdvancedFilters$initOpenedFilterState = function (model) {
	return A3(
		$author$project$Views$Reports$AdvancedFilters$Opened,
		$author$project$Views$Common$Select$init(model.eS),
		$author$project$Views$Common$Select$init(model.dV),
		$author$project$Views$Common$Select$init(model.bS));
};
var $author$project$Views$Reports$AdvancedFilters$isOpen = function (_v0) {
	var state = _v0;
	if (state.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Views$Reports$Model$isReportJobsRunning = function (_v0) {
	var availableReportTypes = _v0.ao;
	return $elm$core$List$length(availableReportTypes) < 3;
};
var $author$project$Views$Reports$Update$reportFormDataError = function (model) {
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{
				di: $elm$core$Maybe$Just('Something went wrong, please try again')
			}),
		$elm$core$Platform$Cmd$none);
};
var $author$project$Data$Country$toString = function (country) {
	switch (country) {
		case 0:
			return 'us';
		case 1:
			return 'pt';
		case 2:
			return 'es';
		case 3:
			return 'uk';
		default:
			return 'fr';
	}
};
var $author$project$Views$Reports$AdvancedFilters$advancedFiltersToStringRecord = function (_v0) {
	var state = _v0;
	var getValue = F3(
		function (list, fn, transformFn) {
			return A2(
				$elm$core$String$join,
				',',
				A2(
					$elm$core$List$map,
					A2($elm$core$Basics$composeL, transformFn, fn),
					$author$project$Views$Common$Select$getSelectedOptions(list)));
		});
	var getStringValue = F2(
		function (list, fn) {
			return A3(getValue, list, fn, $elm$core$Basics$identity);
		});
	var getIntValue = F2(
		function (list, fn) {
			return A3(getValue, list, fn, $elm$core$String$fromInt);
		});
	if (state.$ === 1) {
		var companiesSelect = state.a;
		var storesSelect = state.b;
		var countriesSelect = state.c;
		return {
			bM: A2(
				getIntValue,
				companiesSelect,
				function ($) {
					return $.fS;
				}),
			bS: A2(getStringValue, countriesSelect, $author$project$Data$Country$toString),
			dT: A2(
				getIntValue,
				storesSelect,
				function ($) {
					return $.fS;
				})
		};
	} else {
		return {bM: '', bS: '', dT: ''};
	}
};
var $author$project$Views$Reports$Model$reportFormToCreateReportData = function (reportForm) {
	var advancedFiltersAsRecord = $author$project$Views$Reports$AdvancedFilters$advancedFiltersToStringRecord(reportForm.aQ);
	return {
		bM: advancedFiltersAsRecord.bM,
		bS: advancedFiltersAsRecord.bS,
		dT: advancedFiltersAsRecord.dT,
		bm: {
			fp: reportForm.ar,
			hq: A2(
				$elm$core$Maybe$withDefault,
				$elm$time$Time$millisToPosix(0),
				reportForm.aO)
		},
		d5: reportForm.ah
	};
};
var $author$project$Views$Reports$Model$reportJobsRequestTreshold = 5 * 1000;
var $author$project$Utils$stringToMaybeTime = function (iso8601String) {
	var _v0 = $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(iso8601String);
	if (!_v0.$) {
		var time = _v0.a;
		return $elm$core$Maybe$Just(
			A3($justinmimbs$time_extra$Time$Extra$floor, 11, $elm$time$Time$utc, time));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Views$Reports$AdvancedFilters$updateCompaniesSelect = F2(
	function (_v0, newCompaniesSelect) {
		var state = _v0;
		if (state.$ === 1) {
			var storesSelect = state.b;
			var countriesSelect = state.c;
			return A3($author$project$Views$Reports$AdvancedFilters$Opened, newCompaniesSelect, storesSelect, countriesSelect);
		} else {
			return $author$project$Views$Reports$AdvancedFilters$Closed;
		}
	});
var $author$project$Views$Reports$AdvancedFilters$updateCountriesSelect = F2(
	function (_v0, newCountriesSelect) {
		var state = _v0;
		if (state.$ === 1) {
			var companiesSelect = state.a;
			var storesSelect = state.b;
			return A3($author$project$Views$Reports$AdvancedFilters$Opened, companiesSelect, storesSelect, newCountriesSelect);
		} else {
			return $author$project$Views$Reports$AdvancedFilters$Closed;
		}
	});
var $author$project$Views$Reports$AdvancedFilters$updateStoresSelect = F2(
	function (_v0, newStoresSelect) {
		var state = _v0;
		if (state.$ === 1) {
			var companiesSelect = state.a;
			var countriesSelect = state.c;
			return A3($author$project$Views$Reports$AdvancedFilters$Opened, companiesSelect, newStoresSelect, countriesSelect);
		} else {
			return $author$project$Views$Reports$AdvancedFilters$Closed;
		}
	});
var $author$project$Views$Reports$Update$update = F3(
	function (context, msg, model) {
		switch (msg.$) {
			case 0:
				var reportForm = model.ba;
				var newAdvancedFilters = $author$project$Views$Reports$AdvancedFilters$isOpen(reportForm.aQ) ? $author$project$Views$Reports$AdvancedFilters$closed : $author$project$Views$Reports$AdvancedFilters$initOpenedFilterState(model);
				var newReportForm = _Utils_update(
					reportForm,
					{aQ: newAdvancedFilters});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ba: newReportForm}),
					$elm$core$Platform$Cmd$none);
			case 1:
				if (!msg.a.$) {
					var companies = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{eS: companies, cc: model.cc - 1}),
						$elm$core$Platform$Cmd$none);
				} else {
					return $author$project$Views$Reports$Update$reportFormDataError(model);
				}
			case 2:
				if (!msg.a.$) {
					var stores = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{cc: model.cc - 1, dV: stores}),
						$elm$core$Platform$Cmd$none);
				} else {
					return $author$project$Views$Reports$Update$reportFormDataError(model);
				}
			case 3:
				if (!msg.a.$) {
					var reports = msg.a.a;
					var newReports = (!model.cv) ? A2(
						$elm$core$List$filter,
						function (report) {
							return !A2(
								$elm$core$List$any,
								function (oldReport) {
									return _Utils_eq(report.fS, oldReport.fS);
								},
								model.dk);
						},
						reports) : _List_Nil;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{cv: false, cy: false, cS: newReports, dk: reports}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								cy: false,
								dl: $elm$core$Maybe$Just('Error, we are not able to get the current reports')
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 4:
				if (!msg.a.$) {
					var reportJobs = msg.a.a;
					var lockedTypes = A2(
						$elm$core$List$map,
						function ($) {
							return $.d5;
						},
						reportJobs);
					var newAvailablesReportTypes = A2(
						$elm$core$List$filter,
						function (type_) {
							return !A2(
								$elm$core$List$any,
								$elm$core$String$contains(type_),
								lockedTypes);
						},
						$author$project$Data$Report$reportTypes);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ao: newAvailablesReportTypes}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 5:
				var subMsg = msg.a;
				var _v1 = $author$project$Views$Reports$AdvancedFilters$getCompaniesSelect(model.ba.aQ);
				if (!_v1.$) {
					var select = _v1.a;
					var reportForm = model.ba;
					var _v2 = A3(
						$author$project$Views$Common$Select$update,
						subMsg,
						select,
						function ($) {
							return $.gs;
						});
					var newSelect = _v2.a;
					var cmd = _v2.b;
					var newReportForm = _Utils_update(
						reportForm,
						{
							aQ: A2($author$project$Views$Reports$AdvancedFilters$updateCompaniesSelect, reportForm.aQ, newSelect)
						});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ba: newReportForm}),
						A2($elm$core$Platform$Cmd$map, $author$project$Views$Reports$Msg$CompaniesSelectMsg, cmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 6:
				var subMsg = msg.a;
				var _v3 = $author$project$Views$Reports$AdvancedFilters$getStoresSelect(model.ba.aQ);
				if (!_v3.$) {
					var select = _v3.a;
					var reportForm = model.ba;
					var _v4 = A3(
						$author$project$Views$Common$Select$update,
						subMsg,
						select,
						function ($) {
							return $.gs;
						});
					var newSelect = _v4.a;
					var cmd = _v4.b;
					var newReportForm = _Utils_update(
						reportForm,
						{
							aQ: A2($author$project$Views$Reports$AdvancedFilters$updateStoresSelect, reportForm.aQ, newSelect)
						});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ba: newReportForm}),
						A2($elm$core$Platform$Cmd$map, $author$project$Views$Reports$Msg$StoresSelectMsg, cmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 7:
				var subMsg = msg.a;
				var _v5 = $author$project$Views$Reports$AdvancedFilters$getCountriesSelect(model.ba.aQ);
				if (!_v5.$) {
					var select = _v5.a;
					var reportForm = model.ba;
					var _v6 = A3($author$project$Views$Common$Select$update, subMsg, select, $author$project$Data$Country$toString);
					var newSelect = _v6.a;
					var cmd = _v6.b;
					var newReportForm = _Utils_update(
						reportForm,
						{
							aQ: A2($author$project$Views$Reports$AdvancedFilters$updateCountriesSelect, reportForm.aQ, newSelect)
						});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ba: newReportForm}),
						A2($elm$core$Platform$Cmd$map, $author$project$Views$Reports$Msg$CountriesSelectMsg, cmd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 8:
				var val = msg.a;
				var reportForm = model.ba;
				var newType = $elm$core$String$toLower(val);
				var newReportForm = _Utils_update(
					reportForm,
					{ar: $elm$core$Maybe$Nothing, ah: newType, aO: $elm$core$Maybe$Nothing});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ba: newReportForm, dh: model.dh + 1}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var val = msg.a;
				var reportForm = model.ba;
				var newReportForm = _Utils_update(
					reportForm,
					{
						aO: $author$project$Utils$stringToMaybeTime(val)
					});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ba: newReportForm}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var val = msg.a;
				var reportForm = model.ba;
				var newReportForm = _Utils_update(
					reportForm,
					{
						ar: $author$project$Utils$stringToMaybeTime(val)
					});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ba: newReportForm}),
					$elm$core$Platform$Cmd$none);
			case 11:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cs: true}),
					A3(
						$author$project$Request$Report$createReport,
						context,
						$author$project$Views$Reports$Model$reportFormToCreateReportData(model.ba),
						$author$project$Views$Reports$Msg$CreateReportRequest(model.ba.ah)));
			case 12:
				if (!msg.b.$) {
					var reportType = msg.a;
					var reportForm = model.ba;
					var newReportForm = _Utils_update(
						reportForm,
						{ar: $elm$core$Maybe$Nothing, aO: $elm$core$Maybe$Nothing});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ao: A2(
									$elm$core$List$filter,
									function (rt) {
										return !_Utils_eq(reportType, rt);
									},
									model.ao),
								cs: false,
								ba: newReportForm,
								dh: model.dh + 1
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								cs: false,
								dj: $elm$core$Maybe$Just('Error, please try again')
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 13:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dj: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 14:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cy: true}),
					$elm$core$Platform$Cmd$batch(
						$author$project$Views$Reports$Model$reportListRequests(context)));
			case 15:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cy: true, dl: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$batch(
						$author$project$Views$Reports$Model$reportListRequests(context)));
			case 16:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cc: 2, di: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$batch(
						$author$project$Views$Reports$Model$reportFormRequests(context)));
			default:
				var time = msg.a;
				var isReportJobsRunning = $author$project$Views$Reports$Model$isReportJobsRunning(model);
				var isReloadTimeExpired = _Utils_cmp(
					$elm$time$Time$posixToMillis(time) - $elm$time$Time$posixToMillis(model.cD),
					$author$project$Views$Reports$Model$reportJobsRequestTreshold) > -1;
				var newCmd = (isReportJobsRunning && isReloadTimeExpired) ? $author$project$Views$Reports$Model$reportListRequests(context) : _List_fromArray(
					[$elm$core$Platform$Cmd$none]);
				return _Utils_Tuple2(
					model,
					$elm$core$Platform$Cmd$batch(newCmd));
		}
	});
var $author$project$Views$RetailerRoundIntegration$Model$Complete = function (a) {
	return {$: 0, a: a};
};
var $author$project$Views$RetailerRoundIntegration$Model$Error = function (a) {
	return {$: 3, a: a};
};
var $author$project$Views$RetailerRoundIntegration$Model$initLoadedModel = F2(
	function (routes, retailer) {
		return {
			dr: retailer,
			hc: A2(
				$elm$core$List$map,
				$elm$core$Tuple$pair(false),
				routes),
			dw: $krisajenkins$remotedata$RemoteData$NotAsked
		};
	});
var $author$project$Views$RetailerRoundIntegration$Msg$LoadedModelMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Views$RetailerRoundIntegration$Msg$OnRoutesSent = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Tuple$mapSecond = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			x,
			func(y));
	});
var $author$project$Request$RetailerRoundIntegration$sendRouteToRetailerUrl = F2(
	function (env, retailerUrlPath) {
		return $author$project$Request$Url$apiRailsUrl(env) + ('api/v2/routes/' + retailerUrlPath);
	});
var $author$project$Request$RetailerRoundIntegration$sendRouteToRetailer = F4(
	function (_v0, routeIds, retailerUrlPath, msg) {
		var user = _v0.d7;
		var env = _v0.fr;
		var ids = A2(
			$elm$core$String$join,
			',',
			A2($elm$core$List$map, $elm$core$String$fromInt, routeIds));
		return A5(
			$author$project$Request$HttpClient$authenticatedPost,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.bs;
					},
					user)),
			A2($author$project$Request$RetailerRoundIntegration$sendRouteToRetailerUrl, env, retailerUrlPath),
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'ids',
						$elm$json$Json$Encode$string(ids))
					])),
			$elm$json$Json$Decode$succeed(0),
			A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, msg));
	});
var $author$project$Request$RetailerRoundIntegration$peixosPuignau = F3(
	function (context, routeIds, msg) {
		return A4($author$project$Request$RetailerRoundIntegration$sendRouteToRetailer, context, routeIds, 'puignau', msg);
	});
var $author$project$Request$RetailerRoundIntegration$refruiting = F3(
	function (context, routeIds, msg) {
		return A4($author$project$Request$RetailerRoundIntegration$sendRouteToRetailer, context, routeIds, 'refruiting', msg);
	});
var $author$project$Request$RetailerRoundIntegration$ulabox = F3(
	function (context, routeIds, msg) {
		return A4($author$project$Request$RetailerRoundIntegration$sendRouteToRetailer, context, routeIds, 'ulabox', msg);
	});
var $author$project$Views$RetailerRoundIntegration$Update$sendToRetailerRequest = F3(
	function (context, retailer, routeIds) {
		var requestFn = function () {
			switch (retailer) {
				case 1:
					return $author$project$Request$RetailerRoundIntegration$ulabox;
				case 0:
					return $author$project$Request$RetailerRoundIntegration$refruiting;
				default:
					return $author$project$Request$RetailerRoundIntegration$peixosPuignau;
			}
		}();
		return A2(requestFn, context, routeIds);
	});
var $author$project$Views$RetailerRoundIntegration$Update$updateLoadedModel = F3(
	function (context, msg, loadedModel) {
		return A2(
			$elm$core$Tuple$mapSecond,
			$elm$core$Platform$Cmd$map($author$project$Views$RetailerRoundIntegration$Msg$LoadedModelMsg),
			function () {
				switch (msg.$) {
					case 0:
						var toggledRoute = msg.a;
						return _Utils_Tuple2(
							_Utils_update(
								loadedModel,
								{
									hc: A2(
										$elm$core$List$map,
										function (_v1) {
											var isSelected = _v1.a;
											var route = _v1.b;
											return _Utils_eq(route.fS, toggledRoute.fS) ? _Utils_Tuple2(!isSelected, toggledRoute) : _Utils_Tuple2(isSelected, route);
										},
										loadedModel.hc)
								}),
							$elm$core$Platform$Cmd$none);
					case 3:
						return _Utils_Tuple2(
							_Utils_update(
								loadedModel,
								{
									hc: A2(
										$elm$core$List$map,
										function (_v2) {
											var route = _v2.b;
											return _Utils_Tuple2(true, route);
										},
										loadedModel.hc)
								}),
							$elm$core$Platform$Cmd$none);
					case 1:
						var routeIds = A2(
							$elm$core$List$map,
							A2(
								$elm$core$Basics$composeR,
								$elm$core$Tuple$second,
								function ($) {
									return $.fS;
								}),
							A2($elm$core$List$filter, $elm$core$Tuple$first, loadedModel.hc));
						return _Utils_Tuple2(
							_Utils_update(
								loadedModel,
								{dw: $krisajenkins$remotedata$RemoteData$Loading}),
							A4($author$project$Views$RetailerRoundIntegration$Update$sendToRetailerRequest, context, loadedModel.dr, routeIds, $author$project$Views$RetailerRoundIntegration$Msg$OnRoutesSent));
					default:
						var response = msg.a;
						return _Utils_Tuple2(
							_Utils_update(
								loadedModel,
								{dw: response}),
							$elm$core$Platform$Cmd$none);
				}
			}());
	});
var $author$project$Views$RetailerRoundIntegration$Update$update = F3(
	function (context, msg, model) {
		switch (msg.$) {
			case 0:
				var response = msg.a;
				var _v1 = _Utils_Tuple2(response, model);
				if (_v1.b.$ === 1) {
					if (_v1.a.$ === 3) {
						var _v2 = _v1.a.a;
						var newRoutes = _v2.a;
						var pagination = _v2.b;
						var _v3 = _v1.b;
						var currentPage = _v3.a;
						var routes = _v3.b;
						var retailer = _v3.c;
						return _Utils_eq(pagination.hN, currentPage) ? _Utils_Tuple2(
							$author$project$Views$RetailerRoundIntegration$Model$Complete(
								A2(
									$author$project$Views$RetailerRoundIntegration$Model$initLoadedModel,
									_Utils_ap(routes, newRoutes),
									retailer)),
							$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
							A3(
								$author$project$Views$RetailerRoundIntegration$Model$LoadingRoutes,
								currentPage + 1,
								_Utils_ap(routes, newRoutes),
								retailer),
							A2($author$project$Views$RetailerRoundIntegration$Model$fetchRoutes, context, currentPage + 1));
					} else {
						var _v4 = _v1.b;
						var retailer = _v4.c;
						return _Utils_Tuple2(
							$author$project$Views$RetailerRoundIntegration$Model$Error(retailer),
							$elm$core$Platform$Cmd$none);
					}
				} else {
					return _Utils_Tuple2($author$project$Views$RetailerRoundIntegration$Model$RetailerNotSupported, $elm$core$Platform$Cmd$none);
				}
			case 2:
				var reload = function (retailer) {
					return _Utils_Tuple2(
						A3($author$project$Views$RetailerRoundIntegration$Model$LoadingRoutes, 1, _List_Nil, retailer),
						A2($author$project$Views$RetailerRoundIntegration$Model$fetchRoutes, context, 1));
				};
				switch (model.$) {
					case 1:
						var retailer = model.c;
						return reload(retailer);
					case 3:
						var retailer = model.a;
						return reload(retailer);
					default:
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				var subMsg = msg.a;
				if (!model.$) {
					var loadedModel = model.a;
					return A2(
						$elm$core$Tuple$mapFirst,
						$author$project$Views$RetailerRoundIntegration$Model$Complete,
						A3($author$project$Views$RetailerRoundIntegration$Update$updateLoadedModel, context, subMsg, loadedModel));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Update$update = F2(
	function (msg, model) {
		var context = model.eW;
		var page = model.gP;
		var _v0 = _Utils_Tuple2(msg, page);
		_v0$10:
		while (true) {
			switch (_v0.a.$) {
				case 0:
					var urlRequest = _v0.a.a;
					if (!urlRequest.$) {
						var url = urlRequest.a;
						var _v2 = url.cd;
						if (_v2.$ === 1) {
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						} else {
							return _Utils_Tuple2(
								model,
								A2(
									$elm$browser$Browser$Navigation$pushUrl,
									model.f5,
									$elm$url$Url$toString(url)));
						}
					} else {
						var href = urlRequest.a;
						return _Utils_Tuple2(
							model,
							$elm$browser$Browser$Navigation$load(href));
					}
				case 1:
					var url = _v0.a.a;
					var route = $author$project$Route$fromUrl(url);
					return A2($author$project$Route$canUserAccess, route, context.d7) ? A2($author$project$Update$setRoute, route, model) : _Utils_Tuple2(
						model,
						A2($author$project$Route$redirectForbiddenAccess, model.f5, context.d7));
				case 2:
					var subMsg = _v0.a.a;
					return A2(
						$Fresheyeball$elm_return$Return$mapCmd,
						$author$project$Msg$ContextMsg,
						A2(
							$Fresheyeball$elm_return$Return$map,
							function (c) {
								return _Utils_update(
									model,
									{eW: c});
							},
							A2($author$project$Data$Context$update, subMsg, context)));
				case 5:
					if (!_v0.b.$) {
						var loginMsg = _v0.a.a;
						var loginModel = _v0.b.a;
						var _v3 = A3($author$project$Views$Login$Login$update, context, loginMsg, loginModel);
						var _v4 = _v3.a;
						var pageState = _v4.a;
						var cmd = _v4.b;
						var extCmd = _v3.b;
						var _final = function () {
							switch (extCmd.$) {
								case 1:
									var user = extCmd.a;
									return A4($author$project$SessionUtils$setUserAndRedirectsTo, $author$project$Route$Home, user, false, model);
								case 2:
									var user = extCmd.a;
									var redirectPath = extCmd.b;
									return A4(
										$author$project$SessionUtils$setUserAndRedirectsTo,
										$author$project$Route$stringToRoute(redirectPath),
										user,
										false,
										model);
								default:
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{
												gP: $author$project$Page$Login(pageState)
											}),
										A2($elm$core$Platform$Cmd$map, $author$project$Msg$LoginMsg, cmd));
							}
						}();
						return _final;
					} else {
						break _v0$10;
					}
				case 4:
					var _v6 = _v0.a;
					return $author$project$SessionUtils$logoutAndRedirectsToLogin(model);
				case 3:
					var user = _v0.a.a;
					var _v7 = _Utils_Tuple2(context.d7, user);
					if (_v7.a.$ === 1) {
						if (!_v7.b.$) {
							var _v9 = _v7.a;
							var u = _v7.b.a;
							return A4($author$project$SessionUtils$setUserAndRedirectsTo, $author$project$Route$Home, u, false, model);
						} else {
							return $author$project$Common$ReturnExtra$withNoCmd(model);
						}
					} else {
						if (_v7.b.$ === 1) {
							var _v8 = _v7.b;
							return $author$project$SessionUtils$logoutAndRedirectsToLogin(model);
						} else {
							var u = _v7.b.a;
							return A4($author$project$SessionUtils$setUserAndRedirectsTo, $author$project$Route$Home, u, true, model);
						}
					}
				case 7:
					if (_v0.b.$ === 2) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						var _v10 = A3($author$project$Views$MapArea$Update$update, context, subMsg, subModel);
						var newSubModel = _v10.a;
						var newSubCmd = _v10.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									gP: $author$project$Page$MapArea(newSubModel)
								}),
							A2($elm$core$Platform$Cmd$map, $author$project$Msg$MapAreaMsg, newSubCmd));
					} else {
						break _v0$10;
					}
				case 8:
					if (_v0.b.$ === 3) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						var _v11 = A3($author$project$Views$MapAssignment$Update$update, context, subMsg, subModel);
						var newSubModel = _v11.a;
						var newSubCmd = _v11.b;
						_v12$4:
						while (true) {
							switch (subMsg.$) {
								case 109:
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{
												eW: A2(
													$author$project$Data$Context$updateSettings,
													$elm$core$Maybe$Just(subModel.hl),
													context),
												gP: $author$project$Page$MapAssignment(newSubModel)
											}),
										A2($elm$core$Platform$Cmd$map, $author$project$Msg$MapAssignmentMsg, newSubCmd));
								case 7:
									if (subMsg.a.$ === 1) {
										var error = subMsg.a.a;
										switch (error.$) {
											case 3:
												var statusCode = error.a.dQ;
												return _Utils_eq(
													statusCode,
													$elm$core$Maybe$Just(403)) ? $author$project$SessionUtils$logoutAndRedirectsToLogin(model) : _Utils_Tuple2(
													_Utils_update(
														model,
														{
															gP: $author$project$Page$MapAssignment(newSubModel)
														}),
													A2($elm$core$Platform$Cmd$map, $author$project$Msg$MapAssignmentMsg, newSubCmd));
											case 4:
												var statusCode = error.a.dQ;
												return _Utils_eq(
													statusCode,
													$elm$core$Maybe$Just(403)) ? $author$project$SessionUtils$logoutAndRedirectsToLogin(model) : _Utils_Tuple2(
													_Utils_update(
														model,
														{
															gP: $author$project$Page$MapAssignment(newSubModel)
														}),
													A2($elm$core$Platform$Cmd$map, $author$project$Msg$MapAssignmentMsg, newSubCmd));
											default:
												return _Utils_Tuple2(
													_Utils_update(
														model,
														{
															gP: $author$project$Page$MapAssignment(newSubModel)
														}),
													A2($elm$core$Platform$Cmd$map, $author$project$Msg$MapAssignmentMsg, newSubCmd));
										}
									} else {
										break _v12$4;
									}
								case 108:
									if (subMsg.b.$ === 1) {
										var error = subMsg.b.a;
										if (error.$ === 3) {
											var statusCode = error.a;
											return (statusCode === 403) ? $author$project$SessionUtils$logoutAndRedirectsToLogin(model) : _Utils_Tuple2(
												_Utils_update(
													model,
													{
														gP: $author$project$Page$MapAssignment(newSubModel)
													}),
												A2($elm$core$Platform$Cmd$map, $author$project$Msg$MapAssignmentMsg, newSubCmd));
										} else {
											return _Utils_Tuple2(
												_Utils_update(
													model,
													{
														gP: $author$project$Page$MapAssignment(newSubModel)
													}),
												A2($elm$core$Platform$Cmd$map, $author$project$Msg$MapAssignmentMsg, newSubCmd));
										}
									} else {
										break _v12$4;
									}
								case 89:
									return $author$project$Update$authTokenAboutToExpire(context) ? $author$project$SessionUtils$logoutAndRedirectsToLogin(model) : _Utils_Tuple2(
										_Utils_update(
											model,
											{
												gP: $author$project$Page$MapAssignment(
													_Utils_update(
														newSubModel,
														{hS: $author$project$Views$MapAssignment$Model$LoadingStarted}))
											}),
										A2(
											$elm$core$Platform$Cmd$map,
											$author$project$Msg$MapAssignmentMsg,
											$elm$core$Platform$Cmd$batch(
												_List_fromArray(
													[
														newSubCmd,
														A4($author$project$Request$Order$loadUpcomingOrders, context, subModel.gh.eD, 1, $author$project$Views$MapAssignment$Msg$FetchUpcomingOrders)
													]))));
								default:
									break _v12$4;
							}
						}
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									gP: $author$project$Page$MapAssignment(newSubModel)
								}),
							A2($elm$core$Platform$Cmd$map, $author$project$Msg$MapAssignmentMsg, newSubCmd));
					} else {
						break _v0$10;
					}
				case 9:
					if (_v0.b.$ === 5) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						var _v15 = A3($author$project$Views$RetailerRoundIntegration$Update$update, context, subMsg, subModel);
						var newSubModel = _v15.a;
						var newSubCmd = _v15.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									gP: $author$project$Page$RetailerRoundIntegration(newSubModel)
								}),
							A2($elm$core$Platform$Cmd$map, $author$project$Msg$RetailerRoundIntegrationMsg, newSubCmd));
					} else {
						break _v0$10;
					}
				case 10:
					if (_v0.b.$ === 4) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						var _v16 = A3($author$project$Views$Reports$Update$update, context, subMsg, subModel);
						var newSubModel = _v16.a;
						var newSubCmd = _v16.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									gP: $author$project$Page$Reports(newSubModel)
								}),
							A2($elm$core$Platform$Cmd$map, $author$project$Msg$ReportsMsg, newSubCmd));
					} else {
						break _v0$10;
					}
				default:
					break _v0$10;
			}
		}
		return $author$project$Common$ReturnExtra$withNoCmd(model);
	});
var $author$project$Msg$ForgotPasswordMsg = function (a) {
	return {$: 6, a: a};
};
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$virtual_dom$VirtualDom$lazy = _VirtualDom_lazy;
var $elm$html$Html$Lazy$lazy = $elm$virtual_dom$VirtualDom$lazy;
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $author$project$Route$href = function (route) {
	return $elm$html$Html$Attributes$href(
		$author$project$Route$routeToString(route));
};
var $author$project$Data$User$isAdmin = function (_v0) {
	var role = _v0.aL;
	return !role;
};
var $author$project$Data$User$isFleetManager = function (_v0) {
	var role = _v0.aL;
	return role === 1;
};
var $author$project$Data$User$maybeUserIs = F2(
	function (permissionFn, mUser) {
		if (!mUser.$) {
			var user = mUser.a;
			return permissionFn(user);
		} else {
			return false;
		}
	});
var $elm$html$Html$nav = _VirtualDom_node('nav');
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $elm$html$Html$i = _VirtualDom_node('i');
var $elm$html$Html$Attributes$title = $elm$html$Html$Attributes$stringProperty('title');
var $author$project$Views$Common$Navbar$navbarItem = F3(
	function (maybeRoute, icon, title_) {
		var href_ = function () {
			if (!maybeRoute.$) {
				var route = maybeRoute.a;
				return $author$project$Route$href(route);
			} else {
				return $elm$html$Html$Attributes$href('');
			}
		}();
		return A2(
			$elm$html$Html$a,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('nav-link last'),
					$elm$html$Html$Attributes$title(title_),
					href_
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
							$elm$html$Html$Attributes$class('fa fa-' + icon)
						]),
					_List_Nil)
				]));
	});
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $author$project$HtmlExtra$empty = $elm$html$Html$text('');
var $author$project$HtmlExtra$when = F2(
	function (shouldRender, view) {
		return shouldRender ? view : $author$project$HtmlExtra$empty;
	});
var $author$project$Views$Common$Navbar$navbar = function (context) {
	return A2(
		$elm$html$Html$nav,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('nav left-navbar')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('nav-link navbar-brand'),
								$elm$html$Html$Attributes$title('Home'),
								$author$project$Route$href($author$project$Route$Home)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('pk')
							])),
						A2(
						$author$project$HtmlExtra$when,
						A2($author$project$Data$User$maybeUserIs, $author$project$Data$User$isFleetManager, context.d7),
						A3(
							$author$project$Views$Common$Navbar$navbarItem,
							$elm$core$Maybe$Just($author$project$Route$MapArea),
							'expand-arrows-alt',
							'Map Area Assignment')),
						A2(
						$author$project$HtmlExtra$when,
						A2($author$project$Data$User$maybeUserIs, $author$project$Data$User$isFleetManager, context.d7),
						A3(
							$author$project$Views$Common$Navbar$navbarItem,
							$elm$core$Maybe$Just($author$project$Route$MapAssignment),
							'map',
							'Manual Route Assignment')),
						A2(
						$author$project$HtmlExtra$when,
						A2($author$project$Data$User$maybeUserIs, $author$project$Data$User$isAdmin, context.d7),
						A3(
							$author$project$Views$Common$Navbar$navbarItem,
							$elm$core$Maybe$Just($author$project$Route$Reports),
							'file',
							'Reports')),
						A3(
						$author$project$Views$Common$Navbar$navbarItem,
						$elm$core$Maybe$Just($author$project$Route$Logout),
						'user-circle',
						'Logout')
					]))
			]));
};
var $author$project$Views$Common$LoggedLayout$view = F2(
	function (context, content) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2($elm$html$Html$Lazy$lazy, $author$project$Views$Common$Navbar$navbar, context),
					content
				]));
	});
var $elm$html$Html$footer = _VirtualDom_node('footer');
var $elm$html$Html$span = _VirtualDom_node('span');
var $author$project$Views$ForgotPassword$ForgotPassword$footerView = A2(
	$elm$html$Html$footer,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('footer')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('container')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('col.12')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-muted')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Terms of Service')
										])),
									A2(
									$elm$html$Html$a,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Privacy Policy')
										]))
								]))
						]))
				]))
		]));
var $author$project$Views$ForgotPassword$ForgotPassword$SetEmail = function (a) {
	return {$: 1, a: a};
};
var $author$project$Views$ForgotPassword$ForgotPassword$SubmitForm = {$: 0};
var $elm$html$Html$button = _VirtualDom_node('button');
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$html$Html$main_ = _VirtualDom_node('main');
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Events$keyCode = A2($elm$json$Json$Decode$field, 'keyCode', $elm$json$Json$Decode$int);
var $author$project$Views$Helpers$Events$onEnter = function (msg) {
	var isEnter = function (code) {
		return (code === 13) ? $elm$json$Json$Decode$succeed(msg) : $elm$json$Json$Decode$fail('not ENTER');
	};
	return A2(
		$elm$html$Html$Events$on,
		'keydown',
		A2($elm$json$Json$Decode$andThen, isEnter, $elm$html$Html$Events$keyCode));
};
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $author$project$Views$ForgotPassword$ForgotPassword$mainView = F2(
	function (_v0, _v1) {
		return A2(
			$elm$html$Html$main_,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('container login')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row justify-content-center align-items-center')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-sm-6')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('login-form')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('form-group row')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('login-logo')
																]),
															_List_Nil)
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('form-group row')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('col-sm-12 align-self-center')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$input,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('form-control form-control-lg'),
																			$elm$html$Html$Attributes$placeholder('Email address'),
																			$elm$html$Html$Attributes$type_('email'),
																			$elm$html$Html$Events$onInput($author$project$Views$ForgotPassword$ForgotPassword$SetEmail),
																			$author$project$Views$Helpers$Events$onEnter($author$project$Views$ForgotPassword$ForgotPassword$SubmitForm)
																		]),
																	_List_Nil)
																]))
														])),
													A2(
													$elm$html$Html$button,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('btn btn-primary btn-lg'),
															$elm$html$Html$Events$onClick($author$project$Views$ForgotPassword$ForgotPassword$SubmitForm)
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Reset Password')
														]))
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$Views$ForgotPassword$ForgotPassword$view = F2(
	function (context, model) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2($author$project$Views$ForgotPassword$ForgotPassword$mainView, context, model),
					$author$project$Views$ForgotPassword$ForgotPassword$footerView
				]));
	});
var $author$project$Views$Login$Login$footerView = A2(
	$elm$html$Html$footer,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('footer')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('container')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('col.12')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-muted')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Terms of Service')
										])),
									A2(
									$elm$html$Html$a,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Privacy Policy')
										]))
								]))
						]))
				]))
		]));
var $author$project$Views$Login$Login$SetEmail = function (a) {
	return {$: 2, a: a};
};
var $author$project$Views$Login$Login$SetPassword = function (a) {
	return {$: 3, a: a};
};
var $author$project$Views$Login$Login$SubmitLogin = {$: 0};
var $author$project$Views$Login$Login$errorGroup = function (errors) {
	var errorView = function (error) {
		if (!error.a) {
			var _v1 = error.a;
			var errorMessage = error.b;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('alert alert-danger')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(errorMessage)
					]));
		} else {
			return $elm$html$Html$text('');
		}
	};
	return A2($elm$core$List$map, errorView, errors);
};
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $author$project$Views$Login$Login$mainView = F2(
	function (_v0, model) {
		return A2(
			$elm$html$Html$main_,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('container login')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row justify-content-center align-items-center')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-sm-6')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('login-form')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('form-group row')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('login-logo')
																]),
															_List_Nil)
														])),
													A2(
													$elm$html$Html$div,
													_List_Nil,
													$author$project$Views$Login$Login$errorGroup(model.ft)),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('form-group row')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('col-sm-12 align-self-center')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$input,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('form-control form-control-lg'),
																			$elm$html$Html$Attributes$id('inputEmail'),
																			$elm$html$Html$Attributes$placeholder('Email address'),
																			$elm$html$Html$Attributes$type_('email'),
																			$elm$html$Html$Events$onInput($author$project$Views$Login$Login$SetEmail),
																			$author$project$Views$Helpers$Events$onEnter($author$project$Views$Login$Login$SubmitLogin)
																		]),
																	_List_Nil)
																]))
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('form-group row')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('col-sm-12 align-self-center')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$input,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('form-control form-control-lg'),
																			$elm$html$Html$Attributes$id('inputPassword'),
																			$elm$html$Html$Attributes$placeholder('Password'),
																			$elm$html$Html$Attributes$type_('password'),
																			$elm$html$Html$Events$onInput($author$project$Views$Login$Login$SetPassword),
																			$author$project$Views$Helpers$Events$onEnter($author$project$Views$Login$Login$SubmitLogin)
																		]),
																	_List_Nil)
																]))
														])),
													A2(
													$elm$html$Html$button,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('btn btn-primary btn-lg'),
															$elm$html$Html$Events$onClick($author$project$Views$Login$Login$SubmitLogin)
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Login')
														]))
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row forgot-password justify-content-center align-items-center')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-sm-6')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$a,
											_List_fromArray(
												[
													$author$project$Route$href($author$project$Route$ForgotPassword)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('I forgot my password')
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$Views$Login$Login$viewRedirectLoading = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$id('login-page')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('d-flex')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('redirect-spinner')
						]),
					_List_Nil)
				]))
		]));
var $author$project$Views$Login$Login$view = F2(
	function (context, model) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('login-page')
				]),
			_List_fromArray(
				[
					model.as ? $author$project$Views$Login$Login$viewRedirectLoading : A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2($author$project$Views$Login$Login$mainView, context, model),
							$author$project$Views$Login$Login$footerView
						]))
				]));
	});
var $author$project$Views$MapArea$MapArea$footerView = A2(
	$elm$html$Html$footer,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('footer')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('container')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('col.12')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-muted')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Terms of Service')
										])),
									A2(
									$elm$html$Html$a,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Privacy Policy')
										]))
								]))
						]))
				]))
		]));
var $author$project$Views$MapArea$Msg$MapRequested = {$: 1};
var $krisajenkins$remotedata$RemoteData$isLoading = function (data) {
	if (data.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Views$MapArea$Msg$SubmitForm = {$: 0};
var $author$project$Views$MapArea$MapArea$submitButtonView = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('text-light mb-4')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('btn-primary btn-lg'),
					$elm$html$Html$Events$onClick($author$project$Views$MapArea$Msg$SubmitForm)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Submit Area')
				]))
		]));
var $author$project$Views$MapArea$MapArea$buttonView = function (model) {
	return $krisajenkins$remotedata$RemoteData$isLoading(model.dq) ? A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('text-light mb-4')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class(' btn-disabled btn-lg')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Submit Area')
					]))
			])) : $author$project$Views$MapArea$MapArea$submitButtonView;
};
var $author$project$Views$MapArea$MapArea$errorMessage = F2(
	function (response, fileName) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mb-4 text-danger')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					function () {
						switch (response.$) {
							case 4:
								var responseError = response.a;
								return 'Error received from the server' + responseError;
							case 0:
								return 'Server is unreachable';
							case 1:
								return 'Server is not responding';
							case 2:
								return 'Connection error';
							default:
								return 'Error while sending file ' + fileName;
						}
					}())
				]));
	});
var $elm$file$File$name = _File_name;
var $author$project$Views$MapArea$MapArea$selectFileButtonLabel = function (uploadedModel) {
	var _v0 = uploadedModel.fB;
	if (!_v0.$) {
		var fileSelected = _v0.a;
		return $elm$file$File$name(fileSelected);
	} else {
		return 'Click to select a file';
	}
};
var $author$project$Views$MapArea$MapArea$messageView = function (uploadedModel) {
	var _v0 = uploadedModel.dq;
	switch (_v0.$) {
		case 3:
			var value = _v0.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class(' mb-4 text-success')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Views$MapArea$MapArea$selectFileButtonLabel(uploadedModel) + (' uploaded. \n' + value))
					]));
		case 1:
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mb-4 text-light')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Uploading...')
					]));
		case 2:
			var httpError = _v0.a;
			return A2(
				$author$project$Views$MapArea$MapArea$errorMessage,
				httpError,
				$author$project$Views$MapArea$MapArea$selectFileButtonLabel(uploadedModel));
		default:
			return A2($elm$html$Html$div, _List_Nil, _List_Nil);
	}
};
var $author$project$Views$MapArea$MapArea$mainView = function (uploadModel) {
	return A2(
		$elm$html$Html$main_,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('container login')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('row justify-content-start align-items-center')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('col-sm-6')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('map-area')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('text-light mb-4')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Select your Map Area File (only .KML files)')
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('form-group row')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('col-sm-12 align-self-lg-start')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$button,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('form-control  form-control-file btn-secondary'),
																		$elm$html$Html$Events$onClick($author$project$Views$MapArea$Msg$MapRequested)
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text(
																		$author$project$Views$MapArea$MapArea$selectFileButtonLabel(uploadModel))
																	]))
															]))
													])),
												$author$project$Views$MapArea$MapArea$messageView(uploadModel),
												$author$project$Views$MapArea$MapArea$buttonView(uploadModel)
											]))
									]))
							]))
					]))
			]));
};
var $author$project$Views$MapArea$MapArea$view = function (uploadModel) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$Views$MapArea$MapArea$mainView(uploadModel),
				$author$project$Views$MapArea$MapArea$footerView
			]));
};
var $author$project$Views$MapAssignment$Msg$DismissErrorAlert = {$: 113};
var $author$project$Views$MapAssignment$Msg$AcknowledgeAutomaticRouteError = {$: 72};
var $elm$html$Html$h3 = _VirtualDom_node('h3');
var $author$project$Views$Common$ConfirmationModal$convertConfig = function (config) {
	return {
		eC: $elm$core$Maybe$Just(
			$elm$html$Html$text(config.gm)),
		eN: $elm$core$Maybe$Just(config.bJ),
		eV: $elm$core$Maybe$Nothing,
		fI: config.bD,
		fO: $elm$core$Maybe$Just(
			A2(
				$elm$html$Html$h3,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(config.hH)
					])))
	};
};
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $jouderianjr$elm_dialog$Dialog$backdrop = function (config) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'modal-backdrop show',
						!_Utils_eq(config, $elm$core$Maybe$Nothing))
					]))
			]),
		_List_Nil);
};
var $jouderianjr$elm_dialog$Dialog$empty = A2($elm$html$Html$span, _List_Nil, _List_Nil);
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $jouderianjr$elm_dialog$Dialog$wrapBody = function (body) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('modal-body')
			]),
		_List_fromArray(
			[body]));
};
var $jouderianjr$elm_dialog$Dialog$wrapFooter = function (footer) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('modal-footer')
			]),
		footer);
};
var $jouderianjr$elm_dialog$Dialog$closeButton = function (closeMessage) {
	return A2(
		$elm$html$Html$button,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$type_('button'),
				$elm$html$Html$Attributes$class('close'),
				$elm$html$Html$Events$onClick(closeMessage)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('x')
			]));
};
var $elm$html$Html$h5 = _VirtualDom_node('h5');
var $jouderianjr$elm_dialog$Dialog$wrapHeader = F2(
	function (closeMessage, header) {
		return (_Utils_eq(closeMessage, $elm$core$Maybe$Nothing) && _Utils_eq(header, $elm$core$Maybe$Nothing)) ? $jouderianjr$elm_dialog$Dialog$empty : A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('modal-header')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h5,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('modal-title')
						]),
					_List_fromArray(
						[
							A2($elm$core$Maybe$withDefault, $jouderianjr$elm_dialog$Dialog$empty, header)
						])),
					A2(
					$elm$core$Maybe$withDefault,
					$jouderianjr$elm_dialog$Dialog$empty,
					A2($elm$core$Maybe$map, $jouderianjr$elm_dialog$Dialog$closeButton, closeMessage))
				]));
	});
var $jouderianjr$elm_dialog$Dialog$view = function (maybeConfig) {
	var displayed = !_Utils_eq(maybeConfig, $elm$core$Maybe$Nothing);
	return A2(
		$elm$html$Html$div,
		function () {
			var _v0 = A2(
				$elm$core$Maybe$andThen,
				function ($) {
					return $.eV;
				},
				maybeConfig);
			if (_v0.$ === 1) {
				return _List_Nil;
			} else {
				var containerClass = _v0.a;
				return _List_fromArray(
					[
						$elm$html$Html$Attributes$class(containerClass)
					]);
			}
		}(),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('modal', true),
								_Utils_Tuple2('show', displayed)
							])),
						A2(
						$elm$html$Html$Attributes$style,
						'display',
						displayed ? 'block' : 'none')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('modal-dialog')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('modal-content')
									]),
								function () {
									if (maybeConfig.$ === 1) {
										return _List_fromArray(
											[$jouderianjr$elm_dialog$Dialog$empty]);
									} else {
										var config = maybeConfig.a;
										return _List_fromArray(
											[
												A2($jouderianjr$elm_dialog$Dialog$wrapHeader, config.eN, config.fO),
												A2(
												$elm$core$Maybe$withDefault,
												$jouderianjr$elm_dialog$Dialog$empty,
												A2($elm$core$Maybe$map, $jouderianjr$elm_dialog$Dialog$wrapBody, config.eC)),
												$jouderianjr$elm_dialog$Dialog$wrapFooter(config.fI)
											]);
									}
								}())
							]))
					])),
				$jouderianjr$elm_dialog$Dialog$backdrop(maybeConfig)
			]));
};
var $author$project$Views$Common$ConfirmationModal$view = F2(
	function (isVisible, config) {
		return $jouderianjr$elm_dialog$Dialog$view(
			isVisible ? $elm$core$Maybe$Just(
				$author$project$Views$Common$ConfirmationModal$convertConfig(config)) : $elm$core$Maybe$Nothing);
	});
var $author$project$Views$MapAssignment$View$automaticRouteErrorDialog = function (model) {
	var dialog = F2(
		function (isVisible, msg) {
			return A2(
				$author$project$Views$Common$ConfirmationModal$view,
				isVisible,
				{
					bD: _List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn btn-danger'),
									$elm$html$Html$Events$onClick($author$project$Views$MapAssignment$Msg$AcknowledgeAutomaticRouteError)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Ok')
								]))
						]),
					bJ: $author$project$Views$MapAssignment$Msg$AcknowledgeAutomaticRouteError,
					gm: msg,
					hH: 'Error during route generation'
				});
		});
	var _v0 = model.hc.bz;
	if (_v0 === 1) {
		return A2(dialog, true, 'The optimization engine failed during route generation, please try again. If the issue persists please contact support@paack.co');
	} else {
		return A2(dialog, false, '');
	}
};
var $author$project$Page$AutomaticRoute$Msg$CloseClicked = {$: 1};
var $author$project$Page$AutomaticRoute$Msg$CreateExecution = {$: 2};
var $author$project$Page$AutomaticRoute$Msg$DismissErrorAlert = {$: 25};
var $author$project$Page$AutomaticRoute$Msg$SplitOrders = {$: 14};
var $elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var $elm$svg$Svg$Attributes$fill = _VirtualDom_attribute('fill');
var $elm$svg$Svg$Attributes$height = _VirtualDom_attribute('height');
var $elm$svg$Svg$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$path = $elm$svg$Svg$trustedNode('path');
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $elm$svg$Svg$Attributes$width = _VirtualDom_attribute('width');
var $author$project$Views$Common$Alert$crossIcon = function (msg) {
	if (!msg.$) {
		var msg_ = msg.a;
		return A2(
			$elm$svg$Svg$svg,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$width('12'),
					$elm$svg$Svg$Attributes$height('12'),
					$elm$svg$Svg$Attributes$fill('none'),
					$elm$svg$Svg$Events$onClick(msg_)
				]),
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$path,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$d('m6 4.868 4.41-4.41a.8.8 0 1 1 1.133 1.132L7.133 6l4.41 4.411a.8.8 0 1 1-1.132 1.133L6 7.134l-4.41 4.41A.8.8 0 1 1 .456 10.41l4.41-4.41L.457 1.59A.8.8 0 1 1 1.589.457L6 4.867Z'),
							$elm$svg$Svg$Attributes$fill('#FEE2E2')
						]),
					_List_Nil)
				]));
	} else {
		return $elm$html$Html$text('');
	}
};
var $elm$svg$Svg$Attributes$clipRule = _VirtualDom_attribute('clip-rule');
var $elm$svg$Svg$Attributes$fillRule = _VirtualDom_attribute('fill-rule');
var $author$project$Views$Common$Alert$errorIcon = A2(
	$elm$svg$Svg$svg,
	_List_fromArray(
		[
			$elm$svg$Svg$Attributes$width('20'),
			$elm$svg$Svg$Attributes$height('20'),
			$elm$svg$Svg$Attributes$fill('none')
		]),
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$fillRule('evenodd'),
					$elm$svg$Svg$Attributes$clipRule('evenodd'),
					$elm$svg$Svg$Attributes$d('M.748 6.177c-.081.25-.081.523-.081 1.067v5.515c0 .544 0 .816.081 1.067.027.083.06.164.1.242.12.235.312.427.697.812l3.576 3.576c.385.385.577.577.813.697.077.04.158.073.241.1.251.082.523.082 1.067.082h5.515c.544 0 .816 0 1.068-.082.083-.027.163-.06.241-.1.235-.12.428-.312.813-.697l3.575-3.576c.385-.385.578-.577.697-.812.04-.078.074-.159.1-.242.082-.251.082-.523.082-1.067v-5.53c0-.546 0-.82-.082-1.07a1.668 1.668 0 0 0-.1-.243c-.12-.235-.314-.428-.7-.813l-3.573-3.56c-.385-.383-.577-.575-.812-.694a1.667 1.667 0 0 0-.24-.1c-.251-.081-.523-.081-1.066-.081H7.242c-.544 0-.816 0-1.067.082-.083.027-.164.06-.241.1-.236.12-.428.312-.813.697L1.545 5.123c-.385.384-.577.577-.697.812-.04.078-.073.159-.1.242Zm8.403-.509.241 5.333c0 .553.237.963.712.963.474 0 .71-.41.71-.963l.242-5.333c0-.552-.427-1-.953-1s-.952.448-.952 1Zm.952 10.333c.702 0 1.27-.597 1.27-1.333s-.568-1.333-1.27-1.333c-.701 0-1.27.597-1.27 1.333s.569 1.333 1.27 1.333Z'),
					$elm$svg$Svg$Attributes$fill('#FEE2E2')
				]),
			_List_Nil)
		]));
var $author$project$Views$Common$Alert$errorAlertView = F2(
	function (maybeError, msg) {
		if (!maybeError.$) {
			var message = maybeError.a.gm;
			var statusCode = maybeError.a.dQ;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('error-alert-paack')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'margin-right', '12px')
							]),
						_List_fromArray(
							[$author$project$Views$Common$Alert$errorIcon])),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'padding-right', '12px')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('font-weight-bold')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Sorry, something went wrong - ')
									])),
								function () {
								if (!statusCode.$) {
									var code = statusCode.a;
									return $elm$html$Html$text(
										$elm$core$String$fromInt(code) + ' - ');
								} else {
									return $elm$html$Html$text('');
								}
							}(),
								$elm$html$Html$text(message),
								$elm$html$Html$text(' - If the issue persists, please create a ticket on Paack\'s help center.')
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'margin-left', 'auto'),
								A2($elm$html$Html$Attributes$style, 'cursor', 'pointer')
							]),
						_List_fromArray(
							[
								$author$project$Views$Common$Alert$crossIcon(msg)
							]))
					]));
		} else {
			return $elm$html$Html$text('');
		}
	});
var $author$project$Page$AutomaticRoute$Msg$SelectExecution = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$AutomaticRoute$Msg$RemoveExecution = F3(
	function (a, b, c) {
		return {$: 8, a: a, b: b, c: c};
	});
var $author$project$Page$AutomaticRoute$View$cancelSaveView = F3(
	function (jobId, sol, idx) {
		var styles = A2($elm$html$Html$Attributes$style, 'margin-top', '32px');
		var _v0 = _Utils_Tuple2(jobId, sol.cz);
		_v0$0:
		while (true) {
			if (!_v0.a.$) {
				if (_v0.b) {
					break _v0$0;
				} else {
					return A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('btn btn-danger'),
								styles,
								$elm$html$Html$Events$onClick(
								$author$project$Page$AutomaticRoute$Msg$CancelExecution(idx))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Cancel')
							]));
				}
			} else {
				if (_v0.b) {
					break _v0$0;
				} else {
					var _v1 = _v0.a;
					return A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('btn btn-danger'),
								styles,
								$elm$html$Html$Events$onClick(
								A3($author$project$Page$AutomaticRoute$Msg$RemoveExecution, idx, false, false))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Cancel')
							]));
				}
			}
		}
		return $elm$html$Html$text('');
	});
var $author$project$Page$AutomaticRoute$View$emptyView = $elm$html$Html$text('');
var $author$project$Page$AutomaticRoute$View$kpisView = function (_v0) {
	var kpis = _v0.f6;
	var status = _v0.ht;
	var _v1 = _Utils_Tuple2(kpis, status);
	_v1$2:
	while (true) {
		if (_v1.b === 2) {
			switch (_v1.a.$) {
				case 3:
					var areaKPIs = _v1.a.a.ew;
					var _v2 = _v1.b;
					if (!areaKPIs.$) {
						var kpi = areaKPIs.a;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('area-kpis-executions-screen')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('kpis-col')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$span,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('font-weight-bold')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Productivity: ')
														])),
													$elm$html$Html$text(
													A2($myrho$elm_round$Round$round, 1, kpi.dd))
												])),
											A2(
											$elm$html$Html$span,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('font-weight-bold')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Total distance: ')
														])),
													$elm$html$Html$text(
													A2($myrho$elm_round$Round$round, 1, kpi.ab) + ' km')
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('kpis-col')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$span,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('font-weight-bold')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Total working time: ')
														])),
													$elm$html$Html$text(
													A2($myrho$elm_round$Round$round, 1, kpi.eg) + ' h')
												])),
											A2(
											$elm$html$Html$span,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('font-weight-bold')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Total waiting time: ')
														])),
													$elm$html$Html$text(
													A2($myrho$elm_round$Round$round, 1, kpi.eb) + ' h')
												]))
										]))
								]));
					} else {
						return $author$project$Page$AutomaticRoute$View$emptyView;
					}
				case 2:
					var _v4 = _v1.b;
					return $elm$html$Html$text('Error loading KPIs');
				default:
					break _v1$2;
			}
		} else {
			break _v1$2;
		}
	}
	return $author$project$Page$AutomaticRoute$View$emptyView;
};
var $author$project$Page$AutomaticRoute$View$ordersCountView = function (_v0) {
	var execution = _v0.b;
	var solution = _v0.c;
	var _v1 = solution.ht;
	if (_v1 === 2) {
		var _v2 = solution.f6;
		if (_v2.$ === 3) {
			var areaKPIs = _v2.a.ew;
			if (!areaKPIs.$) {
				var kpis = areaKPIs.a;
				return A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('font-weight-bold')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Total orders assigned: ')
								])),
							$elm$html$Html$text(
							$elm$core$String$fromInt(kpis.ez) + ('/' + $elm$core$String$fromInt(kpis.c1)))
						]));
			} else {
				return $elm$html$Html$text('');
			}
		} else {
			return $elm$html$Html$text('');
		}
	} else {
		return ($elm$core$List$length(execution.gL) > 0) ? A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('font-weight-bold')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Total orders: ')
						])),
					$elm$html$Html$text(
					$elm$core$String$fromInt(
						$elm$core$List$length(execution.gL)))
				])) : $elm$html$Html$text('');
	}
};
var $elm$html$Html$p = _VirtualDom_node('p');
var $author$project$Data$Order$orderTypeToString = function (orderType) {
	switch (orderType) {
		case 0:
			return 'direct';
		case 1:
			return 'reverse';
		default:
			return 'collaborative';
	}
};
var $author$project$Views$MapAssignment$RouteContainer$removeSpecialChars = function (address) {
	var _v0 = $elm$regex$Regex$fromString('[ !\"#$%&\'()*+,-./:;<=>?@[\\\\\\]^_`{|}~]');
	if (_v0.$ === 1) {
		return address;
	} else {
		var regex = _v0.a;
		return A3(
			$elm$regex$Regex$replace,
			regex,
			function (_v1) {
				return '';
			},
			address);
	}
};
var $author$project$Views$MapAssignment$RouteContainer$routeItems = function (orders) {
	var address = function (order) {
		var _v0 = order.gK;
		switch (_v0) {
			case 1:
				return order.gT;
			case 0:
				return order.e6;
			default:
				return order.e6;
		}
	};
	return $elm$core$Set$size(
		A3(
			$elm$core$List$foldl,
			F2(
				function (order, acc) {
					return A2(
						$elm$core$Set$insert,
						$author$project$Data$Order$orderTypeToString(order.gK) + (':' + $author$project$Views$MapAssignment$RouteContainer$removeSpecialChars(
							$elm$core$String$toLower(
								$author$project$Common$Address$fullAddress(
									address(order))))),
						acc);
				}),
			$elm$core$Set$empty,
			orders));
};
var $author$project$Page$AutomaticRoute$View$routeItemsCountView = function (_v0) {
	var orders = _v0.b.gL;
	var solution = _v0.c;
	var routeItemsCountElement = function (count) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('font-weight-bold')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Total route items: ')
						])),
					$elm$html$Html$text(
					$elm$core$String$fromInt(count))
				]));
	};
	var _v1 = solution.ht;
	if (_v1 === 2) {
		var _v2 = solution.f6;
		if (_v2.$ === 3) {
			var areaKPIs = _v2.a.ew;
			if (!areaKPIs.$) {
				var kpis = areaKPIs.a;
				return routeItemsCountElement(kpis.dv);
			} else {
				return $elm$html$Html$text('');
			}
		} else {
			return $elm$html$Html$text('');
		}
	} else {
		return routeItemsCountElement(
			$author$project$Views$MapAssignment$RouteContainer$routeItems(orders));
	}
};
var $author$project$Page$AutomaticRoute$View$routesCountView = function (_v0) {
	var solution = _v0.c;
	var _v1 = solution.ht;
	if (_v1 === 2) {
		var _v2 = solution.f6;
		if (_v2.$ === 3) {
			var areaKPIs = _v2.a.ew;
			if (!areaKPIs.$) {
				var kpis = areaKPIs.a;
				return A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('font-weight-bold')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Total routes: ')
								])),
							$elm$html$Html$text(
							$elm$core$String$fromInt(kpis.hd))
						]));
			} else {
				return $elm$html$Html$text('');
			}
		} else {
			return $elm$html$Html$text('');
		}
	} else {
		return $elm$html$Html$text('');
	}
};
var $author$project$Page$AutomaticRoute$Msg$SaveRouteSolution = F2(
	function (a, b) {
		return {$: 20, a: a, b: b};
	});
var $author$project$Page$AutomaticRoute$View$saveView = F3(
	function (index, execution, sol) {
		var solution = $elm$core$List$head(sol.R.a.hp);
		var _v0 = _Utils_Tuple2(sol.cz, solution);
		if ((!_v0.a) && (!_v0.b.$)) {
			var s = _v0.b.a;
			return A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('btn btn-primary'),
						A2($elm$html$Html$Attributes$style, 'margin', '32px 4px 0 0'),
						$elm$html$Html$Events$onClick(
						A2(
							$author$project$Page$AutomaticRoute$Msg$SaveRouteSolution,
							s,
							_Utils_Tuple3(index, execution, sol)))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Save')
					]));
		} else {
			return $elm$html$Html$text('');
		}
	});
var $author$project$Page$AutomaticRoute$View$statusView = function (sol) {
	var status = function () {
		var _v0 = _Utils_Tuple2(sol.ht, sol.gW);
		switch (_v0.a) {
			case 0:
				if (_v0.b.$ === 1) {
					var _v1 = _v0.a;
					var _v2 = _v0.b;
					return A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('execution-status')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Not started')
							]));
				} else {
					var _v3 = _v0.a;
					var position = _v0.b.a;
					return A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('execution-status')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								'Not started / ' + ($elm$core$String$fromInt(position) + ' in the queue'))
							]));
				}
			case 1:
				var _v4 = _v0.a;
				return A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('execution-status calculating')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Calculating')
						]));
			default:
				var _v5 = _v0.a;
				var greenTick = A2(
					$elm$svg$Svg$svg,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$width('22'),
							$elm$svg$Svg$Attributes$height('22'),
							$elm$svg$Svg$Attributes$fill('none')
						]),
					_List_fromArray(
						[
							A2(
							$elm$svg$Svg$path,
							_List_fromArray(
								[
									$elm$svg$Svg$Attributes$fill('#07AB66'),
									$elm$svg$Svg$Attributes$fillRule('evenodd'),
									$elm$svg$Svg$Attributes$d('M11 21.889c6.014 0 10.889-4.875 10.889-10.889C21.889 4.986 17.014.111 11 .111 4.986.111.111 4.986.111 11c0 6.014 4.875 10.889 10.889 10.889ZM17.116 8.09a.894.894 0 1 0-1.343-1.182 1159.506 1159.506 0 0 0-5.827 6.677.2.2 0 0 1-.296.006l-2.863-3.014A.894.894 0 1 0 5.49 11.81l3.127 3.292c.116.122.259.273.4.385.17.136.45.31.832.3.383-.009.654-.195.818-.338a4.2 4.2 0 0 0 .381-.403l.023-.026c1.06-1.225 3.482-4.017 6.045-6.929Z'),
									$elm$svg$Svg$Attributes$clipRule('evenodd')
								]),
							_List_Nil)
						]));
				return A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('execution-status completed')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'margin-right', '6px')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Completed')
								])),
							greenTick
						]));
		}
	}();
	return A2(
		$elm$html$Html$p,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'margin', '0 0 20px 0')
			]),
		_List_fromArray(
			[status]));
};
var $author$project$Page$AutomaticRoute$View$stopsCountView = function (_v0) {
	var execution = _v0.b;
	var solution = _v0.c;
	var locationsCountElement = function (locations) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('font-weight-bold')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Total Locations: ')
						])),
					$elm$html$Html$text(
					$elm$core$String$fromInt(locations))
				]));
	};
	var _v1 = solution.ht;
	if (_v1 === 2) {
		var _v2 = solution.f6;
		if (_v2.$ === 3) {
			var areaKPIs = _v2.a.ew;
			if (!areaKPIs.$) {
				var kpis = areaKPIs.a;
				var _v4 = kpis.dS;
				if (!_v4.$) {
					var stops = _v4.a;
					return A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('font-weight-bold')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Total stops: ')
									])),
								$elm$html$Html$text(
								$elm$core$String$fromInt(stops))
							]));
				} else {
					return $elm$html$Html$text('');
				}
			} else {
				return $elm$html$Html$text('');
			}
		} else {
			return $elm$html$Html$text('');
		}
	} else {
		var _v5 = execution.hQ;
		if (!_v5.$) {
			var locations = _v5.a;
			return locationsCountElement(locations);
		} else {
			return locationsCountElement(
				$author$project$Common$Locations$uniqueLocationsCount(execution.gL));
		}
	}
};
var $author$project$Page$AutomaticRoute$View$renderExecution = function (executionModel) {
	var index = executionModel.a;
	var execution = executionModel.b;
	var sol = executionModel.c;
	var rihgtColumnView = A2(
		$elm$html$Html$p,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-4 text-right')
			]),
		_List_fromArray(
			[
				$author$project$Page$AutomaticRoute$View$statusView(sol),
				$author$project$Page$AutomaticRoute$View$routesCountView(executionModel),
				$author$project$Page$AutomaticRoute$View$ordersCountView(executionModel),
				$author$project$Page$AutomaticRoute$View$routeItemsCountView(executionModel),
				$author$project$Page$AutomaticRoute$View$stopsCountView(executionModel),
				A3($author$project$Page$AutomaticRoute$View$saveView, index, execution, sol),
				A3($author$project$Page$AutomaticRoute$View$cancelSaveView, execution.f3, sol, index)
			]));
	var classes = $elm$html$Html$Attributes$class('execution-row row');
	var _v0 = function () {
		var _v1 = sol.f6;
		if (_v1.$ === 3) {
			var status = _v1.a.ht;
			var error = _v1.a.fs;
			return _Utils_Tuple2(
				_Utils_eq(
					status,
					$elm$core$Maybe$Just('failed')),
				error);
		} else {
			return _Utils_Tuple2(false, '');
		}
	}();
	var executionFailed = _v0.a;
	var executionErrorMsg = _v0.b;
	if (executionFailed) {
		var nameOrId = function () {
			var _v2 = _Utils_Tuple2(execution.gs, execution.f3);
			if (!_v2.a.$) {
				if (_v2.b.$ === 1) {
					var name = _v2.a.a;
					var _v3 = _v2.b;
					return name;
				} else {
					var name = _v2.a.a;
					return name;
				}
			} else {
				if (!_v2.b.$) {
					var _v4 = _v2.a;
					var id = _v2.b.a;
					return id;
				} else {
					var _v5 = _v2.a;
					var _v6 = _v2.b;
					return '';
				}
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[classes]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('execution-error-row')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(nameOrId),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'color', 'red')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Error')
								]))
						])),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'margin-bottom', '0')
						]),
					_List_fromArray(
						[
							A2(
							$author$project$Views$Common$Alert$errorAlertView,
							$elm$core$Maybe$Just(
								{gm: executionErrorMsg, dQ: $elm$core$Maybe$Nothing}),
							$elm$core$Maybe$Nothing)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'text-align', 'right'),
							A2($elm$html$Html$Attributes$style, 'width', '100%')
						]),
					_List_fromArray(
						[
							A3($author$project$Page$AutomaticRoute$View$cancelSaveView, execution.f3, sol, index)
						]))
				]));
	} else {
		var _v7 = _Utils_Tuple2(execution.gs, execution.f3);
		if (!_v7.a.$) {
			var name = _v7.a.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						classes,
						$elm$html$Html$Events$onClick(
						$author$project$Page$AutomaticRoute$Msg$SelectExecution(index))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('col-8')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(name),
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$author$project$Page$AutomaticRoute$View$kpisView(sol)
									]))
							])),
						rihgtColumnView
					]));
		} else {
			if (!_v7.b.$) {
				var id = _v7.b.a;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							classes,
							$elm$html$Html$Events$onClick(
							$author$project$Page$AutomaticRoute$Msg$SelectExecution(index))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-8')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(id),
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											$author$project$Page$AutomaticRoute$View$kpisView(sol)
										]))
								])),
							rihgtColumnView
						]));
			} else {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							$author$project$Page$AutomaticRoute$Msg$SelectExecution(index)),
							classes
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-8')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Execution not started')
								])),
							rihgtColumnView
						]));
			}
		}
	}
};
var $author$project$Views$Common$Maps$defaultGoogleMapAttributes = function (mapsApiKey) {
	return {eu: mapsApiKey, eE: $elm$core$Maybe$Nothing, eI: $elm$core$Maybe$Nothing, eP: $elm$core$Maybe$Nothing, bR: $elm$core$Maybe$Nothing, e8: false, e9: true, fa: true, fb: true, fc: false, fd: false, ff: false, fg: $elm$core$Maybe$Nothing, fH: false, fY: false, fZ: true, f$: false, f7: 'en', cG: '', gg: 'roadmap', gl: 18, gp: 0, hm: false, hZ: '3.34', h4: 3, h5: $elm$core$Maybe$Nothing};
};
var $author$project$Views$Helpers$GoogleMap$MarkerObject = function (a) {
	return {$: 0, a: a};
};
var $author$project$Views$Helpers$GoogleMap$PolygonObject = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Array$length = function (_v0) {
	var len = _v0.a;
	return len;
};
var $PaackEng$elm_svg_string$Svg$Types$Attribute = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $PaackEng$elm_svg_string$Svg$String$Attributes$attribute = $PaackEng$elm_svg_string$Svg$Types$Attribute;
var $PaackEng$elm_svg_string$Svg$Types$Node = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $PaackEng$elm_svg_string$Svg$Types$Regular = function (a) {
	return {$: 1, a: a};
};
var $PaackEng$elm_svg_string$Svg$String$node = F3(
	function (tag, attributes, children) {
		return A3(
			$PaackEng$elm_svg_string$Svg$Types$Node,
			tag,
			attributes,
			$PaackEng$elm_svg_string$Svg$Types$Regular(children));
	});
var $PaackEng$elm_svg_string$Svg$String$circle = $PaackEng$elm_svg_string$Svg$String$node('circle');
var $PaackEng$elm_svg_string$Svg$String$Attributes$cx = function (value) {
	return A2($PaackEng$elm_svg_string$Svg$String$Attributes$attribute, 'cx', value);
};
var $PaackEng$elm_svg_string$Svg$String$Attributes$cy = function (value) {
	return A2($PaackEng$elm_svg_string$Svg$String$Attributes$attribute, 'cy', value);
};
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $PaackEng$elm_svg_string$Svg$Types$closingTag = function (tagName) {
	return '</' + (tagName + '>');
};
var $elm$core$String$foldl = _String_foldl;
var $PaackEng$elm_svg_string$Svg$Types$escape = A2(
	$elm$core$String$foldl,
	F2(
		function (_char, acc) {
			return (_char === '\'') ? (acc + '&apos;') : _Utils_ap(
				acc,
				$elm$core$String$fromChar(_char));
		}),
	'');
var $elm$core$Char$toLower = _Char_toLower;
var $PaackEng$elm_svg_string$Svg$Types$hyphenate = A2(
	$elm$core$String$foldl,
	F2(
		function (_char, acc) {
			return $elm$core$Char$isUpper(_char) ? (acc + ('-' + $elm$core$String$fromChar(
				$elm$core$Char$toLower(_char)))) : _Utils_ap(
				acc,
				$elm$core$String$fromChar(_char));
		}),
	'');
var $PaackEng$elm_svg_string$Svg$Types$buildProp = F2(
	function (key, value) {
		return $PaackEng$elm_svg_string$Svg$Types$hyphenate(key) + ('=\'' + ($PaackEng$elm_svg_string$Svg$Types$escape(value) + '\''));
	});
var $PaackEng$elm_svg_string$Svg$Types$attributeToString = function (attribute) {
	switch (attribute.$) {
		case 0:
			var key = attribute.a;
			var value = attribute.b;
			return $elm$core$Maybe$Just(
				A2($PaackEng$elm_svg_string$Svg$Types$buildProp, key, value));
		case 1:
			var style = attribute.a;
			return $elm$core$Maybe$Just('style=\'' + (style + '\''));
		default:
			var string = attribute.a;
			var msgDecoder = attribute.b;
			return $elm$core$Maybe$Nothing;
	}
};
var $PaackEng$elm_svg_string$Svg$Types$tag = F2(
	function (tagName, attributes) {
		return '<' + (A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$cons,
				tagName,
				A2($elm$core$List$filterMap, $PaackEng$elm_svg_string$Svg$Types$attributeToString, attributes))) + '>');
	});
var $PaackEng$elm_svg_string$Svg$Types$svgToStringHelper = F3(
	function (indenter, tags, acc) {
		if (!tags.b) {
			var _v1 = acc.E;
			if (!_v1.b) {
				return acc;
			} else {
				var _v2 = _v1.a;
				var tagName = _v2.a;
				var cont = _v2.b;
				var rest = _v1.b;
				return A3(
					$PaackEng$elm_svg_string$Svg$Types$svgToStringHelper,
					indenter,
					cont,
					_Utils_update(
						acc,
						{
							l: acc.l - 1,
							W: A2(
								$elm$core$List$cons,
								A2(
									indenter,
									acc.l - 1,
									$PaackEng$elm_svg_string$Svg$Types$closingTag(tagName)),
								acc.W),
							E: rest
						}));
			}
		} else {
			if (!tags.a.$) {
				var _v3 = tags.a;
				var tagName = _v3.a;
				var attributes = _v3.b;
				var children = _v3.c;
				var rest = tags.b;
				switch (children.$) {
					case 0:
						return A3(
							$PaackEng$elm_svg_string$Svg$Types$svgToStringHelper,
							indenter,
							rest,
							_Utils_update(
								acc,
								{
									W: A2(
										$elm$core$List$cons,
										A2(
											indenter,
											acc.l,
											A2($PaackEng$elm_svg_string$Svg$Types$tag, tagName, attributes)),
										acc.W)
								}));
					case 1:
						var childNodes = children.a;
						return A3(
							$PaackEng$elm_svg_string$Svg$Types$svgToStringHelper,
							indenter,
							childNodes,
							_Utils_update(
								acc,
								{
									l: acc.l + 1,
									W: A2(
										$elm$core$List$cons,
										A2(
											indenter,
											acc.l,
											A2($PaackEng$elm_svg_string$Svg$Types$tag, tagName, attributes)),
										acc.W),
									E: A2(
										$elm$core$List$cons,
										_Utils_Tuple2(tagName, rest),
										acc.E)
								}));
					default:
						var childNodes = children.a;
						return A3(
							$PaackEng$elm_svg_string$Svg$Types$svgToStringHelper,
							indenter,
							A2($elm$core$List$map, $elm$core$Tuple$second, childNodes),
							_Utils_update(
								acc,
								{
									l: acc.l + 1,
									W: A2(
										$elm$core$List$cons,
										A2(
											indenter,
											acc.l,
											A2($PaackEng$elm_svg_string$Svg$Types$tag, tagName, attributes)),
										acc.W),
									E: A2(
										$elm$core$List$cons,
										_Utils_Tuple2(tagName, rest),
										acc.E)
								}));
				}
			} else {
				var string = tags.a.a;
				var rest = tags.b;
				return A3(
					$PaackEng$elm_svg_string$Svg$Types$svgToStringHelper,
					indenter,
					rest,
					_Utils_update(
						acc,
						{
							W: A2(
								$elm$core$List$cons,
								A2(indenter, acc.l, string),
								acc.W)
						}));
			}
		}
	});
var $PaackEng$elm_svg_string$Svg$Types$htmlToStringHelper = F3(
	function (indenter, _v0, acc) {
		var tagName = _v0.a;
		var attributes = _v0.b;
		var children = _v0.c;
		return A3(
			$PaackEng$elm_svg_string$Svg$Types$svgToStringHelper,
			indenter,
			children,
			_Utils_update(
				acc,
				{
					l: acc.l + 1,
					W: A2(
						$elm$core$List$cons,
						A2(
							indenter,
							acc.l,
							A2($PaackEng$elm_svg_string$Svg$Types$tag, tagName, attributes)),
						acc.W),
					E: A2(
						$elm$core$List$cons,
						_Utils_Tuple2(tagName, _List_Nil),
						acc.E)
				}));
	});
var $PaackEng$elm_svg_string$Svg$Types$indent = F3(
	function (perLevel, level, x) {
		return _Utils_ap(
			A2($elm$core$String$repeat, perLevel * level, ' '),
			x);
	});
var $PaackEng$elm_svg_string$Svg$Types$join = F2(
	function (between, list) {
		if (!list.b) {
			return '';
		} else {
			if (!list.b.b) {
				var x = list.a;
				return x;
			} else {
				var x = list.a;
				var xs = list.b;
				return A3(
					$elm$core$List$foldl,
					F2(
						function (y, acc) {
							return _Utils_ap(
								y,
								_Utils_ap(between, acc));
						}),
					x,
					xs);
			}
		}
	});
var $PaackEng$elm_svg_string$Svg$Types$toString = F2(
	function (depth, html) {
		var joinString = function () {
			if (!depth) {
				return '';
			} else {
				return '\n';
			}
		}();
		var initialAcc = {l: 0, W: _List_Nil, E: _List_Nil};
		var indenter = function () {
			if (!depth) {
				return $elm$core$Basics$always($elm$core$Basics$identity);
			} else {
				return $PaackEng$elm_svg_string$Svg$Types$indent(depth);
			}
		}();
		return A2(
			$PaackEng$elm_svg_string$Svg$Types$join,
			joinString,
			A3($PaackEng$elm_svg_string$Svg$Types$htmlToStringHelper, indenter, html, initialAcc).W);
	});
var $PaackEng$elm_svg_string$Svg$String$toString = function (indent) {
	return $PaackEng$elm_svg_string$Svg$Types$toString(indent);
};
var $author$project$Views$Helpers$Icon$encodeIcon = function (node) {
	return A2(
		$elm$core$String$append,
		'data:image/svg+xml,',
		$elm$url$Url$percentEncode(
			A2($PaackEng$elm_svg_string$Svg$String$toString, 0, node)));
};
var $PaackEng$elm_svg_string$Svg$String$Attributes$fill = function (value) {
	return A2($PaackEng$elm_svg_string$Svg$String$Attributes$attribute, 'fill', value);
};
var $PaackEng$elm_svg_string$Svg$String$Attributes$fillOpacity = function (value) {
	return A2($PaackEng$elm_svg_string$Svg$String$Attributes$attribute, 'fill-opacity', value);
};
var $PaackEng$elm_svg_string$Svg$String$Attributes$height = function (name) {
	return A2($PaackEng$elm_svg_string$Svg$String$Attributes$attribute, 'height', name);
};
var $PaackEng$elm_svg_string$Svg$String$Attributes$r = function (value) {
	return A2($PaackEng$elm_svg_string$Svg$String$Attributes$attribute, 'r', value);
};
var $PaackEng$elm_svg_string$Svg$String$rect = $PaackEng$elm_svg_string$Svg$String$node('rect');
var $PaackEng$elm_svg_string$Svg$String$Attributes$rx = function (value) {
	return A2($PaackEng$elm_svg_string$Svg$String$Attributes$attribute, 'rx', value);
};
var $PaackEng$elm_svg_string$Svg$Types$HtmlNode = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $PaackEng$elm_svg_string$Svg$String$htmlNode = F3(
	function (tag, attributes, children) {
		return A3($PaackEng$elm_svg_string$Svg$Types$HtmlNode, tag, attributes, children);
	});
var $PaackEng$elm_svg_string$Svg$String$svg = $PaackEng$elm_svg_string$Svg$String$htmlNode('svg');
var $PaackEng$elm_svg_string$Svg$String$Attributes$viewBox = function (name) {
	return A2($PaackEng$elm_svg_string$Svg$String$Attributes$attribute, 'viewBox', name);
};
var $PaackEng$elm_svg_string$Svg$String$Attributes$width = function (name) {
	return A2($PaackEng$elm_svg_string$Svg$String$Attributes$attribute, 'width', name);
};
var $PaackEng$elm_svg_string$Svg$String$Attributes$x = function (value) {
	return A2($PaackEng$elm_svg_string$Svg$String$Attributes$attribute, 'x', value);
};
var $PaackEng$elm_svg_string$Svg$String$Attributes$y = function (value) {
	return A2($PaackEng$elm_svg_string$Svg$String$Attributes$attribute, 'y', value);
};
var $author$project$Views$Helpers$Icon$genericPin = function (centerX) {
	return function (centerY) {
		return function (radius) {
			return function (stickWidth) {
				return function (stickHeight) {
					return function (shadowOffset) {
						return function (color) {
							return function (shadowColor) {
								return function (stickColor) {
									return function (opacity) {
										return function (shadowOpacity) {
											return function (extra) {
												var stickWidthStr = $elm$core$String$fromInt(stickWidth);
												var stickRadius = $elm$core$String$fromInt((stickWidth / 2) | 0);
												var stickHeightStr = $elm$core$String$fromInt(stickHeight);
												var stickCx = $elm$core$String$fromInt(centerX - ((stickWidth / 2) | 0));
												var shadowCenterYStr = $elm$core$String$fromInt(centerY + shadowOffset);
												var radiusStr = $elm$core$String$fromInt(radius);
												var diameter = 2 * radius;
												var stickCy = $elm$core$String$fromInt(diameter - 2);
												var totalHightStr = $elm$core$String$fromInt((stickHeight + diameter) - 2);
												var totalWidthStr = $elm$core$String$fromInt(diameter);
												var centerYStr = $elm$core$String$fromInt(centerY);
												var centerXStr = $elm$core$String$fromInt(centerX);
												var icon = A2(
													$PaackEng$elm_svg_string$Svg$String$svg,
													_List_fromArray(
														[
															$PaackEng$elm_svg_string$Svg$String$Attributes$width(totalWidthStr + 'px'),
															$PaackEng$elm_svg_string$Svg$String$Attributes$height(totalHightStr + 'px'),
															$PaackEng$elm_svg_string$Svg$String$Attributes$viewBox(
															A2(
																$elm$core$String$join,
																' ',
																_List_fromArray(
																	['0 0 ', totalWidthStr, totalHightStr]))),
															A2($PaackEng$elm_svg_string$Svg$String$Attributes$attribute, 'opacity', opacity),
															A2($PaackEng$elm_svg_string$Svg$String$Attributes$attribute, 'version', '1.1'),
															A2($PaackEng$elm_svg_string$Svg$String$Attributes$attribute, 'xmlns', 'http://www.w3.org/2000/svg'),
															A2($PaackEng$elm_svg_string$Svg$String$Attributes$attribute, 'xmlns:xlink', 'http://www.w3.org/1999/xlink')
														]),
													A2(
														$elm$core$List$append,
														_List_fromArray(
															[
																A2(
																$PaackEng$elm_svg_string$Svg$String$rect,
																_List_fromArray(
																	[
																		$PaackEng$elm_svg_string$Svg$String$Attributes$x(stickCx),
																		$PaackEng$elm_svg_string$Svg$String$Attributes$y(stickCy),
																		$PaackEng$elm_svg_string$Svg$String$Attributes$width(stickWidthStr),
																		$PaackEng$elm_svg_string$Svg$String$Attributes$height(stickHeightStr),
																		$PaackEng$elm_svg_string$Svg$String$Attributes$rx(stickRadius),
																		$PaackEng$elm_svg_string$Svg$String$Attributes$fill(stickColor)
																	]),
																_List_Nil),
																A2(
																$PaackEng$elm_svg_string$Svg$String$circle,
																_List_fromArray(
																	[
																		$PaackEng$elm_svg_string$Svg$String$Attributes$cx(centerXStr),
																		$PaackEng$elm_svg_string$Svg$String$Attributes$cy(shadowCenterYStr),
																		$PaackEng$elm_svg_string$Svg$String$Attributes$r(radiusStr),
																		$PaackEng$elm_svg_string$Svg$String$Attributes$fill(shadowColor),
																		$PaackEng$elm_svg_string$Svg$String$Attributes$fillOpacity(shadowOpacity)
																	]),
																_List_Nil),
																A2(
																$PaackEng$elm_svg_string$Svg$String$circle,
																_List_fromArray(
																	[
																		$PaackEng$elm_svg_string$Svg$String$Attributes$cx(centerXStr),
																		$PaackEng$elm_svg_string$Svg$String$Attributes$cy(centerYStr),
																		$PaackEng$elm_svg_string$Svg$String$Attributes$r(radiusStr),
																		$PaackEng$elm_svg_string$Svg$String$Attributes$fill(color)
																	]),
																_List_Nil)
															]),
														extra));
												return icon;
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $PaackEng$elm_svg_string$Svg$String$Attributes$fontFamily = function (value) {
	return A2($PaackEng$elm_svg_string$Svg$String$Attributes$attribute, 'font-family', value);
};
var $PaackEng$elm_svg_string$Svg$Types$Style = function (a) {
	return {$: 1, a: a};
};
var $PaackEng$elm_svg_string$Svg$String$Attributes$style = $PaackEng$elm_svg_string$Svg$Types$Style;
var $PaackEng$elm_svg_string$Svg$Types$TextNode = function (a) {
	return {$: 1, a: a};
};
var $PaackEng$elm_svg_string$Svg$String$text = $PaackEng$elm_svg_string$Svg$Types$TextNode;
var $PaackEng$elm_svg_string$Svg$String$text_ = $PaackEng$elm_svg_string$Svg$String$node('text');
var $author$project$Views$Helpers$Icon$pinLabel = F3(
	function (xStr, yStr, str) {
		return A2(
			$PaackEng$elm_svg_string$Svg$String$text_,
			_List_fromArray(
				[
					$PaackEng$elm_svg_string$Svg$String$Attributes$x(xStr),
					$PaackEng$elm_svg_string$Svg$String$Attributes$y(yStr),
					$PaackEng$elm_svg_string$Svg$String$Attributes$fill('#0E1520'),
					$PaackEng$elm_svg_string$Svg$String$Attributes$fontFamily('Helvetica'),
					$PaackEng$elm_svg_string$Svg$String$Attributes$style('text-anchor: middle')
				]),
			_List_fromArray(
				[
					$PaackEng$elm_svg_string$Svg$String$text(str)
				]));
	});
var $author$project$Views$Helpers$Icon$routePinNumStr = F3(
	function (pinColor, label, border) {
		var pinDecoration = A3($author$project$Views$Helpers$Icon$pinLabel, '20', '25', label);
		var extras = function () {
			if (!border.$) {
				var _v1 = border.a;
				var borderWidth = _v1.a;
				var borderColor = _v1.b;
				return _List_fromArray(
					[
						A2(
						$PaackEng$elm_svg_string$Svg$String$circle,
						_List_fromArray(
							[
								$PaackEng$elm_svg_string$Svg$String$Attributes$cx(
								$elm$core$String$fromInt(20)),
								$PaackEng$elm_svg_string$Svg$String$Attributes$cy(
								$elm$core$String$fromInt(20)),
								$PaackEng$elm_svg_string$Svg$String$Attributes$r(
								$elm$core$String$fromInt(19)),
								A2($PaackEng$elm_svg_string$Svg$String$Attributes$attribute, 'stroke', borderColor),
								A2(
								$PaackEng$elm_svg_string$Svg$String$Attributes$attribute,
								'stroke-width',
								$elm$core$String$fromInt(borderWidth)),
								A2($PaackEng$elm_svg_string$Svg$String$Attributes$attribute, 'fill-opacity', '0.1')
							]),
						_List_Nil),
						pinDecoration
					]);
			} else {
				return _List_fromArray(
					[pinDecoration]);
			}
		}();
		var icon = $author$project$Views$Helpers$Icon$genericPin(20)(20)(20)(4)(32)(2)(pinColor)('#000')('#E5E5E5')('1')('1')(extras);
		return $author$project$Views$Helpers$Icon$encodeIcon(icon);
	});
var $author$project$Views$Helpers$Icon$routePinNum = F3(
	function (pinColor, num, border) {
		return A3(
			$author$project$Views$Helpers$Icon$routePinNumStr,
			pinColor,
			$elm$core$String$fromInt(num),
			border);
	});
var $author$project$Views$Helpers$Colors$routeThemeColors = _List_fromArray(
	['blue', 'red', 'green', 'coral', 'orange', 'violet', 'white']);
var $author$project$Views$MapAssignment$MapView$drawRouteFromSolution = function (solution) {
	var selectRouteColor = function (index) {
		var mapColor = $elm$core$Array$fromList($author$project$Views$Helpers$Colors$routeThemeColors);
		return A2(
			$elm$core$Maybe$withDefault,
			'blue',
			A2(
				$elm$core$Array$get,
				index % $elm$core$Array$length(mapColor),
				mapColor));
	};
	var generatePinsFromActivities = F3(
		function (routeIndex, route, color) {
			return A2(
				$elm$core$List$indexedMap,
				F2(
					function (index, _v2) {
						var address = _v2.er;
						return $author$project$Views$Helpers$GoogleMap$MarkerObject(
							{
								et: $elm$core$Maybe$Just(
									_Utils_Tuple2(20, 70)),
								eM: $elm$core$Maybe$Nothing,
								eR: false,
								ff: false,
								fy: false,
								fR: A3(
									$author$project$Views$Helpers$Icon$routePinNum,
									color,
									routeIndex + 1,
									function () {
										var _v3 = route.fh;
										if (!_v3.$) {
											return $elm$core$Maybe$Nothing;
										} else {
											return $elm$core$Maybe$Just(
												_Utils_Tuple2(2, '#fedd3e'));
										}
									}()),
								fS: 'R' + ($elm$core$String$fromInt(routeIndex + 1) + ('A' + $elm$core$String$fromInt(index + 1))),
								f9: address.f8,
								gf: address.ge,
								hn: $elm$core$Maybe$Just(
									_Utils_Tuple2(40, 70)),
								el: 5
							});
					}),
				A2(
					$elm$core$List$filter,
					function (a) {
						return a.ep === 3;
					},
					route.eo));
		});
	var convertToMapPoint = function (_v1) {
		var lat = _v1.a;
		var lng = _v1.b;
		return {f8: lat, gb: lng};
	};
	var pointToPolygon = function (point) {
		if (!point.$) {
			var coordinates = point.a.bR;
			return $author$project$Views$Helpers$GoogleMap$PolygonObject(
				{
					eO: false,
					fD: 'red',
					fE: 1,
					gU: _List_fromArray(
						[
							convertToMapPoint(coordinates)
						]),
					hx: 'red',
					hy: 10,
					el: 1
				});
		} else {
			var coordinates = point.a.bR;
			return $author$project$Views$Helpers$GoogleMap$PolygonObject(
				{
					eO: false,
					fD: 'blue',
					fE: 1,
					gU: A2($elm$core$List$map, convertToMapPoint, coordinates),
					hx: 'blue',
					hy: 5,
					el: 1
				});
		}
	};
	var routeDirections = A2(
		$elm$core$List$map,
		A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.gU;
			},
			$elm$core$List$map(pointToPolygon)),
		solution.hc);
	return $elm$core$List$concat(
		A2(
			$elm$core$List$append,
			routeDirections,
			A2(
				$elm$core$List$indexedMap,
				F2(
					function (index, route) {
						return A3(
							generatePinsFromActivities,
							index,
							route,
							selectRouteColor(index));
					}),
				solution.hc)));
};
var $author$project$Page$AutomaticRouteSolution$View$filterNonRejected = F2(
	function (_v0, route) {
		var rejected = _v0.g3;
		return A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				function (uuid) {
					return !A2($elm$core$List$member, uuid, rejected);
				},
				route.hW));
	});
var $author$project$Views$Helpers$GoogleMap$fitToMarkers = F2(
	function (bool, gma) {
		return _Utils_update(
			gma,
			{fH: bool});
	});
var $author$project$Views$Helpers$GoogleMap$setDraggable = F2(
	function (flag, gma) {
		return _Utils_update(
			gma,
			{ff: flag});
	});
var $author$project$Views$Helpers$GoogleMap$setMapStyle = F2(
	function (mapStyle, gma) {
		return _Utils_update(
			gma,
			{cG: mapStyle});
	});
var $author$project$Views$Helpers$GoogleMap$setZoom = F2(
	function (zoom, gma) {
		return _Utils_update(
			gma,
			{h4: zoom});
	});
var $author$project$Utils$boolToString = function (bool) {
	return bool ? 'True' : 'False';
};
var $author$project$Views$Helpers$GoogleMap$boolToStringLower = A2($elm$core$Basics$composeR, $author$project$Utils$boolToString, $elm$core$String$toLower);
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$node = $elm$virtual_dom$VirtualDom$node;
var $author$project$Views$Helpers$GoogleMap$buildDrawingManager = function (_v0) {
	var drawingComplete = _v0.fg;
	var isActive = _v0.fY;
	var isDrawing = _v0.f$;
	if (!drawingComplete.$) {
		var event = drawingComplete.a;
		var coordinatesDecoder = A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['detail']),
			$elm$json$Json$Decode$list(
				A3(
					$elm$json$Json$Decode$map2,
					$author$project$Data$Map$Coordinates,
					A2($elm$json$Json$Decode$field, 'lat', $elm$json$Json$Decode$float),
					A2($elm$json$Json$Decode$field, 'lng', $elm$json$Json$Decode$float))));
		return A3(
			$elm$html$Html$node,
			'google-map-drawing-tool',
			_List_fromArray(
				[
					A2(
					$elm$html$Html$Attributes$attribute,
					'disabled',
					$author$project$Views$Helpers$GoogleMap$boolToStringLower(!isActive)),
					A2(
					$elm$html$Html$Attributes$attribute,
					'drawing',
					$author$project$Views$Helpers$GoogleMap$boolToStringLower(isDrawing)),
					A2(
					$elm$html$Html$Events$on,
					'on-polygon-complete',
					A2($elm$json$Json$Decode$map, event, coordinatesDecoder))
				]),
			_List_Nil);
	} else {
		return $elm$html$Html$text('');
	}
};
var $author$project$Views$Helpers$GoogleMap$buildMarker = function (marker) {
	var size_attributes = A2(
		$elm$core$Maybe$withDefault,
		_List_Nil,
		A2(
			$elm$core$Maybe$map,
			function (_v0) {
				var w = _v0.a;
				var h = _v0.b;
				return _List_fromArray(
					[
						A2(
						$elm$html$Html$Attributes$attribute,
						'width',
						$elm$core$String$fromInt(w)),
						A2(
						$elm$html$Html$Attributes$attribute,
						'height',
						$elm$core$String$fromInt(h))
					]);
			},
			marker.hn));
	var icon_attributes = _List_fromArray(
		[
			A2($elm$html$Html$Attributes$attribute, 'id', marker.fS),
			A2(
			$elm$html$Html$Attributes$attribute,
			'latitude',
			$elm$core$String$fromFloat(marker.f9)),
			A2(
			$elm$html$Html$Attributes$attribute,
			'longitude',
			$elm$core$String$fromFloat(marker.gf)),
			A2(
			$elm$html$Html$Attributes$attribute,
			'z-index',
			$elm$core$String$fromInt(marker.el)),
			A2($elm$html$Html$Attributes$attribute, 'icon', marker.fR),
			A2(
			$elm$html$Html$Attributes$attribute,
			'draggable',
			$author$project$Views$Helpers$GoogleMap$boolToStringLower(marker.ff)),
			A2(
			$elm$html$Html$Attributes$attribute,
			'clusterable',
			$author$project$Views$Helpers$GoogleMap$boolToStringLower(marker.eR)),
			A2($elm$html$Html$Attributes$attribute, 'slot', 'markers')
		]);
	var click_event = A2(
		$elm$core$Maybe$withDefault,
		_List_Nil,
		A2(
			$elm$core$Maybe$map,
			function (click) {
				return _List_fromArray(
					[
						A2(
						$elm$html$Html$Events$on,
						'google-map-marker-click',
						$elm$json$Json$Decode$succeed(click)),
						A2($elm$html$Html$Attributes$attribute, 'click-events', 'true')
					]);
			},
			marker.eM));
	var final_attributes = A2(
		$elm$core$List$append,
		click_event,
		A2($elm$core$List$append, size_attributes, icon_attributes));
	return A3($elm$html$Html$node, 'google-map-marker-with-clusters', final_attributes, _List_Nil);
};
var $author$project$Views$Helpers$GoogleMap$buildPolygon = function (polygon) {
	var mapPoint = function (point) {
		return A3(
			$elm$html$Html$node,
			'google-map-point',
			_List_fromArray(
				[
					A2(
					$elm$html$Html$Attributes$attribute,
					'latitude',
					$elm$core$String$fromFloat(point.f8)),
					A2(
					$elm$html$Html$Attributes$attribute,
					'longitude',
					$elm$core$String$fromFloat(point.gb))
				]),
			_List_Nil);
	};
	var mapPoints = A2(
		$elm$core$List$map,
		function (point) {
			return mapPoint(point);
		},
		polygon.gU);
	var closedAttribute = polygon.eO ? _List_fromArray(
		[
			A2($elm$html$Html$Attributes$attribute, 'closed', '')
		]) : _List_Nil;
	var polygonAttributes = A2(
		$elm$core$List$append,
		closedAttribute,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$attribute, 'fill-color', polygon.fD),
				A2(
				$elm$html$Html$Attributes$attribute,
				'fill-opacity',
				$elm$core$String$fromFloat(polygon.fE)),
				A2(
				$elm$html$Html$Attributes$attribute,
				'stroke-weight',
				$elm$core$String$fromInt(polygon.hy)),
				A2($elm$html$Html$Attributes$attribute, 'stroke-color', polygon.hx),
				A2(
				$elm$html$Html$Attributes$attribute,
				'z-index',
				$elm$core$String$fromInt(polygon.el))
			]));
	return A3($elm$html$Html$node, 'google-map-poly', polygonAttributes, mapPoints);
};
var $author$project$Views$Helpers$GoogleMap$decodeBoundsValue = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Views$Helpers$GoogleMap$Bounds,
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['detail', 'minX']),
		$elm$json$Json$Decode$float),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['detail', 'minY']),
		$elm$json$Json$Decode$float),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['detail', 'maxX']),
		$elm$json$Json$Decode$float),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['detail', 'maxY']),
		$elm$json$Json$Decode$float));
var $author$project$Views$Helpers$GoogleMap$decodeZoomValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['detail', 'value']),
	$elm$json$Json$Decode$int);
var $author$project$Views$Helpers$GoogleMap$view = F2(
	function (googleMapAttributes, mapObjects) {
		var zoomChangeListener = function () {
			var _v5 = googleMapAttributes.h5;
			if (!_v5.$) {
				var event = _v5.a;
				return _List_fromArray(
					[
						A2(
						$elm$html$Html$Events$on,
						'google-map-zoom-change',
						A2($elm$json$Json$Decode$map, event, $author$project$Views$Helpers$GoogleMap$decodeZoomValue))
					]);
			} else {
				return _List_Nil;
			}
		}();
		var markerObjects = A2(
			$elm$core$List$cons,
			$author$project$Views$Helpers$GoogleMap$buildDrawingManager(googleMapAttributes),
			A2(
				$elm$core$List$map,
				function (mapObject) {
					if (!mapObject.$) {
						var marker = mapObject.a;
						return $author$project$Views$Helpers$GoogleMap$buildMarker(marker);
					} else {
						var poly = mapObject.a;
						return $author$project$Views$Helpers$GoogleMap$buildPolygon(poly);
					}
				},
				mapObjects));
		var mapCoordinates = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				function (_v3) {
					var lat = _v3.a;
					var lng = _v3.b;
					return _List_fromArray(
						[
							A2(
							$elm$html$Html$Attributes$attribute,
							'latitude',
							$elm$core$String$fromFloat(lat)),
							A2(
							$elm$html$Html$Attributes$attribute,
							'longitude',
							$elm$core$String$fromFloat(lng))
						]);
				},
				googleMapAttributes.bR));
		var fitToMarkersAttribute = googleMapAttributes.fH ? _List_fromArray(
			[
				A2($elm$html$Html$Attributes$attribute, 'fit-to-markers', 'true')
			]) : _List_Nil;
		var disableAttributes = A2(
			$elm$core$List$map,
			function (_v2) {
				var name = _v2.a;
				return A2($elm$html$Html$Attributes$attribute, name, 'true');
			},
			A2(
				$elm$core$List$filter,
				$elm$core$Tuple$second,
				_List_fromArray(
					[
						_Utils_Tuple2('disable-default-ui', googleMapAttributes.e8),
						_Utils_Tuple2('disable-zoom-control', googleMapAttributes.fd),
						_Utils_Tuple2('disable-map-type-control', googleMapAttributes.fa),
						_Utils_Tuple2('disable-street-view-control', googleMapAttributes.fb),
						_Utils_Tuple2('disable-fullscreen-control', googleMapAttributes.e9),
						_Utils_Tuple2('disable-zoom', googleMapAttributes.fc)
					])));
		var clusterSettingsAttributes = function () {
			var _v1 = googleMapAttributes.eP;
			if (!_v1.$) {
				var gridSize = _v1.a.fN;
				var minPinsToCluster = _v1.a.go;
				return _List_fromArray(
					[
						A2(
						$elm$html$Html$Attributes$attribute,
						'grid-size',
						$elm$core$String$fromInt(gridSize)),
						A2(
						$elm$html$Html$Attributes$attribute,
						'min-pins-to-cluster',
						$elm$core$String$fromInt(minPinsToCluster))
					]);
			} else {
				return _List_Nil;
			}
		}();
		var centerChangeListener = _List_Nil;
		var boundsChangeListener = function () {
			var _v0 = googleMapAttributes.eE;
			if (!_v0.$) {
				var event = _v0.a;
				return _List_fromArray(
					[
						A2(
						$elm$html$Html$Events$on,
						'google-map-bounds-change',
						A2($elm$json$Json$Decode$map, event, $author$project$Views$Helpers$GoogleMap$decodeBoundsValue))
					]);
			} else {
				return _List_Nil;
			}
		}();
		var attributes = A2(
			$elm$core$List$append,
			clusterSettingsAttributes,
			A2(
				$elm$core$List$append,
				boundsChangeListener,
				A2(
					$elm$core$List$append,
					centerChangeListener,
					A2(
						$elm$core$List$append,
						zoomChangeListener,
						A2(
							$elm$core$List$append,
							mapCoordinates,
							A2(
								$elm$core$List$append,
								fitToMarkersAttribute,
								A2(
									$elm$core$List$append,
									disableAttributes,
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$attribute, 'api-key', googleMapAttributes.eu),
											A2($elm$html$Html$Attributes$attribute, 'version', googleMapAttributes.hZ),
											A2($elm$html$Html$Attributes$attribute, 'language', googleMapAttributes.f7),
											A2($elm$html$Html$Attributes$attribute, 'map-type', googleMapAttributes.gg),
											A2(
											$elm$html$Html$Attributes$attribute,
											'max-zoom',
											$elm$core$String$fromInt(googleMapAttributes.gl)),
											A2(
											$elm$html$Html$Attributes$attribute,
											'min-zoom',
											$elm$core$String$fromInt(googleMapAttributes.gp)),
											A2(
											$elm$html$Html$Attributes$attribute,
											'single-info-window',
											$author$project$Views$Helpers$GoogleMap$boolToStringLower(googleMapAttributes.hm)),
											A2(
											$elm$html$Html$Attributes$attribute,
											'zoom',
											$elm$core$String$fromInt(googleMapAttributes.h4)),
											A2(
											$elm$html$Html$Attributes$attribute,
											'draggable',
											$author$project$Views$Helpers$GoogleMap$boolToStringLower(googleMapAttributes.ff)),
											A2($elm$html$Html$Attributes$attribute, 'styles', googleMapAttributes.cG),
											A2(
											$elm$html$Html$Attributes$attribute,
											'is-clustered',
											$author$project$Views$Helpers$GoogleMap$boolToStringLower(googleMapAttributes.fZ))
										]))))))));
		return A3($elm$html$Html$node, 'google-map-with-clusters', attributes, markerObjects);
	});
var $author$project$Page$AutomaticRouteSolution$View$mapView = F3(
	function (_v0, mapStyle, selectedSolution) {
		var mapsApiKey = _v0.gi;
		var filteredRoutesSolution = function (_v2) {
			var sol = _v2.a;
			var routeUpdates = _v2.b;
			var newRoutes = A2(
				$elm$core$List$filter,
				$author$project$Page$AutomaticRouteSolution$View$filterNonRejected(routeUpdates),
				sol.hc);
			return _Utils_update(
				sol,
				{hc: newRoutes});
		};
		var routeMarkers = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				$author$project$Views$MapAssignment$MapView$drawRouteFromSolution,
				A2(
					$elm$core$Maybe$map,
					filteredRoutesSolution,
					A2(
						$elm$core$Maybe$map,
						function (_v1) {
							var sol = _v1.b;
							var rej = _v1.c;
							return _Utils_Tuple2(sol, rej);
						},
						selectedSolution))));
		var attrs = A2(
			$author$project$Views$Helpers$GoogleMap$setMapStyle,
			mapStyle,
			A2(
				$author$project$Views$Helpers$GoogleMap$setDraggable,
				true,
				A2(
					$author$project$Views$Helpers$GoogleMap$setZoom,
					14,
					A2(
						$author$project$Views$Helpers$GoogleMap$fitToMarkers,
						true,
						$author$project$Views$Common$Maps$defaultGoogleMapAttributes(mapsApiKey)))));
		return A2($author$project$Views$Helpers$GoogleMap$view, attrs, routeMarkers);
	});
var $author$project$Page$AutomaticRouteSolution$Msg$CancelProcess = {$: 5};
var $author$project$Page$AutomaticRouteSolution$Msg$ClearError = {$: 18};
var $author$project$Views$Common$BootstrapButtons$genericButton = F3(
	function (maybeEvent, textLabel, btnType) {
		var defaultAttrs = _List_fromArray(
			[
				$elm$html$Html$Attributes$type_('button'),
				$elm$html$Html$Attributes$class('btn btn-' + btnType)
			]);
		var attrs = function () {
			if (!maybeEvent.$) {
				var event = maybeEvent.a;
				return A2(
					$elm$core$List$cons,
					$elm$html$Html$Events$onClick(event),
					defaultAttrs);
			} else {
				return defaultAttrs;
			}
		}();
		return A2(
			$elm$html$Html$button,
			attrs,
			_List_fromArray(
				[
					$elm$html$Html$text(textLabel)
				]));
	});
var $author$project$Views$Common$BootstrapButtons$danger = F2(
	function (event, textLabel) {
		return A3($author$project$Views$Common$BootstrapButtons$genericButton, event, textLabel, 'danger');
	});
var $author$project$Page$AutomaticRouteSolution$Msg$ConfirmSave = function (a) {
	return {$: 12, a: a};
};
var $author$project$Page$AutomaticRouteSolution$Msg$SaveSelectedSolution = {$: 13};
var $author$project$Page$AutomaticRouteSolution$View$saveConfirmation = function (toConfirmSave) {
	return toConfirmSave ? A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-sm-12')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('alert alert-warning')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('This will save the current solution')
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('row save-confirmation')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('col-sm-4')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('btn btn-success col-sm-12'),
										$elm$html$Html$Events$onClick($author$project$Page$AutomaticRouteSolution$Msg$SaveSelectedSolution)
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Yes')
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('col-sm-4')
							]),
						_List_Nil),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('col-sm-4')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('btn btn-danger col-sm-12'),
										$elm$html$Html$Events$onClick(
										$author$project$Page$AutomaticRouteSolution$Msg$ConfirmSave(false))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('No')
									]))
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('')
					]))
			])) : A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-sm')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('btn btn-success'),
						$elm$html$Html$Events$onClick(
						$author$project$Page$AutomaticRouteSolution$Msg$ConfirmSave(true))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Generate Routes')
					]))
			]));
};
var $author$project$Page$AutomaticRouteSolution$View$deletedOrdersView = F3(
	function (routes, deletedRoutes, toConfirmSave) {
		var numberOrders = $elm$core$List$sum(
			A2(
				$elm$core$List$map,
				$elm$core$List$length,
				A2(
					$elm$core$List$map,
					function (route) {
						return A2(
							$elm$core$List$filter,
							function (x) {
								return x.ep === 2;
							},
							route.eo);
					},
					A2(
						$elm$core$List$filter,
						function (x) {
							return A2(
								$elm$core$Maybe$withDefault,
								false,
								A2(
									$elm$core$Maybe$map,
									function (uuid) {
										return A2($elm$core$List$member, uuid, deletedRoutes);
									},
									x.hW));
						},
						routes))));
		return (numberOrders > 0) ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('row'),
					A2($elm$html$Html$Attributes$style, 'margin-top', '12px')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('col-sm')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Orders not routed')
								])),
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$elm$core$String$fromInt(numberOrders) + ' orders')
								]))
						])),
					$author$project$Page$AutomaticRouteSolution$View$saveConfirmation(toConfirmSave)
				])) : $elm$html$Html$text('');
	});
var $krisajenkins$remotedata$RemoteData$isFailure = function (data) {
	if (data.$ === 2) {
		var x = data.a;
		return true;
	} else {
		return false;
	}
};
var $author$project$Page$AutomaticRouteSolution$Msg$DeleteRoute = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$AutomaticRouteSolution$View$deleteIcon = function (clickMsg) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('20'),
				$elm$svg$Svg$Attributes$height('20'),
				$elm$svg$Svg$Attributes$fill('none'),
				$elm$svg$Svg$Events$onClick(clickMsg)
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$d('M12.219.667c2.083 0 3.092.195 4.141.755a5.344 5.344 0 0 1 2.218 2.218c.56 1.049.755 2.058.755 4.141v4.438c0 2.083-.195 3.092-.755 4.141a5.344 5.344 0 0 1-2.218 2.218c-1.049.56-2.058.755-4.141.755H7.78c-2.083 0-3.092-.195-4.141-.755a5.344 5.344 0 0 1-2.218-2.218c-.56-1.049-.755-2.058-.755-4.141V7.78c0-2.083.195-3.092.755-4.141A5.345 5.345 0 0 1 3.64 1.422C4.689.862 5.698.667 7.78.667h4.438ZM6.807 5.252a1.105 1.105 0 0 0-1.484.071l-.071.08-.063.083a1.105 1.105 0 0 0 .134 1.4L8.438 10l-3.115 3.114-.071.08a1.105 1.105 0 0 0 .071 1.482l.08.072.083.063c.43.29 1.019.246 1.4-.135L10 11.562l3.114 3.114.08.072a1.105 1.105 0 0 0 1.482-.072l.072-.078.063-.084a1.105 1.105 0 0 0-.135-1.4L11.562 10l3.114-3.114.072-.08a1.105 1.105 0 0 0-.072-1.483l-.078-.071-.084-.063a1.105 1.105 0 0 0-1.4.134L10 8.438 6.886 5.323l-.08-.071Z'),
						$elm$svg$Svg$Attributes$fill('#D83143')
					]),
				_List_Nil)
			]));
};
var $author$project$Page$AutomaticRouteSolution$Msg$AssignDriverToRoute = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $author$project$Page$AutomaticRouteSolution$Msg$OnDriverAssignmentInput = function (a) {
	return {$: 14, a: a};
};
var $elm$virtual_dom$VirtualDom$Custom = function (a) {
	return {$: 3, a: a};
};
var $elm$html$Html$Events$custom = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Custom(decoder));
	});
var $author$project$HtmlExtra$clickWithoutPropagation = function (msg) {
	return A2(
		$elm$html$Html$Events$custom,
		'click',
		$elm$json$Json$Decode$succeed(
			{gm: msg, g$: true, hv: true}));
};
var $author$project$Page$AutomaticRouteSolution$View$driverAssignmentView = F3(
	function (model, index, routeData) {
		var updatingRouteData = model.bI;
		var driverInfo = function (driver) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('driver-selection pl-1'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'selected',
								_Utils_eq(
									routeData.fh,
									$elm$core$Maybe$Just(driver)))
							]))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('name'),
								$author$project$HtmlExtra$clickWithoutPropagation(
								A2(
									$author$project$Page$AutomaticRouteSolution$Msg$AssignDriverToRoute,
									routeData,
									$elm$core$Maybe$Just(driver)))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(driver.fJ)
							]))
					]));
		};
		var contains = F2(
			function (str1, driver) {
				return A2(
					$elm$core$String$contains,
					$elm$core$String$toLower(str1),
					$elm$core$String$toLower(driver.fJ));
			});
		if (!updatingRouteData.$) {
			var uuid = updatingRouteData.a.hW;
			var filteredDrivers = (model.b0 === '') ? model.b_ : A2(
				$elm$core$List$filter,
				contains(model.b0),
				model.b_);
			var bigListDrivers = $elm$core$List$length(model.b_) > 20;
			var driverListStyle = bigListDrivers ? 'solution-route-driver-list big-driver-list ' : 'solution-route-driver-list';
			var itemDisplayStyle = ((index < 5) || bigListDrivers) ? '' : 'routeScrolled';
			return A2(
				$author$project$HtmlExtra$when,
				_Utils_eq(uuid, routeData.hW),
				A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('route-driver-list-container ' + driverListStyle)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('route-driver-list undelivered ' + itemDisplayStyle)
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$type_('text'),
											$elm$html$Html$Attributes$class('driver-route-list-input mb-3'),
											$elm$html$Html$Events$onInput($author$project$Page$AutomaticRouteSolution$Msg$OnDriverAssignmentInput)
										]),
									_List_Nil),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('pre-driver-list')
										]),
									A2($elm$core$List$map, driverInfo, filteredDrivers))
								]))
						])));
		} else {
			return $elm$html$Html$text('');
		}
	});
var $elm$virtual_dom$VirtualDom$lazy3 = _VirtualDom_lazy3;
var $elm$html$Html$Lazy$lazy3 = $elm$virtual_dom$VirtualDom$lazy3;
var $author$project$Page$AutomaticRouteSolution$Msg$ChangeRouteDriver = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$AutomaticRouteSolution$Msg$UnselectRouteDriver = {$: 9};
var $author$project$Page$AutomaticRouteSolution$View$routeDriverBadge = F2(
	function (model, routeData) {
		var updatingRouteData = model.bI;
		var _v0 = routeData.fh;
		if (_v0.$ === 1) {
			var _v1 = function () {
				if (!updatingRouteData.$) {
					var uuid = updatingRouteData.a.hW;
					return _Utils_eq(uuid, routeData.hW) ? _Utils_Tuple2($author$project$Page$AutomaticRouteSolution$Msg$UnselectRouteDriver, 'selected-driver ') : _Utils_Tuple2(
						$author$project$Page$AutomaticRouteSolution$Msg$ChangeRouteDriver(routeData),
						'');
				} else {
					return _Utils_Tuple2(
						$author$project$Page$AutomaticRouteSolution$Msg$ChangeRouteDriver(routeData),
						'');
				}
			}();
			var event = _v1.a;
			var badgeStyle = _v1.b;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('badge badge-driver ' + badgeStyle)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('clickable big-icon'),
								$author$project$HtmlExtra$clickWithoutPropagation(event)
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$i,
								_List_fromArray(
									[
										A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
										$elm$html$Html$Attributes$class('fa fa-user-plus'),
										$elm$html$Html$Attributes$title('Assign predriver')
									]),
								_List_Nil)
							]))
					]));
		} else {
			var driverAssigned = A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('badge badge-driver driver-assigned')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('clickable big-icon '),
								$author$project$HtmlExtra$clickWithoutPropagation(
								$author$project$Page$AutomaticRouteSolution$Msg$ChangeRouteDriver(routeData))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$i,
								_List_fromArray(
									[
										A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
										$elm$html$Html$Attributes$class('fa fa-user-check'),
										$elm$html$Html$Attributes$title('Change predriver')
									]),
								_List_Nil)
							]))
					]));
			if (!updatingRouteData.$) {
				var uuid = updatingRouteData.a.hW;
				return _Utils_eq(uuid, routeData.hW) ? A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('badge badge-driver delete-driver')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('clickable big-icon'),
									$author$project$HtmlExtra$clickWithoutPropagation(
									A2($author$project$Page$AutomaticRouteSolution$Msg$AssignDriverToRoute, routeData, $elm$core$Maybe$Nothing))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
											$elm$html$Html$Attributes$class('fa fa-user-times'),
											$elm$html$Html$Attributes$title('Unassign predriver')
										]),
									_List_Nil)
								]))
						])) : driverAssigned;
			} else {
				return driverAssigned;
			}
		}
	});
var $author$project$Page$AutomaticRouteSolution$View$routeKpis = function (kpi) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('route-kpis')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('route-kpis-col')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('font-weight-bold')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Orders: ')
									])),
								A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$elm$core$String$fromInt(kpi.c1))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('font-weight-bold')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Stops: ')
									])),
								A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										A2(
											$elm$core$Maybe$withDefault,
											'Unknown',
											A2($elm$core$Maybe$map, $elm$core$String$fromInt, kpi.dS)))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('font-weight-bold')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Route items: ')
									])),
								A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$elm$core$String$fromInt(kpi.dv))
									]))
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('route-kpis-col')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('font-weight-bold')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Productivity: ')
									])),
								A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										A2($myrho$elm_round$Round$round, 1, kpi.dd))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('font-weight-bold')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Distance: ')
									])),
								A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										A2($myrho$elm_round$Round$round, 1, kpi.ab) + ' km')
									]))
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('route-kpis-col')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('font-weight-bold')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Working time: ')
									])),
								A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										A2($myrho$elm_round$Round$round, 1, kpi.eg) + ' h')
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('font-weight-bold')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Waiting time: ')
									])),
								A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										A2($myrho$elm_round$Round$round, 1, kpi.eb) + ' h')
									]))
							]))
					]))
			]));
};
var $author$project$Page$AutomaticRouteSolution$View$routeView = F3(
	function (model, index, route) {
		var routeName = 'Route ' + $elm$core$String$fromInt(index + 1);
		var routeTitle = function () {
			var _v2 = route.fh;
			if (!_v2.$) {
				var driver = _v2.a;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('route-driver-title')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(routeName + (' - ' + driver.fJ))
						]));
			} else {
				return $elm$html$Html$text(routeName);
			}
		}();
		var routeKpisView = function () {
			var _v0 = _Utils_Tuple2(route.hW, model.f6);
			if ((!_v0.a.$) && (_v0.b.$ === 3)) {
				var routeId = _v0.a.a;
				var routeKPIs = _v0.b.a.hb;
				if (!routeKPIs.$) {
					var kpis = routeKPIs.a;
					return A2(
						$elm$core$Maybe$withDefault,
						$elm$html$Html$text('KPI\'s not found for the route'),
						A2(
							$elm$core$Maybe$map,
							function (kpi) {
								return $author$project$Page$AutomaticRouteSolution$View$routeKpis(kpi);
							},
							$elm$core$List$head(
								A2(
									$elm$core$List$filter,
									function (kpi) {
										return _Utils_eq(kpi.du, routeId);
									},
									kpis))));
				} else {
					return $elm$html$Html$text('');
				}
			} else {
				return $elm$html$Html$text('');
			}
		}();
		var deleteView = A2(
			$elm$core$Maybe$withDefault,
			$elm$html$Html$text(''),
			A2(
				$elm$core$Maybe$map,
				function (uuid) {
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'cursor', 'pointer'),
								A2($elm$html$Html$Attributes$style, 'align-self', 'center')
							]),
						_List_fromArray(
							[
								$author$project$Page$AutomaticRouteSolution$View$deleteIcon(
								$author$project$Page$AutomaticRouteSolution$Msg$DeleteRoute(uuid))
							]));
				},
				route.hW));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('route-row')
				]),
			_List_fromArray(
				[
					A2($author$project$Page$AutomaticRouteSolution$View$routeDriverBadge, model, route),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('col route-col')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('route-title')
										]),
									_List_fromArray(
										[
											routeTitle,
											A4($elm$html$Html$Lazy$lazy3, $author$project$Page$AutomaticRouteSolution$View$driverAssignmentView, model, index, route)
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('route-subtitle')
								]),
							_List_fromArray(
								[routeKpisView]))
						])),
					deleteView
				]));
	});
var $author$project$Page$AutomaticRouteSolution$View$routesView = F2(
	function (model, routes) {
		var routesListView = A2(
			$elm$core$List$indexedMap,
			$author$project$Page$AutomaticRouteSolution$View$routeView(model),
			routes);
		return A2($elm$html$Html$div, _List_Nil, routesListView);
	});
var $author$project$Page$AutomaticRouteSolution$Msg$GetKpi = function (a) {
	return {$: 15, a: a};
};
var $author$project$Page$AutomaticRouteSolution$Msg$SelectSolution = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$AutomaticRouteSolution$View$areaKpis = function (kpis) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('area-kpis-solution-screen')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('kpis-col')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('font-weight-bold')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Routes: ')
									])),
								$elm$html$Html$text(
								$elm$core$String$fromInt(kpis.hd))
							])),
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('font-weight-bold')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Orders assigned: ')
									])),
								$elm$html$Html$text(
								$elm$core$String$fromInt(kpis.ez) + ('/' + $elm$core$String$fromInt(kpis.c1)))
							])),
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('font-weight-bold')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Stops: ')
									])),
								$elm$html$Html$text(
								A2(
									$elm$core$Maybe$withDefault,
									'Unknown',
									A2($elm$core$Maybe$map, $elm$core$String$fromInt, kpis.dS)))
							])),
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('font-weight-bold')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Route items: ')
									])),
								$elm$html$Html$text(
								$elm$core$String$fromInt(kpis.dv))
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('kpis-col')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('font-weight-bold')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Productivity: ')
									])),
								$elm$html$Html$text(
								A2($myrho$elm_round$Round$round, 1, kpis.dd))
							])),
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('font-weight-bold')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Distance: ')
									])),
								$elm$html$Html$text(
								A2($myrho$elm_round$Round$round, 1, kpis.ab) + ' km')
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('kpis-col')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('font-weight-bold')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Working time: ')
									])),
								$elm$html$Html$text(
								A2($myrho$elm_round$Round$round, 1, kpis.eg) + ' h')
							])),
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('font-weight-bold')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Waiting time: ')
									])),
								$elm$html$Html$text(
								A2($myrho$elm_round$Round$round, 1, kpis.eb) + ' h')
							]))
					]))
			]));
};
var $author$project$Page$AutomaticRouteSolution$View$areaKpisView = function (response) {
	switch (response.$) {
		case 3:
			var kpis = response.a;
			var _v1 = kpis.ew;
			if (!_v1.$) {
				var kpi = _v1.a;
				return $author$project$Page$AutomaticRouteSolution$View$areaKpis(kpi);
			} else {
				return $elm$html$Html$text('');
			}
		case 2:
			return $elm$html$Html$text('There was an error fetching KPI\'s');
		default:
			return $elm$html$Html$text('');
	}
};
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $author$project$Page$AutomaticRouteSolution$View$onClickStopPropagation = function (msg) {
	return A2(
		$elm$html$Html$Events$custom,
		'click',
		$elm$json$Json$Decode$succeed(
			{gm: msg, g$: false, hv: true}));
};
var $author$project$Page$AutomaticRouteSolution$View$solutionListItemView = F4(
	function (_v0, deletedRoutes, index, solution) {
		var executionId = _v0.fv;
		var selectedSolution = _v0.hh;
		var isSavingSolution = _v0.cz;
		var isFetchingKpiToDownload = _v0.cu;
		var kpis = _v0.f6;
		var isSelected = A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				function (_v1) {
					var i = _v1.a;
					return _Utils_eq(i, index);
				},
				selectedSolution));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('list-group-item list-group-item-action', true),
							_Utils_Tuple2('active', isSelected)
						])),
					$elm$html$Html$Events$onClick(
					$author$project$Page$AutomaticRouteSolution$Msg$SelectSolution(
						_Utils_Tuple3(
							index,
							solution,
							{fi: $elm$core$Dict$empty, g3: deletedRoutes})))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h5,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('d-inline-block')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Solution')
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('float-right')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('btn btn-success mr-2', true),
											_Utils_Tuple2('disabled', isFetchingKpiToDownload)
										])),
									$elm$html$Html$Attributes$disabled(isFetchingKpiToDownload),
									$author$project$Page$AutomaticRouteSolution$View$onClickStopPropagation(
									$author$project$Page$AutomaticRouteSolution$Msg$GetKpi(executionId))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('KPI')
								])),
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('btn btn-success', true),
											_Utils_Tuple2('disabled', isSavingSolution)
										])),
									$elm$html$Html$Attributes$disabled(isSavingSolution),
									$author$project$Page$AutomaticRouteSolution$View$onClickStopPropagation(
									$author$project$Page$AutomaticRouteSolution$Msg$SaveSolution(solution))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Save')
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Page$AutomaticRouteSolution$View$areaKpisView(kpis)
						]))
				]));
	});
var $author$project$Page$AutomaticRouteSolution$View$solutionsListView = function (model) {
	var solutions = function () {
		var _v0 = model.R;
		var activeSol = _v0.a;
		var deletedRoutes = _v0.b;
		var sol = function () {
			var _v1 = $elm$core$List$head(activeSol.hp);
			if (!_v1.$) {
				var x = _v1.a;
				return _List_fromArray(
					[x]);
			} else {
				return _List_Nil;
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('list-group')
				]),
			A2(
				$elm$core$List$indexedMap,
				A2($author$project$Page$AutomaticRouteSolution$View$solutionListItemView, model, deletedRoutes),
				sol));
	}();
	return solutions;
};
var $author$project$Views$Common$BootstrapButtons$success = F2(
	function (event, textLabel) {
		return A3($author$project$Views$Common$BootstrapButtons$genericButton, event, textLabel, 'success');
	});
var $author$project$Page$AutomaticRouteSolution$View$solutionsView = function (model) {
	var deletedOrders = function () {
		var _v5 = _Utils_Tuple2(model.cz, model.hh);
		if (_v5.a) {
			return $elm$html$Html$text('');
		} else {
			if (!_v5.b.$) {
				var _v6 = _v5.b.a;
				var sol = _v6.b;
				var routeUpdates = _v6.c;
				return A3($author$project$Page$AutomaticRouteSolution$View$deletedOrdersView, sol.hc, routeUpdates.g3, model.d0);
			} else {
				return $elm$html$Html$text('');
			}
		}
	}();
	var content = function () {
		if (model.f0 || (_Utils_eq(model.f6, $krisajenkins$remotedata$RemoteData$Loading) || _Utils_eq(model.f6, $krisajenkins$remotedata$RemoteData$NotAsked))) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('d-flex flex-column solutions text-center')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h5,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Loading...')
							]))
					]));
		} else {
			if ((!(!model.fs)) || $krisajenkins$remotedata$RemoteData$isFailure(model.f6)) {
				var errorView = function () {
					var _v3 = model.fs;
					switch (_v3) {
						case 1:
							return A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('alert alert-danger')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Solution was not saved.')
									]));
						case 2:
							return A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('alert alert-danger')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Error while fetching KPI.')
									]));
						default:
							return $elm$html$Html$text('Error loading execution data');
					}
				}();
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick($author$project$Page$AutomaticRouteSolution$Msg$ClearError)
						]),
					_List_fromArray(
						[errorView]));
			} else {
				var _v4 = model.R;
				var activeSol = _v4.a;
				return (!$elm$core$List$length(activeSol.hp)) ? A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('d-flex flex-column solutions text-center')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h5,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('No solutions yet.')
								]))
						])) : $author$project$Page$AutomaticRouteSolution$View$solutionsListView(model);
			}
		}
	}();
	var actionView = function () {
		if (model.cz) {
			return _List_fromArray(
				[
					$elm$html$Html$text('')
				]);
		} else {
			var actions = _List_fromArray(
				[
					A2(
					$author$project$Views$Common$BootstrapButtons$success,
					$elm$core$Maybe$Just(
						$author$project$Page$AutomaticRouteSolution$Msg$ReloadSolutions(
							$elm$core$Maybe$Just(model.fv))),
					'Reload Solutions'),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('ml-2')
						]),
					_List_fromArray(
						[
							A2(
							$author$project$Views$Common$BootstrapButtons$danger,
							$elm$core$Maybe$Just($author$project$Page$AutomaticRouteSolution$Msg$CancelProcess),
							'Cancel Calculation')
						]))
				]);
			return actions;
		}
	}();
	var _v0 = function () {
		var _v1 = _Utils_Tuple2(model.cz, model.hh);
		if (_v1.a) {
			return _Utils_Tuple2(
				$elm$html$Html$text(''),
				0);
		} else {
			if (!_v1.b.$) {
				var _v2 = _v1.b.a;
				var sol = _v2.b;
				var routeUpdates = _v2.c;
				var nonRejectedRoutes = A2(
					$elm$core$List$filter,
					$author$project$Page$AutomaticRouteSolution$View$filterNonRejected(routeUpdates),
					sol.hc);
				return _Utils_Tuple2(
					A2($author$project$Page$AutomaticRouteSolution$View$routesView, model, nonRejectedRoutes),
					$elm$core$List$length(nonRejectedRoutes));
			} else {
				return _Utils_Tuple2(
					$elm$html$Html$text(''),
					0);
			}
		}
	}();
	var solutionRoutesView = _v0.a;
	var solutionsCount = _v0.b;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('d-flex flex-column solutions')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('solutions-list')
					]),
				_List_fromArray(
					[content])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('')
					]),
				_List_fromArray(
					[deletedOrders])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('solution-routes', solutionsCount > 0)
							]))
					]),
				_List_fromArray(
					[solutionRoutesView])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('d-flex justify-content-center'),
						A2($elm$html$Html$Attributes$style, 'margin-top', '12px')
					]),
				actionView)
			]));
};
var $author$project$Page$AutomaticRouteSolution$View$body = F2(
	function (context, model) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('row solution-modal-body')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('col-lg-5')
						]),
					_List_fromArray(
						[
							$author$project$Page$AutomaticRouteSolution$View$solutionsView(model)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('col-lg-7')
						]),
					_List_fromArray(
						[
							A3($author$project$Page$AutomaticRouteSolution$View$mapView, context, model.cG, model.hh)
						]))
				]));
	});
var $author$project$Page$AutomaticRouteSolution$View$view = F2(
	function (context, model) {
		return A2($author$project$Page$AutomaticRouteSolution$View$body, context, model);
	});
var $author$project$Page$AutomaticRouteStep$Msg$DismissErrorAlert = {$: 13};
var $author$project$Page$AutomaticRouteStep$View$executionDetails = A2($elm$html$Html$div, _List_Nil, _List_Nil);
var $author$project$Page$AutomaticRouteStep$Msg$ChangeStep = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$AutomaticRouteStep$Model$GenerateStep = 2;
var $author$project$Page$AutomaticRouteStep$Model$WarehouseStep = 1;
var $elm$html$Html$Attributes$autocomplete = function (bool) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'autocomplete',
		bool ? 'on' : 'off');
};
var $author$project$Page$AutomaticRouteStep$Msg$GenerateRoutesClicked = {$: 4};
var $author$project$Page$AutomaticRouteStep$View$errorMessages = function (model) {
	var checkStore = function (errors) {
		return _Utils_eq(model.dG, $elm$core$Maybe$Nothing) ? A2($elm$core$List$cons, 'Select a warehouse', errors) : errors;
	};
	var checkServiceTime = function (errors) {
		var _v1 = model.bX;
		if (!_v1.$) {
			return A2($elm$core$List$cons, 'Fix service time errors', errors);
		} else {
			return errors;
		}
	};
	var checkOrders = function (errors) {
		return ($elm$core$List$length(model.gL) < 2) ? A2($elm$core$List$cons, 'Select at least two orders', errors) : errors;
	};
	var checkDuration = function (errors) {
		var _v0 = model.cJ;
		if (_v0.$ === 1) {
			return A2($elm$core$List$cons, 'Routing generation duration is required', errors);
		} else {
			var duration = _v0.a;
			return ((duration > 1000) || (duration < 1)) ? A2($elm$core$List$cons, 'Routing generation duration needs to be between 1 and 1000 minutes', errors) : errors;
		}
	};
	var checkDrivers = function (errors) {
		return $elm$core$List$isEmpty(model.dH) ? A2($elm$core$List$cons, 'Select at least one vehicle type', errors) : errors;
	};
	return checkServiceTime(
		checkDuration(
			checkOrders(
				checkDrivers(
					checkStore(_List_Nil)))));
};
var $author$project$Page$AutomaticRouteStep$View$canGenerateRoutes = function (model) {
	return $elm$core$List$isEmpty(
		$author$project$Page$AutomaticRouteStep$View$errorMessages(model));
};
var $author$project$Page$AutomaticRouteStep$View$footer = function (model) {
	var btn = function () {
		var _v0 = model.dR;
		switch (_v0) {
			case 0:
				return A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('btn btn-success mt-3'),
							$elm$html$Html$Events$onClick(
							$author$project$Page$AutomaticRouteStep$Msg$ChangeStep(1))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Next')
						]));
			case 1:
				return A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('btn btn-success mt-3'),
							$elm$html$Html$Events$onClick(
							$author$project$Page$AutomaticRouteStep$Msg$ChangeStep(2))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Next')
						]));
			case 2:
				var _v1 = _Utils_Tuple2(
					$author$project$Page$AutomaticRouteStep$View$canGenerateRoutes(model),
					model.fK);
				if (_v1.a && (!_v1.b)) {
					return A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('btn btn-success'),
								$elm$html$Html$Events$onClick($author$project$Page$AutomaticRouteStep$Msg$GenerateRoutesClicked)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Generate Routes')
							]));
				} else {
					return A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('btn btn-success disabled')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Generate Routes')
							]));
				}
			default:
				return $elm$html$Html$text('');
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('px-4')
			]),
		_List_fromArray(
			[btn]));
};
var $author$project$Page$AutomaticRouteStep$Msg$SetMaxDuration = function (a) {
	return {$: 8, a: a};
};
var $elm$html$Html$label = _VirtualDom_node('label');
var $elm$html$Html$li = _VirtualDom_node('li');
var $elm$html$Html$Attributes$max = $elm$html$Html$Attributes$stringProperty('max');
var $elm$html$Html$Attributes$maxlength = function (n) {
	return A2(
		_VirtualDom_attribute,
		'maxlength',
		$elm$core$String$fromInt(n));
};
var $elm$html$Html$Attributes$min = $elm$html$Html$Attributes$stringProperty('min');
var $author$project$Page$AutomaticRouteStep$Msg$SetOptimizationType = function (a) {
	return {$: 9, a: a};
};
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $elm$html$Html$Attributes$for = $elm$html$Html$Attributes$stringProperty('htmlFor');
var $elm$html$Html$Attributes$name = $elm$html$Html$Attributes$stringProperty('name');
var $elm$html$Html$sup = _VirtualDom_node('sup');
var $author$project$Page$AutomaticRouteStep$View$newBadge = A2(
	$elm$html$Html$sup,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('badge badge-pill badge-danger ml-2'),
			A2($elm$html$Html$Attributes$style, 'vertical-align', 'sub')
		]),
	_List_fromArray(
		[
			$elm$html$Html$text('NEW')
		]));
var $elm$html$Html$small = _VirtualDom_node('small');
var $author$project$Page$AutomaticRouteStep$View$optimizationTypeOption = F3(
	function (value, index, option) {
		var optionId = 'optimzationType' + $elm$core$String$fromInt(index);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form-check mb-3 optimization_option'),
					$elm$html$Html$Events$onClick(
					$author$project$Page$AutomaticRouteStep$Msg$SetOptimizationType(option))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('form-check-input'),
							$elm$html$Html$Attributes$type_('radio'),
							$elm$html$Html$Attributes$name('optimzation'),
							$elm$html$Html$Attributes$id(optionId),
							$elm$html$Html$Attributes$checked(
							_Utils_eq(option, value))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('form-check-label'),
							$elm$html$Html$Attributes$for(optionId)
						]),
					function () {
						if (!option) {
							return _List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('VeeRoute')
										])),
									A2(
									$elm$html$Html$small,
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$style, 'color', '#FF0000')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Only available until Jan 31')
										]))
								]);
						} else {
							return _List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Paack Routing Engine'),
											$author$project$Page$AutomaticRouteStep$View$newBadge
										]))
								]);
						}
					}())
				]));
	});
var $author$project$Page$AutomaticRouteStep$View$optimizationTypeSelection = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mb-4 d-flex flex-row justify-content-between')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'min-width', '50%')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mb-2')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Optimization Engine')
							])),
						A2(
						$elm$html$Html$div,
						_List_Nil,
						A2(
							$elm$core$List$indexedMap,
							$author$project$Page$AutomaticRouteStep$View$optimizationTypeOption(model.cY),
							_List_fromArray(
								[1])))
					]))
			]));
};
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$Page$AutomaticRouteStep$View$generateStepView = function (model) {
	var errorView = A2(
		$elm$html$Html$ul,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('text-danger')
			]),
		A2(
			$elm$core$List$map,
			function (m) {
				return A2(
					$elm$html$Html$li,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(m)
						]));
			},
			$author$project$Page$AutomaticRouteStep$View$errorMessages(model)));
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('px-4')
			]),
		_List_fromArray(
			[
				$author$project$Page$AutomaticRouteStep$View$optimizationTypeSelection(model),
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mb-4')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('mb-2')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Route generation duration')
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('d-flex align-items-center input-group-sm')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$input,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('form-control w-50 mr-3'),
												$elm$html$Html$Attributes$type_('number'),
												$elm$html$Html$Events$onInput($author$project$Page$AutomaticRouteStep$Msg$SetMaxDuration),
												$elm$html$Html$Attributes$max('1000'),
												$elm$html$Html$Attributes$min('1'),
												$elm$html$Html$Attributes$maxlength(4),
												$elm$html$Html$Attributes$value(
												A2(
													$elm$core$Maybe$withDefault,
													'',
													A2($elm$core$Maybe$map, $elm$core$String$fromInt, model.cJ)))
											]),
										_List_Nil),
										A2(
										$elm$html$Html$span,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('minutes')
											]))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$small,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$span,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Recommendations')
											])),
										A2(
										$elm$html$Html$ul,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('pl-4')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$li,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('20 minutes for a routing with less than 1,000 orders')
													])),
												A2(
												$elm$html$Html$li,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('1 hour for a routing with less than 5,000 orders')
													])),
												A2(
												$elm$html$Html$li,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('2 hour for a routing with less than 10,000 orders')
													]))
											]))
									]))
							]))
					])),
				A2(
				$author$project$HtmlExtra$when,
				!$author$project$Page$AutomaticRouteStep$View$canGenerateRoutes(model),
				errorView)
			]));
};
var $author$project$Page$AutomaticRouteStep$View$solutionStepView = function (_v0) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text('Solution step view')
			]));
};
var $author$project$Page$AutomaticRouteStep$View$stepToText = function (step) {
	switch (step) {
		case 0:
			return 'Vehicles and Service Time';
		case 1:
			return 'Warehouse';
		case 2:
			return 'Generate Routes';
		default:
			return '';
	}
};
var $author$project$Page$AutomaticRouteStep$Msg$UnlimitedDriversChecked = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$Events$targetChecked = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'checked']),
	$elm$json$Json$Decode$bool);
var $elm$html$Html$Events$onCheck = function (tagger) {
	return A2(
		$elm$html$Html$Events$on,
		'change',
		A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetChecked));
};
var $author$project$Page$AutomaticRouteStep$Model$Automatic = {$: 0};
var $author$project$Page$AutomaticRouteStep$Msg$DeliveryServiceTimeChanged = function (a) {
	return {$: 14, a: a};
};
var $author$project$Page$AutomaticRouteStep$Msg$ServiceTimeStrategyChanged = function (a) {
	return {$: 15, a: a};
};
var $elm$html$Html$Attributes$step = function (n) {
	return A2($elm$html$Html$Attributes$stringProperty, 'step', n);
};
var $author$project$Page$AutomaticRouteStep$View$serviceTimeFormView = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('card mt-3')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('card-body')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h5,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Delivery Service Time'),
								function () {
								var _v0 = model.bX;
								if (!_v0.$) {
									var error = _v0.a;
									return A2(
										$elm$html$Html$ul,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('list-group')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$li,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('list-group-item list-group-item-danger')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(error)
													]))
											]));
								} else {
									return $elm$html$Html$text('');
								}
							}()
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-group w-25 m-3')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-check-input'),
										$elm$html$Html$Attributes$type_('radio'),
										$elm$html$Html$Attributes$id('custom-service-time'),
										$elm$html$Html$Attributes$checked(
										!_Utils_eq(model.bW, $author$project$Page$AutomaticRouteStep$Model$Automatic)),
										$elm$html$Html$Events$onClick(
										$author$project$Page$AutomaticRouteStep$Msg$ServiceTimeStrategyChanged(
											$author$project$Page$AutomaticRouteStep$Model$Manual(
												$elm$core$Maybe$Just(5))))
									]),
								_List_Nil),
								A2(
								$elm$html$Html$label,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-check-label d-block mb-2'),
										$elm$html$Html$Attributes$for('custom-service-time')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Use custom value')
									])),
								A2(
								$elm$html$Html$label,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Delivery Service Time (in minutes)')
									])),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-control form-control-sm mb-2'),
										$elm$html$Html$Attributes$type_('number'),
										$elm$html$Html$Attributes$min('2.0'),
										$elm$html$Html$Attributes$max('10.0'),
										$elm$html$Html$Attributes$step('0.1'),
										$elm$html$Html$Events$onInput($author$project$Page$AutomaticRouteStep$Msg$DeliveryServiceTimeChanged),
										$elm$html$Html$Attributes$disabled(
										_Utils_eq(model.bW, $author$project$Page$AutomaticRouteStep$Model$Automatic)),
										$elm$html$Html$Attributes$value(
										function () {
											var _v1 = model.bW;
											if (_v1.$ === 1) {
												if (!_v1.a.$) {
													var serviceTime = _v1.a.a;
													return $elm$core$String$fromFloat(serviceTime);
												} else {
													var _v2 = _v1.a;
													return '';
												}
											} else {
												return '5';
											}
										}())
									]),
								_List_Nil),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-check-input'),
										$elm$html$Html$Attributes$type_('radio'),
										$elm$html$Html$Attributes$id('automatic-service-time'),
										$elm$html$Html$Attributes$checked(
										_Utils_eq(model.bW, $author$project$Page$AutomaticRouteStep$Model$Automatic)),
										$elm$html$Html$Events$onClick(
										$author$project$Page$AutomaticRouteStep$Msg$ServiceTimeStrategyChanged($author$project$Page$AutomaticRouteStep$Model$Automatic))
									]),
								_List_Nil),
								A2(
								$elm$html$Html$label,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-check-label d-block mb-2'),
										$elm$html$Html$Attributes$for('automatic-service-time')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Obtain automatically')
									])),
								A2(
								$elm$html$Html$p,
								_List_fromArray(
									[
										A2($elm$html$Html$Attributes$style, 'font-size', '13px')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Using past route data for more accurate Delivery Service Time predictions.')
									]))
							]))
					]))
			]));
};
var $author$project$Page$AutomaticRouteStep$Msg$AddVehicleData = {$: 5};
var $author$project$Page$AutomaticRouteStep$VehicleDataForm$MaxDeliveries = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$AutomaticRouteStep$VehicleDataForm$MaxDistance = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$AutomaticRouteStep$VehicleDataForm$MaxLocations = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$AutomaticRouteStep$VehicleDataForm$MaxRouteItems = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$AutomaticRouteStep$VehicleDataForm$MinDeliveries = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$AutomaticRouteStep$VehicleDataForm$MinRouteItems = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$AutomaticRouteStep$VehicleDataForm$Quantity = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$AutomaticRouteStep$Msg$SetVehicleFormField = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$AutomaticRouteStep$VehicleDataForm$StartTimeHour = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$AutomaticRouteStep$VehicleDataForm$StartTimeMinute = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$AutomaticRouteStep$VehicleDataForm$VehicleType = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$AutomaticRouteStep$VehicleDataForm$WorkingHours = function (a) {
	return {$: 4, a: a};
};
var $author$project$Data$Driver$allVehicles = _List_fromArray(
	[0, 1, 2, 3]);
var $elm$html$Html$option = _VirtualDom_node('option');
var $elm$html$Html$select = _VirtualDom_node('select');
var $elm$html$Html$Attributes$selected = $elm$html$Html$Attributes$boolProperty('selected');
var $elm$html$Html$form = _VirtualDom_node('form');
var $elm$html$Html$table = _VirtualDom_node('table');
var $elm$html$Html$tbody = _VirtualDom_node('tbody');
var $elm$html$Html$th = _VirtualDom_node('th');
var $elm$html$Html$thead = _VirtualDom_node('thead');
var $elm$html$Html$tr = _VirtualDom_node('tr');
var $author$project$Page$AutomaticRouteStep$Msg$RemoveVehicleData = function (a) {
	return {$: 6, a: a};
};
var $elm$html$Html$td = _VirtualDom_node('td');
var $author$project$Common$Vehicle$vehicleClass = function (vehicle) {
	switch (vehicle) {
		case 0:
			return 'fas fa-motorcycle';
		case 1:
			return 'fas fa-car';
		case 2:
			return 'fas fa-bicycle';
		default:
			return 'fas fa-truck';
	}
};
var $author$project$Page$AutomaticRouteStep$View$vehicleItemView = F2(
	function (index, _v0) {
		var vehicle = _v0.hX;
		var quantity = _v0.df;
		var capacity = _v0.bE;
		var startTimeHour = _v0.dN;
		var startTimeMinute = _v0.dO;
		var workingHours = _v0.eg;
		var startTime = $elm$core$String$fromInt(startTimeHour) + (':' + $elm$core$String$fromInt(startTimeMinute));
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text-center')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('badge badge-primary badge-pill mr-2 ml-2')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class(
											$author$project$Common$Vehicle$vehicleClass(vehicle))
										]),
									_List_Nil)
								]))
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text-center')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(quantity))
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text-center')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromFloat(capacity))
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text-center')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(workingHours))
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text-center')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(startTime)
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text-center')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('button'),
									$elm$html$Html$Attributes$class('btn btn-danger btn-sm'),
									$elm$html$Html$Events$onClick(
									$author$project$Page$AutomaticRouteStep$Msg$RemoveVehicleData(index))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('fa fa-times')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$AutomaticRouteStep$View$vehicleListView = function (model) {
	return A2(
		$author$project$HtmlExtra$when,
		$elm$core$List$length(model.dH) > 0,
		A2(
			$elm$html$Html$form,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mt-2')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$table,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('table table-striped')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$thead,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$tr,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$th,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('text-center')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Vehicle')
												])),
											A2(
											$elm$html$Html$th,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('text-center')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Quantity')
												])),
											A2(
											$elm$html$Html$th,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('text-center')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Capacity')
												])),
											A2(
											$elm$html$Html$th,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('text-center')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Working Hours')
												])),
											A2(
											$elm$html$Html$th,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('text-center')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Start Time')
												])),
											A2(
											$elm$html$Html$th,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('text-center')
												]),
											_List_Nil)
										]))
								])),
							A2(
							$elm$html$Html$tbody,
							_List_Nil,
							A2($elm$core$List$indexedMap, $author$project$Page$AutomaticRouteStep$View$vehicleItemView, model.dH))
						]))
				])));
};
var $author$project$Page$AutomaticRouteStep$View$vehicleFormView = function (model) {
	var vehicleDataForm = model.d8;
	var vehicle = A2(
		$elm$core$Maybe$withDefault,
		0,
		$author$project$Common$Vehicle$stringToVehicle(vehicleDataForm.hX));
	var vehicleItem = function (item) {
		return A2(
			$elm$html$Html$option,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$selected(
					_Utils_eq(vehicle, item))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					$author$project$Common$Vehicle$toString(item))
				]));
	};
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('card')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('card-body')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h5,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Vehicles')
							])),
						A2(
						$elm$html$Html$ul,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('list-group')
							]),
						A2(
							$elm$core$List$map,
							function (error) {
								return A2(
									$elm$html$Html$li,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('list-group-item list-group-item-danger')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(error)
										]));
							},
							vehicleDataForm.ft)),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('d-flex')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-group w-50 m-2')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$label,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('mandatory-field')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Vehicle')
											])),
										A2(
										$elm$html$Html$select,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('custom-select custom-select-sm'),
												$elm$html$Html$Events$onInput(
												A2($elm$core$Basics$composeL, $author$project$Page$AutomaticRouteStep$Msg$SetVehicleFormField, $author$project$Page$AutomaticRouteStep$VehicleDataForm$VehicleType)),
												$elm$html$Html$Attributes$value(vehicleDataForm.hX)
											]),
										A2($elm$core$List$map, vehicleItem, $author$project$Data$Driver$allVehicles))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-group w-50 m-2')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$label,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('mandatory-field')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Quantity')
											])),
										A2(
										$elm$html$Html$input,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('form-control form-control-sm'),
												$elm$html$Html$Attributes$type_('number'),
												$elm$html$Html$Attributes$value(vehicleDataForm.df),
												$elm$html$Html$Events$onInput(
												A2($elm$core$Basics$composeL, $author$project$Page$AutomaticRouteStep$Msg$SetVehicleFormField, $author$project$Page$AutomaticRouteStep$VehicleDataForm$Quantity))
											]),
										_List_Nil)
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-group w-50 m-2')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$label,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('mandatory-field')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Working Hours')
											])),
										A2(
										$elm$html$Html$input,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('form-control form-control-sm'),
												$elm$html$Html$Attributes$type_('number'),
												$elm$html$Html$Attributes$value(vehicleDataForm.eg),
												$elm$html$Html$Events$onInput(
												A2($elm$core$Basics$composeL, $author$project$Page$AutomaticRouteStep$Msg$SetVehicleFormField, $author$project$Page$AutomaticRouteStep$VehicleDataForm$WorkingHours))
											]),
										_List_Nil)
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('d-flex')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-group w-100 m-2')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$label,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('mandatory-field')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Start Working Time')
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('d-flex')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('form-control form-control-sm'),
														$elm$html$Html$Attributes$type_('number'),
														$elm$html$Html$Attributes$value(vehicleDataForm.dN),
														$elm$html$Html$Events$onInput(
														A2($elm$core$Basics$composeL, $author$project$Page$AutomaticRouteStep$Msg$SetVehicleFormField, $author$project$Page$AutomaticRouteStep$VehicleDataForm$StartTimeHour))
													]),
												_List_Nil),
												A2(
												$elm$html$Html$span,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('mx-3')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(':')
													])),
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('form-control form-control-sm'),
														$elm$html$Html$Attributes$type_('number'),
														$elm$html$Html$Attributes$value(vehicleDataForm.dO),
														$elm$html$Html$Events$onInput(
														A2($elm$core$Basics$composeL, $author$project$Page$AutomaticRouteStep$Msg$SetVehicleFormField, $author$project$Page$AutomaticRouteStep$VehicleDataForm$StartTimeMinute))
													]),
												_List_Nil)
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-group w-100 m-2')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$label,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('mandatory-field')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Max. Orders')
											])),
										A2(
										$elm$html$Html$input,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('form-control form-control-sm'),
												$elm$html$Html$Attributes$type_('number'),
												$elm$html$Html$Attributes$placeholder('Max Orders'),
												$elm$html$Html$Attributes$value(vehicleDataForm.cH),
												$elm$html$Html$Attributes$max('200'),
												$elm$html$Html$Attributes$min('30'),
												$elm$html$Html$Events$onInput(
												A2($elm$core$Basics$composeL, $author$project$Page$AutomaticRouteStep$Msg$SetVehicleFormField, $author$project$Page$AutomaticRouteStep$VehicleDataForm$MaxDeliveries))
											]),
										_List_Nil)
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-group w-100 m-2')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$label,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Min. Orders')
											])),
										A2(
										$elm$html$Html$input,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('form-control form-control-sm'),
												$elm$html$Html$Attributes$type_('number'),
												$elm$html$Html$Attributes$value(vehicleDataForm.cN),
												$elm$html$Html$Attributes$min('1'),
												$elm$html$Html$Events$onInput(
												A2($elm$core$Basics$composeL, $author$project$Page$AutomaticRouteStep$Msg$SetVehicleFormField, $author$project$Page$AutomaticRouteStep$VehicleDataForm$MinDeliveries))
											]),
										_List_Nil),
										A2(
										$elm$html$Html$small,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('form-text text-muted'),
												A2($elm$html$Html$Attributes$style, 'cursor', 'pointer'),
												$elm$html$Html$Attributes$title('Minimum load entered will be taken as an input for the algorithms, however it does not represent a hard limit. The closer the minimums are to the average load (total volume/ number of vehicles) the more likely some routes could fall under it')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Minimums might not be respected under some conditions')
											]))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('d-flex')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-group w-100 m-2')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$label,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('mandatory-field')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Max. Distance (in Kms)')
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('d-flex')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('form-control form-control-sm'),
														$elm$html$Html$Attributes$type_('number'),
														$elm$html$Html$Attributes$value(vehicleDataForm.cI),
														$elm$html$Html$Attributes$min('1'),
														$elm$html$Html$Attributes$title('Max distance kilometers'),
														$elm$html$Html$Events$onInput(
														A2($elm$core$Basics$composeL, $author$project$Page$AutomaticRouteStep$Msg$SetVehicleFormField, $author$project$Page$AutomaticRouteStep$VehicleDataForm$MaxDistance))
													]),
												_List_Nil)
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-group w-100 m-2')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$label,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('mandatory-field')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Max. Locations')
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('d-flex')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('form-control form-control-sm'),
														$elm$html$Html$Attributes$type_('number'),
														$elm$html$Html$Attributes$value(vehicleDataForm.cK),
														$elm$html$Html$Attributes$min('1'),
														$elm$html$Html$Attributes$title('Max Locations'),
														$elm$html$Html$Events$onInput(
														A2($elm$core$Basics$composeL, $author$project$Page$AutomaticRouteStep$Msg$SetVehicleFormField, $author$project$Page$AutomaticRouteStep$VehicleDataForm$MaxLocations))
													]),
												_List_Nil)
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-group w-100 m-2')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$label,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Max. Route Items')
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('d-flex')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('form-control form-control-sm'),
														$elm$html$Html$Attributes$type_('number'),
														$elm$html$Html$Attributes$value(vehicleDataForm.cL),
														$elm$html$Html$Attributes$min('1'),
														$elm$html$Html$Attributes$title('Max. Route Items'),
														$elm$html$Html$Events$onInput(
														A2($elm$core$Basics$composeL, $author$project$Page$AutomaticRouteStep$Msg$SetVehicleFormField, $author$project$Page$AutomaticRouteStep$VehicleDataForm$MaxRouteItems))
													]),
												_List_Nil)
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-group w-100 m-2')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$label,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Min. Route Items')
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('d-flex')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('form-control form-control-sm'),
														$elm$html$Html$Attributes$type_('number'),
														$elm$html$Html$Attributes$value(vehicleDataForm.cO),
														$elm$html$Html$Attributes$min('1'),
														$elm$html$Html$Attributes$title('Min. Route Items'),
														$elm$html$Html$Events$onInput(
														A2($elm$core$Basics$composeL, $author$project$Page$AutomaticRouteStep$Msg$SetVehicleFormField, $author$project$Page$AutomaticRouteStep$VehicleDataForm$MinRouteItems))
													]),
												_List_Nil)
											]))
									]))
							])),
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('button'),
								$elm$html$Html$Events$onClick($author$project$Page$AutomaticRouteStep$Msg$AddVehicleData),
								$elm$html$Html$Attributes$class('d-block mx-auto btn btn-primary btn-sm mt-3 mb-3')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Add Vehicle')
							])),
						$author$project$Page$AutomaticRouteStep$View$vehicleListView(model)
					]))
			]));
};
var $author$project$Page$AutomaticRouteStep$View$vehiclesStepView = function (model) {
	var unlimitedDriversCheckbox = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('hide mr-1 mb-3 text-right')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$input,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$type_('checkbox'),
						$elm$html$Html$Events$onCheck($author$project$Page$AutomaticRouteStep$Msg$UnlimitedDriversChecked)
					]),
				_List_Nil),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('ml-1')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Allow additional drivers')
					]))
			]));
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				unlimitedDriversCheckbox,
				$author$project$Page$AutomaticRouteStep$View$vehicleFormView(model),
				$author$project$Page$AutomaticRouteStep$View$serviceTimeFormView(model)
			]));
};
var $author$project$Page$AutomaticRouteStep$Msg$StoreInput = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$AutomaticRouteStep$View$inputValueFilter = F2(
	function (inputValue, value) {
		return (inputValue === '') ? true : A2(
			$elm$core$String$contains,
			$elm$core$String$toLower(inputValue),
			$elm$core$String$toLower(value));
	});
var $author$project$Page$AutomaticRouteStep$Msg$StoreSelected = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$AutomaticRouteStep$View$storeAddress = function (store) {
	return A2(
		$elm$core$Maybe$withDefault,
		'',
		A2($elm$core$Maybe$map, $author$project$Common$Address$fullAddress, store.er));
};
var $author$project$Page$AutomaticRouteStep$View$storeItemView = F2(
	function (selectedStore, store) {
		var isSelected = function () {
			if (!selectedStore.$) {
				var s = selectedStore.a;
				return _Utils_eq(store.fS, s.fS);
			} else {
				return false;
			}
		}();
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('list-group-item list-group-item-action', true),
							_Utils_Tuple2('active', isSelected)
						])),
					$elm$html$Html$Events$onClick(
					$author$project$Page$AutomaticRouteStep$Msg$StoreSelected(store))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('float-left')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(store.gs)
						])),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('float-right')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$small,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Page$AutomaticRouteStep$View$storeAddress(store))
								]))
						]))
				]));
	});
var $author$project$Page$AutomaticRouteStep$View$warehouseListView = function (model) {
	return A2(
		$elm$html$Html$ul,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('list-group')
			]),
		A2(
			$elm$core$List$map,
			$author$project$Page$AutomaticRouteStep$View$storeItemView(model.dG),
			A2(
				$elm$core$List$sortBy,
				function ($) {
					return $.gs;
				},
				A2(
					$elm$core$List$filter,
					function (s) {
						return A2($author$project$Page$AutomaticRouteStep$View$inputValueFilter, model.dU, s.gs);
					},
					model.dV))));
};
var $author$project$Page$AutomaticRouteStep$View$warehouseStepView = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$input,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-control mb-3'),
						$elm$html$Html$Attributes$type_('text'),
						$elm$html$Html$Events$onInput($author$project$Page$AutomaticRouteStep$Msg$StoreInput),
						$elm$html$Html$Attributes$placeholder('Filter warehouses')
					]),
				_List_Nil),
				$author$project$Page$AutomaticRouteStep$View$warehouseListView(model)
			]));
};
var $author$project$Page$AutomaticRouteStep$View$routeForm = function (model) {
	var stepButton = function (step) {
		return A2(
			$elm$html$Html$label,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('btn btn-sm btn-outline-info'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'active',
							_Utils_eq(step, model.dR))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$type_('radio'),
							$elm$html$Html$Attributes$name('options'),
							$elm$html$Html$Attributes$autocomplete(false),
							$elm$html$Html$Events$onClick(
							$author$project$Page$AutomaticRouteStep$Msg$ChangeStep(step))
						]),
					_List_Nil),
					$elm$html$Html$text(
					$author$project$Page$AutomaticRouteStep$View$stepToText(step))
				]));
	};
	var stepButtons = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('btn-group btn-group-toggle')
			]),
		A2(
			$elm$core$List$map,
			stepButton,
			_List_fromArray(
				[0, 1, 2])));
	var currentView = function () {
		var _v0 = model.dR;
		switch (_v0) {
			case 0:
				return $author$project$Page$AutomaticRouteStep$View$vehiclesStepView(model);
			case 1:
				return $author$project$Page$AutomaticRouteStep$View$warehouseStepView(model);
			case 2:
				return $author$project$Page$AutomaticRouteStep$View$generateStepView(model);
			default:
				return $author$project$Page$AutomaticRouteStep$View$solutionStepView(model);
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('clearfix mb-3 d-flex justify-content-center')
					]),
				_List_fromArray(
					[stepButtons])),
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[currentView])),
				$author$project$Page$AutomaticRouteStep$View$footer(model)
			]));
};
var $author$project$Page$AutomaticRouteStep$View$body = function (model) {
	var _v0 = model.f3;
	if (!_v0.$) {
		return $author$project$Page$AutomaticRouteStep$View$executionDetails;
	} else {
		return $author$project$Page$AutomaticRouteStep$View$routeForm(model);
	}
};
var $author$project$Page$AutomaticRouteStep$View$header = function (model) {
	var title = function () {
		var _v0 = model.dR;
		switch (_v0) {
			case 0:
				return 'Vehicles and Estimated Delivery Service Time';
			case 1:
				return 'Select Pickup Warehouse';
			case 2:
				return 'Generate Routes';
			default:
				return 'Solutions';
		}
	}();
	return A2(
		$elm$html$Html$h5,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('modal-title')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(title)
			]));
};
var $author$project$Page$AutomaticRouteStep$View$view = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$author$project$Views$Common$Alert$errorAlertView,
				model.dt,
				$elm$core$Maybe$Just($author$project$Page$AutomaticRouteStep$Msg$DismissErrorAlert)),
				$author$project$Page$AutomaticRouteStep$View$header(model),
				$author$project$Page$AutomaticRouteStep$View$body(model)
			]));
};
var $author$project$Page$AutomaticRoute$View$body = F3(
	function (context, model, drivers) {
		var _v0 = model.dF;
		if (!_v0.$) {
			var _v1 = _v0.a;
			var execution = _v1.b;
			var sol = _v1.c;
			var _v2 = execution.f3;
			if (!_v2.$) {
				var jobId = _v2.a;
				return A2(
					$elm$html$Html$map,
					$author$project$Page$AutomaticRoute$Msg$AutomaticRouteSolutionMsg,
					A2(
						$author$project$Page$AutomaticRouteSolution$View$view,
						context,
						_Utils_update(
							sol,
							{b_: drivers, fv: jobId})));
			} else {
				return A2(
					$elm$html$Html$map,
					$author$project$Page$AutomaticRoute$Msg$AutomaticRouteStepMsg,
					$author$project$Page$AutomaticRouteStep$View$view(execution));
			}
		} else {
			var splitAreasBtn = function () {
				if (!$elm$core$List$length(model.gL)) {
					return $elm$html$Html$text('');
				} else {
					var _v5 = _Utils_Tuple2(
						model.dM,
						$elm$core$List$length(model.b5) > 0);
					if (_v5.a) {
						return $elm$html$Html$text('');
					} else {
						if (_v5.b) {
							return $elm$html$Html$text('');
						} else {
							return A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick($author$project$Page$AutomaticRoute$Msg$SplitOrders),
										$elm$html$Html$Attributes$class('btn btn-primary')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Split areas')
									]));
						}
					}
				}
			}();
			var orderText = 'There are ' + ($elm$core$String$fromInt(
				$elm$core$List$length(model.gL)) + ' orders not in any areas');
			var executionsWithDrivers = A2(
				$elm$core$List$map,
				function (_v4) {
					var it = _v4.a;
					var ex = _v4.b;
					var so = _v4.c;
					var solutionWithDrivers = _Utils_update(
						so,
						{b_: drivers});
					return _Utils_Tuple3(it, ex, solutionWithDrivers);
				},
				model.b5);
			var executionView = A2($elm$core$List$map, $author$project$Page$AutomaticRoute$View$renderExecution, executionsWithDrivers);
			var createExecutionBtn = function () {
				if (!$elm$core$List$length(model.gL)) {
					return $elm$html$Html$text('');
				} else {
					var _v3 = _Utils_Tuple2(
						model.dM,
						$elm$core$List$length(model.b5) > 0);
					if (_v3.a && _v3.b) {
						return $elm$html$Html$text('');
					} else {
						return A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn btn-primary'),
									$elm$html$Html$Events$onClick($author$project$Page$AutomaticRoute$Msg$CreateExecution)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Create execution')
								]));
					}
				}
			}();
			return A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'margin-bottom', '8px')
							]),
						_List_fromArray(
							[
								A2(
								$author$project$Views$Common$Alert$errorAlertView,
								model.b3,
								$elm$core$Maybe$Just($author$project$Page$AutomaticRoute$Msg$DismissErrorAlert))
							])),
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(orderText),
								splitAreasBtn
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('container-fluid')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('row')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('col-8')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('executions-view')
													]),
												executionView)
											]))
									]))
							])),
						createExecutionBtn
					]));
		}
	});
var $author$project$Page$AutomaticRoute$View$executionPresent = function (model) {
	return $elm$core$List$length(model.b5) > 0;
};
var $author$project$Page$AutomaticRoute$View$footer = function (_v0) {
	return _List_Nil;
};
var $author$project$Page$AutomaticRoute$Msg$ResetAutoRoutingModal = {$: 4};
var $author$project$Page$AutomaticRoute$View$header = function (model) {
	var _v0 = model.dF;
	if (!_v0.$) {
		var _v1 = _v0.a;
		var execution = _v1.b;
		var sol = _v1.c;
		var executionTitle = function () {
			var _v2 = _Utils_Tuple2(execution.gs, execution.f3);
			if (!_v2.a.$) {
				if (!_v2.b.$) {
					var name = _v2.a.a;
					var id = _v2.b.a;
					return 'Execution ' + (name + (' - ' + id));
				} else {
					var name = _v2.a.a;
					return 'Execution ' + name;
				}
			} else {
				if (!_v2.b.$) {
					var id = _v2.b.a;
					return 'Execution ' + id;
				} else {
					return 'New Execution';
				}
			}
		}();
		var backBtn = (sol.f_ || execution.fK) ? $elm$html$Html$text('') : A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('btn btn-light'),
					$elm$html$Html$Events$onClick($author$project$Page$AutomaticRoute$Msg$CloseSelectedExecution)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Back')
				]));
		var title = A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					backBtn,
					$elm$html$Html$text(executionTitle)
				]));
		return title;
	} else {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					(model.dM && (!$author$project$Page$AutomaticRoute$Model$isThereAnOngoingExecution(model.b5))) ? A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('btn btn-light'),
							$elm$html$Html$Events$onClick($author$project$Page$AutomaticRoute$Msg$ResetAutoRoutingModal)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Back')
						])) : $elm$html$Html$text(''),
					$elm$html$Html$text(' Automatic Route generation')
				]));
	}
};
var $author$project$Page$AutomaticRoute$View$dialogConfig = F3(
	function (context, model, drivers) {
		var modalSize = $author$project$Page$AutomaticRoute$View$executionPresent(model) ? 'modal-big' : 'modal-medium';
		return {
			eC: $elm$core$Maybe$Just(
				A3($author$project$Page$AutomaticRoute$View$body, context, model, drivers)),
			eN: $elm$core$Maybe$Just($author$project$Page$AutomaticRoute$Msg$CloseClicked),
			eV: $elm$core$Maybe$Just(modalSize),
			fI: $author$project$Page$AutomaticRoute$View$footer(model),
			fO: $elm$core$Maybe$Just(
				$author$project$Page$AutomaticRoute$View$header(model))
		};
	});
var $author$project$Page$AutomaticRoute$View$view = F3(
	function (context, model, drivers) {
		return $jouderianjr$elm_dialog$Dialog$view(
			$elm$core$Maybe$Just(
				A3($author$project$Page$AutomaticRoute$View$dialogConfig, context, model, drivers)));
	});
var $author$project$Views$MapAssignment$View$automaticRouteView = F3(
	function (context, dialogState, drivers) {
		if (dialogState.$ === 1) {
			var subModel = dialogState.a;
			return A2(
				$elm$html$Html$map,
				$author$project$Views$MapAssignment$Msg$AutomaticRouteMsg,
				A3($author$project$Page$AutomaticRoute$View$view, context, subModel, drivers));
		} else {
			return $author$project$HtmlExtra$empty;
		}
	});
var $author$project$Views$MapAssignment$Msg$CloseConfirmationModal = {$: 71};
var $author$project$Views$MapAssignment$Msg$HideAllRoutes = {$: 39};
var $author$project$Views$MapAssignment$Msg$HideSelectedRoutes = {$: 78};
var $author$project$Views$Common$BootstrapButtons$primary = F2(
	function (event, textLabel) {
		return A3($author$project$Views$Common$BootstrapButtons$genericButton, event, textLabel, 'primary');
	});
var $author$project$Views$MapAssignment$View$confirmationModalView = function (hideOrDeleteModalStatus) {
	var deleteMsg = function () {
		switch (hideOrDeleteModalStatus.$) {
			case 1:
				return $elm$core$Maybe$Just($author$project$Views$MapAssignment$Msg$HideAllRoutes);
			case 2:
				return $elm$core$Maybe$Just($author$project$Views$MapAssignment$Msg$HideSelectedRoutes);
			default:
				return $elm$core$Maybe$Nothing;
		}
	}();
	return A2(
		$author$project$Views$Common$ConfirmationModal$view,
		!_Utils_eq(hideOrDeleteModalStatus, $author$project$Views$MapAssignment$Msg$Closed),
		{
			bD: _List_fromArray(
				[
					A2(
					$author$project$Views$Common$BootstrapButtons$danger,
					$elm$core$Maybe$Just($author$project$Views$MapAssignment$Msg$CloseConfirmationModal),
					'Cancel'),
					A2($author$project$Views$Common$BootstrapButtons$primary, deleteMsg, 'OK')
				]),
			bJ: $author$project$Views$MapAssignment$Msg$CloseConfirmationModal,
			gm: 'Are you sure?',
			hH: function () {
				switch (hideOrDeleteModalStatus.$) {
					case 1:
						return 'Hide all routes';
					case 2:
						var count = hideOrDeleteModalStatus.a;
						return 'Hide ' + ($elm$core$String$fromInt(count) + ' routes');
					default:
						return '';
				}
			}()
		});
};
var $elm$virtual_dom$VirtualDom$lazy2 = _VirtualDom_lazy2;
var $elm$html$Html$Lazy$lazy2 = $elm$virtual_dom$VirtualDom$lazy2;
var $author$project$Views$MapAssignment$Msg$OnDriverAssignmentInput = function (a) {
	return {$: 75, a: a};
};
var $author$project$Views$MapAssignment$Msg$SetDriver = F2(
	function (a, b) {
		return {$: 31, a: a, b: b};
	});
var $author$project$Views$MapAssignment$View$clickWithoutPropagation = function (msg) {
	return A2(
		$elm$html$Html$Events$custom,
		'click',
		$elm$json$Json$Decode$succeed(
			{gm: msg, g$: true, hv: true}));
};
var $author$project$Views$MapAssignment$View$driverAssignmentView = F2(
	function (routes, drivers) {
		var isChanging = function (routeContainer) {
			var _v1 = routeContainer.ha;
			if (_v1.$ === 2) {
				return true;
			} else {
				return false;
			}
		};
		var driverInfo = F2(
			function (routeContainer, driver) {
				var selected = _Utils_eq(
					routeContainer.g9.fh,
					$elm$core$Maybe$Just(driver));
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('driver-selection pl-1'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('selected', selected)
								]))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('name'),
									$author$project$Views$MapAssignment$View$clickWithoutPropagation(
									A2($author$project$Views$MapAssignment$Msg$SetDriver, routeContainer, driver))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(driver.fJ)
								]))
						]));
			});
		var _v0 = routes.bH;
		if (!_v0.$) {
			var rc = _v0.a;
			return A2(
				$author$project$HtmlExtra$when,
				isChanging(rc),
				A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('route-driver-list-container')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('route-driver-list undelivered')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$type_('text'),
											$elm$html$Html$Attributes$class('driver-route-list-input mb-3'),
											$elm$html$Html$Events$onInput($author$project$Views$MapAssignment$Msg$OnDriverAssignmentInput)
										]),
									_List_Nil),
									A2(
									$elm$html$Html$div,
									_List_Nil,
									A2(
										$elm$core$List$map,
										driverInfo(rc),
										drivers))
								]))
						])));
		} else {
			return $elm$html$Html$text('');
		}
	});
var $author$project$Views$MapAssignment$Msg$BoundsChanged = function (a) {
	return {$: 74, a: a};
};
var $author$project$Views$MapAssignment$Msg$OnDrawingComplete = function (a) {
	return {$: 86, a: a};
};
var $author$project$Views$MapAssignment$Msg$OnPolygonFilterCompleted = function (a) {
	return {$: 52, a: a};
};
var $author$project$Views$MapAssignment$Msg$ZoomChanged = function (a) {
	return {$: 73, a: a};
};
var $author$project$Views$Helpers$GoogleMap$isClustered = F2(
	function (flag, gma) {
		return _Utils_update(
			gma,
			{fZ: flag});
	});
var $author$project$Views$MapAssignment$Settings$ClusterSettings$isClustered = function (clusterSettings) {
	return !_Utils_eq(clusterSettings, $author$project$Views$MapAssignment$Types$NotClustered);
};
var $author$project$Views$MapAssignment$Msg$ExcludeOrderFromFilterByPolygons = function (a) {
	return {$: 51, a: a};
};
var $author$project$Views$MapAssignment$Msg$ShowGroupedOrders = function (a) {
	return {$: 55, a: a};
};
var $author$project$Views$Helpers$Icon$basicPin = F2(
	function (pinColor, size) {
		var icon = $author$project$Views$Helpers$Icon$genericPin(size)(size)(size)(4)(32)(2)(pinColor)('#000')('#E5E5E5')('1')('1')(_List_Nil);
		return $author$project$Views$Helpers$Icon$encodeIcon(icon);
	});
var $author$project$Views$MapAssignment$RouteContainer$isOrderInRouteOrderIds = F2(
	function (ordersId, order) {
		return A2($elm$core$Set$member, order.fS, ordersId);
	});
var $author$project$Views$Helpers$Maybe$maybeExists = function (maybeA) {
	if (maybeA.$ === 1) {
		return false;
	} else {
		return true;
	}
};
var $author$project$Views$Helpers$Coordinates$sameLocation = F2(
	function (latlon1, latlon2) {
		return _Utils_eq(latlon1.f8, latlon2.f8) && _Utils_eq(latlon1.gb, latlon2.gb);
	});
var $author$project$Views$Helpers$Coordinates$maybeSameLocation = F2(
	function (maybeLatlon1, maybeLatlon2) {
		return A3($elm$core$Maybe$map2, $author$project$Views$Helpers$Coordinates$sameLocation, maybeLatlon1, maybeLatlon2);
	});
var $author$project$Views$Helpers$Icon$numberPin = F2(
	function (opacityVal, num) {
		var pinDecoration = A3(
			$author$project$Views$Helpers$Icon$pinLabel,
			'20',
			'25',
			'x' + $elm$core$String$fromInt(num));
		var icon = $author$project$Views$Helpers$Icon$genericPin(20)(20)(20)(4)(32)(2)('#FEDD3E')('#000')('#E5E5E5')(
			$elm$core$String$fromFloat(opacityVal))('1')(
			_List_fromArray(
				[pinDecoration]));
		return $author$project$Views$Helpers$Icon$encodeIcon(icon);
	});
var $author$project$Views$Helpers$Icon$regularPin = F2(
	function (opacityVal, pinColor) {
		var icon = $author$project$Views$Helpers$Icon$genericPin(6)(6)(6)(2)(12)(2)(pinColor)('#000')('#E5E5E5')(
			$elm$core$String$fromFloat(opacityVal))('1')(_List_Nil);
		return $author$project$Views$Helpers$Icon$encodeIcon(icon);
	});
var $author$project$Views$MapAssignment$Msg$RemoveFromRouteContainer = function (a) {
	return {$: 23, a: a};
};
var $author$project$Views$MapAssignment$Utils$foldHelperOrder = F2(
	function (routedOrder, dict) {
		var _v0 = $author$project$Views$MapAssignment$Utils$pinCoordinates(routedOrder.cZ);
		if (!_v0.$) {
			var coordinates = _v0.a;
			var tupleCoordinates = _Utils_Tuple2(coordinates.f8, coordinates.gb);
			return A2($elm$core$Dict$member, tupleCoordinates, dict) ? A3(
				$elm$core$Dict$update,
				tupleCoordinates,
				function (maybeOrders) {
					return A2(
						$elm$core$Maybe$map,
						function (routeOrders) {
							return A2($elm$core$List$cons, routedOrder, routeOrders);
						},
						maybeOrders);
				},
				dict) : A3(
				$elm$core$Dict$insert,
				tupleCoordinates,
				_List_fromArray(
					[routedOrder]),
				dict);
		} else {
			return dict;
		}
	});
var $elm$core$List$minimum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$min, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Views$Helpers$Colors$reverseOrderPinColor = '#fe4859';
var $author$project$Views$Helpers$Colors$statusToColor = '#00FFAC';
var $author$project$Views$MapAssignment$MapView$selectedPinColor = function (order) {
	return (order.gK === 1) ? $author$project$Views$Helpers$Colors$reverseOrderPinColor : $author$project$Views$Helpers$Colors$statusToColor;
};
var $author$project$Views$MapAssignment$MapView$selecterMarkersFromRouteContainer = function (routeContainer) {
	var defaultPinConfig = {
		et: $elm$core$Maybe$Just(
			_Utils_Tuple2(20, 70)),
		eM: $elm$core$Maybe$Nothing,
		eR: false,
		ff: false,
		fy: false,
		fR: A3($author$project$Views$Helpers$Icon$routePinNumStr, $author$project$Views$Helpers$Colors$statusToColor, '1', $elm$core$Maybe$Nothing),
		fS: 'S',
		f9: 0,
		gf: 0,
		hn: $elm$core$Maybe$Just(
			_Utils_Tuple2(40, 70)),
		el: 5
	};
	var groupedMarkers = function (_v1) {
		var coordinates = _v1.a;
		var routeOrders = _v1.b;
		if ($elm$core$List$length(routeOrders) === 1) {
			return A2(
				$elm$core$Maybe$map,
				function (_v0) {
					var order = _v0.cZ;
					var position = _v0.gW;
					return $author$project$Views$Helpers$GoogleMap$MarkerObject(
						_Utils_update(
							defaultPinConfig,
							{
								eM: $elm$core$Maybe$Just(
									$author$project$Views$MapAssignment$Msg$RemoveFromRouteContainer(
										_List_fromArray(
											[order]))),
								fR: A3(
									$author$project$Views$Helpers$Icon$routePinNum,
									$author$project$Views$MapAssignment$MapView$selectedPinColor(order),
									position + 1,
									$elm$core$Maybe$Nothing),
								fS: 'S' + $elm$core$String$fromInt(order.fS),
								f9: coordinates.a,
								gf: coordinates.b
							}));
				},
				$elm$core$List$head(routeOrders));
		} else {
			var positions = A2(
				$elm$core$List$map,
				function ($) {
					return $.gW;
				},
				routeOrders);
			var min = A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$List$minimum(positions));
			var max = A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$List$maximum(positions));
			return $elm$core$Maybe$Just(
				$author$project$Views$Helpers$GoogleMap$MarkerObject(
					_Utils_update(
						defaultPinConfig,
						{
							eM: $elm$core$Maybe$Just(
								$author$project$Views$MapAssignment$Msg$RemoveFromRouteContainer(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.cZ;
										},
										routeOrders))),
							fR: A3(
								$author$project$Views$Helpers$Icon$routePinNumStr,
								$author$project$Views$Helpers$Colors$statusToColor,
								$elm$core$String$fromInt(min + 1) + ('..' + $elm$core$String$fromInt(max + 1)),
								$elm$core$Maybe$Nothing),
							fS: 'S' + $elm$core$String$fromInt(1),
							f9: coordinates.a,
							gf: coordinates.b
						})));
		}
	};
	return A2(
		$elm$core$List$filterMap,
		groupedMarkers,
		$elm$core$Dict$toList(
			A3($elm$core$List$foldl, $author$project$Views$MapAssignment$Utils$foldHelperOrder, $elm$core$Dict$empty, routeContainer.g9.gL)));
};
var $author$project$Views$Helpers$Icon$storePin = function () {
	var pinDecoration = A3(
		$PaackEng$elm_svg_string$Svg$String$node,
		'path',
		_List_fromArray(
			[
				A2($PaackEng$elm_svg_string$Svg$String$Attributes$attribute, 'd', 'M31.6666667,16.9427609 C31.6666667,18.3017269 30.6,19.3209514 29.3333333,19.3209514 C28.0666667,19.3209514 27,18.2337786 27,16.9427609 C27,18.3017269 25.9333333,19.3209514 24.6666667,19.3209514 C23.4,19.3209514 22.3333333,18.2337786 22.3333333,16.9427609 C22.3333333,18.3017269 21.2666667,19.3209514 20,19.3209514 C18.7333333,19.3209514 17.6666667,18.2337786 17.6666667,16.9427609 C17.6666667,18.3017269 16.6,19.3209514 15.3333333,19.3209514 C14.0666667,19.3209514 13,18.2337786 13,16.9427609 C13,18.3017269 11.9333333,19.3209514 10.6666667,19.3209514 C9.4,19.3209514 8.33333333,18.3017269 8.33333333,16.9427609 L12,11.1671554 L28,11.1671554 L31.6666667,16.9427609 Z M29.3333333,22.0388835 C29.5333333,22.0388835 29.8,22.0388835 30,21.9709352 L30,29.5131965 L28,29.5131965 L27.3333333,29.5131965 L12.6666667,29.5131965 L12,29.5131965 L10,29.5131965 L10,21.9709352 C10.2,22.0388835 10.4666667,22.0388835 10.6666667,22.0388835 C11.4,22.0388835 12.0666667,21.9029869 12.6666667,21.6311937 L12.6666667,26.7952645 L27.3333333,26.7952645 L27.3333333,21.6311937 C27.9333333,21.9029869 28.6,22.0388835 29.3333333,22.0388835 Z'),
				$PaackEng$elm_svg_string$Svg$String$Attributes$fill('#0E1520'),
				$PaackEng$elm_svg_string$Svg$String$Attributes$fillOpacity('0.75')
			]),
		_List_Nil);
	var icon = $author$project$Views$Helpers$Icon$genericPin(20)(20)(20)(3)(20)(3)('#FFF')('#000')('#C9C9C9')('1')('0.5')(
		_List_fromArray(
			[pinDecoration]));
	return $author$project$Views$Helpers$Icon$encodeIcon(icon);
}();
var $author$project$Views$Helpers$Colors$timeSlotColor = F3(
	function (zone, startTime, endTime) {
		var _v0 = _Utils_Tuple2(
			A2($elm$time$Time$toHour, zone, startTime),
			A2($elm$time$Time$toHour, zone, endTime));
		switch (_v0.a) {
			case 10:
				return '#940927';
			case 11:
				switch (_v0.b) {
					case 13:
						return '#2e808a';
					case 16:
						return '#8a572e';
					default:
						return '#d10001';
				}
			case 12:
				return '#f9e232';
			case 13:
				return '#fd9727';
			case 14:
				return '#57cf25';
			case 15:
				return '#54a91d';
			case 16:
				return '#296b0e';
			case 17:
				return '#2591d7';
			case 18:
				return '#2e45d8';
			case 19:
				return '#276092';
			case 20:
				return '#dd44ca';
			case 21:
				return '#9E9E9E';
			default:
				return '#57cf25';
		}
	});
var $author$project$Views$MapAssignment$RouteContainer$uniqueRouteOrderIds = function (routeContainers) {
	return $elm$core$Set$fromList(
		A2(
			$elm$core$List$concatMap,
			function (container) {
				return A2(
					$elm$core$List$map,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.cZ;
						},
						function ($) {
							return $.fS;
						}),
					container.g9.gL);
			},
			routeContainers));
};
var $author$project$Views$MapAssignment$MapView$markerObjects = F2(
	function (timeZone, model) {
		var filteredOrdersIds = model.fF;
		var filters = model.fG;
		var warehouses = model.h0;
		var routes = model.hc;
		var maps = model.gh;
		var areas = model.bx;
		var warehouseMarkers = A2(
			$elm$core$List$filterMap,
			function (warehouse) {
				return A2(
					$elm$core$Maybe$map,
					function (latlon) {
						return $author$project$Views$Helpers$GoogleMap$MarkerObject(
							{
								et: $elm$core$Maybe$Just(
									_Utils_Tuple2(20, 58)),
								eM: $elm$core$Maybe$Nothing,
								eR: true,
								ff: false,
								fy: false,
								fR: $author$project$Views$Helpers$Icon$storePin,
								fS: 'W' + $elm$core$String$fromInt(warehouse.fS),
								f9: latlon.f8,
								gf: latlon.gb,
								hn: $elm$core$Maybe$Just(
									_Utils_Tuple2(40, 58)),
								el: 2
							});
					},
					A2(
						$elm$core$Maybe$andThen,
						function ($) {
							return $.ga;
						},
						warehouse.er));
			},
			warehouses);
		var selectedRouteContainerMarkers = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2($elm$core$Maybe$map, $author$project$Views$MapAssignment$MapView$selecterMarkersFromRouteContainer, routes.bd));
		var routeDirections = function () {
			var _v12 = routes.bd;
			if (!_v12.$) {
				var routeContainer = _v12.a;
				var _v13 = routeContainer.ho;
				if (!_v13.$) {
					var solution = _v13.a;
					return A2(
						$elm$core$List$concatMap,
						function (route) {
							return A2(
								$elm$core$List$map,
								function (point) {
									var convertToMapPoint = function (_v15) {
										var lat = _v15.a;
										var lng = _v15.b;
										return {f8: lat, gb: lng};
									};
									if (!point.$) {
										var p = point.a;
										return $author$project$Views$Helpers$GoogleMap$PolygonObject(
											{
												eO: false,
												fD: 'red',
												fE: 1,
												gU: _List_fromArray(
													[
														convertToMapPoint(p.bR)
													]),
												hx: 'red',
												hy: 10,
												el: 1
											});
									} else {
										var lineString = point.a;
										return $author$project$Views$Helpers$GoogleMap$PolygonObject(
											{
												eO: false,
												fD: 'blue',
												fE: 1,
												gU: A2($elm$core$List$map, convertToMapPoint, lineString.bR),
												hx: 'blue',
												hy: 5,
												el: 1
											});
									}
								},
								route.gU);
						},
						solution.hc);
				} else {
					return _List_Nil;
				}
			} else {
				return _List_Nil;
			}
		}();
		var removeOrdersBeingProcessed = function (orders) {
			return A2(
				$elm$core$List$filter,
				function (o) {
					return !A2($elm$core$Set$member, o.fS, routes.c0);
				},
				orders);
		};
		var polygonFilter = filters.c8;
		var orderIds = $author$project$Views$MapAssignment$RouteContainer$uniqueRouteOrderIds(routes.g8);
		var mapBounds = function (marker) {
			var _v10 = maps.eD;
			var lon1 = _v10.a;
			var lat1 = _v10.b;
			var lon2 = _v10.c;
			var lat2 = _v10.d;
			if (!marker.$) {
				var a = marker.a;
				return (_Utils_cmp(a.gf, lon2) < 1) && ((_Utils_cmp(a.gf, lon1) > -1) && ((_Utils_cmp(a.f9, lat2) < 1) && (_Utils_cmp(a.f9, lat1) > -1)));
			} else {
				return true;
			}
		};
		var isOrderInARoute = $author$project$Views$MapAssignment$RouteContainer$isOrderInRouteOrderIds(orderIds);
		var opacity = function (order) {
			return A3(
				$author$project$Utils$ifThenElse,
				isOrderInARoute(order),
				0.25,
				1);
		};
		var filteredOrders = A2($author$project$Views$MapAssignment$Model$orderIdsToOrders, model.gL, filteredOrdersIds);
		var filterByPolygonsOrders = function () {
			if (polygonFilter.$ === 1) {
				var excluded = polygonFilter.c;
				var polygons = polygonFilter.d;
				var allExcluded = A2($elm$core$Set$union, routes.c0, excluded);
				return $elm$core$Dict$values(
					A3(
						$author$project$Views$MapAssignment$Update$ordersInsidePolygons,
						allExcluded,
						A2($author$project$Views$MapAssignment$Model$orderIdsToOrdersDict, model.gL, filteredOrdersIds),
						polygons));
			} else {
				return _List_Nil;
			}
		}();
		var removeOrdersFilteredByPolygons = function (orders) {
			var filterByPolygonsOrderIds = $elm$core$Set$fromList(
				A2(
					$elm$core$List$map,
					function ($) {
						return $.fS;
					},
					filterByPolygonsOrders));
			return A2(
				$elm$core$List$filter,
				function (o) {
					return !A2($elm$core$Set$member, o.fS, filterByPolygonsOrderIds);
				},
				orders);
		};
		var filterByPolygonsOrderMarkers = A2(
			$elm$core$List$filterMap,
			function (order) {
				var _v8 = $author$project$Views$MapAssignment$Utils$addressPinLatLon(order);
				if (!_v8.$) {
					var latlon = _v8.a;
					return $elm$core$Maybe$Just(
						$author$project$Views$Helpers$GoogleMap$MarkerObject(
							{
								et: $elm$core$Maybe$Just(
									_Utils_Tuple2(20, 58)),
								eM: $elm$core$Maybe$Just(
									$author$project$Views$MapAssignment$Msg$ExcludeOrderFromFilterByPolygons(order)),
								eR: true,
								ff: false,
								fy: false,
								fR: A2($author$project$Views$Helpers$Icon$basicPin, $author$project$Views$Helpers$Colors$statusToColor, 10),
								fS: 'PF' + $elm$core$String$fromInt(order.fS),
								f9: latlon.f8,
								gf: latlon.gb,
								hn: $elm$core$Maybe$Just(
									_Utils_Tuple2(40, 58)),
								el: 5
							}));
				} else {
					return $elm$core$Maybe$Nothing;
				}
			},
			filterByPolygonsOrders);
		var drawAreas = A2(
			$elm$core$List$map,
			function (area) {
				return $author$project$Views$Helpers$GoogleMap$PolygonObject(
					{eO: false, fD: '#DEE1E3', fE: 0.35, gU: area.gV, hx: '#DEE1E3', hy: 2, el: 10});
			},
			areas);
		var deliveryMarkers = function () {
			var isSelectedOrder = F2(
				function (order, selectedOrders) {
					return A2(
						$elm$core$List$any,
						function (o) {
							return _Utils_eq(o.fS, order.fS);
						},
						selectedOrders);
				});
			var hasSelectedOrders = F2(
				function (orders, selectedOrders) {
					return A2(
						$elm$core$List$any,
						function (o) {
							return A2(
								$elm$core$List$any,
								function (so) {
									return _Utils_eq(so.fS, o.fS);
								},
								selectedOrders);
						},
						orders);
				});
			var groupedDeliveryMarker = F2(
				function (orders, _v7) {
					var maybeFirstOrder = $elm$core$List$head(orders);
					var maybeLatLon = A2($elm$core$Maybe$andThen, $author$project$Views$MapAssignment$Utils$addressPinLatLon, maybeFirstOrder);
					var _v5 = A2($elm$core$List$any, isOrderInARoute, orders) ? _Utils_Tuple2($elm$core$Maybe$Nothing, 0.25) : _Utils_Tuple2(
						$elm$core$Maybe$Just(
							$author$project$Views$MapAssignment$Msg$ShowGroupedOrders(orders)),
						1);
					var action = _v5.a;
					var ordersOpacity = _v5.b;
					if (!maybeLatLon.$) {
						var latlon = maybeLatLon.a;
						var id = $elm$core$String$fromInt(
							A2(
								$elm$core$Maybe$withDefault,
								0,
								A2(
									$elm$core$Maybe$map,
									function ($) {
										return $.fS;
									},
									maybeFirstOrder)));
						var marker = {
							et: $elm$core$Maybe$Just(
								_Utils_Tuple2(20, 70)),
							eM: action,
							eR: true,
							ff: maps.ff,
							fy: maps.ff,
							fR: A2(
								$author$project$Views$Helpers$Icon$numberPin,
								ordersOpacity,
								$elm$core$List$length(orders)),
							fS: 'GD' + id,
							f9: latlon.f8,
							gf: latlon.gb,
							hn: $elm$core$Maybe$Just(
								_Utils_Tuple2(40, 70)),
							el: 1
						};
						return $elm$core$Maybe$Just(
							$author$project$Views$Helpers$GoogleMap$MarkerObject(marker));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				});
			var groupByDeliveryLocation = function (orders) {
				var sameLocation = F2(
					function (o1, o2) {
						return A2(
							$elm$core$Maybe$withDefault,
							false,
							A2(
								$author$project$Views$Helpers$Coordinates$maybeSameLocation,
								$author$project$Views$MapAssignment$Utils$addressPinLatLon(o1),
								$author$project$Views$MapAssignment$Utils$addressPinLatLon(o2)));
					});
				return A2(
					$elm$core$List$map,
					function (_v4) {
						var h = _v4.a;
						var list = _v4.b;
						return A2($elm$core$List$cons, h, list);
					},
					A2(
						$elm_community$list_extra$List$Extra$groupWhile,
						sameLocation,
						A2(
							$elm$core$List$sortWith,
							F2(
								function (o1, o2) {
									return A2(
										$elm$core$Maybe$withDefault,
										0,
										A3(
											$elm$core$Maybe$map2,
											F2(
												function (latlon1, latlon2) {
													return A2($elm$core$Basics$compare, latlon1.f8 + latlon1.gb, latlon2.f8 + latlon2.gb);
												}),
											$author$project$Views$MapAssignment$Utils$addressPinLatLon(o1),
											$author$project$Views$MapAssignment$Utils$addressPinLatLon(o2)));
								}),
							A2(
								$elm$core$List$filterMap,
								function (order) {
									return A2(
										$elm$core$Maybe$map,
										function (_v3) {
											return order;
										},
										$author$project$Views$MapAssignment$Utils$addressPinLatLon(order));
								},
								orders))));
			};
			var addToRoute = $author$project$Views$Helpers$Maybe$maybeExists(routes.bd);
			var deliveryMarkerAction = function (orders) {
				return addToRoute ? $elm$core$Maybe$Just(
					$author$project$Views$MapAssignment$Msg$AddToRouteContainer(orders)) : $elm$core$Maybe$Just(
					$author$project$Views$MapAssignment$Msg$ShowGroupedOrders(orders));
			};
			var singleDeliveryMaker = F2(
				function (order, _v2) {
					var _v1 = $author$project$Views$MapAssignment$Utils$addressPinLatLon(order);
					if (!_v1.$) {
						var latlon = _v1.a;
						if (isOrderInARoute(order)) {
							return $elm$core$Maybe$Nothing;
						} else {
							var pinColor = A3($author$project$Views$Helpers$Colors$timeSlotColor, timeZone, order.e7.hq, order.e7.fp);
							var opacityVal = opacity(order);
							var marker = {
								et: $elm$core$Maybe$Just(
									_Utils_Tuple2(6, 22)),
								eM: deliveryMarkerAction(
									_List_fromArray(
										[order])),
								eR: true,
								ff: maps.ff,
								fy: maps.ff,
								fR: A2($author$project$Views$Helpers$Icon$regularPin, opacityVal, pinColor),
								fS: 'SD' + $elm$core$String$fromInt(order.fS),
								f9: latlon.f8,
								gf: latlon.gb,
								hn: $elm$core$Maybe$Just(
									_Utils_Tuple2(12, 22)),
								el: 10
							};
							return $elm$core$Maybe$Just(
								$author$project$Views$Helpers$GoogleMap$MarkerObject(marker));
						}
					} else {
						return $elm$core$Maybe$Nothing;
					}
				});
			var markersList = F2(
				function (selectedOrders, orderGroups) {
					return A2(
						$elm$core$List$filterMap,
						function (orderGroup) {
							if (!orderGroup.b) {
								return $elm$core$Maybe$Nothing;
							} else {
								if (!orderGroup.b.b) {
									var order = orderGroup.a;
									var allOrders = selectedOrders;
									return A2(
										singleDeliveryMaker,
										order,
										A2(isSelectedOrder, order, allOrders));
								} else {
									var orders = orderGroup;
									var allOrders = selectedOrders;
									return A2(
										groupedDeliveryMarker,
										orders,
										A2(hasSelectedOrders, orders, allOrders));
								}
							}
						},
						orderGroups);
				});
			return A2(
				markersList,
				_List_Nil,
				groupByDeliveryLocation(
					removeOrdersFilteredByPolygons(
						removeOrdersBeingProcessed(filteredOrders))));
		}();
		return A2(
			$elm$core$List$filter,
			mapBounds,
			A2(
				$elm$core$List$append,
				drawAreas,
				A2(
					$elm$core$List$append,
					filterByPolygonsOrderMarkers,
					A2(
						$elm$core$List$append,
						selectedRouteContainerMarkers,
						A2(
							$elm$core$List$append,
							routeDirections,
							A2($elm$core$List$append, warehouseMarkers, deliveryMarkers))))));
	});
var $author$project$Views$Helpers$GoogleMap$setBoundEvent = F2(
	function (event, gma) {
		return _Utils_update(
			gma,
			{
				eE: $elm$core$Maybe$Just(event)
			});
	});
var $author$project$Views$Helpers$GoogleMap$setClusterAttributes = F2(
	function (clusterAttributes, gma) {
		return _Utils_update(
			gma,
			{eP: clusterAttributes});
	});
var $author$project$Views$Helpers$GoogleMap$setCoordinates = F2(
	function (coordinates, gma) {
		return _Utils_update(
			gma,
			{bR: coordinates});
	});
var $author$project$Views$Helpers$GoogleMap$setDrawingCompleteEvent = F2(
	function (event, gma) {
		return _Utils_update(
			gma,
			{
				fg: $elm$core$Maybe$Just(event)
			});
	});
var $author$project$Views$Helpers$GoogleMap$setIsActive = F2(
	function (newVal, googleAttributes) {
		return _Utils_update(
			googleAttributes,
			{fY: newVal});
	});
var $author$project$Views$Helpers$GoogleMap$setIsDrawing = F2(
	function (newVal, googleAttributes) {
		return _Utils_update(
			googleAttributes,
			{f$: newVal});
	});
var $author$project$Views$Helpers$GoogleMap$setZoomEvent = F2(
	function (event, gma) {
		return _Utils_update(
			gma,
			{
				h5: $elm$core$Maybe$Just(event)
			});
	});
var $author$project$Views$MapAssignment$Settings$ClusterSettings$toMaybe = function (clusterSettings) {
	if (clusterSettings.$ === 1) {
		var settings = clusterSettings.a;
		return $elm$core$Maybe$Just(settings);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Views$MapAssignment$View$mapView = F2(
	function (_v0, model) {
		var zone = _v0.em;
		var mapsApiKey = _v0.gi;
		var filters = model.fG;
		var maps = model.gh;
		var settings = model.hl;
		var markers = A2($author$project$Views$MapAssignment$MapView$markerObjects, zone, model);
		var _v1 = function () {
			var _v2 = filters.c8;
			if (_v2.$ === 1) {
				var isDrawing = _v2.a;
				return _Utils_Tuple3(true, isDrawing, $author$project$Views$MapAssignment$Msg$OnPolygonFilterCompleted);
			} else {
				return _Utils_Tuple3(maps.fY, false, $author$project$Views$MapAssignment$Msg$OnDrawingComplete);
			}
		}();
		var isActiveValue = _v1.a;
		var isDrawingValue = _v1.b;
		var onDrawingComplete = _v1.c;
		var googleMapsAttributes = A2(
			$author$project$Views$Helpers$GoogleMap$setIsDrawing,
			isDrawingValue,
			A2(
				$author$project$Views$Helpers$GoogleMap$setIsActive,
				isActiveValue,
				A2(
					$author$project$Views$Helpers$GoogleMap$setDraggable,
					!maps.ff,
					A2(
						$author$project$Views$Helpers$GoogleMap$setClusterAttributes,
						$author$project$Views$MapAssignment$Settings$ClusterSettings$toMaybe(settings.eQ),
						A2(
							$author$project$Views$Helpers$GoogleMap$isClustered,
							$author$project$Views$MapAssignment$Settings$ClusterSettings$isClustered(settings.eQ),
							A2(
								$author$project$Views$Helpers$GoogleMap$setDrawingCompleteEvent,
								onDrawingComplete,
								A2(
									$author$project$Views$Helpers$GoogleMap$setBoundEvent,
									$author$project$Views$MapAssignment$Msg$BoundsChanged,
									A2(
										$author$project$Views$Helpers$GoogleMap$setZoomEvent,
										$author$project$Views$MapAssignment$Msg$ZoomChanged,
										A2(
											$author$project$Views$Helpers$GoogleMap$setMapStyle,
											maps.cG,
											A2(
												$author$project$Views$Helpers$GoogleMap$setZoom,
												maps.h4,
												A2(
													$author$project$Views$Helpers$GoogleMap$setCoordinates,
													maps.bR,
													A2(
														$author$project$Views$Helpers$GoogleMap$fitToMarkers,
														maps.ca,
														$author$project$Views$Common$Maps$defaultGoogleMapAttributes(mapsApiKey)))))))))))));
		return A2($author$project$Views$Helpers$GoogleMap$view, googleMapsAttributes, markers);
	});
var $author$project$Views$MapAssignment$Msg$ClearGrouped = {$: 64};
var $author$project$Views$MapAssignment$Msg$RemoveFromGroupedOrders = function (a) {
	return {$: 56, a: a};
};
var $author$project$Common$Driver$driverName = function (maybeDriver) {
	return A2(
		$elm$core$Maybe$withDefault,
		' ',
		A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.fJ;
			},
			maybeDriver));
};
var $ryan_haskell$date_format$DateFormat$Language$Language = F6(
	function (toMonthName, toMonthAbbreviation, toWeekdayName, toWeekdayAbbreviation, toAmPm, toOrdinalSuffix) {
		return {hI: toAmPm, hJ: toMonthAbbreviation, hK: toMonthName, Y: toOrdinalSuffix, hL: toWeekdayAbbreviation, hM: toWeekdayName};
	});
var $ryan_haskell$date_format$DateFormat$Language$toEnglishAmPm = function (hour) {
	return (hour > 11) ? 'pm' : 'am';
};
var $ryan_haskell$date_format$DateFormat$Language$toEnglishMonthName = function (month) {
	switch (month) {
		case 0:
			return 'January';
		case 1:
			return 'February';
		case 2:
			return 'March';
		case 3:
			return 'April';
		case 4:
			return 'May';
		case 5:
			return 'June';
		case 6:
			return 'July';
		case 7:
			return 'August';
		case 8:
			return 'September';
		case 9:
			return 'October';
		case 10:
			return 'November';
		default:
			return 'December';
	}
};
var $ryan_haskell$date_format$DateFormat$Language$toEnglishSuffix = function (num) {
	var _v0 = A2($elm$core$Basics$modBy, 100, num);
	switch (_v0) {
		case 11:
			return 'th';
		case 12:
			return 'th';
		case 13:
			return 'th';
		default:
			var _v1 = A2($elm$core$Basics$modBy, 10, num);
			switch (_v1) {
				case 1:
					return 'st';
				case 2:
					return 'nd';
				case 3:
					return 'rd';
				default:
					return 'th';
			}
	}
};
var $ryan_haskell$date_format$DateFormat$Language$toEnglishWeekdayName = function (weekday) {
	switch (weekday) {
		case 0:
			return 'Monday';
		case 1:
			return 'Tuesday';
		case 2:
			return 'Wednesday';
		case 3:
			return 'Thursday';
		case 4:
			return 'Friday';
		case 5:
			return 'Saturday';
		default:
			return 'Sunday';
	}
};
var $ryan_haskell$date_format$DateFormat$Language$english = A6(
	$ryan_haskell$date_format$DateFormat$Language$Language,
	$ryan_haskell$date_format$DateFormat$Language$toEnglishMonthName,
	A2(
		$elm$core$Basics$composeR,
		$ryan_haskell$date_format$DateFormat$Language$toEnglishMonthName,
		$elm$core$String$left(3)),
	$ryan_haskell$date_format$DateFormat$Language$toEnglishWeekdayName,
	A2(
		$elm$core$Basics$composeR,
		$ryan_haskell$date_format$DateFormat$Language$toEnglishWeekdayName,
		$elm$core$String$left(3)),
	$ryan_haskell$date_format$DateFormat$Language$toEnglishAmPm,
	$ryan_haskell$date_format$DateFormat$Language$toEnglishSuffix);
var $ryan_haskell$date_format$DateFormat$amPm = F3(
	function (language, zone, posix) {
		return language.hI(
			A2($elm$time$Time$toHour, zone, posix));
	});
var $ryan_haskell$date_format$DateFormat$dayOfMonth = $elm$time$Time$toDay;
var $ryan_haskell$date_format$DateFormat$days = _List_fromArray(
	[6, 0, 1, 2, 3, 4, 5]);
var $elm$time$Time$toWeekday = F2(
	function (zone, time) {
		var _v0 = A2(
			$elm$core$Basics$modBy,
			7,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60 * 24));
		switch (_v0) {
			case 0:
				return 3;
			case 1:
				return 4;
			case 2:
				return 5;
			case 3:
				return 6;
			case 4:
				return 0;
			case 5:
				return 1;
			default:
				return 2;
		}
	});
var $ryan_haskell$date_format$DateFormat$dayOfWeek = F2(
	function (zone, posix) {
		return function (_v1) {
			var i = _v1.a;
			return i;
		}(
			A2(
				$elm$core$Maybe$withDefault,
				_Utils_Tuple2(0, 6),
				$elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (_v0) {
							var day = _v0.b;
							return _Utils_eq(
								day,
								A2($elm$time$Time$toWeekday, zone, posix));
						},
						A2(
							$elm$core$List$indexedMap,
							F2(
								function (i, day) {
									return _Utils_Tuple2(i, day);
								}),
							$ryan_haskell$date_format$DateFormat$days)))));
	});
var $ryan_haskell$date_format$DateFormat$isLeapYear = function (year_) {
	return (!(!A2($elm$core$Basics$modBy, 4, year_))) ? false : ((!(!A2($elm$core$Basics$modBy, 100, year_))) ? true : ((!(!A2($elm$core$Basics$modBy, 400, year_))) ? false : true));
};
var $ryan_haskell$date_format$DateFormat$daysInMonth = F2(
	function (year_, month) {
		switch (month) {
			case 0:
				return 31;
			case 1:
				return $ryan_haskell$date_format$DateFormat$isLeapYear(year_) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $ryan_haskell$date_format$DateFormat$months = _List_fromArray(
	[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
var $ryan_haskell$date_format$DateFormat$monthPair = F2(
	function (zone, posix) {
		return A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2(0, 0),
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (_v0) {
						var i = _v0.a;
						var m = _v0.b;
						return _Utils_eq(
							m,
							A2($elm$time$Time$toMonth, zone, posix));
					},
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (a, b) {
								return _Utils_Tuple2(a, b);
							}),
						$ryan_haskell$date_format$DateFormat$months))));
	});
var $ryan_haskell$date_format$DateFormat$monthNumber_ = F2(
	function (zone, posix) {
		return 1 + function (_v0) {
			var i = _v0.a;
			var m = _v0.b;
			return i;
		}(
			A2($ryan_haskell$date_format$DateFormat$monthPair, zone, posix));
	});
var $ryan_haskell$date_format$DateFormat$dayOfYear = F2(
	function (zone, posix) {
		var monthsBeforeThisOne = A2(
			$elm$core$List$take,
			A2($ryan_haskell$date_format$DateFormat$monthNumber_, zone, posix) - 1,
			$ryan_haskell$date_format$DateFormat$months);
		var daysBeforeThisMonth = $elm$core$List$sum(
			A2(
				$elm$core$List$map,
				$ryan_haskell$date_format$DateFormat$daysInMonth(
					A2($elm$time$Time$toYear, zone, posix)),
				monthsBeforeThisOne));
		return daysBeforeThisMonth + A2($ryan_haskell$date_format$DateFormat$dayOfMonth, zone, posix);
	});
var $ryan_haskell$date_format$DateFormat$quarter = F2(
	function (zone, posix) {
		return (A2($ryan_haskell$date_format$DateFormat$monthNumber_, zone, posix) / 4) | 0;
	});
var $elm$core$String$right = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(
			$elm$core$String$slice,
			-n,
			$elm$core$String$length(string),
			string);
	});
var $ryan_haskell$date_format$DateFormat$toFixedLength = F2(
	function (totalChars, num) {
		var numStr = $elm$core$String$fromInt(num);
		var numZerosNeeded = totalChars - $elm$core$String$length(numStr);
		var zeros = A2(
			$elm$core$String$join,
			'',
			A2(
				$elm$core$List$map,
				function (_v0) {
					return '0';
				},
				A2($elm$core$List$range, 1, numZerosNeeded)));
		return _Utils_ap(zeros, numStr);
	});
var $ryan_haskell$date_format$DateFormat$toNonMilitary = function (num) {
	return (!num) ? 12 : ((num <= 12) ? num : (num - 12));
};
var $elm$core$String$toUpper = _String_toUpper;
var $ryan_haskell$date_format$DateFormat$millisecondsPerYear = $elm$core$Basics$round((((1000 * 60) * 60) * 24) * 365.25);
var $ryan_haskell$date_format$DateFormat$firstDayOfYear = F2(
	function (zone, time) {
		return $elm$time$Time$millisToPosix(
			$ryan_haskell$date_format$DateFormat$millisecondsPerYear * A2($elm$time$Time$toYear, zone, time));
	});
var $ryan_haskell$date_format$DateFormat$weekOfYear = F2(
	function (zone, posix) {
		var firstDay = A2($ryan_haskell$date_format$DateFormat$firstDayOfYear, zone, posix);
		var firstDayOffset = A2($ryan_haskell$date_format$DateFormat$dayOfWeek, zone, firstDay);
		var daysSoFar = A2($ryan_haskell$date_format$DateFormat$dayOfYear, zone, posix);
		return (((daysSoFar + firstDayOffset) / 7) | 0) + 1;
	});
var $ryan_haskell$date_format$DateFormat$year = F2(
	function (zone, time) {
		return $elm$core$String$fromInt(
			A2($elm$time$Time$toYear, zone, time));
	});
var $ryan_haskell$date_format$DateFormat$piece = F4(
	function (language, zone, posix, token) {
		switch (token.$) {
			case 0:
				return $elm$core$String$fromInt(
					A2($ryan_haskell$date_format$DateFormat$monthNumber_, zone, posix));
			case 1:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.Y(num));
				}(
					A2($ryan_haskell$date_format$DateFormat$monthNumber_, zone, posix));
			case 2:
				return A2(
					$ryan_haskell$date_format$DateFormat$toFixedLength,
					2,
					A2($ryan_haskell$date_format$DateFormat$monthNumber_, zone, posix));
			case 3:
				return language.hJ(
					A2($elm$time$Time$toMonth, zone, posix));
			case 4:
				return language.hK(
					A2($elm$time$Time$toMonth, zone, posix));
			case 17:
				return $elm$core$String$fromInt(
					1 + A2($ryan_haskell$date_format$DateFormat$quarter, zone, posix));
			case 18:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.Y(num));
				}(
					1 + A2($ryan_haskell$date_format$DateFormat$quarter, zone, posix));
			case 5:
				return $elm$core$String$fromInt(
					A2($ryan_haskell$date_format$DateFormat$dayOfMonth, zone, posix));
			case 6:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.Y(num));
				}(
					A2($ryan_haskell$date_format$DateFormat$dayOfMonth, zone, posix));
			case 7:
				return A2(
					$ryan_haskell$date_format$DateFormat$toFixedLength,
					2,
					A2($ryan_haskell$date_format$DateFormat$dayOfMonth, zone, posix));
			case 8:
				return $elm$core$String$fromInt(
					A2($ryan_haskell$date_format$DateFormat$dayOfYear, zone, posix));
			case 9:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.Y(num));
				}(
					A2($ryan_haskell$date_format$DateFormat$dayOfYear, zone, posix));
			case 10:
				return A2(
					$ryan_haskell$date_format$DateFormat$toFixedLength,
					3,
					A2($ryan_haskell$date_format$DateFormat$dayOfYear, zone, posix));
			case 11:
				return $elm$core$String$fromInt(
					A2($ryan_haskell$date_format$DateFormat$dayOfWeek, zone, posix));
			case 12:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.Y(num));
				}(
					A2($ryan_haskell$date_format$DateFormat$dayOfWeek, zone, posix));
			case 13:
				return language.hL(
					A2($elm$time$Time$toWeekday, zone, posix));
			case 14:
				return language.hM(
					A2($elm$time$Time$toWeekday, zone, posix));
			case 19:
				return $elm$core$String$fromInt(
					A2($ryan_haskell$date_format$DateFormat$weekOfYear, zone, posix));
			case 20:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.Y(num));
				}(
					A2($ryan_haskell$date_format$DateFormat$weekOfYear, zone, posix));
			case 21:
				return A2(
					$ryan_haskell$date_format$DateFormat$toFixedLength,
					2,
					A2($ryan_haskell$date_format$DateFormat$weekOfYear, zone, posix));
			case 15:
				return A2(
					$elm$core$String$right,
					2,
					A2($ryan_haskell$date_format$DateFormat$year, zone, posix));
			case 16:
				return A2($ryan_haskell$date_format$DateFormat$year, zone, posix);
			case 22:
				return $elm$core$String$toUpper(
					A3($ryan_haskell$date_format$DateFormat$amPm, language, zone, posix));
			case 23:
				return $elm$core$String$toLower(
					A3($ryan_haskell$date_format$DateFormat$amPm, language, zone, posix));
			case 24:
				return $elm$core$String$fromInt(
					A2($elm$time$Time$toHour, zone, posix));
			case 25:
				return A2(
					$ryan_haskell$date_format$DateFormat$toFixedLength,
					2,
					A2($elm$time$Time$toHour, zone, posix));
			case 26:
				return $elm$core$String$fromInt(
					$ryan_haskell$date_format$DateFormat$toNonMilitary(
						A2($elm$time$Time$toHour, zone, posix)));
			case 27:
				return A2(
					$ryan_haskell$date_format$DateFormat$toFixedLength,
					2,
					$ryan_haskell$date_format$DateFormat$toNonMilitary(
						A2($elm$time$Time$toHour, zone, posix)));
			case 28:
				return $elm$core$String$fromInt(
					1 + A2($elm$time$Time$toHour, zone, posix));
			case 29:
				return A2(
					$ryan_haskell$date_format$DateFormat$toFixedLength,
					2,
					1 + A2($elm$time$Time$toHour, zone, posix));
			case 30:
				return $elm$core$String$fromInt(
					A2($elm$time$Time$toMinute, zone, posix));
			case 31:
				return A2(
					$ryan_haskell$date_format$DateFormat$toFixedLength,
					2,
					A2($elm$time$Time$toMinute, zone, posix));
			case 32:
				return $elm$core$String$fromInt(
					A2($elm$time$Time$toSecond, zone, posix));
			case 33:
				return A2(
					$ryan_haskell$date_format$DateFormat$toFixedLength,
					2,
					A2($elm$time$Time$toSecond, zone, posix));
			case 34:
				return $elm$core$String$fromInt(
					A2($elm$time$Time$toMillis, zone, posix));
			case 35:
				return A2(
					$ryan_haskell$date_format$DateFormat$toFixedLength,
					3,
					A2($elm$time$Time$toMillis, zone, posix));
			default:
				var string = token.a;
				return string;
		}
	});
var $ryan_haskell$date_format$DateFormat$formatWithLanguage = F4(
	function (language, tokens, zone, time) {
		return A2(
			$elm$core$String$join,
			'',
			A2(
				$elm$core$List$map,
				A3($ryan_haskell$date_format$DateFormat$piece, language, zone, time),
				tokens));
	});
var $ryan_haskell$date_format$DateFormat$format = $ryan_haskell$date_format$DateFormat$formatWithLanguage($ryan_haskell$date_format$DateFormat$Language$english);
var $ryan_haskell$date_format$DateFormat$HourMilitaryFixed = {$: 25};
var $ryan_haskell$date_format$DateFormat$hourMilitaryFixed = $ryan_haskell$date_format$DateFormat$HourMilitaryFixed;
var $ryan_haskell$date_format$DateFormat$MinuteFixed = {$: 31};
var $ryan_haskell$date_format$DateFormat$minuteFixed = $ryan_haskell$date_format$DateFormat$MinuteFixed;
var $ryan_haskell$date_format$DateFormat$Text = function (a) {
	return {$: 36, a: a};
};
var $ryan_haskell$date_format$DateFormat$text = $ryan_haskell$date_format$DateFormat$Text;
var $author$project$Views$Helpers$Date$hourMinutes = F2(
	function (zone, time) {
		return A3(
			$ryan_haskell$date_format$DateFormat$format,
			_List_fromArray(
				[
					$ryan_haskell$date_format$DateFormat$hourMilitaryFixed,
					$ryan_haskell$date_format$DateFormat$text(':'),
					$ryan_haskell$date_format$DateFormat$minuteFixed
				]),
			zone,
			time);
	});
var $ryan_haskell$date_format$DateFormat$DayOfMonthNumber = {$: 5};
var $ryan_haskell$date_format$DateFormat$dayOfMonthNumber = $ryan_haskell$date_format$DateFormat$DayOfMonthNumber;
var $ryan_haskell$date_format$DateFormat$MonthNameAbbreviated = {$: 3};
var $ryan_haskell$date_format$DateFormat$monthNameAbbreviated = $ryan_haskell$date_format$DateFormat$MonthNameAbbreviated;
var $ryan_haskell$date_format$DateFormat$YearNumber = {$: 16};
var $ryan_haskell$date_format$DateFormat$yearNumber = $ryan_haskell$date_format$DateFormat$YearNumber;
var $author$project$Views$Helpers$Date$monthDayYear = F2(
	function (zone, time) {
		var day = A2($elm$time$Time$toDay, zone, time);
		var separator = (day < 10) ? '  ' : ' ';
		return A3(
			$ryan_haskell$date_format$DateFormat$format,
			_List_fromArray(
				[
					$ryan_haskell$date_format$DateFormat$monthNameAbbreviated,
					$ryan_haskell$date_format$DateFormat$text(separator),
					$ryan_haskell$date_format$DateFormat$dayOfMonthNumber,
					$ryan_haskell$date_format$DateFormat$text(', '),
					$ryan_haskell$date_format$DateFormat$yearNumber
				]),
			zone,
			time);
	});
var $author$project$Views$MapAssignment$View$orderDisplayAddress = function (order) {
	var _v0 = order.gK;
	switch (_v0) {
		case 0:
			return order.e6;
		case 1:
			return order.gT;
		default:
			return order.e6;
	}
};
var $author$project$Views$Helpers$Order$orderNumber = function (_v0) {
	var retailerOrderNumber = _v0.g5;
	var paackOrderNumber = _v0.gO;
	var saleNumber = _v0.hf;
	return A2(
		$elm$core$Maybe$withDefault,
		paackOrderNumber,
		A2($author$project$Views$Helpers$Maybe$maybeOr, saleNumber, retailerOrderNumber));
};
var $author$project$Views$MapAssignment$Msg$SetSearchingNearbyRoutes = function (a) {
	return {$: 57, a: a};
};
var $author$project$Views$MapAssignment$View$searchNearbyRoutes = function (order) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('row no-gutters rounded p-3 mt-3 dropzone'),
				$elm$html$Html$Events$onClick(
				$author$project$Views$MapAssignment$Msg$SetSearchingNearbyRoutes(order))
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('col-12 text-center')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Search nearby routes')
					]))
			]));
};
var $author$project$Views$MapAssignment$View$orderDetailCard = F3(
	function (zone, routes, order) {
		var orderIds = $author$project$Views$MapAssignment$RouteContainer$uniqueRouteOrderIds(routes.g8);
		var isOrderInARoute = $author$project$Views$MapAssignment$RouteContainer$isOrderInRouteOrderIds(orderIds);
		var driverOrderNumberInfo = '#' + ($author$project$Views$Helpers$Order$orderNumber(order) + (' ' + $author$project$Common$Driver$driverName(order.fh)));
		var addToRoute = $author$project$Views$Helpers$Maybe$maybeExists(routes.bd);
		var removePackage = (isOrderInARoute(order) && addToRoute) ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('add_stuff clickable'),
					$elm$html$Html$Events$onClick(
					$author$project$Views$MapAssignment$Msg$RemoveFromRouteContainer(
						_List_fromArray(
							[order])))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
							$elm$html$Html$Attributes$class('fa fa-minus-square')
						]),
					_List_Nil)
				])) : $author$project$Views$MapAssignment$View$searchNearbyRoutes(order);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('row no-gutters rounded p-2 mb-1 order-detail-card')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('col-4 align-self-center')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row no-gutters text-center')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-5')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('row time')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('col-12')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															A2($author$project$Views$Helpers$Date$hourMinutes, zone, order.e7.hq))
														]))
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('row date')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('col-12')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															A2($author$project$Views$Helpers$Date$monthDayYear, zone, order.e7.hq))
														]))
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-2')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('-')
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-5')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('row time')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('col-12')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															A2($author$project$Views$Helpers$Date$hourMinutes, zone, order.e7.fp))
														]))
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('row date')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('col-12')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															A2($author$project$Views$Helpers$Date$monthDayYear, zone, order.e7.fp))
														]))
												]))
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('col-5 pl-2 pr-2')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row name')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-12')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											A2($elm$core$Maybe$withDefault, '', order.e6.gs))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row address')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-12')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											$author$project$Common$Address$fullAddress(
												$author$project$Views$MapAssignment$View$orderDisplayAddress(order)))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-12')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(driverOrderNumberInfo)
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('col-2 text-center')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-12')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$i,
											_List_fromArray(
												[
													A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
													$elm$html$Html$Attributes$class('fab fa-dropbox')
												]),
											_List_Nil)
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-12')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											'x' + $elm$core$String$fromInt(order.gD))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-12')
										]),
									_List_fromArray(
										[removePackage]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('col-1')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-center clickable'),
									$elm$html$Html$Events$onClick(
									$author$project$Views$MapAssignment$Msg$RemoveFromGroupedOrders(order))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
											$elm$html$Html$Attributes$class('fa fa-times')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Views$MapAssignment$Msg$AttachOrder = F2(
	function (a, b) {
		return {$: 33, a: a, b: b};
	});
var $elm$core$Basics$asin = _Basics_asin;
var $elm$core$Basics$cos = _Basics_cos;
var $elm$core$Basics$pi = _Basics_pi;
var $elm$core$Basics$degrees = function (angleInDegrees) {
	return (angleInDegrees * $elm$core$Basics$pi) / 180;
};
var $elm$core$Basics$sin = _Basics_sin;
var $elm$core$Basics$sqrt = _Basics_sqrt;
var $author$project$Data$Map$coordinatesDistanceKm = F2(
	function (pointA, pointB) {
		var rlat2 = $elm$core$Basics$degrees(pointB.f8);
		var rlat1 = $elm$core$Basics$degrees(pointA.f8);
		var earthR = 6371.071;
		var difflon = $elm$core$Basics$degrees(pointB.gb - pointA.gb);
		var difflat = rlat2 - rlat1;
		return (2 * earthR) * $elm$core$Basics$asin(
			$elm$core$Basics$sqrt(
				($elm$core$Basics$sin(difflat / 2) * $elm$core$Basics$sin(difflat / 2)) + ((($elm$core$Basics$cos(rlat1) * $elm$core$Basics$cos(rlat2)) * $elm$core$Basics$sin(difflon / 2)) * $elm$core$Basics$sin(difflon / 2))));
	});
var $author$project$Utils$flip = F3(
	function (_function, argB, argA) {
		return A2(_function, argA, argB);
	});
var $author$project$Views$MapAssignment$Utils$roundFloatString = F2(
	function (digits, string) {
		var _v0 = A2($elm$core$String$split, '.', string);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var a = _v0.a;
			var _v1 = _v0.b;
			var b = _v1.a;
			return $elm$core$String$concat(
				_List_fromArray(
					[
						a,
						'.',
						A2($elm$core$String$left, digits, b)
					]));
		} else {
			return string;
		}
	});
var $author$project$Views$MapAssignment$View$orderRouteDistance = F2(
	function (order, routeContainer) {
		var routeData = routeContainer.g9;
		var maybeDistance = A3($elm$core$Maybe$map2, $author$project$Data$Map$coordinatesDistanceKm, routeData.eH, order.e6.ga);
		var distanceToString = function (distance) {
			return A3(
				$author$project$Utils$flip,
				$elm$core$Basics$append,
				' km',
				A2(
					$author$project$Views$MapAssignment$Utils$roundFloatString,
					2,
					$elm$core$String$fromFloat(distance)));
		};
		return A2(
			$elm$core$Tuple$pair,
			A2($elm$core$Maybe$withDefault, 40075.0, maybeDistance),
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('row no-gutters')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('col-5')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('row no-gutters')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('col-12 name')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(routeData.gs)
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('row no-gutters')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('col-12 address')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												'Distance of the route: ' + A2(
													$elm$core$Maybe$withDefault,
													'Unknown yet',
													A2($elm$core$Maybe$map, distanceToString, maybeDistance)))
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('row no-gutters')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('col-12 address')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												'Packages in route: ' + $elm$core$String$fromInt(
													$elm$core$List$length(routeData.gL)))
											]))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('col-6 text-center')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('rounded p-3 mt-3 dropzone'),
										$elm$html$Html$Events$onClick(
										A2($author$project$Views$MapAssignment$Msg$AttachOrder, routeContainer, order))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Add to this route')
									]))
							]))
					])));
	});
var $author$project$Views$MapAssignment$View$orderSearchingNeabyRouteCard = F2(
	function (routes, order) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('row no-gutters rounded p-2 mb-1 order-detail-card')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('col-1')
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('col-10 align-self-center')
						]),
					A2(
						$elm$core$List$map,
						$elm$core$Tuple$second,
						A2(
							$elm$core$List$sortBy,
							$elm$core$Tuple$first,
							A2(
								$elm$core$List$map,
								$author$project$Views$MapAssignment$View$orderRouteDistance(order),
								routes.g8)))),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('col-1')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-center clickable'),
									$elm$html$Html$Events$onClick(
									$author$project$Views$MapAssignment$Msg$RemoveFromGroupedOrders(order))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
											$elm$html$Html$Attributes$class('fa fa-times')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Views$MapAssignment$View$orderDetailCardList = F4(
	function (zone, groupedOrders, routes, orderSearchingNeabyRoute) {
		var clearAllBtn = ($elm$core$List$length(groupedOrders) > 1) ? _List_fromArray(
			[
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick($author$project$Views$MapAssignment$Msg$ClearGrouped),
						$elm$html$Html$Attributes$class('btn clear-all-btn')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Clear All')
					]))
			]) : _List_Nil;
		var cardsList = A2(
			$elm$core$List$map,
			function (order) {
				return _Utils_eq(
					$elm$core$Maybe$Just(order.fS),
					orderSearchingNeabyRoute) ? A2($author$project$Views$MapAssignment$View$orderSearchingNeabyRouteCard, routes, order) : A3($author$project$Views$MapAssignment$View$orderDetailCard, zone, routes, order);
			},
			groupedOrders);
		return A2($elm$core$List$append, cardsList, clearAllBtn);
	});
var $author$project$Views$MapAssignment$View$newDashboardUrl = function (context) {
	var _v0 = context.fr;
	switch (_v0) {
		case 3:
			return 'https://rms.paack.app';
		case 1:
			return 'https://rms-sta.paack.app';
		case 2:
			return 'https://rms-snd.paack.app';
		default:
			return 'http://localhost:3000';
	}
};
var $author$project$Views$MapAssignment$View$switchToNewDashboardBanner = function (context) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('switch-to-new-dashboard-banner')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('This is beta dashboard. To switch to new routing dashboard please '),
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href(
						$author$project$Views$MapAssignment$View$newDashboardUrl(context)),
						A2($elm$html$Html$Attributes$style, 'margin-right', '8px')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('click here')
					])),
				A2(
				$elm$html$Html$i,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fa fa-info-circle'),
						A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
						$elm$html$Html$Attributes$title('The preloaded orders will not be carried over to another dashboard and will need to be loaded again. If you choose to come back to this dashboard, the data will remain here. Saved routes will be visible in both.')
					]),
				_List_Nil)
			]));
};
var $author$project$Views$MapAssignment$Msg$ToggleHiddenList = {$: 35};
var $author$project$Views$MapAssignment$View$toggleOrdersListView = function (ordersListHidden) {
	var iconClass = ordersListHidden ? 'fas fa-caret-right' : 'fas fa-caret-left';
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('toggle-orders-list'),
				$elm$html$Html$Events$onClick($author$project$Views$MapAssignment$Msg$ToggleHiddenList)
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$i,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
						$elm$html$Html$Attributes$class(iconClass)
					]),
				_List_Nil)
			]));
};
var $author$project$Views$MapAssignment$View$mapAreaView = F2(
	function (context, model) {
		var groupedOrders = model.cg;
		var routes = model.hc;
		var ordersListHidden = model.c2;
		var driversAssignmentList = model.b$;
		var orderSearchingNeabyRoute = model.c$;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('map-container'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('map-small', !ordersListHidden),
							_Utils_Tuple2('map-big', ordersListHidden)
						]))
				]),
			_List_fromArray(
				[
					A3($elm$html$Html$Lazy$lazy2, $author$project$Views$MapAssignment$View$mapView, context, model),
					$author$project$Views$MapAssignment$View$toggleOrdersListView(ordersListHidden),
					$author$project$Views$MapAssignment$View$switchToNewDashboardBanner(context),
					A3($elm$html$Html$Lazy$lazy2, $author$project$Views$MapAssignment$View$driverAssignmentView, routes, driversAssignmentList),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('order-detail-list')
						]),
					A4($author$project$Views$MapAssignment$View$orderDetailCardList, context.em, groupedOrders, routes, orderSearchingNeabyRoute))
				]));
	});
var $author$project$Views$MapAssignment$Msg$LoadOrdersInViewPort = {$: 89};
var $author$project$Views$MapAssignment$Msg$OpenActiveSolutionsDialog = {$: 90};
var $author$project$Views$MapAssignment$Msg$ToggleFilters = {$: 42};
var $author$project$Views$MapAssignment$Msg$ToogleMapSettings = {$: 80};
var $author$project$Views$MapAssignment$Model$polygonFilterApplied = function (polygonFilter) {
	switch (polygonFilter.$) {
		case 0:
			return false;
		case 1:
			return false;
		default:
			return true;
	}
};
var $author$project$Views$Common$Select$someOptionsAreSelected = function (select) {
	return $elm$core$List$length(
		$author$project$Views$Common$Select$getSelectedOptions(select)) > 0;
};
var $author$project$Views$MapAssignment$Model$anyFilterInFiltersMenuApplied = function (filters) {
	return $author$project$Views$Common$Select$someOptionsAreSelected(filters.gY) || ($author$project$Views$Common$Select$someOptionsAreSelected(filters.c6) || ($author$project$Views$Common$Select$someOptionsAreSelected(filters.b_) || ($author$project$Views$Common$Select$someOptionsAreSelected(filters.g6) || ($author$project$Views$Common$Select$someOptionsAreSelected(filters.dV) || ($author$project$Views$Common$Select$someOptionsAreSelected(filters.hk) || ((!$elm$core$String$isEmpty(
		$elm$core$String$trim(filters.gZ))) || ((!$elm$core$String$isEmpty(
		$elm$core$String$trim(filters.bo))) || $author$project$Views$MapAssignment$Model$polygonFilterApplied(filters.c8))))))));
};
var $author$project$Views$MapAssignment$View$isLoadingStatus = function (status) {
	switch (status.$) {
		case 0:
			return false;
		case 1:
			return true;
		case 2:
			return true;
		default:
			return false;
	}
};
var $author$project$Views$MapAssignment$ViewHelpers$genericRoundedBtn = F5(
	function (btnType, icon, msg, isDisabled, title) {
		return A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$disabled(isDisabled),
					$elm$html$Html$Attributes$class('btn rounded-circle p-3 mx-1 rounded-btn btn-' + btnType),
					$elm$html$Html$Events$onClick(msg),
					$elm$html$Html$Attributes$title(title)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
							$elm$html$Html$Attributes$class('map-control-icon ' + icon)
						]),
					_List_Nil)
				]));
	});
var $author$project$Views$MapAssignment$ViewHelpers$roundedBtn = F4(
	function (icon, msg, isDisabled, title) {
		return A5($author$project$Views$MapAssignment$ViewHelpers$genericRoundedBtn, 'secondary', icon, msg, isDisabled, title);
	});
var $author$project$Views$MapAssignment$ViewHelpers$successRoundedBtn = F4(
	function (icon, msg, isDisabled, title) {
		return A5($author$project$Views$MapAssignment$ViewHelpers$genericRoundedBtn, 'success', icon, msg, isDisabled, title);
	});
var $author$project$Views$Common$TimeRangeDropdown$buildTimeRangeText = F2(
	function (zone, _v0) {
		var start = _v0.a;
		var end = _v0.b;
		return $elm$core$String$fromInt(
			A2($elm$time$Time$toHour, zone, start)) + (':00 - ' + ($elm$core$String$fromInt(
			A2($elm$time$Time$toHour, zone, end)) + ':00'));
	});
var $author$project$Views$Common$Select$ClearSelected = {$: 4};
var $author$project$Views$Common$Select$Search = function (a) {
	return {$: 6, a: a};
};
var $author$project$Views$Common$Select$ToggleOpen = {$: 5};
var $author$project$Views$Common$Select$DeSelectAll = {$: 2};
var $author$project$Views$Common$Select$SelectAll = {$: 3};
var $author$project$Views$Common$Select$allVisibleItemsAreSelected = function (model) {
	return A2(
		$elm$core$List$all,
		function ($) {
			return $.D;
		},
		A2(
			$elm$core$List$filter,
			function ($) {
				return $.A;
			},
			model.b));
};
var $author$project$Views$Common$Select$bulkSelector = function (model) {
	var showingAnyOption = A2(
		$elm$core$List$any,
		function ($) {
			return $.A;
		},
		model.b);
	return showingAnyOption ? A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('item', true),
						_Utils_Tuple2(
						'selected',
						$author$project$Views$Common$Select$allVisibleItemsAreSelected(model))
					])),
				$elm$html$Html$Events$onClick(
				$author$project$Views$Common$Select$allVisibleItemsAreSelected(model) ? $author$project$Views$Common$Select$DeSelectAll : $author$project$Views$Common$Select$SelectAll)
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('font-weight-bold')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Select All')
					])),
				A2(
				$elm$html$Html$input,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$type_('checkbox'),
						$elm$html$Html$Attributes$checked(
						$author$project$Views$Common$Select$allVisibleItemsAreSelected(model))
					]),
				_List_Nil)
			])) : $elm$html$Html$text('');
};
var $author$project$Views$Common$Select$DeSelect = function (a) {
	return {$: 1, a: a};
};
var $author$project$Views$Common$Select$Select = function (a) {
	return {$: 0, a: a};
};
var $author$project$Views$Common$Select$checkbox = F2(
	function (labelFn, _v0) {
		var selected = _v0.D;
		var value = _v0.y;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('selected', selected),
							_Utils_Tuple2('item', true)
						])),
					$elm$html$Html$Events$onClick(
					selected ? $author$project$Views$Common$Select$DeSelect(value) : $author$project$Views$Common$Select$Select(value))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					labelFn(value)),
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$type_('checkbox'),
							$elm$html$Html$Attributes$checked(selected)
						]),
					_List_Nil)
				]));
	});
var $author$project$Views$Common$Select$view = F3(
	function (model, placeholderStr, labelFn) {
		var closedView = function () {
			var placeholder = function () {
				var selectedOptions = A2(
					$elm$core$List$filter,
					function (_v4) {
						var selected = _v4.D;
						return selected;
					},
					model.b);
				return $elm$core$List$isEmpty(selectedOptions) ? placeholderStr : (($elm$core$List$length(selectedOptions) === 1) ? A2(
					$elm$core$Maybe$withDefault,
					'Selected',
					A2(
						$elm$core$Maybe$map,
						function (_v3) {
							var value = _v3.y;
							return labelFn(value);
						},
						$elm$core$List$head(selectedOptions))) : ($elm$core$String$fromInt(
					$elm$core$List$length(selectedOptions)) + ' selected'));
			}();
			var deselectAllBtn = function () {
				var _v1 = A2(
					$elm$core$List$filter,
					function (_v2) {
						var selected = _v2.D;
						return selected;
					},
					model.b);
				if (!_v1.b) {
					return $elm$html$Html$text('');
				} else {
					return A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('button'),
								$elm$html$Html$Attributes$class('md-chip-remove'),
								$author$project$HtmlExtra$clickWithoutPropagation($author$project$Views$Common$Select$ClearSelected)
							]),
						_List_Nil);
				}
			}();
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('md-chip', true),
								_Utils_Tuple2('open', model.T)
							])),
						$author$project$HtmlExtra$clickWithoutPropagation($author$project$Views$Common$Select$ToggleOpen)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('placeholder')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(placeholder)
							])),
						deselectAllBtn
					]));
		}();
		var htmlView = function () {
			if (!model.T) {
				return _List_fromArray(
					[closedView]);
			} else {
				var optionsView = function () {
					var allItems = A2(
						$elm$core$List$append,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('search-container')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$input,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$type_('text'),
												$elm$html$Html$Attributes$id('select'),
												$elm$html$Html$Events$onInput($author$project$Views$Common$Select$Search)
											]),
										_List_Nil)
									])),
								$author$project$Views$Common$Select$bulkSelector(model)
							]),
						A2(
							$elm$core$List$map,
							function (option) {
								return A2($author$project$Views$Common$Select$checkbox, labelFn, option);
							},
							A2(
								$elm$core$List$filter,
								function (_v0) {
									var show = _v0.A;
									return show;
								},
								model.b)));
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('options-list')
							]),
						allItems);
				}();
				return _List_fromArray(
					[closedView, optionsView]);
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('dropdown')
				]),
			htmlView);
	});
var $author$project$Views$Common$TimeRangeDropdown$view = F2(
	function (zone, model) {
		return A3(
			$author$project$Views$Common$Select$view,
			model,
			'Select TimeRange',
			$author$project$Views$Common$TimeRangeDropdown$buildTimeRangeText(zone));
	});
var $author$project$Views$MapAssignment$Msg$DisableDrawing = {$: 88};
var $author$project$Views$MapAssignment$Msg$EnableDrawing = {$: 87};
var $author$project$Views$MapAssignment$ViewHelpers$dangerRoundedBtn = F4(
	function (icon, msg, isDisabled, title) {
		return A5($author$project$Views$MapAssignment$ViewHelpers$genericRoundedBtn, 'danger', icon, msg, isDisabled, title);
	});
var $author$project$Views$MapAssignment$View$viewDrawAreaBtn = F2(
	function (maps, selectedRouteContainer) {
		return maps.fY ? A4($author$project$Views$MapAssignment$ViewHelpers$dangerRoundedBtn, 'fa fa-times', $author$project$Views$MapAssignment$Msg$DisableDrawing, false, 'Stop drawing area') : A4(
			$author$project$Views$MapAssignment$ViewHelpers$roundedBtn,
			'fas fa-draw-polygon',
			$author$project$Views$MapAssignment$Msg$EnableDrawing,
			_Utils_eq(selectedRouteContainer, $elm$core$Maybe$Nothing),
			'Start drawing area');
	});
var $author$project$Views$MapAssignment$View$mapControlsView = F2(
	function (zone, _v0) {
		var upcomingOrdersLoadingStatus = _v0.hS;
		var filters = _v0.fG;
		var maps = _v0.gh;
		var routes = _v0.hc;
		var isLoadingOrders = $author$project$Views$MapAssignment$View$isLoadingStatus(upcomingOrdersLoadingStatus);
		var loadOrdersButtonClass = A3($author$project$Utils$ifThenElse, isLoadingOrders, 'fas fa-circle-notch fa-spin', 'fas fa-map-marker-alt');
		var filterButton = $author$project$Views$MapAssignment$Model$anyFilterInFiltersMenuApplied(filters) ? $author$project$Views$MapAssignment$ViewHelpers$successRoundedBtn : $author$project$Views$MapAssignment$ViewHelpers$roundedBtn;
		var _v1 = routes;
		var autoGenerateRouteState = _v1.bz;
		var selectedRouteContainer = _v1.bd;
		var routesLoadingStatus = _v1.bc;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('map-controls')
				]),
			_List_fromArray(
				[
					A4(
					$author$project$Views$MapAssignment$ViewHelpers$roundedBtn,
					'fas fa-location-arrow',
					$author$project$Views$MapAssignment$Msg$OpenActiveSolutionsDialog,
					(autoGenerateRouteState === 2) || $author$project$Views$MapAssignment$View$isLoadingStatus(routesLoadingStatus),
					'Automatic route generation'),
					A2(
					$author$project$HtmlExtra$when,
					autoGenerateRouteState === 2,
					A4($author$project$Views$MapAssignment$ViewHelpers$successRoundedBtn, 'fas fa-route', $author$project$Views$MapAssignment$Msg$OpenActiveSolutionsDialog, !autoGenerateRouteState, 'Solutions')),
					A4($author$project$Views$MapAssignment$ViewHelpers$roundedBtn, loadOrdersButtonClass, $author$project$Views$MapAssignment$Msg$LoadOrdersInViewPort, isLoadingOrders, 'Load orders inside visible map'),
					A2($author$project$Views$MapAssignment$View$viewDrawAreaBtn, maps, selectedRouteContainer),
					A4(filterButton, 'fa fa-filter', $author$project$Views$MapAssignment$Msg$ToggleFilters, false, 'Filter'),
					A4($author$project$Views$MapAssignment$ViewHelpers$roundedBtn, 'fa fa-cog', $author$project$Views$MapAssignment$Msg$ToogleMapSettings, false, 'Settings'),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('timerange')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$map,
							$author$project$Views$MapAssignment$Msg$TimeRangeMsg,
							A2($author$project$Views$Common$TimeRangeDropdown$view, zone, filters.bm))
						]))
				]));
	});
var $author$project$Views$MapAssignment$Msg$AddAnotherPolygonToFilterbyPolygons = {$: 50};
var $author$project$Views$MapAssignment$Msg$CancelFilterByPolygons = {$: 49};
var $author$project$Views$MapAssignment$Msg$FilterByVolume = function (a) {
	return {$: 45, a: a};
};
var $author$project$Views$MapAssignment$Msg$FinishFilterByPolygons = {$: 48};
var $author$project$Views$MapAssignment$Msg$PostalCodeList = 1;
var $author$project$Views$MapAssignment$Msg$PostalCodeSearchClicked = function (a) {
	return {$: 46, a: a};
};
var $author$project$Views$MapAssignment$Msg$StartFilterByPolygons = {$: 47};
var $elm$html$Html$h4 = _VirtualDom_node('h4');
var $author$project$Views$MapAssignment$Filters$radio = F3(
	function (msg, title, isChecked) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('align-middle'),
							$elm$html$Html$Attributes$type_('radio'),
							$elm$html$Html$Attributes$name('postal-code'),
							$elm$html$Html$Events$onClick(msg),
							$elm$html$Html$Attributes$checked(isChecked)
						]),
					_List_Nil),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('px-1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						]))
				]));
	});
var $author$project$Views$Common$AccuracyQualityDropdown$view = function (model) {
	return A3($author$project$Views$Common$Select$view, model, 'Location Accuracy', $author$project$Data$Address$accuracyQualityToString);
};
var $author$project$Views$Common$DriversDropdown$view = function (model) {
	return A3(
		$author$project$Views$Common$Select$view,
		model,
		'Drivers',
		function ($) {
			return $.fJ;
		});
};
var $author$project$Views$Common$PackagesDropdown$view = function (model) {
	return A3($author$project$Views$Common$Select$view, model, 'Packages', $elm$core$String$fromInt);
};
var $author$project$Views$Common$PostalCodeDropdown$view = function (model) {
	return A3($author$project$Views$Common$Select$view, model, 'Postal Codes', $elm$core$Basics$identity);
};
var $author$project$Views$MapAssignment$Msg$SearchPostalCodes = {$: 54};
var $author$project$Views$MapAssignment$Msg$SetPostalCodes = function (a) {
	return {$: 53, a: a};
};
var $elm$html$Html$Attributes$rows = function (n) {
	return A2(
		_VirtualDom_attribute,
		'rows',
		$elm$core$String$fromInt(n));
};
var $elm$html$Html$textarea = _VirtualDom_node('textarea');
var $author$project$Views$Common$PostalCodeList$view = function (postalCodes) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$textarea,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$rows(7),
						$elm$html$Html$Events$onInput($author$project$Views$MapAssignment$Msg$SetPostalCodes),
						$elm$html$Html$Attributes$value(postalCodes)
					]),
				_List_Nil),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('btn btn-sm btn-primary d-block'),
						$elm$html$Html$Events$onClick($author$project$Views$MapAssignment$Msg$SearchPostalCodes)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Search Postal Codes')
					]))
			]));
};
var $author$project$Views$Common$RetailersDropdown$view = function (model) {
	return A3(
		$author$project$Views$Common$Select$view,
		model,
		'Retailers',
		function ($) {
			return $.gs;
		});
};
var $author$project$Views$Common$ServiceTypesDropdown$view = function (model) {
	return A3($author$project$Views$Common$Select$view, model, 'Service Types', $elm$core$Basics$identity);
};
var $author$project$Views$Common$StoresDropdown$view = function (model) {
	return A3(
		$author$project$Views$Common$Select$view,
		model,
		'Stores',
		function ($) {
			return $.gs;
		});
};
var $author$project$Views$MapAssignment$Filters$view = function (_v0) {
	var filters = _v0.fG;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('filters-section')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('header-row d-flex justify-content-between')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h4,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Filters')
									]))
							])),
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('btn btn-sm btn-primary float-right'),
								$elm$html$Html$Events$onClick($author$project$Views$MapAssignment$Msg$ToggleFilters)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Close')
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('select-filters d-flex flex-wrap justify-content-between')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('select-filter')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$map,
								$author$project$Views$MapAssignment$Msg$PackagesMsg,
								$author$project$Views$Common$PackagesDropdown$view(filters.c6))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('select-filter')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$map,
								$author$project$Views$MapAssignment$Msg$DriversMsg,
								$author$project$Views$Common$DriversDropdown$view(filters.b_))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('select-filter')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$map,
								$author$project$Views$MapAssignment$Msg$RetailersMsg,
								$author$project$Views$Common$RetailersDropdown$view(filters.g6))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('select-filter')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$map,
								$author$project$Views$MapAssignment$Msg$StoresMsg,
								$author$project$Views$Common$StoresDropdown$view(filters.dV))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('select-filter')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$map,
								$author$project$Views$MapAssignment$Msg$ServiceTypeMsg,
								$author$project$Views$Common$ServiceTypesDropdown$view(filters.hk))
							])),
						function () {
						var _v1 = filters.aI;
						if (!_v1) {
							return A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('select-filter')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$map,
										$author$project$Views$MapAssignment$Msg$PostalCodeMsg,
										$author$project$Views$Common$PostalCodeDropdown$view(filters.gY))
									]));
						} else {
							return $elm$html$Html$text('');
						}
					}(),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('select-filter')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$map,
								$author$project$Views$MapAssignment$Msg$AccuracyQualityMsg,
								$author$project$Views$Common$AccuracyQualityDropdown$view(filters.fL))
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('d-flex justify-content-between')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'max-width', '50%')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$label,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Capacity')
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$input,
										_List_fromArray(
											[
												$elm$html$Html$Events$onInput($author$project$Views$MapAssignment$Msg$FilterByVolume),
												$elm$html$Html$Attributes$placeholder('Enter (<,>,=) litres'),
												$elm$html$Html$Attributes$value(filters.bo)
											]),
										_List_Nil)
									])),
								A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_Nil,
										_List_fromArray(
											[
												function () {
												var _v2 = filters.c8;
												if (_v2.$ === 1) {
													var isDrawing = _v2.a;
													var ordersInside = _v2.b;
													return A2(
														$elm$html$Html$div,
														_List_Nil,
														_List_fromArray(
															[
																A2(
																$elm$html$Html$button,
																isDrawing ? _List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('my-2 btn btn-sm btn-secondary disabled')
																	]) : _List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('my-2 btn btn-sm btn-primary'),
																		$elm$html$Html$Events$onClick($author$project$Views$MapAssignment$Msg$AddAnotherPolygonToFilterbyPolygons)
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('Add another polygon')
																	])),
																A2(
																$elm$html$Html$button,
																($elm$core$List$length(ordersInside) > 0) ? _List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('my-1 btn btn-sm btn-primary'),
																		$elm$html$Html$Events$onClick($author$project$Views$MapAssignment$Msg$FinishFilterByPolygons)
																	]) : _List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('my-1 btn btn-sm btn-secondary disabled')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('Apply selection')
																	])),
																A2(
																$elm$html$Html$button,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('my-1 btn btn-sm btn-link'),
																		$elm$html$Html$Events$onClick($author$project$Views$MapAssignment$Msg$CancelFilterByPolygons)
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('Cancel')
																	]))
															]));
												} else {
													return A2(
														$elm$html$Html$button,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('btn btn-sm btn-primary my-3'),
																$elm$html$Html$Events$onClick($author$project$Views$MapAssignment$Msg$StartFilterByPolygons)
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('Draw Polygon')
															]));
												}
											}()
											])),
										function () {
										var _v3 = filters.c8;
										if (_v3.$ === 1) {
											var ordersInside = _v3.b;
											return A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('polygon-filter-stats')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_Nil,
														_List_fromArray(
															[
																A2(
																$elm$html$Html$span,
																_List_Nil,
																_List_fromArray(
																	[
																		$elm$html$Html$text('Orders:')
																	])),
																A2(
																$elm$html$Html$span,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('polygon-filter-stats-value')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text(
																		$elm$core$String$fromInt(
																			$elm$core$List$length(ordersInside)))
																	]))
															])),
														A2(
														$elm$html$Html$div,
														_List_Nil,
														_List_fromArray(
															[
																A2(
																$elm$html$Html$span,
																_List_Nil,
																_List_fromArray(
																	[
																		$elm$html$Html$text('Items:')
																	])),
																A2(
																$elm$html$Html$span,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('polygon-filter-stats-value')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text(
																		$elm$core$String$fromInt(
																			$author$project$Views$MapAssignment$RouteContainer$routeItems(ordersInside)))
																	]))
															]))
													]));
										} else {
											return $elm$html$Html$text('');
										}
									}()
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A3(
								$author$project$Views$MapAssignment$Filters$radio,
								$author$project$Views$MapAssignment$Msg$PostalCodeSearchClicked(0),
								'Selecting Postal Code',
								!filters.aI),
								A3(
								$author$project$Views$MapAssignment$Filters$radio,
								$author$project$Views$MapAssignment$Msg$PostalCodeSearchClicked(1),
								'Entering Postal Codes',
								filters.aI === 1),
								function () {
								var _v4 = filters.aI;
								if (!_v4) {
									return $elm$html$Html$text('');
								} else {
									return $author$project$Views$Common$PostalCodeList$view(filters.gZ);
								}
							}()
							]))
					]))
			]));
};
var $author$project$Views$MapAssignment$View$mapFiltersView = function (model) {
	return A2(
		$author$project$HtmlExtra$when,
		model.fG.cW,
		A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					$author$project$Views$MapAssignment$Filters$view(model)
				])));
};
var $author$project$Views$MapAssignment$Msg$OnAutoRoutingSettingsChanged = function (a) {
	return {$: 100, a: a};
};
var $author$project$Views$MapAssignment$Msg$OnDeliveryServiceTimeChanged = function (a) {
	return {$: 98, a: a};
};
var $author$project$Views$MapAssignment$Msg$OnOriginWarehouseChanged = function (a) {
	return {$: 101, a: a};
};
var $author$project$Views$MapAssignment$Msg$OnPackageVolumeChanged = function (a) {
	return {$: 99, a: a};
};
var $author$project$Views$MapAssignment$Msg$OnStartTimeChanged = function (a) {
	return {$: 102, a: a};
};
var $author$project$Views$MapAssignment$Msg$SaveSettings = {$: 109};
var $author$project$Views$MapAssignment$Msg$StartWorkingTime = function (a) {
	return {$: 0, a: a};
};
var $author$project$Views$MapAssignment$Msg$ToggleNoAddressOrdersTab = {$: 82};
var $author$project$Views$MapAssignment$Msg$Vehicle = function (a) {
	return {$: 2, a: a};
};
var $author$project$Views$MapAssignment$Msg$WorkingHours = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$h2 = _VirtualDom_node('h2');
var $elm$html$Html$h6 = _VirtualDom_node('h6');
var $author$project$Views$MapAssignment$Msg$ToggleIsClustered = {$: 81};
var $author$project$Views$MapAssignment$Msg$OnGridSizeInput = function (a) {
	return {$: 83, a: a};
};
var $author$project$Views$MapAssignment$Msg$OnMinPinToClusterInput = function (a) {
	return {$: 84, a: a};
};
var $author$project$Views$MapAssignment$Settings$ClusterSettings$formView = function (_v0) {
	var gridSize = _v0.fN;
	var minPinsToCluster = _v0.go;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-group form-check')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('offset-1 col-11')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('row')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$label,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('col-6')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Grid Size')
									])),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('range'),
										$elm$html$Html$Attributes$class('col-4 mr-5'),
										$elm$html$Html$Events$onInput($author$project$Views$MapAssignment$Msg$OnGridSizeInput),
										$elm$html$Html$Attributes$value(
										$elm$core$String$fromInt(gridSize)),
										$elm$html$Html$Attributes$min('20'),
										$elm$html$Html$Attributes$max('80'),
										$elm$html$Html$Attributes$step('10')
									]),
								_List_Nil),
								$elm$html$Html$text(
								$elm$core$String$fromInt(gridSize))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('row')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$label,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('col-6')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Mininum quantity of pins nearby to cluster each other.')
									])),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('range'),
										$elm$html$Html$Attributes$class('col-4 mr-5'),
										$elm$html$Html$Events$onInput($author$project$Views$MapAssignment$Msg$OnMinPinToClusterInput),
										$elm$html$Html$Attributes$value(
										$elm$core$String$fromInt(minPinsToCluster)),
										$elm$html$Html$Attributes$min('2'),
										$elm$html$Html$Attributes$max('10'),
										$elm$html$Html$Attributes$step('1')
									]),
								_List_Nil),
								$elm$html$Html$text(
								$elm$core$String$fromInt(minPinsToCluster))
							]))
					]))
			]));
};
var $author$project$Common$ViewSettingItem$viewSettingItem = F2(
	function (title, content) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form-group row align-items-center')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('col-5 col-form-label')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('col-7')
						]),
					content)
				]));
	});
var $author$project$Views$MapAssignment$Settings$ClusterSettings$view = function (clusterSettings) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('row')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('col-12')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$author$project$Common$ViewSettingItem$viewSettingItem,
								'Cluster pins',
								_List_fromArray(
									[
										A2(
										$elm$html$Html$input,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$type_('checkbox'),
												$elm$html$Html$Attributes$checked(
												!_Utils_eq(clusterSettings, $author$project$Views$MapAssignment$Types$NotClustered)),
												$elm$html$Html$Events$onClick($author$project$Views$MapAssignment$Msg$ToggleIsClustered)
											]),
										_List_Nil)
									]))
							])),
						function () {
						if (clusterSettings.$ === 1) {
							var settings = clusterSettings.a;
							return $author$project$Views$MapAssignment$Settings$ClusterSettings$formView(settings);
						} else {
							return $elm$html$Html$text('');
						}
					}()
					]))
			]));
};
var $author$project$Views$MapAssignment$Settings$Settings$view = F2(
	function (_v0, warehouses) {
		var clusterSettings = _v0.eQ;
		var routingSettings = _v0.he;
		var autoRoutingSettings = _v0.aR;
		var enableNoAddressOrdersTab = _v0.fo;
		var isSaving = _v0.f1;
		var errors = _v0.ft;
		var warehouseOptionValue = function (wh) {
			var address = A2(
				$elm$core$Maybe$withDefault,
				'',
				A2($elm$core$Maybe$map, $author$project$Common$Address$fullAddress, wh.er));
			return wh.gs + (' ' + address);
		};
		var warehouseItem = function (wh) {
			var selected = A2(
				$elm$core$Maybe$withDefault,
				false,
				A2(
					$elm$core$Maybe$map,
					function (id) {
						return _Utils_eq(id, wh.fS);
					},
					routingSettings.gN));
			return A2(
				$elm$html$Html$option,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$selected(selected),
						$elm$html$Html$Attributes$value(
						$elm$core$String$fromInt(wh.fS))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						warehouseOptionValue(wh))
					]));
		};
		var warehouseOptions = function () {
			if (warehouses.b) {
				if (!warehouses.b.b) {
					var warehouse = warehouses.a;
					return _List_fromArray(
						[
							A2(
							$elm$html$Html$option,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$selected(true),
									$elm$html$Html$Attributes$value(
									$elm$core$String$fromInt(warehouse.fS))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									warehouseOptionValue(warehouse))
								]))
						]);
				} else {
					return A2(
						$elm$core$List$cons,
						A2(
							$elm$html$Html$option,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Select warehouse')
								])),
						A2($elm$core$List$map, warehouseItem, warehouses));
				}
			} else {
				return _List_Nil;
			}
		}();
		var vehicleItem = function (item) {
			return A2(
				$elm$html$Html$option,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$selected(
						_Utils_eq(autoRoutingSettings.hX, item))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Common$Vehicle$toString(item))
					]));
		};
		var startTime = A2($elm$core$Maybe$withDefault, '', routingSettings.ak);
		var settingExpiredError = function (errorKey) {
			var _v1 = A2($elm$core$Dict$get, errorKey, errors);
			if (!_v1.$) {
				return true;
			} else {
				return false;
			}
		};
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('col-12 settings-form')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('row ')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-8')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h2,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Settings')
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-4')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('btn btn-sm btn-info float-right'),
											$elm$html$Html$Events$onClick($author$project$Views$MapAssignment$Msg$ToogleMapSettings)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Close')
										])),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('btn btn-sm btn-info mr-1 float-right'),
											$elm$html$Html$Attributes$disabled(
											isSaving || ($elm$core$Dict$size(errors) > 0)),
											$elm$html$Html$Events$onClick($author$project$Views$MapAssignment$Msg$SaveSettings)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Save Settings')
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('row')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-12')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('form-group')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$ul,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('list-group')
												]),
											A2(
												$elm$core$List$map,
												function (error) {
													return A2(
														$elm$html$Html$li,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('list-group-item list-group-item-danger')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text(error)
															]));
												},
												$elm$core$Dict$values(errors)))
										])),
									$elm$html$Html$text('')
								]))
						])),
					$author$project$Views$MapAssignment$Settings$ClusterSettings$view(clusterSettings),
					A2(
					$author$project$Common$ViewSettingItem$viewSettingItem,
					'Enable \'Orders Without Address\' Tab',
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('form-check'),
									$elm$html$Html$Attributes$type_('checkbox'),
									$elm$html$Html$Attributes$checked(enableNoAddressOrdersTab),
									$elm$html$Html$Events$onClick($author$project$Views$MapAssignment$Msg$ToggleNoAddressOrdersTab)
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('row col-5 section-form-title')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h6,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Manual routing parameters')
								]))
						])),
					A2(
					$author$project$Common$ViewSettingItem$viewSettingItem,
					'Warehouse for new routes',
					_List_fromArray(
						[
							A2(
							$elm$html$Html$select,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('form-control', true),
											_Utils_Tuple2(
											'border-danger',
											settingExpiredError($author$project$Config$warehouseErrorKeyManualRouting))
										])),
									$elm$html$Html$Events$onInput($author$project$Views$MapAssignment$Msg$OnOriginWarehouseChanged)
								]),
							warehouseOptions),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('invalid-feedback', true),
											_Utils_Tuple2(
											'd-block',
											settingExpiredError($author$project$Config$warehouseErrorKeyManualRouting))
										]))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Please, select a warehouse')
								]))
						])),
					A2(
					$author$project$Common$ViewSettingItem$viewSettingItem,
					'Start time for new routes',
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('form-control', true),
											_Utils_Tuple2(
											'border-danger',
											settingExpiredError($author$project$Config$startTimeErrorKeyManualRouting))
										])),
									$elm$html$Html$Attributes$type_('time'),
									$elm$html$Html$Events$onInput($author$project$Views$MapAssignment$Msg$OnStartTimeChanged),
									$elm$html$Html$Attributes$value(startTime)
								]),
							_List_Nil),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('invalid-feedback', true),
											_Utils_Tuple2(
											'd-block',
											settingExpiredError($author$project$Config$startTimeErrorKeyManualRouting))
										]))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Set the start time')
								]))
						])),
					A2(
					$author$project$Common$ViewSettingItem$viewSettingItem,
					'Delivery Service Time (minutes)',
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('form-control'),
									$elm$html$Html$Attributes$type_('number'),
									$elm$html$Html$Events$onInput($author$project$Views$MapAssignment$Msg$OnDeliveryServiceTimeChanged),
									$elm$html$Html$Attributes$value(routingSettings.bW)
								]),
							_List_Nil)
						])),
					A2(
					$author$project$Common$ViewSettingItem$viewSettingItem,
					'Default package volume',
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('form-control'),
									$elm$html$Html$Attributes$type_('number'),
									$elm$html$Html$Events$onInput($author$project$Views$MapAssignment$Msg$OnPackageVolumeChanged),
									$elm$html$Html$Attributes$value(routingSettings.c5)
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('row col-5 section-form-title')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h6,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Autorouting execution parameters')
								]))
						])),
					A2(
					$author$project$Common$ViewSettingItem$viewSettingItem,
					'Default Vehicle',
					_List_fromArray(
						[
							A2(
							$elm$html$Html$select,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('form-control'),
									$elm$html$Html$Events$onInput(
									A2($elm$core$Basics$composeL, $author$project$Views$MapAssignment$Msg$OnAutoRoutingSettingsChanged, $author$project$Views$MapAssignment$Msg$Vehicle))
								]),
							A2($elm$core$List$map, vehicleItem, $author$project$Data$Driver$allVehicles))
						])),
					A2(
					$author$project$Common$ViewSettingItem$viewSettingItem,
					'Default Start Working time',
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('form-control'),
									$elm$html$Html$Attributes$type_('time'),
									$elm$html$Html$Events$onInput(
									A2($elm$core$Basics$composeL, $author$project$Views$MapAssignment$Msg$OnAutoRoutingSettingsChanged, $author$project$Views$MapAssignment$Msg$StartWorkingTime)),
									$elm$html$Html$Attributes$value(
									A2($elm$core$Maybe$withDefault, '', autoRoutingSettings.hs))
								]),
							_List_Nil)
						])),
					A2(
					$author$project$Common$ViewSettingItem$viewSettingItem,
					'Default Working Hours',
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('form-control'),
									$elm$html$Html$Attributes$type_('number'),
									$elm$html$Html$Events$onInput(
									A2($elm$core$Basics$composeL, $author$project$Views$MapAssignment$Msg$OnAutoRoutingSettingsChanged, $author$project$Views$MapAssignment$Msg$WorkingHours)),
									$elm$html$Html$Attributes$value(
									$elm$core$String$fromInt(autoRoutingSettings.eg))
								]),
							_List_Nil)
						]))
				]));
	});
var $author$project$Views$MapAssignment$View$settingsView = F2(
	function (settings, warehouses) {
		return A2(
			$author$project$HtmlExtra$when,
			settings.T,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('row no-gutters w-50 p-3 rounded-bottom settings-section')
					]),
				_List_fromArray(
					[
						A2($author$project$Views$MapAssignment$Settings$Settings$view, settings, warehouses)
					])));
	});
var $author$project$Views$MapAssignment$View$mapButtonsView = F2(
	function (zone, model) {
		var settings = model.hl;
		var warehouses = model.h0;
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2($author$project$Views$MapAssignment$View$mapControlsView, zone, model),
					$author$project$Views$MapAssignment$View$mapFiltersView(model),
					A2($author$project$Views$MapAssignment$View$settingsView, settings, warehouses)
				]));
	});
var $author$project$Views$MapAssignment$Msg$OrderSearch = function (a) {
	return {$: 43, a: a};
};
var $FabienHenon$elm_infinite_scroll$InfiniteScroll$Scroll = function (a) {
	return {$: 0, a: a};
};
var $FabienHenon$elm_infinite_scroll$InfiniteScroll$ScrollPos = F3(
	function (scrollTop, contentHeight, containerHeight) {
		return {bO: containerHeight, bQ: contentHeight, dD: scrollTop};
	});
var $FabienHenon$elm_infinite_scroll$InfiniteScroll$clientHeight = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['target', 'clientHeight']),
			$elm$json$Json$Decode$int),
			A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['target', 'scrollingElement', 'clientHeight']),
			$elm$json$Json$Decode$int)
		]));
var $FabienHenon$elm_infinite_scroll$InfiniteScroll$offsetHeight = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['target', 'offsetHeight']),
			$elm$json$Json$Decode$int),
			A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['target', 'scrollingElement', 'offsetHeight']),
			$elm$json$Json$Decode$int)
		]));
var $FabienHenon$elm_infinite_scroll$InfiniteScroll$decodeScrollPos = A4(
	$elm$json$Json$Decode$map3,
	$FabienHenon$elm_infinite_scroll$InfiniteScroll$ScrollPos,
	$elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$json$Json$Decode$at,
				_List_fromArray(
					['target', 'scrollTop']),
				$elm$json$Json$Decode$float),
				A2(
				$elm$json$Json$Decode$at,
				_List_fromArray(
					['target', 'scrollingElement', 'scrollTop']),
				$elm$json$Json$Decode$float)
			])),
	$elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$json$Json$Decode$at,
				_List_fromArray(
					['target', 'scrollHeight']),
				$elm$json$Json$Decode$int),
				A2(
				$elm$json$Json$Decode$at,
				_List_fromArray(
					['target', 'scrollingElement', 'scrollHeight']),
				$elm$json$Json$Decode$int)
			])),
	A3($elm$json$Json$Decode$map2, $elm$core$Basics$max, $FabienHenon$elm_infinite_scroll$InfiniteScroll$offsetHeight, $FabienHenon$elm_infinite_scroll$InfiniteScroll$clientHeight));
var $elm$virtual_dom$VirtualDom$mapAttribute = _VirtualDom_mapAttribute;
var $elm$html$Html$Attributes$map = $elm$virtual_dom$VirtualDom$mapAttribute;
var $FabienHenon$elm_infinite_scroll$InfiniteScroll$infiniteScroll = function (mapper) {
	return A2(
		$elm$html$Html$Attributes$map,
		mapper,
		A2(
			$elm$html$Html$Events$stopPropagationOn,
			'scroll',
			A2(
				$elm$json$Json$Decode$map,
				function (pos) {
					return _Utils_Tuple2(
						$FabienHenon$elm_infinite_scroll$InfiniteScroll$Scroll(pos),
						true);
				},
				$FabienHenon$elm_infinite_scroll$InfiniteScroll$decodeScrollPos)));
};
var $author$project$Views$MapAssignment$View$loadingStatusText = function (status) {
	switch (status.$) {
		case 0:
			return '';
		case 1:
			return 'Fetching...';
		case 2:
			var loaded = status.a.a1;
			var total = status.a.bn;
			return 'Loaded ' + ($elm$core$String$fromInt(loaded) + (' of ' + $elm$core$String$fromInt(total)));
		default:
			return 'Done';
	}
};
var $ryan_haskell$date_format$DateFormat$DayOfMonthFixed = {$: 7};
var $ryan_haskell$date_format$DateFormat$dayOfMonthFixed = $ryan_haskell$date_format$DateFormat$DayOfMonthFixed;
var $ryan_haskell$date_format$DateFormat$MonthFixed = {$: 2};
var $ryan_haskell$date_format$DateFormat$monthFixed = $ryan_haskell$date_format$DateFormat$MonthFixed;
var $ryan_haskell$date_format$DateFormat$YearNumberLastTwo = {$: 15};
var $ryan_haskell$date_format$DateFormat$yearNumberLastTwo = $ryan_haskell$date_format$DateFormat$YearNumberLastTwo;
var $author$project$Views$Helpers$Date$dayMonthShortYear = F2(
	function (zone, time) {
		return A3(
			$ryan_haskell$date_format$DateFormat$format,
			_List_fromArray(
				[
					$ryan_haskell$date_format$DateFormat$dayOfMonthFixed,
					$ryan_haskell$date_format$DateFormat$text('/'),
					$ryan_haskell$date_format$DateFormat$monthFixed,
					$ryan_haskell$date_format$DateFormat$text('/'),
					$ryan_haskell$date_format$DateFormat$yearNumberLastTwo
				]),
			zone,
			time);
	});
var $author$project$Views$Helpers$Date$showToday = F3(
	function (zone, time, deliveryDate) {
		return A3($author$project$Views$Helpers$Date$isSameDay, zone, time, deliveryDate) ? 'Today' : A2($author$project$Views$Helpers$Date$dayMonthShortYear, zone, deliveryDate);
	});
var $author$project$Data$Order$statusToString = function (status) {
	switch (status) {
		case 0:
			return 'Draft';
		case 1:
			return 'Pending';
		case 2:
			return 'Oncourse';
		case 3:
			return 'Pickedup';
		case 4:
			return 'Delivered';
		case 5:
			return 'ReachedPickedup';
		case 6:
			return 'ReachedDelivered';
		case 7:
			return 'Cancelled';
		case 8:
			return 'UnDelivered';
		case 9:
			return 'Delivery Attempt';
		default:
			return 'return_in_progress';
	}
};
var $author$project$Views$Helpers$Date$toTimeRange = F2(
	function (zone, _v0) {
		var start = _v0.hq;
		var end = _v0.fp;
		return A2(
			$elm$core$String$join,
			' - ',
			_List_fromArray(
				[
					A2($author$project$Views$Helpers$Date$hourMinutes, zone, start),
					A2($author$project$Views$Helpers$Date$hourMinutes, zone, end)
				]));
	});
var $author$project$Views$Common$Badge$badge = F2(
	function (colorClass, contents) {
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('paack-badge ' + colorClass)
				]),
			contents);
	});
var $author$project$Views$Common$Badge$withIcon = F2(
	function (icon, colorClass) {
		return A2(
			$author$project$Views$Common$Badge$badge,
			colorClass,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
							$elm$html$Html$Attributes$class(icon)
						]),
					_List_Nil)
				]));
	});
var $author$project$Views$Common$Badge$withText = F2(
	function (label, colorClass) {
		return A2(
			$author$project$Views$Common$Badge$badge,
			colorClass,
			_List_fromArray(
				[
					$elm$html$Html$text(label)
				]));
	});
var $author$project$Views$Common$Badge$withTextAndIcon = F3(
	function (label, icon, colorClass) {
		return A2(
			$author$project$Views$Common$Badge$badge,
			colorClass,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
							$elm$html$Html$Attributes$class(icon)
						]),
					_List_Nil),
					$elm$html$Html$text(label)
				]));
	});
var $author$project$Views$MapAssignment$View$orderCard = F4(
	function (zone, time, selectedRouteContainer, order) {
		var pickupComplete = function (_v1) {
			return true;
		};
		var inSelectedRouteContainer = function (anOrder) {
			if (!selectedRouteContainer.$) {
				var rc = selectedRouteContainer.a;
				return A2(
					$elm$core$List$any,
					function (o) {
						return _Utils_eq(o.fS, anOrder.fS);
					},
					A2(
						$elm$core$List$map,
						function ($) {
							return $.cZ;
						},
						rc.g9.gL));
			} else {
				return false;
			}
		};
		var deliveryDate = A3($author$project$Views$Helpers$Date$showToday, zone, time, order.e7.fp);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('row no-gutters mb-3 p-2 order-box'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'selected',
							inSelectedRouteContainer(order)),
							_Utils_Tuple2(
							$elm$core$String$toLower(
								$author$project$Data$Order$statusToString(order.ht)),
							true)
						])),
					$author$project$Views$MapAssignment$View$clickWithoutPropagation(
					$author$project$Views$MapAssignment$Msg$AddToRouteContainer(
						_List_fromArray(
							[order])))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('col-12')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row no-gutters')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-12 day')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$span,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(deliveryDate)
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row no-gutters mb-1')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-12 text-right justify-content-end order-badges')
										]),
									_List_fromArray(
										[
											A2(
											$author$project$HtmlExtra$when,
											order.gK === 1,
											A2($author$project$Views$Common$Badge$withIcon, 'fa fa-reply', 'reverse-order')),
											A2(
											$author$project$Views$Common$Badge$withText,
											$author$project$Data$Order$statusToString(order.ht),
											''),
											A3(
											$author$project$Views$Common$Badge$withTextAndIcon,
											'x' + $elm$core$String$fromInt(order.gD),
											'fab fa-dropbox',
											''),
											A2(
											$author$project$Views$Common$Badge$withIcon,
											$author$project$Common$Vehicle$vehicleClass(order.hX),
											'')
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row no-gutters mb-2')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-12 time')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											A2($author$project$Views$Helpers$Date$toTimeRange, zone, order.e7))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-12  order-number')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											'#' + $author$project$Views$Helpers$Order$orderNumber(order))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row no-gutters pl-2 info')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-12 mb-1 retailer-address'),
											$elm$html$Html$Attributes$classList(
											_List_fromArray(
												[
													_Utils_Tuple2(
													'active',
													!pickupComplete(order))
												]))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											$author$project$Common$Address$fullAddress(order.gT))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-12 delivery-address'),
											$elm$html$Html$Attributes$classList(
											_List_fromArray(
												[
													_Utils_Tuple2(
													'active',
													pickupComplete(order))
												]))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											$author$project$Common$Address$fullAddress(order.e6))
										]))
								]))
						]))
				]));
	});
var $author$project$Views$MapAssignment$View$ordersView = F2(
	function (_v0, _v1) {
		var zone = _v0.em;
		var time = _v0.d_;
		var routes = _v1.hc;
		var ordersScroll = _v1.c3;
		var upcomingOrdersLoadingStatus = _v1.hS;
		var upcomingOrdersBanner = $author$project$Views$MapAssignment$View$loadingStatusText(upcomingOrdersLoadingStatus);
		var _v2 = routes;
		var ordersBeingProcessed = _v2.c0;
		var selectedRouteContainer = _v2.bd;
		var removeOrdersBeingProcessed = function (orders_) {
			return A2(
				$elm$core$List$filter,
				function (o) {
					return !A2($elm$core$Set$member, o.fS, ordersBeingProcessed);
				},
				orders_);
		};
		var ordersToShow = removeOrdersBeingProcessed(ordersScroll.bP);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('orders-container')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('row no-gutters p-2')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-12 mb-1')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Events$onInput($author$project$Views$MapAssignment$Msg$OrderSearch),
											$elm$html$Html$Attributes$placeholder('Search order')
										]),
									_List_Nil)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('row no-gutters text-success orders-totals')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-12')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Orders: ' + upcomingOrdersBanner)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('row no-gutters p-2 orders-list'),
							$FabienHenon$elm_infinite_scroll$InfiniteScroll$infiniteScroll($author$project$Views$MapAssignment$Msg$OrdersScrollMsg)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-12')
								]),
							A2(
								$elm$core$List$map,
								A3($author$project$Views$MapAssignment$View$orderCard, zone, time, selectedRouteContainer),
								ordersToShow))
						]))
				]));
	});
var $author$project$Views$MapAssignment$Msg$ClickCreateRoute = {$: 9};
var $author$project$Views$MapAssignment$Msg$OpenConfirmationModal = function (a) {
	return {$: 70, a: a};
};
var $author$project$Views$MapAssignment$Msg$OpenForAllRoutes = {$: 1};
var $author$project$Views$MapAssignment$Msg$OpenForSelectedRoutes = function (a) {
	return {$: 2, a: a};
};
var $author$project$Views$MapAssignment$Msg$ReloadRoutes = {$: 2};
var $author$project$Views$MapAssignment$ViewHelpers$genericSquareButton = F5(
	function (btnType, icon, msg, isDisabled, title) {
		return A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$disabled(isDisabled),
					$elm$html$Html$Attributes$class('square-button btn btn-' + btnType),
					$elm$html$Html$Events$onClick(msg),
					$elm$html$Html$Attributes$title(title)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
							$elm$html$Html$Attributes$class(icon)
						]),
					_List_Nil)
				]));
	});
var $author$project$Views$MapAssignment$ViewHelpers$dangerSquareButton = F4(
	function (icon, msg, isDisabled, title) {
		return A5($author$project$Views$MapAssignment$ViewHelpers$genericSquareButton, 'danger', icon, msg, isDisabled, title);
	});
var $author$project$Views$MapAssignment$ViewHelpers$primarySquareButton = F4(
	function (icon, msg, isDisabled, title) {
		return A5($author$project$Views$MapAssignment$ViewHelpers$genericSquareButton, 'primary', icon, msg, isDisabled, title);
	});
var $author$project$Views$MapAssignment$View$controlsRow = function (model) {
	var _v0 = model.hc;
	var routeContainers = _v0.g8;
	var isModifyingRoutes = _v0.cw;
	var routesLoadingStatus = _v0.bc;
	var hideRoutesHandler = function () {
		if (A2(
			$elm$core$List$any,
			function ($) {
				return $.f2;
			},
			routeContainers)) {
			var selectedRoutesCount = $elm$core$List$length(
				A2(
					$elm$core$List$filter,
					function ($) {
						return $.f2;
					},
					routeContainers));
			return $author$project$Views$MapAssignment$Msg$OpenConfirmationModal(
				$author$project$Views$MapAssignment$Msg$OpenForSelectedRoutes(selectedRoutesCount));
		} else {
			return $author$project$Views$MapAssignment$Msg$OpenConfirmationModal($author$project$Views$MapAssignment$Msg$OpenForAllRoutes);
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('routes-controls-row')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h4,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								'Routes (' + ($elm$core$String$fromInt(
									$elm$core$List$length(model.hc.g8)) + ')'))
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('control-buttons')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A4(
								$author$project$Views$MapAssignment$ViewHelpers$primarySquareButton,
								'fa fa-plus',
								$author$project$Views$MapAssignment$Msg$ClickCreateRoute,
								$author$project$Views$MapAssignment$View$isLoadingStatus(routesLoadingStatus),
								'Create manual route')
							])),
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A4(
								$author$project$Views$MapAssignment$ViewHelpers$primarySquareButton,
								'fa fa-undo fa-flip-horizontal',
								$author$project$Views$MapAssignment$Msg$ReloadRoutes,
								$author$project$Views$MapAssignment$View$isLoadingStatus(routesLoadingStatus),
								'Reload routes')
							])),
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A4($author$project$Views$MapAssignment$ViewHelpers$dangerSquareButton, 'fa fa-eye-slash', hideRoutesHandler, isModifyingRoutes, 'Hide routes')
							]))
					]))
			]));
};
var $author$project$Views$MapAssignment$View$fetchingRoutesText = function (routesLoadingStatus) {
	return A2(
		$author$project$HtmlExtra$when,
		$author$project$Views$MapAssignment$View$isLoadingStatus(routesLoadingStatus),
		A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('my-3 text-center text-white')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$author$project$Views$MapAssignment$View$loadingStatusText(routesLoadingStatus))
						])),
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
							$elm$html$Html$Attributes$class('fas fa fa-circle-notch fa-spin')
						]),
					_List_Nil)
				])));
};
var $author$project$Views$MapAssignment$View$generatingRoutesText = function (autoGenerateRouteState) {
	return A2(
		$author$project$HtmlExtra$when,
		autoGenerateRouteState === 2,
		A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('my-3 text-center text-white')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Generating routes...')
						])),
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
							$elm$html$Html$Attributes$class('fas fa fa-circle-notch fa-spin')
						]),
					_List_Nil)
				])));
};
var $author$project$Views$MapAssignment$Msg$OptimizeAllRoutes = {$: 41};
var $author$project$Views$MapAssignment$View$optimizeAllRoutesBtn = A2(
	$elm$html$Html$button,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('btn btn-secondary btn-block hide'),
			$elm$html$Html$Events$onClick($author$project$Views$MapAssignment$Msg$OptimizeAllRoutes)
		]),
	_List_fromArray(
		[
			$elm$html$Html$text('Optimize all Routes')
		]));
var $author$project$Views$MapAssignment$Msg$SetOrdersStatsStatus = function (a) {
	return {$: 44, a: a};
};
var $myrho$numeral_elm$Numeral$empty = F3(
	function (lang, format_val, value) {
		return {
			G: '',
			ap: '',
			aq: '',
			e3: '',
			c: format_val,
			f7: lang,
			au: 0,
			aB: '',
			U: false,
			V: false,
			aD: false,
			gM: '',
			aG: _Utils_Tuple2('', ''),
			aH: '',
			aJ: '',
			aj: false,
			aP: $myrho$elm_round$Round$toDecimal(value),
			y: value,
			m: ''
		};
	});
var $myrho$numeral_elm$Numeral$regex = function (str) {
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$regex$Regex$never,
		$elm$regex$Regex$fromString(str));
};
var $myrho$numeral_elm$Numeral$emptyReplace = function (str) {
	return A2(
		$elm$regex$Regex$replace,
		$myrho$numeral_elm$Numeral$regex(str),
		function (_v0) {
			return '';
		});
};
var $elm$core$Basics$pow = _Basics_pow;
var $myrho$numeral_elm$Numeral$checkAbbreviation = function (numeral) {
	var _v0 = numeral;
	var language = _v0.f7;
	var format_value = _v0.c;
	var value = _v0.y;
	var abbrB = A2($elm$core$String$contains, 'aB', format_value);
	var abbrK = A2($elm$core$String$contains, 'aK', format_value);
	var abbrM = A2($elm$core$String$contains, 'aM', format_value);
	var abbrT = A2($elm$core$String$contains, 'aT', format_value);
	var abbrForce = !(abbrK || (abbrM || (abbrB || abbrT)));
	var absValue = $elm$core$Basics$abs(value);
	var _v1 = A2($elm$core$String$contains, ' a', format_value) ? _Utils_Tuple2(
		' ',
		A2($myrho$numeral_elm$Numeral$emptyReplace, ' a', format_value)) : _Utils_Tuple2(
		'',
		A2($myrho$numeral_elm$Numeral$emptyReplace, 'a', format_value));
	var abbr = _v1.a;
	var format1 = _v1.b;
	return (!A2($elm$core$String$contains, 'a', format_value)) ? numeral : ((((_Utils_cmp(
		absValue,
		A2($elm$core$Basics$pow, 10, 12)) > -1) && abbrForce) || abbrT) ? _Utils_update(
		numeral,
		{
			G: _Utils_ap(abbr, language.en.hO),
			c: format1,
			y: value / A2($elm$core$Basics$pow, 10, 12)
		}) : ((((_Utils_cmp(
		absValue,
		A2($elm$core$Basics$pow, 10, 12)) < 0) && ((_Utils_cmp(
		absValue,
		A2($elm$core$Basics$pow, 10, 9)) > -1) && abbrForce)) || abbrB) ? _Utils_update(
		numeral,
		{
			G: _Utils_ap(abbr, language.en.eB),
			c: format1,
			y: value / A2($elm$core$Basics$pow, 10, 9)
		}) : ((((_Utils_cmp(
		absValue,
		A2($elm$core$Basics$pow, 10, 9)) < 0) && ((_Utils_cmp(
		absValue,
		A2($elm$core$Basics$pow, 10, 6)) > -1) && abbrForce)) || abbrM) ? _Utils_update(
		numeral,
		{
			G: _Utils_ap(abbr, language.en.gn),
			c: format1,
			y: value / A2($elm$core$Basics$pow, 10, 6)
		}) : ((((_Utils_cmp(
		absValue,
		A2($elm$core$Basics$pow, 10, 6)) < 0) && ((_Utils_cmp(
		absValue,
		A2($elm$core$Basics$pow, 10, 3)) > -1) && abbrForce)) || abbrK) ? _Utils_update(
		numeral,
		{
			G: _Utils_ap(abbr, language.en.hF),
			c: format1,
			y: value / A2($elm$core$Basics$pow, 10, 3)
		}) : _Utils_update(
		numeral,
		{G: abbr, c: format1})))));
};
var $myrho$numeral_elm$Numeral$suffixes = $elm$core$Array$fromList(
	_List_fromArray(
		['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']));
var $myrho$numeral_elm$Numeral$checkByte = function (numeral) {
	var _v0 = numeral;
	var format_value = _v0.c;
	var value = _v0.y;
	var suffixIndex1 = function (power) {
		suffixIndex1:
		while (true) {
			var minValue = A2($elm$core$Basics$pow, 1024, power);
			var maxValue = A2($elm$core$Basics$pow, 1024, power + 1);
			if ((_Utils_cmp(value, minValue) > -1) && (_Utils_cmp(value, maxValue) < 0)) {
				return (minValue > 0) ? _Utils_Tuple2(power, value / minValue) : _Utils_Tuple2(power, value);
			} else {
				if (power < 10) {
					var $temp$power = power + 1;
					power = $temp$power;
					continue suffixIndex1;
				} else {
					return _Utils_Tuple2(-1, value);
				}
			}
		}
	};
	var _v1 = suffixIndex1(0);
	var suffixIndex = _v1.a;
	var value1 = _v1.b;
	var suffix = A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Array$get,
			$elm$core$Basics$round(suffixIndex),
			$myrho$numeral_elm$Numeral$suffixes));
	var _v2 = A2($elm$core$String$contains, ' b', format_value) ? _Utils_Tuple2(
		' ',
		A2($myrho$numeral_elm$Numeral$emptyReplace, ' b', format_value)) : _Utils_Tuple2(
		'',
		A2($myrho$numeral_elm$Numeral$emptyReplace, 'b', format_value));
	var bytes = _v2.a;
	var format1 = _v2.b;
	return A2($elm$core$String$contains, 'b', format_value) ? _Utils_update(
		numeral,
		{
			ap: _Utils_ap(bytes, suffix),
			c: format1,
			y: value1
		}) : numeral;
};
var $elm$regex$Regex$find = _Regex_findAtMost(_Regex_infinity);
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $myrho$numeral_elm$Numeral$checkForCustomSuffix = function (numeral) {
	var hasSuffix = $elm$core$List$head(
		A2(
			$elm$regex$Regex$find,
			$myrho$numeral_elm$Numeral$regex('\\[\\D+\\]$'),
			numeral.c));
	if (hasSuffix.$ === 1) {
		return numeral;
	} else {
		var match = hasSuffix.a.gj;
		return _Utils_update(
			numeral,
			{
				aq: A3(
					$elm$regex$Regex$replace,
					$myrho$numeral_elm$Numeral$regex('\\[|\\]'),
					function (_v1) {
						return '';
					},
					match),
				c: A3($elm$core$String$replace, match, '', numeral.c)
			});
	}
};
var $myrho$numeral_elm$Numeral$checkIfNegative = function (numeral) {
	return A2($elm$core$String$contains, '-', numeral.m) ? _Utils_update(
		numeral,
		{
			U: true,
			m: A3(
				$elm$core$String$slice,
				1,
				$elm$core$String$length(numeral.m),
				numeral.m)
		}) : numeral;
};
var $myrho$numeral_elm$Numeral$checkOptionalDec = function (numeral) {
	return A2($elm$core$String$contains, '[.]', numeral.c) ? _Utils_update(
		numeral,
		{
			c: A3(
				$elm$regex$Regex$replace,
				$myrho$numeral_elm$Numeral$regex('\\[\\.\\]'),
				function (_v0) {
					return '.';
				},
				numeral.c),
			aD: true
		}) : numeral;
};
var $myrho$numeral_elm$Numeral$checkOrdinal = function (numeral) {
	var _v0 = numeral;
	var language = _v0.f7;
	var format_value = _v0.c;
	var value = _v0.y;
	var _v1 = A2($elm$core$String$contains, ' o', format_value) ? _Utils_Tuple2(
		' ',
		A2($myrho$numeral_elm$Numeral$emptyReplace, ' o', format_value)) : _Utils_Tuple2(
		'',
		A2($myrho$numeral_elm$Numeral$emptyReplace, 'o', format_value));
	var ord = _v1.a;
	var format1 = _v1.b;
	return A2($elm$core$String$contains, 'o', format_value) ? _Utils_update(
		numeral,
		{
			c: format1,
			gM: _Utils_ap(
				ord,
				numeral.f7.gM(value))
		}) : numeral;
};
var $myrho$numeral_elm$Numeral$checkParensAndSign = function (numeral) {
	return A2($elm$core$String$contains, '(', numeral.c) ? _Utils_update(
		numeral,
		{
			c: A3($elm$core$String$slice, 1, -1, numeral.c),
			V: true,
			aj: false
		}) : (A2($elm$core$String$contains, '+', numeral.c) ? _Utils_update(
		numeral,
		{
			c: A2($myrho$numeral_elm$Numeral$emptyReplace, '\\+', numeral.c),
			V: false,
			aj: true
		}) : numeral);
};
var $myrho$numeral_elm$Numeral$addThousandsDelimiter = F2(
	function (lang, word) {
		return A3(
			$elm$regex$Regex$replace,
			$myrho$numeral_elm$Numeral$regex('(\\d)(?=(\\d{3})+(?!\\d))'),
			function (_v0) {
				var match = _v0.gj;
				return _Utils_ap(match, lang.e5.hG);
			},
			word);
	});
var $myrho$numeral_elm$Numeral$checkThousandsDelimiter = function (numeral) {
	return A2($elm$core$String$contains, ',', numeral.c) ? _Utils_update(
		numeral,
		{
			m: A2($myrho$numeral_elm$Numeral$addThousandsDelimiter, numeral.f7, numeral.m)
		}) : numeral;
};
var $myrho$numeral_elm$Numeral$createFinalString = function (_v0) {
	var parens = _v0.aG;
	var minus = _v0.aB;
	var plus = _v0.aH;
	var word = _v0.m;
	var decimal = _v0.e3;
	var ordinal = _v0.gM;
	var abbreviation = _v0.G;
	var bytes = _v0.ap;
	var customSuffix = _v0.aq;
	return A2(
		$elm$core$String$join,
		'',
		_List_fromArray(
			[parens.a, minus, plus, word, decimal, ordinal, abbreviation, bytes, customSuffix, parens.b]));
};
var $myrho$numeral_elm$Numeral$indexOf = F2(
	function (part, word) {
		return A2(
			$elm$core$Maybe$withDefault,
			-1,
			$elm$core$List$head(
				A2($elm$core$String$indexes, part, word)));
	});
var $myrho$numeral_elm$Numeral$createFinalWord = function (numeral) {
	return (!A2($myrho$numeral_elm$Numeral$indexOf, '.', numeral.c)) ? _Utils_update(
		numeral,
		{m: ''}) : numeral;
};
var $myrho$numeral_elm$Numeral$createParens = function (numeral) {
	return (numeral.V && numeral.U) ? _Utils_update(
		numeral,
		{
			aG: _Utils_Tuple2('(', ')')
		}) : numeral;
};
var $myrho$numeral_elm$Numeral$getPrecision = function (numeral) {
	var splitted = A2($elm$core$String$split, '.', numeral.c);
	var precision = A2(
		$elm$core$Maybe$withDefault,
		'',
		$elm$core$List$head(
			A2($elm$core$List$drop, 1, splitted)));
	var leadingZeros = $elm$core$String$length(
		A2(
			$elm$core$Maybe$withDefault,
			'',
			$elm$core$List$head(splitted)));
	var leadingZeros_ = A2($elm$core$String$contains, ',', numeral.c) ? 0 : leadingZeros;
	return _Utils_update(
		numeral,
		{au: leadingZeros_, aJ: precision});
};
var $myrho$numeral_elm$Numeral$hasMinus = function (numeral) {
	return ((!numeral.V) && numeral.U) ? _Utils_update(
		numeral,
		{aB: '-'}) : numeral;
};
var $myrho$numeral_elm$Numeral$hasPlus = function (numeral) {
	return ((!numeral.U) && numeral.aj) ? _Utils_update(
		numeral,
		{aH: '+'}) : numeral;
};
var $myrho$numeral_elm$Numeral$processDecimal = function (numeral) {
	var d = numeral.e3;
	var result = A2(
		$elm$core$Maybe$withDefault,
		1,
		$elm$core$String$toInt(
			A3(
				$elm$core$String$slice,
				1,
				$elm$core$String$length(d),
				d)));
	return (numeral.aD && (!result)) ? _Utils_update(
		numeral,
		{e3: ''}) : _Utils_update(
		numeral,
		{e3: d});
};
var $myrho$numeral_elm$Numeral$processLeadingZeros = function (numeral) {
	var w = $elm$core$String$length(
		A2(
			$elm$core$Maybe$withDefault,
			'',
			$elm$core$List$head(
				A2($elm$core$String$split, '.', numeral.m))));
	var n = numeral.au - w;
	var prefix = A2($elm$core$String$repeat, n, '0');
	return _Utils_update(
		numeral,
		{
			m: _Utils_ap(prefix, numeral.m)
		});
};
var $myrho$numeral_elm$Numeral$flip = F3(
	function (f, x, y) {
		return A2(f, y, x);
	});
var $myrho$numeral_elm$Numeral$toFixed = F2(
	function (precision, value) {
		var power = A2($elm$core$Basics$pow, 10, precision);
		var pad = function (num) {
			_v0$2:
			while (true) {
				if (num.b) {
					if (num.b.b) {
						if (!num.b.b.b) {
							var x = num.a;
							var _v1 = num.b;
							var y = _v1.a;
							return _List_fromArray(
								[
									x,
									A3($elm$core$String$padRight, precision, '0', y)
								]);
						} else {
							break _v0$2;
						}
					} else {
						var val = num.a;
						return _List_fromArray(
							[
								val,
								A3($elm$core$String$padRight, precision, '0', '')
							]);
					}
				} else {
					break _v0$2;
				}
			}
			var val = num;
			return val;
		};
		return A2(
			$elm$core$String$join,
			'.',
			pad(
				A2(
					$elm$core$String$split,
					'.',
					$myrho$elm_round$Round$toDecimal(
						$elm$core$Basics$round((value * power) + 0.01) / power))));
	});
var $myrho$numeral_elm$Numeral$toFixedWithOptional = F2(
	function (prs, value) {
		_v0$2:
		while (true) {
			if (prs.b) {
				if (prs.b.b) {
					if (!prs.b.b.b) {
						var x = prs.a;
						var _v1 = prs.b;
						var y = _v1.a;
						return A2(
							$myrho$numeral_elm$Numeral$emptyReplace,
							'0{1,' + ($elm$core$String$fromInt(y) + '}$'),
							A2($myrho$numeral_elm$Numeral$toFixed, x + y, value));
					} else {
						break _v0$2;
					}
				} else {
					var x = prs.a;
					return A2($myrho$numeral_elm$Numeral$toFixed, x, value);
				}
			} else {
				break _v0$2;
			}
		}
		return $myrho$elm_round$Round$toDecimal(value);
	});
var $myrho$numeral_elm$Numeral$processPrecision = function (numeral) {
	var _v0 = numeral;
	var language = _v0.f7;
	var format_value = _v0.c;
	var value = _v0.y;
	var precision = _v0.aJ;
	var fst = A2($elm$core$String$contains, '[', precision) ? A2(
		$myrho$numeral_elm$Numeral$flip($myrho$numeral_elm$Numeral$toFixedWithOptional),
		value,
		A2(
			$elm$core$List$take,
			2,
			A2(
				$elm$core$List$map,
				$elm$core$String$length,
				A2(
					$elm$core$String$split,
					'[',
					A2($myrho$numeral_elm$Numeral$emptyReplace, ']', precision))))) : A2(
		$myrho$numeral_elm$Numeral$toFixed,
		$elm$core$String$length(precision),
		value);
	var snd = function () {
		var _v1 = A2($elm$core$String$split, '.', fst);
		if ((_v1.b && _v1.b.b) && (!_v1.b.b.b)) {
			var x = _v1.a;
			var _v2 = _v1.b;
			var y = _v2.a;
			return ($elm$core$String$length(y) > 0) ? _Utils_ap(language.e5.e3, y) : '';
		} else {
			return '';
		}
	}();
	var w = A2(
		$elm$core$Maybe$withDefault,
		'',
		$elm$core$List$head(
			A2($elm$core$String$split, '.', fst)));
	return (precision === '') ? _Utils_update(
		numeral,
		{e3: '', m: w}) : _Utils_update(
		numeral,
		{e3: snd, m: w});
};
var $myrho$numeral_elm$Numeral$processWord = function (numeral) {
	var w = A2(
		$elm$core$Maybe$withDefault,
		'',
		$elm$core$List$head(
			A2($elm$core$String$split, '.', numeral.aP)));
	return _Utils_update(
		numeral,
		{m: w});
};
var $myrho$numeral_elm$Numeral$updateStringValue = function (numeral) {
	return _Utils_update(
		numeral,
		{
			aP: $myrho$elm_round$Round$toDecimal(numeral.y)
		});
};
var $myrho$numeral_elm$Numeral$formatNumber = function (numeral) {
	return $myrho$numeral_elm$Numeral$createFinalString(
		$myrho$numeral_elm$Numeral$hasPlus(
			$myrho$numeral_elm$Numeral$hasMinus(
				$myrho$numeral_elm$Numeral$createParens(
					$myrho$numeral_elm$Numeral$createFinalWord(
						$myrho$numeral_elm$Numeral$checkIfNegative(
							$myrho$numeral_elm$Numeral$checkThousandsDelimiter(
								$myrho$numeral_elm$Numeral$processDecimal(
									$myrho$numeral_elm$Numeral$processLeadingZeros(
										$myrho$numeral_elm$Numeral$processPrecision(
											$myrho$numeral_elm$Numeral$getPrecision(
												$myrho$numeral_elm$Numeral$processWord(
													$myrho$numeral_elm$Numeral$updateStringValue(
														$myrho$numeral_elm$Numeral$checkOptionalDec(
															$myrho$numeral_elm$Numeral$checkOrdinal(
																$myrho$numeral_elm$Numeral$checkByte(
																	$myrho$numeral_elm$Numeral$checkAbbreviation(
																		$myrho$numeral_elm$Numeral$checkForCustomSuffix(
																			$myrho$numeral_elm$Numeral$checkParensAndSign(numeral)))))))))))))))))));
};
var $myrho$numeral_elm$Numeral$formatWithoutCurrency = function (lformat) {
	return A2($elm$core$String$contains, ' $', lformat) ? _Utils_Tuple2(
		' ',
		A2($myrho$numeral_elm$Numeral$emptyReplace, ' \\$', lformat)) : (A2($elm$core$String$contains, '$ ', lformat) ? _Utils_Tuple2(
		' ',
		A2($myrho$numeral_elm$Numeral$emptyReplace, '\\$ ', lformat)) : _Utils_Tuple2(
		'',
		A2($myrho$numeral_elm$Numeral$emptyReplace, '\\$', lformat)));
};
var $myrho$numeral_elm$Numeral$formatCurrency = F4(
	function (lang, lformat, value, strValue) {
		var symbolIndex = A2($myrho$numeral_elm$Numeral$indexOf, '$', lformat);
		var openParenIndex = A2($myrho$numeral_elm$Numeral$indexOf, '(', lformat);
		var minusSignIndex = A2($myrho$numeral_elm$Numeral$indexOf, '-', lformat);
		var currencySymbol = lang.e0.hB;
		var _v0 = $myrho$numeral_elm$Numeral$formatWithoutCurrency(lformat);
		var space = _v0.a;
		var format1 = _v0.b;
		var formatted = $myrho$numeral_elm$Numeral$formatNumber(
			A3($myrho$numeral_elm$Numeral$empty, lang, format1, value));
		return (symbolIndex <= 1) ? ((A2($elm$core$String$contains, '(', formatted) || A2($elm$core$String$contains, '-', formatted)) ? (((_Utils_cmp(symbolIndex, openParenIndex) < 0) || (_Utils_cmp(symbolIndex, minusSignIndex) < 0)) ? A2(
			$elm$core$String$join,
			'',
			_List_fromArray(
				[
					currencySymbol,
					space,
					A2($elm$core$String$contains, '-', formatted) ? '-' : '',
					A2($elm$core$String$contains, '(', formatted) ? '(' : '',
					A3(
					$elm$core$String$slice,
					1,
					$elm$core$String$length(formatted),
					formatted)
				])) : A2(
			$elm$core$String$join,
			'',
			_List_fromArray(
				[
					A2($elm$core$String$contains, '-', formatted) ? '-' : '',
					A2($elm$core$String$contains, '(', formatted) ? '(' : '',
					currencySymbol,
					space,
					A3(
					$elm$core$String$slice,
					1,
					$elm$core$String$length(formatted),
					formatted)
				]))) : _Utils_ap(
			currencySymbol,
			_Utils_ap(space, formatted))) : (A2($elm$core$String$contains, ')', formatted) ? A2(
			$elm$core$String$join,
			'',
			_List_fromArray(
				[
					A3(
					$elm$core$String$slice,
					0,
					$elm$core$String$length(formatted) - 1,
					formatted),
					space,
					currencySymbol,
					')'
				])) : _Utils_ap(
			formatted,
			_Utils_ap(space, currencySymbol)));
	});
var $myrho$numeral_elm$Numeral$formatWithoutPercent = function (lformat) {
	return A2($elm$core$String$contains, ' %', lformat) ? _Utils_Tuple2(
		' ',
		A2($myrho$numeral_elm$Numeral$emptyReplace, ' %', lformat)) : _Utils_Tuple2(
		'',
		A2($myrho$numeral_elm$Numeral$emptyReplace, '%', lformat));
};
var $myrho$numeral_elm$Numeral$formatPercentage = F4(
	function (lang, lformat, value, strValue) {
		var value1 = value * 100;
		var _v0 = $myrho$numeral_elm$Numeral$formatWithoutPercent(lformat);
		var space = _v0.a;
		var format1 = _v0.b;
		var formatted = $myrho$numeral_elm$Numeral$formatNumber(
			A3($myrho$numeral_elm$Numeral$empty, lang, format1, value1));
		return A2($elm$core$String$contains, ')', formatted) ? A2(
			$elm$core$String$join,
			'',
			_List_fromArray(
				[
					A3(
					$elm$core$String$slice,
					0,
					$elm$core$String$length(formatted) - 1,
					formatted),
					space,
					'%',
					')'
				])) : (formatted + (space + '%'));
	});
var $myrho$numeral_elm$Numeral$formatTime = F4(
	function (lang, lformat, value, strValue) {
		var hours = $elm$core$Basics$floor((value / 60) / 60);
		var minutes = $elm$core$Basics$floor((value - ((hours * 60) * 60)) / 60);
		var seconds = $elm$core$Basics$round((value - ((hours * 60) * 60)) - (minutes * 60));
		var hasOneDigit = function (val) {
			return ($elm$core$String$length(val) < 2) ? ('0' + val) : val;
		};
		return A2(
			$elm$core$String$join,
			':',
			_List_fromArray(
				[
					$elm$core$String$fromFloat(hours),
					hasOneDigit(
					$elm$core$String$fromFloat(minutes)),
					hasOneDigit(
					$elm$core$String$fromInt(seconds))
				]));
	});
var $myrho$numeral_elm$Numeral$formatWithLanguage = F3(
	function (lang, lformat, value) {
		return A2($elm$core$String$contains, '$', lformat) ? A4(
			$myrho$numeral_elm$Numeral$formatCurrency,
			lang,
			lformat,
			value,
			$myrho$elm_round$Round$toDecimal(value)) : (A2($elm$core$String$contains, '%', lformat) ? A4(
			$myrho$numeral_elm$Numeral$formatPercentage,
			lang,
			lformat,
			value,
			$myrho$elm_round$Round$toDecimal(value)) : (A2($elm$core$String$contains, ':', lformat) ? A4(
			$myrho$numeral_elm$Numeral$formatTime,
			lang,
			lformat,
			value,
			$myrho$elm_round$Round$toDecimal(value)) : $myrho$numeral_elm$Numeral$formatNumber(
			A3($myrho$numeral_elm$Numeral$empty, lang, lformat, value))));
	});
var $myrho$numeral_elm$Languages$Spanish$spanishOrdinal = function (number) {
	var number1 = $elm$core$Basics$floor(number);
	var b = A2($elm$core$Basics$modBy, 10, number1);
	return ((b === 1) || (b === 3)) ? 'er' : ((b === 2) ? 'do' : (((b === 7) || (!b)) ? 'mo' : ((b === 8) ? 'vo' : ((b === 9) ? 'no' : 'to'))));
};
var $myrho$numeral_elm$Languages$Spanish$lang = {
	en: {eB: 'b', gn: 'mm', hF: 'k', hO: 't'},
	e0: {hB: '€'},
	e5: {e3: ',', hG: '.'},
	gM: $myrho$numeral_elm$Languages$Spanish$spanishOrdinal
};
var $author$project$Views$MapAssignment$Model$packagesCount = function (orders) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, acc) {
				var numOfPackages = _v0.gD;
				return acc + numOfPackages;
			}),
		0,
		orders);
};
var $author$project$Views$MapAssignment$View$showOrderStats = F2(
	function (status, _v0) {
		var filteredCount = _v0.p;
		var totalCount = _v0.s;
		if (!status) {
			return filteredCount;
		} else {
			return totalCount;
		}
	});
var $author$project$Views$MapAssignment$Model$anyFilterApplied = function (filters) {
	return $author$project$Views$MapAssignment$Model$anyFilterInFiltersMenuApplied(filters) || $author$project$Views$Common$Select$someOptionsAreSelected(filters.bm);
};
var $author$project$Views$MapAssignment$View$filterDisabledIcon = A2(
	$elm$svg$Svg$svg,
	_List_fromArray(
		[
			$elm$svg$Svg$Attributes$width('16'),
			$elm$svg$Svg$Attributes$height('16'),
			$elm$svg$Svg$Attributes$fill('none')
		]),
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$fill('#fff'),
					$elm$svg$Svg$Attributes$d('M9.667 8.25 4.083 2.667h8.784c.233 0 .405.105.516.316a.504.504 0 0 1-.066.6L9.667 8.25Zm3.883 6.733-4.217-4.216v1.9a.645.645 0 0 1-.191.475.645.645 0 0 1-.475.191H7.333a.645.645 0 0 1-.475-.191.645.645 0 0 1-.191-.475V8.1l-5.65-5.65.716-.717 12.534 12.534-.717.716Z')
				]),
			_List_Nil)
		]));
var $author$project$Views$MapAssignment$View$filterIcon = A2(
	$elm$svg$Svg$svg,
	_List_fromArray(
		[
			$elm$svg$Svg$Attributes$width('16'),
			$elm$svg$Svg$Attributes$height('16'),
			$elm$svg$Svg$Attributes$fill('none')
		]),
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$fill('#fff'),
					$elm$svg$Svg$Attributes$d('M7.333 13.333a.645.645 0 0 1-.475-.191.645.645 0 0 1-.191-.475v-4L2.683 3.583a.504.504 0 0 1-.066-.6.548.548 0 0 1 .516-.316h9.734c.233 0 .405.105.516.316a.504.504 0 0 1-.066.6L9.333 8.667v4a.645.645 0 0 1-.191.475.645.645 0 0 1-.475.191H7.333Z')
				]),
			_List_Nil)
		]));
var $author$project$Views$MapAssignment$View$toggleOrdersStatsButton = function (model) {
	if ($author$project$Views$MapAssignment$Model$anyFilterApplied(model.fG)) {
		var _v0 = model.c4;
		if (!_v0) {
			return $elm$core$Maybe$Just($author$project$Views$MapAssignment$View$filterIcon);
		} else {
			return $elm$core$Maybe$Just($author$project$Views$MapAssignment$View$filterDisabledIcon);
		}
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Views$MapAssignment$View$ordersStats = F2(
	function (context, model) {
		var ordersStatsStatus = model.c4;
		var filteredOrdersIds = model.fF;
		var titleText = function () {
			var _v2 = model.c4;
			if (!_v2) {
				return 'Hide filtered info';
			} else {
				return 'Show filtered info';
			}
		}();
		var routedOrders = $author$project$Views$MapAssignment$Model$routeOrders(model);
		var routedOrdersForSelectedDate = A2($author$project$Views$MapAssignment$Model$filterByDate, context, routedOrders);
		var routedOrdersCount = $elm$core$Dict$size(routedOrdersForSelectedDate);
		var routedPackagesCount = $author$project$Views$MapAssignment$Model$packagesCount(
			$elm$core$Dict$values(routedOrdersForSelectedDate));
		var unroutedOrders = $author$project$Views$MapAssignment$Utils$filterTimeWindowIssues(
			A2(
				$author$project$Views$MapAssignment$Model$filterByDate,
				context,
				A2(
					$elm$core$Dict$filter,
					F2(
						function (_v1, order) {
							return A2($author$project$Views$MapAssignment$Utils$isUnroutedOrder, routedOrders, order);
						}),
					model.gL)));
		var unroutedLocationsCount = $author$project$Common$Locations$uniqueLocationsCount(
			$elm$core$Dict$values(unroutedOrders));
		var unroutedOrdersCount = $elm$core$Dict$size(unroutedOrders);
		var unroutedPackagesCount = $author$project$Views$MapAssignment$Model$packagesCount(
			$elm$core$Dict$values(unroutedOrders));
		var unroutedRouteItemsCount = $author$project$Views$MapAssignment$RouteContainer$routeItems(
			$elm$core$Dict$values(unroutedOrders));
		var routedLocationsCount = $author$project$Common$Locations$uniqueLocationsCount(
			$elm$core$Dict$values(routedOrdersForSelectedDate));
		var routedItemsCount = $author$project$Views$MapAssignment$RouteContainer$routeItems(
			$elm$core$Dict$values(routedOrdersForSelectedDate));
		var format = A2(
			$elm$core$Basics$composeR,
			$elm$core$Basics$toFloat,
			A2($myrho$numeral_elm$Numeral$formatWithLanguage, $myrho$numeral_elm$Languages$Spanish$lang, '0,0'));
		var filteredUnroutedOrdersCount = $elm$core$List$length(filteredOrdersIds);
		var unroutedOrdersStat = A2(
			$author$project$Views$MapAssignment$View$showOrderStats,
			ordersStatsStatus,
			{p: filteredUnroutedOrdersCount, s: unroutedOrdersCount});
		var unroutedCountColor = (!unroutedOrdersStat) ? A2($elm$html$Html$Attributes$style, 'color', 'inherit') : A2($elm$html$Html$Attributes$style, 'color', '#FCD34D');
		var filteredRoutedOrders = A3($author$project$Views$MapAssignment$Update$filterOrdersWithUnroutedIncluded, context, model, routedOrders);
		var filteredRoutedOrdersCount = $elm$core$Dict$size(filteredRoutedOrders);
		var routedOrdersStat = A2(
			$author$project$Views$MapAssignment$View$showOrderStats,
			ordersStatsStatus,
			{p: filteredRoutedOrdersCount, s: routedOrdersCount});
		var totalOrdersStat = A2(
			$author$project$Views$MapAssignment$View$showOrderStats,
			ordersStatsStatus,
			{p: filteredRoutedOrdersCount + filteredUnroutedOrdersCount, s: routedOrdersCount + unroutedOrdersCount});
		var routedCountColor = (_Utils_eq(routedOrdersStat, totalOrdersStat) && (totalOrdersStat > 0)) ? A2($elm$html$Html$Attributes$style, 'color', '#6EE79E') : A2($elm$html$Html$Attributes$style, 'color', 'inherit');
		var filteredOrders = A2($author$project$Views$MapAssignment$Model$orderIdsToOrders, model.gL, filteredOrdersIds);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('stats-visualization-wrapper')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('stats-visualization-flex')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('headers-column')
								]),
							_List_fromArray(
								[
									function () {
									var _v0 = $author$project$Views$MapAssignment$View$toggleOrdersStatsButton(model);
									if (_v0.$ === 1) {
										return A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('header-element')
												]),
											_List_Nil);
									} else {
										var icon = _v0.a;
										return A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('header-element filter-icon'),
													$elm$html$Html$Attributes$title(titleText),
													$elm$html$Html$Events$onClick(
													$author$project$Views$MapAssignment$Msg$SetOrdersStatsStatus(model.c4))
												]),
											_List_fromArray(
												[icon]));
									}
								}(),
									A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Orders')
										])),
									A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Parcels')
										])),
									A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Items')
										])),
									A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Locations')
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('unrouted-column'),
									unroutedCountColor
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('header-element')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Unrouted')
										])),
									A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											format(unroutedOrdersStat))
										])),
									A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											format(
												A2(
													$author$project$Views$MapAssignment$View$showOrderStats,
													ordersStatsStatus,
													{
														p: $author$project$Views$MapAssignment$Model$packagesCount(filteredOrders),
														s: unroutedPackagesCount
													})))
										])),
									A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											format(
												A2(
													$author$project$Views$MapAssignment$View$showOrderStats,
													model.c4,
													{
														p: $author$project$Views$MapAssignment$RouteContainer$routeItems(filteredOrders),
														s: unroutedRouteItemsCount
													})))
										])),
									A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											format(
												A2(
													$author$project$Views$MapAssignment$View$showOrderStats,
													model.c4,
													{
														p: $author$project$Common$Locations$uniqueLocationsCount(filteredOrders),
														s: unroutedLocationsCount
													})))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('routed-column'),
									routedCountColor
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('header-element')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Routed')
										])),
									A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											format(routedOrdersStat))
										])),
									A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											format(
												A2(
													$author$project$Views$MapAssignment$View$showOrderStats,
													ordersStatsStatus,
													{
														p: $author$project$Views$MapAssignment$Model$packagesCount(
															$elm$core$Dict$values(filteredRoutedOrders)),
														s: routedPackagesCount
													})))
										])),
									A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											format(
												A2(
													$author$project$Views$MapAssignment$View$showOrderStats,
													model.c4,
													{
														p: $author$project$Views$MapAssignment$RouteContainer$routeItems(
															$elm$core$Dict$values(filteredRoutedOrders)),
														s: routedItemsCount
													})))
										])),
									A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											format(
												A2(
													$author$project$Views$MapAssignment$View$showOrderStats,
													model.c4,
													{
														p: $author$project$Common$Locations$uniqueLocationsCount(
															$elm$core$Dict$values(filteredRoutedOrders)),
														s: routedLocationsCount
													})))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('total-column')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('header-element')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Total')
										])),
									A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											format(totalOrdersStat))
										])),
									A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											format(
												A2(
													$author$project$Views$MapAssignment$View$showOrderStats,
													ordersStatsStatus,
													{
														p: $author$project$Views$MapAssignment$Model$packagesCount(filteredOrders) + $author$project$Views$MapAssignment$Model$packagesCount(
															$elm$core$Dict$values(filteredRoutedOrders)),
														s: routedPackagesCount + unroutedPackagesCount
													})))
										])),
									A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											format(
												A2(
													$author$project$Views$MapAssignment$View$showOrderStats,
													model.c4,
													{
														p: $author$project$Views$MapAssignment$RouteContainer$routeItems(
															$elm$core$Dict$values(filteredRoutedOrders)) + $author$project$Views$MapAssignment$RouteContainer$routeItems(filteredOrders),
														s: routedItemsCount + unroutedRouteItemsCount
													})))
										])),
									A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											format(
												A2(
													$author$project$Views$MapAssignment$View$showOrderStats,
													model.c4,
													{
														p: $author$project$Common$Locations$uniqueLocationsCount(
															$elm$core$Dict$values(filteredRoutedOrders)) + $author$project$Common$Locations$uniqueLocationsCount(filteredOrders),
														s: routedLocationsCount + unroutedLocationsCount
													})))
										]))
								]))
						]))
				]));
	});
var $author$project$Views$MapAssignment$Msg$CloseExtraInfoForm = function (a) {
	return {$: 104, a: a};
};
var $author$project$Views$MapAssignment$Msg$DeleteRoute = function (a) {
	return {$: 37, a: a};
};
var $author$project$Views$MapAssignment$Msg$OpenExtraInfoForm = function (a) {
	return {$: 103, a: a};
};
var $author$project$Views$MapAssignment$Msg$SaveRouteName = function (a) {
	return {$: 67, a: a};
};
var $author$project$Views$MapAssignment$Msg$SelectRouteContainer = function (a) {
	return {$: 18, a: a};
};
var $author$project$Views$MapAssignment$Msg$SelectRouteToDelete = function (a) {
	return {$: 77, a: a};
};
var $author$project$Views$MapAssignment$Msg$SetCapacityInRouteContainer = F2(
	function (a, b) {
		return {$: 107, a: a, b: b};
	});
var $author$project$Views$MapAssignment$Msg$SetRouteName = F2(
	function (a, b) {
		return {$: 65, a: a, b: b};
	});
var $author$project$Views$MapAssignment$Msg$SetStartTimeInRouteContainer = F2(
	function (a, b) {
		return {$: 106, a: a, b: b};
	});
var $author$project$Views$MapAssignment$Msg$SetWorkingHoursInRouteContainer = F2(
	function (a, b) {
		return {$: 105, a: a, b: b};
	});
var $author$project$Views$MapAssignment$Msg$ToggleRouteNameEdit = function (a) {
	return {$: 66, a: a};
};
var $author$project$Views$MapAssignment$Msg$ZoomToStartOfRoute = function (a) {
	return {$: 19, a: a};
};
var $author$project$Views$MapAssignment$Msg$OptimizeOrdersMsg = function (a) {
	return {$: 25, a: a};
};
var $author$project$Views$MapAssignment$Msg$ResetRoute = F2(
	function (a, b) {
		return {$: 26, a: a, b: b};
	});
var $author$project$Views$MapAssignment$Msg$SaveOrdersToRoute = function (a) {
	return {$: 21, a: a};
};
var $author$project$Views$MapAssignment$View$optimizeOrderStatus = F3(
	function (_v0, routeContainer, _v1) {
		var jobIdsInProgress = _v0.f4;
		var disableOptimizeButtons = _v1.bZ;
		var routeInProgress = A2(
			$elm$core$List$any,
			function (_v13) {
				var routeId = _v13.du;
				return _Utils_eq(routeId, routeContainer.g9.fS);
			},
			jobIdsInProgress) && ((routeContainer.g9.ht === 2) || (!routeContainer.g9.ht));
		var jobIdIfInProgress = A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.f3;
			},
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (_v12) {
						var routeId = _v12.du;
						return _Utils_eq(routeId, routeContainer.g9.fS);
					},
					jobIdsInProgress)));
		var clickHandler = disableOptimizeButtons ? $elm$html$Html$Events$onClick($author$project$Views$MapAssignment$Msg$NOOP) : $author$project$Views$MapAssignment$View$clickWithoutPropagation(
			$author$project$Views$MapAssignment$Msg$OptimizeOrdersMsg(routeContainer));
		var _v2 = disableOptimizeButtons ? _Utils_Tuple2(
			A2($elm$html$Html$Attributes$style, 'cursor', 'not-allowed'),
			$elm$html$Html$Attributes$title('Wait 15 seconds before another execution can be launched')) : _Utils_Tuple2(
			A2($elm$html$Html$Attributes$style, 'cursor', 'pointer'),
			$elm$html$Html$Attributes$title('Generate route'));
		var cursorStyle = _v2.a;
		var tooltipTitle = _v2.b;
		var _v3 = routeContainer.dA;
		if (_v3.$ === 1) {
			return A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('badge badge-route big-icon')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$i,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
								$elm$html$Html$Attributes$class('fas fa fa-circle-notch fa-spin')
							]),
						_List_Nil)
					]));
		} else {
			if (!_v3.a) {
				return A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('badge badge-route clickable big-icon'),
							$author$project$Views$MapAssignment$View$clickWithoutPropagation(
							$author$project$Views$MapAssignment$Msg$SaveOrdersToRoute(routeContainer))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
									$elm$html$Html$Attributes$class('fa far fa-save'),
									$elm$html$Html$Attributes$title('Save')
								]),
							_List_Nil)
						]));
			} else {
				var _v4 = _Utils_Tuple2(routeContainer.ae, routeContainer.g9.ht);
				switch (_v4.a.$) {
					case 0:
						switch (_v4.b) {
							case 1:
								var _v5 = _v4.a;
								var _v6 = _v4.b;
								return $elm$html$Html$text('');
							case 2:
								var _v7 = _v4.a;
								var _v8 = _v4.b;
								return routeInProgress ? A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('badge badge-route clickable big-icon'),
											A2($elm$html$Html$Attributes$style, 'background-color', '#FF0000'),
											$author$project$Views$MapAssignment$View$clickWithoutPropagation(
											A2($author$project$Views$MapAssignment$Msg$ResetRoute, routeContainer.g9.fS, jobIdIfInProgress))
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$i,
											_List_fromArray(
												[
													A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
													$elm$html$Html$Attributes$class('fas fa fa-times-circle'),
													$elm$html$Html$Attributes$title('Reset route'),
													A2($elm$html$Html$Attributes$style, 'color', '#FFFFFF')
												]),
											_List_Nil)
										])) : $elm$html$Html$text('');
							default:
								var _v9 = _v4.a;
								return A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('badge badge-route big-icon'),
											cursorStyle,
											clickHandler
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$i,
											_List_fromArray(
												[
													A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
													$elm$html$Html$Attributes$class('fas fa fa-magic'),
													tooltipTitle
												]),
											_List_Nil)
										]));
						}
					case 1:
						var _v10 = _v4.a;
						return A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									routeContainer.bw ? A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('badge badge-route clickable big-icon'),
											A2($elm$html$Html$Attributes$style, 'background-color', '#FF0000'),
											$author$project$Views$MapAssignment$View$clickWithoutPropagation(
											A2($author$project$Views$MapAssignment$Msg$ResetRoute, routeContainer.g9.fS, routeContainer.f3))
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$i,
											_List_fromArray(
												[
													A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
													$elm$html$Html$Attributes$class('fas fa fa-times-circle'),
													$elm$html$Html$Attributes$title('Reset route'),
													A2($elm$html$Html$Attributes$style, 'color', '#FFFFFF')
												]),
											_List_Nil)
										])) : $elm$html$Html$text(''),
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('badge badge-route big-icon')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$i,
											_List_fromArray(
												[
													A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
													$elm$html$Html$Attributes$class('fas fa fa-circle-notch fa-spin')
												]),
											_List_Nil)
										]))
								]));
					case 2:
						var _v11 = _v4.a;
						return $elm$html$Html$text('');
					default:
						return A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('badge badge-route big-icon'),
											A2($elm$html$Html$Attributes$style, 'background-color', '#FF0000')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$i,
											_List_fromArray(
												[
													A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
													$elm$html$Html$Attributes$class('fa fa-exclamation')
												]),
											_List_Nil)
										])),
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('badge badge-route big-icon'),
											cursorStyle,
											clickHandler
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$i,
											_List_fromArray(
												[
													A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
													$elm$html$Html$Attributes$class('fas fa fa-magic'),
													tooltipTitle
												]),
											_List_Nil)
										]))
								]));
				}
			}
		}
	});
var $author$project$Common$Package$packagesFromOrders = F2(
	function (orders, packages) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, acc) {
					var id = _v0.fS;
					return _Utils_ap(
						acc,
						A2(
							$elm$core$Maybe$withDefault,
							_List_Nil,
							A2($elm$core$Dict$get, id, packages)));
				}),
			_List_Nil,
			orders);
	});
var $author$project$Views$Helpers$Date$dayMonthWithoutYear = F2(
	function (zone, time) {
		return A3(
			$ryan_haskell$date_format$DateFormat$format,
			_List_fromArray(
				[
					$ryan_haskell$date_format$DateFormat$dayOfMonthFixed,
					$ryan_haskell$date_format$DateFormat$text('/'),
					$ryan_haskell$date_format$DateFormat$monthFixed
				]),
			zone,
			time);
	});
var $author$project$Views$Helpers$Date$dateAndTimeWithoutYear = F2(
	function (zone, time) {
		return A2($author$project$Views$Helpers$Date$dayMonthWithoutYear, zone, time) + (', ' + A2($author$project$Views$Helpers$Date$hourMinutes, zone, time));
	});
var $author$project$HtmlExtra$maybeText = function (maybeVal) {
	if (!maybeVal.$) {
		var val = maybeVal.a;
		return $elm$html$Html$text(val);
	} else {
		return $author$project$HtmlExtra$empty;
	}
};
var $author$project$Views$MapAssignment$View$orderStatusToColor = function (order) {
	var isBeforeOrAfterDeliverySlot = function (timeToCompare) {
		return (_Utils_cmp(
			$elm$time$Time$posixToMillis(timeToCompare),
			$elm$time$Time$posixToMillis(order.e7.fp)) > 0) || (_Utils_cmp(
			$elm$time$Time$posixToMillis(timeToCompare),
			$elm$time$Time$posixToMillis(order.e7.hq)) < 0);
	};
	var _v0 = order.fU;
	if (!_v0.$) {
		var incidentTime = _v0.a;
		return A3(
			$author$project$Utils$ifThenElse,
			isBeforeOrAfterDeliverySlot(incidentTime),
			'red',
			'orange');
	} else {
		return (order.ht === 4) ? 'green' : A3($author$project$Utils$ifThenElse, order.ht === 3, 'blue', 'white');
	}
};
var $author$project$Views$MapAssignment$View$packagesVolume = function (packages) {
	return $elm$core$String$fromInt(
		$elm$core$Basics$round(
			$author$project$Views$MapAssignment$Utils$calculateTotalVolume(packages)));
};
var $author$project$Views$MapAssignment$View$routeCardOrder = F4(
	function (zone, _v0, routePackages, _v1) {
		var position = _v1.gW;
		var order = _v1.cZ;
		var expectedDeliveryTime = _v1.b7;
		var statusBadgeLabel = A2(
			$elm$core$Maybe$withDefault,
			$author$project$Data$Order$statusToString(order.ht),
			A2(
				$elm$core$Maybe$map,
				function (_v4) {
					return 'Attempted';
				},
				order.fU));
		var packageTotalVolume = $author$project$Views$MapAssignment$View$packagesVolume(
			A2(
				$elm$core$List$filter,
				function (_package) {
					return _Utils_eq(_package.gI, order.fS);
				},
				routePackages));
		var hourFormatted = (!expectedDeliveryTime) ? '--' : A2(
			$author$project$Views$Helpers$Date$hourMinutes,
			zone,
			$elm$time$Time$millisToPosix(expectedDeliveryTime * 1000));
		var deliveryOrAttemptedTime = function () {
			var _v2 = _Utils_Tuple2(order.ht, order.fU);
			if (_v2.a === 4) {
				var _v3 = _v2.a;
				return 'Delivered';
			} else {
				if (!_v2.b.$) {
					var incidentTime = _v2.b.a;
					return 'Attempted: ' + A2($author$project$Views$Helpers$Date$dateAndTimeWithoutYear, zone, incidentTime);
				} else {
					return '';
				}
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('row no-gutters rounded p-2 mb-1 driver d-flex')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex-column align-self-center time-order-container px-1')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('d-flex justify-content-center')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$elm$core$String$fromInt(position + 1))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('d-flex justify-content-center')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(hourFormatted)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('d-flex justify-content-center')
								]),
							_List_fromArray(
								[
									A2(
									$author$project$Views$Common$Badge$withText,
									statusBadgeLabel,
									$author$project$Views$MapAssignment$View$orderStatusToColor(order))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex-column info')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('date')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									A2($author$project$Views$Helpers$Date$dayMonthWithoutYear, zone, order.e7.fp) + (', ' + A2($author$project$Views$Helpers$Date$toTimeRange, zone, order.e7)))
								])),
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(deliveryOrAttemptedTime)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('customer-name')
								]),
							_List_fromArray(
								[
									$author$project$HtmlExtra$maybeText(
									(order.gK === 1) ? order.gT.gs : order.e6.gs)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('delivery-address')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Common$Address$fullAddress(
										$author$project$Views$MapAssignment$View$orderDisplayAddress(order)))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('order-number')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Views$Helpers$Order$orderNumber(order))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex-column align-self-center delete-btn clickable'),
							$author$project$Views$MapAssignment$View$clickWithoutPropagation(
							$author$project$Views$MapAssignment$Msg$RemoveFromRouteContainer(
								_List_fromArray(
									[order])))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('d-flex justify-content-center')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
											$elm$html$Html$Attributes$class('fa fa-trash'),
											$elm$html$Html$Attributes$title('Remove order')
										]),
									_List_Nil)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex-column align-self-center package-info')
						]),
					_List_fromArray(
						[
							A2(
							$author$project$HtmlExtra$when,
							order.gK === 1,
							A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('d-flex justify-content-center reverse-order')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$i,
										_List_fromArray(
											[
												A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
												$elm$html$Html$Attributes$class('fa fa-reply')
											]),
										_List_Nil)
									]))),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('d-flex justify-content-center')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$elm$core$String$fromInt(order.gD)),
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
											$elm$html$Html$Attributes$class('fab fa-dropbox ml-1 align-self-center')
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('d-flex justify-content-center')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(packageTotalVolume + ' L')
								]))
						]))
				]));
	});
var $author$project$Views$MapAssignment$Msg$SetCancelled = function (a) {
	return {$: 30, a: a};
};
var $author$project$Views$MapAssignment$Msg$SetChanging = function (a) {
	return {$: 29, a: a};
};
var $author$project$Views$MapAssignment$View$routeDriver = function (routeContainer) {
	var _v0 = routeContainer.ha;
	switch (_v0.$) {
		case 0:
			return A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('badge badge-route clickable big-icon'),
						$author$project$Views$MapAssignment$View$clickWithoutPropagation(
						$author$project$Views$MapAssignment$Msg$SetChanging(routeContainer))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$i,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
								$elm$html$Html$Attributes$class('fa fa-user-plus'),
								$elm$html$Html$Attributes$title('Assign predriver')
							]),
						_List_Nil)
					]));
		case 1:
			return A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('badge badge-route clickable big-icon'),
						$author$project$Views$MapAssignment$View$clickWithoutPropagation(
						$author$project$Views$MapAssignment$Msg$SetChanging(routeContainer))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$i,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
								$elm$html$Html$Attributes$class('fa fa-car'),
								$elm$html$Html$Attributes$title('Change predriver')
							]),
						_List_Nil)
					]));
		default:
			return A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('badge badge-route clickable big-icon'),
						$author$project$Views$MapAssignment$View$clickWithoutPropagation(
						$author$project$Views$MapAssignment$Msg$SetCancelled(routeContainer))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$i,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
								$elm$html$Html$Attributes$class('fa fa-times'),
								$elm$html$Html$Attributes$title('Unassign predriver')
							]),
						_List_Nil)
					]));
	}
};
var $author$project$Views$MapAssignment$View$kpisView = function (maybeKpi) {
	if (!maybeKpi.$) {
		var kpi = maybeKpi.a;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('route-kpis-main-screen')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('route-kpis-main-screen-col')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('font-weight-bold')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(' Productivity: ')
										])),
									$elm$html$Html$text(
									A2($myrho$elm_round$Round$round, 1, kpi.dd))
								])),
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('font-weight-bold')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Distance: ')
										])),
									$elm$html$Html$text(
									A2($myrho$elm_round$Round$round, 1, kpi.ab) + ' km')
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('route-kpis-main-screen-col')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('font-weight-bold')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Working time: ')
										])),
									$elm$html$Html$text(
									A2($myrho$elm_round$Round$round, 1, kpi.eg) + ' h')
								])),
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('font-weight-bold')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Waiting time: ')
										])),
									$elm$html$Html$text(
									A2($myrho$elm_round$Round$round, 1, kpi.eb) + ' h')
								]))
						]))
				]));
	} else {
		return $elm$html$Html$text('');
	}
};
var $author$project$Views$MapAssignment$View$routeKpi = function (_v0) {
	var routeData = _v0.g9;
	var kpis = _v0.f6;
	switch (kpis.$) {
		case 3:
			var routeKPIs = kpis.a.hb;
			var error = kpis.a.fs;
			var status = kpis.a.ht;
			if (!routeKPIs.$) {
				var routeKpis_ = routeKPIs.a;
				var maybeKpis = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (_v3) {
							var routeId = _v3.du;
							return _Utils_eq(routeId, routeData.hW);
						},
						routeKpis_));
				var kpisViewOrError = _Utils_eq(
					status,
					$elm$core$Maybe$Just('failed')) ? A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'width', '100%'),
							A2($elm$html$Html$Attributes$style, 'color', 'red')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(error)
						])) : $author$project$Views$MapAssignment$View$kpisView(maybeKpis);
				return _Utils_Tuple2(kpisViewOrError, maybeKpis);
			} else {
				return _Utils_Tuple2(
					$elm$html$Html$text(''),
					$elm$core$Maybe$Nothing);
			}
		case 2:
			return _Utils_Tuple2(
				$elm$html$Html$text(''),
				$elm$core$Maybe$Nothing);
		default:
			return _Utils_Tuple2(
				$elm$html$Html$text(''),
				$elm$core$Maybe$Nothing);
	}
};
var $author$project$Views$MapAssignment$View$routeStopsCount = function (routeContainer) {
	var locationsCount = $author$project$Common$Locations$uniqueLocationsCount(
		A2(
			$elm$core$List$map,
			function ($) {
				return $.cZ;
			},
			routeContainer.g9.gL));
	var _v0 = routeContainer.g9.ht;
	if (_v0 === 1) {
		var maybeStops = function () {
			var _v2 = $author$project$Views$MapAssignment$View$routeKpi(routeContainer);
			if (!_v2.b.$) {
				var kpi = _v2.b.a;
				return kpi.dS;
			} else {
				var _v3 = _v2.b;
				return $elm$core$Maybe$Nothing;
			}
		}();
		if (!maybeStops.$) {
			var stops = maybeStops.a;
			return stops;
		} else {
			return locationsCount;
		}
	} else {
		return locationsCount;
	}
};
var $author$project$Views$MapAssignment$View$routeStats = F2(
	function (routeContainer, routePackages) {
		var orders = A2(
			$elm$core$List$map,
			function ($) {
				return $.cZ;
			},
			routeContainer.g9.gL);
		var ordersCount = $elm$core$List$length(orders);
		var divider = A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('half-opaque')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(' | ')
				]));
		return (ordersCount > 0) ? _List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(ordersCount) + ' Orders')
					])),
				divider,
				A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(
							$author$project$Views$MapAssignment$Model$packagesCount(orders)) + ' Parcels')
					])),
				divider,
				A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(
							$author$project$Views$MapAssignment$RouteContainer$routeItems(orders)) + ' Items')
					])),
				divider,
				A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(
							$author$project$Views$MapAssignment$View$routeStopsCount(routeContainer)) + ' Stops')
					])),
				divider,
				A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Views$MapAssignment$View$packagesVolume(routePackages) + ' L')
					]))
			]) : _List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('half-opaque')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('0 Orders')
					]))
			]);
	});
var $author$project$Views$Common$Checkbox$view = F2(
	function (isChecked, onClickMsg) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(onClickMsg),
							$elm$html$Html$Attributes$class('paack-checkbox')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('fa fa-check paack-checkbox-icon', true),
											_Utils_Tuple2('show', isChecked)
										]))
								]),
							_List_Nil)
						]))
				]));
	});
var $author$project$Views$MapAssignment$View$routeCard = F3(
	function (context, model, routeContainer) {
		var zone = context.em;
		var time = context.d_;
		var packages = model.c6;
		var routes = model.hc;
		var route = routeContainer.g9;
		var statusBadge = function () {
			var _v2 = route.ht;
			switch (_v2) {
				case 0:
					return A2($author$project$Views$Common$Badge$withText, 'To Do', 'gray');
				case 2:
					return A2($author$project$Views$Common$Badge$withText, 'Calculating', 'blue');
				case 1:
					return A2($author$project$Views$Common$Badge$withText, 'Calculated', 'green');
				case 3:
					return A2($author$project$Views$Common$Badge$withText, 'Error', 'red');
				case 5:
					return A2($author$project$Views$Common$Badge$withText, 'Deleted', 'gray');
				default:
					return A2($author$project$Views$Common$Badge$withText, 'Hidden', 'gray');
			}
		}();
		var orders = A2(
			$elm$core$List$map,
			function ($) {
				return $.cZ;
			},
			route.gL);
		var routePackages = A2($author$project$Common$Package$packagesFromOrders, orders, packages);
		var routeOrdersList = function (rc) {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('row no-gutters')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-12'),
									$author$project$Views$MapAssignment$View$clickWithoutPropagation($author$project$Views$MapAssignment$Msg$NOOP)
								]),
							A2(
								$elm$core$List$map,
								A3($author$project$Views$MapAssignment$View$routeCardOrder, zone, time, routePackages),
								rc.g9.gL))
						]))
				]);
		};
		var numOrders = $elm$core$List$length(orders);
		var optimizeOrders = (numOrders > 1) ? A3($author$project$Views$MapAssignment$View$optimizeOrderStatus, context, routeContainer, model) : ((!numOrders) ? A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('badge badge-route clickable big-icon'),
					$author$project$Views$MapAssignment$View$clickWithoutPropagation(
					$author$project$Views$MapAssignment$Msg$DeleteRoute(routeContainer))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
							$elm$html$Html$Attributes$class('fa fa-trash'),
							$elm$html$Html$Attributes$title('Remove route')
						]),
					_List_Nil)
				])) : $elm$html$Html$text(''));
		var extraInfoFormView = A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('input-group input-group-sm'),
							$author$project$Views$MapAssignment$View$clickWithoutPropagation($author$project$Views$MapAssignment$Msg$NOOP)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('input-group-prepend')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('input-group-text')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Working hours')
										]))
								])),
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('number'),
									$elm$html$Html$Attributes$class('form-control'),
									$elm$html$Html$Events$onInput(
									$author$project$Views$MapAssignment$Msg$SetWorkingHoursInRouteContainer(routeContainer)),
									$author$project$Views$MapAssignment$View$clickWithoutPropagation($author$project$Views$MapAssignment$Msg$NOOP),
									$elm$html$Html$Attributes$value(routeContainer.eh)
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('input-group input-group-sm'),
							$author$project$Views$MapAssignment$View$clickWithoutPropagation($author$project$Views$MapAssignment$Msg$NOOP)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('input-group-prepend')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('input-group-text')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Start Time')
										]))
								])),
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('time'),
									$elm$html$Html$Attributes$class('form-control'),
									$elm$html$Html$Events$onInput(
									$author$project$Views$MapAssignment$Msg$SetStartTimeInRouteContainer(routeContainer)),
									$author$project$Views$MapAssignment$View$clickWithoutPropagation($author$project$Views$MapAssignment$Msg$NOOP),
									$elm$html$Html$Attributes$value(routeContainer.dP)
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('input-group input-group-sm'),
							$author$project$Views$MapAssignment$View$clickWithoutPropagation($author$project$Views$MapAssignment$Msg$NOOP)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('input-group-prepend')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('input-group-text')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Capacity')
										]))
								])),
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('number'),
									$elm$html$Html$Attributes$class('form-control'),
									$elm$html$Html$Events$onInput(
									$author$project$Views$MapAssignment$Msg$SetCapacityInRouteContainer(routeContainer)),
									$author$project$Views$MapAssignment$View$clickWithoutPropagation($author$project$Views$MapAssignment$Msg$NOOP),
									$elm$html$Html$Attributes$value(routeContainer.bF)
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('btn btn-sm btn-danger d-block mx-auto m-2'),
							$author$project$Views$MapAssignment$View$clickWithoutPropagation(
							$author$project$Views$MapAssignment$Msg$CloseExtraInfoForm(routeContainer))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Cancel and delete data')
						]))
				]));
		var _v0 = routes;
		var selectedRouteContainer = _v0.bd;
		var changingDriverRouteContainer = _v0.bH;
		var driverInfoRouteContainer = A2(
			$elm$core$Maybe$withDefault,
			routeContainer,
			A2(
				$elm$core$Maybe$map,
				function (container) {
					return A2($author$project$Views$MapAssignment$RouteContainer$isSameRouteContainer, container, routeContainer) ? container : routeContainer;
				},
				changingDriverRouteContainer));
		var routeDriverSelection = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('col-3 text-right')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('d-flex flex-column')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('mb-2')
								]),
							_List_fromArray(
								[statusBadge])),
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									optimizeOrders,
									$author$project$Views$MapAssignment$View$routeDriver(driverInfoRouteContainer)
								]))
						]))
				]));
		var routeCardOrders = function (rc) {
			return A2(
				$elm$core$Maybe$withDefault,
				$elm$html$Html$text(''),
				A2(
					$elm$core$Maybe$map,
					function (container) {
						return A2(
							$author$project$HtmlExtra$when,
							A2($author$project$Views$MapAssignment$RouteContainer$isSameRouteContainer, container, rc),
							A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('row no-gutters')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('col-12')
											]),
										routeOrdersList(rc))
									])));
					},
					selectedRouteContainer));
		};
		var routeContainerSelected = A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				function (container) {
					return A2($author$project$Views$MapAssignment$RouteContainer$isSameRouteContainer, container, routeContainer);
				},
				selectedRouteContainer));
		var routeInfo = function () {
			var tagText = A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('tag')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						A3(
							$elm$core$List$foldr,
							F2(
								function (x, a) {
									return x + (' ' + a);
								}),
							'',
							route.hE))
					]));
			var routeText = routeContainer.fk ? A2(
				$elm$html$Html$input,
				_List_fromArray(
					[
						$author$project$Views$Helpers$Events$onEnter(
						$author$project$Views$MapAssignment$Msg$SaveRouteName(routeContainer)),
						$elm$html$Html$Events$onInput(
						$author$project$Views$MapAssignment$Msg$SetRouteName(routeContainer)),
						$author$project$Views$MapAssignment$View$clickWithoutPropagation($author$project$Views$MapAssignment$Msg$NOOP),
						$elm$html$Html$Attributes$value(routeContainer.fl)
					]),
				_List_Nil) : A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('name')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(route.gs),
						A2(
						$elm$html$Html$i,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
								$elm$html$Html$Attributes$class('fa fa-edit ml-2 clickable'),
								$author$project$Views$MapAssignment$View$clickWithoutPropagation(
								$author$project$Views$MapAssignment$Msg$ToggleRouteNameEdit(routeContainer))
							]),
						_List_Nil)
					]));
			var mapIcon = (numOrders > 0) ? A2(
				$elm$html$Html$i,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
						$elm$html$Html$Attributes$class('fa fa-map ml-2 map-icon'),
						$author$project$Views$MapAssignment$View$clickWithoutPropagation(
						$author$project$Views$MapAssignment$Msg$ZoomToStartOfRoute(routeContainer))
					]),
				_List_Nil) : $elm$html$Html$text('');
			var kpis = $author$project$Views$MapAssignment$View$routeKpi(routeContainer).a;
			var elementId = 'route-' + $elm$core$String$fromInt(route.fS);
			var driverNameText = $elm$html$Html$text(
				A2(
					$elm$core$Maybe$withDefault,
					'',
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.fJ;
						},
						route.fh)));
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('col-9'),
						$elm$html$Html$Attributes$id(elementId)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('row no-gutters')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('col-12')
									]),
								_List_fromArray(
									[routeText])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('col-12')
									]),
								_List_fromArray(
									[tagText]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('row no-gutters')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('col-12')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('name')
											]),
										_List_fromArray(
											[driverNameText])),
										mapIcon
									])),
								A2(
								$author$project$HtmlExtra$when,
								(!route.ht) && (_Utils_eq(
									routeContainer.dA,
									$author$project$Views$MapAssignment$RouteContainer$Saved(true)) && (!routeContainer.ct)),
								A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-primary clickable'),
											$author$project$Views$MapAssignment$View$clickWithoutPropagation(
											$author$project$Views$MapAssignment$Msg$OpenExtraInfoForm(routeContainer))
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$small,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Edit extra info')
												]))
										])))
							])),
						(routeContainerSelected && (route.ht === 1)) ? kpis : ((route.ht === 3) ? kpis : $elm$html$Html$text(''))
					]));
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('row no-gutters rounded p-3 mb-2 route-box pending'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('selected', routeContainerSelected)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('col-12')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row no-gutters mb-1')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-12')
										]),
									_List_fromArray(
										[
											A2(
											$author$project$Views$Common$Checkbox$view,
											routeContainer.f2,
											$author$project$Views$MapAssignment$Msg$SelectRouteToDelete(routeContainer))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row no-gutters'),
									$elm$html$Html$Events$onClick(
									$author$project$Views$MapAssignment$Msg$SelectRouteContainer(routeContainer))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-12')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('row no-gutters')
												]),
											_List_fromArray(
												[routeInfo, routeDriverSelection])),
											A2(
											$author$project$HtmlExtra$when,
											routeContainer.ct && ((!route.ht) && _Utils_eq(
												routeContainer.dA,
												$author$project$Views$MapAssignment$RouteContainer$Saved(true))),
											extraInfoFormView),
											function () {
											var _v1 = routeContainer.ae;
											if (_v1.$ === 3) {
												var error = _v1.a;
												return A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('row no-gutters mb-2')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$span,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('manual-route-error')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text(error)
																]))
														]));
											} else {
												return $elm$html$Html$text('');
											}
										}(),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('route-stats mt-2'),
													$elm$html$Html$Attributes$classList(
													_List_fromArray(
														[
															_Utils_Tuple2('mb-3 mt-3', routeContainerSelected)
														]))
												]),
											A2($author$project$Views$MapAssignment$View$routeStats, routeContainer, routePackages)),
											routeCardOrders(routeContainer)
										]))
								]))
						]))
				]));
	});
var $author$project$Views$MapAssignment$Msg$OnSearchRoundByOrder = function (a) {
	return {$: 76, a: a};
};
var $author$project$Views$MapAssignment$View$searchRoundByOrder = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('mb-3 mt-3')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$input,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$type_('text'),
					$elm$html$Html$Attributes$class('form-control'),
					$elm$html$Html$Attributes$placeholder('Search Round by Order'),
					$elm$html$Html$Events$onInput($author$project$Views$MapAssignment$Msg$OnSearchRoundByOrder)
				]),
			_List_Nil)
		]));
var $author$project$Views$MapAssignment$View$routesView = F2(
	function (context, model) {
		var routes = model.hc;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('routes-container')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id('routes-list'),
							$elm$html$Html$Attributes$class('row no-gutters routes-list'),
							A2($elm$html$Html$Attributes$style, 'padding', '0 8px 8px 8px')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-12')
								]),
							A2(
								$elm$core$List$cons,
								A2($author$project$Views$MapAssignment$View$ordersStats, context, model),
								A2(
									$elm$core$List$cons,
									$author$project$Views$MapAssignment$View$controlsRow(model),
									A2(
										$elm$core$List$cons,
										$author$project$Views$MapAssignment$View$optimizeAllRoutesBtn,
										A2(
											$elm$core$List$cons,
											$author$project$Views$MapAssignment$View$searchRoundByOrder,
											A2(
												$elm$core$List$cons,
												$author$project$Views$MapAssignment$View$generatingRoutesText(routes.bz),
												A2(
													$elm$core$List$cons,
													$author$project$Views$MapAssignment$View$fetchingRoutesText(routes.bc),
													A2(
														$elm$core$List$map,
														A2($author$project$Views$MapAssignment$View$routeCard, context, model),
														routes.b9))))))))
						]))
				]));
	});
var $author$project$Views$MapAssignment$View$view = F2(
	function (context, model) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$main_,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row no-gutters')
								]),
							_List_fromArray(
								[
									A2(
									$author$project$Views$Common$Alert$errorAlertView,
									model.b2,
									$elm$core$Maybe$Just($author$project$Views$MapAssignment$Msg$DismissErrorAlert))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row no-gutters')
								]),
							_List_fromArray(
								[
									model.c2 ? $elm$html$Html$text('') : A3($elm$html$Html$Lazy$lazy2, $author$project$Views$MapAssignment$View$ordersView, context, model),
									A3($elm$html$Html$Lazy$lazy2, $author$project$Views$MapAssignment$View$routesView, context, model),
									A2($author$project$Views$MapAssignment$View$mapAreaView, context, model),
									A3($elm$html$Html$Lazy$lazy2, $author$project$Views$MapAssignment$View$mapButtonsView, context.em, model)
								]))
						])),
					A2($elm$html$Html$Lazy$lazy, $author$project$Views$MapAssignment$View$confirmationModalView, model.cl),
					A2($elm$html$Html$Lazy$lazy, $author$project$Views$MapAssignment$View$automaticRouteErrorDialog, model),
					A3($author$project$Views$MapAssignment$View$automaticRouteView, context, model.hc.bA, model.b$)
				]));
	});
var $author$project$Views$Reports$Msg$ReplayReportFormDataRequests = {$: 16};
var $elm$html$Html$h1 = _VirtualDom_node('h1');
var $author$project$Views$Reports$View$viewAlertError = F2(
	function (error, msg) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('alert alert-danger ')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(error),
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('btn btn-primary ml-4'),
							$elm$html$Html$Events$onClick(msg)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Try again')
						]))
				]));
	});
var $author$project$Views$Reports$Msg$OnCloseReportFormAlertClicked = {$: 13};
var $author$project$Views$Reports$Msg$OnDateEndChanged = function (a) {
	return {$: 10, a: a};
};
var $author$project$Views$Reports$Msg$OnDateStartChanged = function (a) {
	return {$: 9, a: a};
};
var $author$project$Views$Reports$Msg$OnGenerateButtonClicked = {$: 11};
var $author$project$Views$Reports$Msg$OnReportTypeSelected = function (a) {
	return {$: 8, a: a};
};
var $elm$html$Html$fieldset = _VirtualDom_node('fieldset');
var $author$project$Views$Reports$Model$isAbleToGenerateReport = function (model) {
	return A2($elm$core$List$member, model.ba.ah, model.ao);
};
var $author$project$Views$Reports$Model$isReportFormValid = function (_v0) {
	var reportType = _v0.ah;
	var startDate = _v0.aO;
	var endDate = _v0.ar;
	switch (reportType) {
		case 'range':
			return (!_Utils_eq(startDate, $elm$core$Maybe$Nothing)) && (!_Utils_eq(endDate, $elm$core$Maybe$Nothing));
		case 'day':
			return (!_Utils_eq(startDate, $elm$core$Maybe$Nothing)) && _Utils_eq(endDate, $elm$core$Maybe$Nothing);
		case 'location':
			return (!_Utils_eq(startDate, $elm$core$Maybe$Nothing)) && (!_Utils_eq(endDate, $elm$core$Maybe$Nothing));
		default:
			return false;
	}
};
var $elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$Keyed$node = $elm$virtual_dom$VirtualDom$keyedNode;
var $author$project$HtmlExtra$onChange = function (handler) {
	return A2(
		$elm$html$Html$Events$on,
		'change',
		A2(
			$elm$json$Json$Decode$map,
			handler,
			A2(
				$elm$json$Json$Decode$at,
				_List_fromArray(
					['target', 'value']),
				$elm$json$Json$Decode$string)));
};
var $author$project$Views$Reports$Msg$ToggleAdvancedFilters = {$: 0};
var $author$project$Data$Country$toPrettyString = function (country) {
	switch (country) {
		case 0:
			return 'United States';
		case 1:
			return 'Portugal';
		case 2:
			return 'Spain';
		case 3:
			return 'United Kingdom';
		default:
			return 'France';
	}
};
var $author$project$Views$Reports$AdvancedFilters$view = function (_v0) {
	var state = _v0;
	if (state.$ === 1) {
		var companiesSelect = state.a;
		var storesSelect = state.b;
		var countriesSelect = state.c;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('row mt-4')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('col-12 d-inline-flex')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$map,
									$author$project$Views$Reports$Msg$CompaniesSelectMsg,
									A3(
										$author$project$Views$Common$Select$view,
										companiesSelect,
										'Select Companies',
										function ($) {
											return $.gs;
										}))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$map,
									$author$project$Views$Reports$Msg$StoresSelectMsg,
									A3(
										$author$project$Views$Common$Select$view,
										storesSelect,
										'Select Stores',
										function ($) {
											return $.gs;
										}))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$map,
									$author$project$Views$Reports$Msg$CountriesSelectMsg,
									A3($author$project$Views$Common$Select$view, countriesSelect, 'Select Countries', $author$project$Data$Country$toPrettyString))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mt-2 mx-auto')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
									$elm$html$Html$Attributes$class('btn btn-danger fa fa-times'),
									$elm$html$Html$Events$onClick($author$project$Views$Reports$Msg$ToggleAdvancedFilters)
								]),
							_List_Nil)
						]))
				]));
	} else {
		return $elm$html$Html$text('');
	}
};
var $author$project$Views$Reports$View$viewAdvancedFilters = function (advancedFilters) {
	return $author$project$Views$Reports$AdvancedFilters$isOpen(advancedFilters) ? $author$project$Views$Reports$AdvancedFilters$view(advancedFilters) : A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('row mt-4')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href(''),
						$elm$html$Html$Attributes$class('row mx-auto'),
						$author$project$HtmlExtra$clickWithoutPropagation($author$project$Views$Reports$Msg$ToggleAdvancedFilters)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Advanced filters')
					]))
			]));
};
var $author$project$Views$Reports$View$warningAlert = function (errorMsg) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('alert alert-warning col-12')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(errorMsg)
			]));
};
var $author$project$Views$Reports$View$viewForm = function (model) {
	var reportForm = model.ba;
	var isCreatingReport = model.cs;
	var reportFormError = model.dj;
	return A2(
		$elm$html$Html$fieldset,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-12'),
				$elm$html$Html$Attributes$disabled(isCreatingReport)
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group row mt-4')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('col-form-label col-1 offset-1')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Type')
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('col-8')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$select,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-control'),
										$author$project$HtmlExtra$onChange($author$project$Views$Reports$Msg$OnReportTypeSelected)
									]),
								A2(
									$elm$core$List$map,
									function (reportType) {
										return A2(
											$elm$html$Html$option,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(reportType)
												]));
									},
									$author$project$Data$Report$reportTypes))
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group row mt-4')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$for('type-input'),
								$elm$html$Html$Attributes$class('col-form-label col-1 offset-1')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Dates')
							])),
						A3(
						$elm$html$Html$Keyed$node,
						'div',
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('col-4')
							]),
						_List_fromArray(
							[
								_Utils_Tuple2(
								$elm$core$String$fromInt(model.dh),
								A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$type_('date'),
											$elm$html$Html$Attributes$class('form-control'),
											$elm$html$Html$Events$onInput($author$project$Views$Reports$Msg$OnDateStartChanged)
										]),
									_List_Nil))
							])),
						A2(
						$author$project$HtmlExtra$when,
						reportForm.ah !== 'day',
						A3(
							$elm$html$Html$Keyed$node,
							'div',
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-4')
								]),
							_List_fromArray(
								[
									_Utils_Tuple2(
									$elm$core$String$fromInt(model.dh),
									A2(
										$elm$html$Html$input,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$type_('date'),
												$elm$html$Html$Attributes$class('form-control'),
												$elm$html$Html$Events$onInput($author$project$Views$Reports$Msg$OnDateEndChanged)
											]),
										_List_Nil))
								])))
					])),
				$author$project$Views$Reports$View$viewAdvancedFilters(reportForm.aQ),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('row mt-4 mb-4')
					]),
				_List_fromArray(
					[
						$author$project$Views$Reports$Model$isAbleToGenerateReport(model) ? A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick($author$project$Views$Reports$Msg$OnGenerateButtonClicked),
								$elm$html$Html$Attributes$disabled(
								!$author$project$Views$Reports$Model$isReportFormValid(reportForm)),
								$elm$html$Html$Attributes$class('btn btn-primary btn-lg mx-auto')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Generate!')
							])) : $author$project$Views$Reports$View$warningAlert('This type of report is being generated, please wait to generate another report.')
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('col-12')
					]),
				_List_fromArray(
					[
						function () {
						if (!reportFormError.$) {
							var errorMsg = reportFormError.a;
							return A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('alert alert-danger alert-dismissible fade show')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(errorMsg),
										A2(
										$elm$html$Html$button,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('close'),
												$elm$html$Html$Events$onClick($author$project$Views$Reports$Msg$OnCloseReportFormAlertClicked)
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$span,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('X')
													]))
											]))
									]));
						} else {
							return $elm$html$Html$text('');
						}
					}()
					]))
			]));
};
var $author$project$Views$Reports$View$viewLoading = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('mt-4')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('spinner-lg')
				]),
			_List_Nil)
		]));
var $author$project$Views$Reports$View$viewGenerateForm = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('reports-container col-6')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h1,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('col-12 mt-2 text-center')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Report Generator')
					])),
				function () {
				if (!model.cc) {
					return $author$project$Views$Reports$View$viewForm(model);
				} else {
					var _v0 = model.di;
					if (!_v0.$) {
						var errorMsg = _v0.a;
						return A2($author$project$Views$Reports$View$viewAlertError, errorMsg, $author$project$Views$Reports$Msg$ReplayReportFormDataRequests);
					} else {
						return $author$project$Views$Reports$View$viewLoading;
					}
				}
			}()
			]));
};
var $author$project$Views$Reports$Msg$ReplayReportsListRequets = {$: 15};
var $author$project$Views$Reports$View$viewReportJobAlert = function (availableReportTypes) {
	var textLabel = function (reportType) {
		return A2(
			$author$project$HtmlExtra$when,
			A2(
				$elm$core$List$all,
				function (j) {
					return !_Utils_eq(j, reportType);
				},
				availableReportTypes),
			A2(
				$elm$html$Html$h6,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('col-12 mt-2 text-center font-italic')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('A ' + (reportType + ' report is being processed'))
					])));
	};
	return A2(
		$author$project$HtmlExtra$when,
		$elm$core$List$length(availableReportTypes) < 3,
		A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					textLabel('range'),
					textLabel('location'),
					textLabel('day'),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('spinner')
						]),
					_List_Nil)
				])));
};
var $author$project$Views$Helpers$Date$dayMonthFullYear = F2(
	function (zone, time) {
		return A3(
			$ryan_haskell$date_format$DateFormat$format,
			_List_fromArray(
				[
					$ryan_haskell$date_format$DateFormat$dayOfMonthFixed,
					$ryan_haskell$date_format$DateFormat$text('/'),
					$ryan_haskell$date_format$DateFormat$monthFixed,
					$ryan_haskell$date_format$DateFormat$text('/'),
					$ryan_haskell$date_format$DateFormat$yearNumber
				]),
			zone,
			time);
	});
var $elm$html$Html$Attributes$target = $elm$html$Html$Attributes$stringProperty('target');
var $author$project$Views$Reports$View$viewReportTableRow = F3(
	function (zone, newReports, report) {
		var createdAtToString = A2(
			$elm$core$String$join,
			' ',
			_List_fromArray(
				[
					A2($author$project$Views$Helpers$Date$dayMonthFullYear, zone, report.e$),
					A2($author$project$Views$Helpers$Date$hourMinutes, zone, report.e$)
				]));
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(report.fC)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$target('blank_'),
									$elm$html$Html$Attributes$href(report.hV)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Download'),
									A2(
									$author$project$HtmlExtra$when,
									A2($elm$core$List$member, report, newReports),
									A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('ml-2 badge badge-success')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('New')
											])))
								]))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(report.d5)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(createdAtToString)
						]))
				]));
	});
var $author$project$Views$Reports$View$viewReportsTable = F3(
	function (zone, reports, newReports) {
		return A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('table table-striped mt-4')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$thead,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Name')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Download')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Type')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Created At')
										]))
								]))
						])),
					A2(
					$elm$html$Html$tbody,
					_List_Nil,
					A2(
						$elm$core$List$map,
						A2($author$project$Views$Reports$View$viewReportTableRow, zone, newReports),
						reports))
				]));
	});
var $author$project$Views$Reports$View$viewRecentReports = F2(
	function (_v0, model) {
		var zone = _v0.em;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('reports-container col-6 recent-reports-container')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h1,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('col-12 mt-2 text-center')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Recent Reports')
						])),
					$author$project$Views$Reports$View$viewReportJobAlert(model.ao),
					function () {
					if (model.cy) {
						return $author$project$Views$Reports$View$viewLoading;
					} else {
						var _v1 = model.dl;
						if (!_v1.$) {
							var errorMsg = _v1.a;
							return A2($author$project$Views$Reports$View$viewAlertError, errorMsg, $author$project$Views$Reports$Msg$ReplayReportsListRequets);
						} else {
							return A3($author$project$Views$Reports$View$viewReportsTable, zone, model.dk, model.cS);
						}
					}
				}()
				]));
	});
var $author$project$Views$Reports$View$view = F2(
	function (context, model) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('row')
				]),
			_List_fromArray(
				[
					$author$project$Views$Reports$View$viewGenerateForm(model),
					A2($author$project$Views$Reports$View$viewRecentReports, context, model)
				]));
	});
var $author$project$Views$RetailerRoundIntegration$Msg$RetryFetchRoutes = {$: 2};
var $author$project$Views$RetailerRoundIntegration$View$errorView = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('d-flex justify-content-center pt-4')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('error-view p-3 p-md-5 d-flex flex-column')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Could not fetch the routes list')
						])),
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('btn btn-danger mt-4'),
							$elm$html$Html$Events$onClick($author$project$Views$RetailerRoundIntegration$Msg$RetryFetchRoutes)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Retry')
						]))
				]))
		]));
var $author$project$Views$RetailerRoundIntegration$Msg$SelectAllRoutes = {$: 3};
var $author$project$Views$RetailerRoundIntegration$View$retailerToPrettyString = function (retailer) {
	switch (retailer) {
		case 0:
			return 'Refruiting';
		case 1:
			return 'Ulabox';
		default:
			return 'Peixos Puignau';
	}
};
var $author$project$Views$RetailerRoundIntegration$Msg$SendToRetailer = {$: 1};
var $author$project$Views$RetailerRoundIntegration$View$sendToRetailerBtn = function (_v0) {
	var routes = _v0.hc;
	var routesSent = _v0.dw;
	var retailer = _v0.dr;
	var hasRouteSelected = A2($elm$core$List$any, $elm$core$Tuple$first, routes);
	switch (routesSent.$) {
		case 1:
			return A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('btn btn-light btn-block'),
						$elm$html$Html$Attributes$disabled(true)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('spinner-border')
							]),
						_List_Nil)
					]));
		case 3:
			return A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('btn btn-success btn-block'),
						$elm$html$Html$Attributes$disabled(true)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Routes sent!')
					]));
		case 2:
			return A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('btn btn-danger btn-block'),
						$elm$html$Html$Attributes$disabled(!hasRouteSelected),
						$elm$html$Html$Events$onClick($author$project$Views$RetailerRoundIntegration$Msg$SendToRetailer)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Retry')
					]));
		default:
			return A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('btn btn-primary btn-block'),
						$elm$html$Html$Attributes$disabled(!hasRouteSelected),
						$elm$html$Html$Events$onClick($author$project$Views$RetailerRoundIntegration$Msg$SendToRetailer)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						'Send to ' + $author$project$Views$RetailerRoundIntegration$View$retailerToPrettyString(retailer))
					]));
	}
};
var $author$project$Views$RetailerRoundIntegration$View$buttonsView = function (loadedModel) {
	var retailer = $author$project$Views$RetailerRoundIntegration$View$retailerToPrettyString(loadedModel.dr);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('m-2')
			]),
		_List_fromArray(
			[
				A2(
				$author$project$HtmlExtra$when,
				$krisajenkins$remotedata$RemoteData$isFailure(loadedModel.dw),
				A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text-center mb-4')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Error sending routes to ' + (retailer + ', please try again.'))
						]))),
				$author$project$Views$RetailerRoundIntegration$View$sendToRetailerBtn(loadedModel),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('btn btn-info btn-block'),
						$elm$html$Html$Events$onClick($author$project$Views$RetailerRoundIntegration$Msg$SelectAllRoutes)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Select all routes')
					]))
			]));
};
var $author$project$Views$RetailerRoundIntegration$Msg$ToggleRouteSelection = function (a) {
	return {$: 0, a: a};
};
var $author$project$Views$RetailerRoundIntegration$View$routeCardView = F2(
	function (_v0, _v1) {
		var zone = _v0.em;
		var isSelected = _v1.a;
		var route = _v1.b;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('route-card d-flex align-items-center')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mr-4')
						]),
					_List_fromArray(
						[
							A2(
							$author$project$Views$Common$Checkbox$view,
							isSelected,
							$author$project$Views$RetailerRoundIntegration$Msg$ToggleRouteSelection(route))
						])),
					$elm$html$Html$text(
					route.gs + (' - ' + A2($author$project$Views$Helpers$Date$dateAndTimeWithoutYear, zone, route.e$)))
				]));
	});
var $author$project$Views$RetailerRoundIntegration$View$routesListView = F2(
	function (context, routes) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('route-list overflow-auto')
				]),
			A2(
				$elm$core$List$map,
				$author$project$Views$RetailerRoundIntegration$View$routeCardView(context),
				routes));
	});
var $author$project$Views$RetailerRoundIntegration$View$loadedView = F2(
	function (context, loadedModel) {
		return A2(
			$elm$html$Html$map,
			$author$project$Views$RetailerRoundIntegration$Msg$LoadedModelMsg,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('d-flex justify-content-center h-100 overflow-auto')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('col-md-4')
							]),
						_List_fromArray(
							[
								$author$project$Views$RetailerRoundIntegration$View$buttonsView(loadedModel),
								A2($author$project$Views$RetailerRoundIntegration$View$routesListView, context, loadedModel.hc)
							]))
					])));
	});
var $author$project$Views$RetailerRoundIntegration$View$retailerNotSupportedView = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('d-flex justify-content-center pt-4')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('error-view p-3 p-md-5 d-flex flex-column')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('There is no integration available for this retailer')
						]))
				]))
		]));
var $author$project$Views$RetailerRoundIntegration$View$viewLoading = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('spinner-lg h-100')
		]),
	_List_Nil);
var $author$project$Views$RetailerRoundIntegration$View$view = F2(
	function (context, model) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$id('refruiting-integration')
				]),
			_List_fromArray(
				[
					function () {
					switch (model.$) {
						case 0:
							var loadedModel = model.a;
							return A2($author$project$Views$RetailerRoundIntegration$View$loadedView, context, loadedModel);
						case 3:
							return $author$project$Views$RetailerRoundIntegration$View$errorView;
						case 1:
							return $author$project$Views$RetailerRoundIntegration$View$viewLoading;
						default:
							return $author$project$Views$RetailerRoundIntegration$View$retailerNotSupportedView;
					}
				}()
				]));
	});
var $author$project$View$body = function (_v0) {
	var context = _v0.eW;
	var page = _v0.gP;
	switch (page.$) {
		case 0:
			var model = page.a;
			return A2(
				$elm$html$Html$map,
				$author$project$Msg$LoginMsg,
				A2($author$project$Views$Login$Login$view, context, model));
		case 1:
			var model = page.a;
			return A2(
				$elm$html$Html$map,
				$author$project$Msg$ForgotPasswordMsg,
				A2($author$project$Views$ForgotPassword$ForgotPassword$view, context, model));
		case 2:
			var model = page.a;
			return A2(
				$author$project$Views$Common$LoggedLayout$view,
				context,
				A2(
					$elm$html$Html$map,
					$author$project$Msg$MapAreaMsg,
					$author$project$Views$MapArea$MapArea$view(model)));
		case 3:
			var model = page.a;
			return A2(
				$author$project$Views$Common$LoggedLayout$view,
				context,
				A2(
					$elm$html$Html$map,
					$author$project$Msg$MapAssignmentMsg,
					A2($author$project$Views$MapAssignment$View$view, context, model)));
		case 4:
			var model = page.a;
			return A2(
				$author$project$Views$Common$LoggedLayout$view,
				context,
				A2(
					$elm$html$Html$map,
					$author$project$Msg$ReportsMsg,
					A2($author$project$Views$Reports$View$view, context, model)));
		default:
			var model = page.a;
			return A2(
				$author$project$Views$Common$LoggedLayout$view,
				context,
				A2(
					$elm$html$Html$map,
					$author$project$Msg$RetailerRoundIntegrationMsg,
					A2($author$project$Views$RetailerRoundIntegration$View$view, context, model)));
	}
};
var $author$project$View$view = function (model) {
	return {
		eC: _List_fromArray(
			[
				$author$project$View$body(model)
			]),
		hH: 'Paack'
	};
};
var $author$project$Main$main = $elm$browser$Browser$application(
	{fW: $author$project$Model$init, gF: $author$project$Msg$UrlChanged, gG: $author$project$Msg$UrlRequested, hA: $author$project$Subscription$subscriptions, hT: $author$project$Update$update, h_: $author$project$View$view});
_Platform_export({'Main':{'init':$author$project$Main$main($elm$json$Json$Decode$value)(0)}});}(this));