import { Elm } from "./Main.elm";
import { PaackSocket } from "./paackSocket";
import registerServiceWorker from "./registerServiceWorker";

const MSGS_TO_RECEIVE = {
  STORE_SESSION: "StoreSession",
  SAVE_SETTINGS: "SaveSettings",
  CONNECT_TO_WEBSOCKET: "ConnectToWebSocket",
  STORE_ROUTE_JOB_INFO: "StoreRouteJobInfo",
  DISCARD_ROUTE_JOB_INFO: "DiscardRouteJobInfo",
  CHECK_STORED_ROUTE_JOB_INFO: "CheckStoredRouteJobInfo",
  START_JOB_TIMER: "StartJobTimer",
  STOP_JOB_TIMER: "StopJobTimer",
  PLAY_ALERT_SOUND: "PlayAlertSound",
  UPDATE_PAGE_TITLE: "UpdatePageTitle",
  SET_MANUAL_ROUTE_SETTINGS_EXPIRED: "SetManualRouteSettingsExpired",
  SET_TIMER_TO_KEEP_OPTIMIZE_BUTTON_DISABLED:
    "SetTimerToKeepOptimizeButtonsDisabled",
  STORE_ROUTE_JOB_ID: "StoreRouteJobId",
  DISCARD_ROUTE_JOB_ID: "DiscardRouteJobId",
};

const MSGS_TO_SEND = {
  ON_STORE_SESSION: "OnSessionChange",
  ON_SETTINGS_SAVED: "OnSettingsSaved",
  ON_SEND_JOB_REQUEST: "OnSendJobRequest",
  ON_ROUTE_JOB_INFO_STORED: "OnRouteJobInfoStored",
  ON_SETTINGS_EXPIRED: "OnSettingsExpired",
  ENABLE_OPTIMIZE_BUTTONS: "EnableOptimizeButtons",
  LOG_OUT_USER: "LogOutUser",
};

var session = hasLocalStorage() ? sessionStorage.getItem("session") : null;
var settings = hasLocalStorage() ? localStorage.getItem("settings") : null;
var routesJobIds = getJobIdsFromLocalStorage();
var flags = {
  user: JSON.parse(session || null),
  settings: JSON.parse(settings || null),
  routesJobIds: JSON.parse(routesJobIds || null),
  environment: "production",
  mapsApiKey: "AIzaSyDY_YQpEp0jyrntZYoUCeEub_7jBPzKJFk",
  now: new Date().toISOString(),
};

var app = Elm.Main.init({ flags: flags });

var socket = new PaackSocket(flags.environment, app);
socket.connect();

addEventListener("blur", () => {
  const currentTime = Date.now();
  sessionStorage.setItem("inactiveSince", currentTime);
});

addEventListener("focus", () => {
  const currentTime = Date.now();
  const inactiveSince = sessionStorage.getItem("inactiveSince");
  const hoursSinceInactive = (currentTime - inactiveSince) / (1000 * 60 * 60);
  if (hoursSinceInactive >= 5) {
    app.ports.fromUpsideDown.send({ tag: MSGS_TO_SEND.LOG_OUT_USER, data: {} });
  }
});

app.ports.toUpsideDown.subscribe(function (msg) {
  switch (msg.tag) {
    case MSGS_TO_RECEIVE.STORE_SESSION:
      storeSession(msg.data);
      break;
    case MSGS_TO_RECEIVE.SAVE_SETTINGS:
      saveSettings(msg.data);
      break;
    case MSGS_TO_RECEIVE.CONNECT_TO_WEBSOCKET:
      socket.connectToChannel(msg.data);
      break;
    case MSGS_TO_RECEIVE.STORE_ROUTE_JOB_INFO:
      storeRouteJobInfo(msg.data);
      break;
    case MSGS_TO_RECEIVE.DISCARD_ROUTE_JOB_INFO:
      discardRouteJobInfo();
      break;
    case MSGS_TO_RECEIVE.CHECK_STORED_ROUTE_JOB_INFO:
      checkStoredRouteJobInfo();
      break;
    case MSGS_TO_RECEIVE.START_JOB_TIMER:
      startJobTimer(msg.data);
      break;
    case MSGS_TO_RECEIVE.STOP_JOB_TIMER:
      stopJobTimer(msg.data);
      break;
    case MSGS_TO_RECEIVE.PLAY_ALERT_SOUND:
      playAlertSound();
      break;
    case MSGS_TO_RECEIVE.UPDATE_PAGE_TITLE:
      updatePageTitle(msg.data);
      break;

    case MSGS_TO_RECEIVE.SET_MANUAL_ROUTE_SETTINGS_EXPIRED:
      setManualRouteSettingsExpired(msg.data);
      break;

    case MSGS_TO_RECEIVE.SET_TIMER_TO_KEEP_OPTIMIZE_BUTTON_DISABLED:
      setTimerToKeepOptimizeButtonsDisabled();
      break;

    case MSGS_TO_RECEIVE.STORE_ROUTE_JOB_ID:
      storeRouteJobId(msg.data);
      break;

    case MSGS_TO_RECEIVE.DISCARD_ROUTE_JOB_ID:
      discardRouteJobId(msg.data);
      break;
  }
});

function storeRouteJobId(jobIdInfo) {
  const routesJobIds = getJobIdsFromLocalStorage();
  if (hasSessionStorage()) {
    if (routesJobIds === null) {
      sessionStorage.setItem("routes_job_ids", JSON.stringify([jobIdInfo]));
    } else {
      try {
        const routesJobIdsParsed = JSON.parse(routesJobIds);
        const removeJobIdIfAlreadyPresent = routesJobIdsParsed.filter(
          (item) => item.routeId !== jobIdInfo.routeId
        );
        const routesJobIdsUpdated = removeJobIdIfAlreadyPresent.concat([
          jobIdInfo,
        ]);
        sessionStorage.setItem(
          "routes_job_ids",
          JSON.stringify(routesJobIdsUpdated)
        );
      } catch (err) {
        console.error(err);
      }
    }
  }
}

function discardRouteJobId(routeId) {
  if (hasSessionStorage()) {
    try {
      const jobIds = getJobIdsFromLocalStorage();
      if (jobIds === null) return;
      const routesJobIdsParsed = JSON.parse(jobIds);
      const routesJobIdsUpdated = routesJobIdsParsed.filter(
        (item) => item.routeId !== routeId
      );
      sessionStorage.setItem(
        "routes_job_ids",
        JSON.stringify(routesJobIdsUpdated)
      );
    } catch (err) {
      console.error(err);
    }
  }
}

function storeSession(session) {
  if (hasSessionStorage()) {
    if (session === null) {
      sessionStorage.removeItem("session");
      socket.disconnectChannel();
    } else {
      sessionStorage.setItem("session", JSON.stringify(session));
      socket.resetChannelConnection(session);
    }
  }
}

function saveSettings(settings) {
  if (hasLocalStorage()) {
    if (settings === null) {
      localStorage.removeItem("settings");
    } else {
      localStorage.setItem("settings", JSON.stringify(settings));
      setTimeout(function () {
        app.ports.fromUpsideDown.send({
          tag: MSGS_TO_SEND.ON_SETTINGS_SAVED,
          data: {},
        });
      }, 500);
    }
  }
}

function setManualRouteSettingsExpired({ setting, keysToReset }) {
  if (hasLocalStorage()) {
    const rawSettings = localStorage.getItem("settings");
    if (rawSettings !== null) {
      const settings = JSON.parse(rawSettings);
      for (const key of keysToReset) {
        if (settings.routingSettings[key]) {
          settings.routingSettings[key] = null;
        }
      }
      localStorage.setItem("settings", JSON.stringify(settings));
    }
    setTimeout(function () {
      app.ports.fromUpsideDown.send({
        tag: MSGS_TO_SEND.ON_SETTINGS_EXPIRED,
        data: { setting },
      });
    }, 300);
  }
}

function setTimerToKeepOptimizeButtonsDisabled() {
  setTimeout(function () {
    app.ports.fromUpsideDown.send({
      tag: MSGS_TO_SEND.ENABLE_OPTIMIZE_BUTTONS,
      data: {},
    });
  }, 15000);
}

function storeRouteJobInfo(data) {
  if (hasLocalStorage()) {
    localStorage.setItem("routeJobInfo", JSON.stringify(data));
  }
}

function discardRouteJobInfo() {
  if (hasLocalStorage()) {
    localStorage.removeItem("routeJobInfo");
  }
}

function checkStoredRouteJobInfo() {
  if (hasLocalStorage()) {
    var routeJobInfo = JSON.parse(localStorage.getItem("routeJobInfo") || null);
    if (routeJobInfo != null) {
      app.ports.fromUpsideDown.send({
        tag: MSGS_TO_SEND.ON_ROUTE_JOB_INFO_STORED,
        data: routeJobInfo,
      });
    }
  }
}

window.addEventListener(
  "storage",
  function (event) {
    if (event.storageArea === localStorage && event.key === "session") {
      session = event.newValue;
      if (session != "" || session != undefined) {
        session = JSON.parse(session);
      }
      app.ports.fromUpsideDown.send({
        tag: MSGS_TO_SEND.ON_STORE_SESSION,
        data: session,
      });
    }
  },
  false
);

function hasLocalStorage() {
  return typeof localStorage !== "undefined" && localStorage !== null;
}

function hasSessionStorage() {
  return typeof sessionStorage !== "undefined" && sessionStorage !== null;
}

// TODO: Change for a websocket subscription
var timers = {};

function startJobTimer(jobId) {
  timers[jobId] = { attempts: 0 };
  timers[jobId]["timer"] = setInterval(function () {
    if (timers[jobId]["attempts"] < 2000) {
      timers[jobId].attempts += 1;
      app.ports.fromUpsideDown.send({
        tag: MSGS_TO_SEND.ON_SEND_JOB_REQUEST,
        data: jobId,
      });
    } else {
      clearTimer(timers[jobId]);
    }
  }, 20000);
}

function stopJobTimer(jobId) {
  clearTimer(timers[jobId]);
}

function clearTimer(timer) {
  timer.attempts = 0;
  clearInterval(timer.timer);
}

function playAlertSound() {
  const audio = new Audio("alert_error-01.aac");
  audio.addEventListener("canplaythrough", () => {
    audio.play();
  });
}

function updatePageTitle(title) {
  document.title = title;
}

function getJobIdsFromLocalStorage() {
  const routesJobIds = hasSessionStorage()
    ? sessionStorage.getItem("routes_job_ids")
    : null;

  return routesJobIds;
}
